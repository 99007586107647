import React, { useState, useEffect } from 'react';
import {
  TableContainer,
  Table,
  Typography,
  Box,
  Grid,
} from '@material-ui/core';
import TableHeadX from './head';
import readObjectWithArrayKeys from './utils/read-object-value-with-array-keys';
import TableBodyX from './body';
import axios from 'axios';
import useStyles from './style';

const TableXClassic = ({
  schema,
  url,
  dataConfig,
  onRowClick,
  minHeight,
  triggerFetch,
}) => {
  const classes = useStyles();
  const [dataList, setDataList] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  const fetchData = () => {
    setIsLoading(true);
    axios
      .get(url)
      .then((response) => {
        setDataList(
          dataConfig ? readObjectWithArrayKeys(response.data, dataConfig) : []
        );
      })
      .catch((err) => {
        console.log(err);
        setDataList([]);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  useEffect(() => {
    fetchData();
  }, [triggerFetch]);

  return (
    <TableContainer className={classes.root}>
      <Grid container direction='column' style={{ minHeight }}>
        <Grid item xs>
          <Table className='tableX'>
            <TableHeadX dataList={dataList} schema={schema}></TableHeadX>
            <TableBodyX
              handleClick={onRowClick}
              dataList={dataList}
              schema={schema}
            ></TableBodyX>
          </Table>
          {!isLoading && dataList.length === 0 ? (
            <Box marginTop={2}>
              <Typography align='center'>No Item Available</Typography>
            </Box>
          ) : null}
        </Grid>
      </Grid>
    </TableContainer>
  );
};

export default TableXClassic;
