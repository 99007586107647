import React, { useEffect, useState } from 'react';
import { Grid, makeStyles, Paper, Tab } from '@material-ui/core';
import TitleBar from '../../components/title-bar';
import axios from 'axios';
import TabContainer from '../../components/tabs-container';
import { useTranslation } from 'react-i18next';
import DeliveryUnavailable from './unavailable';
import TadaSetting from './tada';
// import AutomaticSequencing from "./automatic-sequencing";

const useStyles = makeStyles(() => ({
  root: {
    marginBottom: '150px',
    flexDirection: 'column',
    position: 'relative',
  },
}));

const componentList = {
  tada: {
    component: TadaSetting,
  },
};

const DeliverySetting = () => {
  const classes = useStyles();
  const [value, setValue] = useState(null);
  const [deliveryInfo, setDeliveryInfo] = useState(null);
  const { t } = useTranslation();

  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_API}/api/v1/setting/delivery`)
      .then(({ data: { delivery } }) => {
        setDeliveryInfo(delivery);
        if (delivery?.length === 0) {
          setValue('unavailable');
        } else {
          setValue(delivery[0].name);
        }
      })
      .catch((err) => console.log(err));
  }, []);

  return (
    <Grid container className={classes.root} spacing={3}>
      <Grid item xs={12}>
        <TitleBar disableButton />
      </Grid>
      <Grid item xs={12}>
        <Paper>
          <TabContainer
            style={{ padding: '0 10px' }}
            value={value}
            onChange={(e, newValue) => setValue(newValue)}
          >
            {deliveryInfo?.length === 0 && (
              <Tab value="unavailable" label={t('delivery')}></Tab>
            )}
            {deliveryInfo?.map((item) => (
              <Tab value="tada" label={t(item.name)} key={item.name} />
            ))}
          </TabContainer>
          {value === 'unavailable' && <DeliveryUnavailable />}
          {deliveryInfo?.map((item) => (
            <div hidden={value !== item.name} key={item.name}>
              {React.createElement(componentList[item.name].component, {
                data: item,
              })}
            </div>
          ))}
        </Paper>
      </Grid>
    </Grid>
  );
};

export default DeliverySetting;
