import axios from 'axios';
import React from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Grid, Typography } from '@material-ui/core';
import { RHFAutocompleteInput } from '../rhf-controlled-input';

const VariantInput = ({
  index,
  rules,
  name,
  defaultValue = null,
  handleAddField,
  setStocks,
  stocks,
  disabled,
  ...props
}) => {
  const { t } = useTranslation();
  const { setValue } = useFormContext();
  const optionsURL = `${process.env.REACT_APP_API}/api/v1/stocks?search={search}&page=1&limit=10`;

  return (
    <RHFAutocompleteInput
      name={name}
      dataConfig='stocks'
      defaultValue={defaultValue}
      optionsURL={`${optionsURL}`}
      disabled={disabled}
      filterOptions={(option) => option}
      disablePortal
      getOptionLabel={variantGetOptionLabel}
      noOptionsText={t('no-product-found')}
      rules={{
        validate: () => {
          return rules;
        },
      }}
      getOptionSelected={(option, value) =>
        option.product?.name === value.product?.name
      }
      onChange={(e, data, onChange) => {
        if (typeof data === 'object') {
          onChange(data);
          if (handleAddField) {
            handleAddField(index);
          }
          if (stocks && data._id in stocks === false) {
            setStocks((prev) => {
              return {
                ...prev,
                [data._id]: data.qty,
              };
            });
          }
          setValue(`orderItems.${index}.salePrice`, data?.salePrice);
        }
      }}
      onKeyDown={(e, onChange) => {
        if (e.key === 'Enter' && e.target.value !== '') {
          const sku = e.target.value;
          findBySKU(sku).then((product) => {
            if (product) {
              onChange(product);
            }
          });
        }
      }}
      renderOption={renderOption}
      {...props}
    />
  );
};

const renderOption = (data) => {
  return (
    <Grid container justify='space-between'>
      <Grid item xs={7}>
        <Typography>{data?.variant?.name}</Typography>
      </Grid>
      <Grid item xs={5}>
        <Typography align='right'>Stock on hand</Typography>
        <Typography align='right'>{data?.qty}</Typography>
      </Grid>
    </Grid>
  );
};

// function to get product value
export const getValue = (data) => {
  const productName = data?.variant?.name ?? '';
  const productCode = data?.variant?.altCode || data?.variant?.sku;

  return {
    productName,
    productCode,
  };
};

const findBySKU = async (sku) => {
  try {
    const response = await axios.get(
      `${process.env.REACT_APP_API}/api/v1/stocks/find-by-sku?value=${sku}`
    );
    return response?.data?.variant;
  } catch (err) {
    console.log(err);
    return null;
  }
};

// for autocomplete variant
const variantGetOptionLabel = (option) => {
  if (typeof option === 'object') {
    const code = (option?.variant?.sku || option?.sku) ?? null;
    return `${code ? `${code} - ` : ''}${
      option?.variant?.name || option?.name
    }`;
  } else {
    return '';
  }
};

export default VariantInput;
