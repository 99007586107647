import { Avatar, Box, ButtonBase, makeStyles } from '@material-ui/core';
import React from 'react';

const useStyles = makeStyles((theme) => {
  return {
    buttonBase: {
      borderRadius: '50%',
      position: 'relative',
      overflow: 'hidden',
    },
    overlay: {
      position: 'absolute',
      width: '100%',
      height: '100%',
      top: 0,
      left: 0,
      zIndex: 1,
      transition: '0.1s linear',
      ':hover': {
        backgroundColor: 'action.hover',
      },
    },
    avatar: {
      width: '120px',
      height: '120px',
      border: `1px solid ${theme.palette.grey[300]}`,
      padding: 0,
      '& svg': {
        fontSize: '3rem',
      },
    },
  };
});

const ClickableAvatar = ({ image, onClick, avatarIcon }) => {
  const classes = useStyles();
  return (
    <ButtonBase onClick={onClick} className={classes.buttonBase}>
      <Box aria className={classes.overlay} />
      <Avatar
        src={image.file ? image.blob : image.src && image?.src?.thumbnail}
        className={classes.avatar}
      >
        {avatarIcon}
      </Avatar>
    </ButtonBase>
  );
};

export default ClickableAvatar;
