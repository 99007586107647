import { Grid, Typography } from '@material-ui/core';
import axios from 'axios';
import React, { useContext, useEffect } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { UtilityContext } from 'src/components/context-provider/utilty-context';
import LabelWrapper from 'src/components/label-wrapper-input';
import SaleBySelector from 'src/components/sale-by-selector';

const SaleBy = ({ orderId, defaultValue, handleRefreshHistory }) => {
  const { t } = useTranslation();
  const { control, setValue, getValues } = useForm();
  const { showPrompt, closePrompt, showSnackbar } = useContext(UtilityContext);

  const handlePrompt = (newSale) => {
    const currentSaleBy = getValues('saleBy');
    console.log(currentSaleBy);
    const title = t('action-confirmation');
    const message = currentSaleBy ? (
      <Typography>
        {t('update-sale-by-from')}
        <br />
        <b>{currentSaleBy.name}</b>
        {` ${t('to')} `}
        <b>{newSale?.user?.fullname}</b>
      </Typography>
    ) : (
      <Typography>
        {t('update-sale-by-to')}
        <br />
        <b>{newSale?.user?.fullname}</b>
      </Typography>
    );

    showPrompt({
      handleConfirm: () => onConfirm(newSale),
      title,
      message,
      disableIcon: true,
      confirmButtonProps: {
        color: 'primary',
        variant: 'contained',
        style: { borderRadius: 0 },
        disableElevation: true,
      },
      confirmButtonText: t('confirm'),
      cancelButtonText: t('cancel'),
    });
  };

  useEffect(() => {
    if (defaultValue) {
      setValue('saleBy', defaultValue);
    }
  }, [defaultValue]);

  const onConfirm = async (newSale) => {
    const saleBy = { id: newSale._id, name: newSale.user.fullname };
    setValue('saleBy', saleBy);
    closePrompt();
    try {
      const response = await axios.put(
        `${process.env.REACT_APP_API}/api/v1/orders/sale-by/${orderId}`,
        saleBy
      );

      showSnackbar({ message: response.data?.key });
      if (handleRefreshHistory) handleRefreshHistory();
    } catch (err) {
      console.error(err);
      showSnackbar({ message: err.response.data?.key, variant: 'error' });
    }
  };

  return (
    <Grid item xs={12} spacing={1} container alignItems="flex-end">
      <Grid item xs>
        <Controller
          control={control}
          name="saleBy"
          defaultValue={null}
          render={({ field: { value, onChange, ref } }) => {
            return (
              <LabelWrapper label={t('sale-by')}>
                <SaleBySelector
                  label={t('sale-by')}
                  value={value}
                  onChange={(_, data) => {
                    if (data) {
                      handlePrompt(data);
                    } else {
                      onChange(data);
                    }
                  }}
                  inputRef={ref}
                />
              </LabelWrapper>
            );
          }}
        ></Controller>
      </Grid>
    </Grid>
  );
};

export default SaleBy;
