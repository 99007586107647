export const TRANSLATIONS_KH = {
  // Sidebar
  'app-sidebar-dashboard': 'ថសាដថសដថ',
  'app-sidebar-product': 'Product',
  'app-sidebar-product-list': 'List Product',
  'app-sidebar-product-add': 'Add Product',
  'app-sidebar-product-listVariant': 'List Variant Product',
  'app-sidebar-product-addVariant': 'Add Variant Product',
  'app-sidebar-product-unit': 'Unit',
  'app-sidebar-sale': 'Sale Order',
  'app-sidebar-sale-list': 'Order List',
  'app-sidebar-category': 'Category',
  'app-sidebar-category-list': 'List Category',
  'app-sidebar-category-add': 'Add Category',
  'app-sidebar-customer': 'Customer',
  'app-sidebar-customer-list': 'List Customer',
  'app-sidebar-customer-add': 'Add Customer',
  'app-sidebar-vendor': 'Vendor',
  'app-sidebar-vendor-list': 'List Vendor',
  'app-sidebar-vendor-add': 'Add Vendor',
  'app-sidebar-purchase': 'Purchase',
  'app-sidebar-purchase-list': 'List Purchase',
  'app-sidebar-purchase-add': 'Add Purchase',
  'app-sidebar-warehouse': 'Warehouse',
  'app-sidebar-warehouse-transferProduct': 'Product Transfer',
  'app-sidebar-warehouse-addTransferProduct': 'Add Product Transfer',
  'app-sidebar-users': 'Users',
  'app-sidebar-users-list': 'List Users',
  'app-sidebar-users-add': 'Add Users',
  'app-sidebar-memberCard': 'Membership Card',
  'app-sidebar-memberCard-list': 'List Card',
  'app-sidebar-memberCard-add': 'Add Card',
  'app-sidebar-event': 'Event',
  'app-sidebar-event-coupon': 'Coupon',
  'app-sidebar-event-productDiscount': 'Product Discount',
  'app-sidebar-reports': 'Reports',
  'app-sidebar-reports-sellReport': 'Sell Report',
  'app-sidebar-reports-productReport': 'Product Report',
  'app-sidebar-reports-saleByCategory': 'Sale By Category',
  'app-sidebar-reports-saleByPaymentType': 'Sale By Payment Type',
  'app-sidebar-reports-saleByStaff': 'Sale By Staff',
  'app-sidebar-reports-shift': 'Shift',
  'app-sidebar-reports-profitAndLose': 'Profit and Lose',
  'app-sidebar-setting': 'Setting',
  'app-sidebar-setting-brand': 'Brand',
  'app-sidebar-setting-currencyOption': 'Currency Option',
  'app-sidebar-setting-paymentType': 'Payment Type',
  'app-sidebar-setting-store': 'Store',
  'app-sidebar-setting-pos': 'POS',
};
