import axios from 'axios';
import React from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Grid, Typography } from '@material-ui/core';
import { RHFAutocompleteInput } from 'src/components/rhf-controlled-input';

const VariantInput = ({
  index,
  handleAddField,
  defaultValue,
  rules,
  disabled,
}) => {
  const { t } = useTranslation();
  const { setValue } = useFormContext();
  const optionsURL = `${process.env.REACT_APP_API}/api/v1/stocks?search={search}&page=1&limit=10&filter=product.id_exists::true`;

  const findBySKU = async (sku) => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API}/api/v1/stocks/find-by-sku?value=${sku}&filter=product.id_exists::true`
      );
      return response?.data?.variant;
    } catch (err) {
      console.log(err);
      return null;
    }
  };

  return (
    <RHFAutocompleteInput
      name={`products.${index}.stock`}
      dataConfig='stocks'
      defaultValue={defaultValue}
      disablePortal
      autoFocus={false}
      filterOptions={(options) => options}
      optionsURL={optionsURL}
      rules={{
        validate: () => {
          return rules;
        },
      }}
      getOptionSelected={(option, value) => {
        return option.product?.name === value.name;
      }}
      noOptionsText={t('no-product-found')}
      onChange={(e, data, update) => {
        if (typeof data === 'object') {
          setValue(
            `products.${index}.costPrice`,
            data?.variant?.price.costPrice
          );
          setValue(
            `products.${index}.salePrice`,
            data?.variant?.price.salePrice
          );
          setValue(`products.${index}.amount`, data?.variant?.price.salePrice);
          update({
            id: data._id,
            name: data.variant.name,
            image: data?.variant?.images?.[0],
          });
          handleAddField(index);
        }
      }}
      onKeyDown={async (e, onChange) => {
        if (e.key === 'Enter' && e.target.value !== '') {
          const sku = e.target.value;
          const product = await findBySKU(sku);
          if (product) {
            onChange(product);
          }
        }
      }}
      renderOption={renderOption}
      getOptionLabel={variantGetOptionLabel}
      disabled={disabled}
    />
  );
};

const renderOption = (data) => {
  return (
    <Grid container justify='space-between'>
      <Grid item xs={7}>
        <Typography>{data?.variant?.name}</Typography>
      </Grid>
      <Grid item xs={5}>
        <Typography align='right'>Stock on hand</Typography>
        <Typography align='right'>{data?.qty}</Typography>
      </Grid>
    </Grid>
  );
};

// for autocomplete variant
const variantGetOptionLabel = (option) => {
  return option?.variant?.name || option?.name;
};

export default VariantInput;
