import React from 'react';
import {
  FormControl,
  FormLabel,
  makeStyles,
  Select,
  MenuItem,
} from '@material-ui/core';

const useStyles = makeStyles(() => ({
  label: {
    color: 'black',
    fontWeight: 'bold',
    marginBottom: '10px',
    fontSize: '14px',
    textTransform: 'uppercase',
  },
  textField: {
    backgroundColor: 'white',
  },
}));
const InputSelect = ({ label, required, menu, boldLabel = true, ...other }) => {
  const classes = useStyles();
  return (
    <FormControl variant='outlined' size='small' fullWidth>
      <FormLabel
        className={classes.label}
        style={{ fontWeight: boldLabel ? 'bold' : 'normal' }}
      >
        {label}
      </FormLabel>
      <Select required={required} {...other}>
        {menu.map((item, index) => (
          <MenuItem key={index} value={item.value}>
            {item.label}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export default InputSelect;
