import React from 'react';
import TotalDisplay from '../../../../components/total-display';
import { useFormContext } from 'react-hook-form';
import { getValue as productGetValue } from '../product';
import { getValue as costLineGetValue } from '../cost-line';

const PurchaseOrderTotal = () => {
  const { watch } = useFormContext();
  const product = watch('product', []);
  const costLines = watch('costLines', []);

  let subtotal = 0;
  let productTaxTotal = 0;

  if (product?.length > 0) {
    ({ subtotal, productTaxTotal } = product?.reduce(
      (acc, curr) => {
        const get = productGetValue(curr);
        const totalAmount = get.totalAmount;
        acc.subtotal += totalAmount;
        acc.productTaxTotal += get.qty * get.costPrice * (get.tax / 100);
        return acc;
      },
      { subtotal: 0, productTaxTotal: 0 }
    ));
  }

  let costingTotal = 0;
  let costingTaxTotal = 0;
  if (costLines?.length > 0) {
    ({ costingTotal, costingTaxTotal } = costLines?.reduce(
      (acc, curr) => {
        const get = costLineGetValue(curr);
        const totalAmount = get.totalAmount;
        acc.costingTotal += totalAmount;
        acc.costingTaxTotal += get.costPrice * (get.tax / 100);
        return acc;
      },
      { costingTotal: 0, costingTaxTotal }
    ));
  }

  const taxTotal = productTaxTotal + costingTaxTotal;

  return (
    <TotalDisplay
      disableShippingFee
      subtotal={subtotal}
      tax={taxTotal}
      costing={costingTotal}
    />
  );
};

export default PurchaseOrderTotal;
