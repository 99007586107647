import { Typography } from '@material-ui/core';
import axios from 'axios';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { RHFAutocompleteInput } from 'src/components/rhf-controlled-input';
const StorageInput = ({ nestedIndex, storage, stockId }) => {
  const { t } = useTranslation();
  const optionsURL = `${process.env.REACT_APP_API}/api/v1/stocks/find-storages?stockId=${stockId}&search={search}`;

  const findById = async (id) => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API}/api/v1/stocks/find-storages?stockId=${stockId}&id=${id}`
      );
      const value = response.data?.storages?.[0] ?? null;
      return value;
    } catch (err) {
      console.log(err);
      return null;
    }
  };

  return storage?.map((field, index) => (
    // eslint-disable-next-line react/jsx-key
    <div
      style={{
        marginBottom: index !== storage.length - 1 ? '5px' : '0px',
      }}
    >
      <RHFAutocompleteInput
        name={`product.${nestedIndex}.storages.${index}.storage`}
        dataConfig='storages'
        optionsURL={`${optionsURL}`}
        getOptionLabel={(option) => option.name ?? ''}
        noOptionsText={t('no-storage-found')}
        autoFocus={false}
        getOptionSelected={(option, value) => option.name === value.name}
        onChange={(e, data, onChange) => {
          onChange(data);
        }}
        onKeyDown={async (e, onChange) => {
          if (e.key === 'Enter' && e.target.value !== '') {
            const id = e.target.value;
            const product = await findById(id);
            if (product) {
              onChange(product);
            }
          }
        }}
        required
        renderOption={(option) => <RenderOptions data={option} />}
      />
    </div>
  ));
};

const RenderOptions = ({ data }) => {
  return (
    <div>
      <Typography>
        <b>{data.name}</b>
      </Typography>
      <Typography textAlign='right' variant='caption'>
        On Hand : {data.qty}
      </Typography>
    </div>
  );
};

export default StorageInput;
