import React, { useContext } from 'react';
import { Button, Grid, Typography } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import TextInputContainer from '../../../../components/text-input-container';
import { Controller, useFieldArray, useFormContext } from 'react-hook-form';
import { DatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import InputSelect from '../../../../components/input-select';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import LabelInput from '../../../../components/label-input';
import {
  RHFPhoneInput,
  RHFTextInput,
} from '../../../../components/rhf-controlled-input';
import AddressList from './address-list';
import ShippingAddressForm from './shipping-address-form';
import { UtilityContext } from '../../../../components/context-provider/utilty-context';

const CustomerInfo = ({ shippingForm, setShippingForm }) => {
  const { control, getValues, setValue } = useFormContext();
  const { t } = useTranslation();
  const { fields, append, remove } = useFieldArray({
    control,
    name: 'shippingInfo',
  });
  const { showDeletePrompt, closeDeletePrompt } = useContext(UtilityContext);

  const handleOpenShippingForm = () => {
    setShippingForm(() => ({ activeIndex: null, state: true }));
  };

  const handleCloseShippingForm = () => {
    setShippingForm((prev) => ({ ...prev, state: false }));
  };

  const handleAddShipping = (data) => {
    if (fields.length === 0) {
      setValue('primaryShipping', 0);
      append({ ...data, isPrimary: true });
    } else {
      append({ ...data, isPrimary: false });
    }
  };

  const handleUpdateShipping = (index, data) => {
    setValue(`shippingInfo.${index}`, data, { shouldDirty: true });
  };

  // remove primary from current and change to the new one
  const handleSetPrimary = (index) => {
    const primaryShipping = getValues('primaryShipping');
    const shippingInfo = getValues('shippingInfo');
    shippingInfo[primaryShipping].isPrimary = false;
    shippingInfo[index].isPrimary = true;
    setValue('primaryShipping', index);
    setValue('shippingInfo', shippingInfo, { shouldDirty: true });
  };

  const handleOpenEditShipping = (index) => {
    setShippingForm({ state: true, activeIndex: index });
  };

  const handleDeleteShipping = (index) => {
    showDeletePrompt({ handleConfirm: () => onConfirmDelete(index) });
  };

  const onConfirmDelete = (index) => {
    remove(index);
    closeDeletePrompt();
  };

  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
      <Grid container className='container' spacing={3}>
        <Grid item xs={12}>
          <Typography variant='h6'>{t('information')}</Typography>
        </Grid>
        <Grid item xs={12} sm={6}>
          <LabelInput
            component={RHFPhoneInput}
            name='phone'
            label={t('phone')}
            required
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <LabelInput
            component={RHFTextInput}
            name='email'
            label={t('email')}
            type='email'
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <LabelInput
            component={RHFTextInput}
            name='firstname'
            label={t('firstname')}
            required
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <LabelInput
            component={RHFTextInput}
            name='lastname'
            label={t('lastname')}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <Controller
            control={control}
            name='gender'
            defaultValue={'none'}
            render={({ field: { value, onChange } }) => {
              return (
                <InputSelect
                  menu={[
                    { value: 'none', label: 'None' },
                    { value: 'male', label: 'Male' },
                    { value: 'female', label: 'Female' },
                  ]}
                  label={t('gender')}
                  value={value}
                  onChange={onChange}
                />
              );
            }}
          ></Controller>
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextInputContainer label={t('date-of-birth')}>
            <Controller
              control={control}
              name='dateOfBirth'
              defaultValue={null}
              render={({ field: { value, onChange } }) => {
                return (
                  <DatePicker
                    initialFocusedDate={new Date('2000')}
                    autoOk
                    placeholder={t('date-of-birth')}
                    variant='inline'
                    inputVariant='outlined'
                    size='small'
                    format='dd/MM/yyyy'
                    margin='normal'
                    value={value}
                    onChange={onChange}
                  />
                );
              }}
            ></Controller>
          </TextInputContainer>
        </Grid>
        <Grid item xs={12}>
          <Typography variant='h6'>{t('shipping-address')}</Typography>
        </Grid>
        <Grid item xs={12}>
          <AddressList
            data={fields}
            onSetPrimary={handleSetPrimary}
            onEdit={handleOpenEditShipping}
            onDelete={handleDeleteShipping}
          />
        </Grid>
        <Grid item xs={12}>
          <Button
            startIcon={<AddCircleIcon />}
            onClick={handleOpenShippingForm}
            color='primary'
            size='small'
          >
            Add Shipping Address
          </Button>
        </Grid>
        <ShippingAddressForm
          open={shippingForm.state}
          activeIndex={shippingForm.activeIndex}
          shippingAddress={fields}
          onAdd={handleAddShipping}
          onUpdate={handleUpdateShipping}
          onClose={handleCloseShippingForm}
        />
      </Grid>
    </MuiPickersUtilsProvider>
  );
};

export default CustomerInfo;
