import React, { useCallback, memo } from 'react';
import { Avatar, Button, makeStyles, Typography } from '@material-ui/core';
import PhotoIcon from '@material-ui/icons/Photo';
import { useDropzone } from 'react-dropzone';
import ImagePreview from './image-preview';

const useStyles = makeStyles((theme) => ({
  root: {
    outline: 'none',
    padding: '10px 0',
    position: 'relative',
    height: '250px',
    backgroundColor: theme.palette.primary.lighter,
    backgroundImage:
      "url(\"data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' stroke='%23098092FF' stroke-width='4' stroke-dasharray='12' stroke-dashoffset='0' stroke-linecap='square'/%3e%3c/svg%3e\")",
  },
  container: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    textAlign: 'center',
    height: '100%',
    '& .MuiAvatar-root': {
      '& svg': {
        color: '#6a969e',
        fontSize: '100px',
      },
    },
    '& button': {
      margin: '5px 0',
    },
  },
  featureImage: {
    width: '100px',
    height: '100px',
    backgroundColor: 'transparent',
    '& img': {
      objectFit: 'contain',
    },
    transition: '0.2s ease-in',
  },
  overlay: {
    top: 0,
    backgroundColor: theme.palette.primary.lighter,
    position: 'absolute',
    width: '100%',
    height: '100%',
    zIndex: 3,
  },
}));

const ImageUpload = ({ images, setImages }) => {
  const classes = useStyles({ existed: images.length > 0 });

  const onDrop = useCallback(
    async (acceptedFiles) => {
      if (acceptedFiles.length > 0) {
        let newImage = acceptedFiles.filter((file) => {
          if (images.length > 0) {
            return !images.find(
              (image) => JSON.stringify(image) === JSON.stringify(file)
            );
          }
          return file;
        });

        // pre convert to ObjectURL to prevent lagging
        newImage = newImage.map((file) => ({
          file: file,
          ObjectURL: URL.createObjectURL(file),
        }));

        setImages((prev) => ({
          ...prev,
          images: [...prev.images, ...newImage],
        }));
      }
    },
    [images]
  );

  const onSortEnd = ({ oldIndex, newIndex }) => {
    const newImages = [...images];
    arrayMove(newImages, oldIndex, newIndex);
    setImages((prev) => ({
      ...prev,
      images: newImages,
    }));
  };

  const handleRemove = (index) => {
    const remove = [];
    if ('thumbnail' in images[index]) {
      remove.push(images[index]);
    }
    const filter = [...images];
    filter.splice(index, 1);
    setImages((prev) => ({
      remove: [...prev.remove, ...remove],
      images: filter,
    }));
  };

  const { getRootProps, getInputProps, isDragActive, open } = useDropzone({
    onDrop,
    noClick: true,
    accept: 'image/*',
    maxFiles: 15,
  });
  console.log(images);
  return (
    <div {...getRootProps()} className={classes.root}>
      <input {...getInputProps()} />
      <div className={classes.container}>
        {!images?.length > 0 && (
          <Avatar variant='square' className={classes.featureImage}>
            <PhotoIcon color='primary' />
          </Avatar>
        )}
        <ImagePreview
          images={images}
          handleRemove={handleRemove}
          onSortEnd={onSortEnd}
        />
        <Typography variant='caption'>Drag image(s) here</Typography>
        <Typography variant='caption'>or</Typography>
        <Button
          onClick={open}
          disableElevation
          size='small'
          variant='contained'
          color='primary'
        >
          Browse image
        </Button>
        <Typography variant='caption' gutterBottom>
          You can add up to 15 images, each not exceeding 5MB
        </Typography>
      </div>
      {isDragActive && <div className={classes.overlay}></div>}
    </div>
  );
};

function arrayMove(arr, fromIndex, toIndex) {
  const element = arr[fromIndex];
  arr.splice(fromIndex, 1);
  arr.splice(toIndex, 0, element);
}

export default ImageUpload;
