import React, { useState, useContext } from 'react';
import TableX from '../../components/tableX';
import TitleBar from '../../components/title-bar';
import ActionButton from '../../components/action-button';
import { Grid, Paper } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import SelectPopUp from '../../components/select-popup';
import config from '../../components/tableX/config';
import reduceArrayString from '../../util/reduce-array-string';
import axios from 'axios';
import { UtilityContext } from '../../components/context-provider/utilty-context';
import { UserContext } from '../../components/context-provider/user-context';

const VendorPage = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const { showDeletePrompt, closeDeletePrompt, showSnackbar } =
    useContext(UtilityContext);
  const { checkAccess } = useContext(UserContext);
  const [triggerFetch, setTriggerFetch] = useState(false);

  const handleDelete = (data) =>
    showDeletePrompt({
      handleConfirm: () => onDelete(data),
    });

  const handleEdit = (data, e) => {
    const location = `/dashboard/vendor/edit/${data._id}`;
    if (e?.ctrlKey) {
      window.open(location, '_blank');
    } else {
      history.push(location);
    }
  };

  const handleCreate = () => history.push('/dashboard/vendor/create');

  const onDelete = (items) => {
    const ids = reduceArrayString(items);
    axios
      .delete(`${process.env.REACT_APP_API}/api/v1/suppliers?${ids}`)
      .then(({ data }) => {
        showSnackbar({ message: data?.message });
        setTriggerFetch((prev) => !prev);
      })
      .catch(({ response }) => {
        showSnackbar({ message: response?.data?.message });
      })
      .finally(() => closeDeletePrompt());
  };

  const tableSchema = [
    { id: 'name', label: t('name') },
    { id: 'phone', label: t('phone') },
    { id: 'email', label: t('email') },
    { id: 'address', label: t('address') },
    {
      id: 'action',
      sort: false,
      label: t('action'),
      type: 'component',
      components: [
        {
          name: ActionButton,
          props: {
            variant: 'edit',
            hidden: !checkAccess({ feature: 'supplier', action: 'update' }),
          },
          onClick: handleEdit,
        },
        {
          name: ActionButton,
          props: {
            variant: 'delete',
            hidden: !checkAccess({ feature: 'supplier', action: 'delete' }),
          },
          onClick: handleDelete,
        },
      ],
    },
  ];

  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <TitleBar
          onClick={handleCreate}
          buttonText={t('create-vendor')}
          disableButton={
            !checkAccess({ feature: 'supplier', action: 'create' })
          }
        ></TitleBar>
      </Grid>
      <Grid item xs={12}>
        <Paper className='tableX-container'>
          <TableX
            onRowClick={handleEdit}
            triggerFetch={triggerFetch}
            render={(data) => {
              return (
                <SelectPopUp
                  open={data.length}
                  select={data}
                  handleDelete={() => handleDelete(data)}
                />
              );
            }}
            config={tableConfig}
            schema={tableSchema}
          ></TableX>
        </Paper>
      </Grid>
    </Grid>
  );
};

const tableConfig = {
  ...config,
  url: `${process.env.REACT_APP_API}/api/v1/suppliers/all`,
  dataConfig: ['suppliers'],
  defaultSortBy: 'companyName',
};

export default VendorPage;
