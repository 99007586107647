// allow status for action

//create manually
const manual = {
  edit: ['pending'],
  complete: ['packed'],
  pack: ['pending'],
  cancel: ['pending', 'packed'],
};

// create auto
const auto = {
  edit: ['pending'],
  complete: ['pending'],
  cancel: ['pending'],
};

const allowStatus = { manual, auto };
export default allowStatus;
