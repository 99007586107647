import React, { useEffect, useState, useContext } from 'react';
import { Grid, makeStyles, Paper } from '@material-ui/core';
import TitleBar from '../../../components/title-bar';
import FloatingBar from '../../../components/floating-bar';
import { useParams, useHistory } from 'react-router-dom';
import { useForm, FormProvider, Controller } from 'react-hook-form';
import axios from 'axios';
import PurchaseOrderInformation from './information';
import Products from './product';
import generateKey from '../../../util/generate-keys';
import { UserContext } from '../../../components/context-provider/user-context';
import { UtilityContext } from '../../../components/context-provider/utilty-context';
import DateFnsUtils from '@date-io/date-fns';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import LabelInput from '../../../components/label-input';
import { RHFTextInput } from '../../../components/rhf-controlled-input';
import { useTranslation } from 'react-i18next';
import PurchaseOrderTotal from './total';
import CostLine from './cost-line';
import FileUpload from './file-upload';
import Spinner, { useSpinner } from 'src/components/spinner';

const useStyles = makeStyles(() => ({
  root: {
    marginBottom: '150px',
    flexDirection: 'column',
    position: 'relative',
  },
}));

const PurchaseOrderFormPage = () => {
  const { t } = useTranslation();
  const classes = useStyles();
  const history = useHistory();
  const { id } = useParams();
  const { showSnackbar } = useContext(UtilityContext);

  const { spinnerState, openSpinner, closeSpinner } = useSpinner();

  const methods = useForm({
    defaultValues: {
      product: [],
      fee: {
        shipping: 0,
        other: 0,
      },
      files: {
        files: [],
        remove: [],
      },
      costLines: [{}],
    },
    shouldUnregister: true,
  });

  const [state, setState] = useState('create');
  // eslint-disable-next-line no-unused-vars
  const [loading, setLoading] = useState(true);
  const { me } = useContext(UserContext);

  const [purchaseData, setPurchaseData] = useState(null);

  useEffect(() => {
    if (id) {
      openSpinner({ title: t('loading') });
      setState('edit');
      setLoading(true);
      axios
        .get(
          `${process.env.REACT_APP_API}/api/v1/purchase-orders/getById/${id}`
        )
        .then(({ data }) => {
          if (typeof data === 'object') {
            const { PurchaseOrder } = data;
            const { product, costLines } = PurchaseOrder;

            if (costLines.length > 0) {
              PurchaseOrder.createCostLine = true;
            }

            costLines.push({ qty: 1, vendor: null });
            product.push([{ qty: 1, tax: 10 }]);
            PurchaseOrder.files = { files: PurchaseOrder.files, remove: [] };
            setPurchaseData({ ...PurchaseOrder, product, costLines });
            setLoading(false);
          } else {
            console.log('error reponse');
          }
        })
        .catch(({ response }) => {
          showSnackbar({
            message: response?.data?.message,
            variant: 'error',
          });
        })
        .finally(() => {
          closeSpinner();
        });
    } else {
      setState('create');
    }
  }, []);

  useEffect(() => {
    if (purchaseData) {
      methods.reset(purchaseData);
    }
  }, [purchaseData]);

  const onSubmit = async (data) => {
    openSpinner({ title: t('saving') });
    const warehouseId = me.warehouse ? me.warehouse.id : 'undefined';

    const { files, vendor, product, costLines, createCostLine, ...other } =
      data;

    const updateData = {
      files,
      vendor: { id: vendor._id || vendor.id._id, name: vendor.name },
      ...other,
    };

    let uploadObject = {
      ContentTypes: [],
      Keys: [],
      Files: [],
      files: [],
    };

    const updateProducts = product.reduce((acc, curr) => {
      const variantId = curr?.variant?.id || curr?.variant?._id;
      if (variantId) {
        acc.push({ ...curr, variant: variantId });
      }
      return acc;
    }, []);

    updateData.product = updateProducts;

    if (createCostLine) {
      const updateCostline = costLines.reduce((acc, curr) => {
        const { vendor, ...other } = curr;
        if (vendor) {
          acc.push({
            ...other,
            vendor: {
              id: vendor._id || vendor.id,
              name: vendor.name,
            },
          });
        }
        return acc;
      }, []);
      updateData.costLines = updateCostline;
    }

    files?.files?.map(async (item) => {
      if (!('key' in item)) {
        const fileType = item.type;
        const fileExtension = item.type.split('/')[1];
        // generate 2 keys (one image one thumbnail)
        uploadObject.ContentTypes.push(fileType);
        const Key = `private/${warehouseId}/purchase/${generateKey()}`;
        const fileKey = `${Key}.${fileExtension}`;

        uploadObject.Keys.push(fileKey);
        // compress image
        uploadObject.Files.push(item);
        uploadObject.files.push({
          name: item.name,
          key: `${fileKey}`,
          mimeType: fileType,
        });
      } else {
        uploadObject.files.push(item);
      }
    });
    updateData.files = uploadObject.files;

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API}/api/v1/aws-s3/file-upload-url`,
        {
          ContentTypes: uploadObject.ContentTypes,
          Keys: uploadObject.Keys,
        }
      );
      // upload to s3
      await Promise.all(
        response.data.map(async (item, index) => {
          await axios.put(item.uploadURL, uploadObject.Files[index], {
            headers: {
              'Content-Type': item.type,
              'Content-Encoding': 'base64',
            },
            withCredentials: false,
          });
        })
      );
    } catch (err) {
      showSnackbar({
        message: 'upload-file-failed',
        variant: 'error',
      });
      console.error(err);
      closeSpinner();
      return;
    }

    switch (state) {
      case 'create':
        axios
          .post(
            `${process.env.REACT_APP_API}/api/v1/purchase-orders`,
            updateData
          )
          .then(({ data }) => {
            showSnackbar({ message: data?.message });
            history.push('/dashboard/purchase');
          })
          .catch(({ response }) => {
            showSnackbar({
              message: response?.data?.message,
              variant: 'error',
            });
          })
          .finally(() => {
            closeSpinner();
          });
        break;
      case 'edit':
        axios
          .put(
            `${process.env.REACT_APP_API}/api/v1/purchase-orders/update/${id}`,
            updateData
          )
          .then(({ data }) => {
            showSnackbar({ message: data?.message });
            history.push('/dashboard/purchase');
          })
          .catch(({ response }) => {
            showSnackbar({
              message: response?.data?.message,
              variant: 'error',
            });
          })
          .finally(() => {
            closeSpinner();
          });
        break;
      default:
        console.log('Error state');
    }
  };

  const handleCancel = () => {
    goBackToList();
  };

  const goBackToList = () => {
    history.push('/dashboard/purchase');
  };

  return (
    <FormProvider {...methods}>
      <MuiPickersUtilsProvider utils={DateFnsUtils}>
        <form id='purchase-form' onSubmit={methods.handleSubmit(onSubmit)}>
          <Grid container className={classes.root} spacing={3}>
            <Grid item>
              <TitleBar disableButton />
            </Grid>
            <Grid item>
              <Paper className='container'>
                <PurchaseOrderInformation />
              </Paper>
            </Grid>
            <Grid item>
              <Paper className='container'>
                <Grid container spacing={3}>
                  <Grid item xs={12}>
                    <Products />
                  </Grid>
                  <Grid item xs={12}>
                    <CostLine />
                  </Grid>
                  <Grid item xs={12}>
                    <Grid container justify='space-between'>
                      <Grid item xs={6}>
                        <Grid container spacing={2}>
                          <Grid item xs={12}>
                            <LabelInput
                              component={RHFTextInput}
                              name='note'
                              label={t('note')}
                              multiline
                              rows={10}
                            />
                          </Grid>
                          <Grid item xs={12}>
                            <Controller
                              name={'files'}
                              control={methods.control}
                              render={({ field: { value, onChange } }) => {
                                return (
                                  <FileUpload
                                    files={value}
                                    setFiles={onChange}
                                  />
                                );
                              }}
                            />
                          </Grid>
                        </Grid>
                      </Grid>
                      <Grid item xs={4}>
                        <PurchaseOrderTotal />
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Paper>
            </Grid>
            <FloatingBar
              onCancel={handleCancel}
              confirmButtonProps={{ form: 'purchase-form' }}
              confirmButtonText={
                state === 'create' ? 'create-purchase' : 'save-changes'
              }
            />
          </Grid>
        </form>
        <Spinner state={spinnerState} />
      </MuiPickersUtilsProvider>
    </FormProvider>
  );
};

export default PurchaseOrderFormPage;
