import React from 'react';
import TotalDisplay from '../../../../components/total-display';
import { useFormContext } from 'react-hook-form';
import calculateDiscount from 'src/util/calculate-discount';

const PurchaseOrderTotal = () => {
  const { watch } = useFormContext();
  const product = watch('items', []);

  let subtotal = 0;

  if (product?.length > 0) {
    ({ subtotal } = product?.reduce(
      (acc, curr) => {
        const totalAmount = (curr.qty || 1) * (curr.costPrice || 0);
        const totalAmountAfterDiscount = calculateDiscount(
          totalAmount,
          curr.discount
        );
        acc.subtotal += totalAmountAfterDiscount;
        // acc.productTaxTotal += get.qty * get.costPrice * (get.tax / 100);
        return acc;
      },
      { subtotal: 0, productTaxTotal: 0 }
    ));
  }

  return (
    <TotalDisplay
      disableShippingFee
      disableCosting
      disableTax
      subtotal={subtotal}
    />
  );
};

export default PurchaseOrderTotal;
