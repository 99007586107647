import React from 'react';
import { Redirect, Route } from 'react-router-dom';
import Cookies from 'js-cookie';

const RouteX = ({ component: Component, redirect, ...rest }) => (
  <Route
    {...rest}
    render={(props) =>
      Cookies.get('user') === 'true' ? (
        <Redirect to={redirect} />
      ) : (
        <Component {...props} />
      )
    }
  />
);

export default RouteX;
