import React from 'react';
import {
  AppBar,
  IconButton,
  makeStyles,
  Toolbar,
  Box,
} from '@material-ui/core';
import { IoMenu } from 'react-icons/io5';
import Profile from './profile';
// import LanguageSwitcher from "../langugage-switcher";

const useStyles = makeStyles((theme) => ({
  root: {
    background: 'white',
    color: 'black',
  },
  toolbar: {
    ...theme.mixins.toolbar,
    display: 'flex',
    justifyContent: 'space-between',
  },
}));

const Navbar = ({ handleOpen }) => {
  const classes = useStyles();

  return (
    <AppBar position="fixed" className={classes.root} elevation={1}>
      <Toolbar className={classes.toolbar}>
        <IconButton onClick={handleOpen}>
          <IoMenu />
        </IconButton>
        <Box display="flex" alignItems="center">
          {/* <IconButton>
            <NotificationsNoneIcon />
          </IconButton> */}
          <Profile />
          {/* <LanguageSwitcher /> */}
        </Box>
      </Toolbar>
    </AppBar>
  );
};

export default Navbar;
