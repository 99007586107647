import React, { useState, useCallback, useContext } from 'react';
import { Box, Button, Grid, Paper, Tab, makeStyles } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { DateRangePicker } from 'react-dates';
import clsx from 'clsx';
import moment from 'moment';
import TableX from '../../components/tableX';
import TitleBar from '../../components/title-bar';
import ActionButton from '../../components/action-button';
import SelectPopUp from '../../components/select-popup';
import config from '../../components/tableX/config';
import Status from '../../components/status';
import DateFormat from '../../components/date-format';
import NumberFormat from '../../components/number-format';
import OrderDetailPopup from './detail-popup';
import filterStatus from './filter-status';
import disableEditStatus from './disable-edit-status';
import TabContainer from '../../components/tabs-container';
import { UserContext } from '../../components/context-provider/user-context';
import disablePaymentStatus from './disable-payment-status';
import { formatUTCDate } from 'src/util/date-format';

const useStyles = makeStyles(() => ({
  tableContainer: {
    padding: '30px 30px 50px 30px',
  },
}));

const SaleOrderPage = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const [value, setValue] = useState(0);
  const classes = useStyles();
  const { checkAccess } = useContext(UserContext);
  const [triggerFetch, setTriggerFetch] = useState(false);
  const [orderDetail, setOrderDetail] = useState({ open: false });
  const [date, setDate] = useState({
    startDate: moment().subtract(3, 'month'),
    endDate: moment(),
    focusedInput: false,
  });

  const handleShowDetail = (data) => {
    setOrderDetail({ open: true, data });
  };

  const handleCloseDetail = () => {
    setOrderDetail({ open: false, data: {} });
  };

  const handleEdit = (data) =>
    history.push(`/dashboard/sale-invoice/edit/${data._id}`);

  const handleDelete = () => {
    alert('Under Development');
    // showDeletePrompt({
    //   handleConfirm: () => onDelete(data),
    // });
  };

  const handleCreate = () => history.push('/dashboard/sale-invoice/create');

  const handleTriggerFetch = () => {
    setTriggerFetch((prev) => !prev);
  };

  // const onDelete = (items) => {
  //   const ids = reduceArrayString(items);
  //   axios
  //     .delete(`${process.env.REACT_APP_API}/api/v1/suppliers?id=${ids}`)
  //     .then(() => {
  //       console.log('delete success');
  //       setTriggerFetch((prev) => !prev);
  //     })
  //     .catch(() => console.log('delete failed'))
  //     .finally(() => closeDeletePrompt());
  // };

  const tableSchema = [
    {
      id: 'invoiceNumber.value',
      label: t('invoice-number'),
      type: 'action',
      dataConfig: ['invoiceNumber', 'value'],
      onClick: handleShowDetail,
    },
    {
      id: 'customer.name',
      label: t('customer-name'),
      dataConfig: ['customer', 'name'],
    },
    {
      id: 'total',
      label: t('amount'),
      type: 'component',
      dataConfig: [],
      components: (data) => <NumberFormat value={data.total} />,
    },
    {
      id: 'invoiceDate',
      label: t('invoice-date'),
      type: 'component',
      components: [
        {
          name: DateFormat,
          customValueProp: 'value',
        },
      ],
    },
    {
      id: 'currentStatus.order',
      dataConfig: ['currentStatus', 'name'],
      label: t('status'),
      type: 'component',
      components: [
        {
          name: Status,
          customValueProp: 'status',
        },
      ],
    },
    {
      id: 'paymentStatus.order',
      label: t('payment-status'),
      type: 'component',
      dataConfig: [],
      components: ({ currentStatus, paymentStatus }) => {
        if (disablePaymentStatus.includes(currentStatus?.name)) return <>-</>;
        return <Status status={paymentStatus?.name} />;
      },
    },
    {
      id: 'action',
      sort: false,
      label: t('action'),
      type: 'component',
      dataConfig: [],
      components: ({ currentStatus, paymentStatus, ...other }) => {
        if (
          disableEditStatus.includes(currentStatus?.name) ||
          disableEditStatus.includes(paymentStatus?.name)
        ) {
          return <>-</>;
        }
        return (
          <ActionButton variant='edit' onClick={() => handleEdit(other)} />
        );
      },
    },
  ];

  const defaultFilter = useCallback(
    () =>
      value > 0
        ? { 'currentStatus.name': filterStatus[value], pos_exists: false }
        : { pos_exists: false },
    [value]
  );

  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <TitleBar
          onClick={handleCreate}
          buttonText={t('create-invoice')}
          disableButton={
            // always disable for now force user to create from pos or website
            true
            // !checkAccess({ feature: 'orders', action: 'create' })
          }
        ></TitleBar>
      </Grid>
      <Grid item xs={12}>
        <Paper>
          <TabContainer
            value={value}
            onChange={(e, newValue) => setValue(newValue)}
          >
            {filterStatus.map((item, index) => (
              <Tab label={t(item)} key={index}></Tab>
            ))}
          </TabContainer>
          <Box className={classes.tableContainer}>
            <TableX
              triggerFetch={triggerFetch}
              render={(data) => (
                <SelectPopUp
                  open={!!data.length}
                  select={data}
                  handleDelete={() => handleDelete(data)}
                />
              )}
              select={false}
              onRowClick={handleShowDetail}
              config={tableConfig}
              defaultFilter={defaultFilter}
              schema={tableSchema}
              renderFilterInput={({ setDateRange, dateRange, dataList }) => {
                const handleDateChange = ({ startDate, endDate }) => {
                  setDate((prev) => ({ ...prev, startDate, endDate }));
                  if (startDate && endDate) {
                    setDateRange({
                      from: formatUTCDate(startDate),
                      to: formatUTCDate(endDate),
                    });
                  } else if (startDate === null && endDate === null) {
                    setDateRange({
                      from: null,
                      to: null,
                    });
                  }
                };

                return (
                  <Grid container spacing={1}>
                    <Grid item>
                      <DateRangePicker
                        showClearDates
                        hideKeyboardShortcutsPanel
                        isOutsideRange={() => false}
                        startDateId='start_date' // PropTypes.string.isRequired,
                        endDateId='end_date' // PropTypes.string.isRequired,
                        startDate={date.startDate}
                        endDate={date.endDate}
                        onDatesChange={handleDateChange}
                        focusedInput={date.focusedInput}
                        onFocusChange={(focusedInput) => {
                          setDate((prev) => ({ ...prev, focusedInput }));
                        }}
                      />
                    </Grid>
                    <Grid item>
                      <Button
                        className={clsx({
                          hidden: dataList.length === 0 || value > 0,
                        })}
                        variant='contained'
                        disableElevation
                        components='a'
                        download
                        href={`${
                          process.env.REACT_APP_API
                        }/api/v1/orders/export?filter=pos_exists::false&${
                          dateRange.from
                            ? `fromDate=${dateRange.from}&toDate=${dateRange.to}`
                            : ''
                        }`}
                      >
                        {t('export')}
                      </Button>
                    </Grid>
                  </Grid>
                );
              }}
            ></TableX>
          </Box>
        </Paper>
      </Grid>
      <OrderDetailPopup
        handleRefreshTable={handleTriggerFetch}
        state={orderDetail}
        onClose={handleCloseDetail}
      />
    </Grid>
  );
};

const tableConfig = {
  ...config,
  url: `${process.env.REACT_APP_API}/api/v1/orders`,
  initialDate: {
    from: formatUTCDate(moment().subtract(3, 'month')),
    to: formatUTCDate(moment()),
  },
  defaultSort: { invoiceDate: 'desc' },
  dataConfig: ['orders'],
};

export default SaleOrderPage;
