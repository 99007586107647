import { Box, Tabs, makeStyles } from '@material-ui/core';
import React from 'react';

const useStyles = makeStyles((theme) => ({
  tabsContainer: {
    backgroundColor: theme.palette.primary.light,
    borderTopLeftRadius: '5px',
    borderTopRightRadius: '5px',
  },
  tabs: {
    '& .MuiTab-textColorInherit': {
      color: 'white',
    },
    '& .Mui-selected': {
      backgroundColor: theme.palette.primary.main,
      color: 'white',
      boxShadow: theme.shadows[1],
    },
    '& .MuiTab-root': {
      '& span': {
        fontWeight: 'bold',
      },
    },
    '& .MuiTab-root:hover': {
      opacity: 1,
      color: 'white',
      backgroundColor: theme.palette.primary.main,
      boxShadow: theme.shadows[1],
    },
    '& .MuiTabs-indicator	': {
      display: 'none',
    },
  },
}));

const TabContainer = ({ children, ...other }) => {
  const classes = useStyles();
  return (
    <Box className={classes.tabsContainer}>
      <Tabs
        className={classes.tabs}
        variant='scrollable'
        textColor='inherit'
        indicatorColor='primary'
        {...other}
      >
        {children}
      </Tabs>
    </Box>
  );
};

export default TabContainer;
