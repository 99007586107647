import React, { useEffect, useState, useContext } from 'react';
import { Grid, makeStyles, Paper } from '@material-ui/core';
import TitleBar from '../../../components/title-bar';
import FloatingBar from '../../../components/floating-bar';
import { useParams, useHistory } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import axios from 'axios';
import ContactInfo from './contact-info';
import CompanyInfo from './company-info';
import { UtilityContext } from '../../../components/context-provider/utilty-context';
import Spinner, { useSpinner } from 'src/components/spinner';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles(() => ({
  root: {
    marginBottom: '150px',
    flexDirection: 'column',
    position: 'relative',
  },
}));

const AddEditVendorPage = () => {
  const classes = useStyles();
  const history = useHistory();
  const { id } = useParams();
  const { showSnackbar } = useContext(UtilityContext);
  const { register, errors, handleSubmit, control, reset, getValues } = useForm(
    {
      shouldUnregister: true,
    }
  );
  const { t } = useTranslation();
  const [state, setState] = useState('create');

  const { spinnerState, openSpinner, closeSpinner } = useSpinner();

  useEffect(() => {
    if (id) {
      openSpinner({ title: t('loading') });
      setState('edit');
      axios
        .get(`${process.env.REACT_APP_API}/api/v1/suppliers/${id}`)
        .then(({ data }) => {
          if (typeof data === 'object') {
            const resetData = {
              ...data,
            };
            if (data.contactInfo.length === 0) {
              resetData.contactInfo = [
                { fullname: '', phone: '', email: '', position: '' },
              ];
            }
            reset(resetData);
          } else {
            console.log('error reponse');
          }
        })
        .catch(({ response }) => {
          showSnackbar({
            message: response?.data?.message,
            variant: 'error',
          });
        })
        .finally(() => {
          closeSpinner();
        });
    } else {
      setState('create');
    }
  }, []);

  const onSubmit = (data) => {
    openSpinner({ title: t('saving') });
    // remove contact field that don't have value name
    const filterContacts = data.contactInfo
      ? data.contactInfo.filter((contact) => contact.fullname.trim())
      : [];

    const updateData = { ...data, contactInfo: filterContacts };

    switch (state) {
      case 'create':
        axios
          .post(`${process.env.REACT_APP_API}/api/v1/suppliers`, updateData)
          .then(({ data }) => {
            showSnackbar({ message: data?.message });
            goBackToList();
          })
          .catch(({ response }) => {
            showSnackbar({
              message: response?.data?.message,
              variant: 'error',
            });
          })
          .finally(() => {
            closeSpinner();
          });
        break;
      case 'edit':
        axios
          .put(
            `${process.env.REACT_APP_API}/api/v1/suppliers/${id}`,
            updateData
          )
          .then(({ data }) => {
            showSnackbar({ message: data?.message });
            goBackToList();
          })
          .catch(({ response }) => {
            showSnackbar({
              message: response?.data?.message,
              variant: 'error',
            });
          })
          .finally(() => {
            closeSpinner();
          });
        break;
      default:
        console.log('Error state');
    }
  };

  const handleCancel = () => {
    goBackToList();
  };

  const goBackToList = () => {
    history.push('/dashboard/vendor');
  };

  return (
    <form id='vendor-form' onSubmit={handleSubmit(onSubmit)}>
      <Grid container className={classes.root} spacing={3}>
        <Grid item>
          <TitleBar disableButton />
        </Grid>
        <Grid item>
          <Paper>
            <Grid container>
              <Grid item xs={12}>
                <CompanyInfo
                  register={register}
                  control={control}
                  errors={errors}
                />
              </Grid>
              <Grid item xs={12}>
                <ContactInfo
                  register={register}
                  control={control}
                  getValues={getValues}
                />
              </Grid>
            </Grid>
          </Paper>
        </Grid>
        <Spinner state={spinnerState} />
        <FloatingBar
          onCancel={handleCancel}
          confirmButtonProps={{ form: 'vendor-form' }}
          confirmButtonText={
            state === 'create' ? 'create-vendor' : 'save-changes'
          }
        />
      </Grid>
    </form>
  );
};

export default AddEditVendorPage;
