import { Chip, makeStyles } from '@material-ui/core';
import React from 'react';
import { useTranslation } from 'react-i18next';
import style from './status-style';

const useStyles = makeStyles(() => ({
  root: {
    minWidth: '88.42px',
    '& span': {
      textTransform: 'capitalize',
    },
  },
}));

const Status = ({ status, size = 'small' }) => {
  const { t } = useTranslation();
  const classes = useStyles();

  return typeof status === 'string' ? (
    <Chip
      size={size}
      className={classes.root}
      style={style[status.toLowerCase()]}
      label={t(status)}
    />
  ) : (
    <Chip
      size={size}
      className={classes.root}
      style={
        status?.length
          ? style[status[status.length - 1].name.toLowerCase()] ?? style.default
          : {}
      }
      label={t(status?.length && status[status.length - 1].name)}
    />
  );
};

export default Status;
