import { Box, Typography, makeStyles } from '@material-ui/core';
import React from 'react';
import Barcode from 'react-barcode';

const useStyles = makeStyles(() => ({
  root: {
    '& *': {
      fontFamily: 'Arial , Siemreap',
    },
    '@media print': {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      height: '90px',
      width: '150px',
      flexDirection: 'column',
      position: 'relative',
      overflow: 'hidden',
    },
    display: 'flex',
    justifyContent: 'center',
    height: '90px',
    width: '200px',
    flexDirection: 'column',
  },
  smallText: {
    '@media print': {
      fontSize: '8px',
      textAlign: 'center',
    },
    fontSize: '14px',
  },
  barcode: {
    '@media print': {
      position: 'absolute',
      bottom: 0,
      marginBottom: '-8px',
    },
  },
}));

// eslint-disable-next-line react/display-name
const BarcodeContainer = React.forwardRef(({ barcode }, ref) => {
  const classes = useStyles();
  return (
    <Box ref={ref} className={classes.root}>
      <Box className={classes.barcode}>
        <Typography className={classes.smallText}>
          <b>{barcode.name}</b>
        </Typography>
        <Barcode value={barcode.id} height={30} width={1} fontSize={8} />
      </Box>
    </Box>
  );
});

export default BarcodeContainer;
