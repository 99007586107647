import { Box, Grid } from '@material-ui/core';
import React, { useState, useCallback } from 'react';
import { DateRangePicker } from 'react-dates';
import ActionButton from '../../../../components/action-button';
import { useTranslation } from 'react-i18next';
import TableX from '../../../../components/tableX';
import config from '../../../../components/tableX/config';
import SelectPopUp from '../../../../components/select-popup';
import Status from '../../../../components/status';
import disableEditStatus from '../../../sale-order/disable-edit-status';
import NumberFormat from '../../../../components/number-format';
import DateFormat from '../../../../components/date-format';
import moment from 'moment';
import { useHistory } from 'react-router-dom';
import OrderDetailPopup from '../../../sale-order/detail-popup';

const UnpaidOrders = ({ id }) => {
  const { t } = useTranslation();
  const history = useHistory();
  const [date, setDate] = useState({
    startDate: moment().subtract(3, 'month'),
    endDate: moment(),
    focusedInput: false,
  });
  const [orderDetail, setOrderDetail] = useState(false);
  const [triggerFetch, setTriggerFetch] = useState(false);

  const handleShowDetail = (data) => {
    setOrderDetail({ open: true, data });
  };

  const handleCloseDetail = () => {
    setOrderDetail({ open: false, data: {} });
  };

  const handleEdit = (data, e) => {
    const location = `/dashboard/sale-invoice/edit/${data._id}`;
    if (e?.ctrlKey) {
      window.open(location, '_blank');
    } else {
      history.push(location);
    }
  };

  const tableSchema = [
    {
      id: 'invoiceNumber.value',
      label: t('invoice-number'),
      dataConfig: ['invoiceNumber', 'value'],
      type: 'action',
      //   onClick: handleShowDetail
    },
    {
      id: 'customer.name',
      label: t('customer-name'),
      dataConfig: ['customer', 'name'],
    },
    {
      id: 'total',
      label: t('amount'),
      type: 'component',
      components: [
        {
          name: NumberFormat,
          customValueProp: 'value',
        },
      ],
    },
    {
      id: 'createdAt',
      label: t('created-date'),
      type: 'component',
      components: [
        {
          name: DateFormat,
          customValueProp: 'value',
        },
      ],
    },
    {
      id: 'currentStatus.order',
      dataConfig: ['currentStatus', 'name'],
      label: t('status'),
      type: 'component',
      components: [
        {
          name: Status,
          customValueProp: 'status',
        },
      ],
    },
    {
      id: 'paymentStatus.order',
      label: t('payment-status'),
      dataConfig: ['paymentStatus', 'name'],
      type: 'component',
      components: [
        {
          name: Status,
          customValueProp: 'status',
        },
      ],
    },
    {
      id: 'action',
      sort: false,
      label: t('action'),
      type: 'component',
      dataConfig: [],
      components: ({ currentStatus, paymentStatus, ...other }) => {
        if (
          disableEditStatus.includes(currentStatus.name) ||
          disableEditStatus.includes(paymentStatus.name)
        ) {
          return <>-</>;
        } else {
          return (
            <ActionButton variant='edit' onClick={() => handleEdit(other)} />
          );
        }
      },
    },
  ];

  const handleTriggerFetch = () => {
    setTriggerFetch((prev) => !prev);
  };

  const defaultFilter = useCallback(() => {
    return { 'customer.id': id, 'paymentStatus.name_ne': 'paid' };
  }, []);

  return (
    <Box className='tableX-container'>
      <TableX
        render={(data) => {
          return (
            <SelectPopUp
              open={!!data.length}
              select={data}
              //   handleDelete={() => handleDelete(data)}
            />
          );
        }}
        onRowClick={handleShowDetail}
        triggerFetch={triggerFetch}
        config={tableConfig}
        defaultFilter={defaultFilter}
        schema={tableSchema}
        renderFilterInput={({ setDateRange }) => {
          const handleDateChange = ({ startDate, endDate }) => {
            setDate((prev) => {
              return { ...prev, startDate, endDate };
            });
            if (startDate && endDate) {
              setDateRange({
                from: startDate?.format('YYYY-MM-DD'),
                to: endDate?.format('YYYY-MM-DD'),
              });
            } else if (startDate === null && endDate === null) {
              setDateRange({
                from: null,
                to: null,
              });
            }
          };

          return (
            <Grid container spacing={1}>
              <Grid item>
                <DateRangePicker
                  showClearDates
                  hideKeyboardShortcutsPanel
                  isOutsideRange={() => false}
                  startDateId='start_date' // PropTypes.string.isRequired,
                  endDateId='end_date' // PropTypes.string.isRequired,
                  startDate={date.startDate}
                  endDate={date.endDate}
                  onDatesChange={handleDateChange}
                  focusedInput={date.focusedInput}
                  onFocusChange={(focusedInput) =>
                    setDate((prev) => ({ ...prev, focusedInput }))
                  }
                />
              </Grid>
              {/* <Grid item>
                <Button
                  className={clsx({
                    hidden: dataList.length === 0
                  })}
                  variant="contained"
                  disableElevation
                  components="a"
                  download
                  href={`${process.env.REACT_APP_API}/api/v1/orders/export?${
                    dateRange.from
                      ? `fromDate=${dateRange.from}&toDate=${dateRange.to}`
                      : ""
                  }`}
                >
                  {t("export")}
                </Button>
              </Grid> */}
            </Grid>
          );
        }}
      ></TableX>
      <OrderDetailPopup
        handleRefreshTable={handleTriggerFetch}
        state={orderDetail}
        onClose={handleCloseDetail}
      />
    </Box>
  );
};

const tableConfig = {
  ...config,
  url: `${process.env.REACT_APP_API}/api/v1/orders`,
  initialDate: {
    from: moment().subtract(3, 'month').format('YYYY-MM-DD'),
    to: moment().format('YYYY-MM-DD'),
  },
  defaultSort: { createdAt: 'desc' },
  dataConfig: ['orders'],
};

export default UnpaidOrders;
