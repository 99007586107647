import React, { useEffect, useState, useCallback } from 'react';
import {
  FormControl,
  FormLabel,
  TextField,
  makeStyles,
  Grid,
  Typography,
} from '@material-ui/core';
import axios from 'axios';
import { Autocomplete } from '@material-ui/lab';
import debounce from 'debounce';
import LocationOnIcon from '@material-ui/icons/LocationOn';
import { nanoid } from 'nanoid';
import useDialog from 'src/hooks/useDialog';
import GoogleMapPickerDialog from '../google-map-picker.js/GoogleMapPickerDialog';
import SearchIcon from '@material-ui/icons/Search';

const useStyles = makeStyles((theme) => ({
  label: {
    color: 'black',
    fontWeight: 'bold',
    marginBottom: '10px',
    fontSize: '14px',
    textTransform: 'uppercase',
  },
  secondary: {
    color: theme.palette.secondary.main,
    fontWeight: 'bold',
    marginBottom: '10px',
    fontSize: '14px',
    textTransform: 'uppercase',
  },
  root: {
    '&:hover': {
      cursor: 'pointer',
    },
  },
}));

const GoogleMapInputSuggestion = ({
  label,
  placeholder = '',
  value,
  required,
  onChange,
  onMapPickerClick,
  ...other
}) => {
  const [list, setList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [session, setSession] = useState('');
  const { dialogState, openDialog, closeDialog } = useDialog();

  const classes = useStyles();

  useEffect(() => {
    //create session
    setSession(nanoid());
  }, []);

  //   get place autocomplete
  const search = (searchText) => {
    axios
      .get(
        `${process.env.REACT_APP_API}/api/v1/google-map/place-autocomplete?search=${searchText}&sessiontoken=${session}`
      )
      .then((response) => {
        setList(response.data?.predictions || []);
      })
      .catch((err) => console.log(err))
      .finally(() => {
        setLoading(false);
      });
  };

  //   get place detail
  const getDetail = async (placeId) => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API}/api/v1/google-map/place-detail?id=${placeId}&sessiontoken=${session}`
      );
      //   renew session
      setSession(nanoid());
      return response.data;
    } catch (err) {
      console.log(err);
      return {};
    }
  };

  const debounceSearch = useCallback(
    debounce((text) => {
      search(text);
    }, 500),
    [session]
  );

  return (
    <FormControl fullWidth>
      {label && (
        <FormLabel className={classes.label}>
          {label}
          {required ? <span className={classes.secondary}> *</span> : null}
        </FormLabel>
      )}
      <Autocomplete
        options={list}
        filterOptions={(option) => option}
        disableClearable
        value={value}
        {...other}
        getOptionLabel={(option) => {
          return (
            option?.description ??
            `${option?.location?.lat}, ${option?.location?.lng}`
          );
        }}
        onChange={async (e, value) => {
          if (value) {
            try {
              const { description, place_id } = value;
              const data = await getDetail(place_id);
              if (data?.result) {
                onChange({
                  description,
                  place_id,
                  location: data?.result?.geometry?.location,
                });
              }
            } catch (err) {
              console.log(err);
            }
          } else {
            onChange(value);
          }
        }}
        loading={loading}
        onInputChange={(event, text) => {
          if (text && event?.type === 'change') {
            setLoading(true);
            debounceSearch(text);
          } else {
            setList([]);
          }
        }}
        renderOption={({ structured_formatting }) => {
          return (
            <Grid container alignItems='center' spacing={1}>
              <Grid item>
                <LocationOnIcon className={classes.icon} />
              </Grid>
              <Grid item xs>
                <Typography>
                  <b>{structured_formatting?.main_text}</b>
                </Typography>
                <Typography variant='body2' color='textSecondary'>
                  {structured_formatting?.secondary_text}
                </Typography>
              </Grid>
            </Grid>
          );
        }}
        className={classes.root}
        renderInput={(params) => {
          return (
            <TextField
              required={required}
              {...params}
              InputProps={{
                ...params.InputProps,
                endAdornment: <SearchIcon color='disabled' />,
              }}
              variant='outlined'
              size='small'
              placeholder={label ? label.toUpperCase() : placeholder}
            ></TextField>
          );
        }}
      ></Autocomplete>
      <GoogleMapPickerDialog
        open={dialogState.open}
        onClose={closeDialog}
        defaultValue={value}
        onSave={(data) => {
          onChange({
            description: `${data?.lat}, ${data?.lng}`,
            place_id: null,
            location: data,
          });
        }}
      />
    </FormControl>
  );
};

export default GoogleMapInputSuggestion;
