import { Box, Button, Paper, Typography } from '@material-ui/core';
import clsx from 'clsx';
import React from 'react';
import AddressCard from '../address-small';

const AddressItem = ({ data, onChange, disabledSelect }) => {
  return (
    <Box clone overflow="hidden">
      <Paper elevation={2}>
        <Box
          bgcolor="primary.main"
          px={1}
          color="white"
          display="flex"
          justifyContent="space-between"
          alignItems="center"
        >
          <Box display="flex" alignItems="center" py={0.6}>
            <Box
              className={clsx({ invisible: !data.isPrimary })}
              bgcolor="white"
              clone
              borderRadius={2}
              px={1}
            >
              <Typography color="primary">
                <b>Primary</b>
              </Typography>
            </Box>
          </Box>
        </Box>
        <Box px={2} py={1}>
          <AddressCard data={data} />
        </Box>
        <Box align="right" mr={1} mb={1}>
          <Button
            disabled={disabledSelect}
            size="small"
            variant="contained"
            onClick={() => onChange(data)}
            color="primary"
          >
            {disabledSelect ? 'Selected' : 'Select'}
          </Button>
        </Box>
      </Paper>
    </Box>
  );
};

export default AddressItem;
