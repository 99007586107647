import { Grid, Paper } from '@material-ui/core';
import React, { useState, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import TableX from '../../components/tableX';
import TitleBar from '../../components/title-bar';
import StorageForm from './form';
import config from '../../components/tableX/config';
import ActionButton from '../../components/action-button';
import { UtilityContext } from '../../components/context-provider/utilty-context';
import { UserContext } from '../../components/context-provider/user-context';
import SelectPopUp from '../../components/select-popup';
import reduceArrayString from '../../util/reduce-array-string';
import axios from 'axios';
import PrintBarcode from './barcode-print';

const StoragePage = () => {
  const { t } = useTranslation();
  const { showDeletePrompt, closeDeletePrompt, showSnackbar } =
    useContext(UtilityContext);
  const { checkAccess } = useContext(UserContext);
  const [storageForm, setStorageForm] = useState(false);
  const [printBarcode, setPrintBarcode] = useState({
    state: false,
    data: null,
  });
  const [triggerFetch, setTriggerFetch] = useState(false);
  const [activeData, setActiveData] = useState(null);

  const handleOpenForm = () => {
    setActiveData(null);
    setStorageForm(true);
  };
  const handleCloseForm = () => setStorageForm(false);

  const handleEdit = (data) => {
    setActiveData(data);
    setStorageForm(true);
  };

  const handleDelete = (data) =>
    showDeletePrompt({
      handleConfirm: () => onDelete(data),
    });

  const onDelete = (items) => {
    const ids = reduceArrayString(items);
    axios
      .delete(`${process.env.REACT_APP_API}/api/v1/storages?${ids}`)
      .then(({ data }) => {
        showSnackbar({ message: data?.message });
        setTriggerFetch((prev) => !prev);
      })
      .catch(({ response }) => {
        showSnackbar({ message: response?.data?.message, variant: 'error' });
      })
      .finally(() => closeDeletePrompt());
  };

  const handleShowPrintBarcode = (data) => {
    setPrintBarcode({ state: true, data });
  };

  const handleClosePrintBarcode = () => {
    setPrintBarcode({ state: false, data: null });
  };

  const tableSchema = [
    { id: 'id', label: t('id') },
    { id: 'name', label: t('name') },
    {
      id: 'createdBy.name',
      label: t('created-by'),
      dataConfig: ['createdBy', 'name'],
    },
    {
      id: 'action',
      sort: false,
      label: t('action'),
      type: 'component',
      components: [
        {
          name: ActionButton,
          props: { variant: 'print' },
          onClick: handleShowPrintBarcode,
        },
        {
          name: ActionButton,
          props: { variant: 'edit', hidden: !checkAccess({feature: 'storage', action: 'update'}) },
          onClick: handleEdit,
        },
        {
          name: ActionButton,
          props: { variant: 'delete', hidden: !checkAccess({feature: 'storage', action: 'delete'}) },
          onClick: handleDelete,
        },
      ],
    },
  ];

  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <TitleBar
          onClick={handleOpenForm}
          buttonText={t('create-storage')}
          disableButton={!checkAccess({feature: 'storage', action: 'create'})}
        ></TitleBar>
      </Grid>
      <Grid item xs={12}>
        <Paper className="tableX-container">
          <TableX
            config={tableConfig}
            triggerFetch={triggerFetch}
            schema={tableSchema}
            onRowClick={handleEdit}
            render={(data) => {
              return (
                <SelectPopUp
                  open={data.length}
                  select={data}
                  handleDelete={() => handleDelete(data)}
                />
              );
            }}
          ></TableX>
        </Paper>
      </Grid>
      <StorageForm
        setTriggerFetch={setTriggerFetch}
        preData={activeData}
        open={storageForm}
        onClose={handleCloseForm}
      />
      <PrintBarcode
        open={printBarcode.state}
        onClose={handleClosePrintBarcode}
        active={printBarcode.data}
      />
    </Grid>
  );
};

const tableConfig = {
  ...config,
  url: `${process.env.REACT_APP_API}/api/v1/storages`,
  dataConfig: ['storages'],
};

export default StoragePage;
