import React, { useContext, useEffect, useState } from 'react';
import { Grid, Typography, makeStyles } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import TextInput from '../../../../../components/text-input';
import TextInputContainer from '../../../../../components/text-input-container';
import SKUInput from '../../../../../components/sku-input';
import { useFormContext } from 'react-hook-form';
import registerMui from '../../../../../util/react-hook-form-helper/register-mui';
import getPriceBeforeTax from '../../../../../util/getPriceBeforeTax';
import { UtilityContext } from '../../../../../components/context-provider/utilty-context';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.primary.ligther,
  },
}));

const PurchaseSaleInformation = ({ state, loading }) => {
  const classes = useStyles();
  const [sku, setSku] = useState(null);
  const { getValues, register, setValue, watch } = useFormContext();
  const watchPrice = watch('price');
  const { t } = useTranslation();
  const { showSnackbar } = useContext(UtilityContext);

  useEffect(() => {
    if (state === 'edit') {
      const value = getValues('sku');
      setSku(value);
    }
  }, [loading, state]);

  return (
    <Grid container className={classes.root} spacing={3}>
      <Grid item xs={12}>
        <Typography variant='h6'>{t('selling-information')}</Typography>
      </Grid>
      <Grid item xs={6}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <TextInput
              type='number'
              label={t('cost-price')}
              placeholder={0}
              {...registerMui(
                register('price.costPrice', { valueAsNumber: true })
              )}
            ></TextInput>
          </Grid>
          <Grid item xs={12}>
            <TextInput
              type='number'
              {...registerMui(
                register('price.salePrice', { valueAsNumber: true })
              )}
              helperText={`${t('before-tax')} : ${getPriceBeforeTax(
                watchPrice?.salePrice || 0
              )}`}
              required
              label={t('selling-price')}
            ></TextInput>
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={6}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <TextInputContainer label={t('sku')}>
              <SKUInput
                enableGenerate
                setValue={setValue}
                getData={() => {
                  const value = getValues();
                  const data = {
                    vendor: value.vendor?.name,
                    name: value.name,
                  };
                  if (data.vendor && data.name) {
                    return data;
                  }
                  showSnackbar({
                    message: 'product-sku-data-insufficient',
                    variant: 'error',
                  });
                  return null;
                }}
                defaultValue={sku}
                textField
                placeholder={t('sku')}
                size='small'
                variant='outlined'
                name='sku'
                register={register}
                fullWidth
              />
            </TextInputContainer>
          </Grid>
          <Grid item xs={6}>
            <TextInput label={t('upc')} {...registerMui(register('altCode'))} />
          </Grid>
          <Grid item xs={6}>
            <TextInput
              label={t('low-stock')}
              type='number'
              placeholder='0'
              inputProps={{ min: 0 }}
              {...registerMui(register('lowStock', { valueAsNumber: true }))}
            />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default PurchaseSaleInformation;
