const SERVER_EN = {
  // server
  'server-internal-error': 'Server internal error',
  'permission-denied': 'Permission Denied',
  'duplicated-phone-or-email': 'Email or Phone already existed',
  //login
  'login-user-not-found': "Account doesn't exist",
  'login-user-incorrect-password': 'Incorrect Password',
  'login-user-invalid-body': 'Invalid Body',
  // product
  'product-updated': 'product update successfully',
  'product-created': 'product create successfully',
  'products-deleted': 'product delete successfully',
  'failed-to-create-product': 'failed to create product',
  //   customer
  'customer-updated': 'customer update successfully',
  'customer-created': 'customer created successfully',
  'customer-deleted': 'customer deleted successfully',
  'customer-updated-failed': 'customer update failed',
  'customer-created-failed': 'customer created failed',
  'customer-deleted-failed': 'customer deleted failed',
  'customer-existed': 'Customer Existed',
  //   user
  'user-updated': 'user update successfully',
  'user-created': 'user created successfully',
  'user-deleted': 'user deleted successfully',
  'user-updated-failed': 'user update failed',
  'user-created-failed': 'user created failed',
  'user-deleted-failed': 'user deleted failed',
  'user-existed': 'User Existed',

  //   sale-order
  'order-updated': 'order update successfully',
  'order-created': 'order created successfully',
  'order-deleted': 'order deleted successfully',
  'order-updated-failed': 'order update failed',
  'failed-to-create-order': 'order created failed',
  'order-deleted-failed': 'order deleted failed',
  'product-no-stock': "don't have enough stock",
  'order-payment-created': 'payment added successfully',
  'order-payment-deleted': 'payment deleted successfully',
  'order-payment-over-amount-due': 'payment over amount due',
  'order-not-found': 'Order not found',
  'order-payment-created-failed': 'payment added failed',
  'order-status-updated': 'order status updated',
  'order-status-updated-failed': 'order status update failed',
  'status-not-found': 'order status not found',
  'order-returned-success': 'order returned successfully',
  'credit-note-delete-success': 'credit note deleted successfully',
  'order-shipping-successful': 'order shipping succesfully',
  'update-sale-by-successfully': 'update order saleby successfully',
  //
  'customer-duplicated': 'customer duplicated',
  'customer-not-found': 'customer not found',
  'customer-shipping-info-added': 'new shipping address added',
  //   vendor
  'supplier-updated': 'vendor update successfully',
  'supplier-created': 'vendor created successfully',
  'supplier-deleted': 'vendor deleted successfully',
  'supplier-updated-failed': 'vendor update failed',
  'supplier-created-failed': 'vendor created failed',
  'supplier-deleted-failed': 'vendor deleted failed',
  'supplier-duplicated': 'vendor duplicated',
  //   purchase
  'purchase-order-created': 'purchase order successfully',
  'purchase-order-created-failed': 'purchase order failed',
  'purchase-order-duplicated': 'purchase order number already exist',
  'failed-to-reconcile-stock': 'stock reconcile failed',
  'stock-reconciled': 'stock reconciled successfully',
  //   brand
  'brand-updated': 'brand update successfully',
  'brand-created': 'brand created successfully',
  'brand-deleted': 'brand deleted successfully',
  'brand-updated-failed': 'brand update failed',
  'brand-created-failed': 'brand created failed',
  'brand-deleted-failed': 'brand deleted failed',
  'brand-duplicated': 'brand duplicated',
  //   currency
  'currency-updated': 'currency update successfully',
  'currency-created': 'currency created successfully',
  'currency-deleted': 'currency deleted successfully',
  'currency-updated-failed': 'currency update failed',
  'currency-created-failed': 'currency created failed',
  'currency-deleted-failed': 'currency deleted failed',
  'currency-duplicated': 'currency duplicated',
  //   unit
  'unit-updated': 'unit update successfully',
  'unit-created': 'unit created successfully',
  'unit-deleted': 'unit deleted successfully',
  'unit-updated-failed': 'unit update failed',
  'unit-created-failed': 'unit created failed',
  'unit-deleted-failed': 'unit deleted failed',
  'unit-duplicated': 'unit duplicated',
  //   category
  'category-updated': 'category update successfully',
  'category-created': 'category created successfully',
  'category-deleted': 'category deleted successfully',
  'category-updated-failed': 'category update failed',
  'category-created-failed': 'category created failed',
  'category-deleted-failed': 'category deleted failed',
  'category-duplicated': 'category duplicated',
  //   storage
  'storage-updated': 'storage update successfully',
  'storage-created': 'storage created successfully',
  'storage-deleted': 'storage deleted successfully',
  'storage-updated-failed': 'storage update failed',
  'storage-created-failed': 'storage created failed',
  'storage-deleted-failed': 'storage deleted failed',
  'storage-duplicated': 'storage duplicated',
  'storage-existed': 'storage existed',
  // setting
  'invoice-number-smaller': 'Invoice number smaller than last issued',
  'purchaseOrder-number-smaller':
    'Purchase order number smaller than last issued',
  'automatic-sequencing-setting-updated':
    'Automatic sequencing setting updated',
  'automatic-sequencing-setting-update-failed':
    'Automatic sequencing setting update failed',
  'delivery-setting-updated': 'Delivery setting updated',
  'delivery-setting-update-failed': 'Delivery setting update failed',
};

export default SERVER_EN;
