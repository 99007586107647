import axios from 'axios';
import { useState } from 'react';

const defaultData = { data: null, params: {} };

const useQuery = (url) => {
  const [loading, setLoading] = useState(true);
  const [state, setState] = useState({
    data: defaultData.data,
    error: null,
    params: defaultData.params,
  });

  const request = async (params) => {
    try {
      setLoading(true);
      const response = await axios.get(url, { params, withCredentials: true });
      setState({
        data: response.data,
        params,
        error: null,
      });
    } catch (error) {
      setState({
        data: null,
        error,
        params,
      });
    } finally {
      setLoading(false);
    }
  };
  console.log('render');

  const query = async (arg, option) => {
    const mergeParams = option?.mergeParams ?? true;
    const updateParams = getParams(arg, mergeParams ? state.params : {});
    const clear = option?.clear ?? false;
    if (clear) setState((prev) => ({ ...prev, data: null }));
    await request(updateParams);
  };

  const startLoading = () => setLoading(true);
  const stopLoading = () => setLoading(false);

  const resetData = (data = null) => setState((prev) => ({ ...prev, data }));

  return {
    data: state.data,
    loading,
    query,
    error: state.error,
    startLoading,
    stopLoading,
    resetData,
  };
};

const getParams = (arg, previousParams) => {
  const argType = typeof arg;
  let updateParams = {};
  switch (argType) {
    case 'function': {
      const value = arg(previousParams);
      updateParams = {
        ...previousParams,
        ...(typeof value === 'object' ? value : {}),
      };
      break;
    }
    default: {
      updateParams = {
        ...previousParams,
        ...(typeof arg === 'object' ? arg : {}),
      };
    }
  }
  return updateParams;
};

export default useQuery;
