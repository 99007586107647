import {
  TableRow,
  TableCell,
  TableBody,
  Typography,
  Box,
} from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import CustomNumberFormat from 'src/components/number-format';
import plusTax from 'src/util/plusTax';
import CustomTable from 'src/components/custom-table';
import displayProductName from 'src/util/display-product-name';

const tableHeadData = [
  { value: 'no.' },
  { value: 'product-name', width: '30%' },
  { value: 'quantity' },
  { value: 'received' },
  { value: 'storage' },
  { value: 'unit-price' },
  { value: 'tax' },
  { value: 'amount', align: 'right' },
];

const Products = ({ data }) => {
  const { t } = useTranslation();

  return (
    <>
      <Box bgcolor='primary.light' color='white' p={1}>
        <Typography>
          <b>{t('items')}</b>
        </Typography>
      </Box>
      <CustomTable.Container size='small'>
        {/* table header */}
        <CustomTable.Head data={tableHeadData} />
        {/* table body */}
        <TableBody>
          {data?.product?.map((item, index) => {
            const storages = item.storages.length > 0 ? item.storages : [{}];
            return storages.map((storage, storageIndex) => {
              const isFirstIndex = storageIndex === 0;

              return (
                <TableRow key={index}>
                  <TableCell>
                    <CustomTable.CellValue>
                      {isFirstIndex && index + 1}
                    </CustomTable.CellValue>
                  </TableCell>
                  <TableCell>
                    <CustomTable.CellValue>
                      {isFirstIndex &&
                        displayProductName(
                          `${item?.variant?.altCode || item?.variant?.sku}`,
                          item?.variant?.name
                        )}
                    </CustomTable.CellValue>
                  </TableCell>
                  <TableCell>
                    <CustomTable.CellValue>
                      {isFirstIndex && item?.receivedQty}
                    </CustomTable.CellValue>
                  </TableCell>
                  <TableCell>
                    <CustomTable.CellValue>
                      {storage?.qty ? (
                        <CustomNumberFormat
                          value={storage?.qty}
                          format='normal'
                        />
                      ) : (
                        t('n/a')
                      )}
                    </CustomTable.CellValue>
                  </TableCell>
                  <TableCell>
                    <CustomTable.CellValue>
                      {storage?.storage?.name || t('n/a')}
                    </CustomTable.CellValue>
                  </TableCell>
                  <TableCell>
                    <CustomTable.CellValue align='right'>
                      <CustomNumberFormat
                        align='right'
                        value={item.costPrice}
                      />
                    </CustomTable.CellValue>
                  </TableCell>
                  <TableCell>
                    <CustomTable.CellValue align='right'>
                      <CustomNumberFormat
                        align='right'
                        format='percentage'
                        value={item.tax}
                      />
                    </CustomTable.CellValue>
                  </TableCell>
                  <TableCell>
                    <CustomTable.CellValue align='right'>
                      <CustomNumberFormat
                        value={
                          storage?.qty
                            ? plusTax(item.costPrice * storage.qty, item.tax)
                            : plusTax(
                                item.costPrice * item.receivedQty,
                                item.tax
                              )
                        }
                      />
                    </CustomTable.CellValue>
                  </TableCell>
                </TableRow>
              );
            });
          })}
        </TableBody>
      </CustomTable.Container>
    </>
  );
};

export default Products;
