import React from 'react';
import {
  Dialog,
  DialogContent,
  DialogTitle,
  Button,
  DialogActions,
  Grid,
  Typography,
  makeStyles,
  Fade,
} from '@material-ui/core';
import AvatarEditor from 'react-avatar-editor';

const useStyles = makeStyles(() => ({
  dialogTitle: {
    textAlign: 'center',
  },
  dialogContent: {
    textAlign: 'center',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  avatar: {
    margin: '2rem 2rem 1rem 2rem',
  },
  scaleInput: {
    width: 250,
    height: 20,
    margin: '10px 0',
  },
  dialogAction: {
    padding: 0,
  },
  btn: {
    borderRadius: 0,
  }
}));

const ProfileDialog = ({ editorRef, open, handleClose, handleSave, profile, setProfile, scale, setScale, handleScale, handleBrowseImage }) => {
  const classes = useStyles();

  return (
    <Dialog open={open} onClose={handleClose} TransitionComponent={Fade}>
      <DialogTitle className={classes.dialogTitle}>
        <Typography color="primary">
          Choosing Profile
        </Typography>
      </DialogTitle>
      <DialogContent className={classes.dialogContent} dividers={true}>
        <AvatarEditor
            ref={editorRef}
            className={classes.avatar}
            scale={parseFloat(scale)}
            name="profile"
            width={200}
            height={200}
            rotate={0}
            borderRadius={200}
            image={profile}
            />
        <input
            className={classes.scaleInput}
            name="scale"
            type="range"
            onChange={handleScale}
            min="1"
            max="3"
            step="0.02"
            defaultValue={scale}
        />
        
      </DialogContent>
      <DialogActions className={classes.dialogAction}>
        <Grid container style={{ borderTop: '1px solid rgba(0,0,0,0.2)' }}>
          <Grid item xs={6}>
            <Button 
                className={classes.btn}
                onClick={handleBrowseImage}
                color="primary"
                variant="contained"
                fullWidth
            >
                Browse Image
            </Button>
          </Grid>
          <Grid item xs={6}>
            <Button 
                className={classes.btn}
                onClick={handleSave}
                variant="contained"
                color="primary"
                fullWidth
            >
                Save
            </Button>
          </Grid>
        </Grid>
      </DialogActions>
    </Dialog>
  );
};

export default ProfileDialog;
