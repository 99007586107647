import React, { useState } from 'react';
import { Container, makeStyles } from '@material-ui/core';
import Navbar from './navbar';
import Drawer from './drawer';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    height: '100vh',
  },
  content: {
    flexGrow: 1,
    overflowX: 'hidden',
    [theme.breakpoints.up('md')]: {
      marginTop: '64px',
    },
    [theme.breakpoints.up('sm')]: {
      marginTop: '56px',
    },
    marginTop: '48px',
    overflow: 'overlay',
    paddingTop: '32px',
    paddingBottom: '10px',
    position: 'relative',
  },
  container: {
    [theme.breakpoints.down('xs')]: {
      padding: 0,
      margin: 0,
    },
    '& > div': {
      // overflowX: "hidden"
    },
  },
}));

const DashboardLayout = ({ children }) => {
  const classes = useStyles();
  const [drawer, setDrawer] = useState(false);

  const handleClose = () => setDrawer(false);
  const handleOpen = () => setDrawer(true);
  return (
    <div className={classes.root}>
      <Navbar handleOpen={handleOpen} />
      <Drawer open={drawer} handleClose={handleClose} />
      <div className={classes.content}>
        <Container className={classes.container} maxWidth="xl">
          {children}
        </Container>
      </div>
    </div>
  );
};

export default DashboardLayout;
