import { Button, Grid, Paper } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { DateRangePicker } from 'react-dates';
import { useTranslation } from 'react-i18next';
import TableX from '../../components/tableX';
import config from '../../components/tableX/config';
import NumberFormat from '../../components/number-format';
import moment from 'moment';
import TitleBar from '../../components/title-bar';
import CustomNumberFormat from '../../components/number-format';
import DownloadButton from 'src/components/download-button';
import axios from 'axios';
import buildURL from 'src/util/get-api-url';
import { useReactToPrint } from 'react-to-print';
import PrintReport from './print-report';
import TableFooter from 'src/components/table-footer';
import { formatUTCDate } from 'src/util/date-format';

const SaleByUser = () => {
  const { t } = useTranslation();
  const [date, setDate] = useState({
    startDate: moment().startOf('month'),
    endDate: moment().endOf('month'),
    focusedInput: false,
  });

  const [printData, setPrintData] = useState([]);
  const reportRef = React.useRef();

  const printReport = useReactToPrint({
    content: () => reportRef.current,
  });

  const handlePrintReport = async ({ url, date }) => {
    try {
      const response = await axios.get(url);
      setPrintData({
        body: response.data.report,
        footer: response.data.total,
        date,
      });
    } catch (err) {
      console.error(err);
    }
  };

  useEffect(() => {
    if (printData?.body?.length) {
      printReport();
    }
  }, [printData]);

  const tableSchema = [
    {
      id: 'name',
      sort: false,
      label: t('name'),
    },
    {
      id: 'totalSale',
      sort: false,
      label: t('total-sale'),
      type: 'component',
      components: [
        {
          name: NumberFormat,
          customValueProp: 'value',
        },
      ],
    },
    {
      id: 'totalAmountDue',
      sort: false,
      label: t('total-amount-due'),
      type: 'component',
      components: [
        {
          name: NumberFormat,
          customValueProp: 'value',
        },
      ],
    },
    {
      id: 'totalPaid',
      sort: false,
      label: t('total-paid'),
      type: 'component',
      components: [
        {
          name: NumberFormat,
          customValueProp: 'value',
        },
      ],
    },
    {
      id: 'commission.percentage',
      sort: false,
      label: t('commission-percentage'),
      dataConfig: ['commission', 'percentage'],
      type: 'component',
      components: [
        {
          name: CustomNumberFormat,
          customValueProp: 'value',
          props: { format: 'percentage' },
        },
      ],
    },
    {
      id: 'totalCommission',
      sort: false,
      label: t('total-commission'),
      type: 'component',
      components: [
        {
          name: NumberFormat,
          customValueProp: 'value',
        },
      ],
    },
  ];

  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <TitleBar disableButton></TitleBar>
      </Grid>
      <Grid item xs={12}>
        <Paper className='tableX-container'>
          <Grid container>
            <Grid item xs={12}>
              <TableX
                disableSearch
                select={false}
                render={(_, data) => {
                  if (data) {
                    return (
                      <TableFooter
                        data={[
                          { label: 'total', value: data.total.sale },
                          { label: 'total-paid', value: data.total.paid },
                          {
                            label: 'total-amount-due',
                            value: data.total.amountDue,
                          },
                        ]}
                      />
                    );
                  }
                }}
                config={tableConfig}
                schema={tableSchema}
                renderFilterInput={({ setDateRange, dateRange }) => {
                  const handleDateChange = ({ startDate, endDate }) => {
                    setDate((prev) => {
                      return { ...prev, startDate, endDate };
                    });
                    if (startDate && endDate) {
                      setDateRange({
                        from: formatUTCDate(startDate),
                        to: formatUTCDate(endDate),
                      });
                    } else if (startDate === null && endDate === null) {
                      setDateRange({
                        from: null,
                        to: null,
                      });
                    }
                  };

                  return (
                    <Grid container spacing={1}>
                      <Grid item>
                        <DateRangePicker
                          showClearDates
                          hideKeyboardShortcutsPanel
                          isOutsideRange={() => false}
                          startDateId='start_date' // PropTypes.string.isRequired,
                          endDateId='end_date' // PropTypes.string.isRequired,
                          startDate={date.startDate}
                          endDate={date.endDate}
                          onDatesChange={handleDateChange}
                          focusedInput={date.focusedInput}
                          onFocusChange={(focusedInput) =>
                            setDate((prev) => ({ ...prev, focusedInput }))
                          }
                        />
                      </Grid>
                      <Grid item>
                        <DownloadButton
                          href={buildURL('/api/v1/report/sale-by-user', {
                            params: {
                              fromDate: dateRange.from,
                              toDate: dateRange.to,
                              action: 'export',
                            },
                          })}
                          label={t('export')}
                        ></DownloadButton>
                      </Grid>
                      <Grid item>
                        <Button
                          onClick={() =>
                            handlePrintReport({
                              url: buildURL('/api/v1/report/sale-by-user', {
                                params: {
                                  fromDate: dateRange.from,
                                  toDate: dateRange.to,
                                  action: 'print',
                                },
                              }),
                              date: dateRange,
                            })
                          }
                          disableElevation
                          variant='contained'
                        >
                          Print
                        </Button>
                      </Grid>
                    </Grid>
                  );
                }}
              ></TableX>
            </Grid>
          </Grid>
        </Paper>
      </Grid>
      <PrintReport
        ref={reportRef}
        head={report.head}
        body={report.body}
        footer={report.footer}
        data={printData}
        title='Sale By User Report'
      />
    </Grid>
  );
};

const report = {
  head: [
    { value: 'name' },
    { value: 'total-sale' },
    { value: 'total-amount-due' },
    { value: 'total-paid' },
    { value: 'commission-percentage' },
    { value: 'total-commission' },
  ],
  body: [
    { field: 'name' },
    { field: 'totalSale', format: 'dollar' },
    { field: 'totalAmountDue', format: 'dollar' },
    { field: 'totalPaid', format: 'dollar' },
    { field: 'commission.percentage', format: 'percentage' },
    { field: 'totalCommission', format: 'dollar' },
  ],
  footer: [
    { label: 'total', field: 'sale', format: 'dollar' },
    { label: 'total-paid', field: 'paid', format: 'dollar' },
    { label: 'total-amount-due', field: 'amountDue', format: 'dollar' },
  ],
};

const tableConfig = {
  ...config,
  url: `${process.env.REACT_APP_API}/api/v1/report/sale-by-user`,
  initialDate: {
    from: formatUTCDate(moment().startOf('month')),
    to: formatUTCDate(moment().endOf('month')),
  },
  defaultSort: { createdAt: 'desc' },
  dataConfig: ['report'],
};

export default SaleByUser;
