import AvTimerIcon from '@material-ui/icons/AvTimer';
import SettingsIcon from '@material-ui/icons/Settings';
import ListAltIcon from '@material-ui/icons/ListAlt';
import { FiPackage } from 'react-icons/fi';
import { IoCartOutline } from 'react-icons/io5';
import { AiOutlineUser, AiOutlineShop } from 'react-icons/ai';
import { IoFileTrayStackedOutline } from 'react-icons/io5';
import { BiUser } from 'react-icons/bi';
import { RiMapPin2Line } from 'react-icons/ri';
import { GoGraph } from 'react-icons/go';
import { FaCashRegister, FaFileInvoiceDollar } from 'react-icons/fa';
import { VscTag } from 'react-icons/vsc';

const isPurchaseOrderDisable = ({ setting }) => {
  return setting?.purchaseOrder?.purchase === false;
};

const baseName = '/dashboard';
const menu = [
  {
    name: 'app-sidebar-dashboard',
    path: baseName,
    icon: AvTimerIcon,
  },
  {
    name: 'app-sidebar-product',
    icon: FiPackage,
    submenu: [
      {
        name: 'app-sidebar-product-list',
        path: `${baseName}/product`,
        feature: 'products',
        action: 'view',
      },
      {
        name: 'app-sidebar-composite-list',
        path: `${baseName}/composite`,
        feature: 'composite',
        action: 'view',
      },
      {
        name: 'app-sidebar-product-add',
        path: `${baseName}/product/create`,
        feature: 'products',
        action: 'create',
      },
      {
        name: 'app-sidebar-composite-add',
        path: `${baseName}/composite/create`,
        feature: 'composite',
        action: 'create',
      },
      {
        name: 'app-sidebar-category-list',
        path: `${baseName}/category`,
        feature: 'category',
        action: 'view',
      },
      {
        name: 'app-sidebar-product-unit',
        path: `${baseName}/unit`,
        feature: 'unit',
        action: 'view',
      },
      {
        name: 'app-sidebar-product-brand',
        path: `${baseName}/brand`,
        feature: 'brand',
        action: 'view',
      },
      // {
      //   name: "app-sidebar-product-listVariant",
      //   path: `${baseName}`,
      // },
      // {
      //   name: "app-sidebar-product-addVariant",
      //   path: `${baseName}`,
      // },
    ],
  },
  // {
  //   name: 'app-sidebar-composite',
  //   icon: FiPackage,
  //   submenu: [
  //     {
  //       name: 'app-sidebar-composite-list',
  //       path: `${baseName}/composite`,
  //       feature: 'products',
  //       action: 'view',
  //     },
  //   ],
  // },
  {
    name: 'app-sidebar-stock',
    icon: IoFileTrayStackedOutline,
    submenu: [
      {
        name: 'app-sidebar-stock-list',
        path: `${baseName}/stock`,
        feature: 'stock',
        action: 'view',
      },
      {
        name: 'app-sidebar-stock-adjust',
        path: `${baseName}/stock/adjust`,
        feature: 'stock',
        action: 'update',
      },
    ],
  },
  {
    name: 'app-sidebar-sale',
    icon: ListAltIcon,
    submenu: [
      {
        name: 'app-sidebar-sale-list',
        path: `${baseName}/sale-invoice`,
        feature: 'orders',
        action: 'view',
      },
      // {
      //   name: 'app-sidebar-sale-add',
      //   path: `${baseName}/sale-invoice/create`,
      //   feature: 'orders',
      //   action: 'create',
      // },
      {
        name: 'app-sidebar-sale-repack',
        path: `${baseName}/sale-invoice/repack`,
        feature: 'orders',
        action: 'repack',
        disabled: ({ setting }) => {
          return setting?.order?.repack === false;
        },
      },
      {
        name: 'app-sidebar-sale-saleReturn',
        path: `${baseName}/sale-invoice/sale-return`,
        feature: 'orders',
        action: 'returned',
        disabled: ({ setting }) => {
          return setting?.order?.saleReturn === false;
        },
      },
    ],
  },
  {
    name: 'app-sidebar-sale-pos',
    icon: FaCashRegister,
    submenu: [
      {
        name: 'app-sidebar-sale-pos-list',
        path: `${baseName}/sale-pos`,
        feature: 'orders',
        action: 'view',
      },
    ],
  },
  {
    name: 'app-sidebar-customer',
    icon: AiOutlineUser,
    submenu: [
      {
        name: 'app-sidebar-customer-list',
        path: `${baseName}/customer`,
        feature: 'customer',
        action: 'view',
      },
      {
        name: 'app-sidebar-customer-add',
        path: `${baseName}/customer/create`,
        feature: 'customer',
        action: 'create',
      },
    ],
  },
  {
    name: 'app-sidebar-discount',
    icon: VscTag,
    submenu: [
      {
        name: 'app-sidebar-discount-list',
        path: `${baseName}/discount`,
        feature: 'discount',
        action: 'view',
      },
      {
        name: 'app-sidebar-discount-add',
        path: `${baseName}/discount/create`,
        feature: 'customer',
        action: 'create',
      },
    ],
  },
  {
    name: 'app-sidebar-vendor',
    icon: AiOutlineShop,
    submenu: [
      {
        name: 'app-sidebar-vendor-list',
        path: `${baseName}/vendor`,
        feature: 'supplier',
        action: 'view',
      },
      {
        name: 'app-sidebar-vendor-add',
        path: `${baseName}/vendor/create`,
        feature: 'supplier',
        action: 'create',
      },
    ],
  },
  {
    name: 'app-sidebar-billing',
    icon: FaFileInvoiceDollar,
    submenu: [
      {
        name: 'app-sidebar-billing-list',
        path: `${baseName}/billing`,
        feature: 'billing',
        action: 'view',
      },
      {
        name: 'app-sidebar-billing-add',
        path: `${baseName}/billing/create`,
        feature: 'billing',
        action: 'create',
      },
    ],
  },
  {
    name: 'app-sidebar-purchase',
    icon: IoCartOutline,
    submenu: [
      {
        name: 'app-sidebar-purchase',
        path: `${baseName}/purchase`,
        feature: 'purchase-order',
        action: 'view',
        disabled: isPurchaseOrderDisable,
      },
      {
        name: 'app-sidebar-purchase-add',
        path: `${baseName}/purchase/create`,
        feature: 'purchase-order',
        action: 'create',
        disabled: isPurchaseOrderDisable,
      },
      {
        name: 'app-sidebar-purchase-in-list',
        path: `${baseName}/purchase-in`,
        feature: 'purchase-in',
        action: 'view',
        disabled: isPurchaseOrderDisable,
      },
      {
        name: 'app-sidebar-purchase-return-list',
        path: `${baseName}/purchase-return`,
        feature: 'purchase-return',
        action: 'view',
        disabled: isPurchaseOrderDisable,
      },
    ],
  },
  {
    name: 'app-sidebar-storage',
    icon: RiMapPin2Line,
    submenu: [
      {
        name: 'app-sidebar-storage-list',
        path: `${baseName}/storage`,
        feature: 'storage',
        action: 'view',
      },
    ],
  },
  // {
  //   name: "app-sidebar-warehouse",
  //   icon: BiBuildingHouse,
  //   submenu: [
  //     {
  //       name: "app-sidebar-warehouse-transferProduct",
  //       path: `${baseName}`,
  //     },
  //     {
  //       name: "app-sidebar-warehouse-addTransferProduct",
  //       path: `${baseName}`,
  //     },
  //   ],
  // },
  {
    name: 'app-sidebar-users',
    icon: BiUser,
    submenu: [
      {
        name: 'app-sidebar-users-list',
        path: `${baseName}/user`,
        feature: 'user',
        action: 'view',
      },
    ],
  },
  // {
  //   name: "app-sidebar-memberCard",
  //   icon: MdCardGiftcard,
  //   submenu: [
  //     {
  //       name: "app-sidebar-memberCard-list",
  //       path: `${baseName}`,
  //     },
  //     {
  //       name: "app-sidebar-memberCard-add",
  //       path: `${baseName}`,
  //     },
  //   ],
  // },
  // {
  //   name: "app-sidebar-event",
  //   icon: RiCalendarEventLine,
  //   submenu: [
  //     {
  //       name: "app-sidebar-event-coupon",
  //       path: `${baseName}`,
  //     },
  //     {
  //       name: "app-sidebar-event-productDiscount",
  //       path: `${baseName}`,
  //     },
  //   ],
  // },
  {
    name: 'app-sidebar-reports',
    icon: GoGraph,
    submenu: [
      {
        name: 'app-sidebar-reports-saleReport',
        path: `${baseName}/report/sale`,
        feature: 'report',
        action: 'view',
      },
      {
        name: 'app-sidebar-reports-paymentReport',
        path: `${baseName}/report/payment`,
        feature: 'report',
        action: 'view',
      },
      {
        name: 'app-sidebar-reports-sale-by-product',
        path: `${baseName}/report/sale-by-product`,
        feature: 'report',
        action: 'view',
      },
      {
        name: 'app-sidebar-reports-sale-by-customer',
        path: `${baseName}/report/sale-by-customer`,
        feature: 'report',
        action: 'view',
      },
      {
        name: 'app-sidebar-reports-sale-by-user',
        path: `${baseName}/report/sale-by-user`,
        feature: 'report',
        action: 'view',
      },
      {
        name: 'app-sidebar-reports-profitAndLose',
        path: `${baseName}/report/profit-and-loss`,
        feature: 'report',
        action: 'view',
      },
      // {
      //   name: "app-sidebar-reports-productReport",
      //   path: `${baseName}`
      // },
      // {
      //   name: "app-sidebar-reports-saleByCategory",
      //   path: `${baseName}`
      // },
      // {
      //   name: "app-sidebar-reports-saleByPaymentType",
      //   path: `${baseName}`
      // },
      // {
      //   name: "app-sidebar-reports-saleByStaff",
      //   path: `${baseName}`
      // },
      // {
      //   name: "app-sidebar-reports-shift",
      //   path: `${baseName}`
      // },
      // {
      //   name: "app-sidebar-reports-profitAndLose",
      //   path: `${baseName}`
      // }
    ],
  },
  {
    name: 'app-sidebar-setting',
    icon: SettingsIcon,
    submenu: [
      {
        name: 'app-sidebar-setting-general',
        path: `${baseName}/setting`,
        feature: 'role',
        action: 'view',
      },
      {
        name: 'app-sidebar-setting-profile',
        path: `${baseName}/setting/profile`,
      },
      {
        name: 'app-sidebar-setting-payment-account',
        path: `${baseName}/payment-account`,
        feature: 'report',
        action: 'view',
      },
      {
        name: 'app-sidebar-setting-sale-target',
        path: `${baseName}/sale-target`,
        feature: 'sale-target',
        action: 'view',
      },
      {
        name: 'app-sidebar-setting-currencyOption',
        path: `${baseName}/currency`,
        feature: 'exchange-rate',
        action: 'view',
      },
      {
        name: 'app-sidebar-setting-delivery',
        path: `${baseName}/delivery-setting`,
        feature: 'supplier',
        action: 'view',
      },
      {
        name: 'app-sidebar-setting-role-and-permission',
        path: `${baseName}/role-and-permission`,
        feature: 'role',
        action: 'view',
      },
      {
        name: 'app-sidebar-setting-pos',
        path: `${baseName}/pos`,
        feature: 'pos',
        action: 'view',
        disabled: ({ feature }) => {
          return feature?.pos === false;
        },
      },
      // {
      //   name: 'app-sidebar-setting-role-add',
      //   path: `${baseName}/roleAndPermission/create`,
      //   feature: 'role,',
      //   action: 'view',
      // },
    ],
  },
];

export default menu;
