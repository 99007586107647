import React, { useEffect, useState } from 'react';
import {
  Checkbox,
  FormControlLabel,
  Grid,
  FormControl,
  Typography,
  IconButton,
} from '@material-ui/core';
import TextInput from '../../../../components/text-input';
import { useTranslation } from 'react-i18next';
import ImageSection from './image-upload';
import PurchaseSaleInformation from './purchase-sale-information';
import AttributeCreator from './attribute-creator';
import RichTextEditor from './rich-text-editor';
import { Controller, useFormContext } from 'react-hook-form';
import PrintIcon from '@material-ui/icons/Print';
import PrintBarcode from './barcode-print';
import registerMui from '../../../../util/react-hook-form-helper/register-mui';
import UnitForm from '../../../unit/form';
import BrandForm from '../../../brand/form';
import CategoryForm from '../../../category/form';
import VendorForm from '../../../vendor/form';
import clsx from 'clsx';
import CategoryLabel from '../../../../components/category-label';
import TextInputContainer from 'src/components/text-input-container';
import SlugInput from 'src/components/slug-input';
import useMessage from 'src/hooks/useMessage';
import AutoCompleteAsync from 'src/components/autocomplete-async';
import LabelInput from 'src/components/label-input';
import TagInput from 'src/components/tag-input';
import ChipInput from 'src/components/chip-input';

const ProductForm = ({ loading, state, images, setImages, setRemoveVariantImage }) => {
  const { t } = useTranslation();
  const { control, register, setValue, getValues, watch } = useFormContext();
  const { showErrorMessage } = useMessage();
  const [unitForm, setUnitForm] = useState({ state: false, data: null });
  const [brandForm, setBrandForm] = useState({ state: false, data: null });
  const [vendorForm, setVendorForm] = useState({ state: false, data: null });
  const [categoryForm, setCategoryForm] = useState({
    state: false,
    data: null,
  });
  const [slug, setSlug] = useState(null);

  useEffect(() => {
    if (state === 'edit') {
      const value = getValues('slug');
      setSlug(value);
    }
  }, [loading, state]);

  const createAttributes = watch('createAttributes');
  const [printBarcode, setPrintBarcode] = useState(false);

  const handleOpenPrintBarcode = () => setPrintBarcode(true);
  const handleClosePrintBarcode = () => setPrintBarcode(false);

  return (
    <Grid container className="container" spacing={3}>
      <Grid item container justify="space-between" xs={12}>
        <Typography variant="h6">{t('product-information')}</Typography>
        {createAttributes === false && state === 'edit' ? (
          <>
            <IconButton size="small" onClick={handleOpenPrintBarcode}>
              <PrintIcon color="primary" />
            </IconButton>
            <PrintBarcode open={printBarcode} onClose={handleClosePrintBarcode} />
          </>
        ) : null}
      </Grid>
      <Grid item xs={6}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <TextInput
              required
              autoFocus
              label={t('product-name')}
              {...registerMui(register('name'))}
            />
          </Grid>
          <Grid item xs={6}>
            <Controller
              control={control}
              name="vendor"
              defaultValue={null}
              render={({ field: { value, onChange } }) => {
                return (
                  <LabelInput
                    label={t('vendor')}
                    // required
                    component={AutoCompleteAsync}
                    value={value}
                    onChange={onChange}
                    placeholder={t('vendor')}
                    onCreate={data =>
                      setVendorForm({
                        state: true,
                        data: { name: data },
                      })
                    }
                    optionsURL={`${process.env.REACT_APP_API}/api/v1/suppliers/all`}
                    getOptions={data => data?.suppliers}
                    renderOption={data => data?.name}
                    getOptionLabel={option => option?.name}
                    getOptionSelected={(option, value) => option?._id === value?._id || value?.id}
                  />
                );
              }}
            ></Controller>
            <VendorForm
              open={vendorForm.state}
              onClose={() => setVendorForm({ state: false })}
              newData={vendorForm.data}
              setReturnData={data => {
                setValue('vendor', data);
              }}
            />
          </Grid>
          <Grid item xs={6}>
            <UnitForm
              open={unitForm.state}
              onClose={() => setUnitForm({ state: false })}
              newData={unitForm.data}
              setReturnData={data => {
                setValue('unit', data);
              }}
            />
            <Controller
              control={control}
              name="unit"
              defaultValue={null}
              render={({ field: { value, onChange } }) => {
                return (
                  <LabelInput
                    label={t('unit')}
                    required
                    component={AutoCompleteAsync}
                    value={value}
                    onChange={onChange}
                    placeholder={t('unit')}
                    onCreate={data =>
                      setUnitForm({
                        state: true,
                        data: { name: data },
                      })
                    }
                    optionsURL={`${process.env.REACT_APP_API}/api/v1/units`}
                    getOptions={data => data?.units}
                    renderOption={data => data.name}
                    getOptionLabel={option => option.name}
                    getOptionSelected={(option, value) => option._id === value._id || value.id}
                  />
                );
              }}
            ></Controller>
          </Grid>
          <Grid item xs={12}>
            <TextInputContainer label={t('slug')}>
              <SlugInput
                enableGenerate
                setValue={setValue}
                getData={() => {
                  const value = getValues('name');
                  if (!value) return showErrorMessage('product-slug-data-insufficient');
                  return value;
                }}
                defaultValue={slug}
                textField
                placeholder={t('slug')}
                size="small"
                variant="outlined"
                name="slug"
                register={register}
                fullWidth
              />
            </TextInputContainer>
          </Grid>
          <Grid item xs={12}>
            <TagInput control={control} />
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={6}>
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <CategoryForm
              open={categoryForm.state}
              onClose={() => setCategoryForm({ state: false })}
              newData={categoryForm.data}
              setReturnData={data => {
                setValue('category', data);
              }}
            />
            <Controller
              control={control}
              name="category"
              defaultValue={null}
              render={({ field: { value, onChange } }) => {
                return (
                  <LabelInput
                    label={t('category')}
                    component={AutoCompleteAsync}
                    filterQuery={{ getAll: true }}
                    value={value}
                    onChange={onChange}
                    placeholder={t('category')}
                    onCreate={data =>
                      setCategoryForm({
                        state: true,
                        data: { name: data },
                      })
                    }
                    optionsURL={`${process.env.REACT_APP_API}/api/v1/categories`}
                    getOptions={data => data?.categories}
                    renderOption={option =>
                      option?.creatable ? null : (
                        <CategoryLabel
                          option={option}
                          name={option?.name}
                          parent={option?.ancestors}
                        />
                      )
                    }
                    getOptionLabel={option => option.name}
                    getOptionSelected={(option, value) => {
                      return option._id === value?._id || value?.id;
                    }}
                  />
                );
              }}
            ></Controller>
          </Grid>
          <Grid item xs={6}>
            <BrandForm
              open={brandForm.state}
              onClose={() => setBrandForm({ state: false })}
              newData={brandForm.data}
              setReturnData={data => {
                setValue('brand', data);
              }}
            />
            <Controller
              control={control}
              name="brand"
              defaultValue={null}
              render={({ field: { value, onChange } }) => {
                return (
                  <LabelInput
                    label={t('brand')}
                    component={AutoCompleteAsync}
                    value={value}
                    onChange={onChange}
                    placeholder={t('brand')}
                    onCreate={data =>
                      setBrandForm({
                        state: true,
                        data: { name: data },
                      })
                    }
                    optionsURL={`${process.env.REACT_APP_API}/api/v1/brands`}
                    getOptions={data => data?.brands}
                    renderOption={option => option.name}
                    getOptionLabel={option => option.name}
                    getOptionSelected={(option, value) => option._id === value._id || value.id}
                  />
                );
              }}
            ></Controller>
          </Grid>
          <Grid item container xs={12}>
            <TextInput
              label={t('summary-description')}
              multiline
              rows={6}
              InputProps={{
                style: { paddingTop: '12.5px', paddingBottom: '2.5px' },
              }}
              {...registerMui(register('shortDescription'))}
            />
          </Grid>
          <Grid item xs={12}>
            <ChipInput control={control} label={t('keywords')} name="keywords" />
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <ImageSection images={images} setImages={setImages} />
      </Grid>
      <Grid item xs={12}>
        <FormControl className={clsx({ hidden: state === 'edit' })}>
          <FormControlLabel
            label={<Typography variant="h6">{t('create-attributes-and-options')}</Typography>}
            control={
              <Controller
                name={'createAttributes'}
                control={control}
                defaultValue={false}
                render={({ field: { value, onChange, ...other } }) => {
                  return (
                    <Checkbox
                      color="primary"
                      disabled={state === 'edit'}
                      {...other}
                      checked={value}
                      onChange={e => {
                        onChange(e.target.checked);
                      }}
                    />
                  );
                }}
              />
            }
          ></FormControlLabel>
        </FormControl>
      </Grid>
      <Grid item xs={12}>
        {createAttributes ? (
          <AttributeCreator state={state} setRemoveVariantImage={setRemoveVariantImage} />
        ) : (
          <PurchaseSaleInformation state={state} loading={loading} />
        )}
      </Grid>
      <Grid item container spacing={1} xs={12}>
        <Grid item xs={12}>
          <Typography variant="h6">{t('description')}</Typography>
        </Grid>
        <Grid item xs={12}>
          <RichTextEditor
            control={control}
            register={register}
            setValue={setValue}
            getValues={getValues}
          />
        </Grid>
      </Grid>
    </Grid>
  );
};

export default ProductForm;
