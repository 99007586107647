import React, { useEffect, useState, useCallback } from 'react';
import {
  FormControl,
  FormLabel,
  TextField,
  makeStyles,
} from '@material-ui/core';
import axios from 'axios';
import { Autocomplete, createFilterOptions } from '@material-ui/lab';
import readObjectWithArrayKeys from '../../util/read-object-value-with-array-keys';
import debounce from 'debounce';
import clsx from 'clsx';

const filter = createFilterOptions();

const useStyles = makeStyles((theme) => ({
  label: {
    color: 'black',
    marginBottom: '10px',
    fontSize: '14px',
    textTransform: 'uppercase',
  },
  bold: {
    fontWeight: 'bold',
  },
  danger: {
    color: theme.palette.secondary.main,
  },
}));

const TextInputListSearch = ({
  label,
  optionsURL,
  queryString = 'search',
  dataConfig,
  placeholder = '',
  value,
  onChange,
  required,
  boldLable = true,
  getOptionConfig,
  disableClearable = true,
  ...other
}) => {
  const [list, setList] = useState([]);
  const [loading, setLoading] = useState(true);
  const classes = useStyles();

  useEffect(() => {
    if (optionsURL) {
      fetchData();
    }
  }, []);

  const fetchData = (searchText) => {
    setLoading(true);
    axios
      .get(
        `${optionsURL}?${queryString}=${searchText || ''}&page=1&limit=5&sort=`
      )
      .then((response) => {
        setList(readObjectWithArrayKeys(response.data, dataConfig));
      })
      .catch((err) => console.log(err))
      .finally(() => {
        setLoading(false);
      });
  };

  const debounceSearch = useCallback(
    debounce((text) => {
      if (optionsURL) {
        fetchData(text);
      }
    }, 500),
    []
  );

  let options = {};
  if (getOptionConfig) {
    options = {
      getOptionLabel: (option) => {
        // Value selected with enter, right from the input
        if (typeof option === 'string') {
          return option;
        }
        // Add "xxx" option created dynamically
        if (option.inputValue) {
          return option.inputValue;
        }
        // Regular option
        return readObjectWithArrayKeys(option, getOptionConfig);
      },
      renderOption: (option) =>
        readObjectWithArrayKeys(option, getOptionConfig) || option,
      filterOptions: (options, params) => {
        const filtered = filter(options, params);

        // Suggest the creation of a new value
        if (params.inputValue !== '') {
          filtered.push({
            inputValue: params.inputValue,
            [getOptionConfig.join('.')]: `Add "${params.inputValue}"`,
          });
        }
        return filtered;
      },
    };
  }

  return (
    <FormControl fullWidth>
      {label && (
        <FormLabel
          className={clsx(classes.label, { [classes.bold]: boldLable })}
        >
          {label}
          {required && boldLable ? (
            <span className={classes.danger}> *</span>
          ) : null}
        </FormLabel>
      )}
      <Autocomplete
        options={list}
        disableClearable={disableClearable}
        autoHighlight
        value={value}
        {...options}
        {...other}
        onChange={onChange}
        loading={loading}
        onInputChange={(event) => {
          if (event?.target?.value) {
            setLoading(true);
            debounceSearch(event.target.value);
          }
        }}
        renderInput={(params) => {
          return (
            <TextField
              {...params}
              required={required}
              variant='outlined'
              size='small'
              placeholder={label ? label.toUpperCase() : placeholder}
            />
          );
        }}
      ></Autocomplete>
    </FormControl>
  );
};

export default TextInputListSearch;
