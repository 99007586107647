import { Avatar, Grid, Paper, Typography, makeStyles } from '@material-ui/core';
import React from 'react';
import NumberFormatCustom from '../../../components/number-format';

const useStyles = makeStyles((theme) => ({
  icon: {
    background: 'white',
    color: theme.palette.primary.main,
    width: '70px',
    height: '50px',
    '& svg': {
      fontSize: '44px',
    },
  },
  title: {
    fontSize: '20px',
  },
  topContainer: {
    padding: theme.spacing(1.8, 3),
    color: theme.palette.primary.main,
    fontWeight: 'bold',
    textAlign: 'right',
    '& .name': {},
    '& .value': {
      color: theme.palette.grey[500],
    },
  },
  bottomContainer: {
    backgroundColor: theme.palette.primary.main,
    padding: theme.spacing(1, 3),
    fontWeight: 'bold',
    color: 'white',
    '& .name': {
      fontSize: '18px',
    },
    '& .value': {
      fontSize: '28px',
    },
  },
}));

const Card = ({ icon, top, bottom }) => {
  const classes = useStyles();

  return (
    <Paper style={{ overflow: 'hidden' }}>
      <Grid container direction='column'>
        <Grid item>
          <Grid
            className={classes.topContainer}
            container
            alignItems='center'
            justify='space-between'
            spacing={2}
          >
            <Grid item xs={3}>
              <Avatar variant='sqaure' className={classes.icon}>
                {icon}
              </Avatar>
            </Grid>
            <Grid item xs={9}>
              <Grid container spacing={1}>
                {top.map((item, index) => (
                  <React.Fragment key={index}>
                    <Grid item xs={7}>
                      <Typography className='name ellipsis'>
                        {item.name}
                      </Typography>
                    </Grid>
                    <Grid item xs={5}>
                      <Typography className='value'>
                        <NumberFormatCustom
                          format={item.format}
                          value={item.value || 0}
                        ></NumberFormatCustom>
                      </Typography>
                    </Grid>
                  </React.Fragment>
                ))}
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid
          item
          container
          justify='space-between'
          alignItems='center'
          className={classes.bottomContainer}
        >
          <Typography className='name'>{bottom.name}</Typography>
          <Typography className='value'>
            <NumberFormatCustom
              format={bottom.format}
              value={bottom.value || 0}
            ></NumberFormatCustom>
          </Typography>
        </Grid>
      </Grid>
    </Paper>
  );
};

export default Card;
