import customAxios from './custom-axios';

const REPORT_ENDPOINT = '/report';

const reportApi = {
  profitAndLoss: (startDate, endDate) =>
    customAxios.get(`${REPORT_ENDPOINT}/profit-and-loss`, {
      params: { startDate, endDate },
    }),
};

export default reportApi;
