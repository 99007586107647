import { Box, Typography, makeStyles } from '@material-ui/core';
import clsx from 'clsx';
import React from 'react';
import Barcode from 'react-barcode';

const useStyles = makeStyles((theme) => ({
  root: {
    '& *': {
      fontFamily: 'Arial , Siemreap',
    },
    '@media print': {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      height: '90px',
      width: '150px',
      flexDirection: 'column',
      position: 'relative',
      overflow: 'hidden',
    },
    display: 'flex',
    justifyContent: 'center',
    height: '90px',
    width: '200px',
    flexDirection: 'column',
  },
  textContainer: {
    '@media print': {
      position: 'absolute',
      top: 0,
      width: '100%',
      padding: theme.spacing(0, 1.8),
      zIndex: 2,
    },
  },
  productName: {
    '@media print': {
      fontSize: '12px',
    },
    fontSize: '20px',
  },
  smallText: {
    '@media print': {
      fontSize: '8px',
    },
    fontSize: '14px',
  },
  barcode: {
    '@media print': {
      position: 'absolute',
      bottom: 0,
      marginBottom: '-8px',
    },
  },
  priceContainer: {
    '@media print': {
      fontSize: '12px',
    },
    fontSize: '20px',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
}));

const BarcodeContainer = React.forwardRef(({ barcode, type }, ref) => {
  const classes = useStyles();
  return (
    <Box ref={ref} className={classes.root}>
      <Box className={classes.textContainer}>
        <Typography className={classes.smallText}>
          <b>Babyco</b>
        </Typography>
        <Typography className={clsx('ellipsis', classes.productName)}>
          <b>{barcode?.name}</b>
        </Typography>
        <Typography className={classes.priceContainer}>
          <span
            className={clsx(classes.smallText, 'ellipsis')}
            style={{ flex: 1 }}
          >
            <b>{barcode?.ageVariation}</b>
          </span>
          <b style={{ flex: 1, textAlign: 'right' }}>
            ${Number(barcode?.price?.salePrice).toFixed(2)}
          </b>
        </Typography>
      </Box>
      <Box className={classes.barcode}>
        <Barcode value={barcode?.[type]} height={30} width={1} fontSize={8} />
      </Box>
    </Box>
  );
});

export default BarcodeContainer;
