import { Avatar, Container, Grid, makeStyles, Paper } from '@material-ui/core';
import React from 'react';

const useStyles = makeStyles((theme) => ({
  root: {
    position: 'relative',
    backgroundImage: 'url("/login.jpg")',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    minHeight: '100vh',
    display: 'flex',
  },
  overlay: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    background: 'rgba(0,0,0,0.58)',
  },
  container: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  formContainer: {
    background: 'rgba(255,255,255,0.86)',
    // minHeight: '550px',
    position: 'relative',
    borderRadius: '15px',
    display: 'flex',
    textAlign: 'center',
    [theme.breakpoints.up('sm')]: {
      padding: '30px  40px',
      width: '480px',
    },
    [theme.breakpoints.down('xs')]: {
      padding: '30px 20px',
    },
  },
  logo: {
    width: '120px',
    height: '120px',
    background: 'white',
    margin: '0 auto',
    '& img': {
      objectFit: 'contain',
    },
  },
}));

const LoginFormContainer = ({ children }) => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <div className={classes.overlay} />
      <Container className={classes.container}>
        <Paper className={classes.formContainer}>
          <Grid container direction='column' justify='space-evenly' spacing={1}>
            <Grid item>
              <Avatar
                src={'/logo.png'}
                variant='circle'
                className={classes.logo}
              />
            </Grid>
            <Grid item xs style={{ position: 'relative' }}>
              {children}
            </Grid>
          </Grid>
        </Paper>
      </Container>
    </div>
  );
};

export default LoginFormContainer;
