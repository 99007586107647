const APP_EN = {
  // global
  'created-by': 'Created By',
  action: 'Action',
  search: 'Search',
  qty: 'Qty',
  description: 'Description',
  cancel: 'Cancel',
  add: 'Add',
  unit: 'Unit of Measurement',
  brand: 'Brand',
  note: 'Note',
  role: 'Role',
  icon: 'Icon',
  sku: 'SKU',
  upc: 'Barcode',
  name: 'Name',
  email: 'Email',
  phone: 'Phone',
  website: 'Website',
  address: 'Address',
  fullname: 'FullName',
  position: 'Position',
  'low-stock': 'LOW STOCK',
  city: 'City',
  firstname: 'First Name',
  lastname: 'Last Name',
  gender: 'Gender',
  'date-of-birth': 'Date of Birth',
  vendor: 'Vendor',
  'created-date': 'Created Date',
  image: 'Image',
  'product-name': 'Product Name',
  slug: 'SLUG',
  quantity: 'Quantity',
  'cost-price': 'Cost Price',
  tax: 'Tax',
  amount: 'Amount',
  status: 'Status',
  subtotal: 'Subtotal',
  'shipping-fee': 'Shipping Fee',
  'other-fee': 'Other Fee',
  discount: 'Discount',
  'grand-total': 'Grand Total',
  update: 'Update',
  'unit-price': 'Unit Price',
  save: 'Save',
  barcode: 'Barcode',
  print: 'Print',
  'edit-options': 'Edit Options',
  filter: 'Filter',
  export: 'Export',
  'before-tax': 'Before Tax',
  'payment-status': 'Payment Status',
  'save-changes': 'Save Changes',
  cash: 'Cash',
  'stock-on-hand': 'Stock on hand',
  'are-you-sure': 'Are you sure?',
  confirm: 'Confirm',
  'action-cant-undone': 'Your action cannot be undone.',
  'action-confirmation': 'Action Confirmation',
  'returned-confirmation': 'Returned Confirmation',
  'no.': 'No.',
  total: 'Total',
  'amount-due': 'Amount Due',
  'print-invoice': 'Print Invoice',
  'print-shipping-label': 'Print Shipping Label',
  payment: 'Payment',
  'credit-note': 'Credit Note',
  order: 'Order',
  create: 'Create',
  delete: 'Delete',
  user: 'User',
  'api-key': 'Api Key',
  'delivery-provider': 'Delivery Provider',
  other: 'Other',
  'payment-account': 'Payment Account',
  'delivery-status': 'Delivery Status',
  'received-all': 'Received All',
  'assign-storage': 'Assign Storage',
  received: 'Received',
  receive: 'Receive',
  receiver: 'Receiver',
  storage: 'Storage',
  altCode: 'AltCode',
  'n/a': 'N/A',
  'costing-total': 'Costing Total',
  'no-product-found': 'No Product Found',
  'no-storage-found': 'No Storage Found',
  cost: 'Cost',
  'cost-date': 'Cost Date',
  reference: 'Reference',
  comment: 'Comment',
  costlines: 'Costlines',
  items: 'Items',
  refund: 'Refund',
  'returned-order': 'Returned Order',
  'refund-order': 'Refund Order',
  'shipping-address': 'Shipping Address',
  customer: 'Customer',
  id: 'Id',
  return: 'Return',
  'to-pick': 'To Pick',
  edit: 'Edit',
  'sale-target': 'Sale Target',
  saving: 'Saving',
  loading: 'Loading',
  'no-category': 'No category',
  salePrice: 'Sale Price',
  costPrice: 'Cost Price',
  'add-shipping-expense': 'Add Shipping Expense',
  'pick-location': 'Pick Location',
  skip: 'Skip',
  profile: 'Profile',
  logout: 'Logout',

  //delivery provider
  tada: 'TADA',

  // status
  pending: 'Pending',
  approved: 'Approved',
  rejected: 'Rejected',
  packing: 'Packing',
  shipping: 'Shipping',
  delivered: 'Delivered',
  'delivery-failed': 'Delivery Failed',
  failed: 'Failed',
  cancelled: 'Cancelled',
  complete: 'Complete',
  returned: 'Returned',
  'partially-returned': 'Partially Returned',
  unpaid: 'Unpaid',
  paid: 'Paid',
  'unpaid-invoice': 'Unpaid Invoice',
  'partially-paid': 'Partially Paid',
  date: 'Date',
  'paid-date': 'Paid Date',

  approve: 'Approve',
  reject: 'Reject',
  pack: 'Pack',
  ship: 'Ship',

  published: 'Published',
  unpublished: 'Unpublished',
  publish: 'Publish',
  unpublish: 'Unpublish',

  'order-quantity': 'Order Quantity',
  'packing-order': 'Pack Order',
  'packing-purchase-return': 'Pack Purchase Return',
  'cancel-purchase-return': 'Cancel Purchase Return',
  'failed-order': 'Fail Order',

  // title bar
  'titlebar-dashboard': 'Dashboard',
  'titlebar-unit': 'List Unit',
  'titlebar-brand': 'List Brand',
  'titlebar-composite': 'List Composite',
  'titlebar-composite-create': 'Create Composite',
  'titlebar-composite-edit': 'Edit Composite',
  'titlebar-category': 'List Category',
  'titlebar-product': 'List Product',
  'titlebar-product-create': 'Create Product',
  'titlebar-product-edit': 'Edit Product',
  'titlebar-currency': 'List Currency',
  'titlebar-vendor': 'List Vendor',
  'titlebar-vendor-create': 'Create Vendor',
  'titlebar-vendor-edit': 'Edit Vendor',
  'titlebar-customer': 'List Customer',
  'titlebar-customer-create': 'Create Customer',
  'titlebar-customer-edit': 'Edit Customer',
  'titlebar-purchase': 'List Purchase',
  'titlebar-billing': 'List Billing',
  'titlebar-billing-create': 'Create Billing',
  'titlebar-discount': 'List Discount',
  'titlebar-discount-create': 'Create Discount',
  'titlebar-discount-edit': 'Edit Discount',
  'titlebar-purchase-create': 'Create Purchase',
  'titlebar-purchase-edit': 'Edit Purchase',
  'titlebar-purchase-in': 'List Purchase In',
  'titlebar-purchase-in-edit': 'Edit Purchase In',
  'titlebar-purchase-in-confirm': 'Confirm Purchase In',
  'titlebar-purchase-return': 'List Purchase Return',
  'titlebar-purchase-return-create': 'Create Purchase Return',
  'titlebar-purchase-return-edit': 'Edit Purchase Return',
  'titlebar-purchase-receive': 'Receive Purchase',
  'titlebar-sale-invoice': 'List Sale Invoice',
  'titlebar-sale-invoice-create': 'Create Invoice',
  'titlebar-sale-invoice-sale-return': 'List Sale Return',
  'titlebar-sale-invoice-edit': 'Edit Invoice',
  'titlebar-sale-invoice-repack': 'List Repack',
  'titlebar-stock': 'List Stock',
  'titlebar-stock-adjust': 'Adjust Stock',
  'titlebar-storage': 'List Storage',
  'titlebar-user': 'List User',
  'titlebar-user-create': 'Create User',
  'titlebar-user-edit': 'Edit User',
  'titlebar-report': 'Report',
  'titlebar-report-sale': 'Sale Report',
  'titlebar-report-sale-by-user': 'Sale By User',
  'titlebar-report-sale-by-customer': 'Sale By Customer',
  'titlebar-report-sale-by-product': 'Sale By Product',
  'titlebar-report-payment': 'Payment Report',
  'titlebar-report-profit-and-loss': 'Profit and Loss',
  'titlebar-payment-account': 'List Payment Account',
  'titlebar-sale-target': 'List Sale Target',
  'titlebar-pos': 'List POS',
  'titlebar-sale-pos': 'List Sale POS',
  'titlebar-setting': 'Setting',
  'titlebar-setting-profile': 'Profile',
  'titlebar-delivery-setting': 'Delivery Setting',
  'titlebar-role-and-permission': 'Role And Permission',
  'titlebar-role-and-permission-create': 'Create Role And Permission',
  'titlebar-role-and-permission-edit': 'Edit Role And Permission',

  // dashboard
  'total-paid': 'Total Paid',
  'total-commission': 'Total Commission',
  'total-amount-due': 'Total AmountDue',
  'total-approved': 'Total Approved',
  'total-rejected': 'Total Rejected',
  'total-sale': 'Total Sale',
  'total-order': 'Total Order',
  'total-products': 'Total Products',
  'low-stock-products': 'Low Stock Products',
  'expiry-products': 'Expiry Products',
  'stock-price': 'Stock Price',
  'total-purchase': 'Total Purchase',

  //report
  'total-expense': 'Total Expense',
  'total-profit': 'Total Profit',
  'shipping-profit': 'Shipping Profit',
  'sale-profit': 'Sale Profit',
  'total-cost': 'Total Cost',
  'total-cost-price': 'Total Cost Price',
  'total-pieces': 'Total Pieces',
  'total-shipping-profit': 'Total Shipping Profit',
  'select-date': 'Select Date',
  today: 'Today',
  yesterday: 'Yesterday',
  'this-week': 'This Week',
  'last-week': 'Last Week',
  'this-month': 'This Month',
  'last-month': 'Last Month',
  'profit-and-loss': 'Profit and Loss',
  sales: 'Sales',
  'trading-income': 'Trading Income',
  'total-trading-income': 'Total Trading Income',
  'cost-of-sale': 'Cost of Sale',
  purchase: 'Purchase',
  'total-cost-of-sales': 'Total Cost Of Sales',
  'gross-profit': 'Gross Profit',
  'operating-expenses': 'Operating Expenses',
  'total-operating-expenses': 'Total Operating Expenses',
  'net-profit': 'Net Profit',
  // product page
  'create-product': 'Create Product',
  'edit-product': 'Edit Product',
  'summary-description': 'Summary Description',
  'age-variation': 'Age Variation',
  'create-attributes-and-options': 'CREATE ATTRIBUTES AND OPTIONS',
  'purchase-information': 'Purchase Information',
  'selling-price': 'Selling Price',
  'product-description': 'Product Description',
  'selling-information': 'Selling Information',
  attribute: 'Attribute',
  options: 'Options',
  'add-more-attribute': 'Add More Attributes',
  'item-name': 'Item Name',
  'product-information': 'Product Information',
  'generate-sku': 'Generate SKU',
  'copy-to-all': 'Copy to All',
  'product-sku-data-insufficient': 'Please fill in (product name, vendor)',
  'product-slug-data-insufficient': 'Please fill in (product name)',
  'composite-sku-data-insufficient': 'Please fill in (product name)',
  'fill-product-name': 'Please fill in product name',
  'fill-in-first-field': 'Please fill in the first field',
  'sale-price': 'Sale Price',
  different: 'Different',
  'variant-already-exist': 'Variant already exist',
  'add-variant': 'Add Variant',
  'convert-from': 'Convert From',
  'convert-to': 'Convert To',
  'category-already-existed': 'Category already existed',
  tags: 'Tags',
  keywords: 'Keywords',

  // composite page
  'composite-information': 'Composite Information',
  'composite-items': 'Composite Items',
  'create-composite': 'Create Composite',
  'composite-name': 'Composite Name',

  // unit page
  'unit-name': 'Unit Name',
  'create-unit': 'Create Unit',
  'unit-form-title': 'UNIT INFORMAION',
  'unit-placeholder': 'EG: PCS',

  // brand page
  'brand-form-title': 'BRAND INFORMATION',
  'create-brand': 'Create Brand',
  'brand-name': 'Brand Name',

  // category page
  'create-category': 'Create Category',
  'category-form-title': 'CATEGORY INFORMATION',
  'category-name': 'CATEGORY NAME',
  'parent-category': 'PARENT CATEGORY',

  // currency page
  'currency-name': 'Currency Name',
  'currency-symbol': 'Currency Symbol',
  'currency-form-title': 'CURRENCY INFORMATION',
  'price-per-dollar': 'Price Per 1USD',
  'create-currency': 'New Currency',

  // vendor page
  'company-information': 'Company Information',
  'vendor-form-title': 'VENDOR INFORMATION',
  'contact-information': 'Contact Information',
  'create-vendor': 'Create Vendor',
  'edit-vendor': 'Edit Vendor',
  'add-more-contact': 'Add more contact',

  // customer
  'customer-information': 'CUSTOMER INFORMATION',
  'select-customer': 'SELECT CUSTOMER',
  'create-customer': 'Create Customer',
  'edit-customer': 'Edit Customer',
  nearby: 'Nearby',
  'city-province': 'City / Province',
  district: 'District',
  commune: 'Commune',
  'google-map-url': 'Google Map URL',

  // user
  'create-user': 'Create User',
  'invite-user': 'Invite User',

  // role and permission
  roles: 'Roles',
  rolename: 'Role Name',
  'create-role': 'Create New Role',
  products: 'Products',
  category: 'Category',
  'purchase-order': 'Purchase Order',
  'purchase-in': 'Purchase In',
  'purchase-return': 'Purchase Return',
  orders: 'Orders',
  supplier: 'Supplier',
  report: 'Report',
  'exchange-rate': 'Exchange Rate',
  stock: 'Stock',
  view: 'View',
  reconcile: 'Reconcile',
  'create-payment': 'Create Payment',
  'view-payment': 'View Payment',
  'delete-payment': 'Delete Payment',
  packed: 'Packed',
  'sale-return': 'Sale Return',
  repack: 'Repack',
  'delete-returned': 'Delete Returned',
  'view-activity': 'View Activity',
  dashboard: 'Dashboard',
  pos: 'POS',
  'create-role-validation-failed': 'Validation Failed when Creating Role',
  'role-name-already-existed': 'Role Name Already Existed',
  'new-role-created': 'New Role Created',
  'failed-to-create-a-new-role': 'Failed To Create a New Role',
  'role-updated-successfully': 'Role Update Successfully',
  'failed-to-update-role-and-permission':
    'Failed to Update Role and Permission',
  'role-not-found': 'Role Not Found',
  'failed-to-get-single-role': 'Failed to Get Single Role',
  'failed-to-get-all-roles': 'Failed to Get All Roles',

  // billing
  'create-billing': 'Create Billing',
  'edit-billing': 'Edit Billing',
  'billing-number': 'Billing No',
  'paid-by': 'Paid By',
  'cancel-billing': 'Cancel Billing',

  // discount
  'create-discount': 'Create Discount',
  'discount-name': 'Discount Name',
  'discount-amount': 'Discount Amount',
  'discount-website': 'Website discount',
  'discount-pos': 'POS discount',
  'expired-date': 'Expired Date',
  'start-date': 'Start Date',
  'edit-discount': 'Edit Discount',
  'delete-discount': 'Delete Discount',
  active: 'Active',
  inactive: 'Inactive',

  // purchase order
  'purchase-order-number': 'Purchase Order No.',
  'reference-number': 'Reference No.',
  'expected-delivery-date': 'Expected Delivery Date',
  'expiry-date': 'Expiry Date',
  'create-purchase': 'Create Purchase',
  'edit-purchase': 'Edit Purchase',
  'expected-delivery': 'Expected Delivery',
  'vendor-name': 'Vendor Name',

  //purchase return
  'create-purchase-return': 'Create Purchase Return',

  // sale-invoice
  'invoice-not-found': 'Invoice not found',
  'invoice-number': 'Invoice No',
  'customer-name': 'Customer Name',
  'create-invoice': 'Create Invoice',
  'edit-invoice': 'Edit Invoice',
  'select-payment-methods': 'SELECT PAYMENT METHODS',
  'payment-methods': 'Payment Methods',
  'cash-on-delivery': 'Cash on delivery',
  'online-payment': 'Online Payment',
  'cash-transfer': 'Cash Transfer',
  'bank-transfer': 'Bank Transfer',
  'invoice-detail': 'INVOICE DETAIL',
  'total-returned-over-amount-due': 'Total returned over amount due',
  'paid-to': 'Paid to',
  'parcel-size': 'Parcel Size',
  'who-pay': 'Who Pay?',
  'instructions-to-driver': 'Instructions to Driver',
  'shipping-with-provider': 'Shipping with Provider',
  'sale-by': 'Sale By',
  'update-sale-by-to': 'Update sale by to',
  'update-sale-by-from': 'Update sale by from',
  'update-customer-to': 'Update customer to',
  'update-customer-from': 'Update customer from',
  to: 'To',
  'edit-invoice-date': 'Edit Invoice Date',
  'invoice-date': 'Invoice Date',
  'shipping-expense': 'Shipping Expense',
  'from-website': 'From Website',
  'main-order-id': 'Main Id',

  //payment-account-code
  'payment-account-form-title': 'PAYMENT ACCOUNT INFORMATION',
  'create-payment-account': 'Create Payment Account',
  'account-name': 'Account Name',
  'account-code': 'Account Code',

  //sale-target
  'sale-target-form-title': 'SALE TARGET INFORMATION',
  'create-sale-target': 'Create Sale Target',
  'sale-amount': 'Sale Amount',
  'commission-percentage': 'Commission Percentage',

  //pos
  'pos-form-title': 'POS INFORMATION',
  'create-pos': 'Create POS',
  available: 'Available',
  unavailable: 'Unavailable',
  'pos-feature-disable-message':
    'POS feature is currently disable. Contact Admin to enable',

  // storage
  'create-storage': 'Create Storage',
  'storage-form-title': 'STORAGE INFORMATION',

  // stock
  'stock-detail': 'Stock Detail',
  'create-adjust-stock': 'Create Adjust Stock',
  convert: 'Convert',
  converting: 'Converting',
  'conversion-rate': 'Conversion Rate',
  from: 'From',
  'convert-unit': 'Convert Unit',

  //login-page
  'email-or-phone': 'Email / Phone Number',
  password: 'Password',
  'new-password': 'New Password',
  login: 'Login',
  'forgot-password': 'Forgot your password?',
  'need-account': 'Need an Account?',
  'failed-login-attempt':
    'You have remaining of {{remainingAttempt}} attempts. After {{maxAttempt}} consecutive unsuccessful login attempts, your account will be locked.',
  'account-locked':
    'Your account has been temporarily locked due to several incorrect login attempts. Contact Admin!',

  //register-page
  register: 'Register',
  'already-have-account': 'Already have an account?',
  'otp-send-title': 'Verify {{type}}',
  'otp-send-message': "We'll send your verification code to {{value}}.",
  send: 'Send',
  'otp-sent-title': 'We sent you a code',
  'otp-sent-message': 'Enter it below to verify {{value}}.',
  'otp-not-received': "Didn't received Code?",
  'resend-otp': 'Resend Code',
  'use-otp-other-type': 'Use {{type}} instead',
  'verification-code': 'Verification Code',
  next: 'Next',
  'warehouse-name': 'Warehouse Name',
  'required-strong-password':
    'Password require minimum 8 characters, at least 1 uppercase letter, 1 lowercase letter and 1 number',

  //forgot password page
  'reset-password': 'Reset Password',
  'reset-password-message':
    'Enter new password and the verify code that sent to {{value}}',
  'forgot-password-message':
    "Don't worry! Just fill in your email/phone and we'll send you a verify code to reset your password.",
  'enter-email-reset': 'Enter Email to Reset',
  'forgot-password-email-sent': 'Forgot Password Email Sent',
  'email-not-existed': 'Email Not Existed',
  'back-to-login': 'Back to login',

  //reset page
  'confirm-password': 'Confirm Password',
  reset: 'Reset',
  'password-reset-successful': 'Password Reset Successfully',
  'password-reset-failed': 'Password Reset Failed',
  'find-reset-password-value-failed': 'Redis Key Not Existed',
  'hash-not-match': 'Hash Not Match',
  'redis-no-reset-value': 'Redis Key Not Existed',
  'checking-reset-link': 'Checking reset link',
  'invalid-reset-link': 'Invalid reset link',
  'invalid-reset-link-message':
    'It looks like you clicked on an invalid password reset link. Please try again.',

  //setting
  //auto sequence setting
  'automatic-sequencing': 'Automatic Sequencing',
  'invoice-prefix': 'Invoice Prefix',
  'next-number': 'Next Number',
  'purchase-order-prefix': 'Purchase Order Prefix',
  'track-stock': 'Track Stock',
  //warehouse
  'warehouse-information': 'Warehouse Information',
  social: 'Social',
  'facebook-page-id': 'Facebook Page Id',
  'below-distance': 'Below Distance',
  fee: 'Fee',
  'free-shipping': 'Free Shipping',
  'subscribe-telegram': 'Scan or Click to Subscribe',
  // delivery
  // account
  'old-password': 'Old Password',
  'new-password': 'New Password',
  'update-password': 'Update Password',
  'account-information': 'Account Information',

  // Sidebar
  'app-sidebar-dashboard': 'Dashboard',
  'app-sidebar-product': 'Product',
  'app-sidebar-product-list': 'List Product',
  'app-sidebar-product-add': 'Add Product',
  'app-sidebar-composite-add': 'Add Composite',
  'app-sidebar-product-listVariant': 'List Variant Product',
  'app-sidebar-composite-list': 'List Composite',
  'app-sidebar-product-addVariant': 'Add Variant Product',
  'app-sidebar-product-unit': 'Unit',
  'app-sidebar-product-brand': 'Brand',
  'app-sidebar-sale': 'Sale Invoice',
  'app-sidebar-sale-add': 'Create Invoice',
  'app-sidebar-sale-repack': 'Repack',
  'app-sidebar-sale-saleReturn': 'Sale Return',
  'app-sidebar-sale-list': 'Invoice List',
  'app-sidebar-sale-pos': 'Sale POS',
  'app-sidebar-sale-pos-list': 'Sale List',
  'app-sidebar-category': 'Category',
  'app-sidebar-category-list': 'Category',
  'app-sidebar-category-add': 'Add Category',
  'app-sidebar-customer': 'Customer',
  'app-sidebar-customer-list': 'List Customer',
  'app-sidebar-customer-add': 'Add Customer',
  'app-sidebar-discount': 'Discount',
  'app-sidebar-discount-list': 'List Discount',
  'app-sidebar-discount-add': 'Add Discount',
  'app-sidebar-vendor': 'Vendor',
  'app-sidebar-vendor-list': 'List Vendor',
  'app-sidebar-vendor-add': 'Add Vendor',
  'app-sidebar-purchase': 'Purchase',
  'app-sidebar-purchase-list': 'Purchase Order',
  'app-sidebar-purchase-add': 'Add Purchase Order',
  'app-sidebar-warehouse': 'Warehouse',
  'app-sidebar-warehouse-transferProduct': 'Product Transfer',
  'app-sidebar-warehouse-addTransferProduct': 'Add Product Transfer',
  'app-sidebar-users': 'Users',
  'app-sidebar-users-list': 'List Users',
  'app-sidebar-users-add': 'Add Users',
  'app-sidebar-memberCard': 'Membership Card',
  'app-sidebar-memberCard-list': 'List Card',
  'app-sidebar-memberCard-add': 'Add Card',
  'app-sidebar-event': 'Event',
  'app-sidebar-event-coupon': 'Coupon',
  'app-sidebar-event-productDiscount': 'Product Discount',
  'app-sidebar-reports': 'Reports',
  'app-sidebar-reports-saleReport': 'Sale Report',
  'app-sidebar-reports-sale-by-product': 'Sale By Product',
  'app-sidebar-reports-sale-by-user': 'Sale By User',
  'app-sidebar-reports-sale-by-customer': 'Sale By Customer',
  'app-sidebar-reports-paymentReport': 'Payment Report',
  'app-sidebar-reports-productReport': 'Product Report',
  'app-sidebar-reports-saleByCategory': 'Sale By Category',
  'app-sidebar-reports-saleByPaymentType': 'Sale By Payment Type',
  'app-sidebar-reports-saleByStaff': 'Sale By Staff',
  'app-sidebar-reports-shift': 'Shift',
  'app-sidebar-reports-profitAndLose': 'Profit and Lose',
  'app-sidebar-setting': 'Setting',
  'app-sidebar-setting-brand': 'Brand',
  'app-sidebar-setting-currencyOption': 'Currency Option',
  'app-sidebar-setting-paymentType': 'Payment Type',
  'app-sidebar-setting-store': 'Store',
  'app-sidebar-setting-pos': 'POS',
  'app-sidebar-setting-payment-account': 'Payment Account',
  'app-sidebar-setting-sale-target': 'Sale Target',
  'app-sidebar-setting-general': 'General',
  'app-sidebar-setting-delivery': 'Delivery',
  'app-sidebar-setting-role-and-permission': 'Role and Permission',
  'app-sidebar-setting-role-add': 'Add Role',
  'app-sidebar-setting-profile': 'Profile',
  'app-sidebar-stock': 'Stock',
  'app-sidebar-stock-adjust': 'Adjust Stock',
  'app-sidebar-stock-list': 'List Stock',
  'app-sidebar-storage': 'Storage',
  'app-sidebar-storage-list': 'List Storage',
  'app-sidebar-billing': 'Billing',
  'app-sidebar-billing-list': 'Billing',
  'app-sidebar-billing-add': 'Add Billing',
  'app-sidebar-purchase-in-list': 'Purchase In',
  'app-sidebar-purchase-return-list': 'Purchase Return',
  // dialog
  'app-dialog-cancelButton': 'Cancel',
  'app-dialog-addButton': 'Add',
  'app-dialog-updateButton': 'Update',
};

export default APP_EN;
