import { Grid, Paper, makeStyles } from '@material-ui/core';
import { IoIosCart } from 'react-icons/io';
import { FaWarehouse, FaMoneyCheckAlt } from 'react-icons/fa';
import React, { useEffect, useState } from 'react';
import axios from 'axios';
import Card from './card';
import moment from 'moment';
import { DateRangePicker } from 'react-dates';
import { useTranslation } from 'react-i18next';
import GraphTotalCostAndSalePrice from './graph-cost-and-sale-price';
import { formatUTCDate } from 'src/util/date-format';

const useStyles = makeStyles(() => ({
  dateSelect: {
    padding: '5px',
    '& .DateRangePicker > div': {
      display: 'flex',
    },
    '& .DateRangePickerInput': {
      borderRadius: '3px',
      overflow: 'hidden',
    },
  },
}));

const DashboardPage = () => {
  const { t } = useTranslation();
  const classes = useStyles();
  const [summary, setSummary] = useState({});
  const [date, setDate] = useState({
    startDate: moment().startOf('month'),
    endDate: moment().endOf('month'),
    focusedInput: false,
  });

  useEffect(() => {
    if (date.startDate && date.endDate) {
      axios
        .get(
          `${
            process.env.REACT_APP_API
          }/api/v1/dashboard/summary?fromDate=${formatUTCDate(
            date.startDate
          )}&toDate=${formatUTCDate(date.endDate)}`
        )
        .then(({ data }) => setSummary(data))
        .catch((err) => console.log(err));
    }
  }, [date]);

  const cardList = [
    {
      icon: <FaMoneyCheckAlt />,
      top: [
        { name: t('total-paid'), value: summary.order?.paid },
        { name: t('total-approved'), value: summary.order?.approved },
        { name: t('total-rejected'), value: summary.order?.rejected },
      ],
      bottom: {
        name: t('total-sale'),
        value: summary.order?.sale,
      },
    },
    {
      icon: <IoIosCart />,
      top: [
        { name: t('packing'), value: summary.order?.packing, format: 'normal' },
        {
          name: t('shipping'),
          value: summary.order?.shipping,
          format: 'normal',
        },
        {
          name: t('delivered'),
          value: summary.order?.delivered,
          format: 'normal',
        },
      ],
      bottom: {
        name: t('total-order'),
        value: summary.order?.order,
        format: 'normal',
      },
    },
    {
      icon: <FaWarehouse />,
      top: [
        {
          name: t('total-products'),
          value: summary.stock?.product,
          format: 'normal',
        },
        {
          name: t('low-stock-products'),
          value: summary.stock?.lowStock,
          format: 'normal',
        },
        {
          name: t('expiry-products'),
          value: summary.stock?.expire,
          format: 'normal',
        },
      ],
      bottom: {
        name: t('stock-price'),
        value: summary.stock?.price,
      },
    },
  ];

  const handleDateChange = ({ startDate, endDate }) => {
    setDate((prev) => {
      return { ...prev, startDate, endDate };
    });
  };

  return (
    <Grid
      container
      direction='column'
      spacing={3}
      alignItems='flex-end'
      style={{ height: '100%' }}
    >
      <Grid item>
        <Paper className={classes.dateSelect}>
          <DateRangePicker
            noBorder
            hideKeyboardShortcutsPanel
            isOutsideRange={() => false}
            startDateId='start_date' // PropTypes.string.isRequired,
            endDateId='end_date' // PropTypes.string.isRequired,
            startDate={date.startDate}
            endDate={date.endDate}
            onDatesChange={handleDateChange}
            focusedInput={date.focusedInput}
            onFocusChange={(focusedInput) =>
              setDate((prev) => ({ ...prev, focusedInput }))
            }
          />
        </Paper>
      </Grid>
      <Grid container item spacing={3}>
        {cardList.map((item, index) => (
          <Grid item lg={4} sm={6} xs={12} key={index}>
            <Card top={item.top} bottom={item.bottom} icon={item.icon} />
          </Grid>
        ))}
      </Grid>
      <Grid container item spacing={3}>
        <Grid item xs={12} lg={6} xl={4}>
          <GraphTotalCostAndSalePrice
            data={summary?.totalSaleCostPrice}
            date={date}
          />
        </Grid>
      </Grid>
    </Grid>
  );
};

export default DashboardPage;
