import { Chip } from '@material-ui/core';
import { Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import AutoCompleteAsync from '../autocomplete-async';
import LabelInput from '../label-input';

const TagInput = ({ control }) => {
  const { t } = useTranslation();
  return (
    <Controller
      control={control}
      name='tags'
      defaultValue={[]}
      render={({ field: { value, onChange } }) => {
        return (
          <LabelInput
            label={t('tags')}
            renderTags={(value, getTagProps) =>
              value.map((option, index) => (
                <Chip
                  style={{ margin: '1px 5px 1px 0' }}
                  size='small'
                  color='primary'
                  label={option}
                  {...getTagProps({ index })}
                />
              ))
            }
            filterSelectedOptions
            freeSolo
            autoHightlight
            multiple
            component={AutoCompleteAsync}
            value={value}
            onChange={onChange}
            placeholder={t('tags')}
            optionsURL={`${process.env.REACT_APP_API}/api/v1/categories`}
            getOptions={(data) =>
              data?.categories.map((category) => category.name)
            }
            renderOption={(data) => {
              if (typeof data === 'string') {
                return data;
              }
            }}
            getOptionLabel={(option) => option}
            getOptionSelected={(option, value) => option === value}
          />
        );
      }}
    ></Controller>
  );
};
export default TagInput;
