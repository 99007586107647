import { makeStyles, TextField, Button } from '@material-ui/core';
import React from 'react';
import { useController } from 'react-hook-form';
import useRootStyle from '../utils/useRootStyle';

const useStyles = makeStyles(() => ({
  button: {
    minWidth: '30px',
    maxWidth: '30px',
    marginLeft: '10px',
  },
  root: {
    '& .MuiOutlinedInput-adornedEnd': {
      paddingRight: '4px',
    },
  },
}));

const discountType = {
  percentage: '%',
  currency: '$',
};

const RHFDiscountInput = (props) => {
  const {
    name,
    control,
    required,
    defaultValue = { amount: '', type: 'percentage' },
    variant = 'outlined',
    size = 'small',
    label,
    fullWidth = true,
    className = '',
    textAlign,
    helperTextAlign,
    min = 0,
    rules,
    disabled,
    onChange,
    ...textFieldProps
  } = props;

  const rootClasses = useRootStyle({ textAlign, helperTextAlign });

  const classes = useStyles();

  // using react hook form useController as value state
  const {
    field: {
      ref,
      value = { amount: '', type: 'percentage' },
      onChange: ucChange,
      ...inputProps
    },
    fieldState: { error },
  } = useController({
    name,
    control, //optional when use inside formContext
    rules: { required, ...rules },
    defaultValue,
  });

  const handleChange = (e) => ucChange({ ...value, amount: e.target.value });

  return (
    <TextField
      className={`${classes.root} ${rootClasses.root} ${className}`}
      variant={variant}
      required={required}
      fullWidth={fullWidth}
      size={size}
      value={value?.amount}
      type='number'
      onChange={
        onChange
          ? (e) => {
              onChange(e, { ...value, amount: e.target.value }, ucChange);
            }
          : handleChange
      }
      inputRef={ref}
      error={!!error}
      inputProps={{ min, ...textFieldProps.inputProps }}
      disabled={disabled}
      {...textFieldProps}
      {...inputProps}
      InputProps={{
        endAdornment: (
          <Button
            disabled={disabled}
            classes={{ root: classes.button }}
            onClick={() =>
              ucChange({
                ...value,
                type: value?.type === 'percentage' ? 'currency' : 'percentage',
              })
            }
            disableElevation
            variant='contained'
            color='primary'
            size='small'
          >
            {discountType[value?.type]}
          </Button>
        ),
      }}
    />
  );
};

export default RHFDiscountInput;
