import { Chip, TextField } from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import { Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import LabelInput from '../label-input';

const ChipInput = ({ control, name, label, placeholder }) => {
  const { t } = useTranslation();
  return (
    <Controller
      control={control}
      name={name}
      defaultValue={[]}
      render={({ field: { value, onChange } }) => {
        return (
          <LabelInput
            label={label}
            component={Autocomplete}
            value={value}
            onChange={(_, data) => onChange(data)}
            size='small'
            freeSolo
            multiple
            options={[]}
            renderInput={(params) => (
              <TextField
                placeholder={placeholder || label}
                variant='outlined'
                {...params}
              />
            )}
            renderTags={(value, getTagProps) =>
              value.map((option, index) => (
                <Chip
                  style={{ margin: '1px 5px 1px 0' }}
                  size='small'
                  color='primary'
                  label={option}
                  {...getTagProps({ index })}
                />
              ))
            }
          />
        );
      }}
    />
  );
};
export default ChipInput;
