import React from 'react';
import { makeStyles, IconButton } from '@material-ui/core';
import {
  CarouselProvider,
  Slider,
  Slide,
  ButtonBack,
  ButtonNext,
} from 'pure-react-carousel';
import 'pure-react-carousel/dist/react-carousel.es.css';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import ImageSlide from './image-slide';

const useStyles = makeStyles(() => ({
  imageSliderContainer: {
    marginTop: '5px',
    alignSelf: 'normal',
    margin: '0  40px',
  },
  buttonPrev: {
    position: 'absolute',
    top: '50%',
    marginLeft: '-30px !important',
    left: '0',
    transform: 'translateY(-50%)',
    '&:disabled': {
      visibility: 'hidden',
    },
  },
  buttonNext: {
    position: 'absolute',
    top: '50%',
    marginRight: '-30px !important',
    right: '0',
    transform: 'translateY(-50%)',
    '&:disabled': {
      visibility: 'hidden',
    },
  },
  slider: {
    paddingTop: '10px',
    outline: 0,
  },
}));

const ImagePreview = ({ images, handleRemove }) => {
  const classes = useStyles();

  return (
    <CarouselProvider
      naturalSlideWidth={90}
      visibleSlides={6}
      naturalSlideHeight={65}
      totalSlides={images.length}
      className={classes.imageSliderContainer}
    >
      <div style={{ position: 'relative' }}>
        <Slider className={classes.slider}>
          {images.map((image, index) => (
            <Slide index={index} key={index}>
              <ImageSlide onRemove={() => handleRemove(index)} image={image} />
            </Slide>
          ))}
        </Slider>
        {images.length > 0 && (
          <>
            <IconButton
              component={ButtonBack}
              color="primary"
              size="small"
              className={classes.buttonPrev}
            >
              <ChevronLeftIcon />
            </IconButton>
            <IconButton
              component={ButtonNext}
              color="primary"
              size="small"
              className={classes.buttonNext}
            >
              <ChevronRightIcon />
            </IconButton>
          </>
        )}
      </div>
    </CarouselProvider>
  );
};

export default ImagePreview;
