import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import './translations/i18n';
import reportWebVitals from './reportWebVitals';
import axios from 'axios';
import 'src/util/firebase';

axios.defaults.withCredentials = true;

// check if forbidden will log user out
axios.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error && error.response?.status === 403) {
      window.location.reload();
    }

    return Promise.reject(error);
  }
);

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
