import React, { useCallback, useContext, useState } from 'react';
import TitleBar from '../../components/title-bar';
import { Button, Grid, Typography } from '@material-ui/core';
import config from '../../components/tableX/config';
import ProductImage from '../../components/product-image';
import StockDetailPopUp from './detail-popup';
import useDisplay from '../../util/useDisplay';
import Status from '../../components/status';
import axios from 'axios';
import useConfirmationPrompt from '../../components/useConfirmationPromp';
import { UtilityContext } from '../../components/context-provider/utilty-context';
import { UserContext } from '../../components/context-provider/user-context';
import TableTabFilter from '../../components/table-with-filter';
import { useTabFilter } from '../../components/tab-filter';
const filterStatus = ['All', 'Published', 'Unpublished'];

const StockPage = () => {
  const { display, t } = useDisplay();

  const [triggerFetch, setTriggerFetch] = useState({});
  const [stockDetail, setStockDetail] = useState({
    open: false,
    data: {},
  });

  const { showSnackbar } = useContext(UtilityContext);
  const { checkAccess } = useContext(UserContext);
  const { showPrompt, closePrompt } = useConfirmationPrompt();

  const handleToggleActive = (id, status) => {
    showPrompt({
      message: <Typography>{t('are-you-sure')}</Typography>,
      handleConfirm: () => onConfirmToggle(id, status),
    });
  };

  const onConfirmToggle = (id, status) => {
    const isActive = status === 'unpublished';
    axios
      .put(
        `${process.env.REACT_APP_API}/api/v1/stocks/set-active/${id}?value=${isActive}`
      )
      .then(({ data }) => {
        showSnackbar({ message: data?.message });
        setTriggerFetch((prev) => !prev); // refresh tableX
      })
      .catch(({ response }) => {
        showSnackbar({ message: response.data?.message, variant: 'error' });
      })
      .finally(() => closePrompt());
  };

  const tableSchema = [
    {
      id: 'image',
      label: t('image'),
      dataConfig: ['variant', 'images', '0', 'thumbnail'],
      type: 'component',
      sort: false,
      components: [
        {
          name: ProductImage,
          customValueProp: 'src',
          props: { style: { margin: '0 auto' } },
        },
      ],
    },
    {
      id: 'variant.name',
      label: t('product-name'),
      dataConfig: ['variant', 'name'],
      align: 'left',
      type: 'component',
      components: (name) => {
        return (
          <Typography className='ellipsis' align='left'>
            {name}
          </Typography>
        );
      },
    },
    {
      id: 'qty',
      label: t('quantity'),
    },
    {
      id: 'variant.sku',
      label: t('sku'),
      type: 'component',
      dataConfig: ['variant', 'sku'],
      components: (data) => {
        return display(data, { translateValue: false });
      },
    },
    {
      id: 'variant.altCode',
      label: t('upc'),
      type: 'component',
      dataConfig: ['variant', 'altCode'],
      components: (data) => {
        return display(data, { translateValue: false });
      },
    },
    {
      id: 'isActive',
      label: t('status'),
      type: 'component',
      dataConfig: [],
      components: (data) => {
        const status = data?.isActive ? 'published' : 'unpublished';
        return <Status status={status} />;
      },
    },
    {
      label: t('action'),
      type: 'component',
      dataConfig: [],
      sort: false,
      components: (data) => {
        const status = data?.isActive ? 'published' : 'unpublished';
        // button props when status match
        const options = {
          published: {
            color: 'secondary',
            text: 'unpublish',
          },
          unpublished: {
            color: 'primary',
            text: 'publish',
          },
        };

        if (!checkAccess({ feature: 'stock', action: 'update' })) return null;
        return (
          <Button
            fullWidth
            variant='contained'
            onClick={(e) => {
              e.stopPropagation();
              handleToggleActive(data._id, status);
            }}
            size='small'
            disableElevation
            {...options[status]}
          >
            {t(options[status].text)}
          </Button>
        );
      },
    },
  ];

  const { value, changeFilter } = useTabFilter(0);

  const defaultFilter = useCallback(() => {
    return value > 0 ? { isActive: value === 1 } : {};
  }, [value]);

  const handleRowClick = (data) => {
    setStockDetail({ open: true, data });
  };

  const handleCloseDetail = () => {
    setStockDetail({ open: false, data: {} });
  };

  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <TitleBar buttonText={t('create-product')} disableButton></TitleBar>
      </Grid>
      <Grid item xs={12}>
        <TableTabFilter
          tabFilterProps={{
            options: filterStatus,
            value,
            onChange: changeFilter,
          }}
          tableXProps={{
            select: false,
            triggerFetch,
            onRowClick: handleRowClick,
            defaultFilter,
            config: tableConfig,
            schema: tableSchema,
          }}
        />
      </Grid>
      <StockDetailPopUp
        refreshTable={() => setTriggerFetch((prev) => !prev)}
        state={stockDetail}
        onClose={handleCloseDetail}
      />
    </Grid>
  );
};

const tableConfig = {
  ...config,
  dataConfig: ['stocks'],
  url: `${process.env.REACT_APP_API}/api/v1/stocks`,
};

export default StockPage;
