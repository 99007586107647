import { Checkbox, TableCell, TableHead, TableRow } from '@material-ui/core';
import { useTranslation } from 'react-i18next';

// Example Data
// const tableHeadData = [
//     { value: "no." },
//     { value: "image", width: "80px" },
//     { value: "product-name", width: "30%" },
//     { value: "quantity" },
//     { value: "unit-price" },
//     { value: "tax" },
//     { value: "expiry-date", width: "15%" },
//     { value: "amount", align: "right", width: "10%" },
//     { width: "50px" },
//   ];

const CustomTableHead = ({
  data,
  enableCheckBox = false,
  checkboxProps,
  style,
}) => {
  const { t } = useTranslation();
  return (
    <TableHead>
      <TableRow>
        {enableCheckBox && (
          <TableCell align="center" width="10px">
            <Checkbox color="primary" size="small" {...checkboxProps} />
          </TableCell>
        )}
        {data?.map(({ value, width, align = 'center' }, index) => (
          <TableCell style={style} width={width} align={align} key={index}>
            {typeof value === 'string' ? t(value) : value}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
};

export default CustomTableHead;
