import {
  Dialog,
  DialogContent,
  DialogTitle,
  makeStyles,
  Grid,
  IconButton,
  Button,
  Typography,
  Box,
  Collapse,
} from '@material-ui/core';
import React, { useState, useRef } from 'react';
import CloseIcon from '@material-ui/icons/Close';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import Items from './items';
import TotalDisplay from '../../../components/total-display';
import axios from 'axios';
import BackDropSpinner from '../../../components/backdrop-spinner';
import clsx from 'clsx';
import { useReactToPrint } from 'react-to-print';
import PurchaseInvoice from './invoice';
import NoteInvoice from '../../../components/note-invoice';
import { useAuth } from 'src/components/context-provider/user-context';
import BillingHistory from './billing-history';

const useStyles = makeStyles(() => ({
  title: {
    background: '#EEEEEE',
    '& h2': {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      fontWeight: 'normal',
    },
  },
  pBold: {
    '& p': {
      fontWeight: 'bold',
    },
  },
  table: {
    fontSize: '14px',
    '& th': {
      fontWeight: 'bold',
    },
  },
  bold: {
    fontWeight: 'bold',
    textAlign: 'right',
  },
  textRight: {
    textAlign: 'right',
  },
  dialogContent: {
    height: '90vh',
    overflow: 'overlay',
  },
}));

const BillingDetailPopup = ({ state, onClose }) => {
  const classes = useStyles();
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState({});
  const { checkAccess } = useAuth();
  const [showHistory, setShowHistory] = useState(false);
  const [triggerFetch, setTriggerFetch] = useState(false);

  const { open } = state;

  const { t } = useTranslation();

  const onEnter = async () => {
    setLoading(true);
    try {
      const {
        data: { billing },
      } = await axios.get(
        `${process.env.REACT_APP_API}/api/v1/billing/${state.data?._id}`
      );

      setData(billing);
    } catch (err) {
      console.log(err);
    }
    setLoading(false);
  };

  const onExited = () => {
    setData({});
    setShowHistory(false);
  };

  const invoiceRef = useRef();
  const handlePrintInvoice = useReactToPrint({
    content: () => invoiceRef.current,
  });

  return (
    <Dialog
      open={open}
      onClose={onClose}
      TransitionProps={{
        onEnter,
        onExited,
      }}
      fullWidth
      maxWidth='md'
    >
      <DialogTitle className={classes.title}>
        <Grid container direction='column'>
          <Grid item container justify='space-between' alignItems='center'>
            <Grid item xs='auto'>
              <Grid container alignItems='center'>
                <Grid item>
                  <Typography>Billing Detail</Typography>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs='auto'>
              <IconButton
                className={classes.hiddenOnXS}
                color='secondary'
                onClick={onClose}
              >
                <CloseIcon />
              </IconButton>
            </Grid>
          </Grid>
          <Grid item container justify='space-between'>
            <Grid item xs='auto'>
              <Button
                disableElevation
                className={clsx({ invisible: loading })}
                variant='contained'
                color='primary'
                onClick={handlePrintInvoice}
              >
                {t('print')}
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </DialogTitle>
      <DialogContent
        classes={{ root: classes.dialogContent }}
        style={{ position: 'relative' }}
      >
        <BackDropSpinner backgroundColor='white' open={loading} />
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Grid container style={{ padding: '0 15px' }}>
              <Grid item xs>
                <Grid container spacing={1}>
                  <Grid item className={classes.pBold}>
                    <p>{`${t('date')} : `}</p>
                    <p>{`${t('billing-number')} : `}</p>
                    <p>{`${t('paid-by')} : `}</p>
                  </Grid>
                  <Grid item>
                    <p>
                      {moment(data?.createdDate).format('DD/MM/YYYY')}&nbsp;
                    </p>
                    <p>{data?.billingNumber?.value}&nbsp;</p>
                    <p>{data?.paidBy?.name}&nbsp;</p>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs>
                <Grid container spacing={1} justify='flex-end'>
                  <Grid item className={classes.pBold}>
                    <p>{`${t('reference-number')} : `}</p>
                    <p>{`${t('created-by')} : `}</p>
                  </Grid>
                  <Grid item>
                    <p>{data?.reference?.number || t('n/a')}&nbsp;</p>
                    <p>{data?.createdBy?.name}&nbsp;</p>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Grid container spacing={4}>
              <Grid item xs={12}>
                <Items data={data?.items} />
              </Grid>
            </Grid>
          </Grid>
          <Grid item container justify='flex-end' xs={12}>
            <Grid item xs={4}>
              <TotalDisplay
                disableShippingFee
                disableCosting
                disableTax
                subtotal={data?.total}
              />
            </Grid>
          </Grid>
          <Grid item xs={12}>
            {data?.note && <NoteInvoice note={data?.note} />}
            {checkAccess({ feature: 'billing', action: 'view-activity' }) && (
              <Box mt={2}>
                <Grid container>
                  <Grid item xs={12}>
                    {/* {orderInfo?.total !== orderInfo?.amountDue ? ( */}
                    <Button
                      variant='contained'
                      disableElevation
                      onClick={() => setShowHistory((prev) => !prev)}
                    >
                      {showHistory ? 'Hide' : 'Show'} History
                    </Button>
                    {/* ) : null} */}
                    {showHistory && (
                      <Collapse in>
                        <Box mb={8}>
                          <BillingHistory
                            billingId={data._id}
                            triggerFetch={triggerFetch}
                          />
                        </Box>
                      </Collapse>
                    )}
                  </Grid>
                </Grid>
              </Box>
            )}
          </Grid>
        </Grid>
        <PurchaseInvoice data={data} ref={invoiceRef} />
      </DialogContent>
    </Dialog>
  );
};

export default BillingDetailPopup;
