const buildAddress = (location) => {
  if (location) {
    const { city, district, commune, address } = location;
    return `${address && `${address}`}${commune ? `, ${commune?.en}` : ''}${
      district ? `, ${district?.en}` : ''
    }${city ? `, ${city?.en}` : ''}`;
  }
  return '';
};

export default buildAddress;
