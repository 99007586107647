export const status = ['available', 'unavailable'];

// isActive ? "available" : "unavailable"

// convert isActive to string status["available","unavailable"]
export const isActiveToStatus = (isActive) => {
  return isActive ? status[1] : status[0];
};

// convert status["available","unavailble"] to boolean
export const statusToIsActive = (option) => {
  return option === status[1];
};
