import customAxios from './custom-axios';

const authApi = {
  checkEmailOrPhoneAvailable({ emailOrPhone, available = true, warehouseId }) {
    return customAxios.get('auth/users/available', {
      params: {
        emailOrPhone,
        warehouseId,
        ...(available ? { available: true } : {}),
      },
    });
  },
  register(data) {
    return customAxios.post('auth/register', data);
  },
  resetPassword(data) {
    return customAxios.put('auth/reset-password', data);
  },
  requestEmailOTP(email, reset = true) {
    return customAxios.post('auth/request-otp', { email, reset });
  },
  updateOwnUser(data) {
    return customAxios.put('auth/users/me', data);
  },
  updatePassword(data) {
    return customAxios.put('auth/users/me/password', data);
  },
  createInviteLink(emailOrPhone) {
    return customAxios.post('auth/users/invite', { emailOrPhone });
  },
  inviteUser(emailOrPhone, roles) {
    return customAxios.post('auth/users/invite', { emailOrPhone, roles });
  },
  createInviteLinkByUserId(id) {
    return customAxios.post(`auth/users/${id}/invite`);
  },
  getInviteTokenInfo(token) {
    return customAxios.get(`auth/users/invite/${token}`);
  },
};

export default authApi;
