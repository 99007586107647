import React, { useEffect, useContext } from 'react';
import { TableRow, TableCell, TableBody } from '@material-ui/core';
import { useFieldArray, useFormContext } from 'react-hook-form';
import { UtilityContext } from '../../../../components/context-provider/utilty-context';

import NumberFormatCustom from '../../../../components/number-format';
import plusTax from '../../../../util/plusTax';
import CustomTable from '../../../../components/custom-table';
import CustomRemoveIconButton from '../../../../components/custom-button/remove-icon-button';
import { RHFNumberInput } from '../../../../components/rhf-controlled-input';
import VariantInput from './variant-input';
import calculateDiscount from '../../../../util/calculate-discount';

const tableHeadData = [
  { value: 'no.' },
  { value: 'image', width: '80px' },
  { value: 'product-name', width: '40%' },
  { value: 'quantity' },
  { value: 'unit-price' },
  { value: 'tax' },
  // { value: 'discount' },
  // { value: 'expiry-date', width: '15%' },
  { value: 'amount', align: 'right', width: '10%' },
  { width: '50px' },
];

const Products = ({ isManual, maxQty }) => {
  // form context
  const { watch, register, control, setValue } = useFormContext();
  // utility context
  const { showDeletePrompt, closeDeletePrompt } = useContext(UtilityContext);
  // watch form
  const vendor = watch('vendor');

  // filedArray product
  const { fields, append, remove } = useFieldArray({
    control,
    name: 'product',
  });

  // controlledFields
  const watchFieldArray = watch('product');
  let orderItems = 0;

  const controlledFields = fields.map((field, index) => {
    if (field.variant) orderItems++;
    return {
      ...field,
      ...watchFieldArray?.[index],
    };
  });

  // useEffect allow add product after select vendor
  useEffect(() => {
    if (vendor?._id) {
      setValue('product', [{ qty: 1, costPriceAverage: 0, tax: 10 }]);
    }
  }, [vendor]);

  // add row item
  const handleAddField = (index) => {
    if (index === fields.length - 1) {
      append({ qty: 1, tax: 10 }, { shouldFocus: false });
    }
  };

  // remove row item prompt
  const handleRemove = (index, id) => {
    showDeletePrompt({
      handleConfirm: () => onDelete(index, id),
    });
  };

  // on remove confirm
  const onDelete = (index) => {
    remove(index);
    closeDeletePrompt();
  };

  return (
    <CustomTable.Container>
      {/* table header */}
      <CustomTable.Head data={tableHeadData} />
      {/* table body */}
      {(vendor?._id || vendor?.id) && (
        <TableBody>
          {controlledFields.map((field, index) => {
            const filter = vendor?._id;

            // get value from field
            const get = getValue(field);

            // store salePrice
            register(`product.${index}.salePrice`, {
              value: get.salePrice,
            });

            if (!isManual) {
              // store qty
              register(`product.${index}.qty`, {
                value: get.qty,
              });
            }

            return (
              <TableRow key={field.id}>
                <TableCell>
                  <CustomTable.CellValue>{index + 1}</CustomTable.CellValue>
                </TableCell>
                <TableCell>
                  <CustomTable.Image src={get.image} />
                </TableCell>
                <TableCell>
                  <VariantInput
                    index={index}
                    disabled={!isManual}
                    filter={filter}
                    handleAddField={handleAddField}
                    defaultValue={get.variant}
                    rules={orderItems > 0}
                  />
                </TableCell>
                <TableCell>
                  <RHFNumberInput
                    name={`product.${index}.receivedQty`}
                    defaultValue={get.receivedQty}
                    textAlign="right"
                    decimalValue={false}
                    min={1}
                    max={!isManual && maxQty?.[index] ? maxQty?.[index] : null}
                    required={!!get.variantName}
                    // helperText="On hand 3"
                  />
                </TableCell>
                <TableCell>
                  <RHFNumberInput
                    name={`product.${index}.costPriceAverage`}
                    textAlign="right"
                    defaultValue={get.costPriceAverage}
                    required={!!get.variantName}
                    min={0}
                    disabled
                  />
                </TableCell>
                <TableCell>
                  <RHFNumberInput
                    name={`product.${index}.tax`}
                    defaultValue={get.tax}
                    textAlign="right"
                  />
                </TableCell>
                {/* <TableCell>
                  <RHFDiscountInput
                    name={`product.${index}.discount`}
                    defaultValue={get.discount}
                  />
                </TableCell>
                <TableCell>
                  <RHFDateInput name={`product.${index}.expiryDate`} />
                </TableCell> */}
                <TableCell style={{ textAlign: 'right' }}>
                  <CustomTable.CellValue align="right">
                    {<NumberFormatCustom value={get.totalAmountWithTax} />}
                  </CustomTable.CellValue>
                </TableCell>
                <TableCell>
                  <CustomTable.CellValue component="div">
                    <CustomRemoveIconButton
                      invisible={
                        isManual
                          ? index === fields.length - 1 || fields.length <= 1
                          : fields.length === 1
                      }
                      onClick={() => handleRemove(index, field._id)}
                    />
                  </CustomTable.CellValue>
                </TableCell>
              </TableRow>
            );
          })}
        </TableBody>
      )}
    </CustomTable.Container>
  );
};

// function to get product value
export const getValue = (data) => {
  const variantName = data.variant?.name ?? '';
  const image =
    data?.variant?.id?.images?.[0]?.thumbnail ||
    data?.variant?.stock?.variant?.image?.thumbnail ||
    data?.variant?.variant?.image?.thumbnail;
  const tax = data.tax ?? 0;
  const costPriceAverage = data.costPriceAverage ?? 0;
  const salePrice = data.salePrice ?? 0;
  const qty = data.qty ?? 1;
  const receivedQty = data.receivedQty ?? 1;
  const discount = data.discount ?? { amount: 0, type: 'percentage' };
  const totalAmount = calculateDiscount(
    receivedQty * Number(costPriceAverage),
    discount
  );
  const totalAmountWithTax = plusTax(totalAmount, tax);
  const variant = data.variant ?? null;

  return {
    variant,
    variantName,
    image,
    tax,
    costPriceAverage,
    salePrice,
    qty,
    receivedQty,
    totalAmount,
    totalAmountWithTax,
    discount,
  };
};

export default Products;
