const disableEditStatus = [
  'paid',
  'partially-paid',
  'shipping',
  'delivered',
  'returned',
  'partially-returned',
  'failed',
  'rejected',
  'repack',
  'completed',
  'refunded',
];

export default disableEditStatus;
