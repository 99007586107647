import { IconButton, makeStyles } from '@material-ui/core';

const useStyles = makeStyles(() => ({
  invisible: {
    visibility: ({ invisible }) => (invisible ? 'hidden' : ''),
  },
}));

// icon button with default style
const CustomBaseIconButton = (props) => {
  const {
    size = 'small',
    invisible = 'none',
    className,
    children,
    ...iconProps
  } = props;

  const classes = useStyles({ invisible });
  return (
    <IconButton
      size={size}
      className={`${classes.invisible} ${className}`}
      {...iconProps}
    >
      {children}
    </IconButton>
  );
};

export default CustomBaseIconButton;
