import React, { useState, useContext, useCallback } from 'react';
import TitleBar from '../../components/title-bar';
import ActionButton from '../../components/action-button';
import { Grid } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import SelectPopUp from '../../components/select-popup';
import config from '../../components/tableX/config';
import Status from '../../components/status';
import DateFormat from '../../components/date-format';
import NumberFormat from '../../components/number-format';
import PurchaseDetailPopup from './detail-popup';
import allowStatus from './status';
import { UtilityContext } from '../../components/context-provider/utilty-context';
import { UserContext } from '../../components/context-provider/user-context';
import axios from 'axios';
import CloseIcon from '@material-ui/icons/Close';
import { useTabFilter } from '../../components/tab-filter';
import TableTabFilter from '../../components/table-with-filter';

const filterStatus = ['all', 'pending', 'canceled', 'completed'];

const PurchasePage = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const [triggerFetch, setTriggerFetch] = useState(false);
  const { showDeletePrompt, showSnackbar, closeDeletePrompt } =
    useContext(UtilityContext);
  const { checkAccess } = useContext(UserContext);
  const [purchaseDetail, setPurchaseDetail] = useState({
    open: false,
    data: {},
  });

  const handleCancel = (data) => {
    showDeletePrompt({
      handleConfirm: () => onCancel(data._id),
      icon: CloseIcon,
    });
  };

  const onCancel = (id) => {
    axios
      .put(`${process.env.REACT_APP_API}/api/v1/purchase-orders/cancel/${id}`)
      .then(({ data }) => {
        showSnackbar({ message: data?.message });
        setTriggerFetch((prev) => !prev);
      })
      .catch(({ response }) => {
        showSnackbar({ message: response.data?.message, variant: 'error' });
      })
      .finally(() => closeDeletePrompt());
  };

  const handleEdit = (data, e) => {
    const location = `/dashboard/purchase/edit/${data._id}`;
    if (e?.ctrlKey) {
      window.open(location, '_blank');
    } else {
      history.push(location);
    }
  };

  const handleCreate = () => history.push('/dashboard/purchase/create');

  const handleShowDetail = (data) => {
    setPurchaseDetail({ open: true, data });
  };

  const handleCloseDetail = () => {
    setPurchaseDetail({ open: false, data: {} });
  };

  const handleReceive = (data) => {
    history.push(`/dashboard/purchase/receive/${data._id}`);
  };

  const tableSchema = [
    {
      id: 'purchaseNumber.value',
      label: t('purchase-order-number'),
      dataConfig: ['purchaseNumber', 'value'],
      type: 'action',
      onClick: handleShowDetail,
    },
    {
      id: 'vendor',
      label: t('vendor'),
      dataConfig: ['vendor', 'name'],
    },
    {
      id: 'amount',
      label: t('amount'),
      type: 'component',
      components: [
        {
          name: NumberFormat,
          customValueProp: 'value',
        },
      ],
    },
    {
      id: 'createBy',
      label: t('created-by'),
      dataConfig: ['createdBy', 'name'],
    },
    {
      id: 'createdAt',
      label: t('created-date'),
      type: 'component',
      components: [
        {
          name: DateFormat,
          customValueProp: 'value',
        },
      ],
    },
    {
      id: 'currentStatus.order',
      label: t('status'),
      type: 'component',
      dataConfig: ['currentStatus', 'name'],
      components: [
        {
          name: Status,
          customValueProp: 'status',
        },
      ],
    },
    {
      id: 'action',
      sort: false,
      label: t('action'),
      type: 'component',
      dataConfig: [],
      components: (data) => {
        const currentStatus = data.currentStatus?.name;
        const actions = [];

        allowStatus.edit.includes(currentStatus) &&
          actions.push({
            variant: 'edit',
            hidden: !checkAccess({
              feature: 'purchase-order',
              action: 'update',
            }),
            onClick: () => handleEdit(data),
            title: t('edit'),
          });

        allowStatus.remove.includes(currentStatus) &&
          actions.push({
            variant: 'close',
            hidden: !checkAccess({
              feature: 'purchase-order',
              action: 'cancel',
            }),
            onClick: (e) => {
              e.stopPropagation();
              handleCancel(data);
            },
            title: t('cancel'),
          });

        allowStatus.receive.includes(currentStatus) &&
          actions.push({
            variant: 'confirm',
            hidden: !checkAccess({
              feature: 'purchase-order',
              action: 'update',
            }),
            onClick: () => handleReceive(data),
            title: t('receive'),
          });

        return actions.length > 0 ? (
          actions.map(
            (data, index) =>
              data && <ActionButton key={index} {...data}></ActionButton>
          )
        ) : (
          <>-</>
        );
      },
    },
  ];

  const { value, changeFilter, createDefaultFilter } = useTabFilter(0);

  const defaultFilter = useCallback(() => {
    return createDefaultFilter({
      value,
      options: filterStatus,
      filter: 'currentStatus.name',
    });
  }, [value]);

  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <TitleBar
          onClick={handleCreate}
          buttonText={t('create-purchase')}
          disableButton={
            !checkAccess({ feature: 'purchase-order', action: 'create' })
          }
        ></TitleBar>
      </Grid>
      <Grid item xs={12}>
        <TableTabFilter
          tabFilterProps={{
            options: filterStatus,
            value,
            onChange: changeFilter,
          }}
          tableXProps={{
            triggerFetch,
            onRowClick: handleShowDetail,
            defaultFilter,
            render: (data) => {
              return <SelectPopUp open={data.length} select={data} noButton />;
            },
            config: tableConfig,
            schema: tableSchema,
          }}
        />
      </Grid>
      <PurchaseDetailPopup
        state={purchaseDetail}
        onClose={handleCloseDetail}
        onReceive={handleReceive}
      />
    </Grid>
  );
};

const tableConfig = {
  ...config,
  url: `${process.env.REACT_APP_API}/api/v1/purchase-orders`,
  dataConfig: ['purchaseOrder'],
};

export default PurchasePage;
