import { LinearProgress, makeStyles } from '@material-ui/core';
import React from 'react';

const useStyles = makeStyles(() => ({
  root: {
    position: 'absolute',
    top: 0,
    width: '100%',
    height: '100%',
    zIndex: '1201',
    left: 0,
    backgroundColor: (props) => props.backgroundColor ?? 'rgba(0,0,0,0.2)',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'flex-start',
  },
  spinner: {
    width: '200px',
    height: '200px',
  },
}));

const BackDropSpinner = ({ backgroundColor, open }) => {
  const classes = useStyles({ backgroundColor });
  return open ? (
    <div className={classes.root}>
      <div style={{ width: '100%' }}>
        <LinearProgress />
      </div>
    </div>
  ) : null;
};

export default BackDropSpinner;
