import { useTranslation } from 'react-i18next';

// integret with useTranslate
const useDisplay = () => {
  const { t } = useTranslation();

  // display value or display defaultValue if value not existed
  const display = (
    value,
    {
      translateValue = true,
      translateDefaultValue = true,
      defaultValue = 'n/a',
    }
  ) => {
    if (value) {
      return translateValue ? t(value) : value;
    }
    return translateDefaultValue ? t(defaultValue) : defaultValue;
  };

  // get value without error if it not exist
  const get = (value) => {
    try {
      return value;
    } catch {
      return 'novalue';
    }
  };

  return { display, t, get };
};

export default useDisplay;
