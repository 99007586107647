import {
  TableRow,
  TableCell,
  TableBody,
  Typography,
  Box,
} from '@material-ui/core';
import NumberFormatCustom from '../../../../components/number-format';
import CustomTable from '../../../../components/custom-table';
import calculateDiscount from '../../../../util/calculate-discount';
import { useTranslation } from 'react-i18next';

const tableHeadData = [
  { value: 'no.' },
  { value: 'item-name', width: '30%' },
  { value: 'quantity' },
  { value: 'unit-price' },
  { value: 'discount' },
  { value: 'amount', align: 'right', width: '10%' },
];

const Items = ({ data }) => {
  const { t } = useTranslation();
  return (
    <>
      <Box bgcolor='primary.light' color='white' p={1}>
        <Typography>
          <b>{t('items')}</b>
        </Typography>
      </Box>
      <CustomTable.Container size='small'>
        {/* table header */}
        <CustomTable.Head data={tableHeadData} />
        {/* table body */}
        <TableBody>
          {data?.map((field, index) => {
            return (
              <TableRow key={index}>
                <TableCell>
                  <CustomTable.CellValue>{index + 1}</CustomTable.CellValue>
                </TableCell>
                <TableCell>
                  <CustomTable.CellValue>{field.name}</CustomTable.CellValue>
                </TableCell>
                <TableCell>
                  <CustomTable.CellValue>{field.qty}</CustomTable.CellValue>
                </TableCell>
                <TableCell>
                  <CustomTable.CellValue>
                    <NumberFormatCustom value={field.costPrice} />
                  </CustomTable.CellValue>
                </TableCell>
                <TableCell>
                  <CustomTable.CellValue>
                    <NumberFormatCustom
                      value={field.discount?.amount}
                      format={field.discount?.type}
                    />
                  </CustomTable.CellValue>
                </TableCell>
                <TableCell>
                  <CustomTable.CellValue align='right'>
                    <NumberFormatCustom
                      value={calculateDiscount(
                        field.costPrice * field.qty,
                        field.discount
                      )}
                    />
                  </CustomTable.CellValue>
                </TableCell>
              </TableRow>
            );
          })}
        </TableBody>
      </CustomTable.Container>
    </>
  );
};

export default Items;
