import React from 'react';
import {
  Snackbar,
  Typography,
  Button,
  Paper,
  makeStyles,
} from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';

const useStyles = makeStyles((theme) => ({
  root: {
    marginBottom: '20px',
  },
  paper: {
    color: 'white',
    display: 'flex',
    justifyContent: 'space-around',
    width: '300px',
    alignItems: 'center',
    backgroundColor: theme.palette.primary.main,
    padding: '18px 0',
  },
  button: {
    padding: '5px 20px',
  },
}));

const SelectPopUp = ({
  handleDelete,
  select,
  buttonText,
  noButton = false,
}) => {
  const classes = useStyles();
  return (
    <Snackbar open={select.length > 0} className={classes.root}>
      <Paper elevation={2} className={classes.paper}>
        <Typography>{`${select.length} selected`}</Typography>
        {noButton ? null : (
          <Button
            onClick={handleDelete}
            disableElevation
            color='secondary'
            variant='contained'
            className={classes.button}
          >
            {buttonText || <DeleteIcon />}
          </Button>
        )}
      </Paper>
    </Snackbar>
  );
};

export default SelectPopUp;
