import React, { useEffect, useState, useContext } from 'react';
import { Grid, makeStyles, Paper } from '@material-ui/core';
import TitleBar from '../../../components/title-bar';
import FloatingBar from '../../../components/floating-bar';
import { useParams, useHistory } from 'react-router-dom';
import { useForm, FormProvider } from 'react-hook-form';
import axios from 'axios';
import Products from './products';
import { UtilityContext } from '../../../components/context-provider/utilty-context';
import PurchaseForm from './form';
import Spinner, { useSpinner } from 'src/components/spinner';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles(() => ({
  root: {
    marginBottom: '150px',
    flexDirection: 'column',
    position: 'relative',
  },
}));

const PurchaseInEditPage = () => {
  const classes = useStyles();
  const history = useHistory();
  const { id } = useParams();
  const { showSnackbar } = useContext(UtilityContext);

  // store max qty
  const [maxQty, setMaxQty] = useState([]);

  const methods = useForm({
    defaultValues: {
      products: [],
    },
  });

  const [state, setState] = useState('create');
  const [loading, setLoading] = useState(true);
  const { t } = useTranslation();

  const { spinnerState, openSpinner, closeSpinner } = useSpinner();

  useEffect(() => {
    if (id) {
      openSpinner({ title: t('loading') });
      setState('edit');
      setLoading(true);
      axios
        .get(`${process.env.REACT_APP_API}/api/v1/purchase-in/detail/${id}`)
        .then(({ data }) => {
          if (typeof data === 'object') {
            const resetData = data.PurchaseIn;

            setMaxQty(resetData.product.map((item) => item.receivedQty));

            methods.reset(resetData);
            setLoading(false);
          } else {
            console.log('error reponse');
          }
        })
        .catch(({ response }) => {
          showSnackbar({
            message: response?.data?.message,
            variant: 'error',
          });
        })
        .finally(() => {
          closeSpinner();
        });
    } else {
      setState('create');
    }
  }, []);

  const onSubmit = (data) => {
    openSpinner({ title: t('saving') });
    const { product, referenceNumber } = data;
    axios
      .put(
        `${process.env.REACT_APP_API}/api/v1/purchase-in/update/${data._id}`,
        {
          product: product.map((item) => ({
            ...item,
            variant: { id: item.variant.id._id },
          })),
          referenceNumber,
        }
      )
      .then(({ data }) => {
        showSnackbar({ message: data?.message });
        history.push('/dashboard/purchase-in');
      })
      .catch(({ response }) => {
        showSnackbar({
          message: response?.data?.message,
          variant: 'error',
        });
      })
      .finally(() => {
        closeSpinner();
      });
  };

  const handleCancel = () => {
    goBackToList();
  };

  const goBackToList = () => {
    history.push('/dashboard/purchase-in');
  };

  return (
    <FormProvider {...methods}>
      <form id='purchase-in-form' onSubmit={methods.handleSubmit(onSubmit)}>
        <Grid container className={classes.root} spacing={3}>
          <Grid item>
            <TitleBar disableButton />
          </Grid>
          <Grid item>
            <Paper>
              <Grid container>
                <Grid item xs={12}>
                  <PurchaseForm state={state} loading={loading} />
                </Grid>
              </Grid>
            </Paper>
          </Grid>
          <Grid item>
            <Paper className='container'>
              <Grid container>
                <Grid item xs={12}>
                  <Products state={state} maxQty={maxQty} />
                </Grid>
              </Grid>
            </Paper>
          </Grid>
          <FloatingBar
            onCancel={handleCancel}
            confirmButtonProps={{ form: 'purchase-in-form' }}
            confirmButtonText={'save-changes'}
          />
        </Grid>
      </form>
      <Spinner state={spinnerState} />
    </FormProvider>
  );
};

export default PurchaseInEditPage;
