import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  makeStyles,
  Slider,
  useMediaQuery,
  useTheme,
} from '@material-ui/core';
import React, { useEffect, useRef, useState } from 'react';
import AvatarEditor from 'react-avatar-editor';
import RemoveIcon from '@material-ui/icons/Remove';
import AddIcon from '@material-ui/icons/Add';
import CloseIcon from '@material-ui/icons/Close';
import imageCompression from 'browser-image-compression';

const avatarSize = 300;

const useStyles = makeStyles((theme) => {
  return {
    dialogContentGrid: {
      [theme.breakpoints.up('xs')]: {
        justify: 'center',
        height: '100%',
      },
      [theme.breakpoints.up('md')]: {
        justify: 'flex-start',
        height: 'auto',
      },
    },
    dialogTitle: { position: 'absolute', top: 8, right: 8 },
  };
});

const style = {
  iconButton: (theme, num, scale) => ({
    color: scale === num ? theme.palette.grey[400] : 'black',
    transition: '0.1s linear',
  }),
};

const ProfileEditor = ({
  open,
  onClose,
  image,
  onBrowse,
  title,
  setImage,
  onImageSave,
}) => {
  const [zoom, setZoom] = useState(0);
  const classes = useStyles();

  const matchSM = useMediaQuery((theme) => theme.breakpoints.down('sm'));
  const theme = useTheme();

  const editorRef = useRef();

  const increaseZoom = () =>
    setZoom((prev) => (prev >= 90 ? 100 : (prev += 10)));

  const decreaseZoom = () => setZoom((prev) => (prev <= 10 ? 0 : (prev -= 10)));

  useEffect(() => {
    setZoom(0);
  }, [image.file]);

  const handleSave = async () => {
    try {
      const canvas = editorRef.current.getImageScaledToCanvas();
      const canvasImage = canvas.toDataURL();
      const imageFile = await imageCompression.getFilefromDataUrl(canvasImage);
      onClose();
      setImage((prev) => {
        const newImage = {
          file: imageFile,
          blob: canvasImage,
        };
        return { ...prev, ...newImage };
      });
      onImageSave({ file: imageFile });
    } catch (err) {
      console.log(err);
    }
  };

  const handleSlideChange = (_, newValue) => setZoom(newValue);

  return (
    <Dialog
      open={open}
      maxWidth='sm'
      fullScreen={matchSM}
      fullWidth
      onClose={onClose}
    >
      <DialogTitle>
        {title}
        <IconButton onClick={onClose} className={classes.dialogTitle}>
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent dividers>
        <Grid
          container
          direction='column'
          spacing={1}
          className={classes.dialogContentGrid}
        >
          <Grid item container justify='center'>
            <AvatarEditor
              ref={(editor) => (editorRef.current = editor)}
              image={image.file}
              width={avatarSize}
              height={avatarSize}
              borderRadius={avatarSize / 2}
              color={[255, 255, 255, 0.6]}
              scale={1 + zoom / 100}
            />
          </Grid>
          <Grid item>
            <Grid container spacing={2} alignItems='center'>
              <Grid item>
                <IconButton disabled={zoom === 0} onClick={decreaseZoom}>
                  <RemoveIcon style={style.iconButton(theme, 0, zoom)} />
                </IconButton>
              </Grid>
              <Grid item xs>
                <Slider value={zoom} onChange={handleSlideChange} />
              </Grid>
              <Grid item>
                <IconButton disabled={zoom === 100} onClick={increaseZoom}>
                  <AddIcon style={style.iconButton(theme, 100, zoom)} />
                </IconButton>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Box
          style={{ width: '100%' }}
          display='flex'
          justifyContent='space-between'
        >
          <Button type='button' onClick={onBrowse} disableElevation>
            Browse
          </Button>
          <Button
            type='button'
            onClick={handleSave}
            variant='contained'
            disableElevation
          >
            Save
          </Button>
        </Box>
      </DialogActions>
    </Dialog>
  );
};

export default ProfileEditor;
