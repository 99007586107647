import React from 'react';
import {
  Dialog,
  DialogContent,
  Button,
  DialogActions,
  Grid,
  Typography,
  Box,
  Zoom,
  makeStyles,
  Fade,
} from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import clsx from 'clsx';

const useStyles = makeStyles(() => ({
  dialogContent: {
    textAlign: 'center',
    minWidth: '285px',
  },
  icon: {
    fontSize: '48px',
  },
  dialogAction: {
    padding: 0,
  },
  root: {
    '& .MuiDialogContent-root:first-child': {
      paddingTop: 8,
    },
  },
  title: {
    fontSize: '16px',
    fontWeight: 'bold',
  },
}));

const PromptPopup = ({
  open,
  handleClose,
  handleConfirm,
  message = 'Are you sure?',
  icon: Icon = DeleteIcon,
  iconProps = {},
  color = 'secondary',
  confirmButtonText = 'yes',
  cancelButtonText = 'no',
  confirmButtonProps = {},
  cancelButtonProps = {},
  disableIcon,
  title,
}) => {
  const classes = useStyles();

  return (
    <Dialog
      open={open}
      className={clsx({ [classes.root]: disableIcon })}
      onClose={handleClose}
      TransitionComponent={Fade}
    >
      <Box py={1.5}>
        <Typography className={classes.title} align='center'>
          {title}
        </Typography>
      </Box>
      <DialogContent dividers className={classes.dialogContent}>
        <Box px={3} py={2}>
          {!disableIcon && (
            <Zoom in timeout={500}>
              <Icon color={color} {...iconProps} className={classes.icon} />
            </Zoom>
          )}
          {message}
        </Box>
      </DialogContent>
      <DialogActions className={classes.dialogAction}>
        <Grid container>
          <Grid item xs={6}>
            <Button onClick={handleClose} {...cancelButtonProps} fullWidth>
              {cancelButtonText}
            </Button>
          </Grid>
          <Grid item xs={6}>
            <Button onClick={handleConfirm} {...confirmButtonProps} fullWidth>
              {confirmButtonText}
            </Button>
          </Grid>
        </Grid>
      </DialogActions>
    </Dialog>
  );
};

export default PromptPopup;
