import { makeStyles, TextField } from '@material-ui/core';
import React from 'react';
import { useController } from 'react-hook-form';
import useRootStyle from '../utils/useRootStyle';

const useStyles = makeStyles(() => ({
  root: {
    '& textarea': {
      paddingTop: '5px',
    },
  },
}));

const RHFTextInput = (props) => {
  const {
    name,
    readOnly,
    control,
    required,
    defaultValue = '',
    variant = 'outlined',
    size = 'small',
    label,
    fullWidth = true,
    placeholder = label?.toUpperCase(), //using label as default value
    textAlign = 'left',
    className = '',
    rules,
    onChange,
    helperTextAlign = 'center',
    ...textFieldProps
  } = props;

  const rootClasses = useRootStyle({ textAlign, helperTextAlign });
  const classes = useStyles();

  // using react hook form useController as value state
  const {
    field: { ref, value, onChange: ucOnChange, ...inputProps },
    fieldState: { error },
  } = useController({
    name,
    control, //optional when use inside formContext
    rules: { required, ...rules },
    defaultValue,
  });

  const handleChange = (e) => {
    const {
      target: { value },
    } = e;
    ucOnChange(value);
  };

  return (
    <TextField
      className={`${rootClasses.root} ${classes.root} ${className}`}
      variant={variant}
      required={required}
      fullWidth={fullWidth}
      size={size}
      value={value}
      onChange={onChange ? (data) => onChange(data, ucOnChange) : handleChange}
      inputRef={ref}
      placeholder={placeholder}
      error={!!error}
      {...textFieldProps}
      {...inputProps}
    />
  );
};

export default RHFTextInput;
