import moment from 'moment';
import { useTranslation } from 'react-i18next';

const DateFormat = ({ value, variant }) => {
  const { t } = useTranslation();
  if (value === null) {
    return t('n/a');
  } else {
    if (variant === 'date-time') {
      return moment(value).format('DD/MM/yy HH:mm');
    }
    return moment(value).format('DD/MM/yy');
  }
};

export default DateFormat;
