import { Grid, IconButton, makeStyles } from '@material-ui/core';
import React, { useContext } from 'react';
import NumberFormatCustom from '../../../../components/number-format';
import BackspaceIcon from '@material-ui/icons/Backspace';
import moment from 'moment';
import axios from 'axios';
import { UtilityContext } from '../../../../components/context-provider/utilty-context';
import { UserContext } from '../../../../components/context-provider/user-context';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles((theme) => ({
  button: {
    '&:hover svg': {
      color: theme.palette.secondary.dark,
    },
  },
  textRight: {
    textAlign: 'right',
  },
}));

const PaymentHistoryItem = ({
  data,
  refreshForm,
  handleRefreshTable,
  handleRefreshHistory,
  showHistory,
}) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const { showSnackbar, showDeletePrompt, closeDeletePrompt } =
    useContext(UtilityContext);
  const { checkAccess } = useContext(UserContext);

  const onDelete = async () => {
    closeDeletePrompt();
    try {
      if (data?.type === 'payment') {
        const { data: orderData } = await axios.delete(
          `${process.env.REACT_APP_API}/api/v1/orders/payment/${data._id}`
        );
        refreshForm();
        showSnackbar({ message: orderData.message });
      } else if (data?.type === 'credit-note') {
        const { data: orderData } = await axios.delete(
          `${process.env.REACT_APP_API}/api/v1/orders/return/${data._id}`
        );
        refreshForm();
        showSnackbar({ message: orderData.message });
      }
      if (showHistory) {
        handleRefreshHistory();
      }
      handleRefreshTable();
    } catch ({ response }) {
      showSnackbar({
        message: `${response?.data?.item} ${t(response?.data?.message)}`,
        variant: 'error',
        noTranslate: true,
      });
    }
  };

  const handleDelete = () => {
    showDeletePrompt({ handleConfirm: onDelete });
  };

  return (
    <>
      <Grid item xs={6} className={classes.textRight}>
        <small>{`${t(data?.type)}`}</small>
        <br />
        <small>{moment(data?.paidDate).format('DD/MM/YYYY')}</small>{' '}
      </Grid>
      <Grid
        item
        xs={6}
        className={classes.textRight}
        style={{ position: 'relative' }}
      >
        {checkAccess({ feature: 'orders', action: 'delete-payment' }) &&
          data?.type !== 'credit-note' && (
            <div
              style={{
                position: 'absolute',
                top: 0,
                right: 0,
                marginRight: '-28px',
                fontSize: '12px',
                marginTop: '-4px',
              }}
            >
              {data.type !== 'refund' && (
                <IconButton
                  onClick={handleDelete}
                  className={classes.button}
                  size="small"
                  color="secondary"
                >
                  <BackspaceIcon fontSize="small" />
                </IconButton>
              )}
            </div>
          )}
        <NumberFormatCustom value={data?.amount || 0}></NumberFormatCustom>
      </Grid>
    </>
  );
};

export default PaymentHistoryItem;
