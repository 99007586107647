import { Box, Grid, makeStyles, Typography } from '@material-ui/core';
import clsx from 'clsx';
import moment from 'moment';
import React from 'react';
import buildAddress from '../../../../util/build-address';
import Products from './products';
import CostLine from './cost-line';
import TotalDisplayAmountDue from './total-display-amountdue';
import PriceWithLabel from '../../../../components/price-with-label';
import CustomNumberFormat from '../../../../components/number-format';
import NoteInvoice from '../../../../components/note-invoice';
import { useTranslation } from 'react-i18next';
import { useAuth } from 'src/components/context-provider/user-context';
import InvoiceStoreHeader from 'src/components/invoice-store-header';

export const useStyles = makeStyles(() => ({
  root: {
    '& *': {
      fontFamily: 'Arial , Siemreap',
    },
    '& #title': {
      padding: '5px',
    },
    padding: '2.2rem 3rem',
    fontFamily: 'san-serif',
    '& p , li , li b': {
      fontSize: '11px',
    },
    '& th.MuiTableCell-root.MuiTableCell-head': {
      fontSize: '10px !important',
      fontWeight: 'bold',
      padding: '5px 0 !important',
      lineHeight: '15px',
    },
  },
  textRight: {
    textAlign: 'right',
  },
  total: {
    '& .title': {
      fontSize: '6px !important',
      marginTop: '5px',
    },
    '& .value': {
      fontSize: '10px !important',
    },
  },
}));

// only show active items for the invoice
const OrderInvoice = React.forwardRef(({ data }, ref) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const { me } = useAuth();

  const totalPayment = data.payments?.reduce((acc, curr) => {
    if (curr.type === 'payment') {
      acc += curr.amount;
    }
    return acc;
  }, 0);

  const totalCreditNote = data.payments?.reduce((acc, curr) => {
    if (curr.type === 'credit-note') {
      acc += curr.amount;
    }
    return acc;
  }, 0);

  // const subTotal = data.orderItems?.reduce((acc, curr) => {
  //   if (curr.isCanceled === false) {
  //     const amount = curr.salePrice * curr.qty;
  //     acc += calculateDiscount(amount, curr.discount);
  //   }
  //   return acc;
  // }, 0);

  return (
    <Grid container spacing={2} style={{ display: 'none' }}>
      <Grid item ref={ref} style={{ overflow: 'hidden' }}>
        <Grid
          container
          spacing={2}
          direction='column'
          className={clsx(classes.root)}
        >
          <Grid item style={{ position: 'relative' }}>
            <InvoiceStoreHeader warehouse={me.warehouse} />
          </Grid>
          <Grid item>
            <Typography align='center'>
              <span style={{ fontSize: '18px' }}>{'វិក្កយបត្រ​'}</span>
              <span style={{ fontSize: '18px' }}>
                <b> INVOICE</b>
              </span>
            </Typography>
          </Grid>
          <Grid item>
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <Grid container>
                  <Grid item xs={12}>
                    <Typography>
                      អតិថិជន / <b>Customer</b>
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <Grid container alignItems='center'>
                      <Grid item xs>
                        <Typography>
                          ឈ្មោះ / <b>Name</b> : {data?.customer?.name}
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={12}>
                    <Grid container alignItems='center'>
                      <Grid item xs>
                        <Typography>
                          លេខទូរសព្ទ / <b>Phone</b> :{' '}
                          {data?.shippingInfo?.phone ? (
                            <CustomNumberFormat
                              value={data?.shippingInfo?.phone}
                              format='phone'
                            />
                          ) : (
                            t('n/a')
                          )}
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={12}>
                    <Grid container alignItems='center'>
                      <Grid item xs>
                        <Typography>
                          អាសយដ្ឋាន / <b>Address</b> :{' '}
                          {buildAddress(data?.shippingInfo?.location) ||
                            t('n/a')}
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={6}>
                <Grid container alignItems='center'>
                  <Grid item xs>
                    <Typography>លេខរៀងវិក្កយបត្រ</Typography>
                    <Typography>
                      <b>INVOICE NUMBER:</b>
                    </Typography>
                  </Grid>
                  <Grid item xs={7}>
                    <Typography> {data?.invoiceNumber?.value}</Typography>
                  </Grid>
                </Grid>
                <Grid container alignItems='center'>
                  <Grid item xs>
                    <Typography>កាលបរិច្ចេទ</Typography>
                    <Typography>
                      <b>INVOICE DATE:</b>
                    </Typography>
                  </Grid>
                  <Grid item xs={7}>
                    <Typography>
                      {moment(data?.invoiceDate).format('DD/MM/YYYY')}
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Grid item container xs={12}>
            <Grid item xs={12}>
              <Products data={data.orderItems} />
            </Grid>
            {data.costLines?.length > 0 && (
              <Grid item xs={12} style={{ marginTop: '20px' }}>
                <CostLine data={data.costLines} />
              </Grid>
            )}
          </Grid>
          <Grid item container justify='flex-end' xs={12}>
            <Grid item xs={4}>
              <TotalDisplayAmountDue
                size='small'
                className={classes.total}
                tax={data?.totalTax}
                costing={data?.totalCosting}
                total={data?.total}
                amountDue={data?.amountDue}
                shippingFee={data?.shippingFee}
              >
                {totalPayment ? (
                  <PriceWithLabel
                    label='ចំនួនទឹកប្រាក់ដែលបង់រួច / Total Payment'
                    value={totalPayment}
                  ></PriceWithLabel>
                ) : null}
                {totalCreditNote ? (
                  <PriceWithLabel
                    label='ចំនួនទឹកប្រាក់ឥណទាន/ Total Credit Note'
                    value={totalCreditNote}
                  ></PriceWithLabel>
                ) : null}
              </TotalDisplayAmountDue>
            </Grid>
          </Grid>
          {data?.note && <NoteInvoice note={data?.note} />}
          <Grid item>
            <Grid
              container
              style={{ marginTop: '50px' }}
              justify='space-between'
            >
              <Grid item>
                <Box style={{ borderTop: '1px solid black' }}>
                  <Typography>{"Customer's Authoried Signature"}</Typography>
                  <Typography>Name:</Typography>
                  <Typography>Position:</Typography>
                </Box>
              </Grid>
              <Grid item>
                <Box style={{ borderTop: '1px solid black' }}>
                  <Typography>{"The Company's Authoried Signature"}</Typography>
                  <Typography>Name:</Typography>
                  <Typography>Position:</Typography>
                </Box>
              </Grid>
            </Grid>
          </Grid>
          {/* <Grid item xs>
            <Typography>
              <b>Bank Information for Remittance of Payment</b>
            </Typography>
            <Box style={{ borderTop: '1px solid black' }} clone>
              <Grid container>
                <Grid item xs='auto'>
                  <ul>
                    <li>
                      Account Name: <b>BABYCO SHOPPING CO LTD</b>
                    </li>
                    <li>
                      Account Number: <b>060 313 500</b>
                    </li>
                    <li>
                      Bank Name: <b>Advance Bank of Asia (ABA)</b>
                    </li>
                    <br />
                    <li>
                      Account Number: <b>060 313 500</b>
                    </li>
                    <li>
                      Bank Name: <b>Wing, E-Money, True Money, AMK, Ly Hour</b>
                    </li>
                  </ul>
                </Grid>
              </Grid>
            </Box>
          </Grid> */}
        </Grid>
      </Grid>
    </Grid>
  );
});

export default OrderInvoice;
