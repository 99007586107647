import {
  Box,
  Button,
  Grid,
  IconButton,
  InputBase,
  makeStyles,
  Typography,
} from '@material-ui/core';
import React, { useState, useCallback } from 'react';
import { AiOutlineUser } from 'react-icons/ai';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import { RiLockPasswordLine } from 'react-icons/ri';
import debounce from 'debounce';
import { useForm } from 'react-hook-form';
import DeviceDetector from 'device-detector-js';
import axios from 'axios';
import { useTranslation } from 'react-i18next';
import { useHistory, useLocation } from 'react-router-dom';
import queryString from 'query-string';
import registerMui from '../../util/react-hook-form-helper/register-mui';
import LoginFormContainer from 'src/components/login-form-container';
import CustomLink from '../../components/custom-link';
import { emailOrPhoneRegexp } from 'src/util/regex-pattern';
import { useSnackbar } from 'notistack';

export const useStyles = makeStyles((theme) => ({
  input: {
    background: 'white',
    borderRadius: '44px',
    padding: '5px 20px',
    '& > div > svg': {
      fontSize: '24px',
      color: '#7D7D7D',
      marginRight: '15px',
    },
    '& input': {
      fontSize: '16px',
      paddingBottom: '6px',
    },
  },
  button: {
    height: '43.11px',
    borderRadius: '44px',
  },
  text: {
    fontSize: '13px',
    textDecoration: 'none',
    fontWeight: 'bold',
  },
}));

const LoginPage = () => {
  const deviceDetector = new DeviceDetector();
  const { userAgent } = navigator;
  const deviceInfo = JSON.stringify(deviceDetector.parse(userAgent));
  const [showPassword, setShowPassword] = useState(false);
  const { register, handleSubmit } = useForm();
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation();
  const history = useHistory();
  const classes = useStyles();
  const { search } = useLocation();
  const urlQuery = queryString.parse(search);

  const onSubmit = useCallback(
    debounce((data) => {
      axios
        .post(`${process.env.REACT_APP_API}/api/v1/auth`, {
          ...data,
          deviceInfo,
        })
        .then(() => {
          if (urlQuery.callback) {
            history.push(decodeURIComponent(urlQuery.callback));
          } else {
            history.push('/dashboard');
          }
        })
        .catch((error) => {
          if (error?.response?.data?.failedAttempt) {
            const { failedAttempt, maxFailedAttempt } = error.response.data;
            const remainingAttempt = maxFailedAttempt - failedAttempt;
            if (remainingAttempt > 0) {
              enqueueSnackbar(
                t('failed-login-attempt', {
                  remainingAttempt: maxFailedAttempt - failedAttempt,
                  maxAttempt: maxFailedAttempt,
                }),
                {
                  variant: 'error',
                }
              );
            } else {
              enqueueSnackbar(t('account-locked'), {
                variant: 'error',
              });
            }
          } else {
            enqueueSnackbar(t(error.response?.data.key), {
              variant: 'error',
            });
          }
        });
    }, 500),
    []
  );

  const handleShowPassword = () => setShowPassword((prev) => !prev);

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <LoginFormContainer>
        <Grid
          container
          spacing={2}
          // direction='column'
          // style={{ height: '100%' }}
          justify='center'
        >
          <Grid item xs={12}>
            <Typography
              color='primary'
              variant='h6'
              style={{ textTransform: 'initial' }}
            >
              {t('login')}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <div className={classes.input}>
              <InputBase
                placeholder={t('email-or-phone')}
                fullWidth
                required
                {...registerMui(
                  register('emailOrPhone', {
                    required: true,
                    pattern: emailOrPhoneRegexp,
                  })
                )}
                startAdornment={<AiOutlineUser />}
              />
            </div>
          </Grid>
          <Grid item xs={12}>
            <div className={classes.input}>
              <InputBase
                placeholder={t('password')}
                fullWidth
                required
                {...registerMui(register('password', { required: true }))}
                type={showPassword ? 'text' : 'password'}
                startAdornment={<RiLockPasswordLine />}
                endAdornment={
                  <IconButton size='small' onClick={handleShowPassword}>
                    {showPassword ? <Visibility /> : <VisibilityOff />}
                  </IconButton>
                }
              />
            </div>
            <Box mt={1} textAlign='right'>
              <CustomLink to='/forgot-password'>
                {t('forgot-password')}
              </CustomLink>
            </Box>
          </Grid>
          <Grid item xs={12}>
            <Button
              disableElevation
              type='submit'
              className={classes.button}
              variant='contained'
              fullWidth
              color='primary'
            >
              {t('login')}
            </Button>
            <Box mt={1} textAlign='center'>
              <CustomLink to='/register'>{t('need-account')}</CustomLink>
            </Box>
          </Grid>
        </Grid>
      </LoginFormContainer>
    </form>
  );
};

export default LoginPage;
