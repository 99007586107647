import { Grid, makeStyles } from '@material-ui/core';
import React from 'react';
import { useTranslation } from 'react-i18next';

import PriceWithLabel from '../price-with-label';

const useStyles = makeStyles((theme) => ({
  container: {
    backgroundColor: theme.palette.primary.lighter,
    padding: '10px',
  },
}));

const TableFooter = ({ data }) => {
  const classes = useStyles();
  const { t } = useTranslation();
  return (
    <Grid container>
      <Grid item xs></Grid>
      <Grid item xs='auto'>
        <Grid container className={classes.container} spacing={1}>
          {data.map((item, index) => {
            const { isCurrency = true } = item;
            return (
              <Grid item xs={6}>
                <PriceWithLabel
                  label={t(item.label)}
                  value={item.value}
                  format={isCurrency ? 'dollar' : 'normal'}
                />
              </Grid>
            );
          })}
        </Grid>
      </Grid>
    </Grid>
  );
};

export default TableFooter;
