const roleData = ( name ) => ( {
  roleName: name,
  features: [
    {
      featureName: 'user',
      rolePermission: [
        {
          permissionName: 'view',
          boolean: true,
        },
        {
          permissionName: 'create',
          boolean: true,
        },
        {
          permissionName: 'update',
          boolean: true,
        },
        {
          permissionName: 'delete',
          boolean: true,
        },
      ],
    },
    {
      featureName: 'products',
      rolePermission: [
        {
          permissionName: 'view',
          boolean: true,
        },
        {
          permissionName: 'create',
          boolean: true,
        },
        {
          permissionName: 'update',
          boolean: true,
        },
        {
          permissionName: 'delete',
          boolean: true,
        },
      ],
    },
    {
      featureName: 'category',
      rolePermission: [
        {
          permissionName: 'view',
          boolean: true,
        },
        {
          permissionName: 'create',
          boolean: true,
        },
        {
          permissionName: 'update',
          boolean: true,
        },
        {
          permissionName: 'delete',
          boolean: true,
        },
      ],
    },
    {
      featureName: 'purchase-order',
      rolePermission: [
        {
          permissionName: 'view',
          boolean: true,
        },
        {
          permissionName: 'create',
          boolean: true,
        },
        {
          permissionName: 'update',
          boolean: true,
        },
        {
          permissionName: 'cancel',
          boolean: true,
        },
        {
          permissionName: 'reconcile',
          boolean: true,
        },
      ],
    },
    {
      featureName: 'purchase-in',
      rolePermission: [
        {
          permissionName: 'view',
          boolean: true,
        },
        {
          permissionName: 'create',
          boolean: true,
        },
        {
          permissionName: 'update',
          boolean: true,
        },
        {
          permissionName: 'pack',
          boolean: true,
        },
        {
          permissionName: 'cancel',
          boolean: true,
        },
      ],
    },
    {
      featureName: 'purchase-return',
      rolePermission: [
        {
          permissionName: 'view',
          boolean: true,
        },
        {
          permissionName: 'create',
          boolean: true,
        },
        {
          permissionName: 'update',
          boolean: true,
        },
        {
          permissionName: 'cancel',
          boolean: true,
        },
        {
          permissionName: 'complete',
          boolean: true,
        },
        {
          permissionName: 'pack',
          boolean: true,
        },
      ],
    },
    {
      featureName: 'orders',
      rolePermission: [
        {
          permissionName: 'view',
          boolean: true,
        },
        {
          permissionName: 'create',
          boolean: true,
        },
        {
          permissionName: 'update',
          boolean: true,
        },
        {
          permissionName: 'create-payment',
          boolean: true,
        },
        {
          permissionName: 'view-payment',
          boolean: true,
        },
        {
          permissionName: 'delete-payment',
          boolean: true,
        },
        {
          permissionName: 'cancelled',
          boolean: true,
        },
        {
          permissionName: 'approved',
          boolean: true,
        },
        {
          permissionName: 'rejected',
          boolean: true,
        },
        {
          permissionName: 'packed',
          boolean: true,
        },
        {
          permissionName: 'repack',
          boolean: true,
        },
        {
          permissionName: 'shipping',
          boolean: true,
        },
        {
          permissionName: 'delivery-failed',
          boolean: true,
        },
        {
          permissionName: 'delivered',
          boolean: true,
        },
        {
          permissionName: 'returned',
          boolean: true,
        },
        {
          permissionName: 'delete-returned',
          boolean: true,
        },
        {
          permissionName: 'failed',
          boolean: true,
        },
        {
          permissionName: 'view-activity',
          boolean: true,
        },
      ],
    },
    {
      featureName: 'supplier',
      rolePermission: [
        {
          permissionName: 'view',
          boolean: true,
        },
        {
          permissionName: 'create',
          boolean: true,
        },
        {
          permissionName: 'update',
          boolean: true,
        },
        {
          permissionName: 'delete',
          boolean: true,
        },
      ],
    },
    {
      featureName: 'customer',
      rolePermission: [
        {
          permissionName: 'view',
          boolean: true,
        },
        {
          permissionName: 'create',
          boolean: true,
        },
        {
          permissionName: 'update',
          boolean: true,
        },
        {
          permissionName: 'delete',
          boolean: true,
        },
      ],
    },
    {
      featureName: 'report',
      rolePermission: [
        {
          permissionName: 'view',
          boolean: true,
        },
        {
          permissionName: 'filter',
          boolean: true,
        },
        {
          permissionName: 'print',
          boolean: true,
        },
        {
          permissionName: 'export',
          boolean: true,
        },
      ],
    },
    {
      featureName: 'role',
      rolePermission: [
        {
          permissionName: 'view',
          boolean: true,
        },
        {
          permissionName: 'create',
          boolean: true,
        },
        {
          permissionName: 'update',
          boolean: true,
        },
        {
          permissionName: 'delete',
          boolean: true,
        },
      ],
    },
    {
      featureName: 'exchange-rate',
      rolePermission: [
        {
          permissionName: 'view',
          boolean: true,
        },
        {
          permissionName: 'create',
          boolean: true,
        },
        {
          permissionName: 'update',
          boolean: true,
        },
        {
          permissionName: 'delete',
          boolean: true,
        },
      ],
    },
    {
      featureName: 'unit',
      rolePermission: [
        {
          permissionName: 'view',
          boolean: true,
        },
        {
          permissionName: 'create',
          boolean: true,
        },
        {
          permissionName: 'update',
          boolean: true,
        },
        {
          permissionName: 'delete',
          boolean: true,
        },
      ],
    },
    {
      featureName: 'brand',
      rolePermission: [
        {
          permissionName: 'view',
          boolean: true,
        },
        {
          permissionName: 'create',
          boolean: true,
        },
        {
          permissionName: 'update',
          boolean: true,
        },
        {
          permissionName: 'delete',
          boolean: true,
        },
      ],
    },
    {
      featureName: 'storage',
      rolePermission: [
        {
          permissionName: 'view',
          boolean: true,
        },
        {
          permissionName: 'create',
          boolean: true,
        },
        {
          permissionName: 'update',
          boolean: true,
        },
        {
          permissionName: 'delete',
          boolean: true,
        },
      ],
    },
    {
      featureName: 'payment-account',
      rolePermission: [
        {
          permissionName: 'view',
          boolean: true,
        },
        {
          permissionName: 'create',
          boolean: true,
        },
        {
          permissionName: 'update',
          boolean: true,
        },
        {
          permissionName: 'delete',
          boolean: true,
        },
      ],
    },
    {
      featureName: 'stock',
      rolePermission: [
        {
          permissionName: 'view',
          boolean: true,
        },
        {
          permissionName: 'create',
          boolean: true,
        },
        {
          permissionName: 'update',
          boolean: true,
        },
        {
          permissionName: 'delete',
          boolean: true,
        },
      ],
    },
    {
      featureName: 'login',
      rolePermission: [
        {
          permissionName: 'dashboard',
          boolean: true,
        },
        {
          permissionName: 'pos',
          boolean: true,
        },
      ],
    },
  ],
} );

export default roleData;
