import DateFnsUtils from '@date-io/date-fns';
import {
  Box,
  Button,
  Collapse,
  Grid,
  Typography,
  Divider,
  makeStyles,
} from '@material-ui/core';
import { DatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import React, { useContext, useEffect } from 'react';
import TextInput from '../../../../components/text-input';
import TextInputContainer from '../../../../components/text-input-container';
import { useTranslation } from 'react-i18next';
import { useForm, Controller } from 'react-hook-form';
import registerMui from '../../../../util/react-hook-form-helper/register-mui';
import axios from 'axios';
import OrderHistory from './order-history';
import { UtilityContext } from '../../../../components/context-provider/utilty-context';
import { UserContext } from '../../../../components/context-provider/user-context';
import { formHandler } from '../../../../util/react-hook-form-helper';
import TextInputListSearch from '../../../../components/text-input-list-search';

const useStyles = makeStyles((theme) => ({
  container: {
    border: `1px solid ${theme.palette.grey[300]}`,
    padding: '15px',
    borderRadius: '5px',
  },
}));

const ReceivePayment = ({
  orderInfo,
  handleRefreshTable,
  showHistory,
  setShowHistory,
  triggerFetch,
  refreshForm,
  handleRefreshHistory,
}) => {
  const classes = useStyles();
  const { showSnackbar } = useContext(UtilityContext);
  const { checkAccess } = useContext(UserContext);
  const { register, control, handleSubmit, setValue } = useForm();
  const { t } = useTranslation();

  useEffect(() => {
    setValue('amount', orderInfo.amountDue);
  }, [orderInfo]);

  const onSubmit = async (data) => {
    if (data.amount > orderInfo.amountDue) {
      showSnackbar({
        message: t('order-payment-over-amount-due'),
        variant: 'error',
      });
    } else {
      try {
        // add payment
        const { data: orderData } = await axios.post(
          `${process.env.REACT_APP_API}/api/v1/orders/payment/${orderInfo?._id}`,
          data
        );
        refreshForm();
        showSnackbar({ message: orderData.message });

        handleRefreshTable();
        if (showHistory) {
          handleRefreshHistory();
        }
      } catch ({ response }) {
        showSnackbar({ message: response?.data.message, variant: 'error' });
      }
    }
  };

  return (
    <form onSubmit={(e) => formHandler(e, handleSubmit(onSubmit))}>
      {orderInfo?.amountDue !== 0 ? (
        <Box className={classes.container}>
          <Grid container direction="column" spacing={1}>
            <Grid item>
              <Typography style={{ fontSize: '16px' }}>
                <b>Recieve a Payment</b>
              </Typography>
            </Grid>
            <Grid item container spacing={1} alignItems="flex-end">
              <Grid item xs={3}>
                <TextInput
                  {...registerMui(register('amount', { valueAsNumber: true }))}
                  defaultValue={orderInfo?.amountDue}
                  boldLabel={false}
                  label="Amount"
                  type="number"
                />
              </Grid>
              <Grid item xs={3}>
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                  <TextInputContainer boldLabel={false} label={t('date')}>
                    <Controller
                      control={control}
                      name="paidDate"
                      defaultValue={new Date()}
                      render={({ field: { value, onChange } }) => {
                        return (
                          <DatePicker
                            disableToolbar
                            autoOk
                            inputVariant="outlined"
                            size="small"
                            format="dd/MM/yyyy"
                            margin="normal"
                            value={value}
                            onChange={onChange}
                          />
                        );
                      }}
                    ></Controller>
                  </TextInputContainer>
                </MuiPickersUtilsProvider>
              </Grid>
              <Grid item xs={3}>
                <Controller
                  control={control}
                  name="paidTo"
                  defaultValue={null}
                  render={({ field: { value, onChange } }) => {
                    return (
                      <TextInputListSearch
                        boldLable={false}
                        disableClearable={false}
                        value={value}
                        required
                        onChange={(e, data) => {
                          onChange(data);
                        }}
                        dataConfig={['paymentAccounts']}
                        optionsURL={`${process.env.REACT_APP_API}/api/v1/payment-accounts`}
                        label={t('paid-to')}
                        getOptionLabel={(option) =>
                          `${option.code} - ${option.name}`
                        }
                        getOptionSelected={(option, value) =>
                          option.name === value.name || value._id || value.id
                        }
                      />
                    );
                  }}
                ></Controller>
              </Grid>
              <Grid item xs={3}>
                <TextInput
                  {...registerMui(register('note'))}
                  boldLabel={false}
                  label="Note"
                />
              </Grid>
              <Grid item>
                <Button
                  type="submit"
                  variant="contained"
                  disableElevation
                  color="primary"
                >
                  Add Payment
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Box>
      ) : (
        <Divider />
      )}
      {checkAccess({ feature: 'orders', action: 'view-activity' }) && (
        <Box mt={2}>
          <Grid container>
            <Grid item xs={12}>
              {/* {orderInfo?.total !== orderInfo?.amountDue ? ( */}
              <Button
                variant="contained"
                disableElevation
                onClick={() => setShowHistory((prev) => !prev)}
              >
                {showHistory ? 'Hide' : 'Show'} History
              </Button>
              {/* ) : null} */}
              {showHistory && (
                <Collapse in>
                  <Box mb={8}>
                    <OrderHistory
                      orderInfo={orderInfo}
                      triggerFetch={triggerFetch}
                    />
                  </Box>
                </Collapse>
              )}
            </Grid>
          </Grid>
        </Box>
      )}
    </form>
  );
};

export default ReceivePayment;
