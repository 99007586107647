import Autocomplete from '@material-ui/lab/Autocomplete';
import { TextField, Chip } from '@material-ui/core';
import React, { useContext, useEffect, useState } from 'react';
import { Controller } from 'react-hook-form';
import axios from 'axios';
import { UtilityContext } from 'src/components/context-provider/utilty-context';

const RoleSelect = ({ control, name = 'roles', ...inputProps }) => {
  const [allRoles, setAllRoles] = useState([]);
  const { showSnackbar } = useContext(UtilityContext);

  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_API}/api/v1/auth/roles`)
      .then(({ data }) => {
        if (typeof data === 'object') {
          const menu = data.roles.map((role) => {
            const roleValue = { id: role._id, name: role.roleName };
            return roleValue;
          });
          setAllRoles(menu);
        } else {
          console.log('error response');
        }
      })
      .catch(({ response }) => {
        showSnackbar({
          message: response.data?.key,
          variant: 'error',
        });
      });
  }, []);

  return (
    <Controller
      control={control}
      rules={{ required: true }}
      name={name}
      defaultValue={[]}
      render={({ field: { onChange, value, ref, ...props } }) => {
        return (
          <Autocomplete
            multiple
            autoHighlight
            disableClearable
            inputRef={ref}
            value={value}
            options={allRoles}
            getOptionLabel={(option) => option.name}
            getOptionSelected={(option, value) => option.name === value.name}
            onChange={(e, data) => {
              onChange(data);
            }}
            renderTags={(tagValue, getTagProps) =>
              tagValue.map((option, index) => {
                return (
                  <Chip
                    label={option.name}
                    style={{ margin: '0 2px' }}
                    {...getTagProps({ index })}
                    color='primary'
                    size='small'
                  />
                );
              })
            }
            {...props}
            renderInput={(params) => (
              <TextField
                {...params}
                size='small'
                variant='outlined'
                InputProps={{ ...params.InputProps }}
              />
            )}
            {...inputProps}
          />
        );
      }}
    />
  );
};

export default RoleSelect;
