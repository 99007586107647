import { makeStyles, Typography } from '@material-ui/core';

const usePriceWithLabelStyle = makeStyles((theme) => ({
  root: {
    '& p:first-child': {
      fontSize: '12px',
      textTransform: 'uppercase',
      color: theme.palette.grey[600],
    },
    '& input': {
      fontSize: ({ valueFontSize }) => valueFontSize,
      fontWeight: 'bold',
      textAlign: 'right',
      marginRight: '5px',
    },
  },
}));

const LabelTotal = (props) => {
  const { label, valueFontSize = '15px' } = props;
  const classes = usePriceWithLabelStyle({ valueFontSize });
  return (
    <div className={classes.root}>
      <Typography className='title' align='right' gutterBottom>
        {label}
      </Typography>
      {props.children}
    </div>
  );
};

export default LabelTotal;
