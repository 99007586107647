import React, { useEffect, useState, useContext } from 'react';
import { Grid, makeStyles, Paper } from '@material-ui/core';
import TitleBar from '../../../components/title-bar';
import FloatingBar from '../../../components/floating-bar';
import { useParams, useHistory } from 'react-router-dom';
import { useForm, FormProvider } from 'react-hook-form';
import axios from 'axios';
import ProductForm from './form';
import compressImage from '../../../util/image-compress';
import generateKey from '../../../util/generate-keys';
import { UserContext } from '../../../components/context-provider/user-context';
// import UnitMeasurement from "./form/unit-measurement";
import findImgSrcInHTML from './form/rich-text-editor/utilities/find-img-src-html';
import { UtilityContext } from '../../../components/context-provider/utilty-context';
import Spinner, { useSpinner } from 'src/components/spinner';
import { useTranslation } from 'react-i18next';
import UnitConversion from './form/unit-conversion';

const useStyles = makeStyles(() => ({
  root: {
    marginBottom: '150px',
    flexDirection: 'column',
    position: 'relative',
  },
}));

const CreateEditProductPage = () => {
  const classes = useStyles();
  const history = useHistory();
  const { t } = useTranslation();
  const { id } = useParams();
  const methods = useForm({
    defaultValues: {
      description: '',
      attributes: [],
      variants: [],
      descriptionImage: [],
      unitConversions: [{ unit: null, covertFrom: null, convertTo: null }],
    },
    shouldUnregister: true,
  });
  const { handleSubmit, reset } = methods;
  const { showSnackbar } = useContext(UtilityContext);
  const { me, s3BaseURL } = useContext(UserContext);
  const [state, setState] = useState('create');
  const [removeVariantImage, setRemoveVariantImage] = useState([]);
  const [loading, setLoading] = useState(true);
  const [images, setImages] = useState({ images: [], remove: [] });

  const { spinnerState, openSpinner, closeSpinner } = useSpinner();

  useEffect(() => {
    if (id) {
      openSpinner({ title: t('loading') });
      setState('edit');
      setLoading(true);
      axios
        .get(`${process.env.REACT_APP_API}/api/v1/products/${id}`)
        .then(({ data }) => {
          if (typeof data === 'object') {
            const { product } = data;
            const resetData = {
              ...product,
              description: product.description[0].value,
              shortDescription: product.shortDescription[0].value,
              descriptionImage: [],
            };
            resetData.unitConversions = [
              ...product.unitConversions,
              { unit: null, convertFrom: null, convertTo: null },
            ];
            setImages({ images: product.images, remove: [] });
            reset(resetData);
            setLoading(false);
          } else {
            console.log('error reponse');
          }
        })
        .catch(response => {
          console.log(response);
          showSnackbar({ message: response.data?.message, variant: 'error' });
        })
        .finally(() => {
          closeSpinner();
        });
    } else {
      setState('create');
    }
  }, []);

  const onSubmit = async formData => {
    openSpinner({ title: t('saving') });
    const warehouseId = me.warehouse ? me.warehouse.id : 'undefined';
    const {
      category,
      brand,
      unit,
      vendor,
      description,
      shortDescription,
      variants,
      descriptionImage,
      unitConversions,
    } = formData;

    // remove image
    if (images.remove.length !== 0 || removeVariantImage !== 0) {
      const combineData = [...images.remove, ...removeVariantImage];
      const removeImageKeys = combineData.reduce((acc, curr) => {
        acc.push({ Key: curr.thumbnail.split('.com/')[1] });
        acc.push({ Key: curr.key.split('.com/')[1] });
        return acc;
      }, []);

      if (removeImageKeys.length !== 0) {
        try {
          await axios.post(`${process.env.REACT_APP_API}/api/v1/aws-s3/remove-file`, {
            Keys: removeImageKeys,
          });
        } catch ({ response }) {
          showSnackbar({ message: response.data?.message, variant: 'error' });
          console.error(response);
          closeSpinner();
          return;
        }
      }
    }

    const updateData = {
      ...formData,
      vendor: { id: vendor?._id || vendor?.id, name: vendor?.name },
      category: category ? { id: category._id || category.id, name: category.name } : null,
      brand: brand ? { id: brand._id || brand.id, name: brand.name } : null,
      unit: { id: unit._id || unit.id, name: unit.name },
      shortDescription: [{ lang: 'en', value: shortDescription }],
      unitConversions: unitConversions.reduce((acc, curr) => {
        if (curr.unit?._id || curr.unit?.id) {
          const { unit, convertFrom, convertTo } = curr;
          acc.push({
            unit: {
              id: unit?._id ?? unit?.id,
              name: unit?.name || null,
            },
            convertFrom: convertFrom || null,
            convertTo: convertTo || null,
          });
        }
        return acc;
      }, []),
    };

    delete updateData.descriptionImage;

    let uploadObject = {
      ContentTypes: [],
      Keys: [],
      Images: [],
      variants: [],
      images: [],
    };

    try {
      // collect all image
      // top image
      if (images.images) {
        const uploadImages = await Promise.all(
          images.images.map(async item => {
            if (!('thumbnail' in item)) {
              const fileType = item.file.type;
              const fileExtension = item.file.type.split('/')[1];
              // generate 2 keys (one image one thumbnail)
              uploadObject.ContentTypes.push(fileType, fileType);
              const Key = `public/${warehouseId}/products/${generateKey()}`;
              const imageKey = `${Key}.${fileExtension}`;
              const thumbnailKey = `${Key}-thumbnail.${fileExtension}`;

              uploadObject.Keys.push(imageKey, thumbnailKey);
              // compress image
              const { image, thumbnail } = await compressImage(item.file);
              uploadObject.Images.push(image, thumbnail);
              return {
                key: `${s3BaseURL}/${imageKey}`,
                thumbnail: `${s3BaseURL}/${thumbnailKey}`,
                mimeType: fileType,
              };
            } else {
              return item;
            }
          }),
        );
        updateData.images = uploadImages;
      }

      // variant image
      if (variants) {
        await Promise.all(
          variants.map(async item => {
            let images = [];
            await Promise.all(
              item?.images?.map(async file => {
                if (!('thumbnail' in file)) {
                  const fileType = file.type;
                  const fileExtension = file.type.split('/')[1];
                  // generate 2 keys (one image one thumbnail)
                  uploadObject.ContentTypes.push(fileType, fileType);
                  const Key = `public/${warehouseId}/products/${generateKey()}`;
                  const imageKey = `${Key}.${fileExtension}`;
                  const thumbnailKey = `${Key}-thumbnail.${fileExtension}`;

                  uploadObject.Keys.push(imageKey, thumbnailKey);
                  // compress image
                  const { image, thumbnail } = await compressImage(file);
                  uploadObject.Images.push(image, thumbnail);
                  // push to images for variant
                  images.push({
                    key: `${s3BaseURL}/${imageKey}`,
                    thumbnail: `${s3BaseURL}/${thumbnailKey}`,
                    mimeType: fileType,
                  });
                } else {
                  images.push(file);
                }
              }),
            );

            uploadObject.variants.push({
              ...item,
              images,
            });
          }),
        );

        updateData.variants = uploadObject.variants;
      }

      // description image
      let updateDescription = description;
      if (updateDescription && descriptionImage?.length) {
        const existImages = findImgSrcInHTML(description);
        descriptionImage.forEach(file => {
          if (existImages.includes(file.url)) {
            const fileType = file.type;
            const fileExtension = file.type.split('/')[1];
            // generate key
            uploadObject.ContentTypes.push(fileType);
            const Key = `public/${warehouseId}/products/${generateKey()}`;
            const imageKey = `${Key}.${fileExtension}`;

            uploadObject.Keys.push(imageKey);
            uploadObject.Images.push(file.img);

            updateDescription = updateDescription.replace(file.url, `${s3BaseURL}/${imageKey}`);
          }
        });
      }
      updateData.description = [{ lang: 'en', value: updateDescription }];

      if (uploadObject.Keys.length) {
        const response = await axios.post(
          `${process.env.REACT_APP_API}/api/v1/aws-s3/file-upload-url`,
          {
            ContentTypes: uploadObject.ContentTypes,
            Keys: uploadObject.Keys,
          },
        );

        await Promise.all(
          response.data.map(async (item, index) => {
            await axios.put(item.uploadURL, uploadObject.Images[index], {
              headers: {
                'Content-Type': item.type,
                'Content-Encoding': 'base64',
              },
              withCredentials: false,
            });
          }),
        );
      }
    } catch (err) {
      showSnackbar({ message: 'upload-image-failed', variant: 'error' });
      closeSpinner();
      console.error(err);
      return;
    }

    switch (state) {
      case 'create':
        axios
          .post(`${process.env.REACT_APP_API}/api/v1/products`, updateData)
          .then(({ data }) => {
            showSnackbar({ message: data?.message });
            history.push('/dashboard/product');
          })
          .catch(({ response }) => {
            showSnackbar({ message: response.data?.message, variant: 'error' });
          })
          .finally(() => {
            closeSpinner();
          });
        break;
      case 'edit':
        axios
          .put(`${process.env.REACT_APP_API}/api/v1/products/${id}`, updateData)
          .then(({ data }) => {
            showSnackbar({ message: data?.message, variant: 'success' });
            history.push('/dashboard/product');
          })
          .catch(({ response }) =>
            showSnackbar({ message: response.data?.message, variant: 'error' }),
          )
          .finally(() => {
            closeSpinner();
          });
        break;
      default:
        console.log('Error state');
    }
  };

  const handleCancel = () => {
    goBackToList();
  };

  const goBackToList = () => {
    history.push('/dashboard/product');
  };

  return (
    <FormProvider {...methods}>
      <form id="product-form" onSubmit={handleSubmit(onSubmit)}>
        <Grid container className={classes.root} spacing={3}>
          <Grid item>
            <TitleBar disableButton />
          </Grid>
          <Grid item>
            <Paper>
              <Grid container>
                <Grid item xs={12}>
                  <ProductForm
                    loading={loading}
                    images={images.images}
                    setImages={setImages}
                    setRemoveVariantImage={setRemoveVariantImage}
                    state={state}
                  />
                </Grid>
              </Grid>
            </Paper>
          </Grid>
          <Grid item>
            <Paper>
              <Grid container>
                <Grid item xs={12}>
                  <UnitConversion state={state} />
                </Grid>
              </Grid>
            </Paper>
          </Grid>
          <FloatingBar
            onCancel={handleCancel}
            confirmButtonProps={{ form: 'product-form' }}
            confirmButtonText={state === 'create' ? 'create-product' : 'save-changes'}
          />
        </Grid>
        <Spinner state={spinnerState} />
      </form>
    </FormProvider>
  );
};

export default CreateEditProductPage;
