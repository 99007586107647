import {
  TableRow,
  TableCell,
  TableBody,
  Typography,
  Box,
} from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import displayProductName from 'src/util/display-product-name';
import CustomTable from '../../../../../components/custom-table';
import NumberFormatCustom from '../../../../../components/number-format';
import calculateDiscount from '../../../../../util/calculate-discount';

const Products = ({ data }) => {
  const { t } = useTranslation();

  const tableHeadData = [
    {
      value: (
        <>
          ល.រ <br /> No.
        </>
      ),
    },
    {
      value: (
        <>
          បរិយាយ <br /> Description
        </>
      ),
      width: '30%',
    },
    {
      value: (
        <>
          បរិមាណ <br /> Quantity
        </>
      ),
    },
    {
      value: (
        <>
          តម្លៃឯកតា
          <br /> Unit Price
        </>
      ),
    },
    {
      value: (
        <>
          ចុះតម្លៃ
          <br /> Discount
        </>
      ),
    },
    {
      value: (
        <>
          ចំនួន (ដុល្លា)
          <br /> Amount (US$)
        </>
      ),
      align: 'right',
      width: '10%',
    },
  ];

  return (
    <>
      <Box bgcolor='primary.light' id='title' color='white' p={1}>
        <Typography>
          <b>ទំនិញ / Items</b>
        </Typography>
      </Box>
      <CustomTable.Container size='small'>
        {/* table header */}
        <CustomTable.Head data={tableHeadData} />
        {/* table body */}
        <TableBody>
          {data
            ?.filter((field) => field.isCanceled === false)
            .map((field, index) => {
              // get value from field
              const get = getValue(field);

              return (
                <TableRow disabled key={index}>
                  <TableCell>
                    <CustomTable.CellValue>{index + 1}</CustomTable.CellValue>
                  </TableCell>
                  <TableCell>
                    <CustomTable.CellValue>
                      {displayProductName(get.productCode, get.productName)}
                    </CustomTable.CellValue>
                  </TableCell>
                  <TableCell>
                    <CustomTable.CellValue>{get.qty}</CustomTable.CellValue>
                  </TableCell>
                  <TableCell>
                    <CustomTable.CellValue>
                      <NumberFormatCustom value={get.salePrice} />
                    </CustomTable.CellValue>
                  </TableCell>
                  <TableCell>
                    <CustomTable.CellValue>
                      <NumberFormatCustom
                        value={get.discount?.amount}
                        format={get.discount?.type}
                      />
                    </CustomTable.CellValue>
                  </TableCell>
                  <TableCell>
                    <CustomTable.CellValue align='right'>
                      <NumberFormatCustom value={get.totalAmount} />
                    </CustomTable.CellValue>
                  </TableCell>
                </TableRow>
              );
            })}
        </TableBody>
      </CustomTable.Container>
    </>
  );
};

// function to get product value
export const getValue = (data) => {
  const productName = data?.variant?.name ?? '';
  const productCode = data?.variant?.altCode || data?.variant?.sku;
  const tax = data.tax ?? 0;
  const salePrice = data.salePrice ?? 0;
  const discount = data.discount ?? {
    amount: 0,
    type: 'percentage',
  };
  const returned = data.returned ?? 0;
  const qty = data.qty - returned ?? 1;
  const totalAmount = calculateDiscount(salePrice * qty, discount);
  const isCanceled = data.isCanceled ?? false;

  return {
    productName,
    productCode,
    tax,
    salePrice,
    qty,
    totalAmount,
    discount,
    returned,
    isCanceled,
  };
};

export default Products;
