import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
} from '@material-ui/core';
import axios from 'axios';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import LabelContainer from 'src/components/LabelContainer';
import useMessage from 'src/hooks/useMessage';
import useLoading from 'src/hooks/useLoading';
import { RHFDateInput } from '../rhf-controlled-input';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import { formatUTCDate } from 'src/util/date-format';

const OrderInvoiceDateEditorDialog = ({
  open,
  onClose,
  orderId,
  defaultDate,
  onSuccess,
}) => {
  const { t } = useTranslation();
  const { control, handleSubmit, reset } = useForm();
  const { loading, startLoading, stopLoading } = useLoading();
  const { showErrorResponseMessage, showSuccessResponseMessage } = useMessage();

  const onEntering = () => {
    reset({ invoiceDate: defaultDate });
  };

  const onSubmit = async (formData) => {
    try {
      startLoading();
      const { invoiceDate } = formData;
      const response = await axios.put(
        `${process.env.REACT_APP_API}/api/v1/orders/${orderId}/date`,
        { invoiceDate: formatUTCDate(invoiceDate) }
      );
      onSuccess && onSuccess(response.data.order);

      onClose();
      showSuccessResponseMessage(response);
    } catch (error) {
      console.log(error);
      showErrorResponseMessage(error);
    } finally {
      stopLoading();
    }
  };

  const onExited = () => {
    reset({});
  };

  return (
    <Dialog
      TransitionProps={{ onEntering, onExited }}
      open={open}
      maxWidth='xs'
      fullWidth
      onClose={!loading ? onClose : () => {}}
    >
      <form onSubmit={handleSubmit(onSubmit)}>
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
          <DialogTitle>{t('edit-invoice-date')}</DialogTitle>
          <DialogContent>
            <Grid container>
              <Grid item style={{ padding: '10px 0' }} xs={12}>
                <LabelContainer required text={t('date')}>
                  <RHFDateInput
                    fullWidth
                    inputVariant='standard'
                    required
                    variant='dialog'
                    control={control}
                    name='invoiceDate'
                  />
                </LabelContainer>
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button disabled={loading} onClick={onClose}>
              {t('cancel')}
            </Button>
            <Button
              type='submit'
              variant='contained'
              color='primary'
              disabled={loading}
              startIcon={loading && <CircularProgress size={18} />}
            >
              {t('confirm')}
            </Button>
          </DialogActions>
        </MuiPickersUtilsProvider>
      </form>
    </Dialog>
  );
};
export default OrderInvoiceDateEditorDialog;
