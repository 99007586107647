import { makeStyles, Typography } from '@material-ui/core';
import React from 'react';
import { Link } from 'react-router-dom';

const useStyles = makeStyles((theme) => ({
  link: {
    fontSize: '13px',
    textDecoration: 'none',
    fontWeight: 'bold',
    color: theme.palette.primary.main,
    cursor: 'pointer',
    '&:hover': {
      textDecoration: 'underline',
    },
  },
}));

const CustomLink = ({ children, to, component = Link, onClick }) => {
  const classes = useStyles();
  return (
    <Typography
      className={classes.link}
      component={component}
      to={to}
      onClick={onClick}
    >
      {children}
    </Typography>
  );
};

export default CustomLink;
