import { useState } from 'react';

const useDialog = (initial = { open: false, data: null }) => {
  const [state, setState] = useState(initial);

  const openDialog = (data = null) => {
    setState((prev) => {
      const updateState = { ...prev, open: true };
      if (data !== null && !data?.target) {
        updateState.data = data;
      }
      return updateState;
    });
  };

  const closeDialog = (data = null) => {
    setState((prev) => {
      const updateState = { ...prev, open: false };
      if (data !== null && !data?.target) {
        updateState.data = data;
      }
      return updateState;
    });
  };

  return { dialogState: state, openDialog, closeDialog };
};

export default useDialog;
