import { Grid } from '@material-ui/core';
import React from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import LabelInput from '../../../../../components/label-input';
import {
  RHFPhoneInput,
  RHFTextInput,
} from '../../../../../components/rhf-controlled-input';
import TextInput from '../../../../../components/text-input';
import registerMui from '../../../../../util/react-hook-form-helper/register-mui';
import InputGoogleMapSuggestion from './input-google-map-search';
import ListInputSearch from './list-input-search';
import {
  generateCityOptions,
  generateDistrictOptions,
  generateCommuneOptions,
} from './province-generator';

const AddressInput = () => {
  const { control, watch, register, setValue } = useFormContext();
  const { t } = useTranslation();
  const googleMapBaseLink = 'https://maps.google.com/maps?output=embed';

  const location = watch('location');

  let googleMapLink = '';

  if (location?.url) {
    googleMapLink = location.url;
  } else if (location?.nearby) {
    const geometry = `${location.nearby?.location?.lat},${location.nearby?.location?.lng}`;
    googleMapLink = `${googleMapBaseLink}&q=${geometry}`;
  } else {
    googleMapLink = `${googleMapBaseLink}&q=(${buildLocationName({
      city: location?.city?.en,
      district: location?.district?.en,
      commune: location?.commune?.en,
    })})`;
  }

  return (
    <Grid container spacing={3}>
      <Grid item xs={12} sm={6}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <LabelInput
              component={RHFTextInput}
              name='receiver'
              label={t('receiver')}
              autoFocus
              required
            />
          </Grid>
          <Grid item xs={12}>
            <LabelInput
              component={RHFPhoneInput}
              name='phone'
              label={t('phone')}
              required
            />
          </Grid>
          <Grid item xs={12}>
            <TextInput
              label={`${t('address')}`}
              danger
              {...registerMui(register('location.address'))}
              required
            />
          </Grid>
          <Grid item xs={12}>
            <Controller
              control={control}
              name='location.nearby'
              defaultValue={null}
              render={({ field: { value, onChange } }) => {
                return (
                  <InputGoogleMapSuggestion
                    value={value || null}
                    onChange={onChange}
                    label='Nearby'
                  />
                );
              }}
            />
          </Grid>

          <Grid item xs={12}>
            <Controller
              control={control}
              name='location.city'
              defaultValue={null}
              render={({ field: { value, onChange } }) => {
                return (
                  <ListInputSearch
                    value={value}
                    label={t('city-province')}
                    list={generateCityOptions()}
                    onChange={(e, data) => {
                      setValue('location.district', null);
                      setValue('location.commune', null);
                      onChange(data);
                    }}
                    getOptionLabel={(option) => {
                      const { en: name } = option;
                      return name;
                    }}
                    getOptionSelected={(option, value) =>
                      option?.name === value?.name
                    }
                  />
                );
              }}
            ></Controller>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Controller
              control={control}
              name='location.district'
              defaultValue={null}
              render={({ field: { value, onChange } }) => {
                return (
                  <ListInputSearch
                    value={value}
                    disabled={!location?.city}
                    list={generateDistrictOptions(location)}
                    onChange={(e, data) => {
                      setValue('location.commune', null);
                      onChange(data);
                    }}
                    label={t('district')}
                    getOptionLabel={(option) => {
                      const { en: name } = option;
                      return name;
                    }}
                    getOptionSelected={(option, value) =>
                      option.name === value.name
                    }
                  />
                );
              }}
            ></Controller>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Controller
              control={control}
              name='location.commune'
              defaultValue={null}
              render={({ field: { value, onChange } }) => {
                return (
                  <ListInputSearch
                    value={value}
                    disabled={!location?.district}
                    list={generateCommuneOptions(location)}
                    onChange={(e, data) => onChange(data)}
                    label={t('commune')}
                    getOptionLabel={(option) => {
                      const { en: name } = option;
                      return name;
                    }}
                    getOptionSelected={(option, value) =>
                      option.name === value.name
                    }
                  />
                );
              }}
            ></Controller>
          </Grid>
        </Grid>
      </Grid>
      <Grid item container xs={12} sm={6}>
        <Grid container direction='column' spacing={3}>
          <Grid item xs>
            <iframe
              title='map'
              style={{ width: '100%', height: '100%', border: 0 }}
              src={googleMapLink}
            ></iframe>
          </Grid>
          <Grid item>
            <TextInput
              {...registerMui(register('location.url'))}
              label={t('google-map-url')}
            />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

const buildLocationName = ({ city, district, commune }) => {
  if (city || district || commune) {
    return `${district || ''} ${commune || ''} ${city || ''}`;
  }
  return 'cambodia';
};

export default AddressInput;
