// import axios from 'axios';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Grid, Typography } from '@material-ui/core';
import LabelInput from '../label-input';
import AutoCompleteAsync from '../autocomplete-async';

const VariantInputSearch = ({
  index,
  rules,
  name,
  defaultValue = null,
  setStocks,
  stocks,
  disabled,
  ...props
}) => {
  const { t } = useTranslation();

  return (
    <LabelInput
      component={AutoCompleteAsync}
      placeholder={t('search')}
      clearOnSelect
      noOptionsText={t('no-product-found')}
      optionsURL={`${process.env.REACT_APP_API}/api/v1/stocks`}
      getOptions={(data) => data?.stocks}
      getOptionLabel={variantGetOptionLabel}
      getOptionSelected={(option, value) =>
        option.product?.name === value.product?.name
      }
      disableClearable
      renderOption={renderOption}
      {...props}
    />
  );
};

const renderOption = (data) => {
  return (
    <Grid container justify='space-between'>
      <Grid item xs={7}>
        <Typography>{data?.variant?.name}</Typography>
      </Grid>
      <Grid item xs={5}>
        <Typography align='right'>Stock on hand</Typography>
        <Typography align='right'>{data?.qty}</Typography>
      </Grid>
    </Grid>
  );
};

// function to get product value
export const getValue = (data) => {
  const productName = data?.variant?.name ?? '';
  const productCode = data?.variant?.altCode || data?.variant?.sku;

  return {
    productName,
    productCode,
  };
};

// const findBySKU = async (sku) => {
//   try {
//     const response = await axios.get(
//       `${process.env.REACT_APP_API}/api/v1/stocks/find-by-sku?value=${sku}`
//     );
//     return response?.data?.variant;
//   } catch (err) {
//     console.log(err);
//     return null;
//   }
// };

// for autocomplete variant
const variantGetOptionLabel = (option) => {
  if (typeof option === 'object') {
    const code = (option?.variant?.sku || option?.sku) ?? null;
    return `${code ? `${code} - ` : ''}${
      option?.variant?.name || option?.name
    }`;
  } else {
    return '';
  }
};

export default VariantInputSearch;
