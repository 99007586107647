import React, { useEffect, useState, useContext } from 'react';
import { Grid, makeStyles, Paper } from '@material-ui/core';
import TitleBar from '../../../components/title-bar';
import FloatingBar from '../../../components/floating-bar';
import { useParams, useHistory } from 'react-router-dom';
import { useForm, FormProvider } from 'react-hook-form';
import axios from 'axios';
import Products from './items';
import { UtilityContext } from '../../../components/context-provider/utilty-context';
import DateFnsUtils from '@date-io/date-fns';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import { useTranslation } from 'react-i18next';
import Spinner, { useSpinner } from 'src/components/spinner';
import DiscountInformation from './information';
import useMessage from 'src/hooks/useMessage';
import moment from 'moment';

const useStyles = makeStyles(() => ({
  root: {
    marginBottom: '150px',
    flexDirection: 'column',
    position: 'relative',
  },
}));

const DiscountFormPage = () => {
  const { t } = useTranslation();
  const classes = useStyles();
  const history = useHistory();
  const { id } = useParams();
  const { showSnackbar } = useContext(UtilityContext);

  const { showErrorResponseMessage } = useMessage();
  const { spinnerState, openSpinner, closeSpinner } = useSpinner();
  const [isDirty, setIsDirty] = useState(false);

  const methods = useForm({
    defaultValues: {
      name: '',
      expiredDate: moment().add('30', 'days').toISOString(),
      startDate: moment().toISOString(),
      discount: { pos: false, website: true },
      products: [],
      amount: 10,
      disc: { amount: 1, type: 'percentage' },
    },
    shouldUnregister: true,
  });

  const [state, setState] = useState('create');

  const [discountData, setDiscountData] = useState(null);

  useEffect(() => {
    if (id) {
      openSpinner({ title: t('loading') });
      setState('edit');
      axios
        .get(`${process.env.REACT_APP_API}/api/v1/discounts/${id}`)
        .then(({ data }) => {
          setDiscountData(data.discount);
        })
        .catch(({ response }) => {
          showSnackbar({
            message: response?.data?.message,
            variant: 'error',
          });
        })
        .finally(() => {
          closeSpinner();
        });
    } else {
      setState('create');
    }
  }, []);

  useEffect(() => {
    if (discountData) {
      const disc = {
        amount: discountData.amount,
        type: discountData.type || 'percentage',
      };
      methods.reset({ disc, ...discountData });
    }
  }, [discountData]);

  const onSubmit = async (formData) => {
    // openSpinner({ title: t('saving') });

    const { name, expiredDate, disc, discount, products, startDate } = formData;
    const { amount, type } = disc; // store discount amount and type

    const payload = {
      name: name || `DISCOUNT-${amount}${type === 'currency' ? '$' : '%'}`,
      expiredDate,
      amount,
      type,
      startDate,
      discount, // store bool of enable on pos or website
      products: products?.map((product) => product._id) || [],
    };

    switch (state) {
      case 'create':
        axios
          .post(`${process.env.REACT_APP_API}/api/v1/discounts`, payload)
          .then(({ data }) => {
            showSnackbar({ message: data?.message });
            goBackToList();
          })
          .catch((error) => {
            showErrorResponseMessage(error);
          })
          .finally(() => {
            closeSpinner();
          });
        break;
      case 'edit':
        console.log('payload', payload);
        axios
          .put(`${process.env.REACT_APP_API}/api/v1/discounts/${id}`, payload)
          .then(({ data }) => {
            showSnackbar({ message: data?.message });
            goBackToList();
          })
          .catch((error) => {
            showErrorResponseMessage(error);
          })
          .finally(() => {
            closeSpinner();
          });
        break;
      default:
        console.log('Error state');
    }
  };

  const handleCancel = () => {
    goBackToList();
  };

  const goBackToList = () => {
    history.push('/dashboard/discount');
  };

  return (
    <FormProvider {...methods}>
      <MuiPickersUtilsProvider utils={DateFnsUtils}>
        <form id='discount-form' onSubmit={methods.handleSubmit(onSubmit)}>
          <Grid container className={classes.root} spacing={3}>
            <Grid item>
              <TitleBar disableButton />
            </Grid>
            <Grid item>
              <Paper className='container'>
                <DiscountInformation />
              </Paper>
            </Grid>
            <Grid item>
              <Paper className='container'>
                <Grid container spacing={3}>
                  <Grid item xs={12}>
                    <Products setIsDirty={setIsDirty} />
                  </Grid>
                </Grid>
              </Paper>
            </Grid>
            <FloatingBar
              onCancel={handleCancel}
              confirmButtonProps={{ form: 'discount-form' }}
              disabledConfirmButton={
                Object.keys(methods.formState.dirtyFields).length === 0 &&
                isDirty === false
              }
              confirmButtonText={
                state === 'create' ? 'create-discount' : 'save-changes'
              }
            />
          </Grid>
        </form>
        <Spinner state={spinnerState} />
      </MuiPickersUtilsProvider>
    </FormProvider>
  );
};

export default DiscountFormPage;
