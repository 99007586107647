import React, { useCallback } from 'react';
import { Button, makeStyles, Typography } from '@material-ui/core';
import PhotoIcon from '@material-ui/icons/Photo';
import { useDropzone } from 'react-dropzone';
import ImagePreview from './image-preview';

const useStyles = makeStyles(() => ({
  root: {
    outline: 'none',
    height: '100%',
    position: 'relative',
    backgroundColor: 'rgba(9,128,146,0.1)',
    backgroundImage:
      "url(\"data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' stroke='%23098092FF' stroke-width='4' stroke-dasharray='12' stroke-dashoffset='0' stroke-linecap='square'/%3e%3c/svg%3e\")",
  },
  container: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    textAlign: 'center',
    height: '100%',
    '& > svg': {
      fontSize: '100px',
      color: '#6a969e',
    },
    '& button': {
      margin: '5px 0',
    },
  },
  overlay: {
    top: 0,
    backgroundColor: 'rgba(163,218,227,0.9)',
    position: 'absolute',
    width: '100%',
    height: '100%',
    zIndex: 3,
  },
}));

const ImageUpload = ({ images, setImages, setRemoveImage }) => {
  const classes = useStyles();

  const onDrop = useCallback(
    async (acceptedFiles) => {
      if (acceptedFiles.length > 0) {
        const newImage = acceptedFiles.filter((file) => {
          if (images.length > 0) {
            return !images.find(
              (image) => JSON.stringify(image) === JSON.stringify(file)
            );
          }
          return file;
        });
        setImages([...images, ...newImage]);
      }
    },
    [images]
  );

  const handleRemove = (index) => {
    const filter = [...images];
    if ('thumbnail' in filter[index]) {
      setRemoveImage((prev) => [...prev, filter[index]]);
    }
    filter.splice(index, 1);
    setImages(filter);
  };

  const { getRootProps, getInputProps, isDragActive, open } = useDropzone({
    onDrop,
    noClick: true,
    accept: 'image/*',
    maxFiles: 15,
  });

  return (
    <div {...getRootProps()} className={classes.root}>
      <input {...getInputProps()} />
      <div className={classes.container}>
        <PhotoIcon />
        <Typography variant='caption'>Drag image(s) here</Typography>
        <Typography variant='caption'>or</Typography>
        <Button
          onClick={open}
          disableElevation
          size='small'
          variant='contained'
          color='primary'
        >
          Browse image
        </Button>
        <Typography variant='caption' gutterBottom>
          You can add up to 15 images, each not exceeding 5MB
        </Typography>
        <ImagePreview images={images} handleRemove={handleRemove} />
      </div>
      {isDragActive && <div className={classes.overlay}></div>}
    </div>
  );
};

export default ImageUpload;
