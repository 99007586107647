import { Grid } from '@material-ui/core';
import React, { useEffect, useState, useContext } from 'react';
import DialogContainer from '../../../components/dialog-container';
import TextInput from '../../../components/text-input';
import { useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';
import axios from 'axios';
import { UtilityContext } from '../../../components/context-provider/utilty-context';
import registerMui from '../../../util/react-hook-form-helper/register-mui';
import { formHandler } from '../../../util/react-hook-form-helper';
import LabelInput from '../../../components/label-input';
import { RHFPhoneInput } from '../../../components/rhf-controlled-input';

const VendorForm = ({ open, onClose, newData, setReturnData }) => {
  const { t } = useTranslation();
  // form current state
  const [state, setState] = useState('add');
  const { showSnackbar } = useContext(UtilityContext);
  // react hook form state
  const { register, handleSubmit, reset, control } = useForm({
    shouldUnregister: true,
  });

  useEffect(() => {
    if (newData) {
      setState('add');
      reset(newData);
    }
  }, [newData]);

  const onSubmit = (data) => {
    switch (state) {
      case 'add':
        axios
          .post(`${process.env.REACT_APP_API}/api/v1/suppliers`, data)
          .then(({ data }) => {
            showSnackbar({ message: data?.message });
            setReturnData(data?.supplier);
            onClose();
          })
          .catch(({ response }) => {
            showSnackbar({
              message: response?.data?.message,
              variant: 'error',
            });
          });
        break;
      default:
        console.log('wrong state');
    }
  };

  return (
    <DialogContainer
      open={open}
      state={state}
      onClose={onClose}
      title={t('vendor-form-title')}
      onCancel={onClose}
      formId='vendor-form'
    >
      <form
        id='vendor-form'
        onSubmit={(e) => formHandler(e, handleSubmit(onSubmit))}
      >
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <TextInput
              label={t('name')}
              required
              {...registerMui(register('name'))}
              autoFocus
            />
          </Grid>
          <Grid item xs={12}>
            <LabelInput
              component={RHFPhoneInput}
              label={t('phone')}
              control={control}
              name='phone'
              required
            />
          </Grid>
          <Grid item xs={12}>
            <TextInput label={t('email')} {...registerMui(register('email'))} />
          </Grid>
          <Grid item xs={12}>
            <TextInput
              label={t('website')}
              {...registerMui(register('website'))}
            />
          </Grid>
          <Grid item xs={12}>
            <TextInput
              label={t('address')}
              multiline
              rows={4}
              {...registerMui(register('address'))}
            />
          </Grid>
        </Grid>
      </form>
    </DialogContainer>
  );
};

export default VendorForm;
