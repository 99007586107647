import axios from 'axios';

const buildURL = ( path, {
  
  baseURL = process.env.REACT_APP_API, params } ) => {
  if ( !path ) return '';
  

  return `${baseURL}${axios.getUri( { url: path, params } )}`;
};

export default buildURL;
