import React from 'react';
import { Grid } from '@material-ui/core';
import TextInput from '../../../../components/text-input';
import { useTranslation } from 'react-i18next';
import { useFormContext } from 'react-hook-form';
import registerMui from '../../../../util/react-hook-form-helper/register-mui';

const PurchaseForm = () => {
  const { t } = useTranslation();
  const { register } = useFormContext();

  return (
    <Grid container className="container" spacing={3}>
      <Grid item xs={6}>
        <TextInput
          disabled
          {...registerMui(register('purchaseNumber'))}
          label={t('purchase-order-number')}
        />
      </Grid>
      <Grid item xs={6}>
        <TextInput
          {...registerMui(register('referenceNumber'))}
          label={t('reference-number')}
        />
      </Grid>
      <Grid item xs={6}>
        <TextInput
          disabled
          {...registerMui(register('vendor.name'))}
          label={t('vendor')}
        />
      </Grid>
    </Grid>
  );
};

export default PurchaseForm;
