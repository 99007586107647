import React, { useState, useCallback, useContext } from 'react';
import TitleBar from '../../components/title-bar';
import { Grid } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import SelectPopUp from '../../components/select-popup';
import config from '../../components/tableX/config';
import Status from '../../components/status';
import DateFormat from '../../components/date-format';
import NumberFormat from '../../components/number-format';
import PurchaseDetailPopup from './detail-popup';
import { useTabFilter } from '../../components/tab-filter';
import TableTabFilter from '../../components/table-with-filter';
import allowStatus from './status';
import ActionButton from '../../components/action-button';
import { UserContext } from '../../components/context-provider/user-context';

const filterStatus = ['all', 'pending', 'packed', 'canceled', 'completed'];

const PurchasePage = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const [triggerFetch, setTriggerFetch] = useState(false);
  const { checkAccess } = useContext(UserContext);
  const [purchaseDetail, setPurchaseDetail] = useState({
    open: false,
    data: {},
  });

  const handleEdit = (data, e) => {
    const location = `/dashboard/purchase-return/edit/${data._id}`;
    if (e?.ctrlKey) {
      window.open(location, '_blank');
    } else {
      history.push(location);
    }
  };

  const handleCreate = () => history.push('/dashboard/purchase-return/create');

  const handleShowDetail = (data) => {
    setPurchaseDetail({ open: true, data });
  };

  const handleCloseDetail = () => {
    setPurchaseDetail({ open: false, data: {} });
  };

  const handleReceive = (data) => {
    history.push(`/dashboard/purchase/receive/${data._id}`);
  };

  const tableSchema = [
    {
      id: '_id',
      label: t('id'),
      type: 'action',
      onClick: handleShowDetail,
    },
    {
      id: 'purchaseNumber',
      label: t('po'),
      type: 'component',
      components: (data) => {
        return data ?? t('n/a');
      },
    },
    {
      id: 'vendor',
      label: t('vendor'),
      dataConfig: ['vendor', 'name'],
    },
    {
      id: 'amount',
      label: t('amount'),
      type: 'component',
      components: [
        {
          name: NumberFormat,
          customValueProp: 'value',
        },
      ],
    },
    {
      id: 'createBy',
      label: t('created-by'),
      dataConfig: ['createdBy', 'name'],
    },
    {
      id: 'createdAt',
      label: t('created-date'),
      type: 'component',
      components: [
        {
          name: DateFormat,
          customValueProp: 'value',
        },
      ],
    },
    {
      id: 'status',
      label: t('status'),
      type: 'component',
      components: [
        {
          name: Status,
          customValueProp: 'status',
        },
      ],
    },
    {
      id: 'action',
      sort: false,
      label: t('action'),
      type: 'component',
      dataConfig: [],
      components: (data) => {
        const currentStatus = data.currentStatus.name;
        const isCreatedManual = !data.purchaseOrderId;
        const actions = [];

        if (isCreatedManual) {
          allowStatus.manual.edit.includes(currentStatus) &&
            actions.push({
              variant: 'edit',
              hidden: !checkAccess({
                feature: 'purchase-return',
                action: 'update',
              }),
              onClick: (e) => {
                handleEdit(data);
              },
              title: t('edit'),
            });
        } else {
          allowStatus.auto.edit.includes(currentStatus) &&
            actions.push({
              variant: 'edit',
              hidden: !checkAccess({
                feature: 'purchase-return',
                action: 'update',
              }),
              onClick: (e) => {
                handleEdit(data);
              },
              title: t('edit'),
            });
        }

        return actions.length > 0 ? (
          actions.map(
            (data, index) =>
              data && <ActionButton key={index} {...data}></ActionButton>
          )
        ) : (
          <>-</>
        );
      },
    },
  ];

  const { value, changeFilter, createDefaultFilter } = useTabFilter(0);

  const defaultFilter = useCallback(() => {
    return createDefaultFilter({
      value,
      options: filterStatus,
      filter: 'currentStatus.name',
    });
  }, [value]);

  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <TitleBar
          onClick={handleCreate}
          buttonText={t('create-purchase-return')}
          disableButton={
            !checkAccess({ feature: 'purchase-return', action: 'create' })
          }
        ></TitleBar>
      </Grid>
      <Grid item xs={12}>
        <TableTabFilter
          tabFilterProps={{
            options: filterStatus,
            value,
            onChange: changeFilter,
          }}
          tableXProps={{
            triggerFetch,
            onRowClick: handleShowDetail,
            defaultFilter,
            render: (data) => {
              return <SelectPopUp open={data.length} select={data} noButton />;
            },
            config: tableConfig,
            schema: tableSchema,
          }}
        />
      </Grid>
      <PurchaseDetailPopup
        state={purchaseDetail}
        onClose={handleCloseDetail}
        handleRefreshTable={() => setTriggerFetch((prev) => !prev)}
      />
    </Grid>
  );
};

const tableConfig = {
  ...config,
  url: `${process.env.REACT_APP_API}/api/v1/purchase-return/list`,
  dataConfig: ['purchaseReturn'],
};

export default PurchasePage;
