import React, { useContext } from 'react';
import { TableRow, TableCell, TableBody } from '@material-ui/core';
import { useFieldArray, useFormContext, useWatch } from 'react-hook-form';
import { UtilityContext } from '../../../../components/context-provider/utilty-context';

import NumberFormatCustom from '../../../../components/number-format';
import CustomTable from '../../../../components/custom-table';
import CustomRemoveIconButton from '../../../../components/custom-button/remove-icon-button';
import {
  RHFDiscountInput,
  RHFNumberInput,
  RHFTextInput,
} from '../../../../components/rhf-controlled-input';
import calculateDiscount from '../../../../util/calculate-discount';

const tableHeadData = [
  { value: 'no.' },
  { value: 'item-name', width: '25%' },
  { value: 'quantity' },
  { value: 'unit-price' },
  { value: 'discount' },
  { value: 'amount', align: 'right', width: '10%' },
  { width: '50px' },
];

const Products = ({ isReferenceToOrder }) => {
  // form context
  const { control } = useFormContext();
  // utility context
  const { showDeletePrompt, closeDeletePrompt } = useContext(UtilityContext);

  // filedArray product
  const { fields, append, remove } = useFieldArray({
    control,
    name: 'items',
  });

  const items = useWatch({ name: 'items' });

  const controlledFields = fields.map((field, index) => {
    return {
      ...field,
      ...items?.[index],
    };
  });

  // add row item
  const handleAddField = (index) => {
    if (isReferenceToOrder) return;
    if (index === fields.length - 1) {
      append({ qty: '' }, { shouldFocus: false });
    }
  };

  // remove row item prompt
  const handleRemove = (index, id) => {
    showDeletePrompt({
      handleConfirm: () => onDelete(index, id),
    });
  };

  // on remove confirm
  const onDelete = (index) => {
    remove(index);
    closeDeletePrompt();
  };

  return (
    <CustomTable.Container>
      {/* table header */}
      <CustomTable.Head data={tableHeadData} />
      {/* table body */}
      <TableBody>
        {controlledFields.map((field, index) => {
          const totalAmount = (field.qty || 1) * (field.costPrice || 0);
          const totalAmountAfterDiscount = calculateDiscount(
            totalAmount,
            field.discount
          );
          return (
            <TableRow key={field.id}>
              <TableCell>
                <CustomTable.CellValue>{index + 1}</CustomTable.CellValue>
              </TableCell>
              <TableCell>
                <RHFTextInput
                  name={`items.${index}.name`}
                  defaultValue={field.name}
                  onChange={(e, onChange) => {
                    onChange(e.target.value);
                    handleAddField(index);
                  }}
                  required={controlledFields.length === 1}
                ></RHFTextInput>
              </TableCell>
              <TableCell>
                <RHFNumberInput
                  name={`items.${index}.qty`}
                  defaultValue={field.qty}
                  textAlign='right'
                  decimalValue={false}
                  placeholder='1'
                  min={1}
                />
              </TableCell>
              <TableCell>
                <RHFNumberInput
                  name={`items.${index}.costPrice`}
                  textAlign='right'
                  defaultValue={field.costPrice}
                  placeholder='0'
                  min={0}
                />
              </TableCell>
              <TableCell>
                <RHFDiscountInput
                  name={`items.${index}.discount`}
                  defaultValue={field.discount}
                />
              </TableCell>
              <TableCell style={{ textAlign: 'right' }}>
                <CustomTable.CellValue align='right'>
                  {<NumberFormatCustom value={totalAmountAfterDiscount} />}
                </CustomTable.CellValue>
              </TableCell>
              <TableCell>
                <CustomTable.CellValue component='div'>
                  <CustomRemoveIconButton
                    invisible={
                      index === fields.length - 1 || fields.length <= 1
                    }
                    onClick={() => handleRemove(index, field._id)}
                  />
                </CustomTable.CellValue>
              </TableCell>
            </TableRow>
          );
        })}
      </TableBody>
    </CustomTable.Container>
  );
};

export default Products;
