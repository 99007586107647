import React from 'react';
import { Grid } from '@material-ui/core';
import { useForm, FormProvider } from 'react-hook-form';
import Products from './products';

import { useTranslation } from 'react-i18next';
import DialogContainer from '../../../../components/dialog-container';
import { formHandler } from '../../../../util/react-hook-form-helper';
import useSubmitButton, { HiddenSubmitButton } from 'src/hooks/useSubmitButton';

const ReturnedForm = ({ open, onClose, items, onSubmit }) => {
  const { t } = useTranslation();
  const { submitButtonRef, submit } = useSubmitButton();

  const methods = useForm({
    defaultValues: {
      product: [],
    },
  });

  const handleCreate = () => {
    submit();
  };

  const onEntering = () => {
    methods.reset({
      product: items
        .filter((item) => item.packed < item.qty)
        .map((item) => ({ ...item, qty: item.qty - item.packed })),
    });
  };

  return (
    <DialogContainer
      open={open}
      title={t('packing-order')}
      onCancel={onClose}
      maxWidth='md'
      TransitionProps={{ onEntering }}
      onAdd={handleCreate}
      state='custom'
      noPadding={true}
      customButtonText={t('confirm')}
    >
      <FormProvider {...methods}>
        <form onSubmit={(e) => formHandler(e, methods.handleSubmit(onSubmit))}>
          <Grid container>
            <Grid item xs={12}>
              <Products />
            </Grid>
          </Grid>
          <HiddenSubmitButton ref={submitButtonRef} />
        </form>
      </FormProvider>
    </DialogContainer>
  );
};

export default ReturnedForm;
