import { useEffect, useRef } from 'react';
import { RecaptchaVerifier, getAuth } from 'firebase/auth';

const useRecaptchaVerifier = ({ onSolved, onExpired, recaptchaId } = {}) => {
  const recaptchaRef = useRef(null);

  useEffect(() => {
    if (recaptchaId) {
      try {
        const auth = getAuth();
        recaptchaRef.current = new RecaptchaVerifier(
          recaptchaId,
          {
            size: 'invisible',
            callback: (response) => {
              onSolved && onSolved(response);
            },
            'expired-callback': () => {
              onExpired && onExpired();
            },
          },
          auth
        );
      } catch (error) {
        console.error(error);
      }
    } else {
      console.error('no recaptchaId');
    }
  }, []);

  const resetRecaptcha = () => {
    recaptchaRef.current.render().then(function (widgetId) {
      window.grecaptcha.reset(widgetId);
    });
  };

  return { recaptcha: recaptchaRef, resetRecaptcha };
};
export default useRecaptchaVerifier;
