import { Button } from '@material-ui/core';
import React from 'react';

const PrimaryButton = (props) => {
  return (
    <Button variant='contained' color='primary' disableElevation {...props}>
      {props.children}
    </Button>
  );
};

export default PrimaryButton;
