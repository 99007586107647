import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  MenuItem,
} from '@material-ui/core';
import axios from 'axios';
import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import LabelContainer from 'src/components/LabelContainer';
import useMessage from 'src/hooks/useMessage';
import useLoading from 'src/hooks/useLoading';
import { RHFNumberInput, RHFTextInput } from '../rhf-controlled-input';
import RHFSelect from '../rhf-controlled-input/select';

const ShippingExpenseDialog = ({
  open,
  onClose,
  orderId,
  defaultFormValue,
  newStatus,
  onSuccess,
  disableSkip,
}) => {
  const { t } = useTranslation();
  const { control, handleSubmit, reset } = useForm();
  const [paymentAccounts, setPaymentAccounts] = useState([]);
  const { loading, startLoading, stopLoading } = useLoading();
  const [clickButton, setClickButton] = useState(null);
  const { showErrorResponseMessage, showSuccessResponseMessage } = useMessage();

  const onEntering = () => {
    if (defaultFormValue) {
      reset(defaultFormValue);
    }
    getPaymentAccounts();
  };

  const getPaymentAccounts = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API}/api/v1/payment-accounts`
      );

      const paymentAccounts = response.data.paymentAccounts;

      setPaymentAccounts({
        data: paymentAccounts,
      });
    } catch (error) {
      console.log(error);
    }
  };

  const handleSkip = async () => {
    try {
      setClickButton('skip');
      startLoading();
      const response = await axios.put(
        `${process.env.REACT_APP_API}/api/v1/orders/status/${orderId}`,
        { status: 'shipping' }
      );
      onSuccess && onSuccess(response.data.order);
      onClose();
      showSuccessResponseMessage(response);
    } catch (error) {
      console.log(error);
      showErrorResponseMessage(error);
    } finally {
      stopLoading();
    }
  };

  const onSubmit = async (formData) => {
    try {
      setClickButton('confirm');
      startLoading();
      const shippingExpense = {
        amount: formData.amount,
        paidDate: new Date(),
        paidBy: JSON.parse(formData.paymentAccount),
        note: formData.note,
      };

      const response = await axios.post(
        `${process.env.REACT_APP_API}/api/v1/orders/${orderId}/shipping-expense`,
        shippingExpense,
        {
          params: {
            status: newStatus,
          },
        }
      );
      onSuccess && onSuccess(response.data.order);

      onClose();
      showSuccessResponseMessage(response);
    } catch (error) {
      console.log(error);
      showErrorResponseMessage(error);
    } finally {
      stopLoading();
    }
  };

  const onExited = () => {
    reset({});
  };

  return (
    <Dialog
      TransitionProps={{ onEntering, onExited }}
      open={open}
      maxWidth='xs'
      fullWidth
      onClose={!loading ? onClose : () => {}}
    >
      <form onSubmit={handleSubmit(onSubmit)}>
        <DialogTitle>{t('add-shipping-expense')}</DialogTitle>
        <DialogContent>
          <Grid container direction='column' spacing={2}>
            <Grid item>
              <LabelContainer required text={t('payment-account')}>
                <RHFSelect
                  fullWidth
                  variant='standard'
                  required
                  control={control}
                  name='paymentAccount'
                >
                  {paymentAccounts?.data?.map((paymentAccount, index) => {
                    const { _id, code, name } = paymentAccount;
                    const value = { id: _id, code, name };
                    return (
                      <MenuItem value={JSON.stringify(value)} key={index}>
                        {name}
                      </MenuItem>
                    );
                  })}
                </RHFSelect>
              </LabelContainer>
            </Grid>
            <Grid item>
              <LabelContainer required text={t('amount')}>
                <RHFNumberInput
                  required
                  variant='standard'
                  control={control}
                  name='amount'
                />
              </LabelContainer>
            </Grid>
            <Grid item>
              <LabelContainer text={t('note')}>
                <RHFTextInput
                  multiline
                  rows={2}
                  variant='standard'
                  control={control}
                  name='note'
                />
              </LabelContainer>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Grid container justify='space-between'>
            <Grid item>
              {!disableSkip && (
                <Button
                  disabled={loading}
                  color='primary'
                  endIcon={
                    loading &&
                    clickButton === 'skip' && <CircularProgress size={18} />
                  }
                  onClick={handleSkip}
                >
                  {t('skip')}
                </Button>
              )}
            </Grid>
            <Grid item>
              <Grid container spacing={1}>
                <Grid item>
                  <Button disabled={loading} onClick={onClose}>
                    {t('cancel')}
                  </Button>
                </Grid>
                <Grid item>
                  <Button
                    type='submit'
                    variant='contained'
                    color='primary'
                    disabled={loading}
                    endIcon={
                      loading &&
                      clickButton === 'confirm' && (
                        <CircularProgress size={18} />
                      )
                    }
                  >
                    {t('confirm')}
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </DialogActions>
      </form>
    </Dialog>
  );
};
export default ShippingExpenseDialog;
