import { useContext } from 'react';
import { Route, Redirect } from 'react-router-dom';
import { UserContext } from '../context-provider/user-context';

const ACRoute = ({ feature, action, disabled, redirect, ...rest }) => {
  const { checkAccess, loading, setting, me } = useContext(UserContext);

  if (loading) {
    return null;
  }

  if (feature && action) {
    const isDisable =
      (disabled && disabled({ setting, feature: me?.warehouse?.feature })) ??
      false;

    if (!isDisable && checkAccess({ feature, action })) {
      return <Route {...rest} />;
    }

    return <Redirect to='/dashboard' />;
  }
  return <Route {...rest} />;
};

export default ACRoute;
