import { getAuth, signInWithPhoneNumber } from 'firebase/auth';
import React, { useState } from 'react';
import useRecaptchaVerifier from './useRecaptchaVerifier';

const useFirebasePhoneVerifier = ({
  recaptchaId = 'recaptcha',
  onSolved,
  onExpired,
}) => {
  const { recaptcha, resetRecaptcha } = useRecaptchaVerifier({
    recaptchaId,
    onSolved,
    onExpired,
  });

  const [state, setState] = useState({
    loading: false,
    success: false,
    verified: null,
  });

  const confirmationResultRef = React.useRef(null);

  const requestPhoneVerificationCode = async (phoneNumber) => {
    if (!recaptcha.current) throw Error('no recaptcha');
    setState({ success: false, verified: null, loading: true });
    const auth = getAuth();
    const appVerifier = recaptcha.current;

    const parsePhoneNumber = phoneNumber?.includes('+')
      ? phoneNumber
      : `+${phoneNumber}`;

    const confirmationResult = await signInWithPhoneNumber(
      auth,
      parsePhoneNumber,
      appVerifier
    );
    setState((prev) => ({ ...prev, loading: false }));
    confirmationResultRef.current = confirmationResult;
  };

  const confirmPhoneVerificationCode = async (verficationCode) => {
    if (confirmationResultRef.current === null || !verficationCode)
      throw Error('confirmationResult failed');

    setState({ success: false, verified: null, loading: true });

    const result = await confirmationResultRef.current?.confirm(
      verficationCode
    );

    if (!result) {
      setState((prev) => ({ ...prev, loading: false }));
      throw Error('no-result');
    }

    setState({ loading: false, verified: result, success: true });

    return {
      ...result,
      idToken: result?.['_tokenResponse'].idToken,
      phoneNumber: result?.user?.phoneNumber,
    };
  };

  return {
    requestPhoneVerificationCode,
    confirmPhoneVerificationCode,
    loading: state.loading,
    success: state.success,
    verified: state.verified,
    resetRecaptcha,
  };
};
export default useFirebasePhoneVerifier;
