import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Box,
  makeStyles,
} from '@material-ui/core';
import React from 'react';
import ImageUpload from './image-upload';

const useStyles = makeStyles(() => ({
  root: {
    // padding: 0,
    height: '350px',
  },
  paper: {
    '& .MuiDialogContent-root:first-child': {
      padding: '10px 10px 0 10px',
    },
  },
}));

const ImageUploadPopup = ({
  open,
  images,
  setImages,
  handleClose,
  setRemoveImage,
  variantName,
}) => {
  const classes = useStyles();
  return (
    <Dialog
      open={open}
      classes={{ paper: classes.paper }}
      maxWidth='sm'
      fullWidth
    >
      <DialogTitle>{variantName}</DialogTitle>
      <DialogContent classes={{ root: classes.root }}>
        <ImageUpload
          images={images}
          setRemoveImage={setRemoveImage}
          setImages={setImages}
        />
      </DialogContent>
      <DialogActions>
        <Button
          color='primary'
          className='button'
          disableElevation
          variant='contained'
          onClick={handleClose}
        >
          Finish
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ImageUploadPopup;
