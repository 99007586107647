import { Grid } from '@material-ui/core';
import React, { useRef, useState, useEffect, useContext } from 'react';
import DialogContainer from '../../../components/dialog-container';
import TextInput from '../../../components/text-input';
import { useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';
import axios from 'axios';
import { UtilityContext } from '../../../components/context-provider/utilty-context';
import registerMui from '../../../util/react-hook-form-helper/register-mui';

const CurrencyForm = ({ open, onClose, preData, setTriggerFetch }) => {
  const { t } = useTranslation();
  const { showSnackbar } = useContext(UtilityContext);
  const [state, setState] = useState('add');
  // react hook form state
  const { register, handleSubmit, reset } = useForm({ shouldUnregister: true });

  useEffect(() => {
    if (open) {
      if (preData) {
        setState('update');
        reset(preData);
      } else {
        setState('add');
        reset({});
      }
    }
  }, [preData, open]);

  const onSubmit = (data) => {
    switch (state) {
      case 'add':
        axios
          .post(`${process.env.REACT_APP_API}/api/v1/currencies`, data)
          .then(({ data }) => {
            showSnackbar({ message: data?.message });
            setTriggerFetch((prev) => !prev);
            onClose();
          })
          .catch(({ response }) => {
            showSnackbar({
              message: response?.data?.message,
              variant: 'error',
            });
          });
        break;
      case 'update':
        axios
          .put(
            `${process.env.REACT_APP_API}/api/v1/currencies/${preData._id}`,
            data
          )
          .then(({ data }) => {
            showSnackbar({ message: data?.message });
            setTriggerFetch((prev) => !prev);
            onClose();
          })
          .catch(({ response }) => {
            showSnackbar({
              message: response?.data?.message,
              variant: 'error',
            });
          });
        break;
      default:
        console.log('wrong state');
    }
  };

  return (
    <DialogContainer
      open={open}
      onClose={onClose}
      title={t('currency-form-title')}
      onCancel={onClose}
      state={state}
      formId='currency-form'
    >
      <form id='currency-form' onSubmit={handleSubmit(onSubmit)}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <TextInput
              label={t('currency-name')}
              required
              {...registerMui(register('currencyName'))}
            />
          </Grid>
          <Grid item xs={12}>
            <TextInput
              label={t('currency-symbol')}
              required
              {...registerMui(register('currencySymbol'))}
            />
          </Grid>
          <Grid item xs={12}>
            <TextInput
              label={t('price-per-dollar')}
              type='number'
              required
              {...registerMui(
                register('pricePerDollar', { valueAsNumber: true })
              )}
            />
          </Grid>
        </Grid>
      </form>
    </DialogContainer>
  );
};

export default CurrencyForm;
