import { Grid } from '@material-ui/core';
import React, { useState, useContext, useEffect } from 'react';
import { Controller, useFormContext, useForm } from 'react-hook-form';
import DialogContainer from '../../../../../components/dialog-container';
import { useTranslation } from 'react-i18next';
import CustomerListSearch from './cutomer-list-search';
import TextInput from '../../../../../components/text-input';
import {
  formHandler,
  registerMui,
} from '../../../../../util/react-hook-form-helper';
import axios from 'axios';
import { UtilityContext } from '../../../../../components/context-provider/utilty-context';
import InputGoogleMapSuggestion from '../../../../customer/create-edit/customer-info/address-input/input-google-map-search';
import LabelInput from '../../../../../components/label-input';
import { RHFPhoneInput } from '../../../../../components/rhf-controlled-input';

const SelectorPopup = ({
  value,
  onClose,
  open,
  // default form state
  defaultState = 'search',
  defaultValue,
  goBackToList,
}) => {
  const { t } = useTranslation();
  const { control, setValue, watch } = useFormContext();
  const watchCustomer = watch('customer');
  const { showSnackbar } = useContext(UtilityContext);
  const { register, handleSubmit, reset, control: customerControl } = useForm();

  // form state available ["search","create","edit"]
  const [formState, setFormState] = useState({ state: 'search', data: null });

  // useEffect create new customer using search text
  useEffect(() => {
    // reset to input field
    reset({});
    if (formState.data) {
      const inputValue = formState.data.trim();
      if (inputValue.includes('@')) {
        reset({ email: inputValue });
      } else if (/^[0-9]*$/.test(inputValue)) {
        reset({ phone: inputValue });
      } else {
        reset({ firstname: inputValue });
      }
    }
  }, [formState.data]);

  // useEffect on open with condition to prefill form create or edit customer with existing value
  useEffect(() => {
    if (open) {
      switch (defaultState) {
        case 'create':
          if (defaultValue) {
            setFormState({ state: 'create', data: null });
            const { name, phone, comment } = defaultValue;
            reset({ firstname: name, phone, location: { address: comment } });
          }
          break;
        case 'edit': {
          setFormState({ state: 'edit', data: null });
          const { firstname, lastname, phone, location } = watchCustomer;
          reset({ firstname, lastname, phone, location });
          break;
        }
        default:
          setFormState({ state: 'search', data: null });
      }
    }
  }, [open]);

  const options = {
    create: {
      hideConfirmButton: false,
      title: t('customer-information'),
      onClose: () => setFormState({ state: 'search', data: null }),
      button: t('create'),
    },
    edit: {
      hideConfirmButton: false,
      title: t('customer-information'),
      onClose: () => setFormState({ state: 'search', data: null }),
      button: t('edit'),
    },
    search: {
      hideConfirmButton: true,
      title: t('select-customer'),
      onClose: goBackToList,
    },
  };

  const onSubmit = (data) => {
    switch (formState.state) {
      case 'create': {
        const createData = {
          phone: data.phone,
          firstname: data.firstname,
          lastname: data.lastname,
          shippingInfo: [
            {
              receiver: `${data.firstname} ${data.lastname}`.trim(),
              phone: data.phone,
              location: data.location,
              isPrimary: true,
            },
          ],
        };
        axios
          .post(`${process.env.REACT_APP_API}/api/v1/customers`, createData)
          .then(({ data }) => {
            showSnackbar({ message: data?.message });
            setValue('customer', data.customer);
            setValue(
              'shippingInfo',
              data.customer?.shippingInfo?.[data?.customer?.primaryShipping]
            );
            setValue('note', defaultValue?.comment);
            onClose();
          })
          .catch(({ response }) => {
            showSnackbar({ message: response.data?.message, variant: 'error' });
          });
        break;
      }
      case 'edit':
        axios
          .put(
            `${process.env.REACT_APP_API}/api/v1/customers/${watchCustomer._id}`,
            data
          )
          .then(({ data }) => {
            showSnackbar({ message: data?.message });
            setValue('customer', data.customer);
            setValue('shippingInfo', data.customer?.shippingInfo[0]);
            onClose();
          })
          .catch(({ response }) => {
            showSnackbar({ message: response.data?.message, variant: 'error' });
          });
        break;
      default:
        console.log('wrong state');
    }
  };

  return (
    <DialogContainer
      open={open}
      state='custom'
      onCancel={value ? onClose : options[formState.state].onClose}
      formId='customer-selector-form'
      hideConfirmButton={options[formState.state].hideConfirmButton}
      title={options[formState.state].title}
      customButtonText={options[formState.state].button}
    >
      <form
        id='customer-selector-form'
        onSubmit={(e) => formHandler(e, handleSubmit(onSubmit))}
      >
        <Grid container justify='center' spacing={2}>
          {formState.state === 'search' ? (
            <Grid item xs={12}>
              <Controller
                control={control}
                name='customer'
                defaultValue={null}
                render={({ field: { value, onChange } }) => {
                  return (
                    <CustomerListSearch
                      value={value}
                      onChange={(e, data) => {
                        if (data.inputValue) {
                          setFormState({
                            state: 'create',
                            data: data.inputValue,
                          });
                        } else {
                          onChange(data);
                          setValue(
                            'shippingInfo',
                            data?.shippingInfo?.[data?.primaryShipping]
                          );
                          onClose();
                        }
                      }}
                      placeholder={t('email-or-phone')}
                      dataConfig={['customers']}
                      optionsURL={`${process.env.REACT_APP_API}/api/v1/customers`}
                      label={t('customer')}
                      getOptionConfig={['fullname']}
                      getOptionSelected={(option, value) => {
                        return (
                          option.name === value.name || value._id || value.id
                        );
                      }}
                    />
                  );
                }}
              ></Controller>
            </Grid>
          ) : (
            <>
              <Grid item xs={12}>
                <LabelInput
                  component={RHFPhoneInput}
                  autoFocus
                  label={t('phone')}
                  control={customerControl}
                  name='phone'
                  required
                />
              </Grid>
              <Grid item xs={6}>
                <TextInput
                  {...registerMui(register('firstname'))}
                  required
                  label={t('firstname')}
                ></TextInput>
              </Grid>
              <Grid item xs={6}>
                <TextInput
                  {...registerMui(register('lastname'))}
                  label={t('lastname')}
                ></TextInput>
              </Grid>

              <Grid item xs={12}>
                <TextInput
                  required
                  multiline
                  {...registerMui(register('location.address'))}
                  rows={4}
                  label={t('address')}
                ></TextInput>
              </Grid>
              <Grid item xs={12}>
                <Controller
                  control={customerControl}
                  name='location.nearby'
                  defaultValue={null}
                  render={({ field: { value, onChange } }) => {
                    return (
                      <InputGoogleMapSuggestion
                        value={value}
                        onChange={onChange}
                        label={t('nearby')}
                      />
                    );
                  }}
                ></Controller>
              </Grid>
              <Grid item xs={12}>
                <TextInput
                  {...registerMui(register('location.url'))}
                  label={t('google-map-url')}
                ></TextInput>
              </Grid>
            </>
          )}
        </Grid>
      </form>
    </DialogContainer>
  );
};

export default SelectorPopup;
