import BrandPage from '../pages/brand';
import CategoryPage from '../pages/category';
import CurrencyPage from '../pages/currency';
import CustomerPage from '../pages/customer';
import AddEditCustomerPage from '../pages/customer/create-edit';
import DashboardPage from '../pages/dashboard';
import DeliverySetting from '../pages/delivery';
import StoragePage from '../pages/storage';
import UserPage from '../pages/user';
import AddEditUserPage from '../pages/user/create-edit';
import PaymentAccountPage from '../pages/payment-account';
import ProductPage from '../pages/product';
import CreateEditProductPage from '../pages/product/create-edit';
import PurchasePage from '../pages/purchase';
import PurchaseInPage from '../pages/purchase-in';
import PurchaseInAssignPage from '../pages/purchase-in/assign-storage';
import PaymentReport from '../pages/report/payment-report';
import SaleReport from '../pages/report/sale-report';
import SaleOrderPage from '../pages/sale-order';
import CreateEditSaleOrder from '../pages/sale-order/create-edit';
import SettingPage from '../pages/setting';
import StockPage from '../pages/stock';
import UnitPage from '../pages/unit';
import VendorPage from '../pages/vendor';
import CreateEditVendorPage from '../pages/vendor/create-edit';
import PurchaseReceivePage from '../pages/purchase/receive';
import PurchaseReturnPage from '../pages/purchase-return';
import PurchaseOrderFormPage from '../pages/purchase/form';
import RepackPage from '../pages/repack';
import SaleReturnPage from '../pages/sale-return';
import PurchaseReturnForm from '../pages/purchase-return/form';
import PurchaseInEditPage from '../pages/purchase-in/form';
import RoleAndPermission from '../pages/role';
import AddEditRolePermissionPage from '../pages/role/create-edit';
import SaleByUser from 'src/pages/report/sale-by-user';
import SaleTargetPage from 'src/pages/sale-target';
import POSPage from 'src/pages/pos';
import SalePosPage from 'src/pages/sale-pos';
import StockAddMorePage from 'src/pages/stock/adjust';
import CompositePage from 'src/pages/composite';
import CreateEditCompositePage from 'src/pages/composite/create-edit';
import CustomerSaleReport from 'src/pages/report/sale-by-customer-report';
import BillingPage from 'src/pages/billing';
import BillingFormPage from 'src/pages/billing/form';
import SaleByProductReport from 'src/pages/report/sale-by-product';
import ProfitAndLossReport from 'src/pages/report/profit-and-loss';
import DiscountPage from 'src/pages/discount';
import DiscountFormPage from 'src/pages/discount/form';
import ProfilePage from 'src/pages/profile';

const isPurchaseOrderDisable = ({ setting }) => {
  return setting?.purchaseOrder?.purchase === false;
};

const routes = [
  {
    exact: true,
    path: '/dashboard/product',
    component: ProductPage,
    feature: 'products',
    action: 'view',
  },
  {
    exact: true,
    path: '/dashboard/product/create',
    component: CreateEditProductPage,
    feature: 'products',
    action: 'create',
  },
  {
    exact: true,
    path: '/dashboard/product/edit/:id',
    component: CreateEditProductPage,
    feature: 'products',
    action: 'update',
  },
  {
    exact: true,
    path: '/dashboard/composite',
    component: CompositePage,
    feature: 'composite',
    action: 'view',
  },
  {
    exact: true,
    path: '/dashboard/composite/edit/:id',
    component: CreateEditCompositePage,
    feature: 'composite',
    action: 'view',
  },
  {
    exact: true,
    path: '/dashboard/composite/create',
    component: CreateEditCompositePage,
    feature: 'products',
    action: 'create',
  },
  {
    exact: true,
    path: '/dashboard/purchase',
    component: PurchasePage,
    feature: 'purchase-order',
    action: 'view',
    disabled: isPurchaseOrderDisable,
  },
  {
    exact: true,
    path: '/dashboard/billing',
    component: BillingPage,
    feature: 'billing',
    action: 'view',
  },
  {
    exact: true,
    path: '/dashboard/billing/create',
    component: BillingFormPage,
    feature: 'billing',
    action: 'create',
  },
  {
    exact: true,
    path: '/dashboard/billing/edit/:id',
    component: BillingFormPage,
    feature: 'billing',
    action: 'update',
  },
  {
    exact: true,
    path: '/dashboard/purchase/create',
    component: PurchaseOrderFormPage,
    feature: 'purchase-order',
    action: 'create',
    disabled: isPurchaseOrderDisable,
  },
  {
    exact: true,
    path: '/dashboard/purchase/edit/:id',
    component: PurchaseOrderFormPage,
    feature: 'purchase-order',
    action: 'update',
    disabled: isPurchaseOrderDisable,
  },
  {
    exact: true,
    path: '/dashboard/purchase/receive/:id',
    component: PurchaseReceivePage,
    feature: 'purchase-order',
    action: 'update',
    disabled: isPurchaseOrderDisable,
  },
  {
    exact: true,
    path: '/dashboard/purchase-return',
    component: PurchaseReturnPage,
    feature: 'purchase-return',
    action: 'view',
    disabled: isPurchaseOrderDisable,
  },
  {
    exact: true,
    path: '/dashboard/purchase-return/create',
    component: PurchaseReturnForm,
    feature: 'purchase-return',
    action: 'create',
    disabled: isPurchaseOrderDisable,
  },
  {
    exact: true,
    path: '/dashboard/purchase-return/edit/:id',
    component: PurchaseReturnForm,
    feature: 'purchase-return',
    action: 'update',
    disabled: isPurchaseOrderDisable,
  },
  {
    exact: true,
    path: '/dashboard/purchase-in',
    component: PurchaseInPage,
    feature: 'purchase-in',
    action: 'view',
    disabled: isPurchaseOrderDisable,
  },
  {
    exact: true,
    path: '/dashboard/purchase-in/confirm/:id',
    component: PurchaseInAssignPage,
    feature: 'purchase-in',
    action: 'update',
    disabled: isPurchaseOrderDisable,
  },
  {
    exact: true,
    path: '/dashboard/purchase-in/edit/:id',
    component: PurchaseInEditPage,
    feature: 'purchase-in',
    action: 'update',
    disabled: isPurchaseOrderDisable,
  },
  {
    exact: true,
    path: '/dashboard/vendor',
    component: VendorPage,
    feature: 'supplier',
    action: 'view',
  },
  {
    exact: true,
    path: '/dashboard/vendor/create',
    component: CreateEditVendorPage,
    feature: 'supplier',
    action: 'create',
  },
  {
    exact: true,
    path: '/dashboard/vendor/edit/:id',
    component: CreateEditVendorPage,
    feature: 'supplier',
    action: 'update',
  },
  {
    exact: true,
    path: '/dashboard/unit',
    component: UnitPage,
    feature: 'unit',
    action: 'view',
  },
  {
    exact: true,
    path: '/dashboard/storage',
    component: StoragePage,
    feature: 'storage',
    action: 'view',
  },
  {
    exact: true,
    path: '/dashboard/user',
    component: UserPage,
    feature: 'user',
    action: 'view',
  },
  {
    exact: true,
    path: '/dashboard/user/create',
    component: AddEditUserPage,
    feature: 'user',
    action: 'create',
  },
  {
    exact: true,
    path: '/dashboard/user/edit/:id',
    component: AddEditUserPage,
    feature: 'user',
    action: 'update',
  },
  {
    exact: true,
    path: '/dashboard/role-and-permission',
    component: RoleAndPermission,
    feature: 'role',
    action: 'view',
  },
  {
    exact: true,
    path: '/dashboard/role-and-permission/create',
    component: AddEditRolePermissionPage,
    feature: 'role',
    action: 'create',
  },
  {
    exact: true,
    path: '/dashboard/role-and-permission/edit/:id',
    component: AddEditRolePermissionPage,
    feature: 'role',
    action: 'update',
  },
  {
    exact: true,
    path: '/dashboard/payment-account',
    component: PaymentAccountPage,
    feature: 'payment-account',
    action: 'view',
  },
  {
    exact: true,
    path: '/dashboard/sale-target',
    component: SaleTargetPage,
    feature: 'sale-target',
    action: 'view',
  },
  {
    exact: true,
    path: '/dashboard/pos',
    component: POSPage,
    feature: 'pos',
    action: 'view',
    disabled: ({ feature }) => {
      return feature?.pos === false;
    },
  },
  {
    exact: true,
    path: '/dashboard/brand',
    component: BrandPage,
    feature: 'brand',
    action: 'view',
  },
  {
    exact: true,
    path: '/dashboard/currency',
    component: CurrencyPage,
    feature: 'exchange-rate',
    action: 'view',
  },
  {
    exact: true,
    path: '/dashboard/category',
    component: CategoryPage,
    feature: 'category',
    action: 'view',
  },
  {
    exact: true,
    path: '/dashboard/customer',
    component: CustomerPage,
    feature: 'customer',
    action: 'view',
  },
  {
    exact: true,
    path: '/dashboard/setting',
    component: SettingPage,
    feature: 'login',
    action: 'dashboard',
  },
  {
    exact: true,
    path: '/dashboard/setting/profile',
    component: ProfilePage,
  },
  {
    exact: true,
    path: '/dashboard/delivery-setting',
    component: DeliverySetting,
    feature: 'login',
    action: 'dashboard',
  },
  {
    exact: true,
    path: '/dashboard/customer/create',
    component: AddEditCustomerPage,
    feature: 'customer',
    action: 'create',
  },
  {
    exact: true,
    path: '/dashboard/customer/edit/:id',
    component: AddEditCustomerPage,
    feature: 'customer',
    action: 'update',
  },
  {
    exact: true,
    path: '/dashboard/sale-invoice',
    component: SaleOrderPage,
    feature: 'orders',
    action: 'view',
  },
  {
    exact: true,
    path: '/dashboard/sale-invoice/create',
    component: CreateEditSaleOrder,
    feature: 'orders',
    action: 'create',
  },
  {
    exact: true,
    path: '/dashboard/sale-invoice/edit/:id',
    component: CreateEditSaleOrder,
    feature: 'orders',
    action: 'update',
  },
  {
    exact: true,
    path: '/dashboard/sale-invoice/repack',
    component: RepackPage,
    feature: 'orders',
    action: 'repack',
    disabled: ({ setting }) => {
      return setting?.order?.repack === false;
    },
  },
  {
    exact: true,
    path: '/dashboard/sale-invoice/sale-return',
    component: SaleReturnPage,
    feature: 'orders',
    action: 'returned',
    disabled: ({ setting }) => {
      return setting?.order?.saleReturn === false;
    },
  },
  {
    exact: true,
    path: '/dashboard/sale-pos',
    component: SalePosPage,
    feature: 'orders',
    action: 'view',
  },
  {
    exact: true,
    path: '/dashboard/stock',
    component: StockPage,
    feature: 'stock',
    action: 'view',
  },
  {
    exact: true,
    path: '/dashboard/stock/adjust',
    component: StockAddMorePage,
    feature: 'stock',
    action: 'update',
  },
  {
    exact: true,
    path: '/dashboard/report/payment',
    component: PaymentReport,
    feature: 'report',
    action: 'view',
  },
  {
    exact: true,
    path: '/dashboard/report/sale',
    component: SaleReport,
    feature: 'report',
    action: 'view',
  },
  {
    exact: true,
    path: '/dashboard/report/sale-by-product',
    component: SaleByProductReport,
    feature: 'report',
    action: 'view',
  },
  {
    exact: true,
    path: '/dashboard/report/sale-by-customer',
    component: CustomerSaleReport,
    feature: 'report',
    action: 'view',
  },
  {
    exact: true,
    path: '/dashboard/report/sale-by-user',
    component: SaleByUser,
    feature: 'report',
    action: 'view',
  },
  {
    exact: true,
    path: '/dashboard/report/profit-and-loss',
    component: ProfitAndLossReport,
    feature: 'report',
    action: 'view',
  },
  {
    exact: true,
    path: '/dashboard/discount',
    component: DiscountPage,
    feature: 'discount',
    action: 'view',
  },
  {
    exact: true,
    path: '/dashboard/discount/create',
    component: DiscountFormPage,
    feature: 'discount',
    action: 'create',
  },
  {
    exact: true,
    path: '/dashboard/discount/edit/:id',
    component: DiscountFormPage,
    feature: 'discount',
    action: 'update',
  },
  {
    exact: true,
    path: '/dashboard',
    component: DashboardPage,
    feature: 'login',
    action: 'dashboard',
  },
  {
    path: '/dashboard/*',
    redirect: '/dashboard',
    feature: 'login',
    action: 'dashboard',
  },
];

export default routes;
