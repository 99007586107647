const combineObjectKeyValue = (object, glue = '::', separator = ',') => {
  if (object && Object.keys(object)?.length > 0) {
    return Object.keys(object)
      .map((key) => [key, object[key]].join(glue))
      .join(separator);
  }
  return '';
};

export default combineObjectKeyValue;
