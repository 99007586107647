import { TextField } from '@material-ui/core';
import { Autocomplete, createFilterOptions } from '@material-ui/lab';
import { useRef } from 'react';
import useDebounceCallback from 'src/hooks/useDebounceCallback';
import useQuery from 'src/hooks/useQuery';

const filter = createFilterOptions();

const AutoCompleteAsync = ({
  getOptions,
  optionsURL,
  customQuery,
  getOptionLabel,
  loadingText = 'Loading...',
  onCreate,
  onChange,
  renderOption,
  textFieldProps,
  placeholder,
  required,
  clearOnSelect = false,
  filterQuery = {},
  ...props
}) => {
  const { data, loading, query, startLoading } = useQuery(optionsURL);

  const search = (value) => {
    query(
      customQuery
        ? customQuery(value)
        : { search: value, page: 1, limit: 5, ...filterQuery }
    );
    startTypeRef.current = false; // reset startType
  };

  const debounceSearch = useDebounceCallback(search, 300);

  // track first time open and refetch if needed
  const openFirstTime = useRef(false);
  const handleOpen = () => {
    if (getOptions(data)?.length !== 0 && openFirstTime.current) return;
    search('');
    openFirstTime.current = true;
  };

  // track start typing to clear old data and start loading state
  // resolve multiple rerender
  const startTypeRef = useRef(false);
  const startType = () => {
    if (startTypeRef.current) return;
    startLoading();
    startTypeRef.current = true;
  };

  // trigger search only when input to resolve
  // unnecessary fetch during reset with hook form
  const handleInputChange = (e, text, reason) => {
    switch (reason) {
      case 'clear':
        search();
        return;
      case 'input':
        startType();
        debounceSearch(e.target.value);
        return;
      default:
        return;
    }
  };

  const handleFilterOptions = (options, params) => {
    const filtered = filter(options, params);
    // Suggest the creation of a new value
    if (params.inputValue !== '') {
      if (loading || onCreate) {
        filtered.push({
          inputValue: params.inputValue,
          loading,
          creatable: loading ? loadingText : `Add "${params.inputValue}"`,
        });
      }
    }
    return filtered;
  };

  return (
    <Autocomplete
      loading={loading}
      value
      onOpen={handleOpen}
      autoHighlight
      size='small'
      onInputChange={handleInputChange}
      filterOptions={handleFilterOptions}
      disabledItemsFocusable
      getOptionDisabled={(option) => {
        return option?.loading;
      }}
      getOptionLabel={(option) => {
        // option will be string when hit enter
        if (clearOnSelect) return '';

        if (typeof option === 'string') {
          return option;
        }
        return (getOptionLabel && getOptionLabel(option)) || option?.inputValue;
      }}
      renderOption={(option) => {
        return (
          renderOption(option) || ((loading || onCreate) && option?.creatable)
        );
      }}
      options={getOptions(data) || []}
      onChange={(e, data) => {
        if (data?.loading) {
          return;
        }
        if (data?.inputValue) {
          onCreate && onCreate(data?.inputValue);
          return;
        }
        onChange && onChange(data);
        return;
      }}
      renderInput={(params) => {
        return (
          <TextField
            placeholder={placeholder}
            variant='outlined'
            required={required}
            {...params}
            {...textFieldProps}
          />
        );
      }}
      {...props}
    />
  );
};

export default AutoCompleteAsync;
