import React from 'react';
import LabelWrapper from '../label-wrapper-input';

// composition LabelWrapper + Input Component
const LabelInput = (props) => {
  const {
    label,
    required,
    showRequiredStar,
    placeholder = label?.toUpperCase(),
    component: Component,
    ...componentInputProps
  } = props;
  return (
    <LabelWrapper
      label={label?.toUpperCase()}
      showRequiredStar={required || showRequiredStar}
    >
      <Component
        placeholder={placeholder}
        required={required}
        {...componentInputProps}
      />
    </LabelWrapper>
  );
};

export default LabelInput;
