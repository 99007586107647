import { ThemeProvider, CssBaseline } from '@material-ui/core';
import { SnackbarProvider } from 'notistack';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from 'react-router-dom';
import './style/global.css';
import DashboardLayout from './components/layout/dashboard';
import theme from './theme';
import UtilityContextProvider from './components/context-provider/utilty-context';
import dashboardRoutes from './routes/dashboard';
import LoginPage from './pages/login';
import PrivateRoute from './components/private-route';
import RouteX from './components/routeX';
import UserContextProvider from './components/context-provider/user-context';
import ForgotPasswordPage from './pages/forgot-password';
import 'react-dates/initialize';
import 'react-dates/lib/css/_datepicker.css';
import './style/react_dates_overrides.css';
import ACRoute from './components/ac-route';
import RegisterPage from './pages/register';

function App() {
  return (
    <ThemeProvider theme={theme}>
      <SnackbarProvider
        maxSnack={3}
        anchorOrigin={{
          vertical: 'top',

          horizontal: 'center',
        }}
      >
        <UtilityContextProvider>
          <CssBaseline />
          <Router>
            <Switch>
              <RouteX
                path='/login'
                component={LoginPage}
                redirect='/dashboard'
              ></RouteX>
              <RouteX
                path='/register'
                component={RegisterPage}
                redirect='/dashboard'
              ></RouteX>
              <Route path='/invite/:token' component={RegisterPage} />
              <Route path='/forgot-password' component={ForgotPasswordPage} />
              <PrivateRoute path='/dashboard/:path*'>
                <UserContextProvider>
                  <DashboardLayout>
                    <Switch>
                      {dashboardRoutes.map((route, index) => (
                        <ACRoute {...route} key={index} />
                      ))}
                    </Switch>
                  </DashboardLayout>
                </UserContextProvider>
              </PrivateRoute>
              <Route path='/*'>
                <Redirect to='/login' />
              </Route>
            </Switch>
          </Router>
        </UtilityContextProvider>
      </SnackbarProvider>
    </ThemeProvider>
  );
}

export default App;
