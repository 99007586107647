import React from 'react';
import { makeStyles, Box, Button } from '@material-ui/core';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles((theme) => ({
  floatingButtonContainer: {
    position: 'fixed',
    bottom: 0,
    right: 0,
    width: '100%',
    background: '#ffffffbd',
    display: 'flex',
    [theme.breakpoints.up('sm')]: {
      justifyContent: 'flex-end',
      paddingRight: '47px',
    },
    [theme.breakpoints.down('xs')]: {
      justifyContent: 'center',
    },
    padding: '20px 0',
    '& button': {
      marginRight: '10px',
    },
  },
}));

const FloatingBar = ({
  onCancel,
  onCreate,
  confirmButtonText,
  confirmButtonProps,
  disabledConfirmButton = false,
}) => {
  const { t } = useTranslation();
  const classes = useStyles();
  return (
    <Box className={classes.floatingButtonContainer}>
      <Button
        onClick={onCancel}
        className='button'
        variant='contained'
        color='secondary'
        disableElevation
      >
        {t('cancel')}
      </Button>
      <Button
        disabled={disabledConfirmButton}
        onClick={onCreate}
        className='button'
        variant='contained'
        disableElevation
        color='primary'
        type='submit'
        {...confirmButtonProps}
      >
        {t(confirmButtonText)}
      </Button>
    </Box>
  );
};

export default FloatingBar;
