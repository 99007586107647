import { makeStyles } from '@material-ui/core';
import ErrorIcon from '@material-ui/icons/Error';

const useStyles = makeStyles((theme) => ({
  root: {
    cursor: 'help',
    fill: theme.palette.error.dark,
  },
}));

const InputError = ({ icon, message, error }) => {
  console.log(error);
  const classes = useStyles();
  const IconComponent = icon || ErrorIcon;

  return error ? (
    <IconComponent
      style={{ marginRight: 6, fontSize: '18px' }}
      titleAccess={message || error.message}
      className={classes.root}
    />
  ) : null;
};

export default InputError;
