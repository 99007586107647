import { useSnackbar } from 'notistack';
import React, { createContext, useState } from 'react';
import DeletePromptPopup from '../../delete-prompt-popup';
import { useTranslation } from 'react-i18next';
import PromptPopup from '../../prompt-popup';

export const UtilityContext = createContext({
  showDeletePrompt: () => {},
  closeDeletePrompt: () => {},
  showPrompt: () => {},
  closePrompt: () => {},
  showSnackbar: () => {},
  closeSnackbar: () => {},
});

const UtilityContextProvider = ({ children }) => {
  const { t } = useTranslation();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const [deletePrompt, setDeletePrompt] = useState({
    state: false,
    handleConfirm: () => {},
  });
  const [prompt, setPrompt] = useState({
    state: false,
    handleConfirm: () => {},
    custom: {},
  });

  const showDeletePrompt = ({ handleConfirm, icon }) =>
    setDeletePrompt((prev) => ({ ...prev, state: true, handleConfirm, icon }));

  const closeDeletePrompt = () => {
    setDeletePrompt((prev) => ({ ...prev, state: false }));
  };

  const showPrompt = ({ handleConfirm, handleClose, ...other }) =>
    setPrompt({ state: true, handleConfirm, handleClose, custom: other });

  const closePrompt = () => setPrompt((prev) => ({ ...prev, state: false }));

  const showSnackbar = ({
    message,
    variant = 'success',
    noTranslate = false,
  }) => {
    if (typeof message === 'string') {
      message = noTranslate ? message : t(message);
      // variant could be success, error, warning, info, or default
      enqueueSnackbar(message, { variant: variant });
    } else {
      enqueueSnackbar('message response failed', { variant: 'error' });
    }
  };

  return (
    <UtilityContext.Provider
      value={{
        showDeletePrompt,
        showSnackbar,
        showPrompt,
        closePrompt,
        closeDeletePrompt,
        closeSnackbar,
      }}
    >
      <DeletePromptPopup
        open={deletePrompt.state}
        handleClose={closeDeletePrompt}
        handleConfirm={deletePrompt.handleConfirm}
      />
      <PromptPopup
        open={prompt.state}
        handleClose={
          prompt.handleClose
            ? () => prompt.handleClose(closePrompt)
            : closePrompt
        }
        handleConfirm={prompt.handleConfirm}
        {...prompt.custom}
      ></PromptPopup>
      {children}
    </UtilityContext.Provider>
  );
};

export default UtilityContextProvider;
