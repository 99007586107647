import { Button } from '@material-ui/core';
import React from 'react';

const DownloadButton = ({ label, ...props }) => {
  return (
    <Button
      variant="contained"
      disableElevation
      components="a"
      download
      {...props}
    >
      {label}
    </Button>
  );
};

export default DownloadButton;
