import { Box, makeStyles, Typography } from '@material-ui/core';

const useCustomChipStyle = makeStyles((theme) => ({
  root: {
    display: 'flex',
    width: 'fit-content',
    background: ({ id }) =>
      id !== null ? theme.palette.primary.main : theme.palette.grey[600],
    padding: '2px 2px 2px 10px',
    borderRadius: '16px',
    fontSize: '14px',
    '& > p': {
      color: 'white',
    },
    '& div': {
      background: 'white',
      display: 'flex',
      alignItems: 'center',
      borderRadius: '16px',
      padding: '0 5px',
      marginLeft: '5px',
      '& p': {
        textAlign: 'center',
        minWidth: '15px',
        fontWeight: 'bold',
      },
    },
  },
}));

const CustomChip = (props) => {
  const { name, qty, id } = props;
  const classes = useCustomChipStyle({ id });
  return (
    <Box className={classes.root}>
      <Typography>{name}</Typography>
      <Box>
        <Typography>{qty}</Typography>
      </Box>
    </Box>
  );
};

export default CustomChip;
