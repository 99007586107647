import React from 'react';
import { Typography } from '@material-ui/core';

const CategoryLabel = ({ name, parent, option }) => {
  if (parent) {
    const reverseParent = [...parent].reverse();

    const ancestors = reverseParent?.reduce((acc, curr, index) => {
      if (index !== reverseParent.length - 1) {
        return (acc += `${curr.name} > `);
      }
      return (acc += curr.name);
    }, '');
    return (
      <div>
        <Typography>
          <b>{name}</b>
        </Typography>
        <Typography variant='caption'>{ancestors}</Typography>
      </div>
    );
  }
  return option?.name;
};

export default CategoryLabel;
