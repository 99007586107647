import { TableBody, TableCell, TableRow } from '@material-ui/core';
import React, { useContext } from 'react';
import { useFieldArray, useFormContext } from 'react-hook-form';
import { UtilityContext } from 'src/components/context-provider/utilty-context';
import CustomRemoveIconButton from 'src/components/custom-button/remove-icon-button';
import CustomTable from 'src/components/custom-table';
import { RHFNumberInput } from 'src/components/rhf-controlled-input';
import VariantInput from './variant-input';

const tableHeadData = [
  { value: 'no.', width: '5px' },
  { value: 'unit', width: '40%' },
  { value: 'convert-from', width: '25%' },
  { value: 'convert-to', width: '25%' },
  { width: '5px' },
];

const Products = () => {
  // form context
  const { control } = useFormContext();
  // utility context
  const { showDeletePrompt, closeDeletePrompt } = useContext(UtilityContext);

  // filedArray unitConversions
  const { fields, append, remove } = useFieldArray({
    control,
    name: 'unitConversions',
  });

  // add row item
  const handleAddField = (index) => {
    if (index === fields.length - 1) {
      append(
        { unit: null, convertFrom: null, convertTo: null },
        { focusName: `unitConversions.${index}.convertFrom` }
      );
    }
  };

  // remove row item prompt
  const handleRemove = (index, id) => {
    showDeletePrompt({
      handleConfirm: () => onDelete(index, id),
    });
  };

  // on remove confirm
  const onDelete = (index) => {
    remove(index);
    closeDeletePrompt();
  };

  return (
    <CustomTable.Container>
      {/* table header */}
      <CustomTable.Head data={tableHeadData} />
      {/* table body */}
      <TableBody>
        {fields.map((field, index) => {
          // get value from field
          const get = getValue(field);

          return (
            <TableRow key={field.id}>
              <TableCell>
                <CustomTable.CellValue>{index + 1}</CustomTable.CellValue>
              </TableCell>
              <TableCell>
                <VariantInput
                  index={index}
                  name={`unitConversions.${index}.unit`}
                  handleAddField={handleAddField}
                  defaultValue={get.unit}
                />
              </TableCell>
              <TableCell>
                <RHFNumberInput
                  name={`unitConversions.${index}.convertFrom`}
                  defaultValue={get.convertFrom}
                  textAlign='right'
                  decimalValue={false}
                  placeholder={1}
                  min={1}
                />
              </TableCell>
              <TableCell>
                <RHFNumberInput
                  name={`unitConversions.${index}.convertTo`}
                  defaultValue={get.convertTo}
                  textAlign='right'
                  placeholder={1}
                  min={1}
                  decimalValue={false}
                />
              </TableCell>
              <TableCell>
                <CustomTable.CellValue component='div'>
                  <CustomRemoveIconButton
                    invisible={
                      index === fields.length - 1 || fields.length <= 1
                    }
                    onClick={() => handleRemove(index, field._id)}
                  />
                </CustomTable.CellValue>
              </TableCell>
            </TableRow>
          );
        })}
      </TableBody>
    </CustomTable.Container>
  );
};

// function to get unitConversions value
export const getValue = (data) => {
  const unit = data.unit ?? '';
  const convertFrom = data.convertFrom ?? '';
  const convertTo = data.convertTo ?? '';

  return {
    unit,
    convertFrom,
    convertTo,
  };
};

export default Products;
