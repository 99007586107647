import React from 'react';
import { useTranslation } from 'react-i18next';
import DateFormat from 'src/components/date-format';
import TableXClassic from 'src/components/tableX-classic';

const BillingHistory = ({ billingId, triggerFetch }) => {
  const { t } = useTranslation();

  const tableSchema = [
    {
      id: 'createdAt',
      label: t('date'),
      type: 'component',
      style: { width: '20%' },
      components: [
        {
          name: DateFormat,
          customValueProp: 'value',
          props: {
            variant: 'date-time',
          },
        },
      ],
    },
    {
      id: 'message',
      label: t('activity'),
      style: { textAlign: 'left' },
    },
  ];

  return (
    <TableXClassic
      triggerFetch={triggerFetch}
      minHeight='10vh'
      url={`${process.env.REACT_APP_API}/api/v1/billing/${billingId}/activity`}
      dataConfig={['activities']}
      schema={tableSchema}
    ></TableXClassic>
  );
};

export default BillingHistory;
