import { Grid, Typography } from '@material-ui/core';
import React from 'react';

const DeliveryUnavailable = () => {
  return (
    <Grid container className="container">
      <Grid item xs={12}>
        <Typography>Your delivery service is currently disable.</Typography>
        <Typography>Contact Admin to enable</Typography>
      </Grid>
    </Grid>
  );
};

export default DeliveryUnavailable;
