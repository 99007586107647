import React, { useContext, useEffect } from 'react';
import { TableRow, TableCell, TableBody } from '@material-ui/core';
import { useFieldArray, useFormContext, useWatch } from 'react-hook-form';
import {
  RHFDiscountInput,
  RHFNumberInput,
} from 'src/components/rhf-controlled-input';
import VariantInput from './variant-input';
import CustomTable from 'src/components/custom-table';
import CustomRemoveIconButton from 'src/components/custom-button/remove-icon-button';
import plusTax from 'src/util/plusTax';
import { UtilityContext } from 'src/components/context-provider/utilty-context';
import calculateDiscount from 'src/util/calculate-discount';

const tableHeadData = [
  { value: 'no.', width: '50px' },
  { value: 'image', width: '50px' },
  { value: 'product-name' },
  { value: 'quantity', width: '80px' },
  { value: 'unit-price', width: '10%' },
  { value: 'discount', width: '120px' },
  { value: 'amount', align: 'right', width: '15%' },
  { width: '30px' },
];

const Products = ({ state }) => {
  // form context
  const { register, control, setValue } = useFormContext();
  // utility context
  const { showDeletePrompt, closeDeletePrompt } = useContext(UtilityContext);

  // filedArray product
  const { fields, append, remove } = useFieldArray({
    control,
    name: 'products',
  });

  // controlledFields
  const watchFieldArray = useWatch({ name: 'products' });

  const controlledFields = fields.map((field, index) => {
    return {
      ...field,
      ...watchFieldArray?.[index],
    };
  });

  // add row item
  const handleAddField = (index) => {
    if (index === fields.length - 1) {
      append({ qty: '' }, { focusName: `products.${index + 1}.stock` });
    }
  };

  // remove row item prompt
  const handleRemove = (index, id) => {
    showDeletePrompt({
      handleConfirm: () => onDelete(index, id),
    });
  };

  // on remove confirm
  const onDelete = (index) => {
    remove(index);
    closeDeletePrompt();
  };

  return (
    <CustomTable.Container>
      {/* table header */}
      <CustomTable.Head data={tableHeadData} />
      {/* table body */}
      <TableBody>
        {controlledFields.map((field, index) => {
          // get value from field
          const get = getValue(field);
          const isEditState = state === 'edit';
          // store salePrice
          register(`products.${index}.costPrice`, {
            value: get.costPrice,
          });
          return (
            <TableRow key={field.id}>
              <TableCell>
                <CustomTable.CellValue>{index + 1}</CustomTable.CellValue>
              </TableCell>
              <TableCell>
                <CustomTable.Image src={field.stock?.image?.thumbnail} />
              </TableCell>
              <TableCell>
                <VariantInput
                  index={index}
                  disabled={isEditState}
                  handleAddField={handleAddField}
                  defaultValue={get.stock}
                  rules={state === 'edit' || fields.length > 1}
                />
              </TableCell>
              <TableCell>
                <RHFNumberInput
                  disabled={isEditState}
                  name={`products.${index}.qty`}
                  defaultValue={get.qty}
                  textAlign='right'
                  placeholder={field.stock ? '1' : ''}
                  onChange={(e, update) => {
                    const value = e.target.value || 1;
                    setValue(
                      `products.${index}.amount`,
                      value * field.salePrice || ''
                    );
                    update(e.target.value);
                  }}
                  decimalValue={false}
                  min={1}
                />
              </TableCell>
              <TableCell>
                <RHFNumberInput
                  placeholder={field.stock ? '0' : ''}
                  disabled={isEditState}
                  name={`products.${index}.salePrice`}
                  onChange={(e, update) => {
                    const value = e.target.value || 0;
                    setValue(
                      `products.${index}.amount`,
                      (field.qty || 1) * value || ''
                    );
                    update(e.target.value);
                  }}
                  textAlign='right'
                  defaultValue={get.salePrice}
                />
              </TableCell>
              <TableCell>
                <RHFDiscountInput
                  placeholder={field.stock ? '0' : ''}
                  disabled={isEditState}
                  name={`products.${index}.discount`}
                  defaultValue={get.discount}
                  onChange={(_, data, update) => {
                    setValue(
                      `products.${index}.amount`,
                      calculateDiscount(
                        (field.qty || 1) * (field.salePrice || 0),
                        data
                      ) || ''
                    );
                    update(data);
                  }}
                />
              </TableCell>
              <TableCell style={{ textAlign: 'right' }}>
                <RHFNumberInput
                  disabled={isEditState}
                  placeholder={field.stock ? '0' : ''}
                  name={`products.${index}.amount`}
                  defaultValue={get.totalAmount}
                  onChange={(e, update) => {
                    const value = e.target.value || 0;
                    setValue(
                      `products.${index}.salePrice`,
                      value / (field.qty || 1) || ''
                    );
                    setValue(`products.${index}.discount.amount`, '');
                    update(e.target.value);
                  }}
                  textAlign='right'
                  min={0}
                />
              </TableCell>
              <TableCell>
                <CustomTable.CellValue component='div'>
                  <CustomRemoveIconButton
                    invisible={
                      isEditState ||
                      index === fields.length - 1 ||
                      fields.length <= 1
                    }
                    onClick={() => handleRemove(index, field._id)}
                  />
                </CustomTable.CellValue>
              </TableCell>
            </TableRow>
          );
        })}
      </TableBody>
    </CustomTable.Container>
  );
};

// function to get product value
export const getValue = (data) => {
  const costPrice = data.costPrice ?? 0;
  const salePrice = data.salePrice ?? '';
  const qty = data.qty;
  const discount = data.discount ?? { amount: '', type: 'percentage' };
  const totalAmount = calculateDiscount(
    (qty || 1) * Number(salePrice),
    discount
  );
  const totalAmountWithTax = plusTax(totalAmount);
  const expiryDate = data.expiryDate;
  const stock = data.stock ?? null;

  return {
    stock,
    costPrice,
    salePrice,
    qty,
    expiryDate,
    totalAmount,
    totalAmountWithTax,
    discount,
  };
};

export default Products;
