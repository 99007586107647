import { Grid, Typography, makeStyles } from '@material-ui/core';
import React from 'react';
import { useTranslation } from 'react-i18next';
import buildAddress from '../../../../../../util/build-address';

const useStyles = makeStyles((theme) => ({
  label: {
    color: theme.palette.primary.main,
    fontWeight: 'bold',
    minWidth: '110px',
  },
}));

const Address = ({ data }) => {
  const { t } = useTranslation();
  const classes = useStyles();

  const dataList = [
    {
      label: t('receiver'),
      value: data?.receiver,
    },
    {
      label: t('phone'),
      value: data?.phone,
    },
    {
      label: t('address'),
      value: buildAddress(data?.location),
    },
    {
      label: t('nearby'),
      value: data?.location?.nearby?.description ?? t('n/a'),
    },
  ];
  return (
    <Grid container spacing={1} style={{ position: 'relative' }}>
      {dataList.map((item, index) => (
        <Grid item xs={12} key={index}>
          <Grid container width='100%' wrap='nowrap'>
            <Typography className={classes.label}>{item.label}</Typography>
            <Typography className='ellipsis'>{item.value}</Typography>
          </Grid>
        </Grid>
      ))}
    </Grid>
  );
};

export default Address;
