import React, { useMemo } from 'react';
import {
  Grid,
  Paper,
  Typography,
  Chip,
  Box,
  IconButton,
} from '@material-ui/core';
import { useFieldArray, useFormContext, useWatch } from 'react-hook-form';

import NumberFormatCustom from '../../../../components/number-format';

import { useTranslation } from 'react-i18next';
import ProductImage from 'src/components/product-image';
import CancelIcon from '@material-ui/icons/Cancel';
import VariantInputSearch from 'src/components/variant-input-search';
import calculateDiscount from 'src/util/calculate-discount';

const Products = ({ setIsDirty }) => {
  // form context
  const { control, register } = useFormContext();

  // filedArray product
  const { fields, remove, prepend } = useFieldArray({
    control,
    name: 'products',
  });

  const existedVariantIds = useMemo(() => {
    return fields.reduce((acc, curr) => {
      acc[curr._id] = curr._id;
      return acc;
    }, {});
  }, [fields]);

  const discount = useWatch({ name: 'disc', control });

  return (
    <Grid container spacing={2}>
      <Grid item xs={6}>
        <VariantInputSearch
          onChange={(data) => {
            prepend(data);
            setIsDirty(true);
          }}
          filterOptions={(options) => {
            return options.filter(
              (option) => !(option._id in existedVariantIds)
            );
          }}
        />
      </Grid>
      <Grid item xs={12}>
        <Grid container spacing={2}>
          {fields.map((item, index) => {
            register(`products.${index}`, { value: item });
            return (
              <Grid item xs={12} md={6} key={item.id}>
                <DiscountProductCardItem
                  data={item}
                  discountAmount={discount.amount}
                  discountType={discount.type}
                  onRemove={() => {
                    remove(index);
                    setIsDirty(true);
                  }}
                />
              </Grid>
            );
          })}
        </Grid>
      </Grid>
    </Grid>
  );
};

const DiscountProductCardItem = ({
  data,
  onRemove,
  discountAmount,
  discountType,
}) => {
  const { t } = useTranslation();
  return (
    <Paper style={{ padding: '5px' }} elevation={2}>
      <Grid
        container
        style={{ width: '100%', height: '100%' }}
        spacing={1}
        wrap='nowrap'
      >
        <Grid item xs='auto'>
          <ProductImage size='large' src={data?.variant?.images?.[0]?.key} />
        </Grid>
        <Grid item xs style={{ overflow: 'hidden' }}>
          <Grid
            container
            style={{ height: '100%', width: '100%', overflow: 'hidden' }}
          >
            <Grid item xs={12}>
              <Typography
                className='ellipsis'
                style={{ fontSize: '16px' }}
                gutterBottom
              >
                <b>{data?.variant?.name}</b>
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography className='ellipsis' gutterBottom variant='body2'>
                SKU: {data?.variant?.sku || data?.variant?.altCode || t('n/a')}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography className='ellipsis' gutterBottom variant='body2'>
                {data?.variant?.attr &&
                  Object.entries(data?.variant?.attr).map(
                    ([key, value], index) => {
                      const keys = Object.keys(data?.variant?.attr);
                      return (
                        <React.Fragment key={index}>
                          {`${key}: `}
                          <span>
                            {value}
                            {keys.length > 0 && index !== keys.length - 1
                              ? ' , '
                              : ''}
                          </span>
                        </React.Fragment>
                      );
                    }
                  )}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs='auto'>
          <Grid
            container
            style={{ height: '100%', textAlign: 'right', position: 'relative' }}
            direction='column'
            justify='flex-end'
            alignItems='flex-end'
          >
            <Box
              style={{
                position: 'absolute',
                top: 0,
                right: 0,
                margin: '-5px',
              }}
            >
              <IconButton onClick={onRemove} size='small' color='secondary'>
                <CancelIcon fontSize='small' />
              </IconButton>
            </Box>
            <Grid item>
              <Chip
                color='primary'
                size='small'
                label={
                  <Typography style={{ fontSize: '10px' }}>
                    <NumberFormatCustom
                      value={discountAmount}
                      prefix='-'
                      suffix={`${discountType === 'currency' ? '$' : '%'}`}
                    />
                  </Typography>
                }
              />
              <Box display='flex' alignItems='center'>
                <Typography
                  variant='caption'
                  style={{ textDecoration: 'line-through', marginRight: '2px' }}
                >
                  <NumberFormatCustom value={data?.salePrice} />
                </Typography>
                <Typography align='right' variant='h6'>
                  <NumberFormatCustom
                    value={calculateDiscount(data?.salePrice, {
                      amount: discountAmount,
                      type: discountType,
                    })}
                  />
                </Typography>
              </Box>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Paper>
  );
};

export default Products;
