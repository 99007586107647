import { Grid, Paper } from '@material-ui/core';
import React, { useState } from 'react';
import { DateRangePicker } from 'react-dates';
import { useTranslation } from 'react-i18next';
import TableX from '../../components/tableX';
import config from '../../components/tableX/config';
import NumberFormat from '../../components/number-format';
import moment from 'moment';
import TitleBar from '../../components/title-bar';
import TableFooter from 'src/components/table-footer';
import { formatUTCDate } from 'src/util/date-format';

const SaleByProductReport = () => {
  const { t } = useTranslation();
  const [date, setDate] = useState({
    startDate: moment().startOf('month'),
    endDate: moment().endOf('month'),
    focusedInput: false,
  });
  const [triggerFetch, setTriggerFetch] = useState(false);

  const tableSchema = [
    {
      id: 'name',
      label: t('name'),
    },
    {
      id: 'totalOrderAmount',
      label: t('total-order'),
      type: 'component',
      components: [
        {
          name: NumberFormat,
          customValueProp: 'value',
          props: { format: 'normal' },
        },
      ],
    },
    {
      id: 'totalOrderQty',
      label: t('total-pieces'),
      type: 'component',
      dataConfig: [],
      components: (data) => {
        return (
          <NumberFormat
            value={data?.totalOrderQty}
            format='normal'
            suffix={` ${data?.unit}`}
          />
        );
      },
    },
    {
      id: 'totalProfit',
      label: t('total-profit'),
      type: 'component',
      components: [
        {
          name: NumberFormat,
          customValueProp: 'value',
        },
      ],
    },
    {
      id: 'totalSaleAmount',
      label: t('total-sale'),
      type: 'component',
      components: [
        {
          name: NumberFormat,
          customValueProp: 'value',
        },
      ],
    },
    {
      id: 'totalCostPrice',
      label: t('total-cost'),
      type: 'component',
      components: [
        {
          name: NumberFormat,
          customValueProp: 'value',
        },
      ],
    },
  ];

  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <TitleBar disableButton></TitleBar>
      </Grid>
      <Grid item xs={12}>
        <Paper className='tableX-container'>
          <Grid container>
            <Grid item xs={12}>
              <TableX
                disableSearch
                select={false}
                render={(_, data) => {
                  if (data) {
                    return (
                      <TableFooter
                        data={[
                          {
                            label: 'total-sale',
                            value: data.total?.sale,
                          },
                          {
                            label: 'total-profit',
                            value: data.total?.profit,
                          },
                        ]}
                      />
                    );
                  }
                }}
                triggerFetch={triggerFetch}
                config={tableConfig}
                schema={tableSchema}
                renderFilterInput={({ setDateRange }) => {
                  const handleDateChange = ({ startDate, endDate }) => {
                    setDate((prev) => {
                      return { ...prev, startDate, endDate };
                    });
                    if (startDate && endDate) {
                      setDateRange({
                        from: formatUTCDate(startDate),
                        to: formatUTCDate(endDate),
                      });
                    } else if (startDate === null && endDate === null) {
                      setDateRange({
                        from: null,
                        to: null,
                      });
                    }
                  };

                  return (
                    <Grid container spacing={1}>
                      <Grid item>
                        <DateRangePicker
                          showClearDates
                          hideKeyboardShortcutsPanel
                          isOutsideRange={() => false}
                          startDateId='start_date' // PropTypes.string.isRequired,
                          endDateId='end_date' // PropTypes.string.isRequired,
                          startDate={date.startDate}
                          endDate={date.endDate}
                          onDatesChange={handleDateChange}
                          focusedInput={date.focusedInput}
                          onFocusChange={(focusedInput) =>
                            setDate((prev) => ({ ...prev, focusedInput }))
                          }
                        />
                      </Grid>
                    </Grid>
                  );
                }}
              ></TableX>
            </Grid>
          </Grid>
        </Paper>
      </Grid>
    </Grid>
  );
};

const tableConfig = {
  ...config,
  url: `${process.env.REACT_APP_API}/api/v1/report/sale-by-product`,
  initialDate: {
    from: formatUTCDate(moment().startOf('month')),
    to: formatUTCDate(moment().endOf('month')),
  },
  defaultSort: { totalProfit: 'desc' },
  dataConfig: ['report'],
};

export default SaleByProductReport;
