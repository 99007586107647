import RichTextEditor from 'src/pages/product/create-edit/form/rich-text-editor';
import CompositeInfo from './composite-info';
import ImageUpload from './image-upload';
import Products from './products';
import { Grid, Paper, Typography, makeStyles } from '@material-ui/core';
import TitleBar from 'src/components/title-bar';
import { useForm, FormProvider } from 'react-hook-form';
import { useContext, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import FloatingBar from 'src/components/floating-bar';
import CompositeItemsTotal from './total';
import axios from 'axios';
import useMessage from 'src/hooks/useMessage';
import findImgSrcInHTML from 'src/pages/product/create-edit/form/rich-text-editor/utilities/find-img-src-html';
import generateKey from 'src/util/generate-keys';
import compressImage from 'src/util/image-compress';
import Spinner, { useSpinner } from 'src/components/spinner';
import { UserContext } from 'src/components/context-provider/user-context';
import {
  useHistory,
  useParams,
} from 'react-router-dom/cjs/react-router-dom.min';

const useStyles = makeStyles(() => ({
  root: {
    marginBottom: '150px',
    flexDirection: 'column',
    position: 'relative',
  },
}));

const CreateCompositePage = () => {
  const classes = useStyles();
  const { t } = useTranslation();
  const history = useHistory();
  const { id } = useParams();
  const methods = useForm({
    defaultValues: { products: [{ qty: '' }], descriptionImage: [] },
  });
  const { me, s3BaseURL } = useContext(UserContext);

  const { showErrorResponseMessage, showSuccessResponseMessage } = useMessage();
  const { spinnerState, openSpinner, closeSpinner } = useSpinner();
  const [state, setState] = useState('create');
  const [images, setImages] = useState({ images: [], remove: [] });

  const buttonRef = useRef();

  const handleCancel = () => {
    goBackToList();
  };

  const goBackToList = () => {
    history.push('/dashboard/composite');
  };

  useEffect(() => {
    if (id) {
      openSpinner({ title: t('loading') });
      setState('edit');
      axios
        .get(`${process.env.REACT_APP_API}/api/v1/composite/${id}`)
        .then(({ data }) => {
          if (typeof data === 'object') {
            const { composite } = data;
            const resetData = {
              ...composite,
              description: composite.description[0].value,
              shortDescription: composite.shortDescription[0].value,
              descriptionImage: [],
            };
            setImages({ images: composite.images, remove: [] });
            methods.reset(resetData);
          } else {
            console.log('error reponse');
          }
        })
        .catch((error) => {
          showErrorResponseMessage(error);
        })
        .finally(() => {
          closeSpinner();
        });
    } else {
      setState('create');
    }
  }, []);

  const onSubmit = async (formData) => {
    openSpinner({ title: t('saving') });
    const warehouseId = me.warehouse ? me.warehouse.id : 'undefined';

    const {
      description,
      shortDescription,
      name,
      category,
      brand,
      sku,
      altCode,
      lowStock,
      products,
      slug,
      descriptionImage,
      keywords,
      tags,
    } = formData;

    const compositeInfo = {
      name,
      category: category
        ? { id: category._id || category.id, name: category.name }
        : null,
      brand: brand ? { id: brand._id || brand.id, name: brand.name } : null,
      sku,
      altCode,
      lowStock,
      slug,
      shortDescription: [{ lang: 'en', value: shortDescription }],
      description: [{ lang: 'en', value: description ?? '' }],
      products: products.filter((item) => item.stock),
      keywords,
      tags,
    };

    const uploadObject = {
      ContentTypes: [],
      Keys: [],
      Images: [],
      variants: [],
      images: [],
    };

    try {
      // remove image
      if (images.remove.length !== 0) {
        const removeImageKeys = images.remove.reduce((acc, curr) => {
          acc.push({ Key: curr.thumbnail.split('.com/')[1] });
          acc.push({ Key: curr.key.split('.com/')[1] });
          return acc;
        }, []);

        if (removeImageKeys.length !== 0) {
          try {
            await axios.post(
              `${process.env.REACT_APP_API}/api/v1/aws-s3/remove-file`,
              {
                Keys: removeImageKeys,
              }
            );
          } catch (error) {
            showErrorResponseMessage(error);
            console.error(error);
            closeSpinner();
            return;
          }
        }
      }
      // collect all image
      // top image
      if (images.images) {
        const uploadImages = await Promise.all(
          images.images.map(async (item) => {
            if (!('thumbnail' in item)) {
              const fileType = item.file.type;
              const fileExtension = item.file.type.split('/')[1];
              // generate 2 keys (one image one thumbnail)
              uploadObject.ContentTypes.push(fileType, fileType);
              const Key = `public/${warehouseId}/products/${generateKey()}`;
              const imageKey = `${Key}.${fileExtension}`;
              const thumbnailKey = `${Key}-thumbnail.${fileExtension}`;

              uploadObject.Keys.push(imageKey, thumbnailKey);
              // compress image
              const { image, thumbnail } = await compressImage(item.file);
              uploadObject.Images.push(image, thumbnail);
              return {
                key: `${s3BaseURL}/${imageKey}`,
                thumbnail: `${s3BaseURL}/${thumbnailKey}`,
                mimeType: fileType,
              };
            } else {
              return item;
            }
          })
        );
        compositeInfo.images = uploadImages;
      }

      // description image
      let updateDescription = description;
      if (updateDescription && descriptionImage?.length) {
        const existImages = findImgSrcInHTML(description);
        descriptionImage.forEach((file) => {
          if (existImages.includes(file.url)) {
            const fileType = file.type;
            const fileExtension = file.type.split('/')[1];
            // generate key
            uploadObject.ContentTypes.push(fileType);
            const Key = `public/${warehouseId}/products/${generateKey()}`;
            const imageKey = `${Key}.${fileExtension}`;

            uploadObject.Keys.push(imageKey);
            uploadObject.Images.push(file.img);

            updateDescription = updateDescription.replace(
              file.url,
              `${s3BaseURL}/${imageKey}`
            );
          }
        });
      }
      compositeInfo.description = [{ lang: 'en', value: updateDescription }];

      if (uploadObject.Keys.length) {
        const response = await axios.post(
          `${process.env.REACT_APP_API}/api/v1/aws-s3/file-upload-url`,
          {
            ContentTypes: uploadObject.ContentTypes,
            Keys: uploadObject.Keys,
          }
        );

        await Promise.all(
          response.data.map(async (item, index) => {
            await axios.put(item.uploadURL, uploadObject.Images[index], {
              headers: {
                'Content-Type': item.type,
                'Content-Encoding': 'base64',
              },
              withCredentials: false,
            });
          })
        );
      }
    } catch (err) {
      showErrorResponseMessage(err);
      closeSpinner();
      console.error(err);
      return;
    }

    switch (state) {
      case 'create':
        try {
          const response = await axios.post(
            `${process.env.REACT_APP_API}/api/v1/composite`,
            compositeInfo
          );
          showSuccessResponseMessage(response);
          goBackToList();
        } catch (error) {
          showErrorResponseMessage(error);
        } finally {
          closeSpinner();
        }
        break;
      case 'edit':
        try {
          const response = await axios.post(
            `${process.env.REACT_APP_API}/api/v1/composite/${id}`,
            compositeInfo
          );
          showSuccessResponseMessage(response);
          goBackToList();
        } catch (error) {
          showErrorResponseMessage(error);
        } finally {
          closeSpinner();
        }
        break;
      default:
        console.log('Error state');
    }
  };

  return (
    <FormProvider {...methods}>
      <form id='composite-form' onSubmit={methods.handleSubmit(onSubmit)}>
        <Grid container className={classes.root} spacing={3}>
          <Grid item>
            <TitleBar disableButton />
          </Grid>
          <Grid item>
            <Paper className='container'>
              <Grid container spacing={3}>
                <Grid item xs={12}>
                  <Grid container spacing={3}>
                    <Grid item xs={12}>
                      <Typography variant='h6'>
                        {t('composite-information')}
                      </Typography>
                    </Grid>
                    <Grid item xs={12}>
                      <CompositeInfo />
                    </Grid>
                    <Grid item xs={12}>
                      <ImageUpload value={images} onChange={setImages} />
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={12}>
                  <Grid container spacing={2}>
                    <Grid item xs={12}>
                      <Typography variant='h6'>
                        {t('composite-items')}
                      </Typography>
                    </Grid>
                    <Grid item xs={12}>
                      <Products state={state} />
                    </Grid>
                    <Grid item xs={12}>
                      <Grid container justify='flex-end'>
                        <Grid item xs={4}>
                          <CompositeItemsTotal />
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={12}>
                  <Grid container spacing={2}>
                    <Grid item xs={12}>
                      <Typography variant='h6'>{t('description')}</Typography>
                    </Grid>
                    <Grid item xs={12}>
                      <RichTextEditor
                        control={methods.control}
                        register={methods.register}
                        setValue={methods.setValue}
                        getValues={methods.getValues}
                      />
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Paper>
          </Grid>
        </Grid>
        <button ref={buttonRef} hidden type='submit' form='composite-form' />
      </form>
      <FloatingBar
        onCancel={handleCancel}
        onCreate={() => buttonRef.current.click()}
        confirmButtonText={
          state === 'create' ? 'create-composite' : 'save-changes'
        }
      />
      <Spinner state={spinnerState} />
    </FormProvider>
  );
};

export default CreateCompositePage;
