import { Grid } from '@material-ui/core';
import { createFilterOptions } from '@material-ui/lab/Autocomplete';
import React, { useContext, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Controller,
  useForm,
  useFieldArray,
  useFormContext,
} from 'react-hook-form';
import DialogContainer from '../../../../../../../components/dialog-container';
import TextInputListSugguestion from '../../../../../../../components/text-input-list-suggestion';
import { UtilityContext } from '../../../../../../../components/context-provider/utilty-context';

const filter = createFilterOptions();

const AddVariantForm = ({ open, onClose, append }) => {
  const { t } = useTranslation();
  const { handleSubmit, control, reset } = useForm();
  const { fields } = useFieldArray({ control, name: 'attributes' });
  const { getValues, setValue } = useFormContext();
  const { showSnackbar } = useContext(UtilityContext);

  useEffect(() => {
    if (open) {
      const values = getValues();
      reset({ attributes: values.attributes });
    }
  }, [open]);

  const onSubmit = (data) => {
    let values = getValues();
    // keep only use options

    // check if this variant already exist
    const isDuplicate = values.variants.some((item) => {
      return data.attributes.every((attr) => {
        return attr[attr.name] === item.attr[attr.name];
      });
    });

    if (isDuplicate) {
      return showSnackbar({
        message: 'variant-already-exist',
        variant: 'error',
      });
    }

    const joinOptions = data.attributes.reduce((acc, curr, index) => {
      const { name } = curr;
      acc += curr?.[name] ?? '';
      if (index !== data.attributes.length - 1) {
        acc += '-';
      }
      return acc;
    }, '');

    append({
      name: `${values.name} / ${joinOptions}`,
      attr: Object.assign(
        {},
        ...data.attributes.map(({ name, options, ...attr }) => attr)
      ),
      isNew: true,
    });

    values = getValues();

    // keep only use options
    const newOptions = values.attributes.map((attribute) => {
      return Array.from(
        new Set(
          values.variants.reduce((acc, curr) => {
            if (!curr.isDeleted) {
              acc.push(curr.attr[attribute.name]);
            }
            return acc;
          }, [])
        )
      );
    });

    newOptions.forEach((option, index) => {
      setValue(`attributes.${index}.options`, option);
    });

    onClose();
  };

  const formHandler = (e) => {
    e.preventDefault();
    handleSubmit(onSubmit)(e);
    e.stopPropagation();
  };

  return (
    <DialogContainer
      open={open}
      onClose={onClose}
      title={t('add-variant')}
      onCancel={onClose}
      formId='attribute-form'
    >
      <form id='attribute-form' onSubmit={formHandler}>
        <Grid container spacing={2}>
          {fields.map((field, index) => (
            <Grid item xs={12} key={field.id}>
              <Controller
                control={control}
                name={`attributes.${index}.${field.name}`}
                defaultValue={null}
                render={({ field: { value, onChange } }) => {
                  return (
                    <TextInputListSugguestion
                      value={value}
                      required
                      clearOnBlur
                      list={field.options}
                      onChange={(event, newValue) => {
                        if (typeof newValue === 'string') {
                          onChange(newValue);
                        } else if (newValue && newValue.inputValue) {
                          // Create a new value from the user input
                          onChange(newValue.inputValue);
                        } else {
                          onChange(newValue);
                        }
                      }}
                      label={field.name}
                      freeSolo
                      getOptionLabel={(option) => {
                        // Value selected with enter, right from the input
                        if (typeof option === 'string') {
                          return option;
                        }
                        // Add "xxx" option created dynamically
                        if (option.inputValue) {
                          return option.inputValue;
                        }
                        // Regular option
                        return option.title;
                      }}
                      renderOption={(option) => option.title || option}
                      filterOptions={(options, params) => {
                        const filtered = filter(options, params);

                        // Suggest the creation of a new value
                        if (params.inputValue !== '') {
                          filtered.push({
                            inputValue: params.inputValue,
                            title: `Add "${params.inputValue}"`,
                          });
                        }
                        return filtered;
                      }}
                    />
                  );
                }}
              ></Controller>
            </Grid>
          ))}
        </Grid>
      </form>
    </DialogContainer>
  );
};

export default AddVariantForm;
