import {
  Box,
  Divider,
  Grid,
  Paper,
  Typography,
  useTheme,
} from '@material-ui/core';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { BarChart, Bar, XAxis, Tooltip, ResponsiveContainer } from 'recharts';
import moment from 'moment';
import CustomNumberFormat from 'src/components/number-format';

const CustomTooltip = ({ active, payload, label }) => {
  const theme = useTheme();
  const { t } = useTranslation();
  if (active && payload && payload.length) {
    const salePrice = payload[0].payload.salePrice;
    const costPrice = payload[0].payload.costPrice;

    const dataList = [
      { color: payload[0].color, name: payload[0].name, value: salePrice },
      { color: payload[1].color, name: payload[1].name, value: costPrice },
      {
        color: theme.palette.primary.dark,
        name: 'different',
        value: salePrice - costPrice,
      },
    ];

    return (
      <Paper elevation={2}>
        <Box p={2}>
          <Grid container direction='column'>
            <Grid item>
              <Typography>{moment(label.toString()).format('MMMM')}</Typography>
            </Grid>
            {dataList.map((item, index) => (
              <Grid item key={index}>
                <Typography variant='caption' style={{ color: item.color }}>
                  <span>{t(item.name)} : </span>
                  <CustomNumberFormat value={item.value}></CustomNumberFormat>
                </Typography>
              </Grid>
            ))}
          </Grid>
        </Box>
      </Paper>
    );
  }

  return null;
};

const GraphTotalCostAndSalePrice = ({ data, date }) => {
  const theme = useTheme();
  const { t } = useTranslation();
  const [graphDate, setGraphDate] = useState(date.endDate);

  useEffect(() => {
    if (date.startDate && date.endDate) {
      setGraphDate(date.endDate);
    }
  }, [date]);

  return (
    <Paper style={{ width: '100%', padding: '15px 20px' }}>
      <Grid container alignItems='center'>
        <Grid item xs={12} lg>
          <Typography
            style={{ fontSize: '20px' }}
            color='textSecondary'
            gutterBottom
          >
            Total sale and cost price
          </Typography>
        </Grid>
        <Grid item xs={12} lg={6}>
          <Grid container spacing={2} justify='flex-end'>
            <Grid item xs='auto'>
              <div
                style={{
                  display: 'inline-block',
                  width: '10px',
                  height: '10px',
                  backgroundColor: theme.palette.primary.main,
                }}
              ></div>
              <span> {t('sale-price')}</span>
            </Grid>
            <Grid item xs='auto'>
              <div
                style={{
                  display: 'inline-block',
                  width: '10px',
                  height: '10px',
                  backgroundColor: theme.palette.grey[500],
                }}
              ></div>
              <span> {t('cost-price')}</span>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Divider />
      <Box height='200px'>
        <ResponsiveContainer>
          <BarChart
            data={generateGraphBarData(graphDate, data)}
            margin={{
              top: 5,
              bottom: 5,
            }}
          >
            <XAxis
              dataKey='month'
              tickFormatter={(value) => {
                return moment(value.toString()).format('MMM');
              }}
            />
            <Tooltip content={<CustomTooltip />} />
            <Bar dataKey='salePrice' fill={theme.palette.primary.main} />
            <Bar dataKey='costPrice' fill={theme.palette.grey[500]} />
          </BarChart>
        </ResponsiveContainer>
      </Box>
    </Paper>
  );
};

export default GraphTotalCostAndSalePrice;

/**
 * generate graph data with default value of previous 5 month
 * @param {date} endDate
 * @param {object[]} graphData
 * @returns {object[]} merged graphData with default value
 */
const generateGraphBarData = (endDate, graphData) => {
  const MONTH_RANGE = 6;

  return Array(MONTH_RANGE)
    .fill()
    .reduce((acc, _, index) => {
      const date = moment(endDate).subtract(index, 'months').format('M');
      const matchIndex = graphData?.findIndex(
        (item) => item.month === Number(date)
      );
      if (matchIndex !== -1) {
        acc.unshift(graphData?.[matchIndex]);
      } else {
        acc.unshift({ month: date, salePrice: 0, costPrice: 0, different: 0 });
      }
      return acc;
    }, []);
};
