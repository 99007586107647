import React from 'react';
import { TableRow, TableCell, TableBody } from '@material-ui/core';
import { useFieldArray, useFormContext } from 'react-hook-form';

import NumberFormatCustom from '../../../../components/number-format';
import CustomTable from '../../../../components/custom-table';
import StorageSelector from './storageSelector';

const tableHeadData = [
  { value: 'no.', width: '5px' },
  { value: 'image', width: '80px' },
  { value: 'product-name' },
  { value: 'received', width: '10%' },
  { value: 'quantity', width: '10%' },
  { value: 'storage', width: '20%' },
];

const Products = () => {
  // form context
  const { control } = useFormContext();

  // filedArray orderItems
  const { fields } = useFieldArray({
    control,
    name: 'product',
  });

  return (
    <CustomTable.Container>
      {/* table header */}
      <CustomTable.Head data={tableHeadData} />
      {/* table body */}
      <TableBody>
        {fields.map((field, index) => {
          // get value from field
          const get = getValue(field);

          return (
            <TableRow key={field.id}>
              <TableCell>
                <CustomTable.CellValue>{index + 1}</CustomTable.CellValue>
              </TableCell>
              <TableCell>
                <CustomTable.Image src={get.image} />
              </TableCell>
              <TableCell>
                <CustomTable.CellValue>{get.variantName}</CustomTable.CellValue>
              </TableCell>
              <TableCell>
                <CustomTable.CellValue align='center'>
                  {
                    <NumberFormatCustom
                      value={get.receivedQty}
                      format='normal'
                    />
                  }
                </CustomTable.CellValue>
              </TableCell>
              <StorageSelector nestedIndex={index} qty={field.receivedQty} />
            </TableRow>
          );
        })}
      </TableBody>
    </CustomTable.Container>
  );
};

// function to get product value
export const getValue = (data) => {
  const variantName = data.variant?.name ?? '';
  const receivedQty = data.receivedQty ?? 0;
  const image = data.variant?.image?.thumbnail;

  return {
    variantName,
    image,
    receivedQty,
  };
};

export default Products;
