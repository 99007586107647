import React from 'react';
import { Grid } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import 'date-fns';
import LabelInput from '../../../../components/label-input';
import {
  RHFCheckbox,
  RHFDateInput,
  RHFDiscountInput,
  RHFTextInput,
} from '../../../../components/rhf-controlled-input';
import { KeyboardDateTimePicker } from '@material-ui/pickers';

const DiscountInformaion = () => {
  const { t } = useTranslation();

  return (
    <Grid container spacing={3}>
      <Grid item xs={6}>
        <LabelInput
          component={RHFTextInput}
          name='name'
          label={t('discount-name')}
        />
      </Grid>
      <Grid item xs={6}>
        <LabelInput
          required
          label={t('start-date')}
          component={RHFDateInput}
          format='dd/MM/yyyy HH:mm a'
          customComponent={KeyboardDateTimePicker}
          name='startDate'
        />
      </Grid>
      <Grid item xs={6}>
        <LabelInput
          required
          component={RHFDiscountInput}
          name='disc'
          min={1}
          max={100}
          label={t('discount-amount')}
        />
      </Grid>
      <Grid item xs={6}>
        <LabelInput
          required
          label={t('expired-date')}
          component={RHFDateInput}
          format='dd/MM/yyyy HH:mm a'
          customComponent={KeyboardDateTimePicker}
          name='expiredDate'
        />
      </Grid>
      <Grid item xs={6}>
        <RHFCheckbox
          defaultValue={true}
          name='discount.website'
          label={t('discount-website')}
        />
        <RHFCheckbox name='discount.pos' label={t('discount-pos')} />
      </Grid>
    </Grid>
  );
};

export default DiscountInformaion;
