import React from 'react';
import { Grid, Typography } from '@material-ui/core';
import TextInput from '../../../../components/text-input';
import { useTranslation } from 'react-i18next';
import registerMui from '../../../../util/react-hook-form-helper/register-mui';
import LabelInput from '../../../../components/label-input';
import { RHFPhoneInput } from '../../../../components/rhf-controlled-input';

const CompanyInfo = ({ register, control }) => {
  const { t } = useTranslation();

  return (
    <Grid container className="container" spacing={3}>
      <Grid item xs={12}>
        <Typography variant="h6">{t('company-information')}</Typography>
      </Grid>
      <Grid item xs={12}>
        <TextInput
          label={t('name')}
          {...registerMui(register('name'))}
          required
        />
      </Grid>
      <Grid item xs={6}>
        <LabelInput
          component={RHFPhoneInput}
          label={t('phone')}
          control={control}
          name="phone"
          required
        />
      </Grid>
      <Grid item xs={6}>
        <TextInput
          label={t('email')}
          type="email"
          {...registerMui(register('email'))}
        />
      </Grid>
      <Grid item xs={12}>
        <TextInput label={t('website')} {...registerMui(register('website'))} />
      </Grid>
      <Grid item xs={12}>
        <TextInput label={t('address')} {...registerMui(register('address'))} />
      </Grid>
    </Grid>
  );
};

export default CompanyInfo;
