import {
  Box,
  Button,
  Grid,
  InputBase,
  makeStyles,
  Typography,
} from '@material-ui/core';
import React, { useEffect, useState } from 'react';

import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router-dom';
import registerMui from '../../util/react-hook-form-helper/register-mui';
import LoginFormContainer from 'src/components/login-form-container';
import CustomLink from '../../components/custom-link';
import clsx from 'clsx';
import { emailOrPhoneRegexp, passwordRegexp } from 'src/util/regex-pattern';
import useMessage from 'src/hooks/useMessage';
import authApi from 'src/api/auth';
import Prompt, { usePrompt } from 'src/components/prompt';
import { FaWarehouse } from 'react-icons/fa';
import useFirebasePhoneVerifier from 'src/util/firebase/usePhoneVerification';
import Spinner, { useSpinner } from 'src/components/spinner';
import CancelIcon from '@material-ui/icons/Cancel';
import InputError from 'src/components/input-error';
import PasswordInput from 'src/components/password-input';

export const useStyles = makeStyles((theme) => ({
  input: {
    background: 'white',
    border: `1px solid ${theme.palette.grey[300]}`,
    borderRadius: '44px',
    padding: '5px 20px',
    '& > div > svg': {
      fontSize: '24px',
      color: '#7D7D7D',
      marginRight: '15px',
    },
    '& input': {
      fontSize: '16px',
      paddingBottom: '6px',
      '&:read-only': {
        color: theme.palette.grey[600],
      },
    },
  },
  error: {
    border: `1px solid ${theme.palette.error.main}`,
  },
  button: {
    height: '43.11px',
    borderRadius: '44px',
  },
  text: {
    fontSize: '13px',
    textDecoration: 'none',
    fontWeight: 'bold',
  },
}));

const STEP = {
  REGISTER_FORM: 'register-form',
  SENT_OTP_CONFIRMATION: 'sent-otp-confirmation',
  INVALID_INVITE_LINK: 'invalid-invite-link',
};

const RegisterPage = () => {
  const { t } = useTranslation();
  const { register, handleSubmit, formState, reset, getValues, setValue } =
    useForm();
  const { showErrorResponseMessage, showErrorMessage } = useMessage();
  const { spinnerState, openSpinner, closeSpinner } = useSpinner({
    title: t('loading'),
  });
  const classes = useStyles();
  const history = useHistory();

  const {
    requestPhoneVerificationCode,
    confirmPhoneVerificationCode,
    resetRecaptcha,
  } = useFirebasePhoneVerifier({
    recaptchaId: 'recaptcha',
  });

  const { token: inviteToken } = useParams();
  const isInvite = !!inviteToken;

  useEffect(() => {
    const fetchTokenInfo = async () => {
      try {
        const response = await authApi.getInviteTokenInfo(inviteToken);
        const { warehouse, emailOrPhone } = response.data?.data || {};
        const resetData = {
          warehouseName: warehouse.name,
          emailOrPhone,
        };
        reset(resetData);
      } catch (error) {
        setStep({ state: STEP.INVALID_INVITE_LINK, data: null });
        showErrorResponseMessage(error);
      } finally {
        closeSpinner();
      }
    };

    openSpinner();
    if (inviteToken) {
      fetchTokenInfo();
    } else {
      closeSpinner();
    }
  }, [inviteToken]);

  const [step, setStep] = useState({ state: STEP.REGISTER_FORM, data: null });
  const { promptState, openPrompt, closePrompt } = usePrompt();

  const onSubmit = async (formData) => {
    try {
      openSpinner();
      delete formData.confirmPassword;
      let verifyCode = formData.verifyCode;
      if (step.data.type === 'phone') {
        const result = await confirmPhoneVerificationCode(verifyCode);
        verifyCode = result.idToken;
      }
      if (isInvite) {
        formData.inviteToken = inviteToken;
      }
      await authApi.register({ ...formData, verifyCode });
      history.push('/dashboard');
    } catch (error) {
      if (error.code === 'auth/invalid-verification-code') {
        showErrorMessage(t('invalid-verification-code'));
      } else {
        showErrorResponseMessage(error);
      }
    } finally {
      closeSpinner();
    }
  };

  const checkUserAvailability = async (formData) => {
    try {
      const { emailOrPhone } = formData;
      const response = await authApi.checkEmailOrPhoneAvailable({
        emailOrPhone,
        available: !isInvite,
      });
      const otp = {
        type: response.data?.data?.type,
        value: response.data?.data?.value,
      };
      openPrompt({
        title: t('otp-send-title', { type: t(otp.type) }),
        message: t('otp-send-message', { value: otp.value }),
        buttons: [
          {
            text: t('send'),
            onClick: async () => {
              try {
                openSpinner();
                await handleSendOtp(otp);
                setStep({ state: STEP.SENT_OTP_CONFIRMATION, data: otp });
              } catch (error) {
                console.log(error);
                showErrorMessage(t('register-failed'));
              } finally {
                closePrompt();
                closeSpinner();
              }
            },
          },
          { text: t('cancel'), onClick: () => closePrompt() },
        ],
      });
    } catch (error) {
      showErrorResponseMessage(error);
    }
  };

  const handleClickNoReceivedOtp = () => {
    openPrompt({
      title: t('otp-not-received'),
      message: '',
      buttons: [
        {
          text: t('resend-otp'),
          onClick: async () => {
            try {
              openSpinner();
              await handleSendOtp(step.data);
              setValue('verifyCode', '');
            } catch (error) {
              console.log(error);
              showErrorMessage(t('resend-failed'));
            } finally {
              closePrompt();
              closeSpinner();
            }
          },
        },
        ...(!isInvite
          ? [
              {
                text: t('use-otp-other-type', {
                  type: step.data?.type === 'phone' ? t('email') : t('phone'),
                }),
                onClick: () => {
                  const values = getValues();
                  values.emailOrPhone = '';
                  setStep({ state: STEP.REGISTER_FORM, data: null });
                  reset(values);
                  closePrompt();
                },
              },
            ]
          : []),
      ],
    });
  };

  const handleSendOtp = async (data) => {
    if (data.type === 'phone') {
      const phoneNumber = data.value;
      await requestPhoneVerificationCode(phoneNumber);
    } else if (data.type === 'email') {
      const email = data.value;
      await authApi.requestEmailOTP(email, false);
    }
  };

  const goBackToLogin = () => {
    history.push('/login');
  };

  return (
    <form
      onSubmit={handleSubmit(
        step.state === STEP.REGISTER_FORM ? checkUserAvailability : onSubmit
      )}
    >
      <LoginFormContainer>
        <div id='recaptcha'></div>
        <Grid container spacing={2} justify='center'>
          {step.state === STEP.REGISTER_FORM && (
            <>
              <Grid item xs={12}>
                <Typography
                  color='primary'
                  variant='h6'
                  style={{ textTransform: 'initial' }}
                >
                  {t('register')}
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <div
                  className={clsx(classes.input, {
                    [classes.error]: formState.errors.warehouseNaem,
                  })}
                >
                  <InputBase
                    placeholder={t('warehouse-name')}
                    fullWidth
                    inputProps={{ readOnly: isInvite }}
                    autoFocus
                    startAdornment={<FaWarehouse />}
                    {...registerMui(
                      register('warehouseName', { required: true })
                    )}
                  />
                </div>
              </Grid>
              <Grid item xs={12}>
                <Grid container spacing={1}>
                  <Grid item xs={6}>
                    <div
                      className={clsx(classes.input, {
                        [classes.error]: formState.errors.firstname,
                      })}
                    >
                      <InputBase
                        placeholder={t('firstname')}
                        fullWidth
                        autoFocus
                        {...registerMui(
                          register('firstname', { required: true })
                        )}
                      />
                    </div>
                  </Grid>
                  <Grid item xs={6}>
                    <div className={classes.input}>
                      <InputBase
                        placeholder={t('lastname')}
                        fullWidth
                        {...registerMui(register('lastname'))}
                      />
                    </div>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12}>
                <div
                  className={clsx(classes.input, {
                    [classes.error]: formState.errors.emailOrPhone,
                  })}
                >
                  <InputBase
                    placeholder={t('email-or-phone')}
                    fullWidth
                    autoComplete='off'
                    inputProps={{ readOnly: isInvite }}
                    {...registerMui(
                      register('emailOrPhone', {
                        required: true,
                        pattern: emailOrPhoneRegexp,
                      })
                    )}
                  />
                </div>
              </Grid>
              <Grid item xs={12}>
                <div
                  className={clsx(classes.input, {
                    [classes.error]: formState.errors.password,
                  })}
                >
                  <PasswordInput
                    placeholder={t('password')}
                    fullWidth
                    autoComplete='new-password'
                    {...registerMui(
                      register('password', {
                        required: true,
                        pattern: {
                          value: passwordRegexp,
                          message: t('required-strong-password'),
                        },
                      })
                    )}
                    customEndAdornment={
                      <InputError error={formState.errors.password} />
                    }
                  />
                </div>
              </Grid>
              <Grid item xs={12}>
                <div
                  className={clsx(classes.input, {
                    [classes.error]: formState.errors.confirmPassword,
                  })}
                >
                  <PasswordInput
                    placeholder={t('confirm-password')}
                    fullWidth
                    autoComplete='new-password'
                    {...registerMui(
                      register('confirmPassword', {
                        required: true,
                        validate: (value) => {
                          return value === getValues('password');
                        },
                      })
                    )}
                  />
                </div>
              </Grid>
              <Grid item xs={12}>
                <Box mt={1}>
                  <Button
                    id='sign-in-button'
                    disableElevation
                    className={classes.button}
                    variant='contained'
                    fullWidth
                    color='primary'
                    type='submit'
                    // onClick={handleSubmit(checkUserAvailability)}
                  >
                    {t('register')}
                  </Button>
                </Box>
                <Box mt={1} textAlign='center'>
                  <CustomLink to='/login'>
                    {t('already-have-account')}
                  </CustomLink>
                </Box>
              </Grid>
            </>
          )}
          {step.state === STEP.SENT_OTP_CONFIRMATION && (
            <>
              <Grid item xs={12}>
                <Typography
                  gutterBottom
                  color='primary'
                  variant='h6'
                  style={{ textTransform: 'initial' }}
                >
                  {t('otp-sent-title')}
                </Typography>
                <Typography variant='body2'>
                  {t('otp-sent-message', { value: step.data?.value })}
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <div
                  className={clsx(classes.input, {
                    [classes.error]: formState.errors.verifyCode,
                  })}
                >
                  <InputBase
                    inputProps={{ style: { textAlign: 'center' } }}
                    placeholder={t('verification-code')}
                    fullWidth
                    type='number'
                    autoComplete='off'
                    {...registerMui(
                      register('verifyCode', {
                        required: true,
                        minLength: 6,
                        maxLength: 6,
                      })
                    )}
                  />
                </div>
                <Box mt={1} textAlign='right'>
                  <CustomLink
                    onClick={handleClickNoReceivedOtp}
                    component='span'
                  >
                    {t('otp-not-received')}
                  </CustomLink>
                </Box>
              </Grid>
              <Grid item xs={6}>
                <Box mt={1}>
                  <Button
                    disableElevation
                    className={classes.button}
                    variant='contained'
                    fullWidth
                    onClick={() => {
                      resetRecaptcha();
                      setValue('verifyCode', '');
                      setStep({ state: STEP.REGISTER_FORM, data: null });
                    }}
                  >
                    {t('cancel')}
                  </Button>
                </Box>
              </Grid>
              <Grid item xs={6}>
                <Box mt={1}>
                  <Button
                    disableElevation
                    className={classes.button}
                    variant='contained'
                    fullWidth
                    color='primary'
                    onClick={handleSubmit(onSubmit)}
                  >
                    {t('next')}
                  </Button>
                </Box>
              </Grid>
            </>
          )}
          {step.state === STEP.INVALID_INVITE_LINK && (
            <>
              <Grid item xs={12}>
                <Typography
                  gutterBottom
                  color='secondary'
                  variant='h6'
                  style={{
                    display: 'inline-flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    textTransform: 'initial',
                  }}
                >
                  <CancelIcon style={{ fontSize: '5rem' }} color='secondary' />
                  {t('invalid-invite-link')}
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Box mt={1}>
                  <Button
                    disableElevation
                    className={classes.button}
                    variant='contained'
                    fullWidth
                    color='primary'
                    onClick={goBackToLogin}
                  >
                    {t('back-to-login')}
                  </Button>
                </Box>
              </Grid>
            </>
          )}
        </Grid>
        <Prompt state={promptState} />
        <Spinner state={spinnerState} />
      </LoginFormContainer>
    </form>
  );
};

export default RegisterPage;
