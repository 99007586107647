import { TableCell } from '@material-ui/core';
import React from 'react';
import { useFormContext, useWatch, useFieldArray } from 'react-hook-form';
import QtyInput from './qty-input';
import StorageInput from './storage-input';

const StorageSelector = ({ nestedIndex, qty, stockId }) => {
  const { control } = useFormContext();
  useFieldArray({
    control,
    name: `product.${nestedIndex}.storages`,
  });

  const storage = useWatch({
    control,
    name: `product.${nestedIndex}.storages`,
    defaultValue: [{ qty, storage: null }],
  });

  return (
    <>
      <TableCell>
        <QtyInput nestedIndex={nestedIndex} qty={qty} storage={storage} />
      </TableCell>
      <TableCell>
        <StorageInput
          nestedIndex={nestedIndex}
          qty={qty}
          storage={storage}
          stockId={stockId}
        />
      </TableCell>
    </>
  );
};

export default StorageSelector;
