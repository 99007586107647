import Head from './head';
import Container from './container';
import CellValue from './cell-value';
import Image from './image';

const CustomTable = { Head, Container, CellValue, Image };

export default CustomTable;

// custom table for BabyCo
