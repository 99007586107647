import { Grid, makeStyles } from '@material-ui/core';
import React from 'react';
import AddressItem from './address-item';

const useStyles = makeStyles(() => ({
  root: {
    maxHeight: '500px',
    overflowY: 'auto',
    marginBottom: '10px',
  },
}));

const AddressList = ({ data, onChange, selectedShippingId }) => {
  const classes = useStyles();
  return (
    <Grid container spacing={2} className={classes.root}>
      {data?.map((item, index) => (
        <Grid item key={index} xs={12}>
          <AddressItem
            onChange={onChange}
            disabledSelect={selectedShippingId === item._id}
            data={item}
          />
        </Grid>
      ))}
    </Grid>
  );
};

export default AddressList;
