import { createMuiTheme, responsiveFontSizes } from '@material-ui/core';

const primary = {
  main: '#0B4668',
  light: '#006D8A',
  lighter: '#0b466814',
};

const theme = createMuiTheme();

let themeConfig = createMuiTheme({
  palette: {
    primary,
    blue: {
      main: '#1869D2',
    },
  },
  typography: {
    fontFamily: [
      'Poppins',
      'KhmerOS Siemreap',
      'Helvetica Neue',
      'Arial',
      'sans-serif',
    ].join(','),
  },
  overrides: {
    MuiPaper: {
      elevation1: {
        boxShadow:
          '2px 3px 4px 0px rgb(0 0 0 / 7%), 0px 0px 0px 0px rgb(0 0 0 / 14%), 0px 0px 0px 0px rgb(0 0 0 / 10%)',
      },
      elevation2: {
        boxShadow:
          '1px 2px 4px 0px rgb(0 0 0 / 10%), -1px 0px 4px 0px rgb(0 0 0 / 5%), 0px 0px 0px 0px rgb(0 0 0 / 10%)',
      },
    },
    MuiButton: {
      root: {
        textTransform: 'none',
        '& >span> svg': {
          marginRight: '5px',
        },
        [theme.breakpoints.down('xs')]: {
          fontSize: '12px',
        },
      },
    },
    MuiTableCell: {
      root: {
        padding: '5px',
      },
    },
  },
});

themeConfig = responsiveFontSizes(themeConfig);

themeConfig.typography.body1 = {
  [themeConfig.breakpoints.down('xs')]: {
    fontSize: '12px',
  },
};

export default themeConfig;
