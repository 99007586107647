import { Avatar, IconButton, makeStyles } from '@material-ui/core';
import ClearIcon from '@material-ui/icons/Clear';
import React from 'react';

const useStyles = makeStyles(() => ({
  container: {
    position: 'relative',
    display: 'flex',
    margin: '0 5px',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100%',
  },
  image: {
    width: '90%',
    height: '100%',
    '& img': {
      objectFit: 'contain',
    },
  },
  button: {
    position: 'absolute',
    top: 0,
    right: 0,
    marginTop: '-5px !important',
    marginRight: '-5px !important',
    zIndex: 1,
    color: 'white',
    backgroundColor: 'rgb(243 111 111 / 80%)',
    '&:hover': {
      backgroundColor: 'rgb(243 111 111 / 90%)',
    },
    '&:active': {
      backgroundColor: 'rgb(243 111 111 )',
    },
    '& svg': {
      fontSize: '15px',
    },
  },
}));

const ImageSlide = ({ image, onRemove }) => {
  const classes = useStyles();
  return (
    <div className={classes.container}>
      <IconButton size="small" onClick={onRemove} className={classes.button}>
        <ClearIcon />
      </IconButton>
      <Avatar
        className={classes.image}
        variant="rounded"
        src={
          image instanceof File ? URL.createObjectURL(image) : image?.thumbnail
        }
      ></Avatar>
    </div>
  );
};

export default ImageSlide;
