import React, { useContext } from 'react';
import { TableRow, TableCell, TableBody } from '@material-ui/core';
import { useFieldArray, useFormContext } from 'react-hook-form';
import { UtilityContext } from '../../../../components/context-provider/utilty-context';

import plusTax from '../../../../util/plusTax';
import CustomTable from '../../../../components/custom-table';
import CustomRemoveIconButton from '../../../../components/custom-button/remove-icon-button';
import {
  RHFDateInput,
  RHFNumberInput,
} from '../../../../components/rhf-controlled-input';
import VariantInput from './variant-input';
import calculateDiscount from '../../../../util/calculate-discount';
import StorageInput from './storage-input';

const Products = () => {
  // form context
  const { watch, register, control } = useFormContext();
  // utility context
  const { showDeletePrompt, closeDeletePrompt } = useContext(UtilityContext);

  const type = watch('type');

  // filedArray product
  const { fields, append, remove } = useFieldArray({
    control,
    name: 'product',
  });

  // add row item
  const handleAddField = (index) => {
    if (index === fields.length - 1) {
      append({ qty: 1, tax: 10 }, { shouldFocus: false });
    }
  };

  // remove row item prompt
  const handleRemove = (index, id) => {
    showDeletePrompt({
      handleConfirm: () => onDelete(index, id),
    });
  };

  // on remove confirm
  const onDelete = (index) => {
    remove(index);
    closeDeletePrompt();
  };

  const tableHeadData = [
    { value: 'no.', width: '20px' },
    { value: 'image', width: '80px' },
    { value: 'product-name' },
    { value: 'quantity', width: '15%' },
    ...(type !== 'remove'
      ? [
          { value: 'cost-price', width: '15%' },
          { value: 'expiry-date', width: '15%' },
        ]
      : []),
    { value: 'storage', width: '15%' },
    { width: '50px' },
  ];

  return (
    <CustomTable.Container>
      {/* table header */}
      <CustomTable.Head data={tableHeadData} />
      {/* table body */}
      <TableBody>
        {fields.map((field, index) => {
          // get value from field
          const get = getValue(field);

          // store salePrice
          register(`product.${index}.salePrice`, {
            value: get.salePrice,
          });

          return (
            <TableRow key={field.id}>
              <TableCell>
                <CustomTable.CellValue>{index + 1}</CustomTable.CellValue>
              </TableCell>
              <TableCell>
                <CustomTable.Image src={get.image} />
              </TableCell>
              <TableCell>
                <VariantInput
                  index={index}
                  defaultValue={get.variant}
                  rules={fields.length > 0}
                  handleAddField={handleAddField}
                />
              </TableCell>
              <TableCell>
                <RHFNumberInput
                  name={`product.${index}.qty`}
                  defaultValue={get.receivedQty}
                  textAlign='right'
                  decimalValue={false}
                  min={1}
                  required={!!get.variantName}
                />
              </TableCell>

              {type !== 'remove' && (
                <>
                  <TableCell>
                    <RHFNumberInput
                      name={`product.${index}.costPrice`}
                      textAlign='right'
                      defaultValue={get.costPrice}
                      required={!!get.variantName}
                      min={0}
                    />
                  </TableCell>
                  <TableCell>
                    <RHFDateInput name={`product.${index}.expiryDate`} />
                  </TableCell>
                </>
              )}
              <TableCell>
                <StorageInput index={index} defaultValue={get.storage} />
              </TableCell>
              <TableCell>
                <CustomTable.CellValue component='div'>
                  <CustomRemoveIconButton
                    invisible={
                      index === fields.length - 1 || fields.length <= 1
                    }
                    onClick={() => handleRemove(index, field._id)}
                  />
                </CustomTable.CellValue>
              </TableCell>
            </TableRow>
          );
        })}
      </TableBody>
    </CustomTable.Container>
  );
};

// function to get product value
export const getValue = (data) => {
  const variantName = data.variant?.name ?? '';
  const image =
    data?.variant?.id?.images?.[0]?.thumbnail ||
    data?.variant?.stock?.variant?.image?.thumbnail ||
    data?.variant?.variant?.image?.thumbnail;
  const tax = data.tax ?? 0;
  const costPrice = data.costPrice ?? 0;
  const salePrice = data.salePrice ?? 0;
  const qty = data.qty ?? 1;
  const receivedQty = data.receivedQty ?? 1;
  const discount = data.discount ?? { amount: 0, type: 'percentage' };
  const totalAmount = calculateDiscount(
    receivedQty * Number(costPrice),
    discount
  );
  const totalAmountWithTax = plusTax(totalAmount, tax);
  const variant = data.variant ?? null;
  const storage = data.storage ?? null;

  return {
    variant,
    variantName,
    image,
    tax,
    costPrice,
    salePrice,
    qty,
    receivedQty,
    totalAmount,
    totalAmountWithTax,
    discount,
    storage,
  };
};

export default Products;
