import { TableCell, TableRow, TableHead } from '@material-ui/core';
import React from 'react';

const TableHeadX = ({ schema }) => {
  return (
    <TableHead>
      <TableRow>
        {schema.map((item) => (
          <TableCell key={item.id} style={item.style}>
            {item.label}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
};

export default TableHeadX;
