import React from 'react';
import { Autocomplete } from '@material-ui/lab';
import {
  TextField,
  FormControl,
  FormLabel,
  makeStyles,
} from '@material-ui/core';
import clsx from 'clsx';

const useStyles = makeStyles(() => ({
  label: {
    color: 'black',
    fontWeight: 'bold',
    marginBottom: '10px',
    fontSize: '14px',
    textTransform: 'uppercase',
  },
}));
const TextInputTag = React.forwardRef(
  ({ label, disabled, required, disableChipRemove, ...other }, ref) => {
    const classes = useStyles();
    const chipProps = { color: 'primary' };
    if (disableChipRemove) {
      chipProps.onDelete = null;
    }
    return (
      <FormControl fullWidth>
        <FormLabel className={clsx(classes.label, 'required')}>
          {label}
        </FormLabel>
        <Autocomplete
          multiple
          freeSolo
          id='size-small-standard-multi'
          size='small'
          color='primary'
          disabled={disabled}
          options={[]}
          ChipProps={chipProps}
          getOptionLabel={(option) => option}
          ref={ref}
          {...other}
          onChange={(e, data) => {
            other.onChange(data);
          }}
          onBlur={(e) => {
            // when blur value that type without type enter will auto enter
            try {
              const currentValue = e.target.value.trim();
              if (currentValue.length > 0) {
                other.onChange([...other.value, currentValue]);
              }
            } catch (err) {
              console.error(err);
              return;
            }
          }}
          renderInput={(params) => (
            <TextField
              required={other.value.length === 0}
              {...params}
              variant='outlined'
              size='small'
            />
          )}
        />
      </FormControl>
    );
  }
);

export default TextInputTag;
