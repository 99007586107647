import { Grid, Typography, Box } from '@material-ui/core';
import React from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import SaleBySelector from 'src/components/sale-by-selector';

const SaleBy = () => {
  const { t } = useTranslation();
  const { control } = useFormContext();

  return (
    <Box mt={1}>
      <Grid container justify="flex-end">
        <Grid item xs={3}>
          <Controller
            control={control}
            name="saleBy"
            defaultValue={null}
            render={({ field: { value, onChange, ref } }) => {
              return (
                <Grid container alignItems="center" spacing={1}>
                  <Grid item xs="auto">
                    <Typography>{t('sale-by')}</Typography>
                  </Grid>
                  <Grid item xs>
                    <SaleBySelector
                      label={t('sale-by')}
                      value={value}
                      onChange={(_, data) => {
                        if (data) {
                          const saleBy = {
                            id: data._id,
                            name: data.user.fullname,
                          };
                          onChange(saleBy);
                        }
                      }}
                      inputRef={ref}
                    />
                  </Grid>
                </Grid>
              );
            }}
          ></Controller>
        </Grid>
      </Grid>
    </Box>
  );
};

export default SaleBy;
