const TADA_DELIVERY_STATUS_EN = {
  ORDER_CREATED: 'Order Created',
  WAITING_PICKUP: 'Waiting Pickup',
  TO_WAREHOUSE: 'To Warehouse',
  AT_WAREHOUSE: 'At Warehouse',
  ON_DELIVERY: 'On Delivery',
  FAILED: 'Failed',
  DELIVERED: 'Delivered',
  ON_RETURN: 'On Returned',
  RETURNED: 'Returned',
  MISSING: 'Missing',
  DROPPED: 'Dropped',
};

export default TADA_DELIVERY_STATUS_EN;
