import { IconButton, InputBase } from '@material-ui/core';
import { useState } from 'react';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';

const PasswordInput = ({ customEndAdornment, ...props }) => {
  const [showPassword, setShowPassword] = useState(false);
  const handleShowPassword = () => setShowPassword((prev) => !prev);

  return (
    <InputBase
      type={showPassword ? 'text' : 'password'}
      endAdornment={
        <>
          {customEndAdornment}
          <IconButton tabIndex={-1} size='small' onClick={handleShowPassword}>
            {showPassword ? <Visibility /> : <VisibilityOff />}
          </IconButton>
        </>
      }
      {...props}
    />
  );
};

export default PasswordInput;
