import {
  Button,
  Grid,
  Typography,
  makeStyles,
  IconButton,
} from '@material-ui/core';
import React, { useState } from 'react';
import SelectorPopup from './selector-popup';
import { Controller, useFormContext } from 'react-hook-form';
import Address from './address';
import EditIcon from '@material-ui/icons/Edit';

const useStyles = makeStyles((theme) => ({
  title: {
    fontWeight: 'bold',
    fontSize: '18px',
    [theme.breakpoints.down('xs')]: {
      fontSize: '14px',
    },
    color: theme.palette.primary.main,
    textTransform: 'uppercase',
    // marginBottom: "28px",
  },
}));

const AddressSelector = () => {
  const { watch, control } = useFormContext();
  const customer = watch('customer', {});
  const shippingInfo = watch('shippingInfo', {});
  const classes = useStyles();
  const [selector, setSelector] = useState(false);
  const [state, setState] = useState('list');

  const handleCloseSelector = () => {
    setSelector(false);
  };

  const handleOpenSelector = () => {
    setSelector(true);
    setState('list');
  };

  const handleEditShipping = () => {
    setSelector(true);
    setState('edit');
  };

  return (
    <Grid
      container
      style={{ height: '100%' }}
      className='container'
      direction='column'
    >
      <Grid item container justify='space-between' alignItems='flex-start'>
        <Typography className={classes.title}>Shipping Information</Typography>
        <IconButton onClick={handleEditShipping} color='primary' size='small'>
          <EditIcon fontSize='small' />
        </IconButton>
      </Grid>
      <Grid item container justify='center' alignItems='center' xs>
        <Address data={shippingInfo} />
      </Grid>
      {customer?._id && (
        <Grid item container justify='flex-end'>
          <Button
            hidden
            color='primary'
            size='small'
            onClick={handleOpenSelector}
            variant='contained'
          >
            Change Address
          </Button>
        </Grid>
      )}
      <Controller
        name='shippingInfo'
        control={control}
        defaultValue={null}
        render={() => {
          return null;
        }}
      ></Controller>
      <SelectorPopup
        open={selector}
        state={state}
        setState={setState}
        onClose={handleCloseSelector}
        data={customer}
        customerId={customer?._id}
      />
    </Grid>
  );
};

export default AddressSelector;
