import React from 'react';
import DialogContainer from '../../../../components/dialog-container';
import { useTranslation } from 'react-i18next';
import { Controller, useFormContext } from 'react-hook-form';
import { Grid } from '@material-ui/core';
import InputSelect from '../../../../components/input-select';

const PaymentMethodSelector = ({ open, onClose, handleCreate, state }) => {
  const { t } = useTranslation();
  const { control } = useFormContext();
  return (
    <DialogContainer
      state='custom'
      customButtonText={state === 'edit' ? t('save-changes') : t('create')}
      open={open}
      onAdd={handleCreate}
      onClose={onClose}
      onCancel={onClose}
      title={t('select-payment-methods')}
    >
      <Grid container>
        <Grid item xs={12}>
          <Controller
            name='paymentMethod'
            control={control}
            defaultValue={'cash-on-delivery'}
            render={({ field: { value, onChange } }) => {
              return (
                <InputSelect
                  value={value}
                  onChange={onChange}
                  label={t('payment-methods')}
                  menu={[
                    { label: t('cash-on-delivery'), value: 'cash-on-delivery' },
                    { label: t('cash-transfer'), value: 'cash-transfer' },
                    { label: t('bank-transfer'), value: 'bank-transfer' },
                  ]}
                  required
                />
              );
            }}
          ></Controller>
        </Grid>
      </Grid>
    </DialogContainer>
  );
};

export default PaymentMethodSelector;
