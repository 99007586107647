import React, { useContext } from 'react';
import { Grid } from '@material-ui/core';
import { useForm, FormProvider } from 'react-hook-form';
import Products from './products';

import { useTranslation } from 'react-i18next';
import DialogContainer from '../../../../components/dialog-container';
import { formHandler } from '../../../../util/react-hook-form-helper';
import { UserContext } from 'src/components/context-provider/user-context';

const ReturnedForm = ({ open, onClose, items, onSubmit }) => {
  const { t } = useTranslation();
  const { setting } = useContext(UserContext);

  const methods = useForm({
    defaultValues: {
      product: [],
    },
  });

  const onEntering = () => {
    methods.reset({ product: items });
  };

  return setting?.order?.trackStock ? (
    <DialogContainer
      open={open}
      title={t('returned-order')}
      onCancel={onClose}
      maxWidth='md'
      TransitionProps={{ onEntering }}
      formId='sale-return-form'
      state='custom'
      noPadding={true}
      customButtonText={t('confirm')}
    >
      <FormProvider {...methods}>
        <form
          id='sale-return-form'
          onSubmit={(e) => formHandler(e, methods.handleSubmit(onSubmit))}
        >
          <Grid container>
            <Grid item xs={12}>
              <Products />
            </Grid>
          </Grid>
        </form>
      </FormProvider>
    </DialogContainer>
  ) : null;
};

export default ReturnedForm;
