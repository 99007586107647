import { Box, FormControl, Grid, MenuItem, Select } from "@material-ui/core";
import React, { useState, useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
import DialogContainer from "../../../../../components/dialog-container";
import Barcode from "react-barcode";
import { useReactToPrint } from "react-to-print";
import BarcodeContainer from "./barcode-container";
import { useFormContext } from "react-hook-form";
import { Typography } from "@material-ui/core";

const PrintBarcode = ({ open, onClose, active }) => {
  const { t } = useTranslation();
  const [type, setType] = useState(null);
  const [value, setValue] = useState(null);
  const { getValues } = useFormContext();

  useEffect(() => {
    if (open) {
      const data = getValues();
      if (data.createAttributes) {
        const value = data.variants[active];
        if (value.altCode) {
          setType("altCode");
        } else {
          setType("sku");
        }
        setValue({
          ...value,
          sku: value.sku,
          altCode: value.altCode,
          ageVariation: data.ageVariation,
        });
      } else {
        if (data.altCode) {
          setType("altCode");
        } else {
          setType("sku");
        }
        setValue({
          ...data,
          sku: data.sku,
          altCode: data.altCode,
          ageVariation: data.ageVariation,
        });
      }
    }
  }, [open]);

  // let barcodeInfo = null;
  // if (value) {
  //   if (hasVariant && type in value?.variants[active]) {
  //     barcodeInfo = {
  //       value: value?.variants[active][type],
  //       data: {
  //         ...value?.variants[active],
  //         ageVariation: value.ageVariation,
  //       },
  //     };
  //   } else {
  //     barcodeInfo = {
  //       value: value[type],
  //       data: value,
  //     };
  //   }
  // }

  const componentRef = useRef();
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
    pageStyle: `@page {
        size: auto;
        margin:0;
      }`,
  });

  return (
    <DialogContainer
      open={open}
      onClose={onClose}
      title={t("barcode")}
      onCancel={onClose}
      onAdd={handlePrint}
      state="custom"
      customButtonText={t("print")}
      disableConfirmButton={!value?.[type]}
    >
      <Grid container spacing={2} justify="center">
        <Grid item xs={12} container justify="center">
          <FormControl variant="outlined" size="small">
            <Select value={type} onChange={(e) => setType(e.target.value)}>
              <MenuItem value="sku">SKU</MenuItem>
              <MenuItem value="altCode">{t("upc")}</MenuItem>
            </Select>
          </FormControl>
        </Grid>
        {
          <>
            <Grid item xs={12} container justify="center">
              {open && value?.[type] ? (
                <Barcode height={40} value={value?.[type]} />
              ) : (
                <Box
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                  style={{ height: "81px" }}
                >
                  <Typography variant="h6">Unavailable</Typography>
                </Box>
              )}
            </Grid>
          </>
        }
        <Grid item xs={12}>
          <Box marginBottom={2} />
        </Grid>
      </Grid>
      <div className="hidden">
        {value?.[type] !== "" && (
          <BarcodeContainer ref={componentRef} barcode={value} type={type} />
        )}
      </div>
    </DialogContainer>
  );
};

export default PrintBarcode;
