import React from 'react';
import { TableRow, TableCell, TableBody } from '@material-ui/core';
import { useFieldArray, useFormContext } from 'react-hook-form';

import StorageSelector from './storageSelector';
import CustomTable from '../../../../../../components/custom-table';
import CustomNumberFormat from '../../../../../../components/number-format';
import displayProductName from 'src/util/display-product-name';

const tableHeadData = [
  { value: 'no.', width: '5px' },
  { value: 'image', width: '80px' },
  { value: 'product-name' },
  { value: 'to-pick', width: '15%' },
  { value: 'quantity', width: '15%' },
  { value: 'storage', width: '20%' },
];

const PackingProducts = () => {
  // form context
  const { control } = useFormContext();

  // filedArray orderItems
  const { fields } = useFieldArray({
    control,
    name: 'product',
  });

  return (
    <CustomTable.Container>
      {/* table header */}
      <CustomTable.Head data={tableHeadData} />
      {/* table body */}
      <TableBody>
        {fields.map((field, index) => {
          // get value from field
          const get = getValue(field);
          return (
            <TableRow key={field.id}>
              <TableCell>
                <CustomTable.CellValue>{index + 1}</CustomTable.CellValue>
              </TableCell>
              <TableCell>
                <CustomTable.Image src={get.image} />
              </TableCell>
              <TableCell>
                <CustomTable.CellValue>
                  {displayProductName(get.productCode, get.productName)}
                </CustomTable.CellValue>
              </TableCell>
              <TableCell>
                <CustomTable.CellValue align='center'>
                  {
                    <CustomNumberFormat
                      value={get.orderQuantity}
                      format='normal'
                    />
                  }
                </CustomTable.CellValue>
              </TableCell>
              <StorageSelector
                nestedIndex={index}
                qty={field.qty}
                stockId={get.stockId}
              />
            </TableRow>
          );
        })}
      </TableBody>
    </CustomTable.Container>
  );
};

// function to get product value
export const getValue = (data) => {
  const stockId = data.variant?.stock?._id || data.variant?.stock;
  const productCode = data.variant?.sku || data.variant?.altCode;
  const productName = data.variant?.name ?? '';
  const image =
    data?.variant?.variant?.image?.thumbnail ||
    data?.variant?.stock?.variant?.image?.thumbnail;
  const orderQuantity = data.qty ?? 0;

  return {
    productCode,
    stockId,
    productName,
    image,
    orderQuantity,
  };
};

export default PackingProducts;
