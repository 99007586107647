import React, { useState, useContext } from 'react';
import TableX from '../../components/tableX';
import TitleBar from '../../components/title-bar';
import ActionButton from '../../components/action-button';
import { Grid, Paper, Typography } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import config from '../../components/tableX/config';
import SelectPopUp from '../../components/select-popup';
import { UtilityContext } from '../../components/context-provider/utilty-context';
import { UserContext } from '../../components/context-provider/user-context';
import reduceArrayString from '../../util/reduce-array-string';
import axios from 'axios';
import ProductImage from '../../components/product-image';

const ProductPage = () => {
  const { showDeletePrompt, closeDeletePrompt, showSnackbar } = useContext(UtilityContext);
  const { checkAccess } = useContext(UserContext);
  const { t } = useTranslation();
  const history = useHistory();
  const [triggerFetch, setTriggerFetch] = useState({});

  const handleEdit = (data, e) => {
    const location = `/dashboard/product/edit/${data._id}`;
    if (e?.ctrlKey) {
      window.open(location, '_blank');
    } else {
      history.push(location);
    }
  };

  const handleCreate = () => history.push('/dashboard/product/create');
  const handleDelete = data =>
    showDeletePrompt({
      handleConfirm: () => onDelete(data),
    });

  const onDelete = items => {
    const ids = reduceArrayString(items);
    axios
      .delete(`${process.env.REACT_APP_API}/api/v1/products?${ids}`)
      .then(({ data }) => {
        showSnackbar({ message: data?.message });
        setTriggerFetch(prev => !prev);
      })
      .catch(({ response }) => {
        showSnackbar({ message: response.data?.message, variant: 'error' });
      })
      .finally(() => closeDeletePrompt());
  };

  const tableSchema = [
    {
      id: 'image',
      width: '10%',
      label: t('image'),
      dataConfig: ['images', '0', 'thumbnail'],
      type: 'component',
      sort: false,
      components: [
        {
          name: ProductImage,
          customValueProp: 'src',
          props: { style: { margin: '0 auto' } },
        },
      ],
    },
    {
      id: 'name',
      width: '40%',
      align: 'left',
      label: t('product-name'),
      type: 'component',
      dataConfig: ['name'],
      components: name => {
        return (
          <Typography className="ellipsis" align="left">
            {name}
          </Typography>
        );
      },
    },
    {
      id: 'category.name',
      width: '20%',
      dataConfig: [],
      label: t('category'),
      type: 'component',
      components: data => {
        return data?.category?.name ?? t('no-category');
      },
    },
    {
      id: 'action',
      width: '10%',
      sort: false,
      label: t('action'),
      type: 'component',
      components: [
        {
          name: ActionButton,
          props: {
            variant: 'edit',
            hidden: !checkAccess({ feature: 'products', action: 'update' }),
          },
          onClick: handleEdit,
        },
        {
          name: ActionButton,
          props: {
            variant: 'delete',
            hidden: !checkAccess({ feature: 'products', action: 'delete' }),
          },
          onClick: handleDelete,
        },
      ],
    },
  ];

  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <TitleBar
          onClick={handleCreate}
          buttonText={t('create-product')}
          disableButton={!checkAccess({ feature: 'products', action: 'create' })}
        ></TitleBar>
      </Grid>
      <Grid item xs={12}>
        <Paper className="tableX-container">
          <TableX
            triggerFetch={triggerFetch}
            config={tableConfig}
            schema={tableSchema}
            onRowClick={handleEdit}
            render={data => {
              return (
                <SelectPopUp
                  open={data.length}
                  select={data}
                  handleDelete={() => handleDelete(data)}
                />
              );
            }}
            // renderFilterInput={(setFilter) => {
            //   return (
            //     <Grid
            //       container
            //       style={{ maxWidth: "500px" }}
            //       spacing={2}
            //       wrap="nowrap"
            //     >
            //       <Grid item xs>
            //         <TextInputListSearch
            //           dataConfig={["category"]}
            //           optionsURL={`${process.env.REACT_APP_API}/api/v1/categories`}
            //           getOptionLabel={(option) => option.name}
            //           getOptionSelected={(option, value) =>
            //             option.name === value.name
            //           }
            //           onChange={(e, data) =>
            //             setFilter((prev) => {
            //               return { ...prev, category: data.name };
            //             })
            //           }
            //         />
            //       </Grid>
            //       <Grid item xs>
            //         <TextInputListSearch
            //           dataConfig={["suppliers"]}
            //           optionsURL={`${process.env.REACT_APP_API}/api/v1/suppliers/all`}
            //           getOptionLabel={(option) => option.companyName}
            //           getOptionSelected={(option, value) =>
            //             option.name === value.companyName
            //           }
            //           onChange={(e, data) =>
            //             setFilter((prev) => {
            //               return { ...prev, suppliers: data.companyName };
            //             })
            //           }
            //         />
            //       </Grid>
            //     </Grid>
            //   );
            // }}
          ></TableX>
        </Paper>
      </Grid>
    </Grid>
  );
};

const tableConfig = {
  ...config,
  dataConfig: ['products'],
  url: `${process.env.REACT_APP_API}/api/v1/products`,
};

export default ProductPage;
