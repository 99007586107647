import React, { useState } from 'react';
import DialogContainer from '../../../../../components/dialog-container';
import { useTranslation } from 'react-i18next';
import {
  formHandler,
  registerMui,
} from '../../../../../util/react-hook-form-helper';
import { Controller, useForm } from 'react-hook-form';
import { Grid } from '@material-ui/core';
import TextInput from '../../../../../components/text-input';
import InputSelect from '../../../../../components/input-select';
import axios from 'axios';
import { useContext } from 'react';
import { UtilityContext } from '../../../../../components/context-provider/utilty-context';
import BackDropSpinner from '../../../../../components/backdrop-spinner';
import { UserContext } from '../../../../../components/context-provider/user-context';

const ShippingForm = ({ open, onClose, orderId, onSuccess, handlePrompt }) => {
  const { t } = useTranslation();
  const { register, handleSubmit, control, reset } = useForm();
  const { showSnackbar } = useContext(UtilityContext);
  const { deliveryInfo } = useContext(UserContext);
  const [loading, setLoading] = useState(false);

  const onSubmit = (data) => {
    setLoading(true);
    axios
      .post(
        `${process.env.REACT_APP_API}/api/v1/orders/delivery/${orderId}`,
        data
      )
      .then(({ data }) => {
        showSnackbar({ message: data?.message });
        onSuccess && onSuccess(data.order);
        onClose();
        reset({});
      })
      .catch(({ response }) => {
        showSnackbar({
          message: response?.data?.message,
          variant: 'error',
        });
      })
      .finally(() => setLoading(false));
  };

  return (
    <DialogContainer
      open={open}
      onClose={!loading && onClose}
      title={t('shipping-with-provider')}
      onCancel={onClose}
      formId='shipping-form'
      state='custom'
      customButtonText={t('confirm')}
      optionalButton={{
        text: t('skip'),
        props: {
          color: 'primary',
          style: { marginRight: 'auto' },
          onClick: () => {
            handlePrompt('shipping');
          },
        },
      }}
    >
      <form
        id='shipping-form'
        onSubmit={(e) => formHandler(e, handleSubmit(onSubmit))}
      >
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Controller
              control={control}
              name='deliveryProvider'
              defaultValue={'tada'}
              render={({ field: { value, onChange } }) => {
                return (
                  <InputSelect
                    menu={deliveryInfo.data.map((item) => ({
                      value: item.name,
                      label: t(item.name),
                    }))}
                    label={t('delivery-provider')}
                    value={value}
                    onChange={onChange}
                  />
                );
              }}
            ></Controller>
          </Grid>
          <Grid item xs={12}>
            <Controller
              control={control}
              name='parcelSize'
              defaultValue={'SMALL'}
              render={({ field: { value, onChange } }) => {
                return (
                  <InputSelect
                    menu={[
                      { value: 'SMALL', label: 'SMALL' },
                      { value: 'MEDIUM', label: 'MEDIUM' },
                      { value: 'LARGE', label: 'LARGE' },
                      { value: 'EXTRA', label: 'EXTRA' },
                    ]}
                    label={t('parcel-size')}
                    value={value}
                    onChange={onChange}
                  />
                );
              }}
            ></Controller>
          </Grid>
          <Grid item xs={12}>
            <Controller
              control={control}
              name='isSenderPaying'
              defaultValue={true}
              render={({ field: { value, onChange } }) => {
                return (
                  <InputSelect
                    menu={[
                      { value: true, label: 'SENDER' },
                      { value: false, label: 'RECEIVER' },
                    ]}
                    label={t('who-pay')}
                    value={value}
                    onChange={onChange}
                  />
                );
              }}
            ></Controller>
          </Grid>
          <Grid item xs={12}>
            <TextInput
              label={t('instructions-to-driver')}
              multiline
              rows={4}
              {...registerMui(register('instructionToDriver'))}
            />
          </Grid>
          <BackDropSpinner open={loading} />
        </Grid>
      </form>
    </DialogContainer>
  );
};

export default ShippingForm;
