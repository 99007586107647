import { Grid, Paper } from '@material-ui/core';
import React, { useState, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import TableX from '../../components/tableX';
import TitleBar from '../../components/title-bar';
import SaleTargetForm from './form';
import config from '../../components/tableX/config';
import ActionButton from '../../components/action-button';
import SelectPopUp from '../../components/select-popup';
import { UtilityContext } from '../../components/context-provider/utilty-context';
import { UserContext } from '../../components/context-provider/user-context';
import reduceArrayString from '../../util/reduce-array-string';
import axios from 'axios';
import { isActiveToStatus } from './utils';
import Status from 'src/components/status';

const POSPage = () => {
  const { t } = useTranslation();
  const [posForm, setPosForm] = useState(false);
  const { showDeletePrompt, closeDeletePrompt, showSnackbar } =
    useContext(UtilityContext);
  const { checkAccess } = useContext(UserContext);
  // state for refresh table component
  const [triggerFetch, setTriggerFetch] = useState(false);
  // state for edit
  const [activeData, setActiveData] = useState(null);

  const handleOpenForm = () => {
    setActiveData(null);
    setPosForm(true);
  };
  const handleCloseForm = () => setPosForm(false);

  const handleEdit = (data) => {
    setActiveData(data);
    setPosForm(true);
  };

  const handleDelete = (data) =>
    showDeletePrompt({
      handleConfirm: () => onDelete(data),
    });

  const onDelete = (items) => {
    const ids = reduceArrayString(items);
    axios
      .delete(`${process.env.REACT_APP_API}/api/v1/pos?${ids}`)
      .then(({ data }) => {
        showSnackbar({ message: data?.message });
        setTriggerFetch((prev) => !prev);
      })
      .catch(({ response }) =>
        showSnackbar({ message: response?.data.message, variant: 'error' })
      )
      .finally(() => closeDeletePrompt());
  };

  const tableSchema = [
    {
      id: 'name',
      label: t('name'),
    },
    {
      id: 'isActive',
      label: t('status'),
      type: 'component',
      components: (isActive) => {
        return <Status status={isActiveToStatus(isActive)} />;
      },
    },
    {
      id: 'createdBy',
      label: t('created-by'),
      dataConfig: ['createdBy', 'name'],
    },
    {
      id: 'action',
      sort: false,
      label: t('action'),
      type: 'component',
      components: [
        {
          name: ActionButton,
          props: {
            variant: 'edit',
            hidden: !checkAccess({
              feature: 'sale-target',
              action: 'update',
            }),
          },
          onClick: handleEdit,
        },
        {
          name: ActionButton,
          props: {
            variant: 'delete',
            hidden: !checkAccess({
              feature: 'sale-target',
              action: 'delete',
            }),
          },
          onClick: handleDelete,
        },
      ],
    },
  ];

  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <TitleBar
          onClick={handleOpenForm}
          buttonText={t('create-pos')}
          disableButton={!checkAccess({ feature: 'pos', action: 'create' })}
        ></TitleBar>
      </Grid>
      <Grid item xs={12}>
        <Paper className='tableX-container'>
          <TableX
            onRowClick={handleEdit}
            triggerFetch={triggerFetch}
            config={tableConfig}
            render={(data) => {
              return (
                <SelectPopUp
                  open={data.length}
                  select={data}
                  handleDelete={() => handleDelete(data)}
                />
              );
            }}
            schema={tableSchema}
          ></TableX>
        </Paper>
      </Grid>
      <SaleTargetForm
        preData={activeData}
        setTriggerFetch={setTriggerFetch}
        open={posForm}
        onClose={handleCloseForm}
      />
    </Grid>
  );
};

const tableConfig = {
  ...config,
  url: `${process.env.REACT_APP_API}/api/v1/pos`,
  dataConfig: ['pos'],
  defaultSortBy: 'invoiceDate',
};

export default POSPage;
