import provinceJson from './provinceInfo.json';

const generateCityOptions = () => {
  return Object.entries(provinceJson).map((item) => ({
    value: item[0],
    label: item[1].en,
    en: item[1].en,
    kh: item[1].kh,
    type: item[1].type,
  }));
};

const generateDistrictOptions = (registeredAddress) => {
  if (
    !registeredAddress ||
    !registeredAddress.city ||
    !provinceJson[registeredAddress.city.value]
  )
    return [];
  return Object.entries(
    provinceJson[registeredAddress.city.value].district
  ).map((item) => ({
    value: item[0],
    label: item[1].en,
    en: item[1].en,
    kh: item[1].kh,
    type: item[1].type,
  }));
};

const generateCommuneOptions = (registeredAddress) => {
  if (
    !registeredAddress ||
    !registeredAddress.district ||
    !provinceJson[registeredAddress.city.value] ||
    !provinceJson[registeredAddress.city.value].district[
      registeredAddress.district.value
    ]
  )
    return [];

  return Object.entries(
    provinceJson[registeredAddress.city.value].district[
      registeredAddress.district.value
    ].commune
  ).map((item) => ({
    value: item[0],
    label: item[1].en,
    en: item[1].en,
    kh: item[1].kh,
    type: item[1].type,
  }));
};

const generateVillageOptions = (registeredAddress) => {
  if (
    !registeredAddress ||
    !registeredAddress.district ||
    !registeredAddress.commune ||
    !provinceJson[registeredAddress.city.value] ||
    !provinceJson[registeredAddress.city.value].district[
      registeredAddress.district.value
    ] ||
    !provinceJson[registeredAddress.city.value].district[
      registeredAddress.district.value
    ].commune[registeredAddress.commune.value]
  )
    return [];

  return Object.entries(
    provinceJson[registeredAddress.city.value].district[
      registeredAddress.district.value
    ].commune[registeredAddress.commune.value].village
  ).map((item) => ({
    value: item[0],
    label: item[1].en,
    en: item[1].en,
    kh: item[1].kh,
    type: item[1].type,
  }));
};

export {
  generateCityOptions,
  generateDistrictOptions,
  generateCommuneOptions,
  generateVillageOptions,
};
