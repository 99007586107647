import { FormControl, Select } from '@material-ui/core';
import { useController } from 'react-hook-form';

const RHFSelect = (props) => {
  const {
    name,
    control,
    required,
    rules,
    children,
    size,
    fullWidth,
    defaultValue = '',
    ...otherProps
  } = props;

  // using react hook form useController as value state
  const {
    field: { ref, value, onChange, ...inputProps },
  } = useController({
    name,
    defaultValue: defaultValue,
    control, //optional when use inside formContext
    rules: { required, ...rules },
  });

  return (
    <FormControl size={size} fullWidth={fullWidth}>
      <Select
        value={value}
        onChange={(e) => onChange(e.target.value)}
        inputRef={ref}
        {...inputProps}
        {...otherProps}
      >
        {children}
      </Select>
    </FormControl>
  );
};

export default RHFSelect;
