import React, { useState, useContext, useCallback, useEffect } from 'react';
import TitleBar from '../../components/title-bar';
import ActionButton from '../../components/action-button';
import { FormControlLabel, Grid, makeStyles, Switch } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import SelectPopUp from '../../components/select-popup';
import config from '../../components/tableX/config';
import DateFormat from '../../components/date-format';
import NumberFormat from '../../components/number-format';
import { UserContext } from '../../components/context-provider/user-context';
import axios from 'axios';
import { useTabFilter } from '../../components/tab-filter';
import TableTabFilter from '../../components/table-with-filter';
import Prompt, { usePrompt } from 'src/components/prompt';
import useMessage from 'src/hooks/useMessage';
import Status from 'src/components/status';
import moment from 'moment';

const filterStatus = [
  // { label: 'all', filter: null },
  { label: 'active', filter: { expired: false, isActive: true } },
  { label: 'inactive', filter: { isActive: false } },
  { label: 'expired', filter: { expired: true } },
];

const DiscountPage = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const [triggerFetch, setTriggerFetch] = useState(false);
  const { checkAccess } = useContext(UserContext);
  const { promptState, openPrompt, closePrompt } = usePrompt({
    title: t('delete-discount'),
    message: t('are-you-sure'),
    buttons: [{ text: t('cancel') }, { text: t('confirm') }],
  });

  const { showErrorResponseMessage, showSuccessResponseMessage } = useMessage();

  const canceldiscount = async (arrayId) => {
    try {
      const respone = await axios.delete(
        `${process.env.REACT_APP_API}/api/v1/discounts`,
        { params: { id: arrayId } }
      );
      showSuccessResponseMessage(respone);
      setTriggerFetch((prev) => !prev);
      closePrompt();
    } catch (error) {
      console.error(error);
      showErrorResponseMessage(error);
    }
  };

  const handleCancel = (data) => {
    openPrompt({
      buttonActions: [
        { onClick: closePrompt },
        { onClick: () => canceldiscount([data._id]) },
      ],
    });
  };

  const handleEdit = (data, e) => {
    const location = `/dashboard/discount/edit/${data._id}`;
    if (e?.ctrlKey) {
      window.open(location, '_blank');
    } else {
      history.push(location);
    }
  };

  const handleCreate = () => history.push('/dashboard/discount/create');

  const handleChangeActive = async (id, state, callback) => {
    try {
      const respone = await axios.put(
        `${process.env.REACT_APP_API}/api/v1/discounts/${id}`,
        { isActive: state },
        { params: { modify: true } }
      );
      showSuccessResponseMessage(respone);
      setTriggerFetch((prev) => !prev);
      closePrompt();
      callback && callback();
    } catch (error) {
      console.error(error);
      showErrorResponseMessage(error);
    }
  };

  const tableSchema = [
    {
      id: 'name',
      label: t('discount-name'),
    },
    {
      id: 'amount',
      label: t('amount'),
      type: 'component',
      dataConfig: [],
      components: ({ amount, type }) => {
        return <NumberFormat value={amount} format={type} />;
      },
    },
    {
      id: 'startDate',
      label: t('start-date'),
      type: 'component',
      components: [
        {
          name: DateFormat,
          customValueProp: 'value',
        },
      ],
    },
    {
      id: 'expiredDate',
      label: t('expired-date'),
      type: 'component',
      components: [
        {
          name: DateFormat,
          customValueProp: 'value',
        },
      ],
    },
    {
      label: t('status'),
      sort: false,
      type: 'component',
      dataConfig: [],
      components: (data) => {
        if (moment(data.expiredDate).diff(new Date(), 'seconds') <= 0) {
          return <Status status={'expired'} />;
        }
        return (
          <ActiveToggle
            defaultValue={data.isActive}
            onChange={(state, setActive) => {
              handleChangeActive(data._id, state, () => {
                setActive(state);
              });
            }}
          />
        );
      },
    },
    {
      id: 'createBy',
      label: t('created-by'),
      dataConfig: ['createdBy', 'name'],
    },
    {
      id: 'action',
      sort: false,
      label: t('action'),
      type: 'component',
      dataConfig: [],
      components: (data) => {
        const actions = [];

        !data?.isCanceled &&
          actions.push({
            variant: 'edit',
            hidden: !checkAccess({
              feature: 'discount',
              action: 'update',
            }),
            onClick: () => handleEdit(data),
            title: t('edit'),
          });

        !data?.isCanceled &&
          actions.push({
            variant: 'cancel',
            hidden: !checkAccess({
              feature: 'discount',
              action: 'update',
            }),
            onClick: (e) => {
              e.stopPropagation();
              handleCancel(data);
            },
            title: t('cancel'),
          });

        return actions.length > 0 ? (
          actions.map(
            (data, index) =>
              data && <ActionButton key={index} {...data}></ActionButton>
          )
        ) : (
          <>-</>
        );
      },
    },
  ];

  const { value, changeFilter } = useTabFilter(0);

  const defaultFilter = useCallback(() => {
    return filterStatus[value].filter;
  }, [value]);

  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <TitleBar
          onClick={handleCreate}
          buttonText={t('create-discount')}
          disableButton={
            !checkAccess({ feature: 'discount', action: 'create' })
          }
        ></TitleBar>
      </Grid>
      <Grid item xs={12}>
        <TableTabFilter
          tabFilterProps={{
            options: filterStatus.map((filter) => filter.label),
            value,
            onChange: changeFilter,
          }}
          tableXProps={{
            triggerFetch,
            onRowClick: handleEdit,
            defaultFilter,
            render: (data) => {
              return <SelectPopUp open={data.length} select={data} noButton />;
            },
            config: tableConfig,
            schema: tableSchema,
          }}
        />
      </Grid>
      <Prompt state={promptState} />
    </Grid>
  );
};

const tableConfig = {
  ...config,
  url: `${process.env.REACT_APP_API}/api/v1/discounts`,
  dataConfig: ['discounts'],
};

const ActiveToggle = ({ defaultValue = false, onChange }) => {
  const [toggle, setToggle] = useState(defaultValue);
  const { t } = useTranslation();

  return (
    <FormControlLabel
      onClick={(e) => {
        e.stopPropagation();
      }}
      control={
        <Switch
          color='primary'
          checked={toggle}
          value={toggle}
          onChange={(e) => {
            e.stopPropagation();
            onChange && onChange(e.target.checked, setToggle);
          }}
        />
      }
      label={toggle ? t('active') : t('inactive')}
    />
  );
};

export default DiscountPage;
