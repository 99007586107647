import { Box, Button, Grid, Link, Typography } from '@material-ui/core';
import React, { useEffect, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import axios from 'axios';
import { Controller, useForm } from 'react-hook-form';
import { UtilityContext } from '../../../components/context-provider/utilty-context';
import {
  RHFCheckbox,
  RHFPhoneInput,
  RHFProfilePicture,
  RHFTextInput,
} from 'src/components/rhf-controlled-input';
import { UserContext } from 'src/components/context-provider/user-context';
import LabelInput from 'src/components/label-input';
import { FaWarehouse } from 'react-icons/fa';
import InputGoogleMapSuggestion from 'src/pages/customer/create-edit/customer-info/address-input/input-google-map-search';
import useMessage from 'src/hooks/useMessage';
import Spinner, { useSpinner } from 'src/components/spinner';
import useSubmitButton, { HiddenSubmitButton } from 'src/hooks/useSubmitButton';
import ShippingFee from './shipping-fee';
import QRCode from 'qrcode.react';
import removeImages from 'src/util/remove-images';
import uploadImage from 'src/util/upload-image';

const WarehouseSetting = () => {
  const { t } = useTranslation();
  const { showSnackbar, showPrompt, closePrompt } = useContext(UtilityContext);
  const { fetchUser, s3BaseURL, me } = useContext(UserContext);
  const { handleSubmit, reset, formState, control, watch } = useForm();
  const { showErrorResponseMessage } = useMessage();
  const { dirtyFields } = formState;
  const { spinnerState, openSpinner, closeSpinner } = useSpinner();
  const isShippingFree = watch('isShippingFree');
  const { submitButtonRef, submit } = useSubmitButton();

  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_API}/api/v1/setting`)
      .then((response) => {
        const warehouse = response.data.warehouse;
        const shippingFee = warehouse.shippingFee ?? [];
        if (shippingFee.length < 5) {
          shippingFee.push({ distance: '', fee: '' });
        }
        reset({
          ...warehouse,
          shippingFee,
          logo: { src: warehouse.logo },
        });
      })
      .catch((err) => console.log(err));
  }, []);

  const onSubmit = async (formData) => {
    try {
      closePrompt();
      openSpinner({ title: t('loading') });
      const { logo, ...data } = formData;

      const updateData = {
        ...data,
        shippingFee: formData?.shippingFee?.filter(
          (item) => item._id || item.distance
        ),
      };

      if (logo?.file) {
        try {
          if (logo.remove.length > 0) {
            await removeImages(logo.remove);
          }
          updateData.logo = await uploadImage(
            formData.logo.file,
            s3BaseURL,
            `public/${me?.warehouse?.id}/logo`
          );
        } catch (error) {
          console.log(error);
          showErrorResponseMessage(error);
          closeSpinner();
          return;
        }
      }

      const response = await axios.put(
        `${process.env.REACT_APP_API}/api/v1/setting`,
        updateData
      );
      showSnackbar({ message: response.data.message });
      const warehouse = response.data.warehouse;

      const updatedShippingFee = warehouse?.shippingFee ?? [];
      if (updatedShippingFee.length < 5) {
        updatedShippingFee.push({ distance: '', fee: '' });
      }

      reset({
        ...warehouse,
        shippingFee: updatedShippingFee,
        logo: { src: warehouse.logo },
      });
      fetchUser();
    } catch (error) {
      showErrorResponseMessage(error);
    } finally {
      closeSpinner();
    }
  };

  const handleClick = () => {
    const title = t('action-confirmation');

    let message = (
      <Typography>
        {t('action-cant-undone')}
        <br /> {t('are-you-sure')}
      </Typography>
    );

    showPrompt({
      handleConfirm: submit,
      title,
      message,
      disableIcon: true,
      confirmButtonProps: {
        color: 'primary',
        variant: 'contained',
        style: { borderRadius: 0 },
        disableElevation: true,
      },
      confirmButtonText: t('confirm'),
      cancelButtonText: t('cancel'),
    });
  };

  return (
    <form id='warehouse-setting-form' onSubmit={handleSubmit(onSubmit)}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Typography variant='h6'>{t('warehouse-information')}</Typography>
        </Grid>
        <Grid item xs={12}>
          <Grid container spacing={2}>
            <Grid item container justify='center' alignItems='center' xs={6}>
              <Box mb={2}>
                <RHFProfilePicture
                  editDialogTitle={t('edit-logo')}
                  avatarIcon={<FaWarehouse />}
                  name='logo'
                  control={control}
                />
              </Box>
            </Grid>
            <Grid
              item
              container
              justify='center'
              direction='column'
              alignItems='center'
              xs={6}
              style={{ textAlign: 'center' }}
            >
              <Link
                href={process.env.REACT_APP_TELEGRAM_STORE_BOT_URL}
                target='_blank'
              >
                <QRCode
                  value={`${process.env.REACT_APP_TELEGRAM_STORE_BOT_URL}`}
                  size={150}
                  imageSettings={{
                    src: '/telegram.svg',
                    width: 32,
                    height: 32,
                  }}
                />
                <Typography variant='body2'>
                  {t('subscribe-telegram')}
                </Typography>
              </Link>
            </Grid>
            <Grid item xs={6}>
              <LabelInput
                label={t('name')}
                component={RHFTextInput}
                required
                name='name'
                control={control}
              ></LabelInput>
            </Grid>
            <Grid item xs={6}>
              <LabelInput
                label={t('phone')}
                component={RHFPhoneInput}
                required
                name='phoneNumber'
                control={control}
              ></LabelInput>
            </Grid>
            <Grid item xs={6}>
              <LabelInput
                required
                label={t('address')}
                component={RHFTextInput}
                name='address.addr'
                control={control}
              ></LabelInput>
            </Grid>
            <Grid item xs={6}>
              <Controller
                control={control}
                name='address.nearby'
                defaultValue={null}
                render={({ field: { value, onChange } }) => {
                  return (
                    <InputGoogleMapSuggestion
                      value={value}
                      onChange={onChange}
                      label='Nearby'
                    />
                  );
                }}
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Typography variant='h6'>{t('social')}</Typography>
        </Grid>
        <Grid item xs={12}>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <LabelInput
                label={t('facebook-page-id')}
                component={RHFTextInput}
                name='social.facebook.pageId'
                control={control}
              ></LabelInput>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={6}>
          <Typography variant='h6'>{t('order')}</Typography>
        </Grid>
        <Grid item xs={6}>
          <Typography variant='h6'>{t('purchase-order')}</Typography>
        </Grid>
        <Grid item xs={6} container>
          <RHFCheckbox
            name='setting.order.trackStock'
            label={t('track-stock')}
            control={control}
          />
          <RHFCheckbox
            name='setting.order.repack'
            label={t('repack')}
            control={control}
          />
          <RHFCheckbox
            name='setting.order.saleReturn'
            label={t('sale-return')}
            control={control}
          />
        </Grid>
        <Grid item xs={6} container>
          <RHFCheckbox
            name='setting.purchaseOrder.purchase'
            label={t('purchase-order')}
            control={control}
          />
        </Grid>
        <Grid item xs={6}>
          <Typography variant='h6'>{t('shipping-fee')}</Typography>
        </Grid>
        <Grid item xs={12} container>
          <RHFCheckbox
            name='isShippingFree'
            label={t('free-shipping')}
            control={control}
          />
          {isShippingFree === false && <ShippingFee control={control} />}
        </Grid>
        <Grid item xs={12} style={{ textAlign: 'right' }}>
          <Button
            disabled={!Object.keys(dirtyFields).length}
            variant='contained'
            color='primary'
            onClick={handleClick}
          >
            {t('save-changes')}
          </Button>
        </Grid>
      </Grid>
      <Spinner state={spinnerState} />
      <HiddenSubmitButton ref={submitButtonRef} />
    </form>
  );
};

export default WarehouseSetting;
