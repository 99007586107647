import React, { useState, useCallback } from 'react';
import {
  FormControl,
  FormLabel,
  TextField,
  makeStyles,
} from '@material-ui/core';
import axios from 'axios';
import { Autocomplete, createFilterOptions } from '@material-ui/lab';
import debounce from 'debounce';
import readObjectWithArrayKeys from '../../../../util/read-object-value-with-array-keys';

const filter = createFilterOptions();

const useStyles = makeStyles((theme) => ({
  label: {
    color: 'black',
    fontWeight: 'bold',
    marginBottom: '10px',
    fontSize: '14px',
    textTransform: 'uppercase',
  },
  danger: {
    color: theme.palette.secondary.main,
  },
}));

const CategoryListSearch = ({
  label,
  optionsURL,
  queryString = 'search',
  dataConfig,
  placeholder = '',
  value,
  onChange,
  required,
  filterData,
  ...other
}) => {
  const [list, setList] = useState([]);
  const [loading, setLoading] = useState(true);
  const classes = useStyles();

  const fetchData = (searchText = '') => {
    setLoading(true);
    axios
      .get(
        `${optionsURL}?${queryString}=${searchText || ''}&page=1&limit=5&sort=`
      )
      .then((response) => {
        setList(readObjectWithArrayKeys(response.data, dataConfig));
      })
      .catch((err) => console.log(err))
      .finally(() => {
        setLoading(false);
      });
  };

  const debounceSearch = useCallback(
    debounce((text) => {
      fetchData(text);
    }, 500),
    []
  );

  let options = {};
  if (filter) {
    options = {
      filterOptions: (options, params) => {
        const filtered = filter(options, params);
        return filtered.filter((item) => item?._id !== filterData?._id);
      },
    };
  }

  return (
    <FormControl fullWidth>
      {label && (
        <FormLabel className={classes.label}>
          {label}
          {required && <span className={classes.danger}>*</span>}
        </FormLabel>
      )}
      <Autocomplete
        options={list}
        disableClearable
        autoHighlight
        {...other}
        {...options}
        value={value}
        onChange={onChange}
        loading={loading}
        onFocus={() => list.length === 0 && fetchData()}
        onKeyDown={(event) => {
          setLoading(true);
          debounceSearch(event.target.value || '');
        }}
        renderInput={(params) => {
          return (
            <TextField
              {...params}
              required={required}
              variant="outlined"
              size="small"
              placeholder={label ? label.toUpperCase() : placeholder}
            />
          );
        }}
      ></Autocomplete>
    </FormControl>
  );
};

export default CategoryListSearch;
