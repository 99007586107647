import { Grid, Paper } from '@material-ui/core';
import React, { useState, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import TableX from '../../components/tableX';
import TitleBar from '../../components/title-bar';
import BrandForm from './form';
import config from '../../components/tableX/config';
import ActionButton from '../../components/action-button';
import { UtilityContext } from '../../components/context-provider/utilty-context';
import { UserContext } from '../../components/context-provider/user-context';
import SelectPopUp from '../../components/select-popup';
import reduceArrayString from '../../util/reduce-array-string';
import axios from 'axios';

const BrandPage = () => {
  const { t } = useTranslation();
  const [brandForm, setBrandForm] = useState(false);
  const { showDeletePrompt, closeDeletePrompt, showSnackbar } =
    useContext(UtilityContext);
  const { checkAccess } = useContext(UserContext);
  // state for refresh table component
  const [triggerFetch, setTriggerFetch] = useState(false);
  const [activeData, setActiveData] = useState(null);

  const handleOpenForm = () => {
    setActiveData(null);
    setBrandForm(true);
  };

  const handleCloseForm = () => setBrandForm(false);

  const handleEdit = (data) => {
    setActiveData(data);
    setBrandForm(true);
  };

  const handleDelete = (data) =>
    showDeletePrompt({
      handleConfirm: () => onDelete(data),
    });

  const onDelete = (items) => {
    const ids = reduceArrayString(items);
    axios
      .delete(`${process.env.REACT_APP_API}/api/v1/brands?${ids}`)
      .then(({ data }) => {
        showSnackbar({ message: data?.message });
        setTriggerFetch((prev) => !prev);
      })
      .catch(({ response }) => {
        showSnackbar({ message: response?.data?.message, variant: 'error' });
      })
      .finally(() => closeDeletePrompt());
  };

  const tableSchema = [
    { id: 'name', label: t('brand-name') },
    {
      id: 'createdBy',
      label: t('created-by'),
      dataConfig: ['createdBy', 'name'],
    },
    {
      id: 'action',
      sort: false,
      label: t('action'),
      type: 'component',
      components: [
        {
          name: ActionButton,
          props: { variant: 'edit', hidden: !checkAccess({feature: 'brand', action: 'update'})},
          onClick: handleEdit,
        },
        {
          name: ActionButton,
          props: { variant: 'delete', hidden: !checkAccess({feature: 'brand', action: 'delete'})},
          onClick: handleDelete,
        },
      ],
    },
  ];

  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <TitleBar
          onClick={handleOpenForm}
          buttonText={t('create-brand')}
          disableButton={!checkAccess({feature: 'brand', action: 'create'})}
        ></TitleBar>
      </Grid>
      <Grid item xs={12}>
        <Paper className="tableX-container">
          <TableX
            onRowClick={handleEdit}
            render={(data) => {
              return (
                <SelectPopUp
                  open={data.length}
                  select={data}
                  handleDelete={() => handleDelete(data)}
                />
              );
            }}
            triggerFetch={triggerFetch}
            config={tableConfig}
            schema={tableSchema}
          ></TableX>
        </Paper>
      </Grid>
      <BrandForm
        preData={activeData}
        open={brandForm}
        setTriggerFetch={setTriggerFetch}
        onClose={handleCloseForm}
      />
    </Grid>
  );
};

const tableConfig = {
  ...config,
  url: `${process.env.REACT_APP_API}/api/v1/brands`,
  dataConfig: ['brands'],
  defaultSortBy: 'companyName',
};

export default BrandPage;
