import { Avatar, IconButton, makeStyles } from '@material-ui/core';
import ClearIcon from '@material-ui/icons/Clear';
import React from 'react';

const useStyles = makeStyles((theme) => ({
  container: {
    position: 'relative',
    display: 'flex',
    justifyContent: 'center',
    margin: '0 5px',
    alignItems: 'center',
    height: '100%',
    cursor: 'pointer',
    '&:hover img': {
      transition: '0.2s ease-in',
      transform: 'scale(1.05)',
    },
  },
  selected: {
    border: `2px solid ${theme.palette.primary.main}`,
    borderRadius: '5px',
  },
  image: {
    width: '90%',
    height: '90%',
    '& img': {
      objectFit: 'contain',
      transition: '0.2s ease-in',
    },
  },
  button: {
    position: 'absolute',
    top: 0,
    right: 0,
    marginTop: '-5px !important',
    marginRight: '-5px !important',
    zIndex: 100,
    color: 'white',
    backgroundColor: 'rgb(243 111 111 / 80%)',
    '&:hover': {
      backgroundColor: 'rgb(243 111 111 / 90%)',
    },
    '&:active': {
      backgroundColor: 'rgb(243 111 111 )',
    },
    '& svg': {
      fontSize: '15px',
    },
  },
}));

const ImageSlide = ({ image, onRemove }) => {
  const classes = useStyles();
  return (
    <div className={classes.container}>
      <IconButton size="small" onClick={onRemove} className={classes.button}>
        <ClearIcon />
      </IconButton>
      <Avatar
        className={classes.image}
        variant="rounded"
        src={'thumbnail' in image ? image.thumbnail : image.ObjectURL}
      ></Avatar>
    </div>
  );
};

export default ImageSlide;
