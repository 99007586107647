import {
  TableRow,
  TableCell,
  TableBody,
  Typography,
  Box,
  Checkbox,
} from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import NumberFormatCustom from '../../../../components/number-format';
import CustomTable from '../../../../components/custom-table';
import calculateDiscount from '../../../../util/calculate-discount';
import displayProductName from 'src/util/display-product-name';

const showReturnedStatus = ['returned', 'partially-returned'];

const Products = (props) => {
  const {
    data,
    handleSelectAll,
    handleSelect,
    returnable = {
      state: false,
      items: [],
    },
    isSelected,
    status = {
      name: '',
    },
  } = props;
  const { t } = useTranslation();

  const isReturnedStatus = showReturnedStatus.includes(status?.name);

  const tableHeadData = [
    {
      value: 'no.',
    },
    {
      value: 'product-name',
      width: '30%',
    },
    {
      value: 'quantity',
    },
    ...(isReturnedStatus
      ? [
          {
            value: 'returned',
          },
        ]
      : []),
    { value: 'status' },
    {
      value: 'unit-price',
    },
    {
      value: 'discount',
    },
    {
      value: 'amount',
      align: 'right',
      width: '10%',
    },
  ];

  return (
    <>
      <Box bgcolor='primary.light' id='title' color='white' p={1}>
        <Typography>
          <b>{t('items')}</b>
        </Typography>
      </Box>
      <CustomTable.Container size='small'>
        {/* table header */}
        <CustomTable.Head
          data={tableHeadData}
          enableCheckBox={returnable.state}
          checkboxProps={{
            onChange: (e) => handleSelectAll && handleSelectAll(e),
            checked:
              returnable.items?.length ===
              data?.filter(
                (item) =>
                  !item.isCanceled && item.qty > item.returned + item.credited
              ).length,
          }}
        />
        {/* table body */}
        <TableBody>
          {data?.map((field, index) => {
            // get value from field
            const get = getValue(field);

            const isItemSelected = isSelected && isSelected(field);
            return (
              <TableRow disabled key={index}>
                {returnable.state && (
                  <TableCell width='10px'>
                    <Checkbox
                      disabled={get.isCanceled || get.totalReturn === get.qty}
                      onChange={(e) => handleSelect && handleSelect(e, field)}
                      checked={isItemSelected}
                      color='primary'
                      size='small'
                    />
                  </TableCell>
                )}
                <TableCell>
                  <CustomTable.CellValue>{index + 1}</CustomTable.CellValue>
                </TableCell>
                <TableCell>
                  <CustomTable.CellValue>
                    {displayProductName(get.productCode, get.productName)}
                  </CustomTable.CellValue>
                </TableCell>
                <TableCell>
                  <CustomTable.CellValue>{get.qty}</CustomTable.CellValue>
                </TableCell>
                {isReturnedStatus && (
                  <TableCell>
                    <CustomTable.CellValue>
                      {get.totalReturn}
                    </CustomTable.CellValue>
                  </TableCell>
                )}
                <TableCell>
                  <CustomTable.CellValue>
                    {get.isCanceled ? (
                      <Box color='red'>Canceled</Box>
                    ) : (
                      <Box color='primary.main'>Active</Box>
                    )}
                  </CustomTable.CellValue>
                </TableCell>
                <TableCell>
                  <CustomTable.CellValue>
                    <NumberFormatCustom value={get.salePrice} />
                  </CustomTable.CellValue>
                </TableCell>
                <TableCell>
                  <CustomTable.CellValue>
                    <NumberFormatCustom
                      value={get.discount?.amount}
                      format={get.discount?.type}
                    />
                  </CustomTable.CellValue>
                </TableCell>
                <TableCell>
                  <CustomTable.CellValue align='right'>
                    <NumberFormatCustom value={get.totalAmount} />
                  </CustomTable.CellValue>
                </TableCell>
              </TableRow>
            );
          })}
        </TableBody>
      </CustomTable.Container>
    </>
  );
};

// function to get product value
export const getValue = (data) => {
  const productName = data?.variant?.name ?? '';
  const productCode = data?.variant?.altCode || data?.variant?.sku;
  const tax = data.tax ?? 0;
  const salePrice = data.salePrice ?? 0;
  const discount = data.discount ?? {
    amount: 0,
    type: 'percentage',
  };
  const returned = data.returned ?? 0;
  const qty = data.qty ?? 1;
  const credited = data.credited ?? 0;
  const totalAmount = calculateDiscount(salePrice * (qty - returned), discount);
  const totalReturn = returned + credited;
  const isCanceled = data.isCanceled ?? false;

  return {
    productName,
    productCode,
    tax,
    salePrice,
    qty,
    totalReturn,
    totalAmount,
    discount,
    returned,
    credited,
    isCanceled,
  };
};

export default Products;
