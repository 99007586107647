import React, { useRef, useEffect, useState, useContext } from 'react';
import { Box, Grid, Hidden, makeStyles, Paper, Tab } from '@material-ui/core';
import TitleBar from '../../../components/title-bar';
import FloatingBar from '../../../components/floating-bar';
import { useParams, useHistory } from 'react-router-dom';
import { useForm, FormProvider } from 'react-hook-form';
import axios from 'axios';
import CustomerInfo from './customer-info';
import { UtilityContext } from '../../../components/context-provider/utilty-context';
import TabContainer from '../../../components/tabs-container';
import TabPanel from '../../../components/tab-panel';
import { useTranslation } from 'react-i18next';
import UnpaidOrders from './unpaid-order';
import Spinner, { useSpinner } from 'src/components/spinner';

const useStyles = makeStyles(() => ({
  root: {
    marginBottom: '150px',
    flexDirection: 'column',
    position: 'relative',
  },
}));

const AddEditCustomerPage = () => {
  const classes = useStyles();
  const history = useHistory();
  const { id } = useParams();
  const { showSnackbar } = useContext(UtilityContext);
  const methods = useForm();
  const [state, setState] = useState('create');
  const [value, setValue] = useState(0);
  const [customerData, setCustomerData] = useState(null);
  const [shippingForm, setShippingForm] = useState({
    state: false,
    activeIndex: null,
  });

  const { t } = useTranslation();

  const { spinnerState, openSpinner, closeSpinner } = useSpinner();

  useEffect(() => {
    if (id) {
      openSpinner({ title: t('loading') });
      setState('edit');
      axios
        .get(`${process.env.REACT_APP_API}/api/v1/customers/${id}`)
        .then(({ data }) => {
          if (typeof data === 'object') {
            setCustomerData(data);
          } else {
            console.log('error reponse');
          }
        })
        .catch(({ response }) =>
          showSnackbar({ message: response.data?.message, variant: 'error' })
        )
        .finally(() => {
          closeSpinner();
        });
    } else {
      setState('create');
    }
  }, []);

  useEffect(() => {
    if (customerData) {
      methods.reset(customerData);
    }
  }, [customerData]);

  const onSubmit = (data) => {
    try {
      const updateData = {
        firstname: data.firstname,
        lastname: data.lastname,
        email: data.email,
        phone: data.phone,
        gender: data.gender,
        dateOfBirth: data.dateOfBirth,
        shippingInfo: data.shippingInfo,
        primaryShipping: data.primaryShipping,
      };

      if (data.shippingInfo.length === 0) {
        setShippingForm({ state: true, activeIndex: null });
      } else {
        openSpinner({ title: t('saving') });
        switch (state) {
          case 'create':
            axios
              .post(`${process.env.REACT_APP_API}/api/v1/customers`, updateData)
              .then(({ data }) => {
                showSnackbar({ message: data?.message });
                goBackToList();
              })
              .catch(({ response }) => {
                showSnackbar({
                  message: response.data?.message,
                  variant: 'error',
                });
              })
              .finally(() => {
                closeSpinner();
              });
            break;
          case 'edit':
            axios
              .put(
                `${process.env.REACT_APP_API}/api/v1/customers/${id}`,
                updateData
              )
              .then(({ data }) => {
                showSnackbar({ message: data?.message });
                goBackToList();
              })
              .catch(({ response }) => {
                showSnackbar({
                  message: response?.data?.message,
                  variant: 'error',
                });
              })
              .finally(() => {
                closeSpinner();
              });
            break;
          default:
            console.log('Error state');
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleCancel = () => {
    goBackToList();
  };

  const goBackToList = () => {
    history.push('/dashboard/customer');
  };

  return (
    <FormProvider {...methods}>
      <form id='customer-form' onSubmit={methods.handleSubmit(onSubmit)}>
        <Grid container className={classes.root} spacing={3}>
          <Grid item xs={12}>
            <TitleBar disableButton />
          </Grid>
          <Grid item xs={12}>
            <Paper>
              <TabContainer
                style={{ padding: '0 10px' }}
                value={value}
                onChange={(e, newValue) => setValue(newValue)}
              >
                <Tab label={t('profile')}></Tab>
                {state === 'edit' && <Tab label={t('unpaid-invoice')}></Tab>}
              </TabContainer>
              <Grid container>
                <Grid item xs={12}>
                  <Box hidden={value !== 0}>
                    <CustomerInfo
                      shippingForm={shippingForm}
                      setShippingForm={setShippingForm}
                    />
                  </Box>
                  {state === 'edit' && (
                    <TabPanel index={1} value={value}>
                      <UnpaidOrders id={id} />
                    </TabPanel>
                  )}
                </Grid>
              </Grid>
            </Paper>
          </Grid>
          <FloatingBar
            onCancel={handleCancel}
            confirmButtonProps={{ form: 'customer-form' }}
            disabledConfirmButton={
              state !== 'create' &&
              Object.keys(methods.formState.dirtyFields).length === 0
            }
            confirmButtonText={
              state === 'create' ? 'create-customer' : 'save-changes'
            }
          />
        </Grid>
        <Spinner state={spinnerState} />
      </form>
    </FormProvider>
  );
};

export default AddEditCustomerPage;
