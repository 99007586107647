import { forwardRef, useRef } from 'react';

const useSubmitButton = () => {
  const submitButtonRef = useRef();

  const clickSubmitButton = () => {
    console.log('click brus');
    if (!submitButtonRef.current) return;
    submitButtonRef.current.click();
  };

  return { submitButtonRef, submit: clickSubmitButton };
};

export const HiddenSubmitButton = forwardRef((props, ref) => {
  return <input ref={ref} hidden type='submit' />;
});

export default useSubmitButton;
