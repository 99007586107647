import { Divider, Grid, Box, Typography } from '@material-ui/core';
import React from 'react';
import { useTranslation } from 'react-i18next';
import CustomNumberFormat from '../number-format';
import PriceWithLabel from '../price-with-label';

const TotalDisplayAmountDue = (props) => {
  const { t } = useTranslation();
  const {
    tax = 0,
    costing = 0,
    subtotal = 0,
    amountDue = 0,
    children,
    total,
    className,
    shippingFee = 0,
    shippingExpense = 0,
    size = 'medium',
  } = props;
  return (
    <Grid
      container
      className={className}
      spacing={size === 'medium' ? 1 : 0}
      direction='column'
    >
      <Grid item container justify='flex-end'>
        <Grid item xs={6}>
          <PriceWithLabel label={t('costing-total')} value={costing} />
        </Grid>
        <Grid item xs={5}>
          <PriceWithLabel label={t('subtotal')} value={subtotal} />
        </Grid>
      </Grid>
      <Grid item container justify='flex-end'>
        <Grid item xs={6}>
          <PriceWithLabel label={t('shipping-fee')} value={shippingFee} />
        </Grid>
        <Grid item xs={5}>
          <PriceWithLabel label={t('tax')} value={tax} />
        </Grid>
      </Grid>
      <Grid item>
        <Divider />
      </Grid>
      <Grid item container justify='flex-end' xs={12}>
        <PriceWithLabel label={t('total')} value={total} />
      </Grid>
      <Grid item container alignItems='center' justify='flex-end'>
        {children}
      </Grid>
      {children && (
        <Grid item>
          <Divider />
        </Grid>
      )}
      <Box bgcolor='primary.lighter' clone>
        <Grid item container justify='flex-end' xs={12}>
          <PriceWithLabel
            valueFontSize='18px'
            label={t('amount-due')}
            value={amountDue}
          />
        </Grid>
      </Box>
      {!!shippingExpense && (
        <Grid item container alignItems='center' justify='flex-end'>
          <Typography variant='caption' color='textSecondary'>
            {`${t('shipping-expense')} : `}
            <CustomNumberFormat value={shippingExpense} />
          </Typography>
        </Grid>
      )}
    </Grid>
  );
};

export default TotalDisplayAmountDue;
