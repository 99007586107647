import React, { useEffect, useState } from 'react';
import { Grid, makeStyles, Button } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { useFieldArray, useFormContext } from 'react-hook-form';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import AttributeOptionField from './attribute-option-field';
import Variants from './variants';

const useStyles = makeStyles((theme) => ({
  button: {
    textTransform: 'uppercase',
    fontSize: '12px',
    fontWeight: 'bold',
    color: 'black',
    '& svg': {
      fontSize: '18px',
      color: theme.palette.primary.main,
    },
  },
}));

const AttributeCreator = ({ state, setRemoveVariantImage }) => {
  const { control, watch } = useFormContext();
  const classes = useStyles();
  const { t } = useTranslation();
  const [triggerGenerate, setTriggerGenerate] = useState(false);
  const { fields, append, remove } = useFieldArray({
    control,
    name: 'attributes',
  });

  const watchAttributes = watch('attributes');

  useEffect(() => {
    if (state === 'create' && fields.length === 0) {
      append({ name: '', options: [] });
    }
  }, []);

  const handleAddMoreAttribute = () => append({ name: '', options: [] });
  const handleRemoveAttribute = (index) => {
    remove(index);
    handleTriggerGenerate();
  };
  const handleTriggerGenerate = () => setTriggerGenerate((prev) => !prev);

  return (
    <Grid container className={classes.root} spacing={3}>
      <Grid item xs={12}>
        <Grid container spacing={2}>
          {fields.map((field, index) => {
            return (
              <AttributeOptionField
                data={field}
                key={field.id}
                state={state}
                handleTriggerGenerate={handleTriggerGenerate}
                index={index}
                onDelete={handleRemoveAttribute}
              />
            );
          })}
          <Grid item xs={12}>
            <div className={state === 'edit' ? 'hidden' : ''}>
              <Button
                // disable add more when the last row of options field empty
                disabled={
                  watchAttributes &&
                  watchAttributes[watchAttributes?.length - 1]?.options
                    ?.length === 0
                }
                className={classes.button}
                color="primary"
                size="small"
                onClick={handleAddMoreAttribute}
                disableElevation
              >
                <AddCircleIcon />
                {t('add-more-attribute')}
              </Button>
            </div>
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <Variants
          triggerGenerate={triggerGenerate}
          state={state}
          setRemoveVariantImage={setRemoveVariantImage}
        />
      </Grid>
    </Grid>
  );
};

export default React.memo(AttributeCreator);
