import { Avatar } from '@material-ui/core';
import PhotoIcon from '@material-ui/icons/Photo';

const CustomTableImage = ({ src = null, variant = 'rounded' }) => {
  return (
    <Avatar src={src} variant={variant}>
      <PhotoIcon />
    </Avatar>
  );
};

export default CustomTableImage;
