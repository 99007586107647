import { Grid, InputAdornment } from '@material-ui/core';
import React, { useEffect, useState, useContext } from 'react';
import DialogContainer from '../../../components/dialog-container';
import TextInput from '../../../components/text-input';
import { useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';
import axios from 'axios';
import { UtilityContext } from '../../../components/context-provider/utilty-context';
import { formHandler, registerMui } from '../../../util/react-hook-form-helper';

const SaleTargetForm = ({ open, onClose, setTriggerFetch, preData }) => {
  const { t } = useTranslation();
  // form current state
  const [state, setState] = useState('add');
  const { showSnackbar } = useContext(UtilityContext);
  // react hook form state
  const { register, handleSubmit, setValue, reset } = useForm();

  useEffect(() => {
    if (open) {
      console.log(!!preData);
      if (!!preData) {
        reset(preData);
        setState('update');
        setValue('sale', preData.sale);
      } else {
        setState('add');
        reset({});
      }
    }
  }, [open, preData]);

  const onSubmit = (data) => {
    const prepareData = { sale: data.sale, commission: data.commission };
    switch (state) {
      case 'add':
        axios
          .post(`${process.env.REACT_APP_API}/api/v1/sale-targets`, prepareData)
          .then(({ data }) => {
            showSnackbar({ message: data?.message });
            setTriggerFetch((prev) => !prev);
            onClose();
          })
          .catch(({ response }) => {
            showSnackbar({
              message: response?.data?.message,
              variant: 'error',
            });
          });
        break;
      case 'update':
        axios
          .put(
            `${process.env.REACT_APP_API}/api/v1/sale-targets/${preData._id}`,
            prepareData
          )
          .then(({ data }) => {
            showSnackbar({ message: data?.message });
            setTriggerFetch((prev) => !prev);
            onClose();
          })
          .catch(({ response }) => {
            showSnackbar({
              message: response?.data?.message,
              variant: 'error',
            });
          });
        break;
      default:
        console.log('wrong state');
    }
  };

  return (
    <DialogContainer
      open={open}
      state={state}
      onClose={onClose}
      title={t('sale-target-form-title')}
      onCancel={onClose}
      formId='sale-target-form'
    >
      <form
        id='sale-target-form'
        onSubmit={(e) => formHandler(e, handleSubmit(onSubmit))}
      >
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <TextInput
              autoFocus
              label={t('sale-amount')}
              type='number'
              required
              InputProps={{
                endAdornment: <InputAdornment position='end'>$</InputAdornment>,
              }}
              {...registerMui(register('sale.amount'))}
            />
          </Grid>
          <Grid item xs={12}>
            <TextInput
              label={t('commission-percentage')}
              type='number'
              required
              align='right'
              InputProps={{
                endAdornment: <InputAdornment position='end'>%</InputAdornment>,
              }}
              {...registerMui(register('commission.percentage'))}
            />
          </Grid>
        </Grid>
      </form>
    </DialogContainer>
  );
};

export default SaleTargetForm;
