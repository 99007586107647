import React, { useCallback, useState } from 'react';
import TableX from '../../components/tableX';
import TitleBar from '../../components/title-bar';
import { Grid, Paper, Tab, Box } from '@material-ui/core';
import config from '../../components/tableX/config';
import useDisplay from '../../util/useDisplay';
import DateFormat from '../../components/date-format';
import Status from '../../components/status';
import SaleReturnDetailPopUp from './detail-popup';
import TabContainer from '../../components/tabs-container';

const filterStatus = ['all', 'pending', 'completed'];

const RepackPage = () => {
  const { t } = useDisplay();

  const [triggerFetch, setTriggerFetch] = useState({});
  const [value, setValue] = useState(0);
  const [saleReturnDetail, setSaleReturnDetail] = useState({
    open: false,
    data: {},
  });

  const handleTriggerFetch = () => {
    setTriggerFetch((prev) => !prev);
  };

  const tableSchema = [
    {
      id: '_id',
      label: t('id'),
    },
    {
      id: 'invoiceNumber',
      label: t('invoice-number'),
    },
    {
      id: 'createdBy.name',
      dataConfig: ['createdBy', 'name'],
      label: t('created-by'),
    },
    {
      id: 'createdAt',
      label: t('created-date'),
      type: 'component',
      components: [
        {
          name: DateFormat,
          customValueProp: 'value',
        },
      ],
    },
    {
      id: 'status',
      label: t('status'),
      type: 'component',
      components: [
        {
          name: Status,
          customValueProp: 'status',
        },
      ],
    },
  ];

  const handleRowClick = (data) => {
    setSaleReturnDetail({ open: true, data });
  };

  const handleCloseDetail = () => {
    setSaleReturnDetail({ open: false, data: {} });
  };

  const defaultFilter = useCallback(() => {
    return value > 0 ? { status: filterStatus[value] } : {};
  }, [value]);

  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <TitleBar buttonText={t('create-product')} disableButton></TitleBar>
      </Grid>
      <Grid item xs={12}>
        <Paper>
          <TabContainer
            scrollButtons="on"
            value={value}
            onChange={(e, newValue) => setValue(newValue)}
          >
            {filterStatus.map((item, index) => (
              <Tab label={t(item)} key={index}></Tab>
            ))}
          </TabContainer>
          <Box className="tableX-container-sm">
            <TableX
              defaultFilter={defaultFilter}
              onRowClick={handleRowClick}
              triggerFetch={triggerFetch}
              config={tableConfig}
              schema={tableSchema}
              select={false}
            ></TableX>
          </Box>
        </Paper>
      </Grid>
      <SaleReturnDetailPopUp
        state={saleReturnDetail}
        handleRefreshTable={handleTriggerFetch}
        onClose={handleCloseDetail}
      />
    </Grid>
  );
};

const tableConfig = {
  ...config,
  dataConfig: ['repacks'],
  url: `${process.env.REACT_APP_API}/api/v1/orders/repack`,
};

export default RepackPage;
