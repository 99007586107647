import {
  Button,
  FormControlLabel,
  Grid,
  Paper,
  Typography,
  Switch,
} from '@material-ui/core';
import React, { useEffect, useContext } from 'react';
import TextInput from '../../../components/text-input';
import { useTranslation } from 'react-i18next';
import axios from 'axios';
import { Controller, useForm } from 'react-hook-form';
import { registerMui } from '../../../util/react-hook-form-helper';
import { UtilityContext } from '../../../components/context-provider/utilty-context';
import { UserContext } from '../../../components/context-provider/user-context';
import useSubmitButton, { HiddenSubmitButton } from 'src/hooks/useSubmitButton';

const TadaSetting = ({ data }) => {
  const { t } = useTranslation();
  const { showSnackbar, showPrompt, closePrompt } = useContext(UtilityContext);
  const { fetchUser } = useContext(UserContext);
  const { register, handleSubmit, reset, formState, control } = useForm();
  const { dirtyFields } = formState;
  const { submitButtonRef, submit } = useSubmitButton();

  useEffect(() => {
    reset(data);
  }, []);

  const onSubmit = (settingData) => {
    axios
      .put(`${process.env.REACT_APP_API}/api/v1/setting/delivery`, {
        ...settingData,
        name: 'tada',
      })
      .then(({ data }) => {
        showSnackbar({ message: data.message });
        fetchUser();
        reset(settingData, { keepDirty: false });
      })
      .catch(({ response }) =>
        showSnackbar({ message: response?.data.message, variant: 'error' })
      )
      .finally(() => closePrompt());
  };

  const handleClick = () => {
    const title = t('action-confirmation');

    let message = (
      <Typography>
        {t('action-cant-undone')}
        <br /> {t('are-you-sure')}
      </Typography>
    );

    showPrompt({
      handleConfirm: submit,
      title,
      message,
      disableIcon: true,
      confirmButtonProps: {
        color: 'primary',
        variant: 'contained',
        style: { borderRadius: 0 },
        disableElevation: true,
      },
      confirmButtonText: t('confirm'),
      cancelButtonText: t('cancel'),
    });
  };

  return (
    <Paper className='container'>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <FormControlLabel
              control={
                <Controller
                  name={'isEnabled'}
                  control={control}
                  defaultValue={false}
                  render={({ field: { value, onChange } }) => {
                    return (
                      <Switch
                        checked={value}
                        onChange={(e) => {
                          onChange(e.target.checked);
                        }}
                        color='primary'
                      />
                    );
                  }}
                />
              }
              label={t('Enabled')}
            />
          </Grid>
          <Grid item xs={12}>
            <TextInput
              {...registerMui(register('apiKey'))}
              label={t('api-key')}
            />
          </Grid>
          <Grid item xs={12} style={{ textAlign: 'right' }}>
            <Button
              disabled={!Object.keys(dirtyFields).length}
              variant='contained'
              color='primary'
              onClick={handleClick}
            >
              {t('save-changes')}
            </Button>
          </Grid>
        </Grid>
        <HiddenSubmitButton ref={submitButtonRef} />
      </form>
    </Paper>
  );
};

export default TadaSetting;
