import React from 'react';
import {
  FormControl,
  FormLabel,
  TextField,
  makeStyles,
} from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  label: {
    color: 'black',
    marginBottom: '10px',
    fontSize: '14px',
    [theme.breakpoints.down('xs')]: {
      fontSize: '12px',
    },
    textTransform: 'uppercase',
  },
  textField: {
    '& > div': {
      backgroundColor: 'white',
    },
  },
  secondary: {
    color: theme.palette.secondary.main,
    fontWeight: 'bold',
    marginBottom: '10px',
    fontSize: '14px',
    textTransform: 'uppercase',
  },
}));

const TextInput = ({
  label,
  required,
  danger,
  type,
  step = 'any',
  boldLabel = true,
  readOnly = false,
  ...other
}) => {
  const classes = useStyles();

  const inputType = {};
  switch (type) {
    case 'tel':
    case 'number':
      inputType.type = 'number';
      break;
    default:
  }

  return (
    <FormControl fullWidth>
      <FormLabel
        className={classes.label}
        style={{ fontWeight: boldLabel ? 'bold' : 'normal' }}
      >
        {label}
        {required || danger ? (
          <span className={classes.secondary}> *</span>
        ) : null}
      </FormLabel>
      <TextField
        className={classes.textField}
        variant='outlined'
        required={required}
        size='small'
        type={type}
        inputProps={
          type === 'number' ? { step } : type === 'tel' ? { step: 1 } : {}
        }
        placeholder={label.toUpperCase()}
        InputProps={{ readOnly }}
        {...other}
        {...inputType}
      />
    </FormControl>
  );
};

export default TextInput;
