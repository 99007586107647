import { useEffect, useState } from 'react';
import React from 'react';
import { GoogleMap, Marker, useJsApiLoader } from '@react-google-maps/api';
import { Box, IconButton, Paper } from '@material-ui/core';
import GoogleMapInputSuggestion from '../google-map-search-input';
import MyLocationIcon from '@material-ui/icons/MyLocation';

const containerStyle = {
  width: '100%',
  height: '500px',
};

function GoogleMapPicker({ onCenterChanged, defaultValue }) {
  const { isLoaded } = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAP_API,
  });

  const [map, setMap] = React.useState(null);
  const [center, setCenter] = useState(null);

  const [value, setValue] = useState();
  useEffect(() => {
    if (value?.location) {
      setCenter(value.location);
    }
  }, [value]);

  const getCurrentLocation = () => {
    navigator.geolocation.getCurrentPosition(
      function onSuccess(location) {
        setCenter({
          lat: location?.coords?.latitude,
          lng: location?.coords?.longitude,
        });
      },
      function onError() {
        setCenter(defaultValue);
      }
    );
  };

  const onLoad = function callback(map) {
    setMap(map);
    if (defaultValue) {
      setCenter(defaultValue);
    } else {
      getCurrentLocation();
    }
  };

  const onUnmount = React.useCallback(function callback(map) {
    setMap(null);
  }, []);

  return isLoaded ? (
    <GoogleMap
      mapContainerStyle={containerStyle}
      zoom={16}
      center={center}
      onLoad={onLoad}
      onUnmount={onUnmount}
      onCenterChanged={() => {
        if (!map) return;
        setCenter(map?.getCenter());
        onCenterChanged({
          lat: map?.getCenter()?.lat(),
          lng: map?.getCenter()?.lng(),
        });
      }}
    >
      <Box
        position='absolute'
        top={0}
        left={0}
        zIndex={1}
        width='300px'
        margin={1}
        clone
      >
        <Paper>
          <GoogleMapInputSuggestion
            freeSolo
            value={value}
            placeholder='Search Map'
            onChange={setValue}
          />
        </Paper>
      </Box>
      <Box
        position='absolute'
        bottom={0}
        right={0}
        zIndex={1}
        marginBottom={14}
        marginRight={1.2}
        padding='5px'
        clone
      >
        <Paper>
          <IconButton size='small' onClick={getCurrentLocation}>
            <MyLocationIcon />
          </IconButton>
        </Paper>
      </Box>
      <ComponentChild position={center} />
    </GoogleMap>
  ) : (
    <></>
  );
}

const ComponentChild = ({ position }) => {
  return <Marker position={position} />;
};

export default React.memo(GoogleMapPicker);
