import { Box, Typography, Divider, makeStyles } from '@material-ui/core';

import { formatNumberToAccountingStyle } from 'src/util/number-format';

const useStyles = makeStyles((theme) => ({
  root: {
    paddingLeft: theme.spacing(4),
    paddingRight: theme.spacing(1),
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
  },
  text: {
    fontWeight: theme.typography.fontWeightBold,
  },
  divider: {
    marginLeft: theme.spacing(2),
  },
}));

const TitleAndAmount = ({
  title,
  amount,
  boldText = false,
  rootStyles = null,
  divider = true,
  variant = 'body1',
}) => {
  const classes = useStyles();

  return (
    <>
      <Box
        display='flex'
        justifyContent='space-between'
        className={rootStyles ? rootStyles : classes.root}
      >
        <Typography
          variant={variant}
          className={boldText ? classes.text : null}
        >
          {title}
        </Typography>
        <Typography
          variant={variant}
          className={boldText ? classes.text : null}
        >
          {formatNumberToAccountingStyle(amount)}
        </Typography>
      </Box>
      {divider && <Divider className={classes.divider} />}
    </>
  );
};

export default TitleAndAmount;
