import React from 'react';
import { Grid } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import 'date-fns';
import LabelInput from '../../../../components/label-input';
import {
  RHFAutocompleteInput,
  RHFDateInput,
  RHFTextInput,
} from '../../../../components/rhf-controlled-input';
import autocompleteOptionsUrl from '../../../../util/autocomplete-option-urls';

const PurchaseOrderInformation = () => {
  const { t } = useTranslation();

  return (
    <Grid container spacing={3}>
      <Grid item xs={6}>
        <LabelInput
          label={t('vendor')}
          component={RHFAutocompleteInput}
          name="vendor"
          dataConfig="suppliers"
          optionsURL={autocompleteOptionsUrl.vendor}
          getOptionLabel={(option) => option.name}
          getOptionSelected={(option, value) => option.name === value.name}
          required
        />
      </Grid>
      <Grid item xs={6}>
        <LabelInput
          label={t('created-date')}
          component={RHFDateInput}
          defaultValue={new Date()}
          name="createdDate"
        />
      </Grid>
      <Grid item xs={6}>
        <LabelInput
          component={RHFTextInput}
          name="referenceNumber"
          label={t('reference-number')}
        />
      </Grid>
      <Grid item xs={6}>
        <LabelInput
          defaultValue={moment().add(3, 'days').toDate()}
          label={t('expected-delivery-date')}
          component={RHFDateInput}
          name="expectedDelivery"
        />
      </Grid>
    </Grid>
  );
};

export default PurchaseOrderInformation;
