import { Grid, Paper, Typography } from '@material-ui/core';
import React, { useState, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import TableX from '../../components/tableX';
import TitleBar from '../../components/title-bar';
import PaymentAccountForm from './form';
import config from '../../components/tableX/config';
import ActionButton from '../../components/action-button';
import SelectPopUp from '../../components/select-popup';
import { UtilityContext } from '../../components/context-provider/utilty-context';
import { UserContext } from '../../components/context-provider/user-context';
import reduceArrayString from '../../util/reduce-array-string';
import axios from 'axios';
import Status from 'src/components/status';

const PaymentAccountPage = () => {
  const { t } = useTranslation();
  const [paymentAccountForm, setPaymentAcountForm] = useState(false);
  const { showDeletePrompt, closeDeletePrompt, showSnackbar } =
    useContext(UtilityContext);
  const { checkAccess } = useContext(UserContext);
  // state for refresh table component
  const [triggerFetch, setTriggerFetch] = useState(false);
  // state for edit
  const [activeData, setActiveData] = useState(null);

  const handleOpenForm = () => {
    setActiveData(null);
    setPaymentAcountForm(true);
  };
  const handleCloseForm = () => setPaymentAcountForm(false);

  const handleEdit = (data) => {
    setActiveData(data);
    setPaymentAcountForm(true);
  };

  const handleDelete = (data) =>
    showDeletePrompt({
      handleConfirm: () => onDelete(data),
    });

  const handleSetDefault = (data) => {
    axios
      .put(
        `${process.env.REACT_APP_API}/api/v1/payment-accounts/set-default/${data._id}`
      )
      .then(({ data }) => {
        showSnackbar({ message: data?.message });
        setTriggerFetch((prev) => !prev);
      })
      .catch(({ response }) =>
        showSnackbar({ message: response?.data.message, variant: 'error' })
      );
  };

  const onDelete = (items) => {
    const ids = reduceArrayString(items);
    axios
      .delete(`${process.env.REACT_APP_API}/api/v1/payment-accounts?${ids}`)
      .then(({ data }) => {
        showSnackbar({ message: data?.message });
        setTriggerFetch((prev) => !prev);
      })
      .catch(({ response }) =>
        showSnackbar({ message: response?.data.message, variant: 'error' })
      )
      .finally(() => closeDeletePrompt());
  };

  const tableSchema = [
    {
      id: 'code',
      label: t('account-code'),
      type: 'component',
      dataConfig: [],
      components: (data) => {
        return (
          <Typography
            style={{
              display: 'inline-flex',
              alignItems: 'center',
              gap: '5px',
            }}
          >
            {data.code} {data.isDefault && <Status status="default" />}
          </Typography>
        );
      },
    },
    { id: 'name', label: t('account-name') },
    {
      id: 'createdBy',
      label: t('created-by'),
      dataConfig: ['createdBy', 'name'],
    },
    {
      id: 'action',
      sort: false,
      label: t('action'),
      type: 'component',
      dataConfig: [],
      components: (data) => {
        const actions = [
          {
            variant: 'edit',
            hidden: !checkAccess({
              feature: 'payment-account',
              action: 'update',
            }),
            onClick: handleEdit,
          },
          {
            variant: 'delete',
            hidden: !checkAccess({
              feature: 'payment-account',
              action: 'delete',
            }),
            onClick: handleDelete,
          },
          !data.isDefault && {
            variant: 'default',
            hidden: !checkAccess({
              feature: 'payment-account',
              action: 'update',
            }),
            onClick: handleSetDefault,
          },
        ];
        return actions.map(
          (action) =>
            action && (
              <ActionButton
                {...action}
                onClick={(e) => {
                  e.stopPropagation();
                  action.onClick(data);
                }}
              />
            )
        );
      },
    },
  ];

  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <TitleBar
          onClick={handleOpenForm}
          buttonText={t('create-payment-account')}
          disableButton={
            !checkAccess({ feature: 'payment-account', action: 'create' })
          }
        ></TitleBar>
      </Grid>
      <Grid item xs={12}>
        <Paper className="tableX-container">
          <TableX
            onRowClick={handleEdit}
            triggerFetch={triggerFetch}
            config={tableConfig}
            render={(data) => {
              return (
                <SelectPopUp
                  open={data.length}
                  select={data}
                  handleDelete={() => handleDelete(data)}
                />
              );
            }}
            schema={tableSchema}
          ></TableX>
        </Paper>
      </Grid>
      <PaymentAccountForm
        preData={activeData}
        setTriggerFetch={setTriggerFetch}
        open={paymentAccountForm}
        onClose={handleCloseForm}
      />
    </Grid>
  );
};

const tableConfig = {
  ...config,
  url: `${process.env.REACT_APP_API}/api/v1/payment-accounts`,
  dataConfig: ['paymentAccounts'],
  defaultSortBy: 'createdAt',
};

export default PaymentAccountPage;
