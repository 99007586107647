import React from 'react';
import { FormControl, FormLabel, makeStyles } from '@material-ui/core';
import clsx from 'clsx';

const useStyles = makeStyles(() => ({
  label: {
    color: 'black',
    marginBottom: '10px',
    fontSize: '14px',
    textTransform: 'uppercase',
  },
  root: {
    '& .MuiFormControl-marginNormal': {
      margin: '0 !important',
    },
  },
}));
const TextInputContainer = React.forwardRef(
  ({ label, children, required, boldLabel = true }, ref) => {
    const classes = useStyles();
    return (
      <FormControl ref={ref} fullWidth className={classes.root}>
        <FormLabel
          className={clsx(classes.label, { required })}
          style={{ fontWeight: boldLabel ? 'bold' : 'normal' }}
        >
          {label}
        </FormLabel>
        {children}
      </FormControl>
    );
  }
);

export default TextInputContainer;
