const tableConfig = {
  dataConfig: ['docs'],
  pageNumQuery: 'page',
  sortByQuery: 'sort',
  limitSizeQuery: 'limit',
  searchQuery: 'search',
  rowsPerPageOptions: [25, 50, 100],
};

export default tableConfig;
