import React from 'react';
import { useTranslation } from 'react-i18next';
import NumberFormatCustom from '../../../../components/number-format';
import DateFormat from '../../../../components/date-format';
import TableXClassic from '../../../../components/tableX-classic';

const OrderHistory = ({ orderInfo, triggerFetch }) => {
  const { t } = useTranslation();

  const tableSchema = [
    {
      id: 'createdAt',
      label: t('date'),
      type: 'component',
      components: [
        {
          name: DateFormat,
          customValueProp: 'value',
          props: {
            variant: 'date-time',
          },
        },
      ],
    },
    {
      id: 'activity',
      label: t('activity'),
      style: { textAlign: 'left' },
      modify: (_, data) => {
        if (data?.message) return data?.message;
        try {
          switch (data.item) {
            case 'payment': {
              const [{ id: referneceData }] = data.reference;
              return (
                <>
                  {`${data.createdBy.name} ${data.action} ${data.item} ${
                    referneceData.paidTo?.name ?? ''
                  } amount `}
                  <NumberFormatCustom
                    value={referneceData.amount}
                  ></NumberFormatCustom>
                </>
              );
            }
            case 'sale-by': {
              const [{ id: previousSale }, { id: newSale }] = data.reference;
              return `${data.createdBy.name} ${data.action} ${t(
                data.item
              )} from ${previousSale.user.fullname} to ${
                newSale.user.fullname
              }`;
            }
            case 'customer': {
              const [{ id: previousCustomer }, { id: newCustomer }] =
                data.reference;
              return `${data.createdBy.name} ${data.action} ${t(
                data.item
              )} from ${previousCustomer.fullname} to ${newCustomer.fullname}`;
            }
            default: {
              return `${data.createdBy.name} ${data.action} ${data.item}`;
            }
          }
        } catch (err) {
          console.error(err);
          return '';
        }
      },
    },
    {
      id: 'note',
      label: t('note'),
      modify: (_, data) => {
        switch (data.item) {
          case 'payment': {
            const [{ id: referneceData }] = data.reference;
            return referneceData.note ?? '';
          }
          default:
            return '';
        }
      },
    },
  ];

  return (
    <TableXClassic
      triggerFetch={triggerFetch}
      minHeight='10vh'
      url={`${process.env.REACT_APP_API}/api/v1/orders/activity/${orderInfo._id}`}
      dataConfig={['orderActivities']}
      schema={tableSchema}
    ></TableXClassic>
  );
};

export default OrderHistory;
