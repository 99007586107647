import { Checkbox, FormControlLabel } from '@material-ui/core';
import { useController } from 'react-hook-form';

const RHFCheckbox = (props) => {
  const {
    name,
    control,
    required,
    rules,
    color = 'primary',
    ...checkboxProps
  } = props;

  // using react hook form useController as value state
  const {
    field: { ref, value, onChange, ...inputProps },
  } = useController({
    name,
    defaultValue: false,
    control, //optional when use inside formContext
    rules: { required, ...rules },
  });

  return (
    <FormControlLabel
      inputRef={ref}
      value={value}
      checked={value}
      onChange={(e) => {
        onChange(e.target.checked);
      }}
      control={<Checkbox color={color} />}
      {...checkboxProps}
      {...inputProps}
    />
  );
};

export default RHFCheckbox;
