import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
} from '@material-ui/core';
import { useRef } from 'react';
import { CloseOutlined } from '@material-ui/icons';
import { useTranslation } from 'react-i18next';
import GoogleMapPicker from './GoogleMapPicker';

const GoogleMapPickerDialog = ({ open, onClose, onSave, defaultValue }) => {
  const { t } = useTranslation();
  const coordinateRef = useRef(null);

  return (
    <Dialog fullWidth maxWidth='md' open={open} onClose={onClose}>
      <DialogTitle dense>
        {t('pick-location')}
        <IconButton
          onClick={onClose}
          style={{ position: 'absolute', right: 8, top: 8 }}
        >
          <CloseOutlined />
        </IconButton>
      </DialogTitle>
      <DialogContent style={{ padding: 0, position: 'relative' }}>
        <GoogleMapPicker
          defaultValue={defaultValue?.location}
          onCenterChanged={(data) => (coordinateRef.current = data)}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>{t('cancel')}</Button>
        <Button
          variant='contained'
          disableElevation
          color='primary'
          onClick={() => {
            onSave(coordinateRef.current);
            onClose();
          }}
        >
          {t('save')}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
export default GoogleMapPickerDialog;
