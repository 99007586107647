import { Divider, Grid, Box } from '@material-ui/core';
import React from 'react';
import PriceWithLabel from '../../../../../components/price-with-label';

const TotalDisplayAmountDue = (props) => {
  const {
    tax = 0,
    costing = 0,
    total = 0,
    children,
    amountDue,
    shippingFee,
    className,
    size = 'medium',
  } = props;
  return (
    <Grid
      container
      className={className}
      spacing={size === 'medium' ? 1 : 0}
      direction='column'
    >
      {shippingFee > 0 && (
        <Grid item container justify='flex-end' xs={12}>
          <PriceWithLabel
            label={'ថ្លៃដឹកជញ្ជូន / Shipping Fee'}
            value={shippingFee} // tax now is only come from costing
          />
        </Grid>
      )}
      {costing > 0 && (
        <Grid item container justify='flex-end' xs={12}>
          <PriceWithLabel
            label={'សរុបរផ្សេងៗ / Miscellaneous'}
            value={costing + tax} // tax now is only come from costing
          />
        </Grid>
      )}
      <Grid item container justify='flex-end' xs={12}>
        <PriceWithLabel
          label={'សរុបរួមបញ្ចូលទាំងអាករ / Total Include (VAT 10%)'}
          value={total}
        />
      </Grid>
      <Grid
        item
        container
        direction='column'
        alignItems='flex-end'
        justify='center'
      >
        {children}
      </Grid>
      <Grid item>
        <Divider />
      </Grid>
      <Box bgcolor='primary.lighter' clone>
        <Grid item container justify='flex-end' xs={12}>
          <PriceWithLabel
            valueFontSize='18px'
            label={'ចំនួនទឹកប្រាក់ដែលត្រូវបង់ / Amount Due'}
            value={amountDue}
          />
        </Grid>
      </Box>
    </Grid>
  );
};

export default TotalDisplayAmountDue;
