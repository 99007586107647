import React from 'react';
import { useForm, FormProvider, useFormContext } from 'react-hook-form';

import { formHandler } from '../../../../../util/react-hook-form-helper';
import { useTranslation } from 'react-i18next';
import DialogContainer from '../../../../../components/dialog-container';
import AddressInput from '../address-input';

const ShippingAddressForm = ({
  open,
  onClose,
  onAdd,
  activeIndex,
  onUpdate,
  shippingAddress,
}) => {
  const { t } = useTranslation();
  const methods = useForm();

  const { getValues } = useFormContext();

  const state = activeIndex === null ? 'add' : 'update';

  const onSubmit = (data) => {
    if (activeIndex == null) {
      onAdd(data);
    } else {
      onUpdate(activeIndex, data);
    }
    onClose();
  };

  const onEntering = () => {
    if (activeIndex !== null) {
      const data = getValues(`shippingInfo.${activeIndex}`);
      methods.reset(data);
    } else {
      if (shippingAddress.length === 0) {
        const { firstname, lastname = '', phone } = getValues();
        const receiver = firstname ? `${firstname} ${lastname}` : '';
        methods.reset({
          receiver,
          phone,
        });
      }
    }
  };

  const onExited = () => {
    methods.reset({});
  };

  return (
    <DialogContainer
      open={open}
      title={t('shipping-address')}
      onCancel={onClose}
      maxWidth='md'
      TransitionProps={{ onEntering, onExited }}
      state='custom'
      noPadding={true}
      formId='shipping-form'
      customButtonText={t(state === 'add' ? 'add' : 'save-changes')}
    >
      <FormProvider {...methods}>
        <form
          id='shipping-form'
          onSubmit={(e) => formHandler(e, methods.handleSubmit(onSubmit))}
        >
          <AddressInput />
        </form>
      </FormProvider>
    </DialogContainer>
  );
};

export default ShippingAddressForm;
