import { Button } from '@material-ui/core';

const UpdateStatusButton = ({ status, onClick, ...other }) => {
  return (
    <Button
      color="primary"
      disableElevation
      variant="contained"
      onClick={onClick}
      {...other}
    >
      {status}
    </Button>
  );
};

export default UpdateStatusButton;
