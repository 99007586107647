import React from 'react';
import {
  FormControl,
  FormLabel,
  TextField,
  makeStyles,
  InputAdornment,
  IconButton,
} from '@material-ui/core';

const useStyles = makeStyles(() => ({
  label: {
    color: 'black',
    fontWeight: 'bold',
    marginBottom: '10px',
    fontSize: '14px',
    textTransform: 'uppercase',
  },
}));
const TextInputWithIconButton = ({ label, icon, InputProps, ...other }) => {
  const Icon = icon;
  const classes = useStyles();
  return (
    <FormControl fullWidth>
      <FormLabel className={classes.label}>{label}</FormLabel>
      <TextField
        variant='outlined'
        size='small'
        placeholder={label.toUpperCase()}
        InputProps={{
          endAdornment: (
            <InputAdornment>
              <IconButton color='primary'>
                <Icon />
              </IconButton>
            </InputAdornment>
          ),
          ...InputProps,
        }}
        {...other}
      />
    </FormControl>
  );
};

export default TextInputWithIconButton;
