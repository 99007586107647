import React, { useContext, useEffect, useState } from 'react';
import { Grid } from '@material-ui/core';
import { useForm, FormProvider } from 'react-hook-form';
import Products from './products';

import { useTranslation } from 'react-i18next';
import DialogContainer from 'src/components/dialog-container';
import { formHandler } from 'src/util/react-hook-form-helper';
import axios from 'axios';
import { UtilityContext } from 'src/components/context-provider/utilty-context';

const RepackForm = ({ open, onClose, orderId, onSuccess }) => {
  const { t } = useTranslation();
  const [repack, setRepack] = useState(null);
  const { showSnackbar } = useContext(UtilityContext);
  const methods = useForm({
    defaultValues: {
      product: [],
    },
  });

  const onEntering = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API}/api/v1/orders/repack?filter=orderId::${orderId},status::pending`
      );
      if (response.data.repacks.length > 0) {
        setRepack(response.data.repacks[0]);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (repack) {
      methods.reset({
        product: repack.items
          .filter((item) => item.packed < item.qty)
          .map((item) => ({ ...item, qty: item.qty - item.packed })),
      });
    }
  }, [repack]);

  const onSubmit = (data) => {
    axios
      .post(`${process.env.REACT_APP_API}/api/v1/orders/repack/${repack._id}`, {
        items: data.product,
      })
      .then(({ data }) => {
        showSnackbar({ message: data?.message });
        onSuccess && onSuccess(data.order);
        onClose();
      })
      .catch(({ response }) => {
        showSnackbar({
          message: response?.data?.message,
          variant: 'error',
        });
      });
  };

  return (
    <DialogContainer
      open={open}
      title={t('packing-order')}
      onCancel={onClose}
      maxWidth='md'
      TransitionProps={{ onEntering }}
      formId='repack-form'
      state='custom'
      noPadding={true}
      customButtonText={t('confirm')}
    >
      <FormProvider {...methods}>
        <form
          id='repack-form'
          onSubmit={(e) => formHandler(e, methods.handleSubmit(onSubmit))}
        >
          <Grid container>
            <Grid item xs={12}>
              <Products />
            </Grid>
          </Grid>
        </form>
      </FormProvider>
    </DialogContainer>
  );
};

export default RepackForm;
