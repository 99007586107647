import React, { useEffect, useState, useContext } from 'react';
import { Grid, makeStyles, Paper } from '@material-ui/core';
import TitleBar from '../../../components/title-bar';
import FloatingBar from '../../../components/floating-bar';
import { useParams, useHistory, useLocation } from 'react-router-dom';
import { useForm, FormProvider } from 'react-hook-form';
import axios from 'axios';
import Products from './products';
import { UtilityContext } from '../../../components/context-provider/utilty-context';
import CustomerSelector from './customer-selector';
import AddressSelector from './address-selector';
import PaymentMethodSelector from './payment-methods-selector';
import { useTranslation } from 'react-i18next';
import queryString from 'query-string';
import DateFnsUtils from '@date-io/date-fns';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import LabelInput from '../../../components/label-input';
import { RHFTextInput } from '../../../components/rhf-controlled-input';
import CostLine from './cost-line';
import SaleOrderTotal from './total';
import SaleBy from './sale-by';
import { UserContext } from 'src/components/context-provider/user-context';
import Spinner, { useSpinner } from 'src/components/spinner';
import useSubmitButton, { HiddenSubmitButton } from 'src/hooks/useSubmitButton';

const useStyles = makeStyles(() => ({
  root: {
    marginBottom: '150px',
    flexDirection: 'column',
    position: 'relative',
  },
}));

const CreateEditSaleOrder = () => {
  const classes = useStyles();
  const { t } = useTranslation();
  const history = useHistory();
  const { search } = useLocation();
  const urlQuery = queryString.parse(search);
  const { submitButtonRef, submit } = useSubmitButton();

  const { id } = useParams();
  const { showSnackbar } = useContext(UtilityContext);
  const { me } = useContext(UserContext);

  const methods = useForm({
    defaultValues: {
      costLines: [{ vendor: null }],
      orderItems: [
        {
          variant: null,
          qty: 1,
          salePrice: 0,
          discount: { id: null, amount: 0, type: 'percentage' },
          isNew: true,
        },
      ],
      shippingInfo: {},
      note: '',
      createCostLine: false,
    },
  });

  //whole form state create || edit
  const [state, setState] = useState('create');
  // state trigger customer form functionality
  const [paymentMethod, setPaymentMethod] = useState(false);
  // loading
  const [loading, setLoading] = useState(true);
  // state for customer form to create from queryString (state = null,existed,create , data= hold query data )
  const [urlQueryState, setUrlQueryState] = useState({
    state: null,
    data: null,
  });

  const customer = methods.watch('customer');

  // for reset order
  const [orderData, setOrderData] = useState(null);
  // for reset customer from urlQuery
  const [customerData, setCustomerData] = useState(null);
  // for reset stock data
  const [stockData, setStockData] = useState({});

  // spinner
  const { spinnerState, openSpinner, closeSpinner } = useSpinner();

  // useEffect on Fetch data for edit or create from query string
  useEffect(() => {
    setLoading(true);
    openSpinner({
      title: t('loading'),
    });
    if (id) {
      setState('edit');
      // fetch order data
      axios
        .get(`${process.env.REACT_APP_API}/api/v1/orders/${id}`)
        .then(({ data }) => {
          if (typeof data === 'object') {
            const { order } = data;

            // make remaining stock
            setStockData(() => {
              return order.orderItems.reduce((acc, curr) => {
                const stock = curr.variant.stock;
                if (stock) {
                  if (stock._id in acc) {
                    acc[stock._id] += curr.qty;
                  } else if (curr.isCanceled !== true) {
                    acc[stock._id] = stock.qty + curr.qty;
                  }
                }
                return acc;
              }, {});
            });

            setOrderData(order);
            setLoading(false);
            closeSpinner();
          } else {
            console.log('error reponse');
          }
        })
        .catch((err) => console.log(err));
    } else {
      const { name, phone, comment } = urlQuery;
      if (phone) {
        axios
          .get(
            `${process.env.REACT_APP_API}/api/v1/customers/find?value=${phone}`
          )
          .then(({ data }) => {
            setCustomerData(data.customer);
          })
          .catch((err) => {
            console.log(err.response);
            setState('create');
            setUrlQueryState(() => ({
              data: { name, phone, comment },
              state: 'create',
            }));
          })
          .finally(() => {
            setLoading(false);
            closeSpinner();
          });
      } else {
        setLoading(false);
        closeSpinner();
      }

      // set sale by to current user
      if (me) {
        methods.setValue('saleBy', { id: me?._id, name: me?.user?.fullname });
      }
    }
  }, []);

  useEffect(() => {
    const { comment } = urlQuery;
    if (customerData) {
      methods.setValue('customer', customerData);
      methods.setValue(
        'shippingInfo',
        customerData.shippingInfo?.[customerData.primaryShipping]
      );
      methods.setValue('note', comment);
      setUrlQueryState((prev) => ({ ...prev, state: 'existed' }));
    }
  }, [customerData]);

  useEffect(() => {
    if (orderData) {
      const resetData = { ...orderData };
      const orderItems = [...orderData.orderItems];
      const costLines = [...orderData.costLines];
      if (orderData.costLines.length > 0) {
        resetData.createCostLine = true;
      }

      if (typeof orderData.customer?.id !== 'string') {
        resetData.customer = {
          ...orderData.customer,
          ...orderData.customer.id,
        };
      }
      // create default value row
      orderItems.push({
        variant: null,
        qty: 1,
        salePrice: 0,
        discount: { id: null, amount: 0, type: 'percentage' },
        isNew: true,
      });
      costLines.push({ qty: 1, vendor: null });

      methods.reset({ ...resetData, orderItems, costLines });
    }
  }, [orderData]);

  const handleClosePaymentMethod = () => {
    setPaymentMethod(false);
  };

  const onSubmit = (data) => {
    if (!Object.keys(methods.formState.errors).length) {
      if (!paymentMethod) {
        setPaymentMethod(true);
        return;
      }

      openSpinner({
        title: t('saving'),
      });
      const { customer, ...other } = data;
      // remove createCostLine property (purpose only for frontend)
      delete other.createCostLine;

      const updateData = {
        ...other,
        customer: {
          id: customer._id,
          name: customer.fullname ?? customer.name,
          ...(customer.telegram ? { telegram: customer?.telegram } : {}),
          phone: customer.phone,
        },
      };

      const filterOrders = data.orderItems.reduce((acc, curr) => {
        const variantId = curr?.variant?._id || curr?.variant?.stock._id;
        if (variantId) {
          acc.push({ ...curr, variant: variantId });
        }
        return acc;
      }, []);

      updateData.orderItems = filterOrders;

      const updateCostline = data.costLines?.reduce((acc, curr) => {
        const { vendor, ...other } = curr;
        if (vendor) {
          acc.push({
            ...other,
            vendor: {
              id: vendor._id || vendor.id,
              name: vendor.name,
            },
          });
        }
        return acc;
      }, []);
      updateData.costLines = updateCostline;

      switch (state) {
        case 'create':
          axios
            .post(`${process.env.REACT_APP_API}/api/v1/orders`, updateData)
            .then(({ data }) => {
              showSnackbar({ message: data?.message });
              history.push('/dashboard/sale-invoice');
            })
            .catch(({ response }) => {
              showSnackbar({
                message: `${response?.data?.item ?? ''} ${t(
                  response?.data?.message
                )}`,
                variant: 'error',
                noTranslate: true,
              });
            })
            .finally(() => {
              setPaymentMethod(false);
              closeSpinner();
            });
          break;
        case 'edit':
          axios
            .put(`${process.env.REACT_APP_API}/api/v1/orders/${id}`, updateData)
            .then(({ data }) => {
              showSnackbar({ message: data?.message });
              history.push('/dashboard/sale-invoice');
            })
            .catch(({ response }) => {
              showSnackbar({
                message: `${response?.data?.item} ${t(
                  response?.data?.message
                )}`,
                variant: 'error',
                noTranslate: true,
              });
            })
            .finally(() => {
              setPaymentMethod(false);
              closeSpinner();
            });
          break;
        default:
          console.log('Error state');
      }
    }
  };

  const handleCreate = () => {
    console.log('create');
    submit();
  };

  const handleCancel = () => {
    goBackToList();
  };

  const goBackToList = () => {
    history.push('/dashboard/sale-invoice');
  };

  return (
    <FormProvider {...methods}>
      <MuiPickersUtilsProvider utils={DateFnsUtils}>
        <form id='sale-order-form' onSubmit={methods.handleSubmit(onSubmit)}>
          <Grid container className={classes.root} spacing={3}>
            <Grid item>
              <TitleBar disableButton />
            </Grid>
            <Grid item className={customer ? '' : 'hidden'}>
              <Grid container spacing={3}>
                <Grid item xs={12} lg={4} style={{ display: 'flex' }}>
                  <Paper style={{ width: '100%' }}>
                    <CustomerSelector
                      state={state}
                      loading={loading}
                      urlQueryState={urlQueryState}
                      goBackToList={goBackToList}
                    />
                  </Paper>
                </Grid>
                <Grid item xs={12} lg={8} style={{ display: 'flex' }}>
                  <Paper style={{ width: '100%' }}>
                    <AddressSelector />
                  </Paper>
                </Grid>
              </Grid>
            </Grid>
            <Grid item className={customer ? '' : 'hidden'}>
              <Paper className='container'>
                <Grid container spacing={3}>
                  <Grid item xs={12}>
                    <Products state={state} stockData={stockData} />
                    <SaleBy />
                  </Grid>
                  <Grid item xs={12}>
                    <CostLine state={state} />
                  </Grid>
                  <Grid item xs={12}>
                    <Grid container justify='space-between'>
                      <Grid item xs={6}>
                        <LabelInput
                          component={RHFTextInput}
                          name='note'
                          label={t('note')}
                          multiline
                          rows={10}
                        />
                      </Grid>
                      <Grid item xs={4}>
                        <SaleOrderTotal />
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Paper>
            </Grid>
            {customer && (
              <FloatingBar
                onCancel={handleCancel}
                confirmButtonProps={{ form: 'sale-order-form' }}
                confirmButtonText={
                  state === 'create' ? 'create-invoice' : 'edit-invoice'
                }
              />
            )}
          </Grid>
          <PaymentMethodSelector
            state={state}
            open={paymentMethod}
            onClose={handleClosePaymentMethod}
            handleCreate={handleCreate}
          />
          <HiddenSubmitButton ref={submitButtonRef} />
        </form>
      </MuiPickersUtilsProvider>
      <Spinner state={spinnerState} />
    </FormProvider>
  );
};

export default CreateEditSaleOrder;
