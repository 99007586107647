import React from 'react';
import {
  makeStyles,
  IconButton,
  useMediaQuery,
  useTheme,
} from '@material-ui/core';
import {
  CarouselProvider,
  Slider,
  Slide,
  ButtonBack,
  ButtonNext,
} from 'pure-react-carousel';
import 'pure-react-carousel/dist/react-carousel.es.css';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import ImageSlide from './image-slide';
import { sortableContainer, sortableElement } from 'react-sortable-hoc';

const useStyles = makeStyles(() => ({
  imageSliderContainer: {
    alignSelf: 'normal',
    margin: (props) => {
      return props.selected ? '10px  40px' : '0 40px';
    },
  },
  buttonPrev: {
    position: 'absolute',
    top: '50%',
    marginLeft: '-30px !important',
    left: '0',
    transform: 'translateY(-50%)',
    '&:disabled': {
      visibility: 'hidden',
    },
  },
  buttonNext: {
    position: 'absolute',
    top: '50%',
    marginRight: '-30px !important',
    right: '0',
    transform: 'translateY(-50%)',
    '&:disabled': {
      visibility: 'hidden',
    },
  },
  slider: {
    outline: 0,
    paddingTop: '5px',
    '& li>div': {
      transition: '1s ease-in',
    },
  },
  item: {
    height: '100%',
    outline: 0,
  },
}));

const SortableContainer = sortableContainer(({ children, className }) => {
  return <Slider className={className}>{children}</Slider>;
});

const SortableItem = sortableElement(({ index, handleRemove, image }) => (
  <Slide index={index} key={index}>
    <ImageSlide onRemove={handleRemove} image={image} />
  </Slide>
));

const ImagePreview = ({ images, handleRemove, onSortEnd }) => {
  const classes = useStyles({ selected: images.length > 0 });
  const theme = useTheme();
  const matchSM = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <CarouselProvider
      naturalSlideWidth={100}
      visibleSlides={matchSM ? 6 : 12}
      naturalSlideHeight={90}
      dragEnabled={false}
      totalSlides={images?.length || 0}
      className={classes.imageSliderContainer}
    >
      <div style={{ position: 'relative' }}>
        <SortableContainer
          helperClass='dragging-helper-class'
          onSortEnd={onSortEnd}
          className={classes.slider}
          axis={'x'}
        >
          {images?.map((image, index) => (
            <SortableItem
              key={index}
              className={classes.item}
              image={image}
              handleRemove={() => {
                handleRemove(index);
              }}
              index={index}
            />
          ))}
        </SortableContainer>
        {images.length > 0 && (
          <>
            <IconButton
              component={ButtonBack}
              color='primary'
              size='small'
              className={classes.buttonPrev}
            >
              <ChevronLeftIcon />
            </IconButton>
            <IconButton
              component={ButtonNext}
              color='primary'
              size='small'
              className={classes.buttonNext}
            >
              <ChevronRightIcon />
            </IconButton>
          </>
        )}
      </div>
    </CarouselProvider>
  );
};

export default ImagePreview;
