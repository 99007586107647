import React, { useState, useContext } from 'react';
import TableX from '../../components/tableX';
import TitleBar from '../../components/title-bar';
import ActionButton from '../../components/action-button';
import { Grid, Paper, Chip } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import SelectPopUp from '../../components/select-popup';
import config from '../../components/tableX/config';
import reduceArrayString from '../../util/reduce-array-string';
import axios from 'axios';
import { UtilityContext } from '../../components/context-provider/utilty-context';
import { UserContext } from '../../components/context-provider/user-context';
import DialogContainer from 'src/components/dialog-container';
import { useForm } from 'react-hook-form';
import LabelInput from 'src/components/label-input';
import { RHFTextInput } from 'src/components/rhf-controlled-input';
import RoleSelect from './create-edit/user-info/role-select';
import useDialog from 'src/hooks/useDialog';
import authApi from 'src/api/auth';
import Spinner, { useSpinner } from 'src/components/spinner';
import useMessage from 'src/hooks/useMessage';
import Status from 'src/components/status';
import LinkIcon from '@material-ui/icons/Link';

const UserPage = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const { showDeletePrompt, closeDeletePrompt, showSnackbar } =
    useContext(UtilityContext);
  const { checkAccess } = useContext(UserContext);
  const [triggerFetch, setTriggerFetch] = useState(false);
  const { openDialog, closeDialog, dialogState } = useDialog();
  const { spinnerState, openSpinner, closeSpinner } = useSpinner();
  const { showErrorResponseMessage, showSuccessMessage } = useMessage();

  const handleDelete = (data) => {
    showDeletePrompt({
      handleConfirm: () => onDelete(data),
    });
  };

  const handleEdit = (data, e) => {
    const location = `/dashboard/user/edit/${data._id}`;
    if (e?.ctrlKey) {
      window.open(location, '_blank');
    } else {
      history.push(location);
    }
  };

  const handleInviteUser = () => {
    openDialog();
  };

  const handleRefreshTable = () => {
    setTriggerFetch((prev) => !prev);
  };

  const onDelete = (items) => {
    const ids = reduceArrayString(items, 'ids[]');
    axios
      .delete(`${process.env.REACT_APP_API}/api/v1/auth/users?${ids}`)
      .then(({ data }) => {
        showSnackbar({ message: data?.key });
        setTriggerFetch((prev) => !prev);
      })
      .catch(({ response }) => {
        showSnackbar({ message: response?.data?.key, variant: 'error' });
      })
      .finally(() => closeDeletePrompt());
  };

  const handleCopyInvitLink = async (data) => {
    try {
      openSpinner({ title: t('loading') });
      const response = await authApi.createInviteLinkByUserId(data._id);
      const link = response.data.link;
      await navigator.clipboard.writeText(link);
      showSuccessMessage(t('copied-invite-link'));
    } catch (error) {
      showErrorResponseMessage(error);
      console.log(error);
    } finally {
      closeSpinner();
    }
  };

  const tableSchema = [
    {
      id: 'fullname',
      label: t('fullname'),
      dataConfig: ['user', 'fullname'],
      type: 'component',
      components: (data) => {
        return data || t('n/a');
      },
    },
    {
      id: 'email',
      label: t('email'),
      dataConfig: ['user', 'email'],
      type: 'component',
      components: (data) => {
        return data || t('n/a');
      },
    },
    {
      id: 'phone',
      label: t('phone'),
      dataConfig: ['user', 'phone'],
      type: 'component',
      components: (data) => {
        return data.length < 4 ? t('n/a') : data;
      },
    },
    {
      id: 'role',
      label: t('group'),
      type: 'component',
      dataConfig: ['roles'],
      components: (data) => (
        // sort list alphabetically then map
        <Grid container spacing={1} justify='center'>
          {data
            .sort((a, b) => (a.name > b.name ? 1 : -1))
            .map((role, idx) => {
              return (
                <Grid item>
                  <Chip
                    key={idx}
                    label={role.name}
                    color='primary'
                    size='small'
                  />
                </Grid>
              );
            })}
        </Grid>
      ),
    },
    {
      id: 'isActive',
      label: t('status'),
      type: 'component',
      components: (isActive) => {
        return <Status status={isActive ? t('active') : t('inactive')} />;
      },
    },
    {
      id: 'action',
      sort: false,
      label: t('action'),
      type: 'component',
      dataConfig: [],
      components: (data) => {
        return (
          <>
            <ActionButton
              variant='edit'
              title={t('update')}
              hidden={!checkAccess({ feature: 'user', action: 'update' })}
              onClick={(e) => {
                e.stopPropagation();
                handleEdit(data);
              }}
            ></ActionButton>
            <ActionButton
              variant='delete'
              title={t('delete')}
              hidden={!checkAccess({ feature: 'user', action: 'delete' })}
              onClick={(e) => {
                e.stopPropagation();
                handleDelete(data);
              }}
            ></ActionButton>
            <ActionButton
              title={t('copy-invite-link')}
              customIcon={LinkIcon}
              hidden={data.isActive}
              onClick={(e) => {
                e.stopPropagation();
                handleCopyInvitLink(data);
              }}
            ></ActionButton>
          </>
        );
      },
    },
  ];

  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <TitleBar
          onClick={handleInviteUser}
          buttonText={t('invite-user')}
          disableButton={!checkAccess({ feature: 'user', action: 'create' })}
        ></TitleBar>
      </Grid>
      <Grid item xs={12}>
        <Paper className='tableX-container'>
          <TableX
            triggerFetch={triggerFetch}
            onRowClick={handleEdit}
            render={(data) => {
              return (
                <SelectPopUp
                  open={data.length}
                  select={data}
                  handleDelete={() => handleDelete(data)}
                />
              );
            }}
            config={tableConfig}
            schema={tableSchema}
          ></TableX>
        </Paper>
        <InviteUserDialog
          handleRefreshTable={handleRefreshTable}
          open={dialogState.open}
          onClose={closeDialog}
        />
        <Spinner state={spinnerState} />
      </Grid>
    </Grid>
  );
};

const InviteUserDialog = ({ open, onClose, handleRefreshTable }) => {
  const { t } = useTranslation();
  const { handleSubmit, control, reset } = useForm();
  const { spinnerState, openSpinner, closeSpinner } = useSpinner({
    title: t('loading'),
  });
  const { showSuccessResponseMessage, showErrorResponseMessage } = useMessage();

  const onSubmit = async (formData) => {
    try {
      openSpinner();
      const { emailOrPhone, roles } = formData;
      const response = await authApi.inviteUser(emailOrPhone, roles);
      handleRefreshTable();
      showSuccessResponseMessage(response);
      onClose();
    } catch (error) {
      showErrorResponseMessage(error);
      console.log(error);
    } finally {
      closeSpinner();
    }
  };

  return (
    <DialogContainer
      optionalButton={{
        text: t('confirm'),
        props: {
          variant: 'contained',
          color: 'primary',
          onClick: handleSubmit(onSubmit),
        },
      }}
      TransitionProps={{
        onExited: () => {
          reset({ emailOrPhone: '', roles: [] });
        },
      }}
      open={open}
      onClose={onClose}
      title={t('invite-user')}
      onCancel={onClose}
      hideConfirmButton
    >
      <form onSubmit={handleSubmit(onSubmit)}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <LabelInput
              component={RHFTextInput}
              autoFocus
              name='emailOrPhone'
              label={t('email-or-phone')}
              control={control}
              required
            />
          </Grid>
          <Grid item xs={12}>
            <LabelInput
              component={RoleSelect}
              autoFocus
              name='roles'
              label={t('roles')}
              control={control}
              required
            />
          </Grid>
        </Grid>
        <Spinner state={spinnerState} />
      </form>
    </DialogContainer>
  );
};

const tableConfig = {
  ...config,
  url: `${process.env.REACT_APP_API}/api/v1/auth/users`,
  dataConfig: ['users'],
};

export default UserPage;
