import React, { useCallback } from 'react';
import { Chip, Grid, makeStyles, Typography } from '@material-ui/core';
import { useDropzone } from 'react-dropzone';
import { FiUpload } from 'react-icons/fi';

const useStyles = makeStyles((theme) => ({
  root: {
    outline: 'none',
    cursor: 'pointer',
    height: '100%',
    position: 'relative',
    backgroundColor: 'rgba(9,128,146,0.1)',
    backgroundImage:
      "url(\"data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' stroke='%23098092FF' stroke-width='4' stroke-dasharray='12' stroke-dashoffset='0' stroke-linecap='square'/%3e%3c/svg%3e\")",
  },
  container: {
    padding: '20px ',
    height: '100%',
    '& > div > svg': {
      fontSize: '28px',
      color: '#6a969e',
      marginRight: '15px',
    },
    '& button': {
      margin: '5px 0',
    },
  },
  overlay: {
    top: 0,
    backgroundColor: 'rgba(163,218,227,0.9)',
    position: 'absolute',
    width: '100%',
    height: '100%',
    zIndex: 3,
  },
  title: {
    fontWeight: 'bold',
    fontSize: '14px',
  },
}));

const FileUpload = ({ files, setFiles }) => {
  const classes = useStyles();

  const onDrop = useCallback(
    async (acceptedFiles) => {
      if (acceptedFiles.length > 0) {
        const newFile = acceptedFiles.filter((oldFile) => {
          if (files.files.length > 0) {
            return !files.files.find(
              (file) => JSON.stringify(file) === JSON.stringify(oldFile)
            );
          }
          return oldFile;
        });
        setFiles({ files: [...files.files, ...newFile], remove: files.remove });
      }
    },
    [files]
  );

  const handleRemove = (index) => {
    const updateFiles = [...files.files];
    updateFiles.splice(index, 1);
    setFiles({ files: updateFiles, remove: files.remove });
  };

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    accept: 'image/*',
    maxFiles: 5,
  });

  return (
    <div>
      <Typography className={classes.title}>
        ATTACH FILE(S) TO BILLING
      </Typography>
      <div {...getRootProps()} className={classes.root}>
        <input {...getInputProps()} />
        <Grid container className={classes.container} alignItems='center'>
          <Grid item xs='auto'>
            <FiUpload />
          </Grid>
          <Grid item xs>
            <Typography className={classes.title}>UPLOAD FILES</Typography>
          </Grid>
          <Grid item xs={12} container spacing={1}>
            {files?.files?.map((file, index) => (
              <Grid item xs='auto'>
                <Chip
                  onClick={(e) => e.stopPropagation()}
                  color='primary'
                  label={file.name}
                  onDelete={() => handleRemove(index)}
                />
              </Grid>
            ))}
          </Grid>
        </Grid>
        {isDragActive && <div className={classes.overlay}></div>}
      </div>
      <Typography variant='caption' style={{ fontWeight: 'bold' }} gutterBottom>
        YOU CAN UPLOAD A MAXIMUM OF 5 FILES, 5MB EACH.
      </Typography>
    </div>
  );
};

export default FileUpload;
