import React from 'react';
import { RHFAutocompleteInput } from '../../../../../components/rhf-controlled-input';
import { useTranslation } from 'react-i18next';
import autocompleteOptionsUrl from '../../../../../util/autocomplete-option-urls';

const VendorInput = ({ index, defaultValue, handleAddField }) => {
  const { t } = useTranslation();
  const optionsURL = autocompleteOptionsUrl.vendor;

  return (
    <RHFAutocompleteInput
      name={`costLines.${index}.vendor`}
      dataConfig="suppliers"
      optionsURL={`${optionsURL}`}
      getOptionLabel={(option) => option.name}
      defaultValue={defaultValue}
      autoFocus={false}
      noOptionsText={t('no-vendor-found')}
      getOptionSelected={(option, value) => option.name === value.name}
      onChange={(e, data, onChange) => {
        if (typeof data === 'object') {
          onChange(data);
          handleAddField(index);
        }
      }}
      renderOption={(option) => option.name}
    />
  );
};

export default VendorInput;
