import React, { useEffect, useState, useMemo } from 'react';
import {
  FormControl,
  FormLabel,
  TextField,
  makeStyles,
  Grid,
  Avatar,
  Typography,
} from '@material-ui/core';
import axios from 'axios';
import { Autocomplete } from '@material-ui/lab';
import debounce from 'debounce';
import readObjectWithArrayKeys from '../../../../../../util/read-object-value-with-array-keys';
import { matchSorter } from 'match-sorter';

const useStyles = makeStyles((theme) => ({
  label: {
    color: 'black',
    fontWeight: 'bold',
    marginBottom: '10px',
    fontSize: '14px',
    textTransform: 'uppercase',
  },
  danger: {
    color: theme.palette.secondary.main,
  },
}));

const CustomerListSearch = ({
  label,
  optionsURL,
  queryString = 'search',
  dataConfig,
  placeholder = '',
  value,
  onChange,
  required,
  getOptionConfig,
  ...other
}) => {
  const [list, setList] = useState([]);
  const [loading, setLoading] = useState(true);
  const classes = useStyles();

  useEffect(() => {
    const cancelToken = axios.CancelToken.source();
    fetchData('');
    return () => {
      cancelToken.cancel();
      debounceSearch.clear();
    };
  }, []);

  const fetchData = (searchText, options = { cancelToke: undefined }) => {
    const { cancelToken } = options;
    setLoading(true);
    axios
      .get(
        `${optionsURL}?${queryString}=${searchText || ''}&page=1&limit=5&sort=`,
        { cancelToken }
      )
      .then((response) => {
        setList(readObjectWithArrayKeys(response.data, dataConfig));
      })
      .catch((err) => console.log(err))
      .finally(() => {
        setLoading(false);
      });
  };

  const debounceSearch = useMemo(
    () =>
      debounce((text) => {
        fetchData(text);
      }, 500),
    []
  );

  let options = {};
  if (getOptionConfig) {
    options = {
      getOptionLabel: (option) => {
        // Value selected with enter, right from the input
        if (typeof option === 'string') {
          return option;
        }
        // Add "xxx" option created dynamically
        if (option.inputValue) {
          return option.inputValue;
        }
        // Regular option
        return readObjectWithArrayKeys(option, getOptionConfig);
      },
      filterOptions: (options, params) => {
        const filtered = matchSorter(options, params.inputValue, {
          keys: ['fullname', 'phone', 'email'],
        });
        if (params.inputValue !== '') {
          filtered.push({
            inputValue: params.inputValue,
            [getOptionConfig.join('.')]: `Add "${params.inputValue}"`,
          });
        }
        return filtered;
      },
    };
  }

  return (
    <FormControl fullWidth>
      {label && (
        <FormLabel className={classes.label}>
          {label}
          {required && <span className={classes.danger}>*</span>}
        </FormLabel>
      )}
      <Autocomplete
        options={list}
        disableClearable
        autoHighlight
        value={value}
        {...other}
        {...options}
        onChange={onChange}
        loading={loading}
        onInputChange={(event, text) => {
          setLoading(true);
          debounceSearch(text);
        }}
        renderInput={(params) => {
          return (
            <TextField
              {...params}
              autoFocus
              required={required}
              variant="outlined"
              size="small"
              placeholder={placeholder ? placeholder : label.toUpperCase()}
            />
          );
        }}
        renderOption={(option) => {
          return (
            <Grid container spacing={2} alignItems="center">
              <Grid item xs="auto">
                <Avatar />
              </Grid>
              <Grid item container spacing={1} direction="column" xs>
                <Grid item>
                  <Typography>
                    <b>{option.fullname}</b>
                  </Typography>
                  {option.email && (
                    <>
                      <Typography variant="caption">{option.email}</Typography>
                      <br />
                    </>
                  )}
                  {option.phone && (
                    <Typography variant="caption">{option.phone}</Typography>
                  )}
                </Grid>
              </Grid>
            </Grid>
          );
        }}
      ></Autocomplete>
    </FormControl>
  );
};

export default CustomerListSearch;
