import CameraAltIcon from '@material-ui/icons/CameraAlt';
import { Box, IconButton, makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => {
  return {
    root: {
      backgroundColor: theme.palette.grey[200],
      border: '3px solid white',
      borderRadius: '50%',
    },
    iconButton: {
      border: `1px solid ${theme.palette.grey[300]}`,
    },
  };
});

const CameraIconButton = ({ onClick }) => {
  const classes = useStyles();
  return (
    <Box className={classes.root}>
      <IconButton onClick={onClick} className={classes.iconButton}>
        <CameraAltIcon style={{ color: 'black' }} />
      </IconButton>
    </Box>
  );
};

export default CameraIconButton;
