import { makeStyles } from '@material-ui/core';
import { KeyboardDatePicker } from '@material-ui/pickers';
import React from 'react';
import { useController } from 'react-hook-form';
import useRootStyle from '../utils/useRootStyle';

const useStyles = makeStyles(() => ({
  root: {
    margin: 0,
  },
}));

// required to be inside MuiPickersUtilsProvider
const RHFDateInput = (props) => {
  const {
    name,
    control,
    required,
    defaultValue = null,
    variant = 'inline',
    inputVariant = 'outlined',
    size = 'small',
    format = 'dd/MM/yyyy',
    fullWidth = true,
    textAlign = 'left',
    className = '',
    onChange,
    customComponent = KeyboardDatePicker,
    ...datePickerProps
  } = props;

  const rootClasses = useRootStyle({ textAlign });
  const classes = useStyles();
  const Component = customComponent;

  // using react hook form useController as value state
  const {
    field: { ref, value, onChange: fieldOnChange, ...inputProps },
  } = useController({
    name,
    control, //optional when use inside formContext
    rules: { required },
    defaultValue,
  });

  return (
    <Component
      className={`${classes.root} ${rootClasses.root} ${className}`}
      inputVariant={inputVariant}
      autoOk
      inputRef={ref}
      variant={variant}
      size={size}
      format={format}
      margin='normal'
      value={value}
      onChange={
        onChange ? (date) => onChange(date, inputProps.onChange) : fieldOnChange
      }
      fullWidth
      {...datePickerProps}
      InputProps={{
        ...inputProps,
        fullWidth,
        required,
      }}
    />
  );
};

export default RHFDateInput;
