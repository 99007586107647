import axios from 'axios';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { RHFAutocompleteInput } from 'src/components/rhf-controlled-input';

const StorageInput = ({ index, defaultValue, rules, disabled }) => {
  const { t } = useTranslation();
  const optionsURL = `${process.env.REACT_APP_API}/api/v1/storages?search={search}&page=1&limit=10`;

  const findById = async (id) => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API}/api/v1/storages/find-by-id?value=${id}`
      );
      return response?.data?.storage;
    } catch (err) {
      console.log(err);
      return null;
    }
  };

  return (
    <RHFAutocompleteInput
      name={`product.${index}.storage`}
      dataConfig='storages'
      optionsURL={`${optionsURL}`}
      getOptionLabel={(option) => option.name ?? ''}
      noOptionsText={t('no-storage-found')}
      autoFocus={false}
      disabled={disabled}
      defaultValue={defaultValue}
      getOptionSelected={(option, value) => option.name === value.name}
      onChange={(e, data, onChange) => {
        onChange(data);
      }}
      rules={{
        validate: () => {
          return rules;
        },
      }}
      onKeyDown={async (e, onChange) => {
        if (e.key === 'Enter' && e.target.value !== '') {
          const id = e.target.value;
          const storage = await findById(id);
          if (storage) {
            onChange(storage);
          }
        }
      }}
    />
  );
};

export default StorageInput;
