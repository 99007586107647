import { Grid } from '@material-ui/core';
import React, { useState, useContext, useEffect } from 'react';
import DialogContainer from '../../../../../components/dialog-container';
import { useTranslation } from 'react-i18next';
import AddressList from '../address-list';
import { Controller, useForm, useFormContext } from 'react-hook-form';
import axios from 'axios';
import { UtilityContext } from '../../../../../components/context-provider/utilty-context';
import registerMui from '../../../../../util/react-hook-form-helper/register-mui';
import TextInput from '../../../../../components/text-input';
import InputGoogleMapSuggestion from '../../../../customer/create-edit/customer-info/address-input/input-google-map-search';
import LabelInput from '../../../../../components/label-input';
import { RHFPhoneInput } from '../../../../../components/rhf-controlled-input';
import useSubmitButton, { HiddenSubmitButton } from 'src/hooks/useSubmitButton';

const SelectorPopup = ({
  data,
  onClose,
  open,
  state,
  setState,
  customerId,
}) => {
  const { t } = useTranslation();
  const { showSnackbar } = useContext(UtilityContext);
  const { setValue, getValues } = useFormContext();
  const { register, control, handleSubmit, reset } = useForm();
  const [shippingList, setShippingList] = useState([]);
  const { submitButtonRef, submit } = useSubmitButton();

  useEffect(() => {
    setShippingList(data?.shippingInfo);
  }, [data]);

  const options = {
    list: {
      title: 'Shipping Information',
      buttonText: 'New Address',
      onAdd: () => {
        setState('create');
      },
      onCancel: onClose,
    },
    create: {
      title: 'New Address',
      buttonText: 'Create',
      onCancel: onClose,
      onAdd: () => {
        submit();
      },
    },
    edit: {
      title: 'Update Address',
      buttonText: 'Save Changes',
      onCancel: onClose,
      onAdd: () => {
        submit();
      },
    },
  };

  const fetchShippingList = () => {
    const customerId = getValues('customer._id');
    axios
      .get(`${process.env.REACT_APP_API}/api/v1/customers/${customerId}`)
      .then(({ data }) => {
        if (typeof data === 'object') {
          setShippingList(data.shippingInfo);
        } else {
          console.log('error reponse');
        }
      })
      .catch((err) => console.log(err));
  };

  useEffect(() => {
    if (open) {
      switch (state) {
        case 'edit':
          reset(getValues('shippingInfo'));
          break;
        default:
      }
    }
  }, [open]);

  const onSubmit = (newShipping) => {
    switch (state) {
      case 'create':
        axios
          .post(
            `${process.env.REACT_APP_API}/api/v1/customers/shippingInfo/${customerId}`,
            newShipping
          )
          .then(({ data }) => {
            showSnackbar({ message: t(data?.message) });
            setShippingList((prev) => [...prev, newShipping]);
            setValue('shippingInfo', data.customer.shippingInfo.pop());
            onClose();
            reset({});
          })
          .catch(({ response }) => {
            showSnackbar({
              message: t(response?.data?.message),
              variant: 'error',
            });
          });
        break;
      case 'edit':
        axios
          .put(
            `${process.env.REACT_APP_API}/api/v1/customers/shippingInfo/${customerId}`,
            newShipping
          )
          .then(({ data }) => {
            showSnackbar({ message: t(data?.message) });
            setValue('shippingInfo', newShipping);
            onClose();
            reset({});
          })
          .catch(({ response }) => {
            showSnackbar({
              message: t(response?.data?.message),
              variant: 'error',
            });
          });
        break;
      default:
        console.log('wrong state');
    }
  };

  const formHandler = (e) => {
    e.preventDefault();
    handleSubmit(onSubmit)(e);
    e.stopPropagation();
  };

  return (
    <DialogContainer
      onClose={() => {
        onClose();
      }}
      open={open}
      onCancel={options[state].onCancel}
      onAdd={options[state].onAdd}
      title={options[state].title}
      state='custom'
      TransitionProps={{
        onExited: () => {
          setState('list');
          reset({});
        },
        onEntering: () => {
          if (state === 'list') {
            fetchShippingList();
          }
        },
      }}
      customButtonText={options[state].buttonText}
    >
      <form onSubmit={formHandler}>
        <Grid container justify='center' alignItems='center' spacing={2}>
          {state === 'list' ? (
            <Grid item xs={12}>
              <AddressList
                data={shippingList}
                selectedShippingId={getValues('shippingInfo._id')}
                onChange={(newShipping) => {
                  setValue('shippingInfo', newShipping);
                  onClose();
                }}
              />
            </Grid>
          ) : (
            <>
              <Grid item xs={12}>
                <TextInput
                  required
                  label={t('receiver')}
                  {...registerMui(register('receiver'))}
                />
              </Grid>
              <Grid item xs={12}>
                <LabelInput
                  component={RHFPhoneInput}
                  name='phone'
                  control={control}
                  label={t('phone')}
                  required
                />
              </Grid>
              <Grid item xs={12}>
                <Controller
                  control={control}
                  name='location.nearby'
                  defaultValue={null}
                  render={({ field: { value, onChange } }) => {
                    return (
                      <InputGoogleMapSuggestion
                        value={value}
                        required
                        onChange={onChange}
                        label={t('nearby')}
                      />
                    );
                  }}
                ></Controller>
              </Grid>
              <Grid item xs={12}>
                <TextInput
                  required
                  multiline
                  {...registerMui(register('location.address'))}
                  rows={4}
                  label={t('address')}
                ></TextInput>
              </Grid>
              <Grid item xs={12}>
                <TextInput
                  label={t('google-map-url')}
                  {...registerMui(register('location.url'))}
                />
              </Grid>
            </>
          )}
        </Grid>
        <HiddenSubmitButton ref={submitButtonRef} />
      </form>
    </DialogContainer>
  );
};

export default SelectorPopup;
