import { Grid, Typography, makeStyles } from '@material-ui/core';
import React from 'react';
import Products from './product';

const useStyles = makeStyles((theme) => ({
  table: {
    '& td': {
      padding: '10px',
      '& p': {
        textAlign: 'right',
      },
      '& > div': {
        background: 'white',
        border: '1px solid rgba(170,170,170,0.6)',
        padding: '2px 6px',
        borderRadius: '5px',
      },
      '& svg': {
        fontSize: '16px',
      },
      '& .MuiInputBase-root': {
        padding: '3px 10px !important',
      },
    },
  },
  textRight: {
    '& input': {
      textAlign: 'right',
    },
  },
}));

const UnitConversion = ({ state }) => {
  return (
    <Grid container className='container' spacing={2}>
      <Grid item xs={12}>
        <Typography variant='h6' gutterBottom>
          Unit conversion
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Products />
      </Grid>
    </Grid>
  );
};

export default UnitConversion;
