import React from 'react';
import { Grid, Typography } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import TextInputContainer from '../../../../components/text-input-container';
import { Controller, useFormContext } from 'react-hook-form';
import { DatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import InputSelect from '../../../../components/input-select';
import LabelInput from '../../../../components/label-input';
import { RHFTextInput } from '../../../../components/rhf-controlled-input';
import ProfileUpload from '../image-upload';
import RoleSelect from './role-select';
import RHFPhoneNumberInput from 'src/components/rhf-controlled-input/phone-number-input';

const UserInfo = ({
  scale,
  setScale,
  profile,
  setProfile,
  editorRef,
  previewProfile,
  setPreviewProfile,
  setChangedProfile,
}) => {
  const { control } = useFormContext();
  const { t } = useTranslation();

  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
      <Grid container className='container' spacing={3}>
        <Grid item xs={12}>
          <ProfileUpload
            editorRef={editorRef}
            profile={profile}
            setProfile={setProfile}
            scale={scale}
            setScale={setScale}
            previewProfile={previewProfile}
            setPreviewProfile={setPreviewProfile}
            setChangedProfile={setChangedProfile}
          />
        </Grid>
        <Grid item xs={12}>
          <Typography variant='h6'>{t('information')}</Typography>
        </Grid>
        <Grid item xs={12} sm={6}>
          <LabelInput
            component={RHFTextInput}
            name='firstname'
            label={t('firstname')}
            disabled
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <LabelInput
            component={RHFTextInput}
            name='lastname'
            disabled
            label={t('lastname')}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <Controller
            control={control}
            name='gender'
            defaultValue={'None'}
            render={({ field: { value, onChange } }) => {
              return (
                <InputSelect
                  disabled
                  menu={[
                    { value: 'None', label: 'None' },
                    { value: 'Male', label: 'Male' },
                    { value: 'Female', label: 'Female' },
                  ]}
                  label={t('gender')}
                  value={value}
                  onChange={onChange}
                />
              );
            }}
          ></Controller>
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextInputContainer label={t('date-of-birth')}>
            <Controller
              control={control}
              name='dateOfBirth'
              defaultValue={null}
              render={({ field: { value, onChange } }) => {
                return (
                  <DatePicker
                    disabled
                    initialFocusedDate={new Date('2000')}
                    autoOk
                    placeholder={t('date-of-birth')}
                    variant='inline'
                    inputVariant='outlined'
                    size='small'
                    format='dd/MM/yyyy'
                    margin='normal'
                    value={value}
                    onChange={onChange}
                  />
                );
              }}
            ></Controller>
          </TextInputContainer>
        </Grid>
        <Grid item xs={12} sm={6}>
          <LabelInput
            component={RHFPhoneNumberInput}
            name='phone'
            disabled
            label={t('phone')}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <LabelInput
            component={RHFTextInput}
            name='email'
            label={t('email')}
            disabled
            type='email'
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <LabelInput
            component={RoleSelect}
            name='roles'
            label={t('roles')}
            required
          />
          {/* <TextInputContainer label={t('roles')} required={true}>
            <Controller
              control={control}
              rules={{ required: true }}
              name='roles'
              onChange={([, data]) => data}
              defaultValue={preSelectedRoles}
              render={({ field: { onChange, value, ref, ...props } }) => (
                <RoleSelect
                  inputRef={ref}
                  value={value}
                  options={allRoles}
                  getOptionLabel={(option) => option.name}
                  getOptionSelected={(option, value) =>
                    option.name === value.name
                  }
                  onChange={(e, data) => {
                    setPreSelectedRoles([]);
                    const newData = [
                      ...preSelectedRoles,
                      ...data.filter(
                        (role) => preSelectedRoles.indexOf(role) === -1
                      ),
                    ];
                    onChange(newData);
                  }}
                  renderTags={(tagValue, getTagProps) =>
                    tagValue.map((option, index) => {
                      return (
                        <Chip
                          label={option.name}
                          style={{ margin: '0 2px' }}
                          {...getTagProps({ index })}
                          color='primary'
                          size='small'
                        />
                      );
                    })
                  }
                />
              )}
            />
          </TextInputContainer> */}
        </Grid>

        <Grid item xs={12} sm={6}>
          <LabelInput
            inputProps={{ readOnly: true }}
            component={RHFTextInput}
            name='address'
            label={t('address')}
            disabled
          />
        </Grid>
      </Grid>
    </MuiPickersUtilsProvider>
  );
};

export default UserInfo;
