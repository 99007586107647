import { ceilToTwoDecimal } from './ceil-to-two-decimal';

// calculate discount (return remaining after discount)
const calculateDiscount = (total, discount) => {
  let result = total;
  if (discount) {
    const { amount, type } = discount;
    switch (type) {
      case 'percentage':
        result = total * ((100 - amount) / 100);
        break;
      case 'currency':
        result = total - amount;
        break;
      default:
    }
  }
  return ceilToTwoDecimal(result);
};

export default calculateDiscount;
