import { Grid } from '@material-ui/core';
import React, { useState, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import TitleBar from '../../components/title-bar';
import CategoryView from './category-view';
import { UserContext } from '../../components/context-provider/user-context';

const CategoryPage = () => {
  const { t } = useTranslation();
  const [categoryForm, setCategoryForm] = useState({
    state: false,
    preData: null,
  });
  const { checkAccess } = useContext(UserContext);

  const handleOpenForm = () => setCategoryForm({ state: true, preData: null });
  const handleCloseForm = () =>
    setCategoryForm({ state: false, preData: null });

  const handleEdit = (preData) => setCategoryForm({ state: true, preData });
  const handleAdd = (preData) => setCategoryForm({ state: true, preData });

  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <TitleBar
          onClick={handleOpenForm}
          buttonText={t('create-category')}
          disableButton={!checkAccess({feature: 'category', action: 'create'})}
        ></TitleBar>
      </Grid>
      <Grid item xs={12}>
        <CategoryView
          categoryForm={categoryForm}
          handleAdd={handleAdd}
          handleEdit={handleEdit}
          handleClose={handleCloseForm}
        />
      </Grid>
    </Grid>
  );
};

export default CategoryPage;
