import React, { useContext } from 'react';
import { TableRow, TableCell, TableBody } from '@material-ui/core';
import { useFieldArray, useFormContext, useWatch } from 'react-hook-form';
import { UtilityContext } from '../../../../components/context-provider/utilty-context';

import CustomTable from '../../../../components/custom-table';
import CustomRemoveIconButton from '../../../../components/custom-button/remove-icon-button';
import {
  RHFCheckbox,
  RHFDateInput,
  RHFNumberInput,
  RHFTextInput,
} from '../../../../components/rhf-controlled-input';
// import VariantInput from "./variant-input";
import VendorInput from './vendor-input';

const tableHeadData = [
  { value: 'no.', width: '30px' },
  { value: 'vendor', width: '30%' },
  { value: 'cost' },
  { value: 'tax' },
  { value: 'cost-date' },
  { value: 'reference' },
  { value: 'comment' },
  { width: '50px' },
];

const CostLine = () => {
  // form context
  const { watch, control, setFocus, setValue } = useFormContext();
  // utility context
  const { showDeletePrompt, closeDeletePrompt } = useContext(UtilityContext);

  // filedArray products
  const { fields, append, remove } = useFieldArray({
    control,
    name: 'costLines',
    shouldUnregister: true,
  });

  const createCostLine = useWatch({
    name: 'createCostLine',
    control,
  });

  const firstOrderItem = useWatch({ name: 'orderItems.0.variant', control });

  // add row item
  const handleAddField = (index) => {
    if (index === fields.length - 1) {
      append({ vendor: null }, { shouldFocus: false });
      setFocus(`costLines.${index}.costPrice`);
    }
  };

  // remove row item prompt
  const handleRemove = (index, id) => {
    showDeletePrompt({
      handleConfirm: () => onDelete(index, id),
    });
  };

  // on remove confirm
  const onDelete = (index) => {
    remove(index);
    closeDeletePrompt();
  };

  return (
    <>
      <RHFCheckbox
        disabled={firstOrderItem === null}
        name="createCostLine"
        label="Cost Line"
      />
      {createCostLine ? (
        <CustomTable.Container>
          {/* table header */}
          <CustomTable.Head data={tableHeadData} />
          {/* table body */}
          <TableBody>
            {fields.map((field, index) => {
              // get value from field
              const get = getValue(field);

              return (
                <TableRow key={field.id}>
                  <TableCell>
                    <CustomTable.CellValue>{index + 1}</CustomTable.CellValue>
                  </TableCell>
                  <TableCell>
                    <VendorInput
                      index={index}
                      handleAddField={handleAddField}
                      defaultValue={field.vendor}
                    />
                  </TableCell>
                  <TableCell>
                    <RHFNumberInput
                      name={`costLines.${index}.costPrice`}
                      defaultValue={field.costPrice}
                      textAlign="right"
                      min={0}
                      required={!!get.vendorName}
                    />
                  </TableCell>
                  <TableCell>
                    <RHFNumberInput
                      name={`costLines.${index}.tax`}
                      defaultValue={get.tax}
                      textAlign="right"
                      min={0}
                    />
                  </TableCell>
                  <TableCell>
                    <RHFDateInput
                      name={`costLines.${index}.costDate`}
                      defaultValue={field.costDate}
                    />
                  </TableCell>
                  <TableCell>
                    <RHFTextInput
                      name={`costLines.${index}.reference`}
                      defaultValue={field.reference}
                    />
                  </TableCell>
                  <TableCell>
                    <RHFTextInput
                      name={`costLines.${index}.comment`}
                      defaultValue={field.comment}
                    />
                  </TableCell>
                  <TableCell>
                    <CustomTable.CellValue component="div">
                      <CustomRemoveIconButton
                        invisible={
                          index === fields.length - 1 || fields.length <= 1
                        }
                        onClick={() => handleRemove(index, field._id)}
                      />
                    </CustomTable.CellValue>
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </CustomTable.Container>
      ) : null}
    </>
  );
};

// function to get product value
export const getValue = (data) => {
  const vendorName = data.vendor?.name ?? '';
  const costPrice = data.costPrice ?? 0;
  const tax = data.tax ?? 10;
  const reference = data.reference ?? 0;
  const comment = data.comment ?? 0;
  const totalAmount = Number(costPrice);

  return {
    vendorName,
    costPrice,
    tax,
    reference,
    comment,
    totalAmount,
  };
};

export default CostLine;
