import { Grid, makeStyles } from '@material-ui/core';
import React from 'react';
import AddressItem from './address-item';

const useStyles = makeStyles(() => ({
  root: {
    maxHeight: '500px',
    overflowY: 'auto',
    marginBottom: '10px',
  },
}));

const AddressList = ({ data, onSetPrimary, onEdit, onDelete }) => {
  const classes = useStyles();
  return (
    <Grid container spacing={2} className={classes.root}>
      {data?.map((item, index) => (
        <Grid item key={item.id} xs={6}>
          <AddressItem
            data={item}
            index={index}
            onSetPrimary={onSetPrimary}
            onDelete={onDelete}
            onEdit={onEdit}
          />
        </Grid>
      ))}
    </Grid>
  );
};

export default AddressList;
