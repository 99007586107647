import React from 'react';
import {  Grid, Typography, Switch, makeStyles, Divider, FormControlLabel } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { Controller,  useFormContext } from 'react-hook-form';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import LabelInput from '../../../../components/label-input';
import {
  RHFTextInput,
} from '../../../../components/rhf-controlled-input';

const useStyles = makeStyles(() => ({
  wrapper: {
    display: 'flex',
    alignItem: 'stretch',
    justifyContent: 'center',
    padding: '20px 0',
  },
  feature: {
    display: 'flex',
    justifyContent: 'flex-start',
  },
  featureName: {
    fontSize: 16,
    fontWeight: 'bold',
    padding: 12,
    alignSelf: 'center',
  },
  permission: {
    display: 'flex',
    flexWrap: 'wrap',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItem: 'center',
  },
  allPermissions: {
    display: 'flex',
    justifyContent: 'center',
    alignItem: 'center',
    flexDirection: 'column',
  },
  switch: {
    color: 'black',
    margin: '10px auto',
    fontSize: '14px',
    // textTransform: 'uppercase',
    fontWeight: 'bold',
  },
}));

const RoleInfo = ({ permissionData }) => {
  const { control, register } = useFormContext();
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
      <Grid container className="container" spacing={3}>
        <Grid item xs={12}>
          <LabelInput
            component={RHFTextInput}
            name="roleName"
            label={t('rolename')}
            required
          />
        </Grid>
        <Grid item xs={12}>
          <Typography variant="h6">{t('permissions')}</Typography>
        </Grid>
        {permissionData?.features.map((role, idx) => {

            // register the missing field in the oject of features array
            const featuresField = `features.${idx}`;
            register(`${featuresField}.featureName`, { value: role?.featureName});
            return (
              <Grid container spacing={0} 
                key={idx} 
                className={classes.wrapper}
                style={{
                  borderTop: `#0000003f solid ${idx !== 0 ? '1px' : '0px'}`,
                }}
              >
                <Grid item xs={4} sm ={2} className={classes.feature}>
                    <Typography className={classes.featureName}>{t( role?.featureName )}</Typography>
                </Grid>
                <Grid item xs={8} sm={10} className={classes.permission}>
                  {role?.rolePermission.map((permission, index) => {

                    // register the missing field in the object of rolePermission array
                    const rolePermissionsField = `${featuresField}.rolePermission.${index}`;
                    register(`${rolePermissionsField}.permissionName`, { value: permission?.permissionName});
                    return (
                      <Grid item xs={6} key={index} sm={3} lg={2} className={classes.allPermissions}>
                        <Controller
                          control={control}
                          name={`features.${idx}.rolePermission.${index}.boolean`}
                          defaultValue={'None'}
                          render={({ field: { value, onChange } }) => {
                            return (
                              <FormControlLabel
                                className={classes.switch}
                                control={
                                  <Switch
                                    color="primary"
                                    onChange={(e) => {
                                      onChange(e.target.checked)
                                    }} 
                                    checked={value}
                                  />
                                }
                                labelPlacement='top'
                                label={t(permission?.permissionName)}
                              />
                            );
                          }}
                        ></Controller>
                      </Grid>
                    )
                  })}
                </Grid>
                <Divider light className={classes.divider} />
              </Grid>
            )
        })}
      </Grid>
    </MuiPickersUtilsProvider>
  );
};

export default RoleInfo;
