import { Button, Grid } from '@material-ui/core';
import React, { useContext, useEffect, useState } from 'react';
import allowStatus from '../../status';
import { useTranslation } from 'react-i18next';
import { UtilityContext } from '../../../../components/context-provider/utilty-context';
import { UserContext } from '../../../../components/context-provider/user-context';
import useConfirmationPrompt from '../../../../components/useConfirmationPromp';
import CloseIcon from '@material-ui/icons/Close';
import axios from 'axios';
import PackingForm from './pack-form';
import ReturnedForm from './cancel-form';

const ActionButtonList = ({ data, onSuccess }) => {
  const { t } = useTranslation();
  const [actions, setActions] = useState([]);
  const { showDeletePrompt, showSnackbar, closeDeletePrompt } =
    useContext(UtilityContext);
  const { checkAccess } = useContext(UserContext);
  const { showPrompt, closePrompt } = useConfirmationPrompt();
  const [packingForm, setPackingForm] = useState(false);
  const [cancelForm, setCancelForm] = useState(false);

  const handleCancel = () => {
    showDeletePrompt({
      handleConfirm: () => onCancel(data._id),
      icon: CloseIcon,
    });
  };

  const handleComplete = () => {
    showPrompt({
      handleConfirm: () => onComplete(data._id),
    });
  };

  const onComplete = (id) => {
    axios
      .put(`${process.env.REACT_APP_API}/api/v1/purchase-return/complete/${id}`)
      .then(({ data }) => {
        showSnackbar({ message: data?.message });
        onSuccess();
      })
      .catch(({ response }) => {
        showSnackbar({ message: response.data?.message, variant: 'error' });
      })
      .finally(() => closePrompt());
  };

  const onCancel = (id) => {
    axios
      .put(`${process.env.REACT_APP_API}/api/v1/purchase-return/cancel/${id}`)
      .then(({ data }) => {
        showSnackbar({ message: data?.message });
        onSuccess();
      })
      .catch(({ response }) => {
        showSnackbar({ message: response.data?.message, variant: 'error' });
      })
      .finally(() => closeDeletePrompt());
  };

  useEffect(() => {
    const currentStatus = data?.currentStatus?.name;
    const isCreatedManual = !data.purchaseOrderId;
    const actionList = [];

    if (isCreatedManual) {
      allowStatus.manual.pack.includes(currentStatus) &&
        actionList.push({
          onClick: () => {
            setPackingForm(true);
          },
          value: t('pack'),
          action: 'pack',
        });

      allowStatus.manual.complete.includes(currentStatus) &&
        actionList.push({
          onClick: () => {
            handleComplete();
          },
          value: t('complete'),
          action: 'complete',
        });
      if (allowStatus.manual.cancel.includes(currentStatus)) {
        currentStatus === 'packed'
          ? actionList.push({
              onClick: () => {
                setCancelForm(true);
              },
              value: t('cancel'),
              action: 'cancel',
            })
            : actionList.push({
              onClick: () => {
                handleCancel();
              },
              value: t('cancel'),
              action: 'cancel',
            });
      }
    } else {
      allowStatus.auto.complete.includes(currentStatus) &&
        actionList.push({
          onClick: () => {
            handleComplete();
          },
          value: t('complete'),
          action: 'complete'
        });

      allowStatus.auto.cancel.includes(currentStatus) &&
        actionList.push({
          onClick: () => {
            handleCancel();
          },
          value: t('cancel'),
          action: 'cancel',
        });
    }

    setActions(actionList);
  }, [data]);

  return (
    <Grid container spacing={1}>
      {actions?.map(({ onClick, value, action }, index) => (
        <Grid item key={index}>
          {checkAccess({feature: 'purchase-return', action: action }) &&
            <Button
              variant="contained"
              color="primary"
              disableElevation
              onClick={onClick}
            >
              {value}
            </Button>
          }
        </Grid>
      ))}
      <PackingForm
        open={packingForm}
        purchaseReturnId={data._id}
        onSuccess={onSuccess}
        items={data.product}
        handlePrompt={showPrompt}
        onClose={() => setPackingForm(false)}
      />
      <ReturnedForm
        open={cancelForm}
        purchaseReturnId={data._id}
        onSuccess={onSuccess}
        items={data.product}
        handlePrompt={showPrompt}
        onClose={() => setCancelForm(false)}
      />
    </Grid>
  );
};

export default ActionButtonList;
