import React, { useState, useContext, useCallback } from 'react';
import TitleBar from '../../components/title-bar';
import ActionButton from '../../components/action-button';
import { Grid } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import SelectPopUp from '../../components/select-popup';
import config from '../../components/tableX/config';
import DateFormat from '../../components/date-format';
import NumberFormat from '../../components/number-format';
// import allowStatus from './status';
import { UtilityContext } from '../../components/context-provider/utilty-context';
import { UserContext } from '../../components/context-provider/user-context';
import axios from 'axios';
import { useTabFilter } from '../../components/tab-filter';
import TableTabFilter from '../../components/table-with-filter';
import BillingDetailPopup from './detail-popup';
import Prompt, { usePrompt } from 'src/components/prompt';
import useMessage from 'src/hooks/useMessage';
import Status from 'src/components/status';

const filterStatus = [
  { label: 'all', filter: null },
  { label: 'approved', filter: { isCanceled: false } },
  { label: 'canceled', filter: { isCanceled: true } },
];

const BillingPage = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const [triggerFetch, setTriggerFetch] = useState(false);
  const { checkAccess } = useContext(UserContext);
  const [billingDetail, setBillingDetail] = useState({
    open: false,
    data: {},
  });

  const { promptState, openPrompt, closePrompt } = usePrompt({
    title: t('cancel-billing'),
    message: t('are-you-sure'),
    buttons: [{ text: t('cancel') }, { text: t('confirm') }],
  });

  const { showErrorResponseMessage, showSuccessResponseMessage } = useMessage();

  const cancelBilling = async (arrayId) => {
    try {
      const respone = await axios.put(
        `${process.env.REACT_APP_API}/api/v1/billing/cancel`,
        null,
        { params: { id: arrayId } }
      );
      showSuccessResponseMessage(respone);
      setTriggerFetch((prev) => !prev);
      closePrompt();
    } catch (error) {
      console.error(error);
      showErrorResponseMessage(error);
    }
  };

  const handleCancel = (data) => {
    openPrompt({
      buttonActions: [
        { onClick: closePrompt },
        { onClick: () => cancelBilling([data._id]) },
      ],
    });
  };

  const handleEdit = (data, e) => {
    const location = `/dashboard/billing/edit/${data._id}`;
    if (e.ctrlKey) {
      window.open(location, '_blank');
    } else {
      history.push(location);
    }
  };

  const handleCreate = () => history.push('/dashboard/billing/create');

  const handleShowDetail = (data) => {
    setBillingDetail({ open: true, data });
  };

  const handleCloseDetail = () => {
    setBillingDetail({ open: false, data: {} });
  };

  const tableSchema = [
    {
      id: 'billingNumber.value',
      label: t('billing-number'),
      dataConfig: ['billingNumber', 'value'],
      type: 'action',
      onClick: handleShowDetail,
    },
    {
      id: 'total',
      label: t('total'),
      type: 'component',
      components: [
        {
          name: NumberFormat,
          customValueProp: 'value',
        },
      ],
    },
    {
      id: 'paidBy.name',
      label: t('paid-by'),
      dataConfig: ['paidBy', 'name'],
    },
    {
      id: 'createdAt',
      label: t('created-date'),
      type: 'component',
      components: [
        {
          name: DateFormat,
          customValueProp: 'value',
        },
      ],
    },
    {
      label: t('status'),
      sort: false,
      type: 'component',
      dataConfig: [],
      components: (data) => {
        let status = 'approved';

        if (data.isDraft) {
          status = 'draft';
        } else if (data.isCanceled) {
          status = 'canceled';
        }

        return <Status status={status} />;
      },
    },
    {
      id: 'createBy',
      label: t('created-by'),
      dataConfig: ['createdBy', 'name'],
    },
    {
      id: 'action',
      sort: false,
      label: t('action'),
      type: 'component',
      dataConfig: [],
      components: (data) => {
        const actions = [];

        !data?.isCanceled &&
          actions.push({
            variant: 'edit',
            hidden: !checkAccess({
              feature: 'billing',
              action: 'update',
            }),
            onClick: () => handleEdit(data),
            title: t('edit'),
          });

        !data?.isCanceled &&
          actions.push({
            variant: 'cancel',
            hidden: !checkAccess({
              feature: 'billing',
              action: 'update',
            }),
            onClick: (e) => {
              e.stopPropagation();
              handleCancel(data);
            },
            title: t('cancel'),
          });

        return actions.length > 0 ? (
          actions.map(
            (data, index) =>
              data && <ActionButton key={index} {...data}></ActionButton>
          )
        ) : (
          <>-</>
        );
      },
    },
  ];

  const { value, changeFilter } = useTabFilter(0);

  const defaultFilter = useCallback(() => {
    return filterStatus[value].filter;
  }, [value]);

  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <TitleBar
          onClick={handleCreate}
          buttonText={t('create-billing')}
          disableButton={!checkAccess({ feature: 'billing', action: 'create' })}
        ></TitleBar>
      </Grid>
      <Grid item xs={12}>
        <TableTabFilter
          tabFilterProps={{
            options: filterStatus.map((filter) => filter.label),
            value,
            onChange: changeFilter,
          }}
          tableXProps={{
            triggerFetch,
            onRowClick: handleShowDetail,
            defaultFilter,
            render: (data) => {
              return <SelectPopUp open={data.length} select={data} noButton />;
            },
            config: tableConfig,
            schema: tableSchema,
          }}
        />
      </Grid>
      <BillingDetailPopup state={billingDetail} onClose={handleCloseDetail} />
      <Prompt state={promptState} />
    </Grid>
  );
};

const tableConfig = {
  ...config,
  url: `${process.env.REACT_APP_API}/api/v1/billing`,
  dataConfig: ['billing'],
};

export default BillingPage;
