import { makeStyles, Typography } from '@material-ui/core';
import CustomNumberFormat from '../number-format';

const usePriceWithLabelStyle = makeStyles((theme) => ({
  root: {
    '& p:first-child': {
      fontSize: '12px',
      textTransform: 'uppercase',
      color: theme.palette.grey[600],
    },
    '& p:last-child': {
      fontSize: ({ valueFontSize }) => valueFontSize,
      fontWeight: 'bold',
    },
  },
}));

const PriceWithLabel = (props) => {
  const { label, value = 0, valueFontSize = '15px' } = props;
  const classes = usePriceWithLabelStyle({ valueFontSize });
  return (
    <div className={classes.root}>
      <Typography className='title' align='right' gutterBottom>
        {label}
      </Typography>
      <Typography className='value' align='right'>
        <CustomNumberFormat value={value} />
      </Typography>
    </div>
  );
};

export default PriceWithLabel;
