import {
  Button,
  Dialog,
  DialogActions,
  makeStyles,
  Typography,
} from '@material-ui/core';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import EditIcon from '@material-ui/icons/Edit';
import React from 'react';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles((theme) => ({
  paper: {
    padding: '25px 0',
  },
  titleContainer: {
    marginBottom: '30px',
  },
  title: {
    textAlign: 'center',
    fontSize: '18px',
    [theme.breakpoints.down('xs')]: {
      fontSize: '16px',
    },
    fontWeight: 'bold',
  },
  content: {
    padding: '0 70px',
    [theme.breakpoints.down('xs')]: {
      padding: '0 30px',
    },
  },
  actionContainer: {
    marginTop: '10px',
    paddingLeft: '70px',
    paddingRight: '70px',
    [theme.breakpoints.down('xs')]: {
      paddingLeft: '30px',
      paddingRight: '30px',
    },
    '& svg': {
      [theme.breakpoints.down('xs')]: {
        fontSize: '14px',
      },
    },
  },
}));

const DialogContainer = ({
  children,
  title,
  open,
  onClose,
  onAdd,
  state,
  onCancel,
  customButtonText,
  disableConfirmButton,
  hideConfirmButton,
  optionalButton,
  TransitionProps,
  formId,
  maxWidth = 'sm',
}) => {
  const classes = useStyles();
  const { t } = useTranslation();
  return (
    <Dialog
      open={open}
      onClose={onClose}
      classes={{ paper: classes.paper }}
      fullWidth
      maxWidth={maxWidth}
      TransitionProps={TransitionProps}
    >
      <div className={classes.titleContainer}>
        <Typography className={classes.title}>{title}</Typography>
      </div>
      <div className={classes.content}>{children}</div>
      <DialogActions className={classes.actionContainer}>
        {optionalButton && (
          <Button className='button' {...optionalButton.props}>
            {optionalButton.text}
          </Button>
        )}
        <Button
          onClick={onCancel}
          className='button'
          color='secondary'
          variant='contained'
        >
          {t('cancel')}
        </Button>
        {!hideConfirmButton && (
          <Button
            onClick={onAdd}
            className='button'
            color='primary'
            variant='contained'
            disabled={disableConfirmButton}
            type='submit'
            form={formId}
          >
            {state === 'update' ? (
              <>
                <EditIcon />
                {t('update')}
              </>
            ) : state === 'custom' ? (
              customButtonText
            ) : (
              <>
                <AddCircleIcon />
                {t('add')}
              </>
            )}
          </Button>
        )}
      </DialogActions>
    </Dialog>
  );
};

export default DialogContainer;
