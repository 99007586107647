import React, { useEffect, useState, useCallback, useContext } from 'react';
import {
  Grid,
  Paper,
  makeStyles,
  Table,
  TableHead,
  TableCell,
  TableRow,
  InputAdornment,
  TextField,
  Box,
} from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';
import CategoryList from './category-list';
import axios from 'axios';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';
import PaginationCustom from '../../../components/pagination';
import debounce from 'debounce';
import CategoryForm from '../form';
import { UtilityContext } from '../../../components/context-provider/utilty-context';
import { Typography } from '@material-ui/core';

const useStyles = makeStyles(() => ({
  root: {
    '& > div': {
      width: '100%',
    },
    '& .MuiTreeItem-iconContainer': {
      '& svg': {
        fontSize: '20px',
      },
    },
  },
  tableHead: {
    '& th': {
      fontWeight: 'bold',
    },
  },
}));

const CategoryView = ({ categoryForm, handleClose, handleEdit, handleAdd }) => {
  const classes = useStyles();
  const { showDeletePrompt, closeDeletePrompt, showSnackbar } =
    useContext(UtilityContext);
  const { t } = useTranslation();
  const [list, setList] = useState({ parents: [], childrens: {} });
  const [search, setSearch] = useState('');
  const [resetExpanded, setResetExpanded] = useState('');
  const [pagination, setPagination] = useState({
    page: 1,
    totalDocs: 0,
    limit: 5,
  });

  useEffect(() => {
    fetchList(search, pagination.page, pagination.limit);
  }, [pagination.page, pagination.limit]);

  const fetchList = (
    searchText = search,
    page = pagination.page,
    limit = pagination.limit
  ) => {
    axios
      .get(
        `${process.env.REACT_APP_API}/api/v1/categories?&getChildren=true&search=${searchText}&parentId=null&limit=${limit}&page=${page}`
      )
      .then(({ data }) => {
        setList((prev) => ({ ...prev, parents: data.categories }));
        setPagination((prev) => ({ ...prev, totalDocs: data.totalDocs }));
      })
      .catch((err) => console.log(err));
  };

  const fetchChildren = (parentId) => {
    return new Promise((resolve, reject) => {
      axios
        .get(
          `${process.env.REACT_APP_API}/api/v1/categories?getChildren=true&parentId=${parentId}`
        )
        .then(({ data }) => {
          if (data.categories.length !== 0) {
            setList((prev) => ({
              ...prev,
              childrens: {
                ...prev.childrens,
                [parentId]: data.categories,
              },
            }));
          }
          resolve(parentId);
        })
        .catch((err) => {
          console.log(err);
          reject(parentId);
        });
    });
  };

  const debounceSearch = useCallback(
    debounce((text) => {
      fetchList(text, 1);
      setSearch(text);
    }, 500),
    [pagination.page, pagination.limit]
  );

  const handleChangePage = (e, page) =>
    setPagination((prev) => ({ ...prev, page }));

  const handleChangeRowsPerPage = (e) =>
    setPagination((prev) => ({ ...prev, limit: e.target.value }));

  const handleReset = () => {
    setResetExpanded((prev) => !prev);
  };

  const handleDelete = (data) =>
    showDeletePrompt({
      handleConfirm: () => onDelete(data),
    });

  const onDelete = (item) => {
    axios
      .delete(`${process.env.REACT_APP_API}/api/v1/categories/${item._id}`)
      .then(({ data }) => {
        if (item.parentId && item.ancestors.length > 1) {
          fetchChildren(item.ancestors[1]._id);
          fetchChildren(item.parentId);
        } else {
          fetchList();
        }
        showSnackbar({ message: data?.message });
      })
      .catch(({ response }) =>
        showSnackbar({ message: response?.data.message, variant: 'error' })
      )
      .finally(() => closeDeletePrompt());
  };

  return (
    <Paper className={clsx('container', classes.root)}>
      <Grid container>
        <Grid item container justify="flex-end" xs={12}>
          <TextField
            variant="outlined"
            size="small"
            placeholder={t('search')}
            onChange={(e) => debounceSearch(e.target.value)}
            InputProps={{
              endAdornment: (
                <InputAdornment>
                  <SearchIcon />
                </InputAdornment>
              ),
            }}
          ></TextField>
        </Grid>
        <Grid item xs={12}>
          <Table className={classes.tableHead}>
            <TableHead>
              <TableRow>
                <TableCell width="35px"></TableCell>
                <TableCell width="30px">Icon</TableCell>
                <TableCell>Category</TableCell>
                <TableCell width="25%" style={{ textAlign: 'right' }}>
                  Action
                </TableCell>
              </TableRow>
            </TableHead>
          </Table>
        </Grid>
        <Grid item xs={12}>
          {list?.parents?.length > 0 ? (
            <CategoryList
              handleAdd={handleAdd}
              handleEdit={handleEdit}
              handleDelete={handleDelete}
              fetchList={fetchList}
              list={list}
              resetExpanded={resetExpanded}
              pagination={pagination}
              fetchChildren={fetchChildren}
            />
          ) : (
            <Box mt={2}>
              <Typography align="center">No Item Available</Typography>
            </Box>
          )}
        </Grid>
        <Grid item xs={12}>
          <PaginationCustom
            page={pagination.page}
            total={pagination.totalDocs}
            rowsPerPage={pagination.limit}
            onChangePagination={handleChangePage}
            onChangeRowsPerPage={handleChangeRowsPerPage}
            count={Math.floor(
              (pagination.totalDocs + pagination.limit - 1) / pagination.limit
            )}
          />
        </Grid>
      </Grid>
      <CategoryForm
        open={categoryForm.state}
        fetchList={fetchList}
        fetchChildren={fetchChildren}
        handleReset={handleReset}
        preData={categoryForm.preData}
        onClose={handleClose}
      />
    </Paper>
  );
};

export default CategoryView;
