import React, { useState, useEffect, useCallback } from 'react';
import {
  TableContainer,
  Table,
  InputAdornment,
  TextField,
  Typography,
  Box,
  Grid,
  LinearProgress,
} from '@material-ui/core';
import PaginationX from './pagination';
import SearchIcon from '@material-ui/icons/Search';
import TableHeadX from './head';
import readObjectWithArrayKeys from './utils/read-object-value-with-array-keys';
import TableBodyX from './body';
import axios from 'axios';
import useStyles from './style';
import debounce from 'debounce';
import combineObjectKeyValue from './utils/combine-object-key-value';
import { useTranslation } from 'react-i18next';

const TableX = ({
  schema,
  config,
  triggerFetch,
  render,
  renderFilterInput,
  onRowClick,
  minHeight,
  defaultFilter,
  disableSearch,
  select = true,
}) => {
  const {
    url,
    pageNumQuery,
    limitSizeQuery,
    dataConfig,
    sortByQuery,
    defaultSort = {},
    searchQuery,
    initialDate,
  } = config;
  const classes = useStyles();
  const [sort, setSort] = useState(defaultSort);
  const [dataList, setDataList] = useState([]);
  const [selected, setSelected] = useState([]);
  const [page, setPage] = useState(1);
  const [isLoading, setIsLoading] = useState(true);
  const [totalDocs, setTotalDocs] = useState(0);
  const [search, setSearch] = useState('');
  const [responseData, setResponseData] = useState(null);
  const [filter, setFilter] = useState(defaultFilter);
  const [dateRange, setDateRange] = useState({
    from: initialDate?.from || null,
    to: initialDate?.to || null,
  });
  const [rowsPerPage, setRowsPerPage] = useState(
    config.rowsPerPageOptions[0] || 25
  );
  const { t } = useTranslation();

  useEffect(() => {
    setPage(1);
    setFilter(defaultFilter);
  }, [defaultFilter]);

  const fetchData = (pageNum = page, searchText = search) => {
    setIsLoading(true);
    axios
      .get(
        `${url}?${searchQuery}=${searchText}&${pageNumQuery}=${pageNum}&${limitSizeQuery}=${rowsPerPage}&${sortByQuery}=${combineObjectKeyValue(
          sort
        )}&filter=${combineObjectKeyValue(filter)}${
          renderFilterInput
            ? `${
                dateRange.from
                  ? `&fromDate=${dateRange.from}&toDate=${dateRange.to} `
                  : ''
              }`
            : ''
        }`
      )
      .then((response) => {
        setDataList(
          dataConfig ? readObjectWithArrayKeys(response.data, dataConfig) : []
        );
        setSelected([]);
        setTotalDocs(response.data.totalDocs);
        setResponseData(response.data);
      })
      .catch((err) => {
        console.log(err);
        setDataList([]);
        setTotalDocs(0);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  useEffect(() => {
    fetchData();
  }, [page, rowsPerPage, triggerFetch, sort, filter, dateRange]);

  const debounceSearch = useCallback(
    debounce((text) => {
      fetchData(1, text);
      setSearch(text);
    }, 500),
    [page, rowsPerPage, sort, filter, dateRange]
  );

  const handleSort = (event, property) => {
    const isSorting = property in sort;
    let order = null;
    if (isSorting) {
      if (sort[property] === 'asc') {
        order = 'desc';
      } else if (sort[property] === 'desc') {
        return setSort((prev) => {
          const newSort = { ...prev };
          delete newSort[property];
          setSort(newSort);
        });
      } else {
        order = 'asc';
      }
    } else {
      order = 'asc';
    }
    setSort(() => ({ [property]: order }));
  };

  const handleChangePagination = (event, page) => {
    setPage(page);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(event.target.value);
  };

  const handleSelectMultiple = (e) => {
    if (e.target.checked) {
      setSelected(dataList);
    } else {
      setSelected([]);
    }
  };

  const handleSelect = (e, row) => {
    e.stopPropagation();
    if (e.target.checked) {
      setSelected((prev) => [...prev, row]);
    } else {
      const filterSelected = selected.filter(
        (item) => JSON.stringify(item) !== JSON.stringify(row)
      );
      setSelected(filterSelected);
    }
  };

  return (
    <Grid
      container
      className={classes.root}
      direction='column'
      style={{ minHeight }}
      spacing={2}
    >
      <Grid item>
        <div
          style={{
            display: 'flex',
            justifyContent: renderFilterInput ? 'space-between' : 'flex-end',
          }}
        >
          {renderFilterInput &&
            renderFilterInput({
              setFilter,
              setDateRange,
              dateRange,
              dataList,
            })}
          {!disableSearch && (
            <TextField
              variant='outlined'
              size='small'
              placeholder={t('search')}
              onChange={(e) => debounceSearch(e.target.value)}
              InputProps={{
                endAdornment: (
                  <InputAdornment>
                    <SearchIcon />
                  </InputAdornment>
                ),
              }}
            ></TextField>
          )}
        </div>
      </Grid>
      <Grid item xs>
        {isLoading ? (
          <LinearProgress />
        ) : (
          <TableContainer style={{ overflow: isLoading ? 'hidden' : 'auto' }}>
            <Table className='tableX'>
              <TableHeadX
                dataList={dataList}
                selected={selected}
                handleSelectMultiple={handleSelectMultiple}
                sort={sort}
                handleSort={handleSort}
                schema={schema}
                select={select}
              ></TableHeadX>
              <TableBodyX
                selected={selected}
                handleSelect={handleSelect}
                handleClick={onRowClick}
                dataList={dataList}
                schema={schema}
                select={select}
              ></TableBodyX>
            </Table>
          </TableContainer>
        )}
        {!isLoading && dataList.length === 0 ? (
          <Box marginTop={2}>
            <Typography align='center'>No Item Available</Typography>
          </Box>
        ) : null}
      </Grid>
      <Grid item>
        {render && render(selected, responseData)}
        <PaginationX
          page={page}
          total={totalDocs}
          rowsPerPageOptions={config.rowsPerPageOptions}
          rowsPerPage={rowsPerPage}
          onChangePagination={handleChangePagination}
          onChangeRowsPerPage={handleChangeRowsPerPage}
          count={Math.floor((totalDocs + rowsPerPage - 1) / rowsPerPage)}
        />
      </Grid>
    </Grid>
  );
};

export default TableX;
