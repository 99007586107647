import React from 'react';
import {
  FormControl,
  FormLabel,
  TextField,
  makeStyles,
} from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';

const useStyles = makeStyles(() => ({
  label: {
    color: 'black',
    fontWeight: 'bold',
    marginBottom: '10px',
    fontSize: '14px',
    textTransform: 'uppercase',
  },
}));

const ListInputSearch = ({
  label,
  placeholder = '',
  value,
  onChange,
  required,
  list,
  ...other
}) => {
  const classes = useStyles();

  return (
    <FormControl fullWidth>
      {label && <FormLabel className={classes.label}>{label}</FormLabel>}
      <Autocomplete
        options={list}
        disableClearable
        value={value}
        {...other}
        onChange={onChange}
        renderInput={(params) => {
          return (
            <TextField
              {...params}
              onChange={(e) => {
                params.inputProps.onChange(e);
              }}
              required={required}
              variant="outlined"
              size="small"
              inputProps={{
                ...params.inputProps,
                autoComplete: 'new-password',
              }}
              placeholder={label ? label.toUpperCase() : placeholder}
            />
          );
        }}
      ></Autocomplete>
    </FormControl>
  );
};

export default ListInputSearch;
