/* eslint-disable react/display-name */
import React, { useContext, useState } from 'react';
import { Grid, makeStyles, IconButton } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';
import TextInput from '../../../../../../components/text-input';
import TextInputTag from '../../../../../../components/text-input-tag';
import { Controller, useFormContext } from 'react-hook-form';
import registerMui from '../../../../../../util/react-hook-form-helper/register-mui';
import { UtilityContext } from '../../../../../../components/context-provider/utilty-context';

const useStyles = makeStyles(() => ({
  removeButton: {
    display: 'flex',
    alignItems: 'flex-end',
    justifyContent: 'center',
  },
}));

const AttributeOptionField = React.forwardRef(
  ({ data, onDelete, index, handleTriggerGenerate, state }, ref) => {
    const classes = useStyles();
    const { t } = useTranslation();
    const [oldName, setOldName] = useState(null);
    const { getValues, setValue, register, control } = useFormContext();
    const { showSnackbar } = useContext(UtilityContext);

    const handleUpdate = (e) => {
      const values = getValues();
      const changeValue = e.target.value;
      if (changeValue !== '') {
        const updateVariants = values.variants?.map((variant) => {
          const attr = {
            ...variant.attr,
          };
          const oldData = attr[oldName];
          delete attr[oldName];
          attr[changeValue] = oldData;
          return { ...variant, attr };
        });
        setValue('variants', updateVariants);
      } else {
        setValue(`attributes.${index}.name`, oldName);
      }
    };

    return (
      <>
        <Grid item xs={5}>
          <TextInput
            {...registerMui(register(`attributes.${index}.name`))}
            defaultValue={data.name || null}
            required
            onFocus={(e) => setOldName(e.target.value)}
            onBlur={handleUpdate}
            label={t('attribute')}
          ></TextInput>
        </Grid>
        <Grid item xs={6}>
          <Controller
            control={control}
            defaultValue={data.options || null}
            name={`attributes.${index}.options`}
            rules={{ required: true }}
            render={({ field }) => {
              return (
                <TextInputTag
                  {...field}
                  onChange={(data) => {
                    const productName = getValues('name');
                    if (!productName.trim()) {
                      return showSnackbar({
                        message: 'fill-product-name',
                        variant: 'error',
                      });
                    }
                    field.onChange(data);
                    handleTriggerGenerate();
                  }}
                  disabled={state === 'edit'}
                  disableChipRemove={state === 'edit'}
                  label={t('options')}
                ></TextInputTag>
              );
            }}
          ></Controller>
        </Grid>
        <Grid item xs={1} className={classes.removeButton}>
          <IconButton
            className={index === 0 || state === 'edit' ? 'invisible' : ''}
            onClick={() => onDelete(index)}
            color="secondary"
          >
            <DeleteOutlineIcon />
          </IconButton>
        </Grid>
      </>
    );
  }
);

export default AttributeOptionField;
