import { Button, Grid, Typography } from '@material-ui/core';
import React, { useContext, useState } from 'react';
import UpdateStatusButton from './button';
import { useTranslation } from 'react-i18next';
import { UtilityContext } from '../../../../components/context-provider/utilty-context';
import axios from 'axios';
import ShippingForm from './shipping-form';
import { UserContext } from '../../../../components/context-provider/user-context';
import PackingForm from './pack-form';
import ReturnedForm from './returned-form';
import FailedForm from './failed-form';
import RepackForm from './repack-form';
import useDialog from 'src/hooks/useDialog';
import ShippingExpenseDialog from 'src/components/ShippingExpense/ShippingExpenseDialog';

const UpdateStatusButtonList = ({
  orderId,
  status = '',
  onSuccess,
  returnable,
  orderItems,
  isPOS,
  isCredited,
  shippingExpense,
  order,
}) => {
  const [shippingForm, setShippingForm] = useState(false);
  const [packingForm, setPackingForm] = useState(false);
  const [repackForm, setRepackForm] = useState(false);
  const [returnedForm, setReturnedForm] = useState({
    state: false,
    data: null,
  });
  const [failedForm, setFailedForm] = useState(false);
  const {
    dialogState: shippingExpenseDialog,
    openDialog: openShippingExpenseDialog,
    closeDialog: closeShippingExpenseDialog,
  } = useDialog();

  const { t } = useTranslation();
  const { showSnackbar, showPrompt, closePrompt } = useContext(UtilityContext);
  const { deliveryInfo, checkAccess, setting } = useContext(UserContext);

  const handlePrompt = (newStatus) => {
    const title = t('action-confirmation');
    const message = (
      <Typography>
        {t('action-cant-undone')}
        <br /> {t('are-you-sure')}
      </Typography>
    );

    showPrompt({
      handleConfirm: () => onConfirm(newStatus),
      title,
      message,
      disableIcon: true,
      confirmButtonProps: {
        color: 'primary',
        variant: 'contained',
        style: { borderRadius: 0 },
        disableElevation: true,
      },
      confirmButtonText: t('confirm'),
      cancelButtonText: t('cancel'),
    });
  };

  const onConfirm = (newStatus) => {
    axios
      .put(`${process.env.REACT_APP_API}/api/v1/orders/status/${orderId}`, {
        status: newStatus,
      })
      .then(({ data }) => {
        showSnackbar({ message: data.message });
        onSuccess && onSuccess();
        setShippingForm(false);
      })
      .catch(({ response }) =>
        showSnackbar({ message: response?.data.message, variant: 'error' })
      )
      .finally(() => {
        closePrompt();
      });
  };

  const handleReturned = (data) => {
    if (data !== null || !data?.target) {
      setReturnedForm({ state: true, data });
    }
  };

  const handleShipping = () => {
    setShippingForm(true);
  };

  const handleFailed = () => {
    setFailedForm(true);
  };

  const handlePacking = () => {
    if (setting?.order?.trackStock) {
      setPackingForm(true);
    } else {
      handlePrompt('packed');
    }
  };

  const handleRepack = () => {
    if (
      setting?.order?.trackStock === false &&
      setting?.order?.repack === false
    ) {
      handlePrompt('packed');
    } else {
      setRepackForm(true);
    }
  };

  const handleAddShippingExpense = () => {
    openShippingExpenseDialog({ amount: order?.shippingFee });
  };

  const returnActions = [
    ...(!isCredited // if order already add credit note no longer allow normal return
      ? [
          {
            label: 'return',
            status: 'returned',
            onClick: handleReturned,
          },
        ]
      : []),
    {
      label: 'credit-note',
      status: 'returned',
      onClick: () => handleReturned({ isCredited: true }),
    },
  ];

  const statusActionList = {
    pending: [
      { status: 'approved', label: 'approve' },
      { status: 'rejected', label: 'reject' },
    ],
    approved: [
      {
        status: 'packed',
        label: 'pack',
        onClick: handlePacking,
      },
    ],
    packed: [
      {
        label: 'ship',
        status: 'shipping',
        onClick: deliveryInfo.onlyManualDelivery
          ? handleAddShippingExpense
          : handleShipping,
      },
    ],
    ...('repack' in setting?.order && setting.order.repack === false
      ? {
          repack: [
            {
              label: 'pack',
              status: 'packed',
              onClick: handleRepack,
            },
          ],
        }
      : {}),
    shipping: [
      { status: 'delivered' },
      { status: 'delivery-failed' },
      { status: 'failed', onClick: handleFailed },
    ],
    'delivery-failed': [
      {
        label: 'ship',
        status: 'shipping',
        onClick: deliveryInfo.onlyManualDelivery
          ? () => handlePrompt('shipping')
          : handleShipping,
      },
    ],
    delivered: returnActions,
    'partially-returned': returnActions,
    completed: returnActions,
  };

  return (
    <Grid container spacing={1}>
      {/* only show add shipping expense when is from POS and no shipping expense */}
      {isPOS && !shippingExpense && (
        <Grid item>
          <Button
            color='primary'
            disableElevation
            variant='contained'
            onClick={handleAddShippingExpense}
          >
            {t('add-shipping-expense')}
          </Button>
        </Grid>
      )}
      {status && statusActionList[status]
        ? statusActionList[status]?.map((item, index) => (
            <Grid item key={index}>
              {checkAccess({ feature: 'orders', action: item.status }) && (
                <UpdateStatusButton
                  disabled={returnable.state && returnable.items.length === 0}
                  status={t(item.label) || t(item.status)}
                  onClick={
                    item.onClick
                      ? item.onClick
                      : () => handlePrompt(item.status)
                  }
                />
              )}
            </Grid>
          ))
        : null}
      <ShippingForm
        open={shippingForm}
        orderId={orderId}
        onSuccess={onSuccess}
        handlePrompt={handlePrompt}
        onClose={() => setShippingForm(false)}
      />
      <PackingForm
        open={packingForm}
        orderId={orderId}
        onSuccess={onSuccess}
        orderItems={orderItems}
        handlePrompt={handlePrompt}
        onClose={() => setPackingForm(false)}
      />
      <ReturnedForm
        open={returnedForm.state}
        isCredited={returnedForm.data?.isCredited}
        orderId={orderId}
        onSuccess={onSuccess}
        orderItems={returnable.items}
        handlePrompt={handlePrompt}
        onClose={() => setReturnedForm((prev) => ({ ...prev, state: false }))}
      />

      <FailedForm
        open={failedForm}
        orderId={orderId}
        onSuccess={onSuccess}
        orderItems={orderItems}
        handlePrompt={handlePrompt}
        onClose={() => setFailedForm(false)}
      />
      <RepackForm
        open={repackForm}
        orderId={orderId}
        onSuccess={onSuccess}
        onClose={() => setRepackForm(false)}
      />
      <ShippingExpenseDialog
        orderId={orderId}
        onSuccess={onSuccess}
        newStatus={isPOS ? '' : 'shipping'}
        onClose={closeShippingExpenseDialog}
        defaultFormValue={shippingExpenseDialog.data}
        open={shippingExpenseDialog.open}
        disableSkip={isPOS}
      />
    </Grid>
  );
};

export default UpdateStatusButtonList;
