import {
  InputBase,
  TextField,
  Typography,
  Button,
  makeStyles,
} from '@material-ui/core';
import React, { useState, useCallback } from 'react';
import axios from 'axios';
import debounce from 'debounce';
import registerMui from '../../util/react-hook-form-helper/register-mui';
import clsx from 'clsx';

const useStyles = makeStyles(() => ({
  root: {
    '& .MuiOutlinedInput-adornedEnd': {
      padding: '0 3px',
    },
  },
  hidden: {
    display: 'none',
  },
}));

const SlugInput = ({
  register,
  textField,
  placeholder,
  name,
  getData,
  setValue,
  enableGenerate,
  ...other
}) => {
  const classes = useStyles();
  const [available, setAvailable] = useState(null);
  const [invalid, setInvalid] = useState(false);

  const debounceSearch = useCallback(
    debounce((text) => {
      setAvailable(null);
      setInvalid(false);
      if (text === '' || text === other.defaultValue) return;

      axios
        .get(`${process.env.REACT_APP_API}/api/v1/stocks/slug/${text}`)
        .then((response) => {
          if (!response.data.exist) {
            return setAvailable(true);
          }
          return setAvailable(false);
        })
        .catch((error) => {
          if (error.response?.data.key) {
            setAvailable(false);
            return setInvalid(true);
          }
          return setAvailable(false);
        });
    }, 500),
    [other.defaultValue]
  );

  const generateSKU = () => {
    const data = getData();
    if (data) {
      axios
        .get(
          `${process.env.REACT_APP_API}/api/v1/stocks/slug/generate?name=${data}`
        )
        .then(({ data }) => {
          setValue(name, data?.slug);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  const Component = textField ? TextField : InputBase;

  const isAvailable = available === null ? null : available === true;

  const props = {};
  const adornment = (
    <div className='action' style={{ display: 'flex', alignItems: 'center' }}>
      <Typography
        style={{
          marginLeft: '5px',
          // minWidth: '60px',
          textAlign: 'right',
        }}
        className={clsx({ [classes.hidden]: available === null })}
        variant='caption'
        color={isAvailable && !invalid ? 'primary' : 'secondary'}
      >
        {invalid ? 'invalid' : isAvailable ? 'available' : 'unavailable'}
      </Typography>
      {enableGenerate && (
        <Button
          onClick={generateSKU}
          size='small'
          color='primary'
          variant='contained'
          disableElevation
          style={{ marginLeft: '5px' }}
        >
          Generate
        </Button>
      )}
    </div>
  );

  if (textField) {
    props.InputProps = { endAdornment: adornment };
  } else {
    props.endAdornment = adornment;
  }

  return (
    <Component
      className={clsx({ [classes.root]: enableGenerate })}
      style={{ background: 'white' }}
      {...props}
      {...other}
      placeholder={placeholder}
      {...registerMui(
        register(name, {
          validate: (value) => {
            if (value.length === 0 || (available && !invalid)) {
              return true;
            } else if (available === null && value.length > 0) {
              return true;
            }
            return false;
          },
        })
      )}
      onChange={(e) => {
        debounceSearch(e.target.value);
      }}
    />
  );
};

export default SlugInput;
