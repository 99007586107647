import React, { useState, useEffect } from 'react';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import KeyboardArrowRightIcon from '@material-ui/icons/KeyboardArrowRight';
import TreeView from '@material-ui/lab/TreeView';
import { CircularProgress, Collapse, makeStyles } from '@material-ui/core';
import CategoryItem from './category-items';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    '& .MuiTreeItem-content': {
      padding: '10px 0',
    },
    '& .MuiTreeItem-iconContainer': {
      margin: '0 10px',
    },
    '& .MuiTreeItem-root.Mui-selected > .MuiTreeItem-content .MuiTreeItem-label':
      {
        backgroundColor: theme.palette.action.hover,
      },
    '& li:nth-child(even)': {
      '&>.MuiTreeItem-content': {
        backgroundColor: theme.palette.primary.lighter,
      },
    },
  },
}));

const CategoryList = ({
  list,
  fetchChildren,
  handleEdit,
  handleAdd,
  handleDelete,
  pagination,
  resetExpanded,
}) => {
  const classes = useStyles();
  const [loading, setLoading] = useState([]);
  const [expanded, setExpanded] = useState([]);
  const [selected, setSelected] = useState([]);

  const handleToggle = (event, nodeIds) => {
    setExpanded(() => nodeIds);
  };

  const handleSelect = (event, nodeIds) => {
    setSelected(nodeIds);
  };

  // clear expanded state on change pagination
  useEffect(() => {
    setExpanded([]);
  }, [pagination.page, pagination.limit, resetExpanded]);

  useEffect(() => {
    // run only on toggle
    if (expanded.includes(selected)) {
      const selectedId = selected?.split(':').pop();
      setLoading((prev) => [...prev, selectedId]);
      fetchChildren(selectedId).then((parentId) => {
        setLoading((prev) => {
          return prev.filter((item) => item !== parentId);
        });
      });
    }

    // run when untoggle
    // clear expanded state after untoggle
    if (!expanded.includes(selected) && typeof selected === 'string') {
      const tempExpanded = [...expanded].filter((item) => {
        return !new RegExp(`^${selected}`).test(item);
      });
      setExpanded(tempExpanded);
      setSelected(null);
    }
  }, [expanded, selected]);

  const renderTree = (node, parentLevel = []) =>
    node?.map((item) => {
      let level = [...parentLevel, item._id];
      const isLoading = loading.includes(item._id);
      return (
        <CategoryItem
          key={item._id}
          icon={item?.icon?.key}
          nodeId={level.join(':')}
          collapseIcon={
            isLoading ? (
              <CircularProgress
                style={{ width: '100%', height: '100%' }}
              ></CircularProgress>
            ) : null
          }
          labelText={item.name}
          onEdit={(e) => {
            e.stopPropagation();
            handleEdit({
              ...item,
              parentCategory:
                item.ancestors.length !== 0 ? item.ancestors[0] : {},
            });
          }}
          onAdd={(e) => {
            e.stopPropagation();
            handleAdd({
              parentCategory: {
                ...item,
                topParent: parentLevel.pop(),
              },
              state: 'add',
            });
          }}
          onDelete={(e) => {
            e.stopPropagation();
            handleDelete(item);
          }}
        >
          {item.hasChildren ? (
            <Collapse in={!isLoading}>
              {!isLoading && renderTree(list.childrens[item._id], level)}
            </Collapse>
          ) : null}
        </CategoryItem>
      );
    });

  return (
    <TreeView
      expanded={expanded}
      selected={selected}
      onNodeToggle={handleToggle}
      onNodeSelect={handleSelect}
      className={classes.root}
      defaultCollapseIcon={<ExpandMoreIcon />}
      defaultExpandIcon={<KeyboardArrowRightIcon />}
    >
      {renderTree(list.parents)}
    </TreeView>
  );
};

export default CategoryList;
