import { Box, Grid } from '@material-ui/core';
import React, { useState, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import Barcode from 'react-barcode';
import { useReactToPrint } from 'react-to-print';
import BarcodeContainer from './barcode-container';
import DialogContainer from '../../../components/dialog-container';

const PrintBarcode = ({ open, onClose, active }) => {
  const { t } = useTranslation();
  const [value, setValue] = useState(null);

  useEffect(() => {
    if (open) {
      setValue(active);
    }
  }, [open]);

  const componentRef = useRef();
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
    pageStyle: `@page {
        size: auto;
        margin:0;
      }`,
  });

  return (
    <DialogContainer
      open={open}
      onClose={onClose}
      title={t('barcode')}
      onCancel={onClose}
      onAdd={handlePrint}
      state="custom"
      customButtonText={t('print')}
    >
      <Grid container spacing={2} justify="center">
        <Barcode height={40} value={value?.id} />
        <Grid item xs={12}>
          <Box marginBottom={2} />
        </Grid>
      </Grid>
      <div className="hidden">
        <BarcodeContainer ref={componentRef} barcode={value} />
      </div>
    </DialogContainer>
  );
};

export default PrintBarcode;
