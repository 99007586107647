import React from 'react';
import {
  TableRow,
  TableCell,
  TableBody,
  Typography,
  Box,
} from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import CustomTable from '../../../../components/custom-table';
import CustomNumberFormat from '../../../../components/number-format';
import DateFormat from '../../../../components/date-format';

const tableHeadData = [
  { value: 'no.', width: '30px' },
  { value: 'vendor', width: '30%' },
  { value: 'cost' },
  { value: 'tax' },
  { value: 'cost-date' },
  { value: 'reference' },
  { value: 'comment' },
];

const CostLine = ({ data }) => {
  const { t } = useTranslation();
  return (
    <>
      <Box bgcolor="grey.200" p={1} id="title">
        <Typography>
          <b>{t('costlines')}</b>
        </Typography>
      </Box>
      <CustomTable.Container size="small">
        {/* table header */}
        <CustomTable.Head data={tableHeadData} />
        {/* table body */}
        <TableBody>
          {data?.map((field, index) => {
            // get value from field
            const get = getValue(field);

            return (
              <TableRow key={field.id}>
                <TableCell>
                  <CustomTable.CellValue>{index + 1}</CustomTable.CellValue>
                </TableCell>
                <TableCell>
                  <CustomTable.CellValue>
                    {get.vendorName}
                  </CustomTable.CellValue>
                </TableCell>
                <TableCell>
                  <CustomTable.CellValue>
                    <CustomNumberFormat value={get.costPrice} />
                  </CustomTable.CellValue>
                </TableCell>
                <TableCell>
                  <CustomTable.CellValue>
                    <CustomNumberFormat value={get.tax} format="percentage" />
                  </CustomTable.CellValue>
                </TableCell>
                <TableCell>
                  <CustomTable.CellValue>
                    <DateFormat value={get.costDate} />
                  </CustomTable.CellValue>
                </TableCell>
                <TableCell>
                  <CustomTable.CellValue>{get.reference}</CustomTable.CellValue>
                </TableCell>
                <TableCell>
                  <CustomTable.CellValue>{get.comment}</CustomTable.CellValue>
                </TableCell>
              </TableRow>
            );
          })}
        </TableBody>
      </CustomTable.Container>
    </>
  );
};

// function to get product value
export const getValue = (data) => {
  const vendorName = data.vendor?.name ?? '';
  const costPrice = data.costPrice ?? 0;
  const costDate = data.costDate ?? null;
  const tax = data.tax ?? 10;
  const reference = data.reference || 'N/A';
  const comment = data.comment || 'N/A';

  return {
    vendorName,
    costPrice,
    tax,
    reference,
    comment,
    costDate,
  };
};

export default CostLine;
