import axios from 'axios';
import generateKey from 'src/util/generate-keys';
import compressImage from 'src/util/image-compress';

const uploadImage = async (
  uploadFile,
  s3BaseURL,
  basePath = 'public/draft'
) => {
  const uploadObject = {
    ContentTypes: [],
    Keys: [],
    Images: [],
  };

  const fileType = uploadFile.type;
  const fileExtension = uploadFile.type.split('/')[1];

  // generate key
  uploadObject.ContentTypes.push(fileType, fileType);
  const key = `${basePath}/${generateKey()}`;
  const imageKey = `${key}.${fileExtension}`;
  const thumbnailKey = `${key}-thumbnail.${fileExtension}`;

  uploadObject.Keys.push(imageKey, thumbnailKey);

  // compress image
  const { image, thumbnail } = await compressImage(uploadFile);
  uploadObject.Images.push(image, thumbnail);

  const uploadedImage = {
    key: `${s3BaseURL}/${imageKey}`,
    thumbnail: `${s3BaseURL}/${thumbnailKey}`,
    mimeType: fileType,
  };

  // get s3 upload url
  const response = await axios.post(
    `${process.env.REACT_APP_API}/api/v1/aws-s3/file-upload-url`,
    {
      ContentTypes: uploadObject.ContentTypes,
      Keys: uploadObject.Keys,
    }
  );

  await Promise.all(
    response.data.map(async (item, index) => {
      await axios.put(item.uploadURL, uploadObject.Images[index], {
        headers: {
          'Content-Type': item.type,
          'Content-Encoding': 'base64',
        },
        withCredentials: false,
      });
    })
  );

  return uploadedImage;
};

export default uploadImage;
