import React, { useRef, useContext } from 'react';
import { Grid, Typography } from '@material-ui/core';
import { useForm, FormProvider } from 'react-hook-form';
import axios from 'axios';
import Products from './products';

import DialogContainer from '../../../../../components/dialog-container';
import { formHandler } from '../../../../../util/react-hook-form-helper';
import { useTranslation } from 'react-i18next';
import { UtilityContext } from '../../../../../components/context-provider/utilty-context';
import useSubmitButton, { HiddenSubmitButton } from 'src/hooks/useSubmitButton';

const FailedForm = ({ open, onClose, orderItems, orderId, onSuccess }) => {
  const { t } = useTranslation();
  const { showSnackbar, showPrompt, closePrompt } = useContext(UtilityContext);
  const { submitButtonRef, submit } = useSubmitButton();

  const methods = useForm({
    defaultValues: {
      product: [],
    },
  });

  // confirm prompt state
  const confirm = useRef(false);

  const handlePrompt = async () => {
    const title = t('action-confirmation');
    const message = (
      <Typography>
        {t('action-cant-undone')}
        <br /> {t('are-you-sure')}
      </Typography>
    );

    showPrompt({
      handleConfirm: () => {
        confirm.current = true;
        handleCreate();
      },
      title,
      message,
      disableIcon: true,
      confirmButtonProps: {
        color: 'primary',
        variant: 'contained',
        style: { borderRadius: 0 },
        disableElevation: true,
      },
      confirmButtonText: t('confirm'),
      cancelButtonText: t('cancel'),
    });
  };

  const onSubmit = (data) => {
    const formConfirm = confirm.current;
    // check if form is validate
    if (Object.keys(methods.formState.errors).length === 0 && !formConfirm) {
      return handlePrompt();
    }
    // if prompt confirm
    if (formConfirm) {
      axios
        .post(`${process.env.REACT_APP_API}/api/v1/orders/return/${orderId}`, {
          items: data.product,
          status: 'failed',
        })
        .then(({ data }) => {
          showSnackbar({ message: data?.message });
          onSuccess && onSuccess();
          onClose();
          closePrompt();
        })
        .catch(({ response }) => {
          showSnackbar({
            message: response?.data?.message,
            variant: 'error',
          });
        });
    }
  };

  const handleCreate = () => {
    submit();
  };

  const onEntering = () => {
    methods.reset({
      product: orderItems.filter((item) => item.isCanceled === false),
    });
  };

  return (
    <DialogContainer
      open={open}
      title={t('failed-order')}
      onCancel={onClose}
      maxWidth='sm'
      TransitionProps={{ onEntering }}
      onAdd={handleCreate}
      state='custom'
      noPadding={true}
      customButtonText={t('confirm')}
    >
      <FormProvider {...methods}>
        <form onSubmit={(e) => formHandler(e, methods.handleSubmit(onSubmit))}>
          <Grid container>
            <Grid item xs={12}>
              <Products />
            </Grid>
          </Grid>
          <HiddenSubmitButton ref={submitButtonRef} />
        </form>
      </FormProvider>
    </DialogContainer>
  );
};

export default FailedForm;
