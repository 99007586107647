import {
  Avatar,
  Button,
  Grid,
  Typography,
  makeStyles,
  Box,
} from '@material-ui/core';
import React, { useState, useEffect } from 'react';
import PhoneEnabledIcon from '@material-ui/icons/PhoneEnabled';
import SelectorPopup from './selector-popup';
import { useFormContext } from 'react-hook-form';

const useStyles = makeStyles((theme) => ({
  avatar: {
    width: '70px',
    height: '70px',
    [theme.breakpoints.down('xs')]: {
      width: '50px',
      height: '50px',
    },
  },
  title: {
    fontWeight: 'bold',
    fontSize: '18px',
    [theme.breakpoints.down('xs')]: {
      fontSize: '14px',
    },
    color: theme.palette.primary.main,
    textTransform: 'uppercase',
  },
  textIcon: {
    display: 'flex',
    '& svg': {
      fontSize: '18px',
      width: '20px',
      height: '30px',
      [theme.breakpoints.down('xs')]: {
        fontSize: '12px',
        width: '14px',
        height: '28px',
      },
      color: theme.palette.primary.main,
      marginRight: '10px',
    },
    '& span': {
      alignSelf: 'center',
    },
  },
}));

const CustomerSelector = ({
  goBackToList,
  state,
  // handleValidate,
  urlQueryState,
  loading,
}) => {
  const { watch, getValues, setFocus } = useFormContext();
  const customer = watch('customer');
  const classes = useStyles();
  // customer popup state
  const [selector, setSelector] = useState({
    open: true,
    // state = [search,create,edit]
    state: 'search',
  });

  // useEffect for form state & urlQueryState
  useEffect(() => {
    if (state === 'edit') {
      setSelector((prev) => ({ ...prev, open: false }));
    } else if (urlQueryState.state) {
      // condition if the data from the query url can create or use existing customer
      switch (urlQueryState.state) {
        case 'create':
          setSelector(() => ({ state: 'create', open: true }));
          break;
        case 'existed':
          setSelector((prev) => ({ ...prev, open: false }));
          break;
        default:
          console.log('wrong state');
      }
    }
  }, [state, urlQueryState]);

  const handleCloseSelector = () => {
    setSelector((prev) => ({ ...prev, open: false }));
    // temperary solution to focus on product field on first load
    if (state === 'create' && getValues('orderItems').length === 1) {
      setFocus('orderItems.0.variant');
    }
  };

  const handleOpenSelector = () =>
    setSelector(() => ({ state: 'search', open: true }));

  return (
    <Grid container className='container' alignItems='center' spacing={2}>
      <Grid item xs={12}>
        <Typography className={classes.title}>Customer Information</Typography>
      </Grid>
      <Grid item container justify='center' alignItems='center' xs={12} sm={4}>
        <Avatar className={classes.avatar} />
      </Grid>
      <Grid item xs={8}>
        <Grid container direction='column' spacing={1}>
          <Grid item container alignItems='center'>
            <Box clone marginRight='5px !important'>
              <Typography className={classes.title}>
                {customer?.fullname || customer?.name}
              </Typography>
            </Box>
          </Grid>
          <Grid item>
            <Typography className={classes.textIcon}>
              <PhoneEnabledIcon />
              <span>{customer?.phone}</span>
            </Typography>
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12} container justify='flex-end'>
        <Button
          size='small'
          color='primary'
          onClick={handleOpenSelector}
          variant='contained'
        >
          Change Customer
        </Button>
      </Grid>
      <SelectorPopup
        open={selector.open && !loading}
        defaultState={selector.state}
        defaultValue={urlQueryState.data}
        value={customer}
        onClose={handleCloseSelector}
        goBackToList={goBackToList}
      />
    </Grid>
  );
};

export default CustomerSelector;
