import { Grid, Paper, Typography } from '@material-ui/core';
import React, { useState } from 'react';
import { DateRangePicker } from 'react-dates';
import { useTranslation } from 'react-i18next';
import TableX from '../../components/tableX';
import config from '../../components/tableX/config';
import NumberFormat from '../../components/number-format';
import DateFormat from '../../components/date-format';
import moment from 'moment';
import OrderDetailPopup from '../sale-order/detail-popup';
import TitleBar from '../../components/title-bar';
import TableFooter from 'src/components/table-footer';
import { formatUTCDate } from 'src/util/date-format';

const PaymentReport = () => {
  const { t } = useTranslation();
  const [date, setDate] = useState({
    startDate: moment().startOf('month'),
    endDate: moment().endOf('month'),
    focusedInput: false,
  });
  const [orderDetail, setOrderDetail] = useState(false);
  const [triggerFetch, setTriggerFetch] = useState(false);

  const handleShowDetail = (data) => {
    setOrderDetail({ open: true, data: { ...data, _id: data.orderId } });
  };

  const handleCloseDetail = () => {
    setOrderDetail({ open: false, data: {} });
  };

  const tableSchema = [
    {
      id: 'invoiceNumber.value',
      dataConfig: ['invoiceNumber', 'value'],
      label: t('invoice-number'),
      type: 'action',
      onClick: handleShowDetail,
    },
    {
      id: 'amount',
      label: t('paid'),
      type: 'component',
      components: [
        {
          name: NumberFormat,
          customValueProp: 'value',
        },
      ],
    },
    {
      id: 'paidTo.name',
      label: t('payment-account'),
      type: 'component',
      dataConfig: [],
      components: (data) => {
        const paidTo = data?.tendered?.[0]?.paidTo ?? data?.paidTo;
        return paidTo ? (
          <Typography>{`${paidTo?.code} - ${paidTo?.name}`}</Typography>
        ) : (
          t('refund')
        );
      },
    },
    {
      id: 'paidDate',
      label: t('paid-date'),
      type: 'component',
      components: [
        {
          name: DateFormat,
          customValueProp: 'value',
        },
      ],
    },
  ];

  const handleTriggerFetch = () => {
    setTriggerFetch((prev) => !prev);
  };

  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <TitleBar disableButton></TitleBar>
      </Grid>
      <Grid item xs={12}>
        <Paper className='tableX-container'>
          <Grid container>
            <Grid item xs={12}>
              <TableX
                disableSearch
                render={(_, data) => {
                  if (data) {
                    return (
                      <TableFooter
                        data={[{ label: 'total', value: data.totalPaid }]}
                      />
                    );
                  }
                }}
                select={false}
                onRowClick={handleShowDetail}
                triggerFetch={triggerFetch}
                config={tableConfig}
                schema={tableSchema}
                renderFilterInput={({ setDateRange }) => {
                  const handleDateChange = ({ startDate, endDate }) => {
                    setDate((prev) => {
                      return { ...prev, startDate, endDate };
                    });
                    if (startDate && endDate) {
                      setDateRange({
                        from: formatUTCDate(startDate),
                        to: formatUTCDate(endDate),
                      });
                    } else if (startDate === null && endDate === null) {
                      setDateRange({
                        from: null,
                        to: null,
                      });
                    }
                  };

                  return (
                    <Grid container spacing={1}>
                      <Grid item>
                        <DateRangePicker
                          showClearDates
                          hideKeyboardShortcutsPanel
                          isOutsideRange={() => false}
                          startDateId='start_date' // PropTypes.string.isRequired,
                          endDateId='end_date' // PropTypes.string.isRequired,
                          startDate={date.startDate}
                          endDate={date.endDate}
                          onDatesChange={handleDateChange}
                          focusedInput={date.focusedInput}
                          onFocusChange={(focusedInput) =>
                            setDate((prev) => ({ ...prev, focusedInput }))
                          }
                        />
                      </Grid>
                    </Grid>
                  );
                }}
              ></TableX>
            </Grid>
          </Grid>
        </Paper>
      </Grid>
      <OrderDetailPopup
        handleRefreshTable={handleTriggerFetch}
        state={orderDetail}
        onClose={handleCloseDetail}
      />
    </Grid>
  );
};

const tableConfig = {
  ...config,
  url: `${process.env.REACT_APP_API}/api/v1/report/payment`,
  initialDate: {
    from: formatUTCDate(moment().startOf('month')),
    to: formatUTCDate(moment().endOf('month')),
  },
  defaultSort: { createdAt: 'desc' },
  dataConfig: ['orderPayments'],
};

export default PaymentReport;
