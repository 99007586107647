import React, { useState, useEffect } from 'react';
import { DateRangePicker } from 'react-dates';
import moment from 'moment';
import {
  Container,
  Paper,
  Typography,
  makeStyles,
  Divider,
  Box,
  Grid,
  List,
  ListItem,
  ListItemText,
  useTheme,
} from '@material-ui/core';
import { useTranslation } from 'react-i18next';

import useMessage from 'src/hooks/useMessage';
import reportApi from 'src/api/report';
import TitleAndAmount from './title-and-amount';
import TitleBar from 'src/components/title-bar';
import { isSameDay } from 'src/util/is-same-day';

const datePreset = [
  { text: 'today', start: moment().startOf('day'), end: moment().endOf('day') },
  {
    text: 'yesterday',
    start: moment().subtract(1, 'days').startOf('day'),
    end: moment().subtract(1, 'days').endOf('day'),
  },
  {
    text: 'this-week',
    start: moment().startOf('week'),
    end: moment().endOf('week'),
  },
  {
    text: 'last-week',
    start: moment().subtract(1, 'weeks').startOf('week'),
    end: moment().subtract(1, 'weeks').endOf('week'),
  },
  {
    text: 'this-month',
    start: moment().startOf('month'),
    end: moment().endOf('month'),
  },
  {
    text: 'last-month',
    start: moment().subtract(1, 'months').startOf('month'),
    end: moment().subtract(1, 'months').endOf('month'),
  },
];

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(6),
  },
  title: {
    fontWeight: theme.typography.fontWeightBold,
  },
  date: {
    marginLeft: theme.spacing(1),
  },
  heading: {
    fontWeight: theme.typography.fontWeightBold,
  },
  divider: {
    marginTop: theme.spacing(2),
  },
  profit: {
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(1),
  },
}));

const ProfitAndLossReport = () => {
  const classes = useStyles();
  const { t } = useTranslation();
  const { showErrorResponseMessage } = useMessage();
  const theme = useTheme();

  const [date, setDate] = useState({
    startDate: moment().startOf('month'),
    endDate: moment().endOf('month'),
    focusedInput: null,
  });
  const [filterDate, setFilterDate] = useState({
    startDate: moment().startOf('month'),
    endDate: moment().endOf('month'),
  });

  const [profitAndLoss, setProfitAndLoss] = useState({
    sales: 0,
    costOfSales: 0,
    grossProfit: 0,
    expenses: 0,
    netProfit: 0,
  });

  useEffect(() => {
    const fetchProfitAndLoss = async () => {
      try {
        const { data } = await reportApi.profitAndLoss(
          filterDate.startDate.format('YYYY-MM-DD'),
          filterDate.endDate.format('YYYY-MM-DD')
        );
        setProfitAndLoss(data.profitAndLoss);
      } catch (error) {
        console.log('error get profit and loss :>> ', error);
        showErrorResponseMessage(error, 'Error get profit and loss report');
      }
    };

    fetchProfitAndLoss();
  }, [filterDate]);

  const handleDateChange = ({ startDate, endDate }) => {
    setDate((prev) => ({ ...prev, startDate, endDate }));

    if (startDate && endDate) {
      setFilterDate((prev) => ({
        ...prev,
        startDate,
        endDate,
      }));
    }
  };

  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <TitleBar disableButton />
      </Grid>
      <Grid item xs={12}>
        <Paper elevation={1} className={classes.root}>
          <Typography gutterBottom>{t('select-date')}</Typography>
          <DateRangePicker
            calendarInfoPosition='before'
            renderCalendarInfo={() => {
              return (
                <List>
                  {datePreset.map((preset) => {
                    const isSelected =
                      isSameDay(preset.start, date.startDate) &&
                      isSameDay(preset.end, date.endDate);

                    return (
                      <ListItem
                        key={preset.text}
                        button
                        selected={isSelected}
                        onClick={() => {
                          handleDateChange({
                            startDate: preset.start,
                            endDate: preset.end,
                          });
                          setDate((prev) => ({ ...prev, focusedInput: null }));
                        }}
                      >
                        <ListItemText primary={t(preset.text)} />
                      </ListItem>
                    );
                  })}
                </List>
              );
            }}
            displayFormat='DD/MM/YYYY'
            hideKeyboardShortcutsPanel
            isOutsideRange={() => false}
            startDateId='start_date' // PropTypes.string.isRequired,
            endDateId='end_date' // PropTypes.string.isRequired,
            startDate={date.startDate}
            endDate={date.endDate}
            onDatesChange={handleDateChange}
            focusedInput={date.focusedInput}
            onFocusChange={(focusedInput) => {
              setDate((prev) => ({ ...prev, focusedInput }));
            }}
          />
          <Container maxWidth='md'>
            <Box display='flex' alignItems='baseline' mb={4} mt={2}>
              <Typography variant='h5' className={classes.title}>
                {t('profit-and-loss')}
              </Typography>
              <Typography
                align='right'
                color='textSecondary'
                variant='body2'
                className={classes.date}
              >
                {`(${filterDate.startDate.format(
                  'D MMM YYYY'
                )} - ${filterDate.endDate.format('D MMM YYYY')})`}
              </Typography>
            </Box>
            <Typography variant='h6' className={classes.heading}>
              {t('trading-income')}
            </Typography>
            <Divider />
            <TitleAndAmount title={t('sales')} amount={profitAndLoss.sales} />
            <TitleAndAmount
              title={t('total-trading-income')}
              boldText
              divider={false}
              amount={profitAndLoss.sales}
            />
            <Typography
              variant='h6'
              className={classes.heading}
              style={{ marginTop: 8 }}
            >
              {t('cost-of-sale')}
            </Typography>
            <Divider />
            <TitleAndAmount
              title={t('purchase')}
              amount={profitAndLoss.costOfSales}
            />
            <TitleAndAmount
              title={t('total-cost-of-sales')}
              boldText
              divider={false}
              amount={profitAndLoss.costOfSales}
            />
            <Divider className={classes.divider} />
            <Box my={1}>
              <TitleAndAmount
                rootStyles={classes.profit}
                title={t('gross-profit')}
                boldText
                divider={false}
                amount={profitAndLoss.grossProfit}
              />
            </Box>
            <Divider style={{ marginBottom: theme.spacing(6) }} />
            <Typography variant='h6' className={classes.heading}>
              {t('operating-expenses')}
            </Typography>
            <Divider />
            <TitleAndAmount
              title={t('total-operating-expenses')}
              boldText
              divider={false}
              amount={profitAndLoss.expenses}
            />
            <Divider style={{ marginBottom: theme.spacing(6) }} />
            <Box my={1}>
              <TitleAndAmount
                rootStyles={{ paddingLeft: 0 }}
                title={t('net-profit')}
                boldText
                divider={false}
                amount={profitAndLoss.netProfit}
                variant='h6'
              />
            </Box>
            <Divider />
          </Container>
        </Paper>
      </Grid>
    </Grid>
  );
};

export default ProfitAndLossReport;
