import {
  Avatar,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  makeStyles,
  Typography,
} from '@material-ui/core';
import React, { useContext, useState } from 'react';
import CloseIcon from '@material-ui/icons/Close';
import BackDropSpinner from '../../../components/backdrop-spinner';
import axios from 'axios';
import CustomChip from './custom-chip';
import useDisplay from '../../../util/useDisplay';
import AmpStoriesIcon from '@material-ui/icons/AmpStories';
import CustomNumberFormat from '../../../components/number-format';
import useDialog from 'src/hooks/useDialog';
import ConvertUnitDialog from './convert-unit-dialog';
import PrimaryButton from 'src/components/primary-button';
import { UserContext } from 'src/components/context-provider/user-context';

const useStyles = makeStyles((theme) => ({
  title: {
    background: '#EEEEEE',
    '& h2': {
      display: 'flex',
      justifyContent: 'space-between',
      [theme.breakpoints.down('xs')]: {
        flexDirection: 'column',
      },
      alignItems: 'center',
      fontWeight: 'normal',
    },
  },
  image: {
    width: '300px',
    height: '300px',
    '& img': {
      objectFit: 'contain',
    },
    '& svg': {
      fontSize: '100px',
    },
  },
  container: {
    '& > div': {
      display: 'flex',
      alignItems: 'flex-start',
    },
    fontSize: '16px',
    '& p.title': {
      fontWeight: 'bold',
      width: '120px',
      '&::after': {
        content: '" : "',
      },
      marginBottom: '10px',
    },
  },
}));

const DetailPopup = (props) => {
  const { checkAccess } = useContext(UserContext);
  const { display, t } = useDisplay();
  const { state, onClose, refreshTable } = props;
  const { dialogState, openDialog, closeDialog } = useDialog();

  const classes = useStyles();

  // data state
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(false);

  const onEnter = async () => {
    setLoading(true);
    await fetchData();
    setLoading(false);
  };

  const fetchData = async () => {
    try {
      const {
        data: { stock },
      } = await axios.get(
        `${process.env.REACT_APP_API}/api/v1/stocks/${state.data?._id}`
      );
      setData(stock);
    } catch (err) {
      console.log(err);
    }
  };

  const onExited = () => {
    setData({});
  };

  // detail for stock
  const stockDetail = [
    { title: 'name', value: data?.variant?.name },
    {
      title: 'sku',
      value: display(data?.variant?.sku, { translateValue: false }),
    },
    {
      title: 'altCode',
      value: display(data?.variant?.altCode, { translateValue: false }),
    },
    {
      title: 'sale-price',
      value: (
        <CustomNumberFormat
          value={display(data?.salePrice, { translateValue: false })}
        ></CustomNumberFormat>
      ),
    },
    {
      title: 'cost-price',
      value: (
        <CustomNumberFormat
          value={display(data?.costPriceAverage, {
            translateValue: false,
          })}
        ></CustomNumberFormat>
      ),
    },
    {
      title: 'quantity',
      value: data?.qty,
    },
  ];

  return (
    <Dialog
      open={state.open}
      onClose={onClose}
      fullWidth
      maxWidth='md'
      TransitionProps={{ onEnter, onExited }}
    >
      <DialogTitle className={classes.title}>
        {t('stock-detail')}
        <IconButton color='secondary' onClick={onClose}>
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent
        style={{ position: 'relative', padding: '20px 0', overflow: 'hidden' }}
      >
        <Grid container spacing={2}>
          <BackDropSpinner backgroundColor='white' open={loading} />
          <Grid item xs={12} container>
            <Grid item xs={6} container justify='center'>
              <Avatar
                src={data?.variant?.images?.[0]?.key}
                className={classes.image}
                variant='rounded'
              >
                <AmpStoriesIcon />
              </Avatar>
            </Grid>
            <Grid item xs={6} className={classes.container}>
              {stockDetail?.map((item, index) => (
                <div key={index}>
                  <Typography className='title'>{t(item.title)}</Typography>
                  <Typography>{item.value}</Typography>
                </div>
              ))}
              <div>
                <Typography className='title'>{t('storage')}</Typography>
                <div>
                  {data?.storages

                    ?.sort((a, b) => {
                      if (a.id === null) return 1;
                      return -1;
                    })
                    ?.map((item, index) => (
                      <Box key={index} marginBottom={'5px'}>
                        <CustomChip
                          id={item.id}
                          name={item.name}
                          qty={item.qty}
                        />
                      </Box>
                    ))}
                </div>
              </div>
            </Grid>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        {checkAccess({ feature: 'stock', action: 'update' }) &&
          data?.product?.id && (
            <PrimaryButton disabled={loading} onClick={() => openDialog(data)}>
              {t('convert-unit')}
            </PrimaryButton>
          )}
      </DialogActions>
      <ConvertUnitDialog
        open={dialogState.open}
        onClose={closeDialog}
        refreshData={() => {
          refreshTable();
          fetchData();
        }}
        preData={dialogState.data}
      />
    </Dialog>
  );
};

export default DetailPopup;
