import axios from 'axios';
import React from 'react';
import { useFormContext } from 'react-hook-form';
import { RHFAutocompleteInput } from '../../../../../components/rhf-controlled-input';
import { useTranslation } from 'react-i18next';
import { Grid, Typography } from '@material-ui/core';

const VariantInput = ({
  index,
  handleAddField,
  defaultValue,
  rules,
  disabled,
}) => {
  const { t } = useTranslation();
  const { setValue } = useFormContext();
  const optionsURL = `${process.env.REACT_APP_API}/api/v1/stocks?search={search}&page=1&limit=10`;

  const findBySKU = async (sku) => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API}/api/v1/stocks/find-by-sku?value=${sku}`
      );
      return response?.data?.variant;
    } catch (err) {
      console.log(err);
      return null;
    }
  };

  return (
    <RHFAutocompleteInput
      name={`product.${index}.variant`}
      dataConfig='stocks'
      disabled={disabled}
      defaultValue={defaultValue}
      optionsURL={`${optionsURL}`}
      disablePortal
      rules={{
        validate: () => {
          return rules;
        },
      }}
      getOptionLabel={variantGetOptionLabel}
      noOptionsText={t('no-product-found')}
      getOptionSelected={(option, value) => option.name === value.name}
      onChange={(e, data, onChange) => {
        if (typeof data === 'object') {
          setValue(`product.${index}.costPrice`, data?.costPriceAverage);
          setValue(`product.${index}.salePrice`, data?.salePrice);
          onChange(data);
          handleAddField(index);
        }
      }}
      onKeyDown={async (e, onChange) => {
        if (e.key === 'Enter' && e.target.value !== '') {
          const sku = e.target.value;
          const product = await findBySKU(sku);
          if (product) {
            onChange(product);
          }
        }
      }}
      renderOption={renderOption}
    />
  );
};
const renderOption = (data) => {
  return (
    <Grid container justify='space-between'>
      <Grid item xs={7}>
        <Typography>{data?.variant?.name}</Typography>
      </Grid>
      <Grid item xs={5}>
        <Typography align='right'>Stock on hand</Typography>
        <Typography align='right'>{data?.qty}</Typography>
      </Grid>
    </Grid>
  );
};

// for autocomplete variant
const variantGetOptionLabel = (option) => {
  if (typeof option === 'object') {
    const code = (option?.variant?.sku || option?.sku) ?? null;
    return `${code ? `${code} - ` : ''}${
      option?.variant?.name || option?.name
    }`;
  } else {
    return '';
  }
};
export default VariantInput;
