import {
  Box,
  Paper,
  IconButton,
  Menu,
  MenuItem,
  Typography,
} from '@material-ui/core';
import React from 'react';
import Address from './address';
import MoreHorizIcon from '@material-ui/icons/MoreHoriz';
import clsx from 'clsx';

const AddressItem = ({ data, onSetPrimary, index, onEdit, onDelete }) => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <Box clone overflow="hidden">
      <Paper elevation={2}>
        <Box
          bgcolor="primary.main"
          px={1}
          color="white"
          display="flex"
          justifyContent="space-between"
          alignItems="center"
        >
          <Box
            className={clsx({ invisible: !data.isPrimary })}
            bgcolor="white"
            clone
            borderRadius={2}
            px={1}
          >
            <Typography color="primary">
              <b>Primary</b>
            </Typography>
          </Box>
          <IconButton size="small" color="inherit" onClick={handleClick}>
            <MoreHorizIcon />
          </IconButton>
          <Menu
            anchorEl={anchorEl}
            open={open}
            onClick={handleClose}
            onClose={handleClose}
            elevation={2}
            getContentAnchorEl={null}
            anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
            transformOrigin={{ vertical: 'top', horizontal: 'right' }}
          >
            {!data.isPrimary && (
              <MenuItem
                onClick={() => {
                  onSetPrimary(index);
                }}
              >
                Set Primary
              </MenuItem>
            )}
            <MenuItem onClick={() => onEdit(index)}>Edit</MenuItem>
            <MenuItem disabled={data.isPrimary} onClick={() => onDelete(index)}>
              Delete
            </MenuItem>
          </Menu>
        </Box>
        <Box p={2}>
          <Address data={data} />
        </Box>
      </Paper>
    </Box>
  );
};

export default AddressItem;
