import axios from 'axios';
import React, { createContext, useState, useEffect, useContext } from 'react';

export const UserContext = createContext({
  me: null,
  setting: null,
  loading: true,
  s3BaseURL: '',
  fetchUser: () => {},
  deliveryInfo: null,
  rolePermission: {},
  checkAccess: () => {},
  checkMenuAccess: () => {},
});

const UserContextProvider = ({ children }) => {
  const [me, setMe] = useState(null);
  const [deliveryInfo, setDeliveryInfo] = useState(null);
  const [setting, setSetting] = useState(null);
  const [s3BaseURL, setS3BaseURL] = useState('');
  const [loading, setLoading] = useState(true);
  const [rolePermission, setRolePermission] = useState({});

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = () => {
    setLoading(true);
    Promise.all([
      axios.get(`${process.env.REACT_APP_API}/api/v1/aws-s3/get-base-url`),
      axios.get(`${process.env.REACT_APP_API}/api/v1/auth/roles`),
      fetchUser(),
    ])
      .then((value) => {
        const [baseURL, roleData] = value;
        setS3BaseURL(baseURL.data?.url);
        setRolePermission(prepareRolePermissionObject(roleData.data.roles));

        const delivery = me.data.warehouse?.delivery;
        const onlyManualDelivery = delivery.every(
          (item) => item.isEnabled === false
        );
        setDeliveryInfo({ onlyManualDelivery, data: delivery });
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const fetchUser = async (callback) => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API}/api/v1/auth/users/me`
      );
      setMe(response.data);
      setSetting(response.data.warehouse.setting);
      const delivery = response.data.warehouse?.delivery;
      const onlyManualDelivery = delivery.every(
        (item) => item.isEnabled === false
      );
      setDeliveryInfo({ onlyManualDelivery, data: delivery });
      callback && callback(false, response.data);
    } catch (error) {
      callback && callback(true, error);
      console.log(error);
    }
  };

  const checkAccess = ({ feature, action }) => {
    if (!loading && me?.roles?.length) {
      const isPermissionGranted = me.roles.some((role) => {
        try {
          if (rolePermission[role.name.toLowerCase()][feature][action]) {
            return true;
          }
          return false;
        } catch (err) {
          console.error(err);
          return false;
        }
      });
      return isPermissionGranted;
    }
    return false;
  };

  return (
    <UserContext.Provider
      value={{
        me,
        setting,
        loading,
        s3BaseURL,
        fetchUser,
        deliveryInfo,
        checkAccess,
        rolePermission,
      }}
    >
      {children}
    </UserContext.Provider>
  );
};

export const useAuth = () => useContext(UserContext);

const prepareRolePermissionObject = (rolePermissionArray) => {
  const rolePermissionObject = rolePermissionArray.reduce(
    (accumulator, current) => {
      accumulator[current.roleName.toLowerCase()] = current.features.reduce(
        (accumulator, current) => {
          accumulator[current?.featureName] = current.rolePermission.reduce(
            (accumulator, current) => {
              accumulator[current.permissionName] = current.boolean;
              return accumulator;
            },
            {}
          );
          return accumulator;
        },
        {}
      );
      return accumulator;
    },
    {}
  );
  return rolePermissionObject;
};
export default UserContextProvider;
