import React, { useState, useEffect, useContext } from 'react';
import TitleBar from '../../components/title-bar';
import ActionButton from '../../components/action-button';
import { Grid, Paper, makeStyles, Box } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import axios from 'axios';
import { UserContext } from '../../components/context-provider/user-context';
import { UtilityContext } from 'src/components/context-provider/utilty-context';

const useStyles = makeStyles((theme) => ({
  rolePaper: {
    marginBottom: '30px',
    padding: '20px 30px',
    display: 'flex',
    flexAlign: 'center',
    justifyContent: 'space-between',
  },
  roleText: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    color: theme.palette.primary.main,
    fontWeight: 'bold',
    fontSize: '16px',
    textTransform: 'capitalize',
  },
  actionBtn: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItem: 'center',
  },
}));

const RoleAndPermission = () => {
  const { t } = useTranslation();
  const classes = useStyles();
  const history = useHistory();
  const { checkAccess } = useContext(UserContext);
  const { showDeletePrompt, showSnackbar, closeDeletePrompt } =
    useContext(UtilityContext);
  const [allRoles, setAllRoles] = useState([]);

  const handleEdit = (data, e) => {
    const location = `/dashboard/role-and-permission/edit/${data._id}`;
    if (e?.ctrlKey) {
      window.open(location, '_blank');
    } else {
      history.push(location);
    }
  };

  const handleDelete = (data) =>
    showDeletePrompt({
      handleConfirm: () => onDelete(data._id),
    });

  const onDelete = (id) => {
    axios
      .delete(`${process.env.REACT_APP_API}/api/v1/auth/roles/${id}`)
      .then(({ data }) => {
        showSnackbar({ message: data?.message });
        fetchRoles();
      })
      .catch(({ response }) =>
        showSnackbar({ message: response?.data.message, variant: 'error' })
      )
      .finally(() => closeDeletePrompt());
  };

  const handleCreate = () =>
    history.push('/dashboard/role-and-permission/create');

  useEffect(() => {
    fetchRoles();
  }, []);

  const fetchRoles = () => {
    axios
      .get(`${process.env.REACT_APP_API}/api/v1/auth/roles`)
      .then(({ data }) => {
        if (typeof data === 'object') {
          setAllRoles(data.roles);
        } else {
          console.log('error response');
        }
      })
      .catch((err) => console.log(err));
  };

  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <TitleBar
          onClick={handleCreate}
          buttonText={t('create-role')}
          disableButton={!checkAccess({ feature: 'role', action: 'create' })}
        ></TitleBar>
      </Grid>
      <Grid item xs={12}>
        {allRoles?.map((role, idx) => {
          return (
            <Paper key={idx} className={classes.rolePaper}>
              <Box className={classes.roleText}>{role.roleName}</Box>
              <Box className={classes.actionBtn}>
                <ActionButton variant='edit' onClick={() => handleEdit(role)} />
                <ActionButton
                  variant='delete'
                  onClick={() => handleDelete(role)}
                />
              </Box>
            </Paper>
          );
        })}
      </Grid>
    </Grid>
  );
};

export default RoleAndPermission;
