import { Grid } from '@material-ui/core';
import React, { useEffect, useState, useContext } from 'react';
import DialogContainer from '../../../components/dialog-container';
import TextInput from '../../../components/text-input';
import { useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';
import axios from 'axios';
import { UtilityContext } from '../../../components/context-provider/utilty-context';
import { formHandler, registerMui } from '../../../util/react-hook-form-helper';

const UnitForm = ({
  open,
  onClose,
  setTriggerFetch,
  preData,
  newData,
  setReturnData,
}) => {
  const { t } = useTranslation();
  // form current state
  const [state, setState] = useState('add');
  const { showSnackbar } = useContext(UtilityContext);
  // react hook form state
  const { register, handleSubmit, reset } = useForm({ shouldUnregister: true });

  useEffect(() => {
    if (open) {
      if (preData) {
        setState('update');
        reset(preData);
      } else {
        setState('add');
        if (newData) {
          reset(newData);
        } else {
          reset({});
        }
      }
    }
  }, [preData, newData, open]);

  const onSubmit = (data) => {
    switch (state) {
      case 'add':
        axios
          .post(`${process.env.REACT_APP_API}/api/v1/units`, data)
          .then(({ data }) => {
            showSnackbar({ message: data?.message });
            if (newData) {
              setReturnData(data?.unit);
            } else {
              setTriggerFetch((prev) => !prev);
            }
            onClose();
          })
          .catch(({ response }) => {
            showSnackbar({
              message: response?.data?.message,
              variant: 'error',
            });
          });
        break;
      case 'update':
        axios
          .put(`${process.env.REACT_APP_API}/api/v1/units/${preData._id}`, data)
          .then(({ data }) => {
            showSnackbar({ message: data?.message });
            setTriggerFetch((prev) => !prev);
            onClose();
          })
          .catch(({ response }) => {
            showSnackbar({
              message: response?.data?.message,
              variant: 'error',
            });
          });
        break;
      default:
        console.log('wrong state');
    }
  };

  return (
    <DialogContainer
      open={open}
      state={state}
      onClose={onClose}
      title={t('unit-form-title')}
      onCancel={onClose}
      formId='unit-form'
    >
      <form
        id='unit-form'
        onSubmit={(e) => formHandler(e, handleSubmit(onSubmit))}
      >
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <TextInput
              label={t('unit-name')}
              placeholder={t('unit-placeholder')}
              required
              autoFocus
              {...registerMui(register('name'))}
            />
          </Grid>
          <Grid item xs={12}>
            <TextInput
              label={t('description')}
              multiline
              rows={4}
              {...registerMui(register('description'))}
            />
          </Grid>
        </Grid>
      </form>
    </DialogContainer>
  );
};

export default UnitForm;
