const style = {
  pending: {
    color: 'white',
    background: '#DAB600',
  },
  completed: {
    color: 'white',
    background: '#006FB9',
  },
  unpaid: {
    color: 'white',
    background: '#d61414',
  },
  paid: {
    color: 'white',
    background: '#26bb62',
  },
  'partially-paid': {
    color: 'white',
    background: '#4b9ed9',
  },
  approved: {
    color: '#184d07',
    backgroundColor: '#8bf3a1',
  },
  rejected: {
    color: '#791515',
    background: '#fff9494',
  },
  packed: {
    background: '#b1722a',
    color: 'white',
  },
  repack: {
    background: '#e997c4',
    color: 'white',
  },
  shipping: {
    background: '#94b9fe',
    color: '#1a376d',
  },
  'delivery-failed': {
    background: '#e84717',
    color: 'white',
  },
  delivered: {
    background: '#20b320',
    color: 'white',
  },
  returned: {
    background: 'purple',
    color: 'white',
  },
  refunded: {
    background: 'purple',
    color: 'white',
  },
  failed: {
    background: 'red',
    color: 'white',
  },
  'partially-returned': {
    background: '#4242ad',
    color: 'white',
  },
  'partially-refunded': {
    background: '#4242ad',
    color: 'white',
  },
  canceled: {
    color: 'white',
    background: '#d61414',
  },
  published: {
    background: '#20b320',
    color: 'white',
  },
  unpublished: {
    color: 'white',
    background: '#f50057',
  },
  unavailable: {
    color: 'white',
    background: '#f50057',
  },
  available: {
    background: '#20b320',
    color: 'white',
  },
  active: {
    background: '#20b320',
    color: 'white',
  },
  expired: {
    color: 'white',
    background: '#f50057',
  },
  default: {
    color: 'white',
    background: '#20b320',
  },
  inactive: {
    color: 'white',
    background: '#d61414',
  },
};

export default style;
