import React, { useEffect, useState, useContext } from 'react';
import { Grid, makeStyles, Paper } from '@material-ui/core';
import TitleBar from '../../../components/title-bar';
import FloatingBar from '../../../components/floating-bar';
import { useParams, useHistory } from 'react-router-dom';
import { useForm, FormProvider, Controller } from 'react-hook-form';
import axios from 'axios';
import Products from './items';
import generateKey from '../../../util/generate-keys';
import { UserContext } from '../../../components/context-provider/user-context';
import { UtilityContext } from '../../../components/context-provider/utilty-context';
import DateFnsUtils from '@date-io/date-fns';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import LabelInput from '../../../components/label-input';
import { RHFTextInput } from '../../../components/rhf-controlled-input';
import { useTranslation } from 'react-i18next';
import BillingTotal from './total';
import FileUpload from './file-upload';
import Spinner, { useSpinner } from 'src/components/spinner';
import BillingInformation from './information';
import PaidBy from './paid-by';
import useMessage from 'src/hooks/useMessage';

const useStyles = makeStyles(() => ({
  root: {
    marginBottom: '150px',
    flexDirection: 'column',
    position: 'relative',
  },
}));

const BillingFormPage = () => {
  const { t } = useTranslation();
  const classes = useStyles();
  const history = useHistory();
  const { id } = useParams();
  const { showSnackbar } = useContext(UtilityContext);

  const { showErrorResponseMessage, showSuccessResponseMessage } = useMessage();
  const { spinnerState, openSpinner, closeSpinner } = useSpinner();

  const methods = useForm({
    defaultValues: {
      items: [{}],
      files: {
        files: [],
        remove: [],
      },
    },
    shouldUnregister: true,
  });

  const [state, setState] = useState('create');
  // eslint-disable-next-line no-unused-vars
  const [loading, setLoading] = useState(true);
  const { me } = useContext(UserContext);

  const [billingData, setBillingData] = useState(null);
  const [isReferenceToOrder, setIsReferenceToOrder] = useState(false);

  useEffect(() => {
    if (id) {
      openSpinner({ title: t('loading') });
      setState('edit');
      setLoading(true);
      axios
        .get(`${process.env.REACT_APP_API}/api/v1/billing/${id}`)
        .then(({ data }) => {
          if (typeof data === 'object') {
            const { billing } = data;
            const { items } = billing;

            billing.files = { files: billing.files, remove: [] };

            // check if billing have reference to order
            // currenctly billing that have reference don't allow to add new item
            if (!billing.reference?.id) {
              items.push([{ qty: 1, tax: 10 }]);
            } else {
              setIsReferenceToOrder(true);
            }
            setBillingData({ ...billing, items });

            setLoading(false);
          } else {
            console.log('error reponse');
          }
        })
        .catch(({ response }) => {
          showSnackbar({
            message: response?.data?.message,
            variant: 'error',
          });
        })
        .finally(() => {
          closeSpinner();
        });
    } else {
      setState('create');
    }
  }, []);

  useEffect(() => {
    if (billingData) {
      methods.reset(billingData);
    }
  }, [billingData]);

  const onSubmit = async (data) => {
    openSpinner({ title: t('saving') });
    const warehouseId = me.warehouse ? me.warehouse.id : 'undefined';

    const updateData = {
      ...data,
    };

    let uploadObject = {
      ContentTypes: [],
      Keys: [],
      Files: [],
      files: [],
    };

    const updateProducts = updateData.items.filter((item) => item.name);

    updateData.items = updateProducts;

    updateData.files?.files?.map(async (item) => {
      if (!('key' in item)) {
        const fileType = item.type;
        const fileExtension = item.type.split('/')[1];
        // generate 2 keys (one image one thumbnail)
        uploadObject.ContentTypes.push(fileType);
        const Key = `private/${warehouseId}/billing/${generateKey()}`;
        const fileKey = `${Key}.${fileExtension}`;

        uploadObject.Keys.push(fileKey);
        // compress image
        uploadObject.Files.push(item);
        uploadObject.files.push({
          name: item.name,
          key: `${fileKey}`,
          mimeType: fileType,
        });
      } else {
        uploadObject.files.push(item);
      }
    });

    updateData.files = uploadObject.files;

    if (uploadObject.ContentTypes.length > 0) {
      try {
        const response = await axios.post(
          `${process.env.REACT_APP_API}/api/v1/aws-s3/file-upload-url`,
          {
            ContentTypes: uploadObject.ContentTypes,
            Keys: uploadObject.Keys,
          }
        );
        // upload to s3
        await Promise.all(
          response.data.map(async (item, index) => {
            await axios.put(item.uploadURL, uploadObject.Files[index], {
              headers: {
                'Content-Type': item.type,
                'Content-Encoding': 'base64',
              },
              withCredentials: false,
            });
          })
        );
      } catch (err) {
        showSnackbar({
          message: 'upload-file-failed',
          variant: 'error',
        });
        console.error(err);
        closeSpinner();
        return;
      }
    }

    switch (state) {
      case 'create':
        axios
          .post(`${process.env.REACT_APP_API}/api/v1/billing`, updateData)
          .then(({ data }) => {
            showSnackbar({ message: data?.message });
            goBackToList();
          })
          .catch((error) => {
            showErrorResponseMessage(error);
          })
          .finally(() => {
            closeSpinner();
          });
        break;
      case 'edit':
        axios
          .put(`${process.env.REACT_APP_API}/api/v1/billing/${id}`, updateData)
          .then(({ data }) => {
            showSnackbar({ message: data?.message });
            goBackToList();
          })
          .catch((error) => {
            showErrorResponseMessage(error);
          })
          .finally(() => {
            closeSpinner();
          });
        break;
      default:
        console.log('Error state');
    }
  };

  const handleCancel = () => {
    goBackToList();
  };

  const goBackToList = () => {
    history.push('/dashboard/billing');
  };

  return (
    <FormProvider {...methods}>
      <MuiPickersUtilsProvider utils={DateFnsUtils}>
        <form id='billing-form' onSubmit={methods.handleSubmit(onSubmit)}>
          <Grid container className={classes.root} spacing={3}>
            <Grid item>
              <TitleBar disableButton />
            </Grid>
            <Grid item>
              <Paper className='container'>
                <BillingInformation />
              </Paper>
            </Grid>
            <Grid item>
              <Paper className='container'>
                <Grid container spacing={3}>
                  <Grid item xs={12}>
                    <Products isReferenceToOrder={isReferenceToOrder} />
                  </Grid>
                  <Grid item xs={12}>
                    <PaidBy />
                  </Grid>
                  <Grid item xs={12}>
                    <Grid container justify='space-between'>
                      <Grid item xs={6}>
                        <Grid container spacing={2}>
                          <Grid item xs={12}>
                            <LabelInput
                              component={RHFTextInput}
                              name='note'
                              label={t('note')}
                              multiline
                              rows={10}
                            />
                          </Grid>
                          <Grid item xs={12}>
                            <Controller
                              name={'files'}
                              control={methods.control}
                              render={({ field: { value, onChange } }) => {
                                return (
                                  <FileUpload
                                    files={value}
                                    setFiles={onChange}
                                  />
                                );
                              }}
                            />
                          </Grid>
                        </Grid>
                      </Grid>
                      <Grid item xs={4}>
                        <BillingTotal />
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Paper>
            </Grid>
            <FloatingBar
              onCancel={handleCancel}
              confirmButtonProps={{ form: 'billing-form' }}
              disabledConfirmButton={
                Object.keys(methods.formState.dirtyFields).length === 0
              }
              confirmButtonText={
                state === 'create' ? 'create-billing' : 'save-changes'
              }
            />
          </Grid>
        </form>
        <Spinner state={spinnerState} />
      </MuiPickersUtilsProvider>
    </FormProvider>
  );
};

export default BillingFormPage;
