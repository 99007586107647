import { TableCell, TableRow, Typography } from '@material-ui/core';
import React from 'react';
import get from 'lodash.get';
import CustomTable from 'src/components/custom-table';
import CustomNumberFormat from 'src/components/number-format';
import { useTranslation } from 'react-i18next';
import moment from 'moment';

const PrintReport = React.forwardRef(
  ({ head, body, data, footer, title }, ref) => {
    const { t } = useTranslation();
    return (
      <div style={{ display: 'none' }}>
        <div style={{ margin: '10px 5px' }} ref={ref}>
          <Typography variant="h6" align="center" gutterBottom>{`${title} ${
            data?.date?.from
              ? `(${moment(data?.date?.from).format('DD/MM/yyyy')} - ${moment(
                  data?.date?.to
                ).format('DD/MM/yyyy')})`
              : ''
          }`}</Typography>
          <CustomTable.Container th={{ fontSize: '5px' }} size="small">
            {/* table header */}
            <CustomTable.Head data={head} />
            {data?.body?.map((field, index) => {
              return (
                <TableRow disabled key={index}>
                  {body?.map((item, bodyIndex) => {
                    return (
                      <TableCell key={bodyIndex}>
                        <CustomTable.CellValue disableMargin>
                          {item.format ? (
                            <CustomNumberFormat
                              value={get(field, item.field)}
                              format={item.format}
                            />
                          ) : (
                            get(field, item.field)
                          )}
                        </CustomTable.CellValue>
                      </TableCell>
                    );
                  })}
                </TableRow>
              );
            })}
            {footer?.map((item, index) => {
              return (
                <TableRow disabled key={index}>
                  <TableCell
                    style={{ borderBottom: 0 }}
                    colSpan={head.length - 2}
                  />
                  <TableCell>
                    <CustomTable.CellValue align="right" disableMargin>
                      {t(item.label)}
                    </CustomTable.CellValue>
                  </TableCell>
                  <TableCell>
                    <CustomTable.CellValue disableMargin>
                      {item.format ? (
                        <CustomNumberFormat
                          value={get(data?.footer, item.field)}
                          format={item.format}
                        />
                      ) : (
                        get(data?.footer, item.field)
                      )}
                    </CustomTable.CellValue>
                  </TableCell>
                </TableRow>
              );
            })}
          </CustomTable.Container>
        </div>
      </div>
    );
  }
);

export default PrintReport;
