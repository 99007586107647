import React from 'react';
import { useFormContext } from 'react-hook-form';
import { RHFNumberInput } from '../../../../../../../../components/rhf-controlled-input';

const QtyInput = ({ nestedIndex, qty, storage }) => {
  const { setValue } = useFormContext();

  // add another field with remaining qty if change qty less than received qty
  const handleChange = (value, index) => {
    const tempStorage = [...storage];
    tempStorage[index].qty = value ?? 0;
    let total = 0;
    let i = 0;
    const newArray = [];

    while (total < qty && i <= tempStorage.length - 1) {
      const currentQty = tempStorage[i].qty;
      const tempTotal = total + Number(currentQty);
      if (tempTotal <= qty) {
        total += Number(currentQty);
        newArray.push({ qty: currentQty });
      }

      if (i === tempStorage.length - 1 && total < qty) {
        const remaining = qty - total;
        total += Number(remaining);

        newArray.push({ qty: remaining });
      }
      i++;
    }

    setValue(`product.${nestedIndex}.storages`, newArray);
  };

  const handleOnBlur = (value, index) => {
    if (Number(value) === 0) {
      const tempStorage = [...storage];
      tempStorage.splice(index, 1);
      setValue(`product.${nestedIndex}.storages`, tempStorage);
    }
  };

  return storage?.map((field, index) => (
    <React.Fragment key={index}>
      <div
        style={{
          marginBottom: index !== storage.length - 1 ? '5px' : '0px',
        }}
      >
        <RHFNumberInput
          textAlign="right"
          name={`product.${nestedIndex}.storages.${index}.qty`}
          defaultValue={field.qty}
          min={1}
          onChange={(e) => {
            handleChange(e.target.value, index);
          }}
          onBlur={(e) => {
            handleOnBlur(e.target.value, index);
          }}
          required
        />
      </div>
    </React.Fragment>
  ));
};

export default QtyInput;
