import React from 'react';
import {
  Dialog,
  DialogContent,
  Button,
  DialogActions,
  Grid,
  Typography,
  Box,
  Zoom,
  makeStyles,
  Fade,
} from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';

const useStyles = makeStyles(() => ({
  dialogContent: {
    textAlign: 'center',
  },
  icon: {
    fontSize: '48px',
  },
  dialogAction: {
    padding: 0,
  },
}));

const PromptPopup = ({ open, handleClose, handleConfirm, icon }) => {
  const Icon = icon ?? DeleteIcon;
  const classes = useStyles();
  return (
    <Dialog open={open} onClose={handleClose} TransitionComponent={Fade}>
      <DialogContent className={classes.dialogContent}>
        <Box px={7} py={3}>
          <Zoom in timeout={500}>
            <Icon color='secondary' className={classes.icon} />
          </Zoom>
          <Typography variant='h5'>Are you sure?</Typography>
        </Box>
      </DialogContent>
      <DialogActions className={classes.dialogAction}>
        <Grid container style={{ borderTop: '1px solid rgba(0,0,0,0.2)' }}>
          <Grid item xs={6}>
            <Button onClick={handleClose} color='primary' fullWidth>
              No
            </Button>
          </Grid>
          <Grid item xs={6}>
            <Button
              onClick={handleConfirm}
              disableElevation
              variant='contained'
              style={{ borderRadius: 0 }}
              color='secondary'
              fullWidth
            >
              Yes
            </Button>
          </Grid>
        </Grid>
      </DialogActions>
    </Dialog>
  );
};

export default PromptPopup;
