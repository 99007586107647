import { TableBody, TableCell, TableRow } from '@material-ui/core';
import readObjectWithArrayKeys from '../utils/read-object-value-with-array-keys';
import React from 'react';

const TableBodyX = ({ schema, dataList, handleClick }) => {
  const showContent = ({ item, keyIndex, rowIndex }) => {
    const currentSchema = item;
    const currentCellData = dataList[rowIndex];

    let currentCellValue = !item.dataConfig
      ? currentCellData[item.id]
      : readObjectWithArrayKeys(currentCellData, item.dataConfig);

    if (currentSchema.modify) {
      currentCellValue = currentSchema.modify(
        currentCellValue,
        currentCellData
      );
    }

    switch (currentSchema.type) {
      case 'component': {
        return (
          <TableCell key={keyIndex} className={item.className}>
            {currentSchema.components.map((component, componentIndex) => {
              const {
                name: Component,
                children: Children,
                acceptValue,
                customValueProp = 'currentvalue',
              } = component;
              return (
                <Component
                  key={componentIndex}
                  onClick={
                    component.onClick
                      ? (e) => {
                          e.stopPropagation();
                          component.onClick(currentCellData);
                        }
                      : () => {}
                  }
                  {...component?.props}
                  {...{ [customValueProp]: currentCellValue }}
                >
                  {acceptValue && currentCellValue}
                  {Children && typeof Children !== 'string' ? (
                    <Children></Children>
                  ) : (
                    Children
                  )}
                </Component>
              );
            })}
          </TableCell>
        );
      }
      case 'action': {
        return (
          <TableCell key={keyIndex} className={item.className}>
            <span
              onClick={(e) => {
                e.stopPropagation();
                currentSchema.onClick(currentCellData);
              }}
              className="tableX-action-cell"
            >
              {currentCellValue}
            </span>
          </TableCell>
        );
      }
      default:
        return (
          <TableCell
            style={item.style}
            className={item.className}
            key={keyIndex}
          >
            {currentCellValue}
          </TableCell>
        );
    }
  };

  return (
    <TableBody>
      {dataList.map((row, rowIndex) => {
        return (
          <TableRow
            onClick={
              handleClick
                ? () => {
                    handleClick(row);
                  }
                : () => {}
            }
            key={rowIndex}
          >
            {schema.map((item, index) =>
              showContent({ item, keyIndex: index, rowIndex })
            )}
          </TableRow>
        );
      })}
    </TableBody>
  );
};

export default TableBodyX;
