import {
  Dialog,
  DialogContent,
  DialogTitle,
  makeStyles,
  Grid,
  IconButton,
  Button,
  Typography,
} from '@material-ui/core';
import React, { useContext, useState } from 'react';
import CloseIcon from '@material-ui/icons/Close';
import moment from 'moment';
import Status from '../../../components/status';
import { useTranslation } from 'react-i18next';
import Products from './products';
import axios from 'axios';
import BackDropSpinner from '../../../components/backdrop-spinner';
import ReturnedForm from './returned-form';
import { UtilityContext } from 'src/components/context-provider/utilty-context';
import { UserContext } from 'src/components/context-provider/user-context';

const useStyles = makeStyles(() => ({
  title: {
    background: '#EEEEEE',
    '& h2': {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      fontWeight: 'normal',
    },
  },
  pBold: {
    '& p': {
      fontWeight: 'bold',
    },
  },
  table: {
    fontSize: '14px',
    '& th': {
      fontWeight: 'bold',
    },
  },
  bold: {
    fontWeight: 'bold',
    textAlign: 'right',
  },
  textRight: {
    textAlign: 'right',
  },
  dialogContent: {
    height: '90vh',
    overflow: 'overlay',
  },
}));

const SaleReturnDetailPopUp = ({ state, onClose, handleRefreshTable }) => {
  const classes = useStyles();
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState({});
  const [returnForm, setReturnForm] = useState(false);
  const { showPrompt, showSnackbar, closePrompt } = useContext(UtilityContext);
  const { setting } = useContext(UserContext);
  const { open } = state;

  const { t } = useTranslation();

  const handleOpenReturnForm = () => {
    if (setting?.order?.trackStock) {
      setReturnForm(true);
    } else {
      handlePrompt();
    }
  };

  const handleCloseReturnForm = () => {
    setReturnForm(false);
  };

  const handlePrompt = async () => {
    const title = t('action-confirmation');
    const message = (
      <Typography>
        {t('action-cant-undone')}
        <br /> {t('are-you-sure')}
      </Typography>
    );

    showPrompt({
      handleConfirm: () => {
        handleSubmit();
        closePrompt();
      },
      title,
      message,
      disableIcon: true,
      confirmButtonProps: {
        color: 'primary',
        variant: 'contained',
        style: { borderRadius: 0 },
        disableElevation: true,
      },
      confirmButtonText: t('confirm'),
      cancelButtonText: t('cancel'),
    });
  };
  const onEnter = async () => {
    setLoading(true);
    await fetchFormData();
    setLoading(false);
  };

  const handleSubmit = (formData) => {
    axios
      .post(
        `${process.env.REACT_APP_API}/api/v1/orders/sale-return/${data?._id}`,
        {
          items: formData?.product ?? [],
        }
      )
      .then(({ data }) => {
        showSnackbar({ message: data?.message });
        fetchFormData();
        handleRefreshTable();
        if (formData) {
          handleCloseReturnForm();
        }
      })
      .catch(({ response }) => {
        showSnackbar({
          message: response?.data?.message,
          variant: 'error',
        });
      });
  };

  const fetchFormData = async () => {
    try {
      const {
        data: { saleReturn },
      } = await axios.get(
        `${process.env.REACT_APP_API}/api/v1/orders/sale-return/${state.data?._id}`
      );

      setData(saleReturn);
    } catch (err) {
      console.log(err);
    }
  };

  const onExited = () => {
    setData({});
  };

  return (
    <Dialog
      open={open}
      onClose={onClose}
      TransitionProps={{
        onEnter,
        onExited,
      }}
      fullWidth
      maxWidth='md'
    >
      <DialogTitle className={classes.title}>
        <Grid container direction='column'>
          <Grid item container justify='space-between' alignItems='center'>
            <Grid item xs='auto'>
              <Grid container alignItems='center'>
                <Grid item>
                  <Typography>Sale Return Detail</Typography>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs='auto'>
              <IconButton
                className={classes.hiddenOnXS}
                color='secondary'
                onClick={onClose}
              >
                <CloseIcon />
              </IconButton>
            </Grid>
          </Grid>
        </Grid>
      </DialogTitle>
      <DialogContent
        classes={{ root: classes.dialogContent }}
        style={{ position: 'relative' }}
      >
        <BackDropSpinner backgroundColor='white' open={loading} />
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Grid container style={{ padding: '0 15px' }}>
              <Grid item xs>
                <Grid container spacing={1}>
                  <Grid item className={classes.pBold}>
                    <p>{`${t('date')} : `}</p>
                    <p>{`${t('id')} : `}</p>
                    <p>{`${t('status')} : `}</p>
                  </Grid>
                  <Grid item>
                    <p>{moment(data?.createdAt).format('DD/MM/YYYY')}&nbsp;</p>
                    <p>{data?._id}&nbsp;</p>
                    <Status size='small' status={data?.status} />
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs>
                <Grid container spacing={1} justify='flex-end'>
                  <Grid item className={classes.pBold}>
                    <p>{`${t('invoice-number')} : `}</p>
                    <p>{`${t('created-by')} : `}</p>
                  </Grid>
                  <Grid item>
                    <p>{data?.invoiceNumber}&nbsp;</p>
                    <p>{data?.createdBy?.name}&nbsp;</p>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Grid container spacing={4}>
              <Grid item xs={12}>
                <Products data={data?.items} />
              </Grid>
            </Grid>
          </Grid>
          {data?.status?.length && data?.status !== 'completed' ? (
            <Grid item xs={12} container justify='flex-end'>
              <Button
                color='primary'
                variant='contained'
                onClick={handleOpenReturnForm}
                disableElevation
              >
                {t('return')}
              </Button>
            </Grid>
          ) : null}
        </Grid>
        <ReturnedForm
          open={returnForm}
          onSubmit={handleSubmit}
          onClose={handleCloseReturnForm}
          items={data?.items}
        />
      </DialogContent>
    </Dialog>
  );
};

export default SaleReturnDetailPopUp;
