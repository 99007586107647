import {
  Breadcrumbs,
  Paper,
  makeStyles,
  Button,
  Typography,
} from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { useHistory, Link } from 'react-router-dom';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '30px',
    textTransform: 'capitalize',
    '& a': {
      textDecoration: 'none',
      color: 'black',
      '&:hover': {
        textDecoration: 'underline',
      },
    },
    '& p': {
      color: theme.palette.blue.main,
    },
  },
  hidden: {
    visibility: 'hidden',
  },
}));

const TitleBar = ({ disableButton, buttonText, onClick }) => {
  const classes = useStyles();
  const history = useHistory();
  const [path, setPath] = useState([]);
  const { t } = useTranslation();

  useEffect(() => {
    const pathArray = history.location.pathname.split('/').slice(1, 4);
    setPath(pathArray);
  }, [history.location.pathname]);

  return (
    <Paper className={classes.root}>
      <Breadcrumbs>
        {path.map((item, index) =>
          index !== path.length - 1 ? (
            <Link key={index} to={`/${path.slice(0, index + 1).join('/')}`}>
              {t(`titlebar-${item}`)}
            </Link>
          ) : (
            <Typography key={index}>
              {index === 2
                ? t(`titlebar-${path[index - 1]}-${item}`)
                : t(`titlebar-${item}`)}
            </Typography>
          )
        )}
      </Breadcrumbs>
      {
        <Button
          className={disableButton ? classes.hidden : ''}
          onClick={onClick}
          disableElevation
          color='primary'
          variant='contained'
        >
          <AddCircleIcon />
          {buttonText}
        </Button>
      }
    </Paper>
  );
};

export default TitleBar;
