//read object with array key
// object {a:{b:1}} array ["a","b"] return 1;
const readObjectWithArrayKeys = (object, arrayKey) => {
  let value = object;
  arrayKey.forEach((item) => {
    value = value[item] !== undefined ? value[item] : '';
  });
  return value;
};

export default readObjectWithArrayKeys;
