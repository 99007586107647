import { Grid } from '@material-ui/core';
import { useContext, useEffect, useState } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import AutoCompleteAsync from 'src/components/autocomplete-async';
import CategoryLabel from 'src/components/category-label';
import ChipInput from 'src/components/chip-input';
import { UtilityContext } from 'src/components/context-provider/utilty-context';
import LabelInput from 'src/components/label-input';
import SKUInput from 'src/components/sku-input';
import SlugInput from 'src/components/slug-input';
import TagInput from 'src/components/tag-input';
import TextInput from 'src/components/text-input';
import TextInputContainer from 'src/components/text-input-container';
import useMessage from 'src/hooks/useMessage';
import BrandForm from 'src/pages/brand/form';
import CategoryForm from 'src/pages/category/form';
import { registerMui } from 'src/util/react-hook-form-helper';

const CompositeInfo = (loading, state) => {
  const { t } = useTranslation();
  const { register, setValue, control, getValues } = useFormContext();
  const [brandForm, setBrandForm] = useState({ state: false, data: null });
  const [categoryForm, setCategoryForm] = useState({
    state: false,
    data: null,
  });
  const { showSnackbar } = useContext(UtilityContext);
  const [sku, setSku] = useState(null);
  const [slug, setSlug] = useState(null);
  const { showErrorMessage } = useMessage();

  useEffect(() => {
    if (state === 'edit') {
      const skuValue = getValues('sku');
      const slugValue = getValues('slug');
      setSlug(slugValue);
      setSku(skuValue);
    }
  }, [loading, state]);

  return (
    <Grid container spacing={3}>
      <Grid item xs={6}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <TextInput
              required
              autoFocus
              label={t('product-name')}
              {...registerMui(register('name'))}
            />
          </Grid>
          <Grid item xs={6}>
            <CategoryForm
              open={categoryForm.state}
              onClose={() => setCategoryForm({ state: false })}
              newData={categoryForm.data}
              setReturnData={(data) => {
                setValue('category', data);
              }}
            />
            <Controller
              control={control}
              name='category'
              defaultValue={null}
              render={({ field: { value, onChange } }) => {
                return (
                  <LabelInput
                    label={t('category')}
                    component={AutoCompleteAsync}
                    value={value}
                    onChange={onChange}
                    placeholder={t('category')}
                    onCreate={(data) =>
                      setCategoryForm({
                        state: true,
                        data: { name: data },
                      })
                    }
                    optionsURL={`${process.env.REACT_APP_API}/api/v1/categories`}
                    getOptions={(data) => data?.categories}
                    renderOption={(option) =>
                      option?.creatable ? null : (
                        <CategoryLabel
                          option={option}
                          name={option?.name}
                          parent={option?.ancestors}
                        />
                      )
                    }
                    getOptionLabel={(option) => option.name}
                    getOptionSelected={(option, value) =>
                      option._id === value._id || value.id
                    }
                  />
                );
              }}
            ></Controller>
          </Grid>
          <Grid item xs={6}>
            <BrandForm
              open={brandForm.state}
              onClose={() => setBrandForm({ state: false })}
              newData={brandForm.data}
              setReturnData={(data) => {
                setValue('brand', data);
              }}
            />
            <Controller
              control={control}
              name='brand'
              defaultValue={null}
              render={({ field: { value, onChange } }) => {
                return (
                  <LabelInput
                    label={t('brand')}
                    component={AutoCompleteAsync}
                    value={value}
                    onChange={onChange}
                    placeholder={t('brand')}
                    onCreate={(data) =>
                      setBrandForm({
                        state: true,
                        data: { name: data },
                      })
                    }
                    optionsURL={`${process.env.REACT_APP_API}/api/v1/brands`}
                    getOptions={(data) => data?.brands}
                    renderOption={(option) => option.name}
                    getOptionLabel={(option) => option.name}
                    getOptionSelected={(option, value) =>
                      option._id === value._id || value.id
                    }
                  />
                );
              }}
            ></Controller>
          </Grid>
          <Grid item xs={12}>
            <TextInputContainer label={t('slug')}>
              <SlugInput
                enableGenerate
                setValue={setValue}
                getData={() => {
                  const value = getValues('name');
                  console.log(value);
                  if (!value)
                    return showErrorMessage('product-slug-data-insufficient');
                  return value;
                }}
                defaultValue={slug}
                textField
                placeholder={t('slug')}
                size='small'
                variant='outlined'
                name='slug'
                register={register}
                fullWidth
              />
            </TextInputContainer>
          </Grid>
          <Grid item xs={12}>
            <TextInputContainer label={t('sku')}>
              <SKUInput
                enableGenerate
                setValue={setValue}
                getData={() => {
                  const value = getValues();
                  const data = {
                    vendor: 'CP',
                    name: value.name,
                  };
                  if (data.name) {
                    return data;
                  }
                  showSnackbar({
                    message: 'composite-sku-data-insufficient',
                    variant: 'error',
                  });
                  return null;
                }}
                defaultValue={sku}
                textField
                placeholder={t('sku')}
                size='small'
                variant='outlined'
                name='sku'
                register={register}
                fullWidth
              />
            </TextInputContainer>
          </Grid>
          <Grid item xs={12}>
            <TagInput control={control} />
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={6}>
        <Grid container spacing={2}>
          <Grid item container xs={12}>
            <TextInput
              label={t('summary-description')}
              multiline
              rows={6}
              InputProps={{
                style: { paddingTop: '12.5px', paddingBottom: '2.5px' },
              }}
              {...registerMui(register('shortDescription'))}
            />
          </Grid>
          <Grid item xs={12}>
            <TextInput label={t('upc')} {...registerMui(register('altCode'))} />
          </Grid>
          <Grid item xs={12}>
            <TextInput
              label={t('low-stock')}
              type='number'
              placeholder='0'
              inputProps={{ min: 0 }}
              {...registerMui(register('lowStock', { valueAsNumber: true }))}
            />
          </Grid>
          <Grid item xs={12}>
            <ChipInput
              control={control}
              label={t('keywords')}
              name='keywords'
            />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default CompositeInfo;
