import { makeStyles } from '@material-ui/core';
import React from 'react';
import { useController } from 'react-hook-form';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/material.css';

const useStyle = makeStyles((theme) => ({
  container: {
    fontFamily: 'inherit',
    '& .form-control': {
      width: '100%',
      padding: '7.42px 14px 7.42px 58px',
      backgroundColor: 'white',
      borderStyle: 'solid',
      boxShadow: 'none',
      fontSize: '14px',
      fontFamily: 'inherit',
      '&:focus': {
        boxShadow: `0 0 0 1px ${theme.palette.primary.main}`,
        borderColor: theme.palette.action.active,
      },
    },
  },
}));

const RHFPhoneNumberInput = (props) => {
  const {
    name,
    control,
    required,
    defaultValue = '',
    label,
    placeholder = label?.toUpperCase(), //using label as default value
    ...textFieldProps
  } = props;

  // using react hook form useController as value state
  const {
    field: { ref, value, onChange, ...inputProps },
    fieldState: { error },
  } = useController({
    name,
    defaultValue,
    control, //optional when use inside formContext
    rules: { required },
  });

  const classes = useStyle();

  return (
    <PhoneInput
      defaultMask='.. ... ... ... ...'
      inputClass={classes.input}
      containerClass={classes.container}
      inputProps={{ ref, ...inputProps }}
      specialLabel=''
      value={value}
      country={'kh'}
      onChange={onChange}
      variant='outlined'
      countryCodeEditable={false}
      fullWidth
      placeholder={placeholder}
      {...textFieldProps}
    />
  );
};

export default RHFPhoneNumberInput;
