import {
  Box,
  Button,
  Grid,
  InputBase,
  makeStyles,
  Typography,
} from '@material-ui/core';
import React, { useState } from 'react';
import { AiOutlineUser } from 'react-icons/ai';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Link, useHistory } from 'react-router-dom';
import registerMui from '../../util/react-hook-form-helper/register-mui';
import LoginFormContainer from 'src/components/login-form-container';
import { emailOrPhoneRegexp, passwordRegexp } from 'src/util/regex-pattern';
import Prompt, { usePrompt } from 'src/components/prompt';
import useMessage from 'src/hooks/useMessage';
import Spinner, { useSpinner } from 'src/components/spinner';
import useFirebasePhoneVerifier from 'src/util/firebase/usePhoneVerification';
import authApi from 'src/api/auth';
import CustomLink from 'src/components/custom-link';
import clsx from 'clsx';
import PasswordInput from 'src/components/password-input';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import InputError from 'src/components/input-error';

const useStyles = makeStyles((theme) => ({
  input: {
    background: 'white',
    border: `1px solid ${theme.palette.grey[300]}`,
    borderRadius: '44px',
    padding: '5px 20px',
    '& > div > svg': {
      fontSize: '24px',
      color: '#7D7D7D',
      marginRight: '15px',
    },
    '& input': {
      fontSize: '16px',
      paddingBottom: '6px',
    },
  },
  error: {
    border: `1px solid ${theme.palette.error.main}`,
  },
  button: {
    height: '43.11px',
    borderRadius: '44px',
  },
  link: {
    fontSize: '14px',
    textDecoration: 'none',
    fontWeight: 'bold',
    color: theme.palette.primary.main,
  },
}));

const STEP = {
  FORGOT_FORM: 'forgot-form',
  SENT_OTP_CONFIRMATION: 'sent-otp-confirmation',
  RESET_SUCCESS: 'reset-success',
};

const ForgotPasswordPage = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const classes = useStyles();

  const { register, handleSubmit, formState, getValues, setValue } = useForm();
  const { showErrorResponseMessage, showErrorMessage } = useMessage();
  const { spinnerState, openSpinner, closeSpinner } = useSpinner({
    title: t('loading'),
  });

  const {
    requestPhoneVerificationCode,
    confirmPhoneVerificationCode,
    resetRecaptcha,
  } = useFirebasePhoneVerifier({
    recaptchaId: 'recaptcha',
  });

  const [step, setStep] = useState({ state: STEP.FORGOT_FORM, data: null });
  const { promptState, openPrompt, closePrompt } = usePrompt();

  const onSubmit = async (formData) => {
    try {
      openSpinner();
      let verifyCode = formData.verifyCode;
      if (step.data.type === 'phone') {
        const result = await confirmPhoneVerificationCode(verifyCode);
        verifyCode = result.idToken;
      }
      await authApi.resetPassword({ ...formData, verifyCode });
      setStep({ state: STEP.RESET_SUCCESS, data: null });
    } catch (error) {
      if (error.code === 'auth/invalid-verification-code') {
        showErrorMessage(t('invalid-verification-code'));
      } else {
        showErrorResponseMessage(error);
      }
    } finally {
      closeSpinner();
    }
  };

  const goBackToLogin = () => {
    history.push('/login');
  };

  const checkUserAvailability = async (formData) => {
    try {
      const { emailOrPhone } = formData;
      const response = await authApi.checkEmailOrPhoneAvailable({
        emailOrPhone,
        available: false,
      });
      const otp = {
        type: response.data?.data?.type,
        value: response.data?.data?.value,
      };
      openPrompt({
        title: t('otp-send-title', { type: t(otp.type) }),
        message: t('otp-send-message', { value: otp.value }),
        buttons: [
          {
            text: t('send'),
            onClick: async () => {
              try {
                openSpinner();
                await handleSendOtp(otp);
                setStep({ state: STEP.SENT_OTP_CONFIRMATION, data: otp });
              } catch (error) {
                console.log(error);
                showErrorMessage(t('register-failed'));
              } finally {
                closePrompt();
                closeSpinner();
              }
            },
          },
          { text: t('cancel'), onClick: () => closePrompt() },
        ],
      });
    } catch (error) {
      showErrorResponseMessage(error);
    }
  };

  const handleClickNoReceivedOtp = () => {
    openPrompt({
      title: t('otp-not-received'),
      message: '',
      buttons: [
        {
          text: t('resend-otp'),
          onClick: async () => {
            try {
              openSpinner();
              await handleSendOtp(step.data);
              setValue('verifyCode', '');
            } catch (error) {
              console.log(error);
              showErrorMessage(t('resend-failed'));
            } finally {
              closePrompt();
              closeSpinner();
            }
          },
        },
      ],
    });
  };

  const handleSendOtp = async (data) => {
    if (data.type === 'phone') {
      const phoneNumber = data.value;
      await requestPhoneVerificationCode(phoneNumber);
    } else if (data.type === 'email') {
      const email = data.value;
      await authApi.requestEmailOTP(email, false);
    }
  };

  return (
    <form
      onSubmit={handleSubmit(
        step.state === STEP.FORGOT_FORM ? checkUserAvailability : onSubmit
      )}
    >
      <LoginFormContainer>
        <div id='recaptcha'></div>
        <Grid container spacing={2} style={{ height: '100%' }} justify='center'>
          {step.state === STEP.FORGOT_FORM && (
            <>
              <Grid item xs={12}>
                <Typography
                  gutterBottom
                  color='primary'
                  variant='h6'
                  style={{ textTransform: 'initial' }}
                >
                  {t('forgot-password')}
                </Typography>
                <Typography variant='body2'>
                  {t('forgot-password-message')}
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <div className={classes.input}>
                  <InputBase
                    placeholder={t('email-or-phone')}
                    fullWidth
                    {...registerMui(
                      register('emailOrPhone', {
                        required: true,
                        pattern: emailOrPhoneRegexp,
                      })
                    )}
                    startAdornment={<AiOutlineUser />}
                  />
                </div>
              </Grid>
              <Grid item xs={12}>
                <Button
                  type='submit'
                  className={classes.button}
                  variant='contained'
                  fullWidth
                  disableElevation
                  color='primary'
                >
                  {t('reset-password')}
                </Button>
              </Grid>
              <Grid item xs={12}>
                <Typography
                  className={classes.link}
                  component={Link}
                  to='/login'
                >
                  {t('back-to-login')}
                </Typography>
              </Grid>
            </>
          )}
          {step.state === STEP.SENT_OTP_CONFIRMATION && (
            <>
              <Grid item xs={12}>
                <Typography
                  gutterBottom
                  color='primary'
                  variant='h6'
                  style={{ textTransform: 'initial' }}
                >
                  {t('reset-password')}
                </Typography>
                <Typography variant='body2'>
                  {t('reset-password-message', { value: step.data?.value })}
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <div
                  className={clsx(classes.input, {
                    [classes.error]: formState.errors.newPassword,
                  })}
                >
                  <PasswordInput
                    inputProps={{ style: { textAlign: 'center' } }}
                    placeholder={t('new-password')}
                    fullWidth
                    autoComplete='off'
                    {...registerMui(
                      register('newPassword', {
                        required: true,
                        pattern: {
                          value: passwordRegexp,
                          message: t('required-strong-password'),
                        },
                      })
                    )}
                    customEndAdornment={
                      <InputError error={formState.errors.newPassword} />
                    }
                  />
                </div>
              </Grid>
              <Grid item xs={12}>
                <div
                  className={clsx(classes.input, {
                    [classes.error]: formState.errors.confirmPassword,
                  })}
                >
                  <PasswordInput
                    inputProps={{ style: { textAlign: 'center' } }}
                    placeholder={t('confirm-password')}
                    fullWidth
                    autoComplete='off'
                    {...registerMui(
                      register('confirmPassword', {
                        required: true,
                        validate: (value) => {
                          return value === getValues('newPassword');
                        },
                        minLength: 6,
                      })
                    )}
                  />
                </div>
              </Grid>
              <Grid item xs={12}>
                <div
                  className={clsx(classes.input, {
                    [classes.error]: formState.errors.verifyCode,
                  })}
                >
                  <InputBase
                    inputProps={{ style: { textAlign: 'center' } }}
                    placeholder={t('verification-code')}
                    fullWidth
                    type='number'
                    autoComplete='off'
                    {...registerMui(
                      register('verifyCode', {
                        required: true,
                        minLength: 6,
                        maxLength: 6,
                      })
                    )}
                  />
                </div>
                <Box mt={1} textAlign='right'>
                  <CustomLink
                    onClick={handleClickNoReceivedOtp}
                    component='span'
                  >
                    {t('otp-not-received')}
                  </CustomLink>
                </Box>
              </Grid>
              <Grid item xs={6}>
                <Box mt={1}>
                  <Button
                    disableElevation
                    className={classes.button}
                    variant='contained'
                    fullWidth
                    onClick={() => {
                      resetRecaptcha();
                      setValue('verifyCode', '');
                      setStep({ state: STEP.FORGOT_FORM, data: null });
                    }}
                  >
                    {t('cancel')}
                  </Button>
                </Box>
              </Grid>
              <Grid item xs={6}>
                <Box mt={1}>
                  <Button
                    disableElevation
                    className={classes.button}
                    variant='contained'
                    fullWidth
                    type='submit'
                    color='primary'
                  >
                    {t('next')}
                  </Button>
                </Box>
              </Grid>
            </>
          )}
          {step.state === STEP.RESET_SUCCESS && (
            <>
              <Grid item xs={12}>
                <Typography
                  gutterBottom
                  color='primary'
                  variant='h6'
                  style={{
                    display: 'inline-flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    textTransform: 'initial',
                  }}
                >
                  <CheckCircleIcon
                    style={{ fontSize: '5rem' }}
                    color='primary'
                  />
                  {t('password-reset-successful')}
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Box mt={1}>
                  <Button
                    disableElevation
                    className={classes.button}
                    variant='contained'
                    fullWidth
                    color='primary'
                    onClick={goBackToLogin}
                  >
                    {t('back-to-login')}
                  </Button>
                </Box>
              </Grid>
            </>
          )}
        </Grid>
        <Prompt state={promptState} />
        <Spinner state={spinnerState} />
      </LoginFormContainer>
    </form>
  );
};

export default ForgotPasswordPage;
