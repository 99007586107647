import { useState } from 'react';
import { Visibility, VisibilityOff } from '@material-ui/icons';
import { IconButton } from '@material-ui/core';
import RHFTextInput from '../text-input';

const RHFPasswordInput = ({ customEndAdornment, ...props }) => {
  const [showPassword, setShowPassword] = useState(false);

  const handleToggleShowPassword = () => setShowPassword((prev) => !prev);

  const showPasswordProps = {};

  const adornment = (
    <>
      {customEndAdornment}
      <IconButton size='small' edge='end' onClick={handleToggleShowPassword}>
        {showPassword ? <VisibilityOff /> : <Visibility />}
      </IconButton>
    </>
  );

  if (props.component?.render?.name === 'InputBase') {
    showPasswordProps.endAdornment = adornment;
  } else {
    showPasswordProps.InputProps = {
      endAdornment: adornment,
    };
  }

  return (
    <RHFTextInput
      type={showPassword ? 'text' : 'password'}
      {...showPasswordProps}
      {...props}
    />
  );
};

export default RHFPasswordInput;
