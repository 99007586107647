import React, { useContext } from 'react';
import {
  Grid,
  makeStyles,
  MenuItem,
  Paper,
  Typography,
} from '@material-ui/core';
import TitleBar from '../../../components/title-bar';
import FloatingBar from '../../../components/floating-bar';
import { useHistory } from 'react-router-dom';
import { useForm, FormProvider } from 'react-hook-form';
import axios from 'axios';
import Products from './product';
import { UtilityContext } from '../../../components/context-provider/utilty-context';
import DateFnsUtils from '@date-io/date-fns';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import LabelInput from '../../../components/label-input';
import { RHFTextInput } from '../../../components/rhf-controlled-input';
import { useTranslation } from 'react-i18next';
import Spinner, { useSpinner } from 'src/components/spinner';
import RHFSelect from 'src/components/rhf-controlled-input/select';

const useStyles = makeStyles(() => ({
  root: {
    marginBottom: '150px',
    flexDirection: 'column',
    position: 'relative',
  },
}));

const AdjustStockPage = () => {
  const { t } = useTranslation();
  const classes = useStyles();
  const history = useHistory();
  const { showSnackbar } = useContext(UtilityContext);
  const { spinnerState, openSpinner, closeSpinner } = useSpinner();

  const methods = useForm({
    defaultValues: {
      product: [
        {
          variant: null,
          qty: 1,
          costPrice: 0,
        },
      ],
      type: 'add',
    },
    shouldUnregister: true,
  });

  const onSubmit = async (formData) => {
    openSpinner({ title: t('saving') });
    const { product, note, type } = formData;

    const updateProducts = product.reduce((acc, curr) => {
      const { variant, storage, qty, costPrice, salePrice, expirtyDate } = curr;
      if (variant?._id || variant?.id) {
        acc.push({
          storage: {
            id: storage?._id ?? null,
            name: storage?.name ?? 'default',
          },
          stock: { id: variant._id, name: variant?.variant?.name },
          qty: Number(qty),
          costPrice: Number(costPrice),
          salePrice: Number(salePrice),
          expirtyDate,
        });
      }
      return acc;
    }, []);

    axios
      .post(`${process.env.REACT_APP_API}/api/v1/adjust-stock`, {
        products: updateProducts,
        note,
        type,
      })
      .then(({ data }) => {
        showSnackbar({ message: data?.message });
        history.push('/dashboard/stock');
      })
      .catch(({ response }) => {
        showSnackbar({
          message: t(response?.data?.message),
          variant: 'error',
        });
      })
      .finally(() => {
        closeSpinner();
      });
  };

  const handleCancel = () => {
    goBackToList();
  };

  const goBackToList = () => {
    history.push('/dashboard/stock');
  };

  return (
    <FormProvider {...methods}>
      <MuiPickersUtilsProvider utils={DateFnsUtils}>
        <form id='stock-adjust-form' onSubmit={methods.handleSubmit(onSubmit)}>
          <Grid container className={classes.root} spacing={3}>
            <Grid item>
              <TitleBar disableButton />
            </Grid>
            <Grid item>
              <Paper className='container'>
                <Grid container spacing={3}>
                  <Grid item xs={12} container alignItems='center' spacing={1}>
                    <Grid item>
                      <Typography>Type:</Typography>
                    </Grid>
                    <Grid item>
                      <RHFSelect variant='outlined' size='small' name='type'>
                        <MenuItem value='add'>Add</MenuItem>
                        <MenuItem value='remove'>Remove</MenuItem>
                      </RHFSelect>
                    </Grid>
                  </Grid>
                  <Grid item xs={12}>
                    <Products />
                  </Grid>
                  <Grid item xs={12}>
                    <Grid container justify='space-between'>
                      <Grid item xs={6}>
                        <Grid container spacing={2}>
                          <Grid item xs={12}>
                            <LabelInput
                              component={RHFTextInput}
                              name='note'
                              label={t('note')}
                              multiline
                              rows={10}
                            />
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Paper>
            </Grid>
            <FloatingBar
              onCancel={handleCancel}
              confirmButtonProps={{ form: 'stock-adjust-form' }}
              confirmButtonText={'create-adjust-stock'}
            />
          </Grid>
        </form>
        <Spinner state={spinnerState} />
      </MuiPickersUtilsProvider>
    </FormProvider>
  );
};

export default AdjustStockPage;
