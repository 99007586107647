import { Avatar, Divider, makeStyles, Typography } from '@material-ui/core';
import React from 'react';

const useStyles = makeStyles(() => ({
  logo: {
    width: '100px',
    height: '50px',
    '& img': {
      objectFit: 'contain',
    },
    position: 'absolute',
    top: 0,
    left: 0,
    marginTop: '20px',
    marginLeft: '10px',
  },
}));

const InvoiceStoreHeader = ({ warehouse }) => {
  const classes = useStyles();
  return (
    <div>
      {/* <Typography
                    style={{ fontFamily: 'Moul', fontSize: '18px' }}
                    align='center'
                  >
                    បេប៊ីខូ សបភីង ឯ.ក
                  </Typography> */}
      <Typography align='center' style={{ fontSize: '18px' }}>
        {warehouse?.name}
      </Typography>
      {/* <Typography
                    gutterBottom
                    align='center'
                    style={{ fontSize: '14px' }}
                  >
                    VATTIN: K002-902104085
                  </Typography> */}
      {/* <Typography
                    gutterBottom
                    align='center'
                    style={{ fontSize: '11px' }}
                  >
                    លេខ១២ ជាន់ទី២ ផ្លូវ៣៤៨ ភូមិ៤ សង្កាត់ទួលស្វាយព្រៃទី១ ខណ្ឌបឹងកេងកង
                    រាជធានីភ្នំពេញ
                  </Typography> */}
      <Typography gutterBottom align='center' style={{ fontSize: '11px' }}>
        {warehouse?.address?.addr}
      </Typography>
      <Typography gutterBottom align='center' style={{ fontSize: '11px' }}>
        លេខទូរសព្ទ / Telephone No: {warehouse?.phoneNumber}
      </Typography>
      <Divider style={{ background: 'black' }} />
      <Avatar
        variant='square'
        src={warehouse?.logo?.key || '/logo.png'}
        className={classes.logo}
      ></Avatar>
    </div>
  );
};

export default InvoiceStoreHeader;
