import React from 'react';
import { Grid } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import 'date-fns';
import LabelInput from '../../../../components/label-input';
import {
  RHFDateInput,
  RHFTextInput,
} from '../../../../components/rhf-controlled-input';

const PurchaseOrderInformation = () => {
  const { t } = useTranslation();

  return (
    <Grid container spacing={3}>
      <Grid item xs={6}>
        <LabelInput
          component={RHFTextInput}
          name='reference.number'
          label={t('reference-number')}
        />
      </Grid>
      <Grid item xs={6}>
        <LabelInput
          label={t('created-date')}
          component={RHFDateInput}
          defaultValue={new Date()}
          name='createdDate'
        />
      </Grid>
    </Grid>
  );
};

export default PurchaseOrderInformation;
