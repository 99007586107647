import React from 'react';
import { Select, MenuItem, Typography, makeStyles } from '@material-ui/core';
import Pagination from '@material-ui/lab/Pagination';

const useStyles = makeStyles(() => ({
  root: {
    display: 'flex',
    marginTop: '50px',
    justifyContent: 'space-between',
  },
  select: {
    display: 'flex',
    alignItems: 'center',
    '& .MuiInputBase-root.MuiOutlinedInput-root': {
      margin: '0 5px',
    },
    '& .MuiSelect-outlined.MuiSelect-outlined': {
      padding: '5px 28px 5px 10px !important',
    },
    '& p': {
      fontSize: '14px',
    },
  },
}));

const PaginationCustom = ({
  page,
  onChangePagination,
  onChangeRowsPerPage,
  count,
  total,
  rowsPerPage,
  rowsPerPageOptions = [5, 10, 25],
}) => {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <div className={classes.select}>
        <Typography>Show </Typography>
        <Select
          variant='outlined'
          value={rowsPerPage}
          onChange={onChangeRowsPerPage}
        >
          {rowsPerPageOptions.map((item, index) => (
            <MenuItem value={item} key={index}>
              {item}
            </MenuItem>
          ))}
        </Select>
        <Typography> of {total} entries</Typography>
      </div>
      <Pagination
        shape='rounded'
        color='primary'
        page={page}
        onChange={onChangePagination}
        count={count}
      />
    </div>
  );
};

export default PaginationCustom;
