import React, { useState, useCallback } from 'react';
import { Button, makeStyles, Typography } from '@material-ui/core';
import { useDropzone } from 'react-dropzone';
import ProfilePreview from './profile-preview';
import ProfileDialog from './profile-dialog';
import b64toBlob from '../../../../util/b64toBlob';

const useStyles = makeStyles(() => ({
  root: {
    outline: 'none',
    padding: '10px 0',
    // position: 'relative',
    height: '250px',
    // backgroundColor: 'rgba(9,128,146,0.1)',
    // backgroundColor: 'red',
    // backgroundImage:
    //   "url(\"data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' stroke='%23098092FF' stroke-width='4' stroke-dasharray='12' stroke-dashoffset='0' stroke-linecap='square'/%3e%3c/svg%3e\")",
  },
  container: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    textAlign: 'center',
    height: '100%',
    '& button': {
      margin: '10px 0',
    },
  },
}));

const ProfileUpload = ({
  profile,
  setProfile,
  scale,
  setScale,
  editorRef,
  previewProfile,
  setPreviewProfile,
  setChangedProfile,
}) => {
  const classes = useStyles();
  const [openDialog, setOpenDialog] = useState(false);
  const [draftProfile, setDraftProfile] = useState(profile);

  const onDrop = useCallback(
    async (acceptedFiles) => {
      if (acceptedFiles.length > 0) {
        // setProfile(acceptedFiles[0]);
        setDraftProfile(acceptedFiles[0]);
      }
    },
    [profile]
  );

  const { getRootProps, getInputProps, isDragActive, open } = useDropzone({
    onDrop,
    noClick: true,
    accept: 'image/*',
    maxFiles: 1,
  });

  const handleScale = (e) => {
    const scaleTo = parseFloat(e.target.value);
    setScale(scaleTo);
  };

  const handleClose = (e) => {
    setOpenDialog(false);
  };

  const handleSave = (e) => {
    // const img = editorRef?.current?.getImageScaledToCanvas()?.toDataURL();
    if (draftProfile === null) {
      return handleBrowseImage();
    }
    // return;
    const profileBase64 = editorRef?.current?.getImage().toDataURL(); // get base64
    var block = profileBase64.split(';');
    // Get the content type of the image
    var contentType = block[0].split(':')[1];
    // get the real base64 content of the file
    var realData = block[1].split(',')[1];
    var blob = b64toBlob(realData, contentType); // generate blob from base64
    // update the name, lastModifiedData, lastModified field to be the same as the profile(File)
    blob.name = draftProfile.name;
    blob.lastModifiedDate = draftProfile.lastModifiedDate;
    blob.lastModified = draftProfile.lastModified;
    setProfile(blob);
    setPreviewProfile(profileBase64);
    setChangedProfile(true);
    setOpenDialog(false);
  };

  const handleBrowseImage = () => {
    open();
    setOpenDialog(true);
  };

  return (
    <div {...getRootProps()} className={classes.root}>
      <input {...getInputProps()} />
      <div className={classes.container}>
        <ProfilePreview profile={profile} previewProfile={previewProfile} />
        <ProfileDialog
          editorRef={editorRef}
          open={openDialog}
          handleClose={handleClose}
          handleSave={handleSave}
          profile={draftProfile}
          setProfile={setProfile}
          scale={scale}
          setScale={setScale}
          handleScale={handleScale}
          handleBrowseImage={handleBrowseImage}
        />
        {/* <Button
          onClick={handleBrowseImage}
          disableElevation
          size='small'
          variant='contained'
          color='primary'
        >
          Browse image
        </Button>
        <Typography variant='caption' gutterBottom>
          Image should not exceeding 5MB
        </Typography> */}
      </div>
    </div>
  );
};

export default ProfileUpload;
