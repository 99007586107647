import React, { useContext, useState, useRef } from 'react';
import {
  Button,
  Avatar,
  Typography,
  makeStyles,
  Popper,
  Grow,
  Paper,
  ListItem,
  List,
  ListItemIcon,
  ClickAwayListener,
  ListItemText,
} from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import { UserContext } from '../../../../context-provider/user-context';
import axios from 'axios';
import { useHistory } from 'react-router-dom';
import PersonIcon from '@material-ui/icons/Person';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles(() => ({
  profileButton: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    textTransform: 'none',
    '& p': {
      margin: '0 10px',
    },
  },
  paper: {
    padding: '10px',
    marginTop: '8px',
  },
}));

const Profile = () => {
  const classes = useStyles();
  const { t } = useTranslation();
  const { me, loading } = useContext(UserContext);
  const [open, setOpen] = useState(false);
  const anchorRef = useRef();
  const history = useHistory();

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const handleLogout = () => {
    axios
      .get(`${process.env.REACT_APP_API}/api/v1/auth/logout`)
      .catch((err) => console.log(err))
      .finally(() => {
        history.push('/login');
      });
  };

  const handleGoToAccountSetting = () => {
    handleClose();
    history.push('/dashboard/setting/profile');
  };

  return !loading && me ? (
    <>
      <Button
        ref={anchorRef}
        onClick={handleOpen}
        className={classes.profileButton}
        color='primary'
      >
        <Avatar src={me?.user?.id?.profile?.key} />
        <Typography>{`${me?.user.fullname}`}</Typography>
        <ExpandMoreIcon />
      </Button>
      <Popper
        open={open}
        anchorEl={anchorRef.current}
        transition
        placement='bottom-end'
        disablePortal
      >
        {({ TransitionProps }) => (
          <Grow {...TransitionProps}>
            <Paper elevation={3} className={classes.paper}>
              <ClickAwayListener onClickAway={handleClose}>
                <List disablePadding>
                  <ListItem button onClick={handleGoToAccountSetting}>
                    <ListItemIcon>
                      <PersonIcon color='primary' />
                    </ListItemIcon>
                    <ListItemText>{t('profile')}</ListItemText>
                  </ListItem>
                  <ListItem button onClick={handleLogout}>
                    <ListItemIcon>
                      <ExitToAppIcon color='primary' />
                    </ListItemIcon>
                    <ListItemText>{t('logout')}</ListItemText>
                  </ListItem>
                </List>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </>
  ) : null;
};

export default Profile;
