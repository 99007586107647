import { Grid, Typography, Box } from '@material-ui/core';
import React from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import TextInputListSearch from 'src/components/text-input-list-search';

const PaidBy = () => {
  const { t } = useTranslation();
  const { control } = useFormContext();

  return (
    <Box mt={1}>
      <Grid container>
        <Grid item xs={3}>
          <Controller
            control={control}
            name='paidBy'
            defaultValue={null}
            render={({ field: { value, onChange } }) => {
              return (
                <TextInputListSearch
                  value={value}
                  required
                  onChange={(e, data) => {
                    const paidBy = {
                      id: data?._id,
                      code: data?.code,
                      name: data?.name,
                    };
                    onChange(paidBy);
                  }}
                  dataConfig={['paymentAccounts']}
                  optionsURL={`${process.env.REACT_APP_API}/api/v1/payment-accounts`}
                  label={t('paid-by')}
                  placeholder={t('paid-by')}
                  getOptionLabel={(option) => `${option.code} - ${option.name}`}
                  getOptionSelected={(option, value) =>
                    option.name === value.name || value._id || value.id
                  }
                />
              );
            }}
          ></Controller>
        </Grid>
      </Grid>
    </Box>
  );
};

export default PaidBy;
