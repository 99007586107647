import React from 'react';
import TotalDisplay from '../../../components/total-display';
import { useWatch } from 'react-hook-form';
import { getValue as productGetValue } from './products';

const CompositeItemsTotal = () => {
  const products = useWatch({ name: 'products' });

  let subtotal = 0;
  let productTaxTotal = 0;

  if (products?.length > 0) {
    ({ subtotal, productTaxTotal } = products?.reduce(
      (acc, curr) => {
        if (!curr.isCanceled) {
          const get = productGetValue(curr);
          const totalAmount = get.totalAmount;
          acc.subtotal += totalAmount;
          acc.productTaxTotal += 0;
        }
        return acc;
      },
      { subtotal: 0, productTaxTotal: 0 }
    ));
  }

  return <TotalDisplay subtotal={subtotal} disableCosting />;
};

export default CompositeItemsTotal;
