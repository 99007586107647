import React from 'react';
import {
  FormControl,
  FormLabel,
  TextField,
  makeStyles,
} from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';

const useStyles = makeStyles(() => ({
  label: {
    color: 'black',
    fontWeight: 'bold',
    marginBottom: '10px',
    fontSize: '14px',
    textTransform: 'uppercase',
  },
}));

const TextInputListSugguestion = ({
  label,
  value,
  list,
  onChange,
  required,
  ...other
}) => {
  const classes = useStyles();

  return (
    <FormControl fullWidth>
      <FormLabel className={classes.label}>{label}</FormLabel>
      <Autocomplete
        options={list}
        disableClearable
        value={value}
        {...other}
        onChange={onChange}
        renderInput={(params) => {
          return (
            <TextField
              {...params}
              onChange={(e) => {
                params.inputProps.onChange(e);
              }}
              required={required}
              variant='outlined'
              size='small'
              placeholder={label.toUpperCase()}
            />
          );
        }}
      ></Autocomplete>
    </FormControl>
  );
};

export default TextInputListSugguestion;
