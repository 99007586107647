import React from 'react';
import {
  makeStyles,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Grid,
  Avatar,
  Typography,
  InputBase,
  Button,
} from '@material-ui/core';
import { useFieldArray, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import PhotoIcon from '@material-ui/icons/Photo';

import NumberFormatCustom from '../../../../components/number-format';
import { registerMui } from '../../../../util/react-hook-form-helper';
import clsx from 'clsx';

const useStyles = makeStyles((theme) => ({
  contactForm: {
    '& td,th': {
      border: 0,
      textAlign: 'center',
    },
    '& th': {
      textTransform: 'uppercase',
      fontWeight: 'bold',
      padding: '10px',
      borderBottom: '1px solid rgb(112,112,112)',
    },
    '& tr:nth-child(even)': {
      '& td': {
        background: theme.palette.primary.ligther,
      },
    },
    '& td': {
      padding: '10px',
      '& p': {
        textAlign: 'center',
      },
      '& > div:not(.MuiAvatar-root)': {
        background: 'white',
        border: '1px solid rgba(170,170,170,0.6)',
        padding: '2px 6px',
        borderRadius: '5px',
      },
    },
    '& .MuiAvatar-root': {
      padding: 0,
    },
    '& .MuiInputBase-root': {
      padding: '3px 10px !important',
    },
    '& svg': {
      fontSize: '16px',
    },
  },
  hidden: {
    visibility: 'hidden',
  },
  image: {
    width: '40px',
    backgroundColor: `${theme.palette.primary.ligther} !important`,
    '& svg': {
      color: theme.palette.primary.main,
      fontSize: '30px',
    },
    '& img': {
      objectFit: 'contain',
    },
  },
  amount: {
    '& input': {
      textAlign: 'right',
    },
    '& .MuiInputBase-root': {
      border: 0,
      background: 'transparent',
    },
  },
  noPadding: {
    padding: '8px 0 !important',
  },
  datePicker: {
    margin: 0,
    '& input': {
      textAlign: 'right',
    },
  },
  input: {
    display: 'block',
    '& input': {
      textAlign: 'right',
    },
  },
  buttonHeader: {
    fontWeight: 'bold',
    padding: '3px',
    textTransform: 'uppercase',
    fontSize: '15px',
  },
  error: {
    '& > div': {
      border: `1px solid ${theme.palette.secondary.main} !important`,
      textAlign: 'center',
    },
  },
  textCenter: {
    '& input': {
      textAlign: 'center',
    },
  },
}));

const Products = () => {
  const { control, register, formState, setValue } = useFormContext();
  const classes = useStyles();

  const { t } = useTranslation();
  const { fields } = useFieldArray({
    control,
    name: 'product',
  });

  const handleReceivedAll = () => {
    fields.forEach((item, index) => {
      setValue(`product.${index}.receivedQty`, item.qty);
    });
  };

  return (
    <Grid container className='container' spacing={5}>
      <Grid item xs={12}>
        <Table className={classes.contactForm}>
          <TableHead>
            <TableRow>
              <TableCell style={{ textAlign: 'left' }}>{t('image')}</TableCell>
              <TableCell width='20%'>{t('product-name')}</TableCell>
              <TableCell>{t('outstanding')}</TableCell>
              <TableCell style={{ width: '13%' }}>
                <span> {t('received')}</span> <br />
                <Button
                  color='primary'
                  disabled={fields.length === 0}
                  className={classes.buttonHeader}
                  onClick={handleReceivedAll}
                >
                  {t('received-all')}
                </Button>
              </TableCell>
              <TableCell width='25%'>{t('note')}</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {fields.map((field, index) => {
              let image = field.variant?.image?.thumbnail;

              return (
                <TableRow key={field.id}>
                  <TableCell padding='none'>
                    <Avatar
                      src={image}
                      className={classes.image}
                      variant='square'
                    >
                      <PhotoIcon />
                    </Avatar>
                  </TableCell>
                  <TableCell padding='none'>
                    <Typography>{field.variant.name}</Typography>
                  </TableCell>
                  <TableCell padding='none'>
                    <Typography>
                      <NumberFormatCustom
                        format='normal'
                        value={field.qty}
                      ></NumberFormatCustom>
                    </Typography>
                  </TableCell>
                  <TableCell
                    // show on error
                    className={clsx({
                      [classes.error]:
                        formState.errors.variant &&
                        formState.errors.variant[index],
                    })}
                    padding='none'
                  >
                    <InputBase
                      defaultValue={field.receivedQty}
                      className={classes.input}
                      {...registerMui(
                        // validate allow only value less than or equal to field.qty
                        register(`product.${index}.receivedQty`, {
                          validate: (value) => value <= field.qty,
                          required: true,
                          valueAsNumber: true,
                        })
                      )}
                      type='number'
                      fullWidth
                    />
                  </TableCell>
                  <TableCell
                    // show on error
                    className={clsx({
                      [classes.error]:
                        formState.errors.variant &&
                        formState.errors.variant[index],
                    })}
                  >
                    <InputBase
                      className={clsx(classes.input, classes.textCenter)}
                      {...registerMui(register(`product.${index}.note`))}
                      fullWidth
                    />
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </Grid>
    </Grid>
  );
};

export default Products;
