import React, { useRef, useEffect, useState, useContext } from 'react';
import { Grid, makeStyles, Paper } from '@material-ui/core';
import TitleBar from '../../../components/title-bar';
import FloatingBar from '../../../components/floating-bar';
import { useParams, useHistory } from 'react-router-dom';
import { useForm, FormProvider } from 'react-hook-form';
import axios from 'axios';
import UserInfo from './user-info';
import { UtilityContext } from '../../../components/context-provider/utilty-context';

import { UserContext } from '../../../components/context-provider/user-context';
import Spinner, { useSpinner } from 'src/components/spinner';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles(() => ({
  root: {
    marginBottom: '150px',
    flexDirection: 'column',
    position: 'relative',
  },
}));

const AddEditUserPage = () => {
  const classes = useStyles();
  const history = useHistory();
  const { id } = useParams();
  const { showSnackbar } = useContext(UtilityContext);
  const { me, s3BaseURL } = useContext(UserContext);
  const methods = useForm();
  const [state, setState] = useState('create');
  const [userData, setUserData] = useState(null);
  const [preSelectedRoles, setPreSelectedRoles] = useState([]);
  const [allRoles, setAllRoles] = useState([]);
  const [profile, setProfile] = useState(null);
  const [previewProfile, setPreviewProfile] = useState(null);
  const [changedProfile, setChangedProfile] = useState(false);
  const [scale, setScale] = useState(1);
  const editorRef = useRef(null);

  const { t } = useTranslation();
  const { spinnerState, openSpinner, closeSpinner } = useSpinner();

  useEffect(() => {
    if (id) {
      openSpinner({ title: t('loading') });
      setState('edit');
      axios
        .get(`${process.env.REACT_APP_API}/api/v1/auth/users/${id}`)
        .then(({ data }) => {
          // console.log("data in axios: ", data);
          if (typeof data === 'object') {
            const populatedUser = data.user.id; // user.id is resulted from populated at backend
            populatedUser.roles = data.roles;
            setUserData(populatedUser);
            setPreSelectedRoles(data.roles);
            setProfile(populatedUser?.profile);
            setPreviewProfile(populatedUser?.profile?.key);
          } else {
            console.log('error response');
          }
        })
        .catch(({ response }) => {
          showSnackbar({
            message: response.data?.key,
            variant: 'error',
          });
        })
        .finally(() => {
          closeSpinner();
        });
    } else {
      setState('create');
    }
  }, []);

  useEffect(() => {
    if (userData) {
      methods.reset(userData);
    }
  }, [userData]);

  const onSubmit = async (data) => {
    openSpinner({ title: t('saving') });
    const updateData = {
      // firstname: data.firstname,
      // lastname: data.lastname,
      // address: data.address,
      // gender: data.gender,
      // email: data.email,
      // phone: data.phone,
      // dateOfBirth: data.dateOfBirth,
      roles: data.roles,
      // profile: data.profile,
    };

    // let uploadObject = {
    //   ContentTypes: [],
    //   Keys: [],
    //   Images: [],
    // };

    // try {
    //   if (profile?.type) {
    //     const fileType = profile.type;
    //     const fileExtension = profile.type.split('/')[1];

    //     // generate key
    //     uploadObject.ContentTypes.push(fileType);
    //     const key = `public/users-profile/${generateKey()}`;
    //     const imageKey = `${key}.${fileExtension}`;
    //     const thumbnailKey = `${key}-thumbnail.${fileExtension}`;

    //     uploadObject.Keys.push(imageKey, thumbnailKey);

    //     // compress image
    //     const { image, thumbnail } = await compressImage(profile);
    //     uploadObject.Images.push(image, thumbnail);

    //     updateData.profile = {
    //       key: `${s3BaseURL}/${imageKey}`,
    //       thumbnail: `${s3BaseURL}/${thumbnailKey}`,
    //       mineType: fileType,
    //     };

    //     // get s3 upload url
    //     const response = await axios.post(
    //       `${process.env.REACT_APP_API}/api/v1/aws-s3/file-upload-url`,
    //       {
    //         ContentTypes: uploadObject.ContentTypes,
    //         Keys: uploadObject.Keys,
    //       }
    //     );

    //     await Promise.all(
    //       response.data.map(async (item, index) => {
    //         await axios.put(item.uploadURL, uploadObject.Images[index], {
    //           headers: {
    //             'Content-Type': item.type,
    //             'Content-Encoding': 'base64',
    //           },
    //           withCredentials: false,
    //         });
    //       })
    //     );
    //   }
    // } catch (err) {
    //   showSnackbar({
    //     message: t('upload-image-failed'),
    //     variant: 'error',
    //   });
    //   console.error(err);
    //   closeSpinner();
    //   return;
    // }

    // console.log('updatedData in axios', updateData);

    switch (state) {
      case 'create':
        axios
          .post(`${process.env.REACT_APP_API}/api/v1/auth/users`, updateData)
          .then(({ data }) => {
            showSnackbar({ message: data?.key });
            goBackToList();
          })
          .catch(({ response }) => {
            showSnackbar({
              message: response.data?.key,
              variant: 'error',
            });
          })
          .finally(() => {
            closeSpinner();
          });
        break;
      case 'edit':
        axios
          .put(
            `${process.env.REACT_APP_API}/api/v1/auth/users/${id}`,
            updateData
          )
          .then(({ data }) => {
            showSnackbar({ message: data?.key });
            goBackToList();
          })
          .catch(({ response }) => {
            showSnackbar({
              message: response?.data?.key,
              variant: 'error',
            });
          })
          .finally(() => {
            closeSpinner();
          });
        break;
      default:
        console.log('Error state');
    }
  };

  const handleCancel = () => {
    goBackToList();
  };

  const goBackToList = () => {
    history.push('/dashboard/user');
  };

  return (
    <FormProvider {...methods}>
      <form id='user-form' onSubmit={methods.handleSubmit(onSubmit)}>
        <Grid container className={classes.root} spacing={3}>
          <Grid item xs={12}>
            <TitleBar disableButton />
          </Grid>
          <Grid item xs={12}>
            <Paper>
              <Grid container>
                <Grid item xs={12}>
                  <UserInfo
                    editorRef={editorRef}
                    profile={profile}
                    setProfile={setProfile}
                    scale={scale}
                    setScale={setScale}
                    allRoles={allRoles}
                    previewProfile={previewProfile}
                    setPreviewProfile={setPreviewProfile}
                    preSelectedRoles={preSelectedRoles}
                    setPreSelectedRoles={setPreSelectedRoles}
                    setChangedProfile={setChangedProfile}
                  />
                </Grid>
              </Grid>
            </Paper>
          </Grid>
          <FloatingBar
            onCancel={handleCancel}
            confirmButtonProps={{ form: 'user-form' }}
            disabledConfirmButton={
              state !== 'create' &&
              !changedProfile &&
              Object.keys(methods.formState.dirtyFields).length === 0
            }
            confirmButtonText={
              state === 'create' ? 'create-user' : 'save-changes'
            }
          />
        </Grid>
      </form>
      <Spinner state={spinnerState} />
    </FormProvider>
  );
};

export default AddEditUserPage;
