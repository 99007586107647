import { Button } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import useDialog from 'src/hooks/useDialog';
import OrderInvoiceDateEditorDialog from './OrderInvoiceDateEditorDialog';

const OrderDateEditor = ({ render, orderId, defaultDate, onSuccess }) => {
  const { t } = useTranslation();
  const { openDialog, closeDialog, dialogState } = useDialog();

  return (
    <>
      {render ? (
        render(openDialog)
      ) : (
        <Button onClick={openDialog}>{t('invoice-date-editor')}</Button>
      )}
      <OrderInvoiceDateEditorDialog
        defaultDate={defaultDate}
        onSuccess={onSuccess}
        open={dialogState.open}
        orderId={orderId}
        onClose={closeDialog}
      />
    </>
  );
};

export default OrderDateEditor;
