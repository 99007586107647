import React, { useEffect } from 'react';
import {
  makeStyles,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Grid,
  Typography,
  InputBase,
  Button,
  IconButton,
} from '@material-ui/core';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import { useFieldArray } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import RemoveCircleIcon from '@material-ui/icons/RemoveCircle';
import clsx from 'clsx';
import registerMui from '../../../../util/react-hook-form-helper/register-mui';

const useStyles = makeStyles((theme) => ({
  contactForm: {
    '& td,th': {
      border: 0,
      textAlign: 'center',
    },
    '& th': {
      textTransform: 'uppercase',
      fontWeight: 'bold',
      padding: '10px',
      borderBottom: '1px solid rgb(112,112,112)',
    },
    '& tr:nth-child(1)': {
      '& td': {
        paddingTop: '20px',
      },
    },
    '& tr:nth-child(even)': {
      '& td': {
        background: theme.palette.primary.ligther,
      },
    },
    '& td': {
      padding: '10px',
    },
    '& div': {
      background: 'white',
      border: '1px solid rgba(112,112,112,0.6)',
      padding: '3px 10px',
      borderRadius: '5px',
    },
    '& svg': {
      fontSize: '16px',
    },
    '& button': {
      marginRight: '5px',
    },
  },
  hidden: {
    visibility: 'hidden',
  },
  buttonContainer: {
    marginTop: '10px',
    textAlign: 'left',
  },
  button: {
    textTransform: 'uppercase',
    fontSize: '10px',
    fontWeight: 'bold',
    color: 'black',
    '& svg': {
      fontSize: '18px',
      color: theme.palette.primary.main,
    },
  },
}));

const ContactInfo = ({ register, control }) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const { fields, append, remove } = useFieldArray({
    control,
    name: 'contactInfo',
  });

  useEffect(() => {
    if (Array.isArray(fields) && fields.length === 0) {
      append({ fullname: '', phone: '', email: '', position: '' });
    }
  }, [control]);

  const handleAdd = () => {
    append({ fullname: '', phone: '', email: '', position: '' });
  };

  const handleRemove = (index) => {
    remove(index);
  };

  return (
    <Grid container className='container' spacing={2}>
      <Grid item xs={12}>
        <Typography variant='h6'>{t('contact-information')}</Typography>
      </Grid>
      <Grid item xs={12}>
        <Table className={classes.contactForm}>
          <TableHead>
            <TableRow>
              <TableCell>{t('fullname')}</TableCell>
              <TableCell>{t('phone')}</TableCell>
              <TableCell>{t('email')}</TableCell>
              <TableCell>{t('position')}</TableCell>
              <TableCell></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {fields.map((field, index) => (
              <TableRow key={field.id}>
                <TableCell padding='none'>
                  <InputBase
                    {...registerMui(register(`contactInfo.${index}.fullname`))}
                    defaultValue={field.fullname}
                    variant='outlined'
                    fullWidth
                  />
                </TableCell>
                <TableCell padding='none'>
                  <InputBase
                    {...registerMui(register(`contactInfo.${index}.phone`))}
                    defaultValue={field.phone}
                    variant='outlined'
                    fullWidth
                  />
                </TableCell>
                <TableCell padding='none'>
                  <InputBase
                    {...registerMui(register(`contactInfo.${index}.email`))}
                    defaultValue={field.email}
                    variant='outlined'
                    fullWidth
                  />
                </TableCell>
                <TableCell padding='none'>
                  <InputBase
                    {...registerMui(register(`contactInfo.${index}.position`))}
                    defaultValue={field.position}
                    variant='outlined'
                    fullWidth
                  />
                </TableCell>
                <TableCell padding='none'>
                  <IconButton
                    onClick={() => handleRemove(index)}
                    className={clsx({ [classes.hidden]: index === 0 })}
                    size='small'
                  >
                    <RemoveCircleIcon color='secondary' />
                  </IconButton>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
        <div className={classes.buttonContainer}>
          <Button
            className={classes.button}
            color='primary'
            size='small'
            onClick={handleAdd}
            disableElevation
          >
            <AddCircleIcon /> {t('add-more-contact')}
          </Button>
        </div>
      </Grid>
    </Grid>
  );
};

export default ContactInfo;
