import {
  InputAdornment,
  TableBody,
  TableCell,
  TableRow,
} from '@material-ui/core';
import { useContext } from 'react';
import { useFieldArray } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { UtilityContext } from 'src/components/context-provider/utilty-context';
import CustomRemoveIconButton from 'src/components/custom-button/remove-icon-button';
import CustomTable from 'src/components/custom-table';
import { RHFNumberInput } from 'src/components/rhf-controlled-input';

const ShippingFee = ({ control }) => {
  const { t } = useTranslation();
  const { fields, append, remove } = useFieldArray({
    name: 'shippingFee',
    control,
  });
  const { showDeletePrompt, closeDeletePrompt } = useContext(UtilityContext);

  // add row item
  const handleAddField = (index) => {
    if (index === fields.length - 1 && fields.length < 5) {
      append({ distance: '', fee: '' }, { shouldFocus: false });
    }
  };

  // remove row item prompt
  const handleRemove = (index, id) => {
    showDeletePrompt({
      handleConfirm: () => onDelete(index, id),
    });
  };

  // on remove confirm
  const onDelete = (index) => {
    remove(index);
    closeDeletePrompt();
  };
  return (
    <CustomTable.Container>
      <CustomTable.Head
        data={[
          { value: t('no.'), width: '20px' },
          { value: t('below-distance'), width: '45%' },
          { value: t('fee'), width: '45%' },
          { width: '20px' },
        ]}
      />
      <TableBody>
        {fields?.map((field, index) => {
          return (
            <TableRow key={field.id}>
              <TableCell>
                <CustomTable.CellValue>{index + 1}</CustomTable.CellValue>
              </TableCell>
              <TableCell>
                <RHFNumberInput
                  onChange={(e, onChange) => {
                    onChange(e.target.value);
                    handleAddField(index);
                  }}
                  name={`shippingFee.${index}.distance`}
                  InputProps={{
                    endAdornment: <InputAdornment>km</InputAdornment>,
                  }}
                  control={control}
                  defaultValue={field.distance}
                />
              </TableCell>
              <TableCell>
                <RHFNumberInput
                  name={`shippingFee.${index}.fee`}
                  control={control}
                  defaultValue={field.fee}
                  InputProps={{
                    endAdornment: <InputAdornment>$</InputAdornment>,
                  }}
                />
              </TableCell>
              <TableCell>
                <CustomTable.CellValue>
                  <CustomRemoveIconButton
                    invisible={
                      index === fields.length - 1 || fields.length <= 1
                    }
                    onClick={() => handleRemove(index, field._id)}
                  />
                </CustomTable.CellValue>
              </TableCell>
            </TableRow>
          );
        })}
      </TableBody>
    </CustomTable.Container>
  );
};
export default ShippingFee;
