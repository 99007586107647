import React from 'react';
import {
  Avatar,
  Drawer,
  makeStyles,
  useTheme,
  useMediaQuery,
  Toolbar,
  Typography,
} from '@material-ui/core';
import Menu from './menu';
import { useAuth } from 'src/components/context-provider/user-context';

const useStyles = makeStyles((theme) => ({
  drawer: {
    width: 280,
    flexShrink: 0,
  },
  paper: {
    width: 280,
    borderRight: 0,
    display: 'flex',
    overflowY: 'overlay',
    boxShadow:
      '2px 64px 4px 0px rgb(0 0 0 / 7%), 0px 0px 0px 0px rgb(0 0 0 / 14%), 0px 0px 0px 0px rgb(0 0 0 / 10%)',
  },
  logo: {
    width: '80%',
    height: '80%',
    '& img': {
      objectFit: 'contain',
    },
  },
  // logoContainer: {
  //   width: '100%',
  //   marginTop: theme.spacing(1.8),
  //   height: '100px',
  //   display: 'flex',
  //   justifyContent: 'center',
  //   alignItems: 'center',
  //   position: 'absolute',
  // },
}));

const DrawerComponent = ({ open, handleClose }) => {
  const classes = useStyles();
  const theme = useTheme();
  const matchSM = useMediaQuery(theme.breakpoints.down('sm'));
  const { me, loading } = useAuth();

  return (
    <Drawer
      open={open}
      variant={!matchSM ? 'permanent' : 'temporary'}
      onClose={handleClose}
      className={classes.drawer}
      classes={{
        paper: classes.paper,
      }}
    >
      <Toolbar
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          padding: '10px',
        }}
      >
        {!loading && (
          <Avatar
            src={me?.warehouse?.logo?.key || '/logo.png'}
            alt='logo'
            className={classes.logo}
            variant='square'
            style={{ width: '120px', height: '120px' }}
          />
        )}
      </Toolbar>
      <Menu handleClose={handleClose} />
      <Typography>{`Version ${process.env.REACT_APP_VERSION}`}</Typography>
    </Drawer>
  );
};

export default DrawerComponent;
