import { Divider, Grid, Typography } from '@material-ui/core';
import React from 'react';
import { useTranslation } from 'react-i18next';

const NoteInvoice = ({ note }) => {
  const { t } = useTranslation();
  return (
    <>
      <Grid item xs={12}>
        <Divider />
      </Grid>
      <Grid item xs={12}>
        <Typography align='left'>
          <b>{t('note')} : </b>
          {note}
        </Typography>
      </Grid>
    </>
  );
};

export default NoteInvoice;
