import {
  Avatar,
  Divider,
  Grid,
  makeStyles,
  Typography,
} from '@material-ui/core';
import clsx from 'clsx';
import React from 'react';
import buildAddress from '../../../../util/build-address';
import QRCode from 'qrcode.react';
import NumberFormatCustom from '../../../../components/number-format';
import logo from './asset/logo.png';
import { useTranslation } from 'react-i18next';
import { useAuth } from 'src/components/context-provider/user-context';

const useStyles = makeStyles((theme) => ({
  logo: {
    width: '15px',
    height: '15px',
    '& img': {
      objectFit: 'contain',
    },
  },
  root: {
    // height: '113.38px',
    padding: '0px 10px',
    '& *': {
      fontFamily: 'Siemreap , monospace',
    },
    '& h6': {
      fontSize: '15px',
    },
    '& p': {
      fontSize: '12px',
    },
  },
  textRight: {
    textAlign: 'right',
  },
  smaller: {
    fontSize: 8,
  },
  title: {
    fontSize: '12px !important',
  },
  text: {
    fontSize: '10px !important',
  },
}));

const ShippingLabel = React.forwardRef(({ data }, ref) => {
  const classes = useStyles();
  const { me } = useAuth();
  const { t } = useTranslation();
  return (
    <Grid container spacing={1} style={{ display: 'none' }}>
      <Grid
        item
        ref={ref}
        className={clsx(classes.root)}
        style={{ overflow: 'hidden' }}
      >
        <Grid container direction='column'>
          {/* <Grid item>
            <Grid container justify="space-around" alignItems="center">
              <Grid item>
                <Avatar src={logo} className={classes.logo} />
              </Grid>
              <Grid item>
                <Typography style={{ fontSize: '10px', textAlign: 'center' }}>
                  <span style={{ fontFamily: 'Moul', fontSize: '10px' }}>
                    បេប៊ីខូ រីថេល
                  </span>
                  <br />
                  <span>
                    <b>BabyCo Retail</b>
                  </span>
                </Typography>
              </Grid>
            </Grid>
          </Grid> */}
          {/* <Grid item>
            <Grid container justify="space-between" alignItems="center">
              <Grid item xs="auto">
                <Typography className={classes.title}>លេខទំនាក់ទំនង</Typography>
                <Typography className={classes.text}>
                  <b>
                    Cellcard : 089 313 500 <br />
                    Smart &nbsp;&nbsp;&nbsp;: 086 313 500 <br />
                    Metfone &nbsp;: 060 313 500
                  </b>
                </Typography>
              </Grid>
              <Grid item xs="auto">
                <Grid container alignItems="center" direction="column">
                  <QRCode size={60} value="https://t.me/babycoai" />
                  <Typography className={classes.title}>
                    <b>Telegram</b>
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
          </Grid> */}
          <Grid item>
            <Grid container item>
              <Avatar
                variant='square'
                src={me?.warehouse?.logo?.thumbnail || logo}
                className={classes.logo}
              />
              <Typography>
                <b>Shipping Info</b>
              </Typography>
            </Grid>
          </Grid>
          <Grid item>
            <Divider style={{ backgroundColor: 'black' }} />
          </Grid>
          <Grid item style={{ margin: '3px 0' }}>
            <Grid
              container
              spacing={1}
              alignItems='center'
              justify='space-between'
            >
              <Grid item xs>
                <Grid container direction='column'>
                  {/* <Grid item>
                    <Typography className={classes.title}>
                      ព័ត៌មានដឹកជញ្ជូន/Shipping Info
                    </Typography>
                  </Grid> */}
                  <Grid item>
                    <Typography className={classes.text}>
                      <b>
                        {/* <span className={classes.smaller}>
                          អ្នកទទួល/Receiver
                        </span>{' '} */}
                        {data?.shippingInfo?.receiver || t('n/a')}
                      </b>
                    </Typography>
                  </Grid>
                  <Grid item>
                    <Typography className={classes.text}>
                      <b>
                        {/* <span className={classes.smaller}>
                          លេខទូរសព្ទ/Phone Number
                        </span>{' '} */}
                        {data?.shippingInfo?.phone || t('n/a')}
                      </b>
                    </Typography>
                  </Grid>
                  <Grid item>
                    <Grid container>
                      <Grid item xs>
                        <Typography className={`${classes.text} line-clamp`}>
                          <b>
                            {/* <span className={classes.smaller}>
                              អាសយដ្ឋាន/Address
                            </span>{' '} */}
                            {/* :{' '} */}
                            {buildAddress(data?.shippingInfo?.location) ||
                              t('n/a')}
                          </b>
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs='auto'>
                <Grid container direction='column' alignItems='flex-end'>
                  <QRCode size={40} value={data?._id || '123'} />

                  {/* <QRCode
                    size={60}
                    renderAs='svg'
                    value={
                      data?.delivery?.id
                        ? `${data.delivery.id}|${data._id}`
                        : buildGoogleMapLink(data?.shippingInfo?.location)
                    }
                  />
                  <Typography className={classes.title} align='center'>
                    <b>
                      {data?.delivery?.provider
                        ? t(data?.delivery?.provider)
                        : 'Scan for map'}
                    </b>
                  </Typography> */}
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Grid item>
            <Divider style={{ backgroundColor: 'black' }} />
          </Grid>
          <Grid item container>
            <Grid item xs='auto'>
              <Typography
                style={{
                  marginTop: '5px',
                  fontSize: '10px',
                  textAlign: 'left',
                }}
              >
                ប្រាក់ដែលត្រូវបង់
                <br />
                Amount Due
              </Typography>
              {/* <Typography className={classes.title}>
                <b>{data.paymentMethod && t(data.paymentMethod)}</b>
              </Typography> */}
            </Grid>
            <Grid item xs>
              {data?.amountDue === 0 ? (
                <Typography style={{ fontSize: '18px' }} align='right'>
                  Paid
                </Typography>
              ) : (
                <>
                  <Typography style={{ fontSize: '18px' }} align='right'>
                    <NumberFormatCustom
                      value={data?.amountDue}
                    ></NumberFormatCustom>
                  </Typography>
                </>
              )}
            </Grid>
          </Grid>
          {/* <Grid item container alignItems='flex-end' justify='center'>
            <Grid item xs={12} container justify='space-between'>
              <Grid item xs={2}>
                <QRCode size={40} value={data?._id || '123'} />
              </Grid>
              <Grid
                item
                xs={8}
                container
                alignItems='flex-end'
                justify='flex-start'
              >
                <Typography style={{ fontSize: '8px' }}>{data?._id}</Typography>
              </Grid>
            </Grid>
          </Grid> */}
        </Grid>
      </Grid>
    </Grid>
  );
});

export default ShippingLabel;
