import React, { useCallback, useContext, useState } from 'react';
import TitleBar from '../../components/title-bar';
import { Grid } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import SelectPopUp from '../../components/select-popup';
import config from '../../components/tableX/config';
import Status from '../../components/status';
import DateFormat from '../../components/date-format';
import NumberFormat from '../../components/number-format';
import PurchaseInDetailPopup from './detail-popup';
import { useTabFilter } from '../../components/tab-filter';
import TableTabFilter from '../../components/table-with-filter';
import ActionButton from '../../components/action-button';
import allowStatus from './status';
import CloseIcon from '@material-ui/icons/Close';
import { UtilityContext } from '../../components/context-provider/utilty-context';
import { UserContext } from '../../components/context-provider/user-context';
import axios from 'axios';

const filterStatus = ['all', 'pending', 'canceled', 'completed'];

const PurchaseInPage = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const [triggerFetch, setTriggerFetch] = useState(false);
  const [purchaseDetail, setPurchaseDetail] = useState({
    open: false,
    data: {},
  });
  const { showDeletePrompt, showSnackbar, closeDeletePrompt } =
    useContext(UtilityContext);
  const { checkAccess } = useContext(UserContext);

  const handleCancel = (data) => {
    showDeletePrompt({
      handleConfirm: () => onCancel(data._id),
      icon: CloseIcon,
    });
  };

  const onCancel = (id) => {
    axios
      .put(`${process.env.REACT_APP_API}/api/v1/purchase-in/cancel/${id}`)
      .then(({ data }) => {
        showSnackbar({ message: data?.message });
        setTriggerFetch((prev) => !prev);
      })
      .catch(({ response }) => {
        showSnackbar({ message: response.data?.message, variant: 'error' });
      })
      .finally(() => closeDeletePrompt());
  };

  const handleAssignStorage = (data) => {
    history.push(`/dashboard/purchase-in/confirm/${data._id}`);
  };

  const handleEdit = (data, e) => {
    const location = `/dashboard/purchase-in/edit/${data._id}`;
    if (e?.ctrlKey) {
      window.open(location, '_blank');
    } else {
      history.push(location);
    }
  };

  const handleRowClick = (data) => {
    setPurchaseDetail({ open: true, data });
  };

  const handleCloseDetail = () => {
    setPurchaseDetail({ open: false, data: {} });
  };

  const tableSchema = [
    {
      id: 'purchaseNumber',
      label: t('purchase-order-number'),
      type: 'action',
      onClick: handleRowClick,
    },
    {
      id: 'vendor.name',
      label: t('vendor'),
      dataConfig: ['vendor', 'name'],
    },
    {
      id: 'amount',
      label: t('amount'),
      type: 'component',
      components: [
        {
          name: NumberFormat,
          customValueProp: 'value',
        },
      ],
    },
    {
      id: 'createBy',
      label: t('created-by'),
      dataConfig: ['createdBy', 'name'],
    },
    {
      id: 'createdAt',
      label: t('created-date'),
      type: 'component',
      components: [
        {
          name: DateFormat,
          customValueProp: 'value',
        },
      ],
    },
    {
      id: 'status',
      label: t('status'),
      type: 'component',
      components: [
        {
          name: Status,
          customValueProp: 'status',
        },
      ],
    },
    {
      id: 'action',
      sort: false,
      label: t('action'),
      type: 'component',
      dataConfig: [],
      components: (data) => {
        const currentStatus = data.currentStatus?.name;
        const actions = [];

        if (allowStatus.edit.includes(currentStatus)) {
          actions.push({
            variant: 'edit',
            hidden: !checkAccess({ feature: 'purchase-in', action: 'update' }),
            onClick: () => handleEdit(data),
            title: t('edit'),
          });

          actions.push({
            variant: 'confirm',
            hidden: !checkAccess({ feature: 'purchase-in', action: 'update' }),
            onClick: () => handleAssignStorage(data),
            title: t('confirm'),
          });
        }

        allowStatus.cancel.includes(currentStatus) &&
          actions.push({
            variant: 'close',
            hidden: !checkAccess({ feature: 'purchase-in', action: 'cancel' }),
            onClick: (e) => {
              e.stopPropagation();
              handleCancel(data);
            },
            title: t('cancel'),
          });

        return actions.length > 0 ? (
          actions.map(
            (data, index) =>
              data && <ActionButton key={index} {...data}></ActionButton>
          )
        ) : (
          <>-</>
        );
      },
    },
  ];

  const { value, changeFilter, createDefaultFilter } = useTabFilter(0);

  const defaultFilter = useCallback(() => {
    return createDefaultFilter({
      value,
      options: filterStatus,
      filter: 'currentStatus.name',
    });
  }, [value]);

  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <TitleBar disableButton></TitleBar>
      </Grid>
      <Grid item xs={12}>
        <TableTabFilter
          tabFilterProps={{
            options: filterStatus,
            value,
            onChange: changeFilter,
          }}
          tableXProps={{
            triggerFetch,
            onRowClick: handleRowClick,
            defaultFilter,
            render: (data) => {
              return <SelectPopUp open={data.length} select={data} noButton />;
            },
            config: tableConfig,
            schema: tableSchema,
          }}
        />
      </Grid>
      <PurchaseInDetailPopup
        state={purchaseDetail}
        onClose={handleCloseDetail}
        onAssignStorage={handleAssignStorage}
      />
    </Grid>
  );
};

const tableConfig = {
  ...config,
  url: `${process.env.REACT_APP_API}/api/v1/purchase-in/list`,
  dataConfig: ['purchaseIn'],
};

export default PurchaseInPage;
