import React, { useContext } from 'react';
import { TableRow, TableCell, TableBody } from '@material-ui/core';
import { useFieldArray, useFormContext } from 'react-hook-form';
import CustomTable from '../../../../components/custom-table';
import { RHFNumberInput } from '../../../../components/rhf-controlled-input';
import CustomRemoveIconButton from '../../../../components/custom-button/remove-icon-button';
import { UtilityContext } from '../../../../components/context-provider/utilty-context';

const tableHeadData = [
  { value: 'no.', width: '5px' },
  { value: 'image', width: '120px' },
  { value: 'product-name' },
  { value: 'received', width: '20%' },
  { width: '50px' },
];

const Products = ({ maxQty }) => {
  // form context
  const { control } = useFormContext();

  const { showDeletePrompt, closeDeletePrompt } = useContext(UtilityContext);

  // filedArray orderItems
  const { fields, remove } = useFieldArray({
    control,
    name: 'product',
  });

  // remove row item prompt
  const handleRemove = (index) => {
    showDeletePrompt({
      handleConfirm: () => onDelete(index),
    });
  };

  // on remove confirm
  const onDelete = (index) => {
    remove(index);
    closeDeletePrompt();
  };

  return (
    <CustomTable.Container>
      {/* table header */}
      <CustomTable.Head data={tableHeadData} />
      {/* table body */}
      <TableBody>
        {fields.map((field, index) => {
          // get value from field
          const get = getValue(field);

          return (
            <TableRow key={field.id}>
              <TableCell>
                <CustomTable.CellValue>{index + 1}</CustomTable.CellValue>
              </TableCell>
              <TableCell>
                <CustomTable.Image src={get.image} />
              </TableCell>
              <TableCell>
                <CustomTable.CellValue>{get.variantName}</CustomTable.CellValue>
              </TableCell>
              <TableCell>
                <RHFNumberInput
                  textAlign='center'
                  name={`product.${index}.receivedQty`}
                  defaultValue={get.receivedQty}
                  min={1}
                  max={maxQty[index]}
                  required
                />
              </TableCell>
              <TableCell>
                <CustomTable.CellValue component='div'>
                  <CustomRemoveIconButton
                    invisible={fields.length === 1}
                    onClick={() => handleRemove(index)}
                  />
                </CustomTable.CellValue>
              </TableCell>
            </TableRow>
          );
        })}
      </TableBody>
    </CustomTable.Container>
  );
};

// function to get product value
export const getValue = (data) => {
  const variantName = data.variant?.name ?? '';
  const image = data.variant?.image?.thumbnail;

  const receivedQty = data.receivedQty ?? 0;

  return {
    variantName,
    image,
    receivedQty,
  };
};

export default Products;
