import { Grid } from '@material-ui/core';
import React, { useRef, useState, useEffect, useContext } from 'react';
import DialogContainer from '../../../components/dialog-container';
import TextInput from '../../../components/text-input';
import { useTranslation } from 'react-i18next';
import { useForm, Controller } from 'react-hook-form';
import axios from 'axios';
import { FiUpload } from 'react-icons/fi';
import TextInputWithIconButton from '../../../components/text-input-with-icon-button';
import { UtilityContext } from '../../../components/context-provider/utilty-context';
import registerMui from '../../../util/react-hook-form-helper/register-mui';
import { formHandler } from '../../../util/react-hook-form-helper';
import generateKey from '../../../util/generate-keys';
import { UserContext } from '../../../components/context-provider/user-context';
import compressImage, { thumbnailOption } from '../../../util/image-compress';
import CategoryListSearch from './category-search-input';
import CategoryLabel from '../../../components/category-label';

const CategoryForm = ({
  open,
  onClose,
  preData,
  newData,
  setReturnData,
  fetchChildren,
  fetchList,
  handleReset,
}) => {
  const { t } = useTranslation();
  const fileRef = useRef();
  const { showSnackbar } = useContext(UtilityContext);
  const [state, setState] = useState('add');
  const [selectedIcon, setSelectedIcon] = useState(null);
  const { s3BaseURL } = useContext(UserContext);

  // react hook form state
  const { register, handleSubmit, control, reset } = useForm({
    shouldUnregister: true,
  });

  useEffect(() => {
    if (open) {
      setSelectedIcon({});
      if (preData) {
        if (preData.state) {
          setState(preData.state);
        } else {
          setState('update');
          if (preData?.icon?.key) {
            const iconName = preData.icon.key.split('/');
            setSelectedIcon({ name: iconName[iconName.length - 1] });
          }
        }
        reset(preData);
      } else {
        setState('add');
        if (newData) {
          reset(newData);
        } else {
          setSelectedIcon(null);
          reset({});
        }
      }
    }
  }, [preData, newData, open]);

  const onSubmit = async (formData) => {
    const { parentCategory, ...data } = formData;
    const prepareData = {
      ...data,
      parentId: parentCategory?._id || null,
    };

    let uploadObject = {
      ContentTypes: [],
      Keys: [],
      Images: [],
    };

    // upload icons
    if (selectedIcon?.type) {
      const fileType = selectedIcon.type;
      const fileExtension = selectedIcon.type.split('/')[1];
      // generate key
      uploadObject.ContentTypes.push(fileType);
      const Key = `public/category-icons/${generateKey()}`;
      const imageKey = `${Key}.${fileExtension}`;

      uploadObject.Keys.push(imageKey);
      const image = await compressImage(selectedIcon, false, thumbnailOption);
      uploadObject.Images.push(image);

      prepareData.icon = {
        key: `${s3BaseURL}/${imageKey}`,
        mimeType: fileType,
      };

      // get s3 upload url
      const response = await axios.post(
        `${process.env.REACT_APP_API}/api/v1/aws-s3/file-upload-url`,
        {
          ContentTypes: uploadObject.ContentTypes,
          Keys: uploadObject.Keys,
        }
      );

      const [data] = response.data;
      // upload file to s3 url
      await axios.put(data.uploadURL, uploadObject.Images[0], {
        headers: {
          'Content-Type': data.type,
          'Content-Encoding': 'base64',
        },
        withCredentials: false,
      });

      if (preData?.icon?.key) {
        const iconName = preData.icon.key.split('.com/');
        try {
          await axios.post(
            `${process.env.REACT_APP_API}/api/v1/aws-s3/remove-file`,
            {
              Keys: [{ Key: iconName[1] }],
            }
          );
        } catch (err) {
          console.log(err);
        }
      }
    }

    switch (state) {
      case 'add':
        axios
          .post(`${process.env.REACT_APP_API}/api/v1/categories`, prepareData)
          .then(({ data }) => {
            showSnackbar({ message: data?.message });
            if (newData) {
              setReturnData(data?.category);
            } else if (preData) {
              if (parentCategory?.hasChildren) {
                fetchChildren(prepareData.parentId);
              } else if (
                !parentCategory?.hasChildren &&
                parentCategory?.parentId
              ) {
                fetchChildren(parentCategory.topParent);
              } else {
                fetchList();
              }
            } else {
              handleReset();
              fetchList();
            }
            onClose();
          })
          .catch(({ response }) => {
            showSnackbar({
              message: response?.data?.message,
              variant: 'error',
            });
          });
        break;
      case 'update': {
        const updateData = { ...prepareData };
        if (parentCategory?.ancestors) {
          updateData.newParentId = parentCategory?._id;
        }

        axios
          .put(
            `${process.env.REACT_APP_API}/api/v1/categories/${preData?._id}`,
            updateData
          )
          .then(({ data }) => {
            showSnackbar({ message: data?.message });
            if (newData) {
              setReturnData(data?.category);
            } else {
              // fetch category list
              if (parentCategory?.ancestors) {
                handleReset();
                fetchList();
              } else if (preData.parentId) {
                fetchChildren(preData.parentId);
              } else {
                fetchList();
              }
            }
            onClose();
          })
          .catch(({ response }) => {
            showSnackbar({
              message: response?.data?.message,
              variant: 'error',
            });
          });

        break;
      }
      default:
        console.log('error state');
    }
  };

  const handleChooseIcon = () => {
    fileRef.current.click();
  };

  const fileChangeHandler = (e) => {
    setSelectedIcon(e.target.files[0]);
  };

  return (
    <DialogContainer
      open={open}
      onClose={onClose}
      state={state}
      title={t('category-form-title')}
      onCancel={onClose}
      formId={'category-form'}
    >
      <form
        id='category-form'
        onSubmit={(e) => formHandler(e, handleSubmit(onSubmit))}
      >
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <TextInput
              label={t('category-name')}
              required
              autoFocus
              {...registerMui(register('name'))}
            />
          </Grid>
          <Grid item xs={12}>
            <TextInputWithIconButton
              onClick={handleChooseIcon}
              icon={FiUpload}
              value={selectedIcon?.name}
              label={t('icon')}
              name='icon'
              InputProps={{ readOnly: true }}
            ></TextInputWithIconButton>
          </Grid>
          <Grid item xs={12}>
            <Controller
              control={control}
              name='parentCategory'
              defaultValue={null}
              render={({ field: { value, onChange } }) => {
                return (
                  <CategoryListSearch
                    disableClearable={false}
                    value={value || null}
                    filterData={preData}
                    disabled={preData?.state === 'add'}
                    onChange={(e, data) => onChange(data)}
                    dataConfig={['categories']}
                    optionsURL={`${process.env.REACT_APP_API}/api/v1/categories`}
                    label={t('parent-category')}
                    getOptionLabel={(option) => option?.name || ''}
                    getOptionSelected={() => true}
                    renderOption={(option) => (
                      <CategoryLabel
                        name={option?.name}
                        parent={option?.ancestors}
                      />
                    )}
                  />
                );
              }}
            ></Controller>
          </Grid>
        </Grid>
        <input
          accept='image/png,image/jpeg'
          className='hidden'
          onChange={fileChangeHandler}
          type='file'
          ref={fileRef}
        />
      </form>
    </DialogContainer>
  );
};

export default CategoryForm;
