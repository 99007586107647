import CustomBaseIconButton from '../base-icon-button';
import { RiDeleteBack2Fill } from 'react-icons/ri';
import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  root: {
    color: theme.palette.secondary.main,
  },
}));

const CustomRemoveIconButton = (props) => {
  const classes = useStyles();
  const {
    icon: Icon = RiDeleteBack2Fill,
    iconColor = 'secondary',
    className,
    ...iconButtonProps
  } = props;
  return (
    <CustomBaseIconButton {...iconButtonProps}>
      <Icon color={iconColor} className={`${classes.root} ${className}`} />
    </CustomBaseIconButton>
  );
};

export default CustomRemoveIconButton;
