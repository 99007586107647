import { FormControl, FormLabel, makeStyles, Box } from '@material-ui/core';
import clsx from 'clsx';
import React from 'react';

const useLabelWrapperStyle = makeStyles((theme) => ({
  label: {
    color: 'black',
    marginBottom: '10px',
    fontSize: '14px',
    [theme.breakpoints.down('xs')]: {
      fontSize: '12px',
    },
  },
  bold: {
    fontWeight: 'bold',
  },
}));

// Red Required Star *
const RequiredStar = () => (
  <Box color='error.main' component='span'>
    <b>{' *'}</b>
  </Box>
);

// Label Wrapper for input (show label above input)
const LabelWrapper = (props) => {
  const { label, bold = true, showRequiredStar, children } = props;
  const labelWrapperClasses = useLabelWrapperStyle();

  return (
    <FormControl fullWidth>
      <FormLabel
        className={clsx(
          labelWrapperClasses.label,
          bold && labelWrapperClasses.bold
        )}
      >
        {label}
        {showRequiredStar && <RequiredStar />}
      </FormLabel>
      {children}
    </FormControl>
  );
};

export default LabelWrapper;
