import React from 'react';
import { Redirect, Route } from 'react-router-dom';
import Cookies from 'js-cookie';

const PrivateRoute = ({ children, ...rest }) => {
  // store url in callback query
  const { pathname, search } = window.location;
  const callback = encodeURIComponent(`${pathname}${search}`);
  return (
    <Route {...rest}>
      {Cookies.get('user') === 'true' ? (
        children
      ) : (
        <Redirect to={`/login?callback=${callback}`} />
      )}
    </Route>
  );
};

export default PrivateRoute;
