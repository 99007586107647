import React from 'react';
import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(() => ({
  profileWrapper: {
    margin: '1rem auto',
  },
  profile: {
    height: 200,
    width: 200,
    borderRadius: 100,
    border: 'solid black 1px',
  },
}));

const ProfilePreview = ({ previewProfile }) => {
  const classes = useStyles();

  return (
    <div className={classes.profileWrapper}>
      <div
        className={classes.profile}
        style={{
          background: `url(${previewProfile}) no-repeat center center/cover`,
        }}
      />
    </div>
  );
};

export default ProfilePreview;
