import { makeStyles } from '@material-ui/core';

// custom style table

const useStyles = makeStyles((theme) => ({
  root: {
    '& .tableX th': {
      fontWeight: 'bold',
      textWrap: 'no-wrap',
      textAlign: 'center',
      textTransform: 'uppercase',
      '& span': {
        whiteSpace: 'nowrap',
      },
    },
    '& .tableX td': {
      border: 0,
      textAlign: 'center',
      cursor: 'pointer',
      // overflow: 'hidden',
      // whiteSpace: 'nowrap',
    },
    '& .tableX tr:nth-child(even)': {
      '& td': {
        background: theme.palette.primary.lighter,
      },
    },
    '& .tableX table': {
      marginTop: '40px',
    },
    // action type cell
    '& .tableX-action-cell': {
      textDecoration: 'underline',
      color: 'blue',
      cursor: 'pointer',
    },
  },
}));

export default useStyles;
