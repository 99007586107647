const filterStatus = [
  'all',
  'pending',
  'approved',
  'packed',
  'repack',
  'shipping',
  'delivered',
  'rejected',
  'delivery-failed',
  'partially-returned',
  'returned',
  'failed',
];

export default filterStatus;
