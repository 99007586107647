import React, { useEffect, useState, useContext } from 'react';
import {
  makeStyles,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Grid,
  InputBase,
  Button,
  IconButton,
  Avatar,
  Box,
  Typography,
} from '@material-ui/core';
import EditIcon from '@material-ui/icons/Edit';
import { Controller, useFieldArray, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import RemoveCircleIcon from '@material-ui/icons/RemoveCircle';
import PhotoIcon from '@material-ui/icons/Photo';
import readObjectWithArrayKeys from '../../../../../../util/read-object-value-with-array-keys';
import mergeArrayToObject from '../../../../../../util/merge-arrays-to-object';
import axios from 'axios';
import SKUInput from '../../../../../../components/sku-input';
import EditOptionForm from './edit-option-form';
import { UtilityContext } from '../../../../../../components/context-provider/utilty-context';
import ImageUploadPopup from './image-upload-popup';
import PrintBarcode from '../../barcode-print';
import PrintIcon from '@material-ui/icons/Print';
import registerMui from '../../../../../../util/react-hook-form-helper/register-mui';
import getPriceBeforeTax from '../../../../../../util/getPriceBeforeTax';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import AddVariantForm from './add-variant-form';

const useStyles = makeStyles((theme) => ({
  contactForm: {
    '& td,th': {
      border: 0,
      textAlign: 'center',
    },
    '& th': {
      textTransform: 'uppercase',
      verticalAlign: 'top',
      fontWeight: 'bold',
      padding: '4px 10px',
      borderBottom: '1px solid rgb(112,112,112)',
      lineHeight: '14px',
    },
    '& tr:nth-child(1)': {
      '& td': {
        paddingTop: '20px',
      },
    },
    '& tr:nth-child(even)': {
      '& td': {
        background: theme.palette.primary.ligther,
      },
    },
    '& td': {
      padding: '10px',
    },
    '&  div:not(.action)': {
      background: 'white',
      border: '1px solid rgba(170,170,170,0.6)',
      padding: '2px 6px',
      borderRadius: '5px',
    },
    '& div:first-child': {
      padding: '0',
    },
    '& .MuiInputBase-root': {
      padding: '3px 10px !important',
      margin: '10px 0',
    },
    '& svg': {
      fontSize: '16px',
    },
    '& button': {
      marginRight: '5px',
    },
  },
  hidden: {
    visibility: 'hidden',
  },
  buttonContainer: {
    marginTop: '10px',
    textAlign: 'left',
  },
  button: {
    textTransform: 'uppercase',
    fontSize: '12px',
    fontWeight: 'bold',
    color: 'black',
    '& svg': {
      fontSize: '18px',
      color: theme.palette.primary.main,
    },
  },
  image: {
    cursor: 'pointer',
    width: '40px',
    '& .MuiAvatar-img': {
      objectFit: 'contain !important',
    },
    backgroundColor: `${theme.palette.primary.ligther} !important`,
    '& svg': {
      color: theme.palette.primary.main,
      fontSize: '30px',
    },
  },
  buttonHeader: {
    fontWeight: 'bold',
    padding: '3px',
    textTransform: 'uppercase',
    fontSize: '14px',
  },
}));

const cartesian = (array) =>
  array.reduce((accumulator, current) => {
    return accumulator.flatMap((a) => current.map((b) => `${a}-${b}`));
  });

const Variants = ({ state, triggerGenerate, setRemoveVariantImage }) => {
  const { showDeletePrompt, closeDeletePrompt, showSnackbar } =
    useContext(UtilityContext);
  const { watch, control, register, getValues, setValue } = useFormContext();
  //edit option form
  const [form, setForm] = useState(false);
  // add variant form
  const [variantForm, setVariantForm] = useState(false);
  const [activeImageUpload, setActiveImageUpload] = useState(null);
  const [activeField, setActiveField] = useState(null);
  const watchVariants = watch('variants');
  const classes = useStyles();
  const { t } = useTranslation();
  const { fields, append } = useFieldArray({
    control,
    name: 'variants',
  });
  const [printBarcode, setPrintBarcode] = useState(false);

  const handleOpenPrintBarcode = (index) => {
    setActiveField(index);
    setPrintBarcode(true);
  };

  const handleClosePrintBarcode = () => setPrintBarcode(false);

  useEffect(() => {
    const values = getValues();
    if (state === 'create') {
      const arrayOptions =
        values.attributes?.map((attribute) => {
          return attribute.options || [''];
        }) || [];

      const attributeNames = values.attributes?.map((attribute) => {
        return attribute.name;
      });

      // generate name
      if (Array.isArray(arrayOptions) && arrayOptions.length > 0) {
        const variants = cartesian(arrayOptions)?.map((item) => {
          const variant = {
            name: `${values.name} / ${item}`,
            attr: mergeArrayToObject(attributeNames, item.split('-')),
            isDeleted: false,
          };
          return variant;
        });

        setValue('variants', variants);
      }
    }
  }, [triggerGenerate]);

  const generateSKU = () => {
    const values = getValues();

    if (!(values.vendor && values.name)) {
      return showSnackbar({
        message: 'product-sku-data-insufficient',
        variant: 'error',
      });
    }

    const arrayOptions =
      values.attributes?.map((attribute) => {
        return attribute.options || [''];
      }) || [];

    const attributeNames = values.attributes?.map((attribute) => {
      return attribute.name;
    });

    if (Array.isArray(arrayOptions) && arrayOptions.length > 0) {
      const options = cartesian(arrayOptions)?.map((item) => {
        const variant = {
          attr: mergeArrayToObject(attributeNames, item.split('-')),
        };
        return variant;
      });

      // generate sku
      axios
        .post(`${process.env.REACT_APP_API}/api/v1/sku/generate`, {
          vendor: values.vendor?.name,
          variants: options,
          name: values.name,
        })
        .then(({ data }) => {
          if (data) {
            data.skus?.forEach((sku, index) => {
              setValue(`variants.${index}.sku`, sku);
            });
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  const handleCopyToAll = (field) => {
    const values = getValues();
    const variants = values.variants;
    if (variants?.length > 0) {
      const copyValue = readObjectWithArrayKeys(variants[0], field.split('.'));
      if (copyValue > 0 || copyValue == 0) {
        variants.forEach((product, index) => {
          setValue(`variants.${index}.${field}`, copyValue);
        });
      } else {
        showSnackbar({
          message: 'fill-in-first-field',
          variant: 'error',
        });
      }
    }
  };

  const handleEdit = (index) => {
    setForm(true);
    setActiveField(index);
  };

  const handleRemove = (index, id) => {
    showDeletePrompt({
      handleConfirm: () => onDelete(index, id),
    });
  };

  const onDelete = async (index, id) => {
    let values = getValues();
    let updateVariants = [];
    if (state === 'edit' && id) {
      try {
        const { data } = await axios.get(
          `${process.env.REACT_APP_API}/api/v1/stocks?filter=_id::${id},qty_gt::0`
        );
        if (data.totalDocs === 1) {
          showSnackbar({
            message: 'variant-exist-in-stock',
            variant: 'error',
          });
        } else {
          updateVariants = values.variants.map((variant) => {
            if (variant._id === id) {
              return { ...variant, isDeleted: true };
            }
            return variant;
          });

          setValue('variants', updateVariants);
        }
      } catch (err) {
        console.error(err);
      }
    } else {
      updateVariants = values.variants.filter((variant, i) => {
        return i !== index;
      });
      setValue('variants', updateVariants);
    }

    // keep only use options
    const newOptions = values.attributes.map((attribute) => {
      return Array.from(
        new Set(
          updateVariants.reduce((acc, curr) => {
            if (!curr.isDeleted) {
              acc.push(curr.attr[attribute.name]);
            }
            return acc;
          }, [])
        )
      );
    });

    newOptions.forEach((option, i) => {
      setValue(`attributes.${i}.options`, option);
    });

    closeDeletePrompt();
  };

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Table className={classes.contactForm}>
          <TableHead>
            <TableRow>
              <TableCell>{t('image')}</TableCell>
              <TableCell className='required'>{t('item-name')}</TableCell>

              <TableCell>
                <span> {t('cost-price')}</span> <br />
                <Button
                  color='primary'
                  disabled={fields.length === 0}
                  className={classes.buttonHeader}
                  onClick={() => handleCopyToAll('price.costPrice')}
                >
                  {t('copy-to-all')}
                </Button>
              </TableCell>
              <TableCell>
                <span className='required'> {t('selling-price')}</span> <br />
                <Button
                  disabled={fields.length === 0}
                  color='primary'
                  className={classes.buttonHeader}
                  onClick={() => handleCopyToAll('price.salePrice')}
                >
                  {t('copy-to-all')}
                </Button>
              </TableCell>
              <TableCell>
                <span>{t('sku')}</span> <br />
                <Button
                  color='primary'
                  className={classes.buttonHeader}
                  onClick={generateSKU}
                  disabled={fields.length === 0}
                >
                  {t('generate-sku')}
                </Button>
              </TableCell>
              <TableCell>{t('upc')}</TableCell>
              <TableCell>
                <span> {t('low-stock')}</span> <br />
                <Button
                  disabled={fields.length === 0}
                  color='primary'
                  className={classes.buttonHeader}
                  onClick={() => handleCopyToAll('lowStock')}
                >
                  {t('copy-to-all')}
                </Button>
              </TableCell>
              <TableCell />
            </TableRow>
          </TableHead>
          <TableBody>
            {fields.map((field, index) => {
              let img = watchVariants?.[index]?.images?.[0] ?? null;

              if (img !== null) {
                if (img instanceof File) {
                  img = URL.createObjectURL(img);
                } else {
                  img = img.thumbnail;
                }
              }

              return (
                <TableRow
                  className={
                    'isDeleted' in field && field.isDeleted === true
                      ? 'hidden'
                      : ''
                  }
                  key={field.id}
                >
                  <TableCell padding='none'>
                    <Avatar
                      src={img}
                      onClick={() => {
                        setActiveImageUpload(index);
                      }}
                      className={classes.image}
                      variant='square'
                    >
                      <PhotoIcon />
                    </Avatar>
                    <Controller
                      control={control}
                      name={`variants.${index}.images`}
                      defaultValue={field.images || []}
                      render={({ field: { value, onChange } }) => {
                        return (
                          <ImageUploadPopup
                            open={activeImageUpload === index}
                            handleClose={() => setActiveImageUpload(null)}
                            images={value}
                            setImages={onChange}
                            setRemoveImage={setRemoveVariantImage}
                            variantName={field.name}
                          />
                        );
                      }}
                    ></Controller>
                    {state === 'edit' && !field?.isNew ? (
                      <>
                        <input
                          defaultValue={field._id}
                          className='hidden'
                          {...register(`variants.${index}._id`)}
                        />
                        <input
                          defaultChecked={field.isDeleted}
                          className='hidden'
                          type='checkbox'
                          {...register(`variants.${index}.isDeleted`)}
                        />
                      </>
                    ) : null}
                  </TableCell>
                  <TableCell padding='none'>
                    <InputBase
                      {...registerMui(register(`variants.${index}.name`))}
                      defaultValue={field.name}
                      variant='outlined'
                      required
                      fullWidth
                    />
                    <Controller
                      control={control}
                      name={`variants.${index}.attr`}
                      defaultValue={field.attr}
                      render={() => null}
                    ></Controller>
                  </TableCell>

                  <TableCell padding='none'>
                    <InputBase
                      {...registerMui(
                        register(`variants.${index}.price.costPrice`, {
                          valueAsNumber: true,
                        })
                      )}
                      variant='outlined'
                      placeholder='0'
                      type='number'
                      defaultValue={field.price?.costPrice}
                      inputProps={{ min: 0, step: 0.01 }}
                      fullWidth
                    />
                  </TableCell>
                  <TableCell padding='none' style={{ position: 'relative' }}>
                    <InputBase
                      {...registerMui(
                        register(`variants.${index}.price.salePrice`, {
                          valueAsNumber: true,
                        })
                      )}
                      variant='outlined'
                      type='number'
                      defaultValue={field.price?.salePrice}
                      inputProps={{ min: 0, step: 0.01 }}
                      fullWidth
                      required
                    />
                    <Typography
                      style={{
                        position: 'absolute',
                        bottom: 0,
                        width: '100%',
                        left: 0,
                        fontSize: '10px',
                        paddingLeft: '13px',
                        marginBottom: '1px',
                      }}
                      align='left'
                      variant='caption'
                    >
                      {`${t('before-tax')} : ${getPriceBeforeTax(
                        watchVariants?.[index]?.price?.salePrice || 0
                      )}`}
                    </Typography>
                  </TableCell>
                  <TableCell padding='none'>
                    <SKUInput
                      name={`variants.${index}.sku`}
                      defaultValue={field.sku}
                      variant='outlined'
                      register={register}
                      fullWidth
                    />
                  </TableCell>
                  <TableCell padding='none'>
                    <InputBase
                      {...registerMui(register(`variants.${index}.altCode`))}
                      defaultValue={field.altCode}
                      variant='outlined'
                      fullWidth
                    />
                  </TableCell>
                  <TableCell padding='none'>
                    <InputBase
                      {...registerMui(
                        register(`variants.${index}.lowStock`, {
                          valueAsNumber: true,
                        })
                      )}
                      variant='outlined'
                      type='number'
                      placeholder='0'
                      inputProps={{ min: 0 }}
                      defaultValue={field.lowStock}
                      fullWidth
                    />
                  </TableCell>
                  <TableCell padding='none'>
                    <Box display='flex' className='action'>
                      {state === 'edit' && (
                        <IconButton
                          size='small'
                          onClick={() => handleOpenPrintBarcode(index)}
                        >
                          <PrintIcon color='primary' />
                        </IconButton>
                      )}
                      <IconButton
                        onClick={() => handleEdit(index)}
                        size='small'
                      >
                        <EditIcon color='primary' />
                      </IconButton>
                      <IconButton
                        className={fields.length <= 1 ? 'invisible' : ''}
                        onClick={() => handleRemove(index, field._id)}
                        size='small'
                      >
                        <RemoveCircleIcon color='secondary' />
                      </IconButton>
                    </Box>
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </Grid>
      <Grid item xs={12}>
        <div className={state !== 'edit' ? 'hidden' : ''}>
          <Button
            className={classes.button}
            color='primary'
            size='small'
            onClick={() => setVariantForm(true)}
            disableElevation
          >
            <AddCircleIcon />
            {t('add-variant')}
          </Button>
        </div>
      </Grid>
      <EditOptionForm
        active={activeField}
        open={form}
        onClose={() => setForm(false)}
      />
      <AddVariantForm
        open={variantForm}
        append={append}
        onClose={() => setVariantForm(false)}
      />
      <PrintBarcode
        open={printBarcode}
        onClose={handleClosePrintBarcode}
        active={activeField}
      />
    </Grid>
  );
};

export default Variants;
