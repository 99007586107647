import { Paper } from '@material-ui/core';
import React from 'react';
import TabFilter from '../tab-filter';
import TableX from '../tableX';
import TableXContainer from '../tableX-container';

// TableX + TabFilter
const TableTabFilter = ({ tableXProps, tabFilterProps }) => {
  return (
    <Paper>
      <TabFilter {...tabFilterProps} />
      <TableXContainer>
        <TableX {...tableXProps}></TableX>
      </TableXContainer>
    </Paper>
  );
};

export default TableTabFilter;
