import React from 'react';
import { useTranslation } from 'react-i18next';
import { RHFAutocompleteInput } from 'src/components/rhf-controlled-input';

const VariantInput = ({ handleAddField, defaultValue, rules, name, index }) => {
  const { t } = useTranslation();
  const optionsURL = `${process.env.REACT_APP_API}/api/v1/products?search={search}&page=1&limit=10&filter=`;

  return (
    <RHFAutocompleteInput
      name={name}
      dataConfig='products'
      autoFocus={false}
      filterOptions={(option) => option}
      defaultValue={defaultValue}
      optionsURL={`${optionsURL}`}
      rules={{
        validate: () => {
          return rules;
        },
      }}
      getOptionLabel={variantGetOptionLabel}
      noOptionsText={t('no-product-found')}
      getOptionSelected={(option, value) => {
        return option._id === (value?._id ?? value.id);
      }}
      onChange={(e, data, onChange) => {
        if (typeof data === 'object') {
          onChange(data);
          handleAddField(index);
        }
      }}
    />
  );
};

// for autocomplete variant
const variantGetOptionLabel = (option) => {
  if (typeof option === 'object') {
    return option?.name;
  } else {
    return '';
  }
};
export default VariantInput;
