import { Grid, makeStyles, Typography } from '@material-ui/core';
import clsx from 'clsx';
import moment from 'moment';
import React from 'react';
import { useAuth } from 'src/components/context-provider/user-context';
import InvoiceStoreHeader from 'src/components/invoice-store-header';
import buildAddress from '../../../../util/build-address';
import Products from '../products';

const useStyles = makeStyles(() => ({
  logo: {
    width: '100px',
    height: '50px',
    '& img': {
      objectFit: 'contain',
    },
    position: 'absolute',
    top: 0,
    left: 0,
    marginTop: '20px',
    marginLeft: '10px',
  },
  root: {
    '& *': {
      fontFamily: 'Arial , Siemreap',
    },
    '& #title': {
      padding: '5px',
    },
    padding: '2.2rem 3rem',
    fontFamily: 'san-serif',
    '& p , li , li b': {
      fontSize: '11px',
    },
    '& th.MuiTableCell-root.MuiTableCell-head': {
      fontSize: '10px !important',
      fontWeight: 'bold',
      padding: '0 !important',
    },
  },
  textRight: {
    textAlign: 'right',
  },
  total: {
    '& .title': {
      fontSize: '6px !important',
      marginTop: '5px',
    },
    '& .value': {
      fontSize: '10px !important',
    },
  },
}));

const PurchaseInvoice = React.forwardRef(({ data }, ref) => {
  const classes = useStyles();
  const { me } = useAuth();

  return (
    <Grid container spacing={2} style={{ display: 'none' }}>
      <Grid item ref={ref} style={{ overflow: 'hidden' }}>
        <Grid
          container
          spacing={2}
          direction='column'
          className={clsx(classes.root)}
        >
          <Grid item style={{ position: 'relative' }}>
            <InvoiceStoreHeader warehouse={me.warehouse} />
          </Grid>
          <Grid item>
            <Typography align='center'>
              <span style={{ fontSize: '18px' }}>
                <b> PURCHASE IN</b>
              </span>
            </Typography>
          </Grid>
          <Grid item>
            <Grid container>
              <Grid item xs={6}>
                <Grid container>
                  <Grid item xs={12}>
                    <Typography>
                      អ្នកលក់ / <b>Vendor</b>
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <Grid container alignItems='center'>
                      <Grid item xs>
                        <Typography>
                          ឈ្មោះ / <b>Name</b> : {data?.vendor?.name}
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={12}>
                    <Grid container alignItems='center'>
                      <Grid item xs>
                        <Typography>
                          លេខទូរសព្ទ / <b>Phone</b> : {data?.vendor?.id?.phone}
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={12}>
                    <Grid container alignItems='center'>
                      <Grid item xs>
                        <Typography>
                          អាសយដ្ឋាន / <b>Address</b> :{' '}
                          {buildAddress(data?.vendor?.id)}
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={6}>
                <Grid container alignItems='center'>
                  <Grid item xs>
                    <Typography>លេខរៀង</Typography>
                    <Typography>
                      <b>PURCHASE ORDER NUMBER:</b>
                    </Typography>
                  </Grid>
                  <Grid item xs={7}>
                    <Typography>{data?.purchaseNumber}</Typography>
                  </Grid>
                </Grid>
                <Grid container alignItems='center'>
                  <Grid item xs>
                    <Typography>កាលបរិច្ចេទ</Typography>
                    <Typography>
                      <b>DATE:</b>
                    </Typography>
                  </Grid>
                  <Grid item xs={7}>
                    <Typography>
                      {moment(data?.createdAt).format('DD/MM/YYYY')}
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Grid container justify='flex-end' spacing={3}>
              <Grid item xs={12}>
                <Products data={data} />
              </Grid>
            </Grid>
          </Grid>
          {/* <Grid item>
            <Grid
              container
              style={{ marginTop: "50px" }}
              justify="space-between"
            >
              <Grid item>
                <Box style={{ borderTop: "1px solid black" }}>
                  <Typography>Customer's Authoried Signature</Typography>
                  <Typography>Name:</Typography>
                  <Typography>Position:</Typography>
                </Box>
              </Grid>
              <Grid item>
                <Box style={{ borderTop: "1px solid black" }}>
                  <Typography>The Company's Authoried Signature</Typography>
                  <Typography>Name:</Typography>
                  <Typography>Position:</Typography>
                </Box>
              </Grid>
            </Grid>
          </Grid> */}
          {/* <Grid item xs>
            <Typography>
              <b>Bank Information for Remittance of Payment</b>
            </Typography>
            <Box style={{ borderTop: "1px solid black" }} clone>
              <Grid container>
                <Grid item xs="auto">
                  <ul>
                    <li>
                      Account Name: <b>Khim Sophors</b>
                    </li>
                    <li>
                      Account Number: <b>017 299 599</b>
                    </li>
                    <li>
                      Bank Name: <b>Advance Bank of Asia (ABA)</b>
                    </li>
                  </ul>
                </Grid>
              </Grid>
            </Box>
          </Grid> */}
        </Grid>
      </Grid>
    </Grid>
  );
});

export default PurchaseInvoice;
