import { Typography } from '@material-ui/core';
import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { UtilityContext } from '../context-provider/utilty-context';

const useConfirmationPrompt = () => {
  const { t } = useTranslation();
  const { showPrompt: handleShowPrompt, closePrompt } =
    useContext(UtilityContext);

  const showPrompt = async ({
    handleConfirm,
    title = t('action-confirmation'),
    message = (
      <Typography>
        {t('action-cant-undone')}
        <br /> {t('are-you-sure')}
      </Typography>
    ),
  }) => {
    handleShowPrompt({
      handleConfirm,
      title,
      message,
      disableIcon: true,
      confirmButtonProps: {
        color: 'primary',
        variant: 'contained',
        style: { borderRadius: 0 },
        disableElevation: true,
      },
      confirmButtonText: t('confirm'),
      cancelButtonText: t('cancel'),
    });
  };
  return { showPrompt, closePrompt };
};

export default useConfirmationPrompt;
