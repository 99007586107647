import React, { useState, useMemo } from 'react';
import { TextField, Grid, Avatar, Typography } from '@material-ui/core';
import axios from 'axios';
import { Autocomplete } from '@material-ui/lab';
import debounce from 'debounce';

const CustomerSelector = ({
  label,
  placeholder = '',
  value,
  onChange,
  required,
  inputRef,
  ...other
}) => {
  const [list, setList] = useState([]);
  const [loading, setLoading] = useState(true);

  const fetchData = (searchText = '', options = { cancelToke: undefined }) => {
    const { cancelToken } = options;
    setLoading(true);
    axios
      .get(`${process.env.REACT_APP_API}/api/v1/customers`, {
        params: {
          search: searchText,
          page: 1,
          limite: 5,
          sort: '',
        },
        cancelToken,
      })
      .then((response) => {
        setList(response.data.customers);
      })
      .catch((err) => console.log(err))
      .finally(() => {
        setLoading(false);
      });
  };

  const debounceSearch = useMemo(
    () =>
      debounce((text) => {
        fetchData(text);
      }, 500),
    []
  );

  return (
    <Autocomplete
      options={list}
      autoHighlight
      disableClearable
      onOpen={() => fetchData()}
      {...other}
      value={value}
      onChange={onChange}
      loading={loading}
      onInputChange={(event, text, reason) => {
        if (reason !== 'reset') {
          setLoading(true);
          debounceSearch(text);
        }
      }}
      filterOptions={(option) => option}
      getOptionSelected={(option, value) =>
        option?._id === (value?._id || value?.id)
      }
      getOptionLabel={(option) => option?.fullname ?? option?.name}
      renderInput={(params) => {
        return (
          <TextField
            {...params}
            required={required}
            variant='outlined'
            size='small'
            inputRef={inputRef}
            placeholder={placeholder ? placeholder : label.toUpperCase()}
          />
        );
      }}
      renderOption={(option) => {
        return (
          <Grid container spacing={2} alignItems='center'>
            <Grid item xs='auto'>
              <Avatar />
            </Grid>
            <Grid item container spacing={1} direction='column' xs>
              <Grid item>
                <Typography>
                  <b>{option?.fullname}</b>
                </Typography>
                {option?.email && (
                  <>
                    <Typography variant='caption'>{option.email}</Typography>
                    <br />
                  </>
                )}
                {option?.phone && (
                  <Typography variant='caption'>{option.phone}</Typography>
                )}
              </Grid>
            </Grid>
          </Grid>
        );
      }}
    ></Autocomplete>
  );
};

export default CustomerSelector;
