import React from 'react';
import { Checkbox } from '@material-ui/core';

const CheckboxCustom = (props) => {
  return (
    <Checkbox
      disableRipple
      disableFocusRipple
      size="small"
      disableTouchRipple
      color="primary"
      {...props}
    />
  );
};

export default CheckboxCustom;
