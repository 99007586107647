import {
  Dialog,
  DialogContent,
  DialogTitle,
  makeStyles,
  Grid,
  IconButton,
  Button,
  Typography,
  Box,
} from '@material-ui/core';
import React, { useRef, useState, useEffect, useContext } from 'react';
import CloseIcon from '@material-ui/icons/Close';
import moment from 'moment';
import { useReactToPrint } from 'react-to-print';
import { useTranslation } from 'react-i18next';
import EditIcon from '@material-ui/icons/Edit';
import { useHistory } from 'react-router-dom';
import BackDropSpinner from '../../../components/backdrop-spinner';
import OrderInvoice from './invoice';
import ShippingLabel from './shipping-label';
import ReceivePayment from './receive-payment';
import UpdateStatusButtonList from './update-status-button-list';
import Status from '../../../components/status';
import clsx from 'clsx';
import disableEditStatus from '../disable-edit-status';
import axios from 'axios';
import PaymentHistoryItem from './payment-history-item';
import Products from './products';
import CostLine from './cost-line';
import TotalDisplayAmountDue from '../../../components/total-display-amountdue';
import NoteInvoice from '../../../components/note-invoice';
import { UserContext } from '../../../components/context-provider/user-context';
import SaleBy from './sale-by';
import Customer from './customer';
import OrderDateEditor from 'src/components/order-invoice-date-editor';

const useStyles = makeStyles((theme) => ({
  title: {
    background: '#EEEEEE',
    '& h2': {
      display: 'flex',
      justifyContent: 'space-between',
      [theme.breakpoints.down('xs')]: {
        flexDirection: 'column',
      },
      alignItems: 'center',
      fontWeight: 'normal',
    },
  },
  pBold: {
    '& p': {
      fontWeight: 'bold',
    },
  },
  table: {
    fontSize: '14px',
    '& th,td': {
      // border: "1px solid #DBDBDB",
    },
    '& th': {
      fontWeight: 'bold',
    },
  },
  bold: {
    fontWeight: 'bold',
    textAlign: 'right',
  },
  textRight: {
    textAlign: 'right',
  },
  hiddenOnXS: {
    [theme.breakpoints.down('xs')]: {
      display: 'none',
    },
  },
  container: {
    [theme.breakpoints.up('sm')]: {
      justifyContent: 'flex-end',
    },
  },
  dialogContent: {
    height: '90vh',
    overflow: 'overlay',
  },
}));

const OrderDetailPopup = ({ state, onClose, handleRefreshTable }) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const { open } = state;
  const [data, setData] = useState({});
  const [loading, setLoading] = useState(true);
  const [returnable, setReturnable] = useState({ state: false, items: [] });
  const [showHistory, setShowHistory] = useState(false);
  const [triggerFetch, setTriggerFetch] = useState(false);
  const handleRefreshHistory = () => setTriggerFetch((prev) => !prev);
  const { checkAccess } = useContext(UserContext);

  const history = useHistory();

  const invoiceRef = useRef();
  const handlePrintInvoice = useReactToPrint({
    content: () => invoiceRef.current,
  });

  const shippingLableRef = useRef();
  const handlePrintShippingLabel = useReactToPrint({
    content: () => shippingLableRef.current,
  });

  const handleEdit = () => {
    history.push(`/dashboard/sale-invoice/edit/${data?._id}`);
  };

  const handleSelectAll = (e) => {
    if (e.target.checked) {
      setReturnable((prev) => ({
        ...prev,
        items: data?.orderItems.filter(
          (item) =>
            item.isCanceled === false &&
            item.qty > item.returned + item.credited
        ),
      }));
    } else {
      setReturnable((prev) => ({ ...prev, items: [] }));
    }
  };

  const handleSelect = (e, row) => {
    if (e.target.checked) {
      setReturnable((prev) => ({ ...prev, items: [...prev.items, row] }));
    } else {
      const filterSelected = returnable.items.filter(
        (item) => item._id !== row._id
      );
      setReturnable((prev) => ({ ...prev, items: filterSelected }));
    }
  };

  const onEnter = async () => {
    setLoading(true);
    await fetchFormData();
    setLoading(false);
  };

  const fetchFormData = async () => {
    try {
      const {
        data: { order },
      } = await axios.get(
        `${process.env.REACT_APP_API}/api/v1/orders/${state.data?._id}`
      );

      setData(order);
    } catch (err) {
      console.log(err);
    }
  };

  const onExited = () => {
    setData({});
    setShowHistory(false);
  };

  const isSelected = (row) => {
    const found = returnable.items.find((item) => item._id === row._id);
    if (found) {
      return true;
    }
    return false;
  };

  useEffect(() => {
    setReturnable(() => ({
      items: [],
      state: !!['delivered', 'completed', 'partially-returned'].includes(
        data?.currentStatus?.name
      ),
    }));
  }, [data]);

  return (
    <Dialog
      open={open}
      onClose={onClose}
      fullWidth
      maxWidth='md'
      TransitionProps={{
        onEnter,
        onExited,
      }}
    >
      <DialogTitle className={classes.title}>
        <Grid container direction='column'>
          <Grid item container justify='space-between' alignItems='center'>
            <Grid item xs='auto'>
              <Grid container alignItems='center'>
                <Grid item>
                  <Typography>{t('invoice-detail')}</Typography>
                </Grid>
                <Grid item>
                  {!(
                    disableEditStatus.includes(data?.currentStatus?.name) ||
                    disableEditStatus.includes(data?.paymentStatus?.name)
                  ) &&
                    checkAccess({ feature: 'orders', action: 'update' }) && (
                      <IconButton
                        className={clsx(classes.hiddenOnXS, {
                          hidden: !['unpaid'].includes(
                            data?.paymentStatus?.name
                          ),
                        })}
                        color='primary'
                        onClick={handleEdit}
                      >
                        <EditIcon />
                      </IconButton>
                    )}
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs='auto'>
              <IconButton
                className={classes.hiddenOnXS}
                color='secondary'
                onClick={onClose}
              >
                <CloseIcon />
              </IconButton>
            </Grid>
          </Grid>
          <Grid item container justify='space-between'>
            <Grid item xs='auto'>
              <Button
                disableElevation
                className={clsx({ invisible: loading })}
                variant='contained'
                color='primary'
                onClick={handlePrintInvoice}
              >
                {t('print-invoice')}
              </Button>
              <Button
                className={clsx({ invisible: loading })}
                disableElevation
                variant='contained'
                color='primary'
                style={{ margin: '0 5px' }}
                onClick={handlePrintShippingLabel}
              >
                {t('print-shipping-label')}
              </Button>
            </Grid>
            <Grid item xs='auto'>
              <UpdateStatusButtonList
                onSuccess={() => {
                  fetchFormData();
                  if (showHistory) {
                    handleRefreshHistory();
                  }
                  handleRefreshTable();
                }}
                isPOS={data?.pos}
                shippingExpense={data?.shippingExpense}
                isCredited={data?.isCredited}
                orderItems={data.orderItems}
                amountDue={data?.amountDue}
                returnable={returnable}
                status={data?.currentStatus?.name}
                orderId={data?._id}
                order={data}
              />
            </Grid>
          </Grid>
        </Grid>
      </DialogTitle>
      <DialogContent
        classes={{ root: classes.dialogContent }}
        style={{ position: 'relative' }}
      >
        <Grid container spacing={2}>
          <BackDropSpinner backgroundColor='white' open={loading} />
          <Grid item xs={12}>
            <Grid container style={{ padding: '0 15px' }}>
              <Grid item xs={12} sm>
                <Grid container spacing={1}>
                  <Grid item className={classes.pBold}>
                    <p>{t('date')} : </p>
                    <p>{t('customer-name')} : </p>
                    {data?.shippingInfo?.phone && <p>{t('phone')} : </p>}
                    <p>{t('status')} : </p>
                    <p>{t('payment-status')} : </p>
                  </Grid>
                  <Grid item>
                    {/* <Box display='flex' alignItems='center'> */}
                    <p style={{ position: 'relative', width: 'fit-content' }}>
                      {moment(data?.invoiceDate).format('DD/MM/YYYY')}
                      &nbsp;
                      <OrderDateEditor
                        defaultDate={data?.invoiceDate}
                        orderId={data?._id}
                        onSuccess={() => {
                          fetchFormData();
                          if (showHistory) {
                            handleRefreshHistory();
                          }
                          handleRefreshTable();
                        }}
                        render={(openDialog) => {
                          return (
                            <IconButton
                              color='primary'
                              onClick={openDialog}
                              style={{
                                position: 'absolute',
                                right: 0,
                                top: 0,
                                marginRight: '-20px',
                                marginTop: '-4px',
                              }}
                              size='small'
                            >
                              <EditIcon fontSize='inherit' />
                            </IconButton>
                          );
                        }}
                      />
                    </p>
                    {/* </Box> */}
                    <p>{data?.customer?.name}&nbsp;</p>
                    {data?.shippingInfo?.phone && (
                      <div>{data?.shippingInfo?.phone}&nbsp;</div>
                    )}
                    <Box my={1.4}>
                      <Status status={data?.currentStatus?.name} size='small' />
                    </Box>
                    <Status status={data?.paymentStatus?.name} size='small' />
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12} sm>
                <Grid
                  container
                  wrap='nowrap'
                  spacing={1}
                  className={classes.container}
                >
                  <Grid item xs='auto' className={classes.pBold}>
                    <p>{t('main-order-id')} : </p>
                    <p>{t('invoice-number')} : </p>
                    {data?.paymentMethod && <p>{t('payment-methods')} : </p>}
                    <p>{t('created-by')} : </p>{' '}
                    <p
                      className={clsx({
                        hidden: data?.deliveryStatus?.length === 0,
                      })}
                    >
                      {t('delivery-status')} :{' '}
                    </p>
                  </Grid>
                  <Grid item xs={6}>
                    <p>{data?.mainOrderId}&nbsp;</p>
                    <p>{data?.invoiceNumber?.value}&nbsp;</p>
                    {data?.paymentMethod && (
                      <p> {t(data?.paymentMethod)}&nbsp;</p>
                    )}
                    <p>
                      {data.orderedFrom === 'website' && !data?.createdBy?.id
                        ? t('from-website')
                        : data?.createdBy?.name}
                      &nbsp;
                    </p>
                    <Box hidden={data?.deliveryStatus?.length === 0}>
                      <Status status={data?.deliveryStatus} size='small' />
                    </Box>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Grid container spacing={4}>
              <Grid item xs={12}>
                <Products
                  returnable={returnable}
                  isSelected={isSelected}
                  handleSelect={handleSelect}
                  handleSelectAll={handleSelectAll}
                  data={data.orderItems}
                  status={data.currentStatus}
                />
              </Grid>
              {data.costLines?.length > 0 && (
                <Grid item xs={12}>
                  <CostLine data={data.costLines} />
                </Grid>
              )}
            </Grid>
          </Grid>
          <Grid item container justify='space-between' xs={12}>
            <Grid item xs={4} container alignItems='flex-end'>
              <Grid item container direction='column' spacing={2} xs={12}>
                <Grid item>
                  <SaleBy
                    handleRefreshHistory={showHistory && handleRefreshHistory}
                    orderId={data?._id}
                    defaultValue={data?.saleBy}
                  />
                </Grid>
                <Grid item>
                  <Customer
                    refreshForm={fetchFormData}
                    handleRefreshTable={handleRefreshTable}
                    handleRefreshHistory={showHistory && handleRefreshHistory}
                    orderId={data?._id}
                    defaultValue={data?.customer}
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={4}>
              <Box mr={1.5}>
                <TotalDisplayAmountDue
                  tax={data?.totalTax}
                  costing={data?.totalCosting}
                  subtotal={data?.subtotal}
                  total={data?.total}
                  shippingFee={data?.shippingFee}
                  shippingExpense={data?.shippingExpense?.amount}
                  amountDue={data?.amountDue}
                >
                  {data.payments?.length > 0 &&
                    data.payments?.map((payment) => (
                      <PaymentHistoryItem
                        showHistory={showHistory}
                        handleRefreshHistory={handleRefreshHistory}
                        key={payment._id}
                        handleRefreshTable={handleRefreshTable}
                        name='Payment'
                        returnable={returnable}
                        data={payment}
                        refreshForm={fetchFormData}
                      />
                    ))}
                </TotalDisplayAmountDue>
              </Box>
            </Grid>
          </Grid>
          {data?.note && <NoteInvoice note={data?.note} />}
          {checkAccess({ feature: 'orders', action: 'view-payment' }) && (
            <Grid item xs={12}>
              <ReceivePayment
                handleRefreshTable={handleRefreshTable}
                orderInfo={data}
                showHistory={showHistory}
                setShowHistory={setShowHistory}
                triggerFetch={triggerFetch}
                refreshForm={fetchFormData}
                handleRefreshHistory={handleRefreshHistory}
              />
            </Grid>
          )}
        </Grid>
        <OrderInvoice data={data} ref={invoiceRef} />
        <ShippingLabel data={data} ref={shippingLableRef} />
      </DialogContent>
    </Dialog>
  );
};

export default OrderDetailPopup;
