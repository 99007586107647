import { useSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';

// require useSnackbar
// extend useSnackbar for easy use
const useMessage = () => {
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation();

  const showMessage = (message, variant = 'success') =>
    enqueueSnackbar(message, { variant });

  const showSuccessMessage = (message, variant = 'success') =>
    showMessage(message, variant);

  const showErrorMessage = (message, variant = 'error') =>
    showMessage(message, variant);

  const showSuccessResponseMessage = (response, fallback = 'success') => {
    const message = response?.data?.key || response?.data?.message;
    if (message) {
      showSuccessMessage(t(message));
    } else {
      showSuccessMessage(fallback);
    }
  };

  const showErrorResponseMessage = (error, fallback = 'failed') => {
    const message =
      error?.response?.data?.key ||
      error?.response?.data?.message ||
      error?.message;
    if (message) {
      showErrorMessage(t(message));
    } else {
      showErrorMessage(fallback);
    }
  };

  return {
    showMessage,
    showErrorMessage,
    showSuccessMessage,
    showSuccessResponseMessage,
    showErrorResponseMessage,
  };
};

export default useMessage;
