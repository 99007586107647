import {
  TableRow,
  TableCell,
  TableBody,
  Typography,
  Box,
} from '@material-ui/core';
import NumberFormatCustom from '../../../../components/number-format';
import CustomTable from '../../../../components/custom-table';
import calculateDiscount from '../../../../util/calculate-discount';
import plusTax from '../../../../util/plusTax';
import { useTranslation } from 'react-i18next';
import displayProductName from 'src/util/display-product-name';

const tableHeadData = [
  { value: 'no.' },
  { value: 'product-name', width: '30%' },
  { value: 'quantity' },
  { value: 'unit-price' },
  { value: 'tax' },
  // { value: 'discount' },
  // { value: 'expiry-date', width: '15%' },
  { value: 'amount', align: 'right', width: '10%' },
];

const Products = ({ data }) => {
  const { t } = useTranslation();
  return (
    <>
      <Box bgcolor='primary.light' color='white' p={1}>
        <Typography>
          <b>{t('items')}</b>
        </Typography>
      </Box>
      <CustomTable.Container size='small'>
        {/* table header */}
        <CustomTable.Head data={tableHeadData} />
        {/* table body */}
        <TableBody>
          {data?.map((field, index) => {
            // get value from field
            const get = getValue(field);

            return (
              <TableRow key={index}>
                <TableCell>
                  <CustomTable.CellValue>{index + 1}</CustomTable.CellValue>
                </TableCell>
                <TableCell>
                  <CustomTable.CellValue>
                    {displayProductName(get.productCode, get.productName)}
                  </CustomTable.CellValue>
                </TableCell>
                <TableCell>
                  <CustomTable.CellValue>
                    {get.receivedQty}
                  </CustomTable.CellValue>
                </TableCell>
                <TableCell>
                  <CustomTable.CellValue>
                    <NumberFormatCustom value={get.costPrice} />
                  </CustomTable.CellValue>
                </TableCell>
                <TableCell>
                  <CustomTable.CellValue>
                    <NumberFormatCustom value={get.tax} format='percentage' />
                  </CustomTable.CellValue>
                </TableCell>
                {/* <TableCell>
                  <CustomTable.CellValue>
                    <NumberFormatCustom
                      value={get.discount?.amount}
                      format={get.discount?.type}
                    />
                  </CustomTable.CellValue>
                </TableCell>
                <TableCell>
                  <CustomTable.CellValue>
                    <DateFormat value={get.expiryDate} />
                  </CustomTable.CellValue>
                </TableCell> */}
                <TableCell>
                  <CustomTable.CellValue align='right'>
                    <NumberFormatCustom value={get.totalAmount} />
                  </CustomTable.CellValue>
                </TableCell>
              </TableRow>
            );
          })}
        </TableBody>
      </CustomTable.Container>
    </>
  );
};

// function to get product value
export const getValue = (data) => {
  const productName = data?.variant?.name ?? '';
  const productCode = data?.variant?.altCode || data?.variant?.sku;
  const tax = data.tax ?? 0;
  const costPrice = data.costPriceAverage ?? 0;
  const expiryDate = data.expiryDate ?? null;
  const receivedQty = data.receivedQty ?? 1;
  const discount = data.discount ?? { amount: 0, type: 'percentage' };
  const totalAmount = plusTax(
    calculateDiscount(receivedQty * costPrice, discount),
    tax
  );

  return {
    productName,
    productCode,
    // image,
    tax,
    expiryDate,
    costPrice,
    receivedQty,
    totalAmount,
    discount,
  };
};

export default Products;
