import React, { useEffect } from 'react';
import ReactQuill, { Quill } from 'react-quill';

import { makeStyles } from '@material-ui/core';
import 'react-quill/dist/quill.snow.css';
import { Controller } from 'react-hook-form';
import imageCompression from 'browser-image-compression';

// #1 import quill-image-uploader
import ImageUploader from 'quill-image-uploader';

// #2 register module
Quill.register('modules/imageUploader', ImageUploader);

const useStyles = makeStyles(() => ({
  root: {
    '& .ql-editor': {
      minHeight: '200px',
    },
  },
}));

// resolve urlObject not showing
const Image = Quill.import('formats/image');
Image.sanitize = (url) => {
  return url;
};

const RichTextEditor = ({ register, setValue, getValues, control }) => {
  const classes = useStyles();

  useEffect(() => {
    // register
    register('descriptionImage');
  }, [register]);

  const modules = {
    //resolve add extra line
    clipboard: {
      matchVisual: false,
    },
    toolbar: [
      [{ header: [1, 2, 3, 4, 5, 6, false] }],
      ['bold', 'italic', 'underline', 'strike', 'blockquote'],
      [
        { list: 'ordered' },
        { list: 'bullet' },
        { indent: '-1' },
        { indent: '+1' },
      ],
      [{ align: [] }],
      ['link', 'image', 'video'],
    ],
    imageUploader: {
      upload: function (file) {
        // eslint-disable-next-line no-async-promise-executor
        return new Promise(async (resolve, reject) => {
          try {
            // compress image
            const options = {
              maxSizeMB: 5,
              maxWidthOrHeight: 500,
              useWebWorker: true,
            };
            const img = await imageCompression(file, options);
            const temporaryUrl = URL.createObjectURL(img);
            // use hook form
            const { descriptionImage } = getValues();
            setValue('descriptionImage', [
              ...descriptionImage,
              { img, url: temporaryUrl, type: file.type },
            ]);
            resolve(temporaryUrl);
          } catch (err) {
            reject(err);
          }
        });
      },
    },
  };

  return (
    <Controller
      name='description'
      control={control}
      render={({ field }) => {
        return (
          <ReactQuill
            placeholder='description'
            className={classes.root}
            modules={modules}
            {...field}
          />
        );
      }}
    ></Controller>
  );
};

export default RichTextEditor;
