import imageCompression from 'browser-image-compression';

const option = {
  maxSizeMB: 1,
  maxWidthOrHeight: 1200,
  useWebWorker: true,
};

const thumbnailOption = {
  maxSizeMB: 0.2,
  maxWidthOrHeight: 500,
  useWebWorker: true,
};

const compressImage = async (file, thumbnail = true, updateOption = {}) => {
  const image = await imageCompression(file, { ...option, ...updateOption });
  if (!thumbnail) {
    return image;
  } else {
    const thumbnail = await imageCompression(file, {
      ...thumbnailOption,
      ...updateOption,
    });
    return { image, thumbnail };
  }
};
export { thumbnailOption, option };
export default compressImage;
