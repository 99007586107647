import { Typography, makeStyles } from '@material-ui/core';
import clsx from 'clsx';

const useStyles = makeStyles(() => ({
  root: {
    margin: '8.825px 0 8.825px 0',
  },
}));

// cell value for display value non input with input component properly in table
const CustomTableCellValue = ({
  children,
  align = 'center',
  component: Component = Typography,
  disableMargin,
}) => {
  const classes = useStyles();
  return (
    <Component align={align} className={clsx(!disableMargin && classes.root)}>
      {children}
    </Component>
  );
};

export default CustomTableCellValue;
