import { TableBody, TableCell, TableRow, makeStyles } from '@material-ui/core';
import readObjectWithArrayKeys from '../utils/read-object-value-with-array-keys';
import Checkbox from '../checkbox';
import React from 'react';
import clsx from 'clsx';

const useStyles = makeStyles(() => ({
  component: {
    whiteSpace: 'nowrap',
  },
}));

const TableBodyX = ({
  schema,
  dataList,
  handleSelect,
  handleClick,
  selected,
  select,
}) => {
  const classes = useStyles();
  const isSelected = (row) => {
    const found = selected.find(
      (item) => JSON.stringify(item) === JSON.stringify(row)
    );
    if (found) {
      return true;
    }
    return false;
  };
  const showContent = ({ item, keyIndex, rowIndex }) => {
    const currentSchema = item;
    const currentCellData = dataList[rowIndex];

    const currentCellValue = !item.dataConfig
      ? currentCellData[item.id]
      : readObjectWithArrayKeys(currentCellData, item.dataConfig);

    switch (currentSchema.type) {
      case 'component': {
        return (
          <TableCell
            key={keyIndex}
            className={clsx(classes.component, item.className)}
          >
            {typeof currentSchema.components === 'function'
              ? currentSchema.components(currentCellValue)
              : currentSchema.components.map((component, componentIndex) => {
                  const {
                    name: Component,
                    children: Children,
                    acceptValue,
                    customValueProp = 'currentvalue',
                  } = component;
                  return (
                    <Component
                      key={componentIndex}
                      onClick={
                        component.onClick
                          ? (e) => {
                              e.stopPropagation();
                              component.onClick(currentCellData);
                            }
                          : () => {}
                      }
                      {...component?.props}
                      {...{ [customValueProp]: currentCellValue }}
                    >
                      {acceptValue && currentCellValue}
                      {Children && typeof Children !== 'string' ? (
                        <Children></Children>
                      ) : (
                        Children
                      )}
                    </Component>
                  );
                })}
          </TableCell>
        );
      }
      case 'action': {
        return (
          <TableCell key={keyIndex} className={item.className}>
            <span
              onClick={(e) => {
                e.stopPropagation();
                currentSchema.onClick(currentCellData);
              }}
              className='tableX-action-cell'
            >
              {currentCellValue}
            </span>
          </TableCell>
        );
      }
      default:
        return (
          <TableCell className={item.className} key={keyIndex}>
            {currentCellValue}
          </TableCell>
        );
    }
  };

  return (
    <TableBody>
      {dataList.map((row, rowIndex) => {
        const isItemSelected = isSelected(row);
        return (
          <TableRow
            hover
            onClick={
              handleClick
                ? (e) => {
                    e.stopPropagation();
                    handleClick(row, e);
                  }
                : () => {}
            }
            key={rowIndex}
          >
            <TableCell>
              {select && (
                <Checkbox
                  onClick={(e) => handleSelect(e, row)}
                  checked={isItemSelected}
                />
              )}
            </TableCell>
            {schema.map((item, index) =>
              showContent({ item, keyIndex: index, rowIndex })
            )}
          </TableRow>
        );
      })}
    </TableBody>
  );
};

export default TableBodyX;
