import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  TextField,
  Typography,
} from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import axios from 'axios';
import React, { useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import Label from 'src/components/LabelContainer';
import PrimaryButton from 'src/components/primary-button';
import {
  RHFAutocompleteInput,
  RHFNumberInput,
} from 'src/components/rhf-controlled-input';
import useLoading from 'src/hooks/useLoading';
import useMessage from 'src/hooks/useMessage';

const ConvertUnitDialog = ({ open, preData, onClose, refreshData }) => {
  const { t } = useTranslation();
  const { control, reset, setFocus, setValue, watch, handleSubmit } = useForm();
  const { fromQty, to, from } = watch();
  const [data, setData] = useState(null);
  const {
    showSuccessResponseMessage,
    showErrorResponseMessage,
    showErrorMessage,
  } = useMessage();
  const { loading, startLoading, stopLoading } = useLoading();

  const handleGetUnitConversion = async (productId) => {
    try {
      setData(null);
      setValue('to', null);
      setValue('toQty', '');
      const response = await axios.get(
        `${process.env.REACT_APP_API}/api/v1/stocks/unit-conversion/${productId}`
      );
      setFocus('to');
      setData(response.data.stocks);
    } catch (error) {
      console.log(error);
    }
  };

  const onEntering = async () => {
    reset({ fromQty: '', toQty: '', from: preData, to: null });
    stopLoading();
    if (preData?._id) {
      await handleGetUnitConversion(preData.product.id);
    } else {
      setFocus('from');
    }
  };

  const onSubmit = async (formData) => {
    const { from, to, fromQty, toQty } = formData;

    if (
      !Number.isInteger(Number(fromQty)) ||
      !Number.isInteger(Number(toQty))
    ) {
      return showErrorMessage(t('unit-conversion-not-support-decimal-number'));
    }

    startLoading();
    const conversionInfo = {
      from: { id: from._id, name: from.variant.name },
      to: { id: to._id, name: to.variant.name },
      conversion: to.convert,
      qty: fromQty,
    };
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API}/api/v1/unit-conversion`,
        conversionInfo
      );
      showSuccessResponseMessage(response);
      onClose();
      refreshData();
    } catch (error) {
      console.log(error);
      showErrorResponseMessage(error);
      stopLoading();
    }
  };

  return (
    <Dialog
      TransitionProps={{ onEntering }}
      fullWidth
      maxWidth='xs'
      open={open}
    >
      <form onSubmit={handleSubmit(onSubmit)}>
        <DialogTitle>{t('convert-unit')}</DialogTitle>
        <DialogContent>
          <Grid container direction='column' spacing={2}>
            <Grid item>
              <Label text={t('from')} inputId='from-input'>
                <RHFAutocompleteInput
                  name='from'
                  id='from-input'
                  required
                  autoFocus={false}
                  control={control}
                  dataConfig='stocks'
                  onChange={(_, data, onChange) => {
                    onChange(data);
                    handleGetUnitConversion(data.product.id);
                  }}
                  renderOption={renderOption}
                  optionsURL={`${process.env.REACT_APP_API}/api/v1/stocks?search={search}&page=1&limit=5&filter=product.id_exists::true`}
                  getOptionLabel={(option) => option.variant.name}
                  getOptionSelected={(option, value) =>
                    option._id === value._id
                  }
                />
              </Label>
            </Grid>
            <Grid item>
              <RHFNumberInput
                onChange={(e, onChange) => {
                  const value = e.target.value;
                  const unitRate = to?.convert?.from / to?.convert?.to;
                  setValue('toQty', value * unitRate || '');
                  onChange(value);
                }}
                id='qty-input'
                label={t('qty')}
                required
                control={control}
                name='fromQty'
              />
            </Grid>
            <Grid item>
              <Label text={t('to')} inputId='to-input'>
                <Controller
                  name='to'
                  defaultValue={null}
                  control={control}
                  render={({ field: { value, onChange, ref } }) => {
                    return (
                      <Autocomplete
                        id='to-input'
                        loading={data === null}
                        getOptionLabel={(option) => option?.variant?.name}
                        getOptionSelected={(option, value) => {
                          return option?._id === value?._id;
                        }}
                        value={value}
                        onChange={(_, data) => {
                          const unitRate =
                            data?.convert?.from / data?.convert?.to;
                          setValue('toQty', fromQty * unitRate || '');
                          onChange(data);
                        }}
                        autoHighlight
                        selectOnFocus
                        options={data || []}
                        disableClearable
                        size='small'
                        renderOption={renderOption}
                        renderInput={(params) => {
                          return (
                            <TextField
                              required
                              inputRef={ref}
                              {...params}
                              variant='outlined'
                            />
                          );
                        }}
                      />
                    );
                  }}
                />
              </Label>
            </Grid>
            <Grid item>
              <RHFNumberInput
                disabled={!to?.convert}
                label={t('qty')}
                onChange={(e, onChange) => {
                  const value = e.target.value;
                  const unitRate = to.convert.to / to.convert.from;
                  setValue('fromQty', value * unitRate || '');
                  onChange(value);
                }}
                id='to-qty-input'
                required
                control={control}
                name='toQty'
              />
            </Grid>
            <Grid item>
              <Grid container>
                <Grid item container justify='space-between'>
                  <Typography color='textSecondary'>
                    {t('conversion-rate')} :{' '}
                  </Typography>
                  <Typography color='textSecondary'>
                    {`${to?.convert?.to ?? 'X'} ${
                      from?.variant?.name ?? 'item'
                    } = ${to?.convert?.from ?? 'X'} ${
                      to?.variant?.name ?? 'item'
                    }`}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button disabled={loading} onClick={onClose}>
            {t('cancel')}
          </Button>
          <PrimaryButton
            startIcon={loading && <CircularProgress size={18} />}
            disabled={loading}
            type='submit'
          >
            {loading ? t('converting') : t('convert')}
          </PrimaryButton>
        </DialogActions>
      </form>
    </Dialog>
  );
};
const renderOption = (data) => {
  return (
    <Grid container justify='space-between'>
      <Grid item xs={7}>
        <Typography>{data?.variant?.name}</Typography>
      </Grid>
      <Grid item xs={5}>
        <Typography align='right'>Stock on hand</Typography>
        <Typography align='right'>{data?.qty}</Typography>
      </Grid>
    </Grid>
  );
};
export default ConvertUnitDialog;
