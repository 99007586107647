import React, { useEffect, useState, useContext } from 'react';
import { Grid, makeStyles, Paper } from '@material-ui/core';
import TitleBar from '../../../components/title-bar';
import FloatingBar from '../../../components/floating-bar';
import { useParams, useHistory } from 'react-router-dom';
import { useForm, FormProvider } from 'react-hook-form';
import axios from 'axios';
import { UtilityContext } from '../../../components/context-provider/utilty-context';
import { UserContext } from '../../../components/context-provider/user-context';
import RoleInfo from './role-info';
import roleData from './role-data';
import Spinner, { useSpinner } from 'src/components/spinner';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles(() => ({
  root: {
    marginBottom: '150px',
    flexDirection: 'column',
    position: 'relative',
  },
}));

const AddEditRolePermissionPage = () => {
  const classes = useStyles();
  const history = useHistory();
  const { id } = useParams();
  const { showSnackbar } = useContext(UtilityContext);
  const { me } = useContext(UserContext);
  const methods = useForm();

  const [state, setState] = useState('create');
  const [permissionData, setPermissionData] = useState(roleData);

  const { t } = useTranslation();
  const { spinnerState, openSpinner, closeSpinner } = useSpinner();

  useEffect(() => {
    if (id) {
      openSpinner({ title: t('loading') });
      setState('edit');
      axios
        .get(`${process.env.REACT_APP_API}/api/v1/auth/roles/${id}`)
        .then(({ data }) => {
          if (typeof data === 'object') {
            setPermissionData(data);
          } else {
            console.log('error response');
          }
        })
        .catch(({ response }) => {
          showSnackbar({
            message: response.data.key,
            variant: 'error',
          });
        })
        .finally(() => {
          closeSpinner();
        });
    } else {
      setState('create');
    }
  }, []);

  useEffect(() => {
    if (permissionData) {
      methods.reset(permissionData);
    }
  }, [permissionData]);

  const onSubmit = async (data) => {
    openSpinner({ title: t('saving') });
    const updateData = data;
    switch (state) {
      case 'create':
        axios
          .post(`${process.env.REACT_APP_API}/api/v1/auth/roles`, updateData)
          .then(({ data }) => {
            showSnackbar({ message: data?.key });
            goBackToList();
          })
          .catch(({ response }) => {
            showSnackbar({
              message: response.data.key,
              variant: 'error',
            });
          })
          .finally(() => {
            closeSpinner();
          });
        break;
      case 'edit':
        axios
          .put(
            `${process.env.REACT_APP_API}/api/v1/auth/roles/${id}`,
            updateData
          )
          .then(({ data }) => {
            showSnackbar({ message: data?.key });
            goBackToList();
          })
          .catch(({ response }) => {
            showSnackbar({
              message: response.data.key,
              variant: 'error',
            });
          })
          .finally(() => {
            closeSpinner();
          });
        break;
      default:
        console.log('Error State');
    }
  };

  const handleCancel = () => {
    goBackToList();
  };

  const goBackToList = () => {
    history.push('/dashboard/role-and-permission');
  };

  return (
    <FormProvider {...methods}>
      <form onSubmit={methods.handleSubmit(onSubmit)}>
        <Grid container className={classes.root} spacing={3}>
          <Grid item xs={12}>
            <TitleBar disableButton />
          </Grid>
          <Grid item xs={12}>
            <Paper>
              <Grid container>
                <Grid item xs={12}>
                  <RoleInfo
                    permissionData={permissionData}
                    setPermissionData={setPermissionData}
                  />
                </Grid>
              </Grid>
            </Paper>
          </Grid>
          <FloatingBar
            onCancel={handleCancel}
            onCreate={methods.handleSubmit(onSubmit)}
            disabledConfirmButton={
              state !== 'create' &&
              Object.keys(methods.formState.dirtyFields).length === 0
            }
            confirmButtonText={
              state === 'create' ? 'create-role' : 'save-changes'
            }
          />
        </Grid>
        <Spinner state={spinnerState} />
      </form>
    </FormProvider>
  );
};

export default AddEditRolePermissionPage;
