import { Button, Grid, Typography } from '@material-ui/core';
import React, { useEffect, useContext } from 'react';
import TextInput from '../../../components/text-input';
import { useTranslation } from 'react-i18next';
import axios from 'axios';
import { useForm } from 'react-hook-form';
import { registerMui } from '../../../util/react-hook-form-helper';
import { UtilityContext } from '../../../components/context-provider/utilty-context';
import useSubmitButton, { HiddenSubmitButton } from 'src/hooks/useSubmitButton';

const Automa = () => {
  const { t } = useTranslation();
  const { showSnackbar, showPrompt, closePrompt } = useContext(UtilityContext);
  const { register, handleSubmit, reset, formState } = useForm();
  const { dirtyFields } = formState;
  const { submitButtonRef, submit } = useSubmitButton();

  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_API}/api/v1/setting/automatic-sequencing`)
      .then(({ data: { setting } }) => {
        reset(setting);
      })
      .catch((err) => console.log(err));
  }, []);

  const onSubmit = (data) => {
    axios
      .put(
        `${process.env.REACT_APP_API}/api/v1/setting/automatic-sequencing`,
        data
      )
      .then(({ data }) => {
        showSnackbar({ message: data.message });
        reset(data.setting);
      })
      .catch(({ response }) =>
        showSnackbar({ message: response?.data.message, variant: 'error' })
      )
      .finally(() => closePrompt());
  };

  const handleClick = () => {
    const title = t('action-confirmation');

    let message = (
      <Typography>
        {t('action-cant-undone')}
        <br /> {t('are-you-sure')}
      </Typography>
    );

    showPrompt({
      handleConfirm: submit,
      title,
      message,
      disableIcon: true,
      confirmButtonProps: {
        color: 'primary',
        variant: 'contained',
        style: { borderRadius: 0 },
        disableElevation: true,
      },
      confirmButtonText: t('confirm'),
      cancelButtonText: t('cancel'),
    });
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Typography variant='h6'>{t('automatic-sequencing')}</Typography>
        </Grid>
        <Grid item xs={6}>
          <TextInput
            {...registerMui(register('order.prefix'))}
            label={t('invoice-prefix')}
          />
        </Grid>
        <Grid item xs={6}>
          <TextInput
            type='number'
            {...registerMui(register('order.nextNumber'))}
            label={t('next-number')}
          />
        </Grid>
        <Grid item xs={6}>
          <TextInput
            {...registerMui(register('purchaseOrder.prefix'))}
            label={t('purchase-order-prefix')}
          />
        </Grid>
        <Grid item xs={6}>
          <TextInput
            type='number'
            {...registerMui(register('purchaseOrder.nextNumber'))}
            label={t('next-number')}
          />
        </Grid>
        <Grid item xs={6}>
          <TextInput
            {...registerMui(register('billing.prefix'))}
            label={t('billing-prefix')}
          />
        </Grid>
        <Grid item xs={6}>
          <TextInput
            type='number'
            {...registerMui(register('billing.nextNumber'))}
            label={t('next-number')}
          />
        </Grid>
        <Grid item xs={12} style={{ textAlign: 'right' }}>
          <Button
            disabled={!Object.keys(dirtyFields).length}
            variant='contained'
            color='primary'
            onClick={handleClick}
          >
            {t('save-changes')}
          </Button>
        </Grid>
      </Grid>
      <HiddenSubmitButton ref={submitButtonRef} />
    </form>
  );
};

export default Automa;
