import React from 'react';
import { Avatar, makeStyles } from '@material-ui/core';
import PhotoIcon from '@material-ui/icons/Photo';

const useStyles = makeStyles((theme) => ({
  image: {
    width: ({ size }) => (size === 'large' ? '80px' : '40px'),
    height: ({ size }) => (size === 'large' ? '80px' : '40px'),
    backgroundColor: `${theme.palette.primary.lighter} !important`,
    borderRadius: '5px',
    '& svg': {
      color: theme.palette.primary.main,
      fontSize: ({ size }) => (size === 'large' ? '50px' : '30px'),
    },
    '& img': {
      objectFit: 'contain',
    },
  },
}));

const ProductImage = ({ size, ...props }) => {
  const classes = useStyles({ size });
  return (
    <Avatar
      {...props}
      src={props.src ? props.src : null}
      className={classes.image}
      variant='square'
    >
      <PhotoIcon />
    </Avatar>
  );
};

export default ProductImage;
