import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import TabContainer from '../tabs-container';
import { Tab } from '@material-ui/core';

const TabFilter = ({ value, onChange, options, ...other }) => {
  const { t } = useTranslation();
  return (
    <TabContainer
      scrollButtons='on'
      value={value}
      onChange={onChange}
      {...other}
    >
      {options.map((item, index) => (
        <Tab label={t(item)} key={index}></Tab>
      ))}
    </TabContainer>
  );
};

export const useTabFilter = (initial) => {
  const [value, setValue] = useState(initial);
  const changeFilter = (_, newValue) => setValue(newValue);

  //   create default filter to change filter for tableX
  const createDefaultFilter = ({ value, options, filter }) =>
    value > 0 ? { [filter]: options[value] } : {};

  return { value, setValue, changeFilter, createDefaultFilter };
};

export default TabFilter;
