import React, { useState } from 'react';
import { Grid, makeStyles, Paper, Tab } from '@material-ui/core';
import TitleBar from '../../components/title-bar';
import TabContainer from '../../components/tabs-container';
import { useTranslation } from 'react-i18next';
import AutomaticSequencing from './automatic-sequencing';
import WarehouseSetting from './warehouse-setting';

const useStyles = makeStyles(() => ({
  root: {
    marginBottom: '150px',
    flexDirection: 'column',
    position: 'relative',
  },
}));

const SettingPage = () => {
  const classes = useStyles();
  const [value, setValue] = useState(0);
  const { t } = useTranslation();

  return (
    <Grid container className={classes.root} spacing={3}>
      <Grid item xs={12}>
        <TitleBar disableButton />
      </Grid>
      <Grid item xs={12}>
        <Paper>
          <TabContainer
            style={{ padding: '0 10px' }}
            value={value}
            onChange={(e, newValue) => setValue(newValue)}
          >
            <Tab label={t('general')}></Tab>
          </TabContainer>
          <Grid container className='container'>
            <Grid item xs={12}>
              <WarehouseSetting />
            </Grid>
            <Grid item xs={12}>
              <AutomaticSequencing />
            </Grid>
          </Grid>
        </Paper>
      </Grid>
    </Grid>
  );
};

export default SettingPage;
