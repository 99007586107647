import React from 'react';
import { IconButton, makeStyles } from '@material-ui/core';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import AddIcon from '@material-ui/icons/Add';
import { AiOutlinePrinter } from 'react-icons/ai';
import CheckIcon from '@material-ui/icons/Check';
import CloseIcon from '@material-ui/icons/Close';
import StarIcon from '@material-ui/icons/Star';

const useStyles = makeStyles((theme) => ({
  root: {
    marginRight: '5px',
    background: theme.palette.primary.main,
    padding: '7px',
    '& svg': {
      color: 'white',
      fontSize: '16px',
    },
    '&:hover': {
      background: theme.palette.primary.dark,
    },
  },
}));

const icons = {
  add: AddIcon,
  edit: EditIcon,
  delete: DeleteIcon,
  print: AiOutlinePrinter,
  confirm: CheckIcon,
  close: CloseIcon,
  default: StarIcon,
  cancel: CloseIcon,
};

const ActionButton = ({ variant, hidden = false, customIcon, ...other }) => {
  const classes = useStyles();
  const Icon = customIcon || icons[variant] || EditIcon;
  if (hidden) return null;
  return (
    <IconButton className={classes.root} {...other}>
      <Icon />
    </IconButton>
  );
};

export default ActionButton;
