import {
  Dialog,
  DialogContent,
  DialogTitle,
  makeStyles,
  Grid,
  IconButton,
  Button,
  Typography,
} from '@material-ui/core';
import React, { useState, useRef } from 'react';
import CloseIcon from '@material-ui/icons/Close';
import moment from 'moment';
import Status from '../../../components/status';
import { useTranslation } from 'react-i18next';
import Products from './products';
import TotalDisplay from '../../../components/total-display';
import axios from 'axios';
import BackDropSpinner from '../../../components/backdrop-spinner';
import CostLine from './cost-line';
import clsx from 'clsx';
import { useReactToPrint } from 'react-to-print';
import PurchaseInvoice from './invoice';
import ActionButtonList from './action-button-list';
import NoteInvoice from '../../../components/note-invoice';

const useStyles = makeStyles(() => ({
  title: {
    background: '#EEEEEE',
    '& h2': {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      fontWeight: 'normal',
    },
  },
  pBold: {
    '& p': {
      fontWeight: 'bold',
    },
  },
  table: {
    fontSize: '14px',
    '& th': {
      fontWeight: 'bold',
    },
  },
  bold: {
    fontWeight: 'bold',
    textAlign: 'right',
  },
  textRight: {
    textAlign: 'right',
  },
  dialogContent: {
    height: '90vh',
    overflow: 'overlay',
  },
}));

const PurchaseDetailPopup = ({ state, onClose, handleRefreshTable }) => {
  const classes = useStyles();
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState({});

  const { open } = state;

  const { t } = useTranslation();

  const onEnter = async () => {
    setLoading(true);
    await fetchData();
    setLoading(false);
  };

  const fetchData = async () => {
    try {
      const {
        data: { purchaseReturn },
      } = await axios.get(
        `${process.env.REACT_APP_API}/api/v1/purchase-return/${state.data?._id}`
      );
      setData(purchaseReturn);
    } catch (err) {
      console.log(err);
    }
  };

  const onExited = () => {
    setData({});
  };

  const invoiceRef = useRef();
  const handlePrintInvoice = useReactToPrint({
    content: () => invoiceRef.current,
  });

  return (
    <Dialog
      open={open}
      onClose={onClose}
      TransitionProps={{
        onEnter,
        onExited,
      }}
      fullWidth
      maxWidth="md"
    >
      <DialogTitle className={classes.title}>
        <Grid container direction="column">
          <Grid item container justify="space-between" alignItems="center">
            <Grid item xs="auto">
              <Grid container alignItems="center">
                <Grid item>
                  <Typography> Purchase Return Detail</Typography>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs="auto">
              <IconButton
                className={classes.hiddenOnXS}
                color="secondary"
                onClick={onClose}
              >
                <CloseIcon />
              </IconButton>
            </Grid>
          </Grid>
          <Grid item container justify="space-between">
            <Grid item xs="auto">
              <Button
                disableElevation
                className={clsx({ invisible: loading })}
                variant="contained"
                color="primary"
                onClick={handlePrintInvoice}
              >
                {t('print')}
              </Button>
            </Grid>
            <Grid item xs="auto">
              <ActionButtonList
                onSuccess={() => {
                  handleRefreshTable();
                  fetchData();
                }}
                data={data}
              />
            </Grid>
          </Grid>
        </Grid>
      </DialogTitle>
      <DialogContent
        classes={{ root: classes.dialogContent }}
        style={{ position: 'relative' }}
      >
        <BackDropSpinner backgroundColor="white" open={loading} />
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Grid container style={{ padding: '0 15px' }}>
              <Grid item xs>
                <Grid container spacing={1}>
                  <Grid item className={classes.pBold}>
                    <p>{`${t('date')} : `}</p>
                    <p>{`${t('vendor-name')} : `}</p>
                    <p>{`${t('status')} : `}</p>
                  </Grid>
                  <Grid item>
                    <p>{moment(data?.createdAt).format('DD/MM/YYYY')}&nbsp;</p>
                    <p>{data?.vendor?.name}&nbsp;</p>
                    <Status size="small" status={data?.status} />
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs>
                <Grid container spacing={1} justify="flex-end">
                  <Grid item className={classes.pBold}>
                    <p>{`${t('purchase-order-number')} : `}</p>
                    <p>{`${t('reference-number')} : `}</p>
                    <p>{`${t('created-by')} : `}</p>
                  </Grid>
                  <Grid item>
                    <p>{data?.purchaseNumber || t('n/a')}&nbsp;</p>
                    <p>{data?.referenceNumber || t('n/a')}&nbsp;</p>
                    <p>{data?.createdBy?.name}&nbsp;</p>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Grid container spacing={4}>
              <Grid item xs={12}>
                <Products data={data?.product} />
              </Grid>
              {data?.costLines?.length > 0 && (
                <Grid item xs={12}>
                  <CostLine data={data?.costLines} />
                </Grid>
              )}
            </Grid>
          </Grid>
          <Grid item container justify="flex-end" xs={12}>
            <Grid item xs={4}>
              <TotalDisplay
                tax={data?.totalTax}
                costing={data?.totalCosting}
                subtotal={data?.subtotal}
              />
            </Grid>
          </Grid>
          {data?.note && <NoteInvoice note={data?.note} />}
        </Grid>
        <PurchaseInvoice data={data} ref={invoiceRef} />
      </DialogContent>
    </Dialog>
  );
};

export default PurchaseDetailPopup;
