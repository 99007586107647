import { Grid, Typography } from '@material-ui/core';
import axios from 'axios';
import React, { useContext, useEffect } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { UtilityContext } from 'src/components/context-provider/utilty-context';
import LabelWrapper from 'src/components/label-wrapper-input';
import CustomerSelector from 'src/components/customer-selector';
import useMessage from 'src/hooks/useMessage';

// attach customer to sale order
const Customer = ({
  refreshForm,
  orderId,
  defaultValue,
  handleRefreshHistory,
  handleRefreshTable,
}) => {
  const { t } = useTranslation();
  const { control, setValue, getValues } = useForm();
  const { showErrorResponseMessage, showSuccessResponseMessage } = useMessage();
  const { showPrompt, closePrompt } = useContext(UtilityContext);

  const handlePrompt = (newCustomer) => {
    const currentCustomer = getValues('customer');
    const title = t('action-confirmation');
    const message = currentCustomer ? (
      <Typography>
        {t('update-customer-from')}
        <br />
        <b>{currentCustomer.name}</b>
        {` ${t('to')} `}
        <b>{newCustomer?.fullname}</b>
      </Typography>
    ) : (
      <Typography>
        {t('update-customer-to')}
        <br />
        <b>{newCustomer?.fullname}</b>
      </Typography>
    );

    showPrompt({
      handleConfirm: () => onConfirm(newCustomer),
      title,
      message,
      disableIcon: true,
      confirmButtonProps: {
        color: 'primary',
        variant: 'contained',
        style: { borderRadius: 0 },
        disableElevation: true,
      },
      confirmButtonText: t('confirm'),
      cancelButtonText: t('cancel'),
    });
  };

  useEffect(() => {
    if (defaultValue) {
      setValue('customer', defaultValue);
    }
  }, [defaultValue]);

  const onConfirm = async (newCustomer) => {
    const customer = {
      id: newCustomer._id,
      name: newCustomer.fullname,
      phone: newCustomer.phone,
    };
    const newShippingInfo =
      newCustomer.shippingInfo?.[newCustomer.primaryShipping];
    setValue('customer', customer);
    closePrompt();
    try {
      const response = await axios.put(
        `${process.env.REACT_APP_API}/api/v1/orders/${orderId}/customer`,
        {
          customer,
          shippingInfo: newShippingInfo,
        }
      );
      showSuccessResponseMessage(response);
      refreshForm();
      handleRefreshTable();
      if (handleRefreshHistory) handleRefreshHistory();
    } catch (err) {
      showErrorResponseMessage(err);
      console.error(err);
    }
  };

  return (
    <Grid item xs={12} spacing={1} container alignItems='flex-end'>
      <Grid item xs>
        <LabelWrapper label={t('customer')}>
          <Controller
            control={control}
            name='customer'
            defaultValue={null}
            render={({ field: { value, onChange, ref } }) => {
              return (
                <CustomerSelector
                  label={t('customer')}
                  value={value}
                  onChange={(_, data) => {
                    if (data) {
                      handlePrompt(data);
                    } else {
                      onChange(data);
                    }
                  }}
                  inputRef={ref}
                />
              );
            }}
          ></Controller>
        </LabelWrapper>
      </Grid>
    </Grid>
  );
};

export default Customer;
