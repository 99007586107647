import React from 'react';
import { useFormContext } from 'react-hook-form';
import { getValue as productGetValue } from '../products';
import { getValue as costLineGetValue } from '../cost-line';
import TotalDisplay from './total-display';

const SaleOrderTotal = () => {
  const { watch } = useFormContext();
  const orderItems = watch('orderItems');
  const costLines = watch('costLines');
  const shippingFee = watch('shippingFee');

  let subtotal = 0;
  let productTaxTotal = 0;

  if (orderItems?.length > 0) {
    ({ subtotal, productTaxTotal } = orderItems?.reduce(
      (acc, curr) => {
        if (!curr.isCanceled) {
          const get = productGetValue(curr);
          const totalAmount = get.totalAmount;
          acc.subtotal += totalAmount;
          acc.productTaxTotal += 0;
        }
        return acc;
      },
      { subtotal: 0, productTaxTotal: 0 }
    ));
  }

  let costingTotal = 0;
  let costingTaxTotal = 0;
  if (costLines?.length > 0) {
    ({ costingTotal, costingTaxTotal } = costLines?.reduce(
      (acc, curr) => {
        const get = costLineGetValue(curr);
        const totalAmount = get.totalAmount;
        acc.costingTotal += totalAmount;
        acc.costingTaxTotal += get.costPrice * (get.tax / 100);
        return acc;
      },
      { costingTotal: 0, costingTaxTotal }
    ));
  }

  const taxTotal = productTaxTotal + costingTaxTotal;

  return (
    <TotalDisplay
      subtotal={subtotal}
      tax={taxTotal}
      shippingFee={shippingFee}
      costing={costingTotal}
    />
  );
};

export default SaleOrderTotal;
