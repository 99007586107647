import React, { useContext, useState, useEffect } from 'react';
import { TableRow, TableCell, TableBody } from '@material-ui/core';
import { useFieldArray, useFormContext } from 'react-hook-form';
import { UtilityContext } from '../../../../components/context-provider/utilty-context';

import NumberFormatCustom from '../../../../components/number-format';
import CustomTable from '../../../../components/custom-table';
import CustomRemoveIconButton from '../../../../components/custom-button/remove-icon-button';
import {
  RHFDiscountInput,
  RHFNumberInput,
} from '../../../../components/rhf-controlled-input';
import calculateDiscount from '../../../../util/calculate-discount';
import VariantInput from 'src/components/variant-input';

const tableHeadData = [
  { value: 'no.' },
  { value: 'image', width: '80px' },
  { value: 'product-name', width: '30%' },
  { value: 'quantity' },
  { value: 'unit-price' },
  { value: 'discount' },
  { value: 'amount', align: 'right', width: '10%' },
  { width: '50px' },
];

const Products = ({ state, stockData = {} }) => {
  // form context
  const { control, watch, getValues, setValue } = useFormContext();
  // remainingStock
  const [stocks, setStocks] = useState({});
  // utility context
  const { showDeletePrompt, closeDeletePrompt } = useContext(UtilityContext);

  // filedArray orderItems
  const { fields, append, remove } = useFieldArray({
    control,
    name: 'orderItems',
  });

  useEffect(() => {
    setStocks(stockData);
  }, [stockData]);

  let orderItems = 0;
  let tableNumber = 0;
  const remainingStock = {};
  const watchFieldArray = watch('orderItems');

  // make fields to controlled field and store remainingStock
  const controlledFields = watchFieldArray
    ? fields.map((field, index) => {
        const variantId =
          watchFieldArray[index]?.variant?._id ||
          watchFieldArray[index]?.variant?.stock?._id;
        if (variantId) {
          const currentStock = stocks[variantId];
          const currentQty = watchFieldArray[index].qty;
          if (!watchFieldArray[index].isCanceled) {
            orderItems++;
          }
          if (remainingStock[variantId] !== undefined) {
            remainingStock[variantId] -= Number(currentQty);
          } else if (!watchFieldArray[index].isCanceled) {
            remainingStock[variantId] = Number(currentStock - currentQty);
          }
        }
        return {
          ...field,
          ...watchFieldArray[index],
        };
      })
    : [];

  // add row item
  const handleAddField = (index) => {
    if (index === fields.length - 1) {
      // use isNew to easily identify which item is newly add
      append({ qty: 1, isNew: true }, { shouldFocus: false });
    }
  };

  // remove row item prompt
  const handleRemove = (index, id) => {
    showDeletePrompt({
      handleConfirm: () => onDelete(index, id),
    });
  };

  // on remove confirm
  const onDelete = (index) => {
    switch (state) {
      case 'create': {
        remove(index);
        break;
      }
      case 'edit': {
        const currentItem = getValues(`orderItems.${index}`);
        /*  if the item newly add during edit by remove before save should
         be remove normally else should only add isCanceled true and isNew true.
         already cancel item would also be isCancel true so hard to identify which one is newly cancel
         so add isCanceled true + isNew to newly cancel item would solve this problem
         */
        if (currentItem.isNew) {
          remove(index);
        } else {
          const removeItem = {
            ...currentItem,
            isCanceled: true,
            isNew: true,
          };

          setValue(`orderItems.${index}`, removeItem);
        }
        break;
      }
      default: {
        console.error('wrong state', state);
      }
    }
    closeDeletePrompt();
  };

  return (
    <CustomTable.Container>
      {/* table header */}
      <CustomTable.Head data={tableHeadData} />
      {/* table body */}
      <TableBody>
        {controlledFields?.map((field, index) => {
          if (field.isCanceled) {
            return null;
          }
          // get value from field
          const get = getValue(field);

          let remaining = 0;
          const variant = get.variant;
          if (
            variant?._id in remainingStock ||
            variant?.stock?._id in remainingStock
          ) {
            remaining = remainingStock[variant.stock?._id || variant._id];
          }

          tableNumber++;
          return (
            <TableRow key={field.id}>
              <TableCell>
                <CustomTable.CellValue>{tableNumber}</CustomTable.CellValue>
              </TableCell>
              <TableCell>
                <CustomTable.Image src={get.image} />
              </TableCell>
              <TableCell>
                <VariantInput
                  index={index}
                  name={`orderItems.${index}.variant`}
                  remainingStock={remainingStock}
                  defaultValue={get.variant}
                  handleAddField={handleAddField}
                  setStocks={setStocks}
                  stocks={stocks}
                  rules={orderItems > 0}
                  disabled={!field.isNew}
                />
              </TableCell>
              <TableCell>
                <RHFNumberInput
                  name={`orderItems.${index}.qty`}
                  defaultValue={get.qty}
                  textAlign='right'
                  decimalValue={false}
                  min={1}
                  required={!!get.productName}
                  helperText={`Remaining : ${remaining}`}
                  disabled={!field.isNew}
                />
              </TableCell>
              <TableCell>
                <RHFNumberInput
                  name={`orderItems.${index}.salePrice`}
                  textAlign='right'
                  defaultValue={get.salePrice}
                  required={!!get.productName}
                  min={0}
                  disabled={!field.isNew}
                />
              </TableCell>
              <TableCell>
                <RHFDiscountInput
                  name={`orderItems.${index}.discount`}
                  defaultValue={get.discount}
                  disabled={!field.isNew}
                />
              </TableCell>
              <TableCell style={{ textAlign: 'right' }}>
                <CustomTable.CellValue align='right'>
                  <NumberFormatCustom value={get.totalAmount} />
                </CustomTable.CellValue>
              </TableCell>
              <TableCell>
                <CustomTable.CellValue component='div'>
                  <CustomRemoveIconButton
                    invisible={
                      index === fields.length - 1 || fields.length <= 1
                    }
                    onClick={() => handleRemove(index, field._id)}
                  />
                </CustomTable.CellValue>
              </TableCell>
            </TableRow>
          );
        })}
      </TableBody>
    </CustomTable.Container>
  );
};

// function to get product value
export const getValue = (data) => {
  const variant = data.variant ?? null;
  const productName = variant?.variant?.name ?? '';
  const productCode = variant?.variant?.altCode || variant?.variant?.sku;
  const image =
    data?.variant?.variant?.images?.[0]?.thumbnail ||
    data?.variant?.stock?.variant?.images?.[0]?.thumbnail;
  const tax = data.tax ?? 0;
  const costPrice = data.costPrice ?? 0;
  const salePrice = data?.salePrice ?? 0;
  const qty = data?.qty ?? 1;
  const discount = data.discount ?? { amount: 0, type: 'percentage' };
  const totalAmount = calculateDiscount(qty * salePrice, discount);

  return {
    variant,
    productName,
    productCode,
    image,
    tax,
    costPrice,
    salePrice,
    qty,
    totalAmount,
    discount,
  };
};

export default Products;
