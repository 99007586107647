// convert array to string like [1,2,3] to "id[]=1&id[]=2&id[]=3"
const reduceArrayString = (array, name = 'id[]') => {
  if (Array.isArray(array)) {
    return array
      .reduce((acc, curr) => (acc += `${name}=${curr._id}&`), '')
      .slice(0, -1);
  } else {
    return `${name}=${array._id}`;
  }
};

export default reduceArrayString;
