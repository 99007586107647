import { Grid, Typography } from '@material-ui/core';
import React from 'react';

const Label = ({ text, inputId, children, spacing = 1 }) => {
  return (
    <Grid container direction='column' spacing={spacing}>
      <Grid item>
        <label htmlFor={inputId}>
          <Typography variant='body2' color='primary'>
            <b>{text}</b>
          </Typography>
        </label>
      </Grid>
      <Grid item>{children}</Grid>
    </Grid>
  );
};

export default Label;
