import { Box, Button, Grid, Paper, Typography } from '@material-ui/core';
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from '@material-ui/pickers';
import { useContext, useEffect } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { UserContext } from 'src/components/context-provider/user-context';
import InputSelect from 'src/components/input-select';
import LabelInput from 'src/components/label-input';
import {
  RHFDateInput,
  RHFProfilePicture,
  RHFTextInput,
} from 'src/components/rhf-controlled-input';
import Spinner, { useSpinner } from 'src/components/spinner';
import TitleBar from 'src/components/title-bar';
import useMessage from 'src/hooks/useMessage';
import DateFnsUtils from '@date-io/date-fns';
import DialogContainer from 'src/components/dialog-container';
import RHFPasswordInput from 'src/components/rhf-controlled-input/password-input';
import useDialog from 'src/hooks/useDialog';
import RHFPhoneNumberInput from 'src/components/rhf-controlled-input/phone-number-input';
import authApi from 'src/api/auth';
import removeImages from 'src/util/remove-images';
import uploadImage from 'src/util/upload-image';
import InputError from 'src/components/input-error';
import { passwordRegexp } from 'src/util/regex-pattern';

const Profile = () => {
  const { t } = useTranslation();
  const { fetchUser, s3BaseURL } = useContext(UserContext);
  const { handleSubmit, reset, formState, control, trigger } = useForm();
  const { showErrorResponseMessage } = useMessage();
  const { dirtyFields } = formState;
  const { spinnerState, openSpinner, closeSpinner } = useSpinner({
    title: t('loading'),
  });
  const { dialogState, openDialog, closeDialog } = useDialog();

  useEffect(() => {
    openSpinner({ title: t('loading') });
    fetchUser((error, data) => {
      if (error) {
        showErrorResponseMessage(data);
        closeSpinner();
        return;
      }

      const resetData = {
        gender: 'None',
        ...(data?.user?.id || {}),
        profile: { src: data?.user?.id?.profile },
      };
      reset(resetData);
      closeSpinner();
    });
  }, []);

  const onSubmit = async (formData) => {
    try {
      openSpinner();
      const { profile, _id, ...data } = formData;

      const updateData = data;

      if (profile?.file) {
        try {
          if (profile.remove.length > 0) {
            await removeImages(profile.remove);
          }
          updateData.profile = await uploadImage(
            formData.profile.file,
            s3BaseURL,
            'public/users-profile'
          );
        } catch (error) {
          console.log(error);
          showErrorResponseMessage(error);
          closeSpinner();
          return;
        }
      }

      await authApi.updateOwnUser(updateData);

      fetchUser();
      closeDialog();
      reset({ ...formData, password: '' }, { keepDirty: false });
    } catch (error) {
      showErrorResponseMessage(error);
    } finally {
      closeSpinner();
    }
  };

  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <TitleBar disableButton />
        </Grid>
        <Grid item xs={12}>
          <Paper style={{ marginBottom: '50px' }}>
            <form id='warehouse-setting-form' onSubmit={handleSubmit(onSubmit)}>
              <Grid container spacing={2} className='container'>
                <Grid item xs={12}>
                  <Typography variant='h6'>
                    {t('account-information')}
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <Grid container spacing={2}>
                    <Grid
                      item
                      container
                      justify='center'
                      alignItems='center'
                      xs={12}
                    >
                      <Box mb={2}>
                        <RHFProfilePicture
                          editDialogTitle={t('edit-profile')}
                          name='profile'
                          control={control}
                        />
                      </Box>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <LabelInput
                        component={RHFTextInput}
                        name='firstname'
                        label={t('firstname')}
                        required
                        control={control}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <LabelInput
                        component={RHFTextInput}
                        name='lastname'
                        label={t('lastname')}
                        control={control}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <Controller
                        control={control}
                        name='gender'
                        defaultValue={'None'}
                        render={({ field: { value, onChange } }) => {
                          return (
                            <InputSelect
                              menu={[
                                { value: 'None', label: 'None' },
                                { value: 'Male', label: 'Male' },
                                { value: 'Female', label: 'Female' },
                              ]}
                              label={t('gender')}
                              value={value}
                              onChange={onChange}
                            />
                          );
                        }}
                      ></Controller>
                    </Grid>
                    <Grid item xs={6}>
                      <LabelInput
                        label={t('date-of-birth')}
                        component={RHFDateInput}
                        format='dd/MM/yyyy'
                        customComponent={KeyboardDatePicker}
                        name='dateOfBirth'
                        control={control}
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <LabelInput
                        label={t('phone')}
                        component={RHFPhoneNumberInput}
                        name='phone'
                        control={control}
                      ></LabelInput>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <LabelInput
                        component={RHFTextInput}
                        name='email'
                        label={t('email')}
                        type='email'
                        control={control}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <LabelInput
                        label={t('address')}
                        component={RHFTextInput}
                        name='address'
                        multiline
                        rows={3}
                        control={control}
                      ></LabelInput>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={12}>
                  <Grid container justify='space-between'>
                    <Grid item>
                      <Button
                        variant='contained'
                        color='primary'
                        onClick={() => {
                          openDialog({ state: 'update' });
                        }}
                      >
                        {t('update-password')}
                      </Button>
                    </Grid>
                    <Grid item>
                      <Button
                        disabled={!Object.keys(dirtyFields).length}
                        variant='contained'
                        color='primary'
                        onClick={async () => {
                          const result = await trigger();
                          if (result) {
                            openDialog({
                              state: 'save',
                              onConfirmPassword: handleSubmit(onSubmit),
                            });
                          }
                        }}
                      >
                        {t('save-changes')}
                      </Button>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
              <Spinner state={spinnerState} />
            </form>
          </Paper>
        </Grid>
      </Grid>
      <PasswordDialog
        open={dialogState.open}
        onClose={closeDialog}
        openSpinner={openSpinner}
        closeSpinner={closeSpinner}
        data={dialogState.data}
        control={control}
      />
    </MuiPickersUtilsProvider>
  );
};

const PasswordDialog = ({
  open = true,
  onClose,
  data,
  openSpinner,
  closeSpinner,
  control,
}) => {
  const { t } = useTranslation();
  const {
    handleSubmit,
    control: innerControl,
    getValues,
    reset,
    formState,
  } = useForm();
  const { showErrorResponseMessage } = useMessage();

  const state = data?.state || 'save';

  const onSubmit = (formData) => {
    if (state === 'update') {
      handleUpdatePassword(formData);
    } else {
      handleConfirmPassword(formData);
    }
  };

  const handleUpdatePassword = async (formData) => {
    //update password
    try {
      openSpinner();
      await authApi.updatePassword(formData);
      onClose();
    } catch (error) {
      showErrorResponseMessage(error);
      console.log(error);
    } finally {
      closeSpinner();
    }
  };

  const resetForm = () => {
    reset({ oldPassword: '', newPassword: '', confirmPassword: '' });
  };

  const handleConfirmPassword = () => {
    //check password
    data.onConfirmPassword();
  };

  return (
    <DialogContainer
      optionalButton={{
        text: t('confirm'),
        props: {
          variant: 'contained',
          color: 'primary',
          onClick: handleSubmit(onSubmit),
        },
      }}
      open={open}
      onClose={onClose}
      title={t(state === 'update' ? 'update-password' : 'confirm')}
      onCancel={onClose}
      state={state}
      TransitionProps={{
        onExited: () => {
          resetForm();
        },
      }}
      hideConfirmButton
    >
      <form onSubmit={handleSubmit(onSubmit)}>
        <Grid container spacing={2}>
          {state === 'update' ? (
            <>
              <Grid item xs={12}>
                <LabelInput
                  component={RHFPasswordInput}
                  autoFocus
                  name='oldPassword'
                  label={t('old-password')}
                  control={innerControl}
                  required
                />
              </Grid>
              <Grid item xs={12}>
                <LabelInput
                  component={RHFPasswordInput}
                  name='newPassword'
                  label={t('new-password')}
                  rules={{
                    required: true,
                    pattern: {
                      value: passwordRegexp,
                      message: t('required-strong-password'),
                    },
                  }}
                  customEndAdornment={
                    <InputError error={formState.errors.newPassword} />
                  }
                  control={innerControl}
                  required
                />
              </Grid>
              <Grid item xs={12}>
                <LabelInput
                  component={RHFPasswordInput}
                  name='confirmPassword'
                  label={t('confirm-password')}
                  control={innerControl}
                  rules={{
                    validate: (value) => value === getValues('newPassword'),
                  }}
                  required
                />
              </Grid>
            </>
          ) : (
            <Grid item xs={12}>
              <LabelInput
                component={RHFPasswordInput}
                autoFocus
                name='password'
                label={t('password')}
                control={control}
                required
              />
            </Grid>
          )}
        </Grid>
      </form>
    </DialogContainer>
  );
};

export default Profile;
