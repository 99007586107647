import { Divider, Grid } from '@material-ui/core';
import React from 'react';
import { useTranslation } from 'react-i18next';
import PriceWithLabel from 'src/components/price-with-label';
import LabelTotal from 'src/components/label-input-total';
import { RHFNumberInput } from 'src/components/rhf-controlled-input';

const TotalDisplay = (props) => {
  const { t } = useTranslation();
  const {
    tax = 0,
    costing = 0,
    subtotal = 0,
    defaultTotal = 0,
    disableCosting,
    shippingFee = 0,
    disableSubtotal,
    className,
    size = 'medium',
  } = props;
  const total = defaultTotal || costing + subtotal + tax + Number(shippingFee);
  return (
    <Grid
      container
      className={className}
      spacing={size === 'medium' ? 1 : 0}
      direction='column'
    >
      <Grid item container justify='flex-end'>
        {disableCosting || (
          <Grid item xs={6}>
            <PriceWithLabel label={t('costing-total')} value={costing} />
          </Grid>
        )}
        {disableSubtotal || (
          <Grid item xs={5}>
            <PriceWithLabel label={t('subtotal')} value={subtotal} />
          </Grid>
        )}
      </Grid>
      <Grid item container justify='flex-end'>
        <Grid item xs={6} container justify='flex-end'>
          <Grid item xs={6}>
            <LabelTotal label={t('shipping-fee')}>
              <RHFNumberInput
                name='shippingFee'
                size='small'
                variant='standard'
                placeholder='0'
              />
            </LabelTotal>
          </Grid>
        </Grid>
        <Grid item xs={5}>
          <PriceWithLabel label={t('tax')} value={tax} />
        </Grid>
      </Grid>
      <Grid item>
        <Divider />
      </Grid>
      <Grid item container justify='flex-end' xs={12}>
        <PriceWithLabel valueFontSize='18px' label={t('total')} value={total} />
      </Grid>
    </Grid>
  );
};

export default TotalDisplay;
