import TreeItem from '@material-ui/lab/TreeItem';
import { makeStyles, Typography, Avatar, Grid } from '@material-ui/core';
import React from 'react';
import ActionButton from '../../../../../components/action-button';
import CategoryIcon from '@material-ui/icons/Category';

const useTreeItemStyles = makeStyles(() => ({
  labelContainer: {
    padding: '5px 10px',
  },
  avartar: {
    marginRight: '20px',
  },
}));

const CategoryItem = (props) => {
  const classes = useTreeItemStyles();
  const { labelText, icon, onEdit, onAdd, onDelete, ...other } = props;

  return (
    <TreeItem
      label={
        <Grid
          container
          justify="space-between"
          alignItems="center"
          className={classes.labelContainer}
        >
          <Grid item xs container alignItems="center">
            <Avatar src={icon} className={classes.avartar}>
              {!icon && <CategoryIcon />}
            </Avatar>
            <Typography>{labelText}</Typography>
          </Grid>
          <Grid item xs="auto">
            <ActionButton variant="add" onClick={onAdd}></ActionButton>
            <ActionButton variant="edit" onClick={onEdit}></ActionButton>
            <ActionButton variant="delete" onClick={onDelete}></ActionButton>
          </Grid>
        </Grid>
      }
      classes={{ root: classes.root }}
      {...other}
    />
  );
};

export default CategoryItem;
