import { makeStyles, Table } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  // default style
  root: {
    '& .MuiIconButton-root .MuiIconButton-label': {
      '& svg': {
        fontSize: '16px',
      },
    },
    '& thead': {
      '& th': {
        fontSize: '12px',
      },
    },
    '& tbody': {
      '& tr': {
        verticalAlign: 'top',
      },
      '& tr:nth-child(even)': {
        '& td': {
          background: theme.palette.primary.lighter,
        },
      },
      '& td': {
        border: 0,
        verticalAlign: ({ size }) => size === 'small' && 'middle',
        paddingTop: ({ size }) => size !== 'small' && '10px',
        '& p': {
          margin: ({ size }) => size === 'small' && '0',
        },
      },
      '& .MuiAvatar-root': {
        width: '40px',
        margin: '0 auto',
        backgroundColor: `${theme.palette.primary.lighter} !important`,
        '& svg': {
          color: theme.palette.primary.main,
          fontSize: '30px',
        },
        '& img': {
          objectFit: 'contain',
        },
      },
    },
  },
}));

// table with default style
const CustomTableContainer = ({ children, size = 'medium', th }) => {
  const classes = useStyles({ size, th });
  return <Table className={classes.root}>{children}</Table>;
};

export default CustomTableContainer;
